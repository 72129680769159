import * as s from "./MissingStyled";

import { useAppDispatch, useAppSelector } from "redux/hooks";
import { LEVEL_TYPE } from "api/interfaces/commonInterface.interface";
import { UnknownRecorderList } from "api/interfaces/recorderInterface.interface";
import AccountId from "components/atoms/text/labels/AccountId";
import { getSwitchAccountInfo } from "utils/AccountUtil";
import { navigateSwitchAccount } from "utils/AuthUtil";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { useAuth } from "components/Auth";
import InfoBubble from "components/blocks/bubble/InfoBubble";
import { useState } from "react";
import { BubbleContainer } from "pages/license/LicenseStyled";

type Props = {
  item: UnknownRecorderList;
  onChangeEach: (e: any, id: string) => void;
  checkList: string[];
};

const MissingListItem = ({
  item,
  onChangeEach,
  checkList,
}: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const intl = useIntl();
  const auth = useAuth();
  const selectedAccount = useAppSelector((state) => state.accountSettings);
  const [isHover, setIsHover] = useState<boolean>(false);

  const onClickAccountId = (accountId: string) => {
    getSwitchAccountInfo(accountId).then(function (selectAccount) {
      if (!!selectAccount) {
        navigateSwitchAccount({
          selectAccount,
          auth,
          navigate,
          dispatch,
          intl,
        });
      }
    });
  };

  return (
    <s.ListItem accountLevel={selectedAccount.accountLevel}>
      <label>
        <span className="recorder-name-wrapper">
          {selectedAccount.accountLevel === LEVEL_TYPE.EU && (
            <input
              className="input_checkbox"
              type="checkbox"
              name={item.recorderId}
              onChange={(e) => onChangeEach(e, item.recorderId)}
              checked={checkList.includes(item.recorderId)}
            />
          )}
          <div className="recorder-name">
            <span className="title">
              {item.type === "Spectrum"
                ? item.mergedSystemName
                : item.recorderName}
            </span>
            {item.type === "Spectrum" && (
              <span className="mergedSystemName">{item.recorderName}</span>
            )}
          </div>
        </span>
        {selectedAccount.accountLevel === LEVEL_TYPE.CP && (
          <button
            onClick={() => onClickAccountId(item.accountId)}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
          >
            <AccountId
              accountId={`${item.accountName}`}
              level={LEVEL_TYPE.EU}
            />
            {isHover && (
              <BubbleContainer>
                <InfoBubble right>
                  <span>{item.accountName}</span>
                  <span>{item.accountNumber}</span>
                </InfoBubble>
              </BubbleContainer>
            )}
          </button>
        )}
      </label>
    </s.ListItem>
  );
};

export default MissingListItem;
