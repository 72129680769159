import { useLocalStorage } from "hook/useLocalStorage";
import { useEffect, useRef, useState } from "react";
import { SelfBrowserSurface, StatusMessages, useReactMediaRecorder } from "react-media-recorder";

const SCREEN_RECORDER_SUPPORTED = !!window.navigator && !!window.navigator.mediaDevices && !!window.navigator.mediaDevices.getDisplayMedia;

const MAXIMUN_DURATION_IN_MIN = 5;
const MAXIMUM_TIME_IN_MS = MAXIMUN_DURATION_IN_MIN * 60 * 1000;

export type ScreenRecorderHookProps = {
  fileExtension: string,
  durationInMs: number,
  startedAt: Date | null,
  error: string,
  resultBlob: Blob | null,
  resultBlobUrl: string,
  status: StatusMessages,
  preconfirmed: boolean,
  setPreconfirmed: (value: boolean) => void,
  startRecording: () => void,
  stopRecording: () => void,
}

const getVideoExtension = (): string => {
  const extensions = [
    'webm', 'mp4'
  ];
  for(let extension of extensions) {
    const mimeType = `video/${extension}`;
    if (MediaRecorder.isTypeSupported(mimeType)) {
      return extension;
    }
  }
  return 'mp4';
}

const localStorageKey = 'mydw-screen-recording-preconfirmed';

export const useScreenRecorder = (): ScreenRecorderHookProps => {
  const [ preconfirmed, setPreconfirmed ] = useLocalStorage(localStorageKey, false);
  const fileExtension = getVideoExtension();
  const mimeType = `video/${fileExtension}`;

  const screenRecorderOptions = {
    screen: true,
    mediaRecorderOptions: { mimeType },
    selfBrowserSurface: 'include' as SelfBrowserSurface,
    onStart: () => {
      setStartedAt(new Date());
      startTimer();
    },
    onStop: (blobUrl: string, blob: Blob) => {
      endTimer();
      setResultBlob(blob);
    }
  }

  if (!SCREEN_RECORDER_SUPPORTED) {
    screenRecorderOptions.screen = false;
  }

  const { error, mediaBlobUrl, status, startRecording, stopRecording } = useReactMediaRecorder(screenRecorderOptions);
  const [resultBlob, setResultBlob] = useState<Blob | null>(null);
  const [startedAt, setStartedAt] = useState<Date | null>(null);
  const [durationInMs, setDurationInMs] = useState<number>(0);
  const timerId = useRef<any>(null);

  useEffect(() => {
    if (durationInMs >= MAXIMUM_TIME_IN_MS) {
      stopRecording();
    }
  }, [durationInMs]);

  function startTimer() {
    setDurationInMs(0);
    if (timerId.current) {
      return;
    }
    timerId.current = setInterval(() => {
      setDurationInMs((duration) => {
        return duration + 1000;
      });
    }, 1000);
  }

  function endTimer() {
    if (!timerId.current) {
      return;
    }
    clearInterval(timerId.current);
    timerId.current = null;
  }

  return {
    fileExtension,
    durationInMs,
    startedAt,
    error,
    resultBlob,
    resultBlobUrl: mediaBlobUrl || '',
    status,
    preconfirmed,
    setPreconfirmed,
    startRecording,
    stopRecording,
  };
};
