import * as s from "./CvvStyled";
import { CVVPairItem, DayNightItem } from "api/interfaces/reportInterface.interface";
import { CVVType } from "./interface";
import { CvvBase } from "./CvvContainer";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { checkCVVError } from "utils/functions";
import { Fragment } from "react";
import CVVThumbnail from "components/atoms/image/thumbnail/CVVThumbnail";
import { isEmpty } from "lodash";
import { CvvCameraThumbnailReferenceInfo, CvvCameraThumbnailEventInfo } from "./CvvCameraThumbnailInfo";
import SmallLabel from "components/atoms/text/labels/SmallLabel";
import { COLORS } from "styles/colors";
import { cvvEvent } from "./constants";


type CvvCameraThumbnailPairProps = {
  data: DayNightItem,
  type: CVVType,
  onChangeOpen: (data: DayNightItem, pairItem: CVVPairItem, base: CvvBase) => void,
  onClickUpdate: (data: DayNightItem, pairItem: CVVPairItem) => Promise<void>,
  onClickCheckNow: () => Promise<void>,
  selectedAccount: ProfileAccountInfo,
  timezoneOffset: number,
  timezone: string
}

export default function CvvCameraThumbnailPair({
  data,
  type,
  onChangeOpen,
  onClickCheckNow,
  selectedAccount,
  timezoneOffset,
  timezone,
  ...props
}: CvvCameraThumbnailPairProps) {
  // event 에 하나라도 true 가 있으면 true
  const pairItem: CVVPairItem = data[type] as CVVPairItem;

  // console.log(pairItem);

  const onClickUpdate = () => {
    return props.onClickUpdate(data, pairItem);
  }

  // 추후 base, last [key] 값으로 구별 방식으로 리팩토링 필요
  return (
    <Fragment key={type}>
      <ThumbnailReference
        onImageClick={() => onChangeOpen(data, pairItem, CvvBase.REFERENCE)}
        onClickUpdate={onClickUpdate}
        pairItem={pairItem}
        type={type}
        timezoneOffset={timezoneOffset}
        timezone={timezone}
      />
      <ThumbnailEvent
        onImageClick={() => onChangeOpen(data, pairItem, CvvBase.EVENT)}
        onClickCheckNow={onClickCheckNow}
        pairItem={pairItem}
        timezoneOffset={timezoneOffset}
        timezone={timezone}
      />
    </Fragment>
  )
}


type ThumbnailReferenceProps = {
  pairItem: CVVPairItem,
  type: CVVType,
  onImageClick: () => void,
  onClickUpdate: () => Promise<void>,
  timezoneOffset: number,
  timezone:string
}

const ThumbnailReference = ({
  onImageClick,
  onClickUpdate,
  pairItem,
  type,
  timezoneOffset,
  timezone
}: ThumbnailReferenceProps) => {
  const key = `${pairItem.referenceThumbnail.date}-${pairItem.referenceThumbnail.thumbnailId}`;
  return (
    <s.CameraThumbnail>
      <s.ThumbnailImage
        onClick={onImageClick}
      >
        {/* {isError && index === 1 && <s.ErrorBorder></s.ErrorBorder>} */}

        <CVVThumbnail
          key={key}
          errorStatus={
            pairItem !== undefined &&
              !isEmpty(pairItem.referenceThumbnail.errorStatus)
              ? `${pairItem.referenceThumbnail.errorStatus}`
              : undefined
          }
          videoLoss={false}
          thumbnailUrl={
            pairItem !== undefined &&
              !isEmpty(pairItem.referenceThumbnail.url)
              ? `${pairItem.referenceThumbnail.url}?v=${Date.now()}`
              : undefined
          }
        />
        {/* <img
          src={pairItem !== undefined && !isEmpty(pairItem.referenceThumbnail.url) ? pairItem.referenceThumbnail.url : WARNING_CIRCLE_ICON}
          alt="reference_thumbnail_night"
        /> */}
      </s.ThumbnailImage>
      <CvvCameraThumbnailReferenceInfo
        pairItem={pairItem}
        dayOrNight={type}
        onClickUpdate={onClickUpdate}
        timezoneOffset={timezoneOffset}
        timezone={timezone}
      />
    </s.CameraThumbnail>
  )
}



type ThumbnailEventProps = {
  pairItem: CVVPairItem,
  onImageClick: () => void,
  onClickCheckNow: () => Promise<void>,
  timezoneOffset: number,
  timezone :string
}

const ThumbnailEvent = ({
  onImageClick,
  onClickCheckNow,
  pairItem,
  timezoneOffset,
  timezone
}: ThumbnailEventProps) => {

  let isError = false;
  if (pairItem !== undefined) {
    isError = checkCVVError(pairItem.eventThumbnail);
  }
  const key = `${pairItem.eventThumbnail.date}-${pairItem.eventThumbnail.thumbnailId}`;

  // TODO: isAnalyzing 표시 
  
  return (
    <s.CameraThumbnail>
      <s.ThumbnailImage
        onClick={onImageClick}
      >
        {isError && <s.ErrorBorder></s.ErrorBorder>}
        {isError && (
          <s.ErrorWrapper>
            {cvvEvent.map((evt, index) => {
              if (pairItem.eventThumbnail[evt] === true) {
                return (
                  <SmallLabel label={evt} color={COLORS.RED} />
                );
              } else return null;
            })}
          </s.ErrorWrapper>
        )}
        <CVVThumbnail
          key={key}
          errorStatus={
            pairItem !== undefined &&
              !isEmpty(pairItem.eventThumbnail.errorStatus)
              ? `${pairItem.eventThumbnail.errorStatus}`
              : undefined
          }
          videoLoss={false}
          thumbnailUrl={
            pairItem !== undefined &&
              !isEmpty(pairItem.eventThumbnail.url)
              ? `${pairItem.eventThumbnail.url}?v=${Date.now()}`
              : undefined
          }
          isAnalyzing={pairItem.eventThumbnail.isAnalyzing}
        />
        {/* <img src={pairItem !== undefined && !isEmpty(pairItem.eventThumbnail.url) ? pairItem.eventThumbnail.url : WARNING_CIRCLE_ICON} alt="" /> */}
      </s.ThumbnailImage>
      <CvvCameraThumbnailEventInfo
        pairItem={pairItem}
        onClickCheckNow={onClickCheckNow}
        timezoneOffset={timezoneOffset}
        timezone={timezone}
      />
    </s.CameraThumbnail>
  )
}
