import { components, GroupBase, OptionProps, StylesConfig } from "react-select";
import SelectsCombo, {
  customComboStyles,
  SelectComboProps,
} from "./SelectCombo";
import { ComponentType, useState } from "react";
import DwPermissionCheckList from "pages/users/add/group/steps/DwPermissionCheckList";
import { UserGroupUserPermission } from "api/interfaces/userInterface.interface";
import styled from "styled-components";

const initialDisabledList: UserGroupUserPermission = {
  enableAllPermissions: true,
  manageAlertRules: true,
  viewAlerts: true,
  manageUsersAndGroups: true,
  viewUsersAndGroups: true,
  manageReportRules: true,
  viewReports: true,
  manageRecordersAndGroups: true,
  viewRecordersAndGroups: true,
  manageAccount: true,
  viewAccount: true,
  view: true,
  manageBillingAndLicenses: true,
  enableDelete: true,
  managePlan: true,
  viewBilling: true,
  suspend: true,
  myOrganization: true,
  videoGuideSetting: true,
  techSupport: true,
  siteNotice: true,
  emails: true,
};

const styles: StylesConfig<any, true, GroupBase<any>> = {
  ...customComboStyles,
  menu: (provided) => ({ ...provided, overflow: "visible" }),
  menuList: (provided) => ({ ...provided, overflow: "visible" }),
};

const CustomOption = (props: OptionProps<any, true, GroupBase<any>>) => {
  const { data } = props;

  const [popupPosition, setPopupPosition] = useState<{
    top: number;
    left: number;
  } | null>(null);

  const handleMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setPopupPosition({
      top: rect.top + window.scrollY,
      left: rect.right + 8,
    });
  };

  const handleMouseLeave = () => {
    setPopupPosition(null);
  };

  if (data.userPermission)
    return (
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{ position: "relative" }}
      >
        <components.Option {...props} />
        {popupPosition && (
          <Popup top={popupPosition.top} left={popupPosition.left}>
            <DwPermissionCheckList
              userCheckedList={data.userPermission}
              userDisabledList={initialDisabledList}
              onChangeDwPermission={() => null}
            />
          </Popup>
        )}
      </div>
    );

  return <components.Option {...props} />;
};

const UserGroupSelectCombo = (props: SelectComboProps) => {
  return (
    <SelectsCombo
      {...props}
      placeholder="Select Groups"
      styles={styles as any}
      components={{
        Option: CustomOption as any,
      }}
    />
  );
};

const Popup = styled.div<{ top: number; left: number }>`
  position: fixed;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  background-color: ${({ theme }) => theme.COLORS.PRIMARY2};
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
`;

export default UserGroupSelectCombo;
