import { BasePagination } from "api/interfaces/commonInterface.interface";
import { TechSupportDto } from "api/interfaces/managerInterface.interface";
import { ClickSearch } from "components/atoms/input/search/SearchInput";
import { TableTemplate } from "components/templates/default/table/TableTemplate";
import Status, { StatusType } from "components/blocks/status/Status";
import ViewSupport from "./edit";
import { TableColumnsType } from "antd";
import { CommonTable } from "components/atoms/table/AntdTable";

type Props = {
  data: TechSupportDto[];
  selectedData: TechSupportDto;
  columns: TableColumnsType<TechSupportDto>;
  onModal: () => void;
  paginationInfo: BasePagination;
  onClickSearch: ClickSearch;
  onChangePage: (page: number, totalRows: number) => void;
  status: StatusType;
  showPopup: boolean;
  onRowClick: (row: TechSupportDto) => void;
  tablePending: boolean;
};
export function TroubleManagerPresenter(props: Props): JSX.Element {
  return (
    <TableTemplate
      onClickSearch={props.onClickSearch}
      centerChildren={<Status status={props.status} />}
      keyword={props.paginationInfo.keyword}
    >
      <CommonTable
        columns={props.columns}
        dataSource={props.data}
        queryInfo={props.paginationInfo}
        onChangePage={props.onChangePage}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              props.onRowClick(record);
            },
          };
        }}
        loading={props.tablePending}
      />

      {props.showPopup && (
        <ViewSupport
          isEdit={true}
          selectedData={props.selectedData}
          onModal={props.onModal}
          onClickSave={() => {}}
        />
      )}
    </TableTemplate>
  );
}
