import { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import * as s from "styles/ModalCommonStyled";
import { useIntl } from "react-intl";
import DebounceSearchInput from "components/atoms/input/search/DebounceSearchInput";
import React from "react";
import { getAlertRuleAccountList } from "api/alertAPI";
import {
  AlertRuleAccountInfo,
  AlertRuleAccountListResponse,
} from "api/interfaces/alertInterface.interface";
import { useAppSelector } from "redux/hooks";
import { COLORS } from "styles/colors";
import { orderBy } from "lodash";
import { TableColumnsType } from "antd";
import { VirtualTable } from "components/atoms/table/AntdVirtualTable";
import { matchStringSearchKeyword } from "utils/functions";
import { TableRowSelection } from "antd/es/table/interface";

export type SearchAlertAccountProps = {
  accountId: string;
  placeholder?: string;
  selectedAccountId?: string[];
  disabledAccountId?: string[];
  isSingleMode?: boolean;
  onSelectedRowsChange?: (selectedRows: string[]) => void;
  disabled?: boolean;
  allSelect?: boolean;
};

export interface DataRow {
  id: string;
  name: string;
  level: string;
}

export interface ModalAccount {
  key: string;
  name: string;
  level: string;
  subRows?: ModalAccount[];
}

export function SearchAlertAccount(
  props: SearchAlertAccountProps
): JSX.Element {
  const intl = useIntl();

  const theme = useAppSelector((state) => state.theme.theme);
  const caretColor = theme === "light" ? COLORS.BLACK : COLORS.WHITE;

  const [accounts, setAccounts] = useState<ModalAccount[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>(
    props.selectedAccountId ?? []
  );
  const [pending, setPending] = useState<boolean>(true);

  const columns = React.useMemo<TableColumnsType<ModalAccount>>(
    () => [
      {
        dataIndex: "name",
        title: "Name",
        render: (value) => value,
      },
      {
        dataIndex: "level",
        title: intl.formatMessage({
          id: "table.label.level",
          defaultMessage: "Level",
        }),
        size: 80,
      },
    ],
    []
  );

  const { isLoading, refetch } = useQuery(
    ["account", props.accountId],
    () =>
      getAlertRuleAccountList({
        accountId: props.accountId,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: AlertRuleAccountListResponse) => {
        if (res.result === undefined) {
          setAccounts([]);
          return;
        } else {
          const totalList: ModalAccount[] = [];

          res.result.forEach((data: AlertRuleAccountInfo, index: number) => {
            if (data.subRow === undefined) {
              const model = {
                key: data.accountId.concat(`_`, data.level),
                name: data.name,
                level: data.level,
              } as ModalAccount;
              totalList.push(model);
            } else {
              const model = {
                key: data.accountId.concat(`_`, data.level),
                name: data.name,
                level: data.level,
                subRows: data.subRow?.map(
                  (item: AlertRuleAccountInfo, index2: number) => {
                    return {
                      key: item.accountId,
                      name: item.name,
                      level: item.level,
                    };
                  }
                ),
              } as ModalAccount;
              totalList.push(model);
            }
          });
          setAccounts(totalList);
        }
      },
      onError: (e: any) => {
        setAccounts([]);
      },
      onSettled: () => {
        setPending(false);
      },
    }
  );

  useEffect(() => {
    if (props.allSelect) {
      let checkedRecorder: string[] = accounts.map(
        (account) => account.key as string
      );
      if (props.onSelectedRowsChange) {
        props.onSelectedRowsChange(checkedRecorder);
      }
    }
  }, [props.allSelect, isLoading]);

  useEffect(() => {
    if (props.selectedAccountId) {
      setSelectedRowKeys(props.selectedAccountId);
    }
  }, [props.selectedAccountId]);

  const onClickSearch = (keyword: string) => {
    setSearchText(keyword);
    // setAccountQueryInfo((info) => {
    //   return { ...info, keyword: keyword as string };
    // });
    // refetch();
  };

  const onChangeSearchText = useCallback((value: string) => {
    // setSearchText(value);
  }, []);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    if (props.onSelectedRowsChange)
      props.onSelectedRowsChange(newSelectedRowKeys as string[]);
  };

  const rowSelection: TableRowSelection<ModalAccount> = {
    columnWidth: 40,
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const data: ModalAccount[] = useMemo(() => {
    return accounts.filter((rec) => {
      return matchStringSearchKeyword(rec.name, searchText);
    });
  }, [searchText, accounts]);

  return (
    <>
      <DebounceSearchInput
        placeholder={props.placeholder}
        onClickSearch={onClickSearch}
        onChange={onChangeSearchText}
      />
      <s.ModalTableContainer>
        <VirtualTable
          columns={columns}
          dataSource={data}
          rowSelection={rowSelection}
          loading={pending}
        />
      </s.ModalTableContainer>
    </>
  );
}
