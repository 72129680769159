import { RecorderHealthDetailRow } from "api/interfaces/reportInterface.interface";
import styled from "styled-components";
import { FLEX } from "styles/flex";
import * as mediaQuery from "components/MediaQuery";
import WebAppLink from "components/blocks/link/WebAppLink";
import { LEVEL_TYPE } from "api/interfaces/commonInterface.interface";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { RecorderDetail } from "../HealthDetail";
import AccountId from "components/atoms/text/labels/AccountId";
import { getSwitchAccountInfo } from "utils/AccountUtil";
import { navigateSwitchAccount } from "utils/AuthUtil";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { useAuth } from "components/Auth";

type Props = {
  items: Array<RecorderHealthDetailRow>;
  recorderInfo: RecorderDetail;
};

export default function HealthHeader(props: Props): JSX.Element {
  //BUG [RND-199] web/app link at health report
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const intl = useIntl();
  const auth = useAuth();
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const onClickAccount = (accountId: string | undefined) => {
    if (!accountId) {
      return;
    }

    getSwitchAccountInfo(accountId).then(function (selectAccount) {
      if (!!selectAccount) {
        navigateSwitchAccount({
          selectAccount,
          auth,
          navigate,
          dispatch,
          intl,
        });
      }
    });
  };
  return (
    <Container>
      {selectedAccount.accountLevel === LEVEL_TYPE.CP &&
        props.recorderInfo.accountNumber && (
          <Item>
            <span className="label">Organization</span>
            <span className="value account-id-wrapper">
              {props.recorderInfo.accountName}

              <div
                className="account-id"
                onClick={() => onClickAccount(props.recorderInfo.accountId)}
              >
                <AccountId
                  level={LEVEL_TYPE.EU}
                  accountId={props.recorderInfo.accountNumber}
                />
              </div>
            </span>
          </Item>
        )}
      {props.items.map((item, idx) => (
        <Item key={`health-header-${idx}`}>
          <span className="label">{item.label}</span>
          <span className="value">{item.value}</span>
        </Item>
      ))}
      <Row>
        <WebAppLink
          {...props.recorderInfo}
          accountId={selectedAccount.accountId}
        />
      </Row>
    </Container>
  );
}

const Container = styled.ul`
  border-radius: 5px;
  padding: 15px 20px;
  width: 100%;
  grid-area: header;

  ${mediaQuery.isDefault} {
    background-color: ${({ theme }) => theme.COLORS.PRIMARY3}50;
    ${FLEX.FlexStartStart};
    gap: 50px;
  }
  ${mediaQuery.isMobile} {
    background-color: ${({ theme }) => theme.COLORS.PRIMARY3};
    ${FLEX.FlexStartStart};
    flex-direction: column;
    gap: 20px;
  }
`;

const Item = styled.li`
  ${FLEX.FlexStartStart};
  .label {
    font-weight: 500;
  }

  ${mediaQuery.isDefault} {
    gap: 8px;
    flex-direction: column;
    color: ${({ theme }) => theme.COLORS.WHITE};

    .label {
      color: ${({ theme }) => theme.COLORS.BUTTONS1};
    }
    .account-id-wrapper {
      ${FLEX.FlexStartStart};
      gap: 4px;

      .account-id {
        cursor: pointer;
      }
    }
  }
  ${mediaQuery.isMobile} {
    width: 100%;
    gap: 18px;
    color: #828b9b;
    .label {
      flex: 1;
      color: ${({ theme }) => theme.COLORS.WHITE};
    }
    .value {
      flex: 2;
    }
  }
`;
const Row = styled.div`
  ${FLEX.FlexStartCenter};
  gap: 7px;
`;
