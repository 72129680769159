import { TableTemplate } from "components/templates/default/table/TableTemplate";
import {
  CPReport,
  CPReportDataRow,
  CVVReport,
  InstallReport,
  ReportDataRow,
  ThumbnailReport,
} from "api/interfaces/reportInterface.interface";
import Status, { StatusType } from "components/blocks/status/Status";
import AddReport from "./add";
import {
  HealthReport,
  InventoryReport,
} from "api/interfaces/reportInterface.interface";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { ClickSearch } from "components/atoms/input/search/SearchInput";
import { useAppSelector } from "redux/hooks";
import { BasePagination } from "api/interfaces/commonInterface.interface";
import { CommonTable } from "components/atoms/table/AntdTable";
import { TableColumnsType } from "antd";

type Props = {
  columns: TableColumnsType<ReportDataRow>;
  cpColumns: TableColumnsType<CPReportDataRow>;
  data: ReportDataRow[] | CPReportDataRow[];
  onModal: (type?: string) => void;
  isAddModal: boolean;
  isEditModal: boolean;
  status: StatusType;
  onRowClick: (row: ReportDataRow | CPReportDataRow) => void;
  selectReport?: number;
  selectReportRow?: ReportDataRow | CPReportDataRow;
  selectData?:
    | HealthReport
    | InventoryReport
    | InstallReport
    | CVVReport
    | ThumbnailReport
    | CPReport;
  selectedAccount: ProfileAccountInfo;
  onChangePage: (page: number, totalRows: number) => void;
  onClickSearch: ClickSearch;
  isRowClickEditable: boolean;
  tablePending: boolean;
  paginationInfo: BasePagination;
};

export default function ReportPresenter(props: Props): JSX.Element {
  const centerChildren = <Status status={props.status} />;
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  return (
    <>
      <TableTemplate
        onClickBtn={() => props.onModal("add")}
        buttonLabel="Add Report"
        centerChildren={centerChildren}
        onClickSearch={props.onClickSearch}
        keyword={props.paginationInfo.keyword}
        requiredPermissions={"manageReportRules"}
      >
        <CommonTable
          columns={
            selectedAccount.accountLevel === "EU"
              ? props.columns.filter((column) => column.key !== "accountName")
              : props.cpColumns
          }
          dataSource={props.data}
          queryInfo={props.paginationInfo}
          onChangePage={props.onChangePage}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                props.onRowClick(record);
              },
            };
          }}
          loading={props.tablePending}
          rowClassName={(record) => {
            return record.isDefault && "default-rule-row";
          }}
        />
      </TableTemplate>
      {props.isAddModal && <AddReport onModal={() => props.onModal("add")} />}

      {props.isEditModal && (
        <AddReport
          reportRow={props.selectReportRow}
          data={props.selectData}
          onModal={() => props.onModal("edit")}
          isEdit
        />
      )}
    </>
  );
}
