import {
  RoutesProps,
  createSearchParams,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";

import { setMenu } from "redux/reducers/menu/menuSlice";
import * as s from "./SideNavBarStyled";
import { ReactComponent as AccountSVG } from "assets/icons/sidenav/account.svg";
import { ReactComponent as RecorderSVG } from "assets/icons/sidenav/recorder.svg";
import { ReactComponent as AlertSVG } from "assets/icons/sidenav/alerts.svg";
import { ReactComponent as VideoSVG } from "assets/icons/sidenav/videoshare.svg";
import { ReactComponent as BillingSVG } from "assets/icons/sidenav/billing.svg";
import { ReactComponent as ReportSVG } from "assets/icons/sidenav/reports.svg";
import { ReactComponent as UserSVG } from "assets/icons/sidenav/users.svg";
import { ReactComponent as ManageSVG } from "assets/icons/sidenav/settings.svg";
import * as mediaQuery from "components/MediaQuery";

import {
  LEVEL_TYPE,
  ProfileAccountInfo,
} from "api/interfaces/accountInterface.interface";
import { off } from "process";
import {
  menuClasses,
  Menu,
  MenuItem,
  MenuItemStyles,
  useProSidebar,
} from "react-pro-sidebar";
import { Fragment, useEffect, useState } from "react";
import { calcRem } from "styles/theme";
import { COLORS } from "styles/colors";
import { Text } from "components/atoms/text/Text";
import RBACWrapper from "../function/RBACWrapper";
import { useAuth } from "components/Auth";
import { checkTrialShowMenu } from "utils/AccountUtil";
import { Tooltip } from "antd";
import { initializeMenuList, MenuList } from "utils/MenuUtil";

type SideBarProps = {
  isCollapsed?: boolean;
  onOpen?: () => void;
};

const themes = {
  light: {
    menu: {
      hover: {
        color: "#44596e",
      },
      disabled: {
        color: "#9fb6cf",
      },
    },
  },
  dark: {
    menu: {
      // menuContent: "#082440",
      // icon: COLORS.COMPANY,
      hover: {
        color: COLORS.WHITE,
      },
      disabled: {
        color: `${COLORS.WHITE}20`,
      },
    },
  },
};

function MenuComponent({ isCollapsed, onOpen }: SideBarProps): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location: any = useLocation();
  const auth = useAuth();

  const theme = useAppSelector((state) => state.theme.theme);
  const [isSwitchAccount, SetIsSwitchAccount] = useState<boolean>(false);
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const { collapsed } = useProSidebar();

  const [lists, setLists] = useState<MenuList[]>(initializeMenuList);

  useEffect(() => {
    let state = (location?.state?.isSwitchMenu as boolean) || undefined;
    const newList = lists.map((list) => {
      // console.log(selectedAccount.accountLevel, list, state, "--");
      if (selectedAccount.accountLevel === LEVEL_TYPE.EU) {
        if (checkTrialShowMenu(selectedAccount)) {
          if (
            list.routeName.includes("/reports") ||
            list.routeName.includes("/videoShare") ||
            list.routeName.includes("/billing") ||
            list.routeName.includes("/alert")
          ) {
            return {
              ...list,
              hidden: false,
              disabled: true,
            };
          } else return { ...list, hidden: false, disabled: false };
        } else {
          if (list.routeName.includes("/account")) {
            return {
              ...list,
              hidden: state !== undefined && state === true ? false : true,
            };
          } else if (list.routeName.includes("/recorder")) {
            return {
              ...list,
              hidden: state !== undefined && state === true ? true : false,
            };
          } else if (list.routeName.includes("/videoShare")) {
            return {
              ...list,
              hidden: selectedAccount.isHidden ? true : false,
              disabled: false,
            };
          } else return { ...list, hidden: false, disabled: false };
        }
      } else if (selectedAccount.accountLevel === LEVEL_TYPE.CP && selectedAccount.isSuspended) {
        if(list.routeName.includes("/billing")){
          return { ...list, hidden: false, disabled: false }
        }else{
          return { ...list, hidden: false, disabled: true }
        }
      } else {
        if (
          list.routeName.includes("/recorder") ||
          list.routeName.includes("/videoShare")
        ) {
          return {
            ...list,
            hidden: true,
            disabled: false,
          };
        } else if (list.routeName.includes("/manager")) {
          if (selectedAccount.accountLevel === LEVEL_TYPE.DW) {
            return {
              ...list,
              hidden: false,
              disabled: false,
            };
          } else {
            return {
              ...list,
              hidden: true,
              disabled: false,
            };
          }
        } else if (
          list.routeName.includes("/alert") ||
          list.routeName.includes("/reports")
        ) {
          if (selectedAccount.accountLevel !== LEVEL_TYPE.DW) {
            return {
              ...list,
              hidden: false,
              disabled: false,
            };
          } else {
            return {
              ...list,
              hidden: true,
              disabled: false,
            };
          }
        } else {
          return { ...list, hidden: false, disabled: false };
        }
      }
    });
    setLists(newList);
    SetIsSwitchAccount(state !== undefined && state === true ? true : false);
  }, [location, selectedAccount]);

  const menuItemStyles: MenuItemStyles = {
    root: {
      fontSize: calcRem(14),
      height: 58,
      color: COLORS.SIDEBARMENU,

      "&:hover": {
        backgroundColor: "transparent",
      },
      ".ps-menu-label": {
        fontWeight: 500,
      },
    },
    icon: {
      svg: {
        width: "24px",
      },
    },
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
      ".ps-active": {
        color: `${COLORS.COMPANY} !important`,
      },
      "&:hover": {
        ".menu-item-label": {
          color: `${themes[theme].menu.hover.color} !important`,
        },
        svg: {
          color: `${themes[theme].menu.hover.color} !important`,
        },
        backgroundColor: "transparent",
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  return (
    <>
      <mediaQuery.Default>
        <Menu menuItemStyles={menuItemStyles} rootStyles={{ flex: "1 1 0%" }}>
          {lists.map((item, idx) => {
            let currentLink = location.pathname.includes(
              item.includeRouteName ? item.includeRouteName : item.routeName
            );

            return !collapsed ? (
              <Fragment key={idx}>
                {item.hidden !== true && (
                  <RBACWrapper requiredPermissions={item.permission}>
                    <MenuItem
                      key={item.label}
                      icon={item.svg}
                      active={currentLink}
                      disabled={
                        (isSwitchAccount &&
                          item.routeName.includes("/account")) ||
                        item.disabled
                      }
                      onClick={() => {
                        dispatch(setMenu(item.label));
                        if (
                          item.routeName.includes("/account") ||
                          item.routeName.includes("/billing") ||
                          item.routeName.includes("/recorders/list")
                        ) {
                          navigate({
                            pathname: item.routeName,
                            search: `?${createSearchParams({
                              account: selectedAccount.accountId,
                            })}`,
                          });
                        } else {
                          navigate({
                            pathname: item.routeName,
                            search: `?${createSearchParams({
                              account: selectedAccount.accountId,
                            })}`,
                          });
                        }
                      }}
                    >
                      <Text
                        className="menu-item-label"
                        fontSize={14}
                        bold
                        color={
                          currentLink ? COLORS.COMPANY : COLORS.SIDEBARMENU
                        }
                      >
                        {item.label}
                      </Text>
                    </MenuItem>
                  </RBACWrapper>
                )}
              </Fragment>
            ) : (
              <Tooltip
                placement="right"
                title={
                  <>
                    <div>{item.label}</div>
                    {selectedAccount.licenseType === "none" &&
                      item.disabled && (
                        <div style={{ color: "red", fontWeight: "bold" }}>
                          *License required
                        </div>
                      )}
                  </>
                }
                key={idx}
                color={"#f0f0f0"}
                arrow={false}
                overlayInnerStyle={{
                  fontSize: 10,
                  minHeight: 0,
                  color: COLORS.PRIMARY1,
                  marginBottom: 16,
                  marginLeft: -20,
                }}
              >
                <Fragment key={idx}>
                  {item.hidden !== true && (
                    <RBACWrapper requiredPermissions={item.permission}>
                      <MenuItem
                        key={item.label}
                        icon={item.svg}
                        active={currentLink}
                        disabled={
                          (isSwitchAccount &&
                            item.routeName.includes("/account")) ||
                          item.disabled
                        }
                        onClick={() => {
                          dispatch(setMenu(item.label));
                          if (
                            item.routeName.includes("/account") ||
                            item.routeName.includes("/billing") ||
                            item.routeName.includes("/recorders/list")
                          ) {
                            navigate({
                              pathname: item.routeName,
                              search: `?${createSearchParams({
                                account: selectedAccount.accountId,
                              })}`,
                            });
                          } else {
                            navigate({
                              pathname: item.routeName,
                              search: `?${createSearchParams({
                                account: selectedAccount.accountId,
                              })}`,
                            });
                          }
                        }}
                      >
                        <Text
                          className="menu-item-label"
                          fontSize={14}
                          bold
                          color={
                            currentLink ? COLORS.COMPANY : COLORS.SIDEBARMENU
                          }
                        >
                          {item.label}
                        </Text>
                      </MenuItem>
                    </RBACWrapper>
                  )}
                </Fragment>
              </Tooltip>
            );
          })}
        </Menu>
      </mediaQuery.Default>
      <mediaQuery.Mobile>
        <s.MenuContainer>
          {lists.map((item, idx) => {
            let currentLink = location.pathname.includes(
              item.includeRouteName ? item.includeRouteName : item.routeName
            );
            return (
              item.hidden !== true && (
                <RBACWrapper requiredPermissions={item.permission} key={idx}>
                  <s.MenuItem
                    key={item.label}
                    isCollapsed={isCollapsed ? isCollapsed : false}
                    disabled={
                      (isSwitchAccount &&
                        item.routeName.includes("/account")) ||
                      item.disabled
                    }
                    onClick={() => {
                      if (
                        (isSwitchAccount &&
                          item.routeName.includes("/account")) ||
                        item.disabled
                      )
                        return;

                      dispatch(setMenu(item.label));
                      if (
                        item.routeName.includes("/account") ||
                        item.routeName.includes("/billing") ||
                        item.routeName.includes("/recorders/list")
                      ) {
                        navigate({
                          pathname: item.routeName,
                          search: `?${createSearchParams({
                            account: selectedAccount.accountId,
                          })}`,
                        });
                      } else {
                        navigate({
                          pathname: item.routeName,
                          search: `?${createSearchParams({
                            account: selectedAccount.accountId,
                          })}`,
                        });
                      }
                      // dispatch(
                      //   clearPushBreadcrumbRoute({
                      //     name: selectedAccount.accountName,
                      //     routeName: item.routeName,
                      //     accountId: selectedAccount.accountId,
                      //   })
                      // );
                      onOpen && onOpen();
                    }}
                    isCurrent={currentLink}
                  >
                    <s.MenuSVG isCollapsed={isCollapsed}>{item.svg}</s.MenuSVG>
                    {isCollapsed && <s.MenuLabel>{item.label}</s.MenuLabel>}
                  </s.MenuItem>
                </RBACWrapper>
              )
            );
          })}
        </s.MenuContainer>
      </mediaQuery.Mobile>
    </>
  );
}

export default MenuComponent;
