import styled from "styled-components";
import { FLEX } from "styles/flex";
import TableButton from "components/atoms/buttons/TableButton";
import UploadInput from "components/atoms/input/upload/UploadInput";
import { Text } from "components/atoms/text/Text";
import { COLORS, LIGHT_THEME_COLORS } from "styles/colors";
import { ForwardedRef, forwardRef } from "react";

interface MapUploadProps {
  onUpload: (e?: any) => void;
  onDelete: () => void;
  ref?: ForwardedRef<any>;
  value?: any;
  isDeleteDisabled?: boolean;
}

const MapUpload = forwardRef(
  (
    { onUpload, onDelete, isDeleteDisabled }: MapUploadProps,
    ref: ForwardedRef<any>
  ): JSX.Element => {
    return (
      <Wrapper>
        <Row>
          <UploadInput
            type="file"
            id="files"
            accept="image/png, image/jpg, image/jpeg"
            onChange={onUpload}
            ref={ref}
          />
          <p>
            <Text bold fontSize={14}>
              Upload
            </Text>
          </p>
        </Row>
        <TableButton
          buttonType="warning"
          label="Delete"
          onClick={onDelete}
          disabled={isDeleteDisabled}
        />
      </Wrapper>
    );
  }
);

const Wrapper = styled.div`
  ${FLEX.FlexStartCenter};
  width: 100%;
  gap: 10px;
  button,
  p {
    padding: 10px;
  }
`;

const Row = styled.label`
  ${FLEX.FlexBetweenCenter};
  gap: 10px;

  > p {
    background-color: ${({ theme }) =>
      theme.value === "light"
        ? LIGHT_THEME_COLORS.LIGHTGRAYBORDER
        : COLORS.BUTTONS1};
    border-radius: 4px;
    cursor: pointer;
  }
`;

export const MapUploadImage = styled.div`
  width: 100%;
  height: 200px;
  border: 1px solid #cacaca;
  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export default MapUpload;
