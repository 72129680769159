import TableButton from "components/atoms/buttons/TableButton";
import Type from "components/atoms/text/labels/Type";
import HealthPresenter from "./HealthPresenter";
import {
  RecorderHealthRow,
  ReportHealthList,
} from "api/interfaces/reportInterface.interface";
import { ReportDataRow } from "api/interfaces/reportInterface.interface";
import * as s from "./HealthStyled";
import {
  checkStatus,
  convertType,
  formatBytesFromMB,
  isFullStorage,
} from "utils/functions";
import TableTitle from "components/blocks/table/TableTitle";
import Status from "components/atoms/text/labels/Status";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  BasePagination,
  LEVEL_TYPE,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { getHealthMergedReportList, getHealthReportList } from "api/reportAPI";
import { useQuery } from "react-query";
// import { healthTableData } from "pages/reports/tempData";
import InfoBubble from "components/blocks/bubble/InfoBubble";
import WebAppLink from "components/blocks/link/WebAppLink";
import { notify } from "components/atoms/notification/Notification";
import { useIntl } from "react-intl";
import { isNull } from "lodash";
import { queryNavigate } from "utils/MenuUtil";
import { AccountBox } from "pages/alert/AlertStyled";
import AccountId from "components/atoms/text/labels/AccountId";
import { getSwitchAccountInfo } from "utils/AccountUtil";
import { navigateSwitchAccount } from "utils/AuthUtil";
import { useAuth } from "components/Auth";
import { TableColumnsType } from "antd";
import {
  changeSortOrderAntd,
  onSortAntd,
} from "components/atoms/table/AntdTable";
import { ColumnType } from "antd/es/table";

type Props = {
  state: ReportDataRow;
};

interface AlertTotalCount {
  [key: string]: number;
}

const initialReportQueryInfo = {
  total: 0,
  pageNum: 0,
  pageLimit: 20,
  keyword: "",
  sortType: "name",
  sortDirection: SORT_DIRECTION.ASC,
};

export default function HealthContainer(props: Props): JSX.Element {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const params = useParams();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const isMerged: boolean = location.pathname.includes("merged");

  const reportId = params.reportId as string;
  const mergedSystemId = params.mergedSystemId as string;

  const [reportQueryInfo, setReportQueryInfo] = useState<BasePagination>({
    ...initialReportQueryInfo,
  });

  const [reportMergedQueryInfo, setReportMergedQueryInfo] =
    useState<BasePagination>({
      ...initialReportQueryInfo,
    });

  useEffect(() => {
    // if (location.state) {
    //   SetHealthStateProps(location.state);
    // }

    const changeParam = new URLSearchParams(location.search);
    if (changeParam.size > 0) {
      if (!isMerged) {
        setReportQueryInfo((info) => {
          return {
            ...info,
            pageNum: isNull(changeParam.get("pageNum"))
              ? 0
              : Number(changeParam.get("pageNum")),
            pageLimit: isNull(changeParam.get("pageLimit"))
              ? 20
              : Number(changeParam.get("pageLimit")),
            keyword: isNull(changeParam.get("keyword"))
              ? ""
              : (changeParam.get("keyword") as string),
            sortType: isNull(changeParam.get("keyword"))
              ? "name"
              : (changeParam.get("sortType") as string),
            sortDirection: isNull(changeParam.get("sortDirection"))
              ? SORT_DIRECTION.ASC
              : (changeParam.get("sortDirection") as SORT_DIRECTION),
          };
        });
      } else {
        setReportMergedQueryInfo((info) => {
          return {
            ...info,
            pageNum: isNull(changeParam.get("pageNum"))
              ? 0
              : Number(changeParam.get("pageNum")),
            pageLimit: isNull(changeParam.get("pageLimit"))
              ? 20
              : Number(changeParam.get("pageLimit")),
            keyword: isNull(changeParam.get("keyword"))
              ? ""
              : (changeParam.get("keyword") as string),
            sortType: isNull(changeParam.get("keyword"))
              ? "name"
              : (changeParam.get("sortType") as string),
            sortDirection: isNull(changeParam.get("sortDirection"))
              ? SORT_DIRECTION.ASC
              : (changeParam.get("sortDirection") as SORT_DIRECTION),
          };
        });
      }
    } else {
      if (!isMerged) {
        setReportQueryInfo((info) => {
          return {
            ...info,
            pageNum: 0,
            pageLimit: 20,
            keyword: "",
            sortType: "name",
            sortDirection: SORT_DIRECTION.ASC,
          };
        });
      } else {
        setReportMergedQueryInfo((info) => {
          return {
            ...info,
            pageNum: 0,
            pageLimit: 20,
            keyword: "",
            sortType: "name",
            sortDirection: SORT_DIRECTION.ASC,
          };
        });
      }
    }
  }, [location]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [mergedState, setMergedState] = useState({
  //   mergedSystemId: healthStateProps.mergedSystemId,
  //   mergedSystemName: healthStateProps.mergedSystemName,
  //   reportId: healthStateProps.id,
  // });

  const [reportList, setReportList] = useState<RecorderHealthRow[]>([]);
  const [isMouseOver, setIsMouseOver] = useState<boolean>(false);
  const [mouseIndex, setMouseIndex] = useState<number | null>(null);
  const [reportTitle, setReportTitle] = useState<string>("");
  const [mergedSystemName, setMergedSystemName] = useState<string>("");
  // header 에 있는 상태 표시용 (healthy : healthy + warning / alert: alert + critical + assigned)
  const [totalCount, setTotalCount] = useState<AlertTotalCount>({
    total: 0,
    healthy: 0,
    alert: 0,
    online: 0,
    offline: 0,
  });

  const [alertDetail, setAlertDetail] = useState<AlertTotalCount>({
    system: 0,
    disk: 0,
    video: 0,
  });

  useEffect(() => {}, [location]);

  const onClickAccount = (accountId: string | undefined) => {
    if (!accountId) {
      return;
    }

    getSwitchAccountInfo(accountId).then(function (selectAccount) {
      if (!!selectAccount) {
        navigateSwitchAccount({
          selectAccount,
          auth,
          navigate,
          dispatch,
          intl,
        });
      }
    });
  };

  const onHeaderCell = (column: ColumnType<RecorderHealthRow>) => {
    return {
      onClick: () => {
        if (column.key) {
          if (isMerged) {
            setReportMergedQueryInfo((info) => {
              return {
                ...info,
                sortType: column.key as string,
                sortDirection: onSortAntd(info.sortDirection),
              };
            });
          } else {
            setReportQueryInfo((info) => {
              return {
                ...info,
                sortType: column.key as string,
                sortDirection: onSortAntd(info.sortDirection),
              };
            });
          }
        }
      },
    };
  };

  const onInitial = () => {
    setReportList([]);
    setReportTitle("");
    setTotalCount({
      total: 0,
      healthy: 0,
      alert: 0,
      online: 0,
      offline: 0,
    });
    setAlertDetail({
      system: 0,
      disk: 0,
      video: 0,
    });
  };

  // table column
  const columns: TableColumnsType<RecorderHealthRow> = [
    {
      title: "Type",
      render: (value, row) => <Type type={convertType(row)} />,
      width: 110,
    },
    {
      key: "name",
      title: "Title",
      sorter: true,
      sortOrder: changeSortOrderAntd(
        isMerged ? reportMergedQueryInfo : reportQueryInfo,
        "name"
      ),
      onHeaderCell: onHeaderCell,
      render: (row) => (
        <TableTitle
          report
          row={row}
          //onUnderlineClick={onUnderlineClick}
          onCellClick={() => onRowClicked(row)}
        />
      ),
      defaultSortOrder: "ascend",
    },

    {
      title: "",
      render: (value, row) =>
        !isMerged && (
          <s.Row
            // className="table-button-wrapper"
            onClick={(e) => e.stopPropagation()}
          >
            <WebAppLink {...row} accountId={selectedAccount.accountId} />
          </s.Row>
        ),
      align: "right",
      responsive: ["lg"],
      width: 100,
    },
    {
      key: "accountName",
      title: "Organization",
      render: (row) => {
        if (!row.accountNumber) {
          return;
        }
        return (
          <AccountBox>
            {row.accountName}
            <AccountId accountId={row.accountNumber} level={LEVEL_TYPE.EU} />
          </AccountBox>
        );
      },
    },
    {
      //name: <TableColumnFilterTitle title="Status" />,
      title: "Status",
      align: "center",
      render: (value, row, index) => (
        <s.StatusButton
          onMouseEnter={() => {
            setIsMouseOver(true);
            setMouseIndex(index);
          }}
          onMouseLeave={() => {
            setIsMouseOver(false);
            setMouseIndex(null);
          }}
        >
          <Status
            status={checkStatus(row.recorderCount || 1, row.onlineStatusCount)}
          />

          <s.StatusBubble>
            {isMouseOver && mouseIndex !== null && mouseIndex === index && (
              <InfoBubble>
                Online : {row.onlineStatusCount}/{row.recorderCount || 1}
              </InfoBubble>
            )}
          </s.StatusBubble>
        </s.StatusButton>
      ),
    },
    {
      title: "Local Storage",
      render: (value, row) =>
        row.recorderCount !== undefined && row.recorderCount > 1 ? (
          <></>
        ) : row.totalStorage !== 0 ? (
          <s.StorageText
            full={isFullStorage(
              row.totalStorage - row.freeStorage,
              row.totalStorage
            )}
          >
            {formatBytesFromMB(row.totalStorage - row.freeStorage)} /{" "}
            {formatBytesFromMB(row.totalStorage)}
          </s.StorageText>
        ) : (
          "N/A"
        ),
      responsive: ["xl"],
    },
    {
      title: "Cameras (Loss / Total)",
      render: (value, row) => (
        <s.CameraRow>
          <s.CameraItem>
            <span className="loss">{row.lossCamera}</span> /{" "}
            <span className="title">{row.totalCamera}</span>
          </s.CameraItem>
        </s.CameraRow>
      ),
      responsive: ["lg"],
      width: 180,
    },
    // 마지막 버튼
    {
      title: "",
      render: (value, row) =>
        row.recorderCount && row.recorderCount > 1 ? (
          <></>
        ) : (
          <span className="row-click-button table-button-wrapper">
            <TableButton label={"View"} onClick={() => onRowClicked(row)} />
          </span>
        ),
      align: "right",
      width: 100,
    },
  ];
  const onRowClicked = (row: RecorderHealthRow) => {
    if (row.recorderCount > 1) {
      navigate({
        pathname: `/reports/health/merged/${reportId}/${row.mergedSystemId}`,
        search: `?${createSearchParams({
          account: selectedAccount.accountId,
        })}`,
      });
    } else {
      navigate({
        pathname: `/reports/health/detail/${reportId}/${row.recorderId}`,
        search: `?${createSearchParams({
          account: selectedAccount.accountId,
        })}`,
      });
    }
  };

  const [pending, setPending] = useState<boolean>(true);

  const healthReportQuery = useQuery(
    ["healthList", reportQueryInfo, reportId, selectedAccount.accountId],
    () =>
      getHealthReportList({
        payload: reportQueryInfo,
        reportId: reportId as string,
        accountId: selectedAccount.accountId,
      }),
    {
      retry: 0,
      enabled:
        !isMerged && reportId !== undefined && selectedAccount.accountId !== "",
      onSuccess: (res: any) => {
        if (!res.result || !res.result.healthReports) {
          onInitial();
          setReportQueryInfo({
            ...initialReportQueryInfo,
          });
          return;
        }

        setReportTitle(res.result.title);
        setReportList(
          res.result.healthReports.map((report: ReportHealthList) => {
            return {
              ...report,
              title: report.mergedSystemName,
              version: report.firmwareVersion,
              status: "status",
              totalStorage: report.storages.reduce(function (
                prev: any,
                next: any
              ) {
                return prev + next.total;
              },
              0),
              freeStorage: report.storages.reduce(function (
                prev: any,
                next: any
              ) {
                return prev + next.free;
              },
              0),
              totalCamera: report.cameraCount,
              lossCamera: report.videoLoss,
            };
          })
        );
        setReportQueryInfo((info) => {
          return {
            ...info,
            ...res.page,
          };
        });

        setTotalCount((info) => {
          return {
            ...info,
            total: res.page.total,
            online: res.result.online,
            offline: res.result.offline,
            alert:
              res.result.systemAlerts +
              res.result.diskAlerts +
              res.result.videoLossAlerts,
          };
        });

        setAlertDetail({
          system: res.result.systemAlerts,
          disk: res.result.diskAlerts,
          video: res.result.videoLossAlerts,
        });
      },
      onError: (e: any) => {
        onInitial();
        setReportQueryInfo({
          ...initialReportQueryInfo,
        });
        notify(
          "error",
          intl.formatMessage({
            id: "label.report.notify.health.list.error",
            defaultMessage: "Health search error.",
          })
        );
      },
      onSettled: (e: any) => {
        setPending(false);
      },
    }
  );

  const healthReportMergedQuery = useQuery(
    ["healthMergedList", reportMergedQueryInfo, mergedSystemId],
    () =>
      getHealthMergedReportList({
        payload: reportMergedQueryInfo,
        reportId: reportId as string,
        mergedSystemId: mergedSystemId as string,
        accountId: selectedAccount.accountId,
      }),
    {
      retry: 0,
      enabled: isMerged,
      onSuccess: (res: any) => {
        if (!res.result) {
          onInitial();
          setReportMergedQueryInfo({
            ...initialReportQueryInfo,
          });
          return;
        }
        setReportTitle(res.result.title);
        setReportList(
          res.result.healthReports.map((report: any) => {
            setMergedSystemName(report.mergedSystemName);
            return {
              ...report,
              title: report.recorderName,
              version: report.firmwareVersion,
              status: "status",
              totalStorage: report.storages.reduce(function (
                prev: any,
                next: any
              ) {
                return prev + next.total;
              },
              0),
              freeStorage: report.storages.reduce(function (
                prev: any,
                next: any
              ) {
                return prev + next.free;
              },
              0),
              totalCamera: report.cameraCount,
              lossCamera: report.videoLoss,
            };
          })
        );
        setReportMergedQueryInfo((info) => {
          return {
            ...info,
            ...res.page,
          };
        });
        setTotalCount((info) => {
          return {
            ...info,
            total: res.page.total,
            online: res.result.online,
            offline: res.result.offline,
            alert:
              res.result.systemAlerts +
              res.result.diskAlerts +
              res.result.videoLossAlerts,
          };
        });
        setAlertDetail({
          system: res.result.systemAlerts,
          disk: res.result.diskAlerts,
          video: res.result.videoLossAlerts,
        });
      },
      onError: (e: any) => {
        onInitial();
        setReportMergedQueryInfo({
          ...initialReportQueryInfo,
        });
        notify(
          "error",
          intl.formatMessage({
            id: "label.report.notify.health.list.error",
            defaultMessage: "Health search error.",
          })
        );
      },
      onSettled: (e: any) => {
        setPending(false);
      },
    }
  );

  const onChangePage = useCallback(
    (page: number, totalRows: number) => {
      console.log(page, totalRows);
      if (!isMerged) {
        if (page !== reportQueryInfo.pageNum + 1) {
          queryNavigate(
            navigate,
            location.pathname,
            {
              pageLimit: reportQueryInfo.pageLimit,
              pageNum: page - 1,
              keyword: reportQueryInfo.keyword as string,
              sortType: reportQueryInfo.sortType,
              sortDirection: reportQueryInfo.sortDirection as SORT_DIRECTION,
            },
            selectedAccount
          );
          setPending(true);
          // setReportQueryInfo((info) => {
          //   return { ...info, pageNum: page as number };
          // });
        }
      } else {
        if (page !== reportMergedQueryInfo.pageNum + 1) {
          queryNavigate(
            navigate,
            location.pathname,
            {
              pageLimit: reportMergedQueryInfo.pageLimit,
              pageNum: page - 1,
              keyword: reportMergedQueryInfo.keyword as string,
              sortType: reportMergedQueryInfo.sortType,
              sortDirection:
                reportMergedQueryInfo.sortDirection as SORT_DIRECTION,
            },
            selectedAccount
          );
          setPending(true);
          // setReportMergedQueryInfo((info) => {
          //   return { ...info, pageNum: page as number };
          // });
        }
      }
    },
    [
      isMerged,
      location.pathname,
      navigate,
      reportMergedQueryInfo.keyword,
      reportMergedQueryInfo.pageLimit,
      reportMergedQueryInfo.pageNum,
      reportMergedQueryInfo.sortDirection,
      reportMergedQueryInfo.sortType,
      reportQueryInfo.keyword,
      reportQueryInfo.pageLimit,
      reportQueryInfo.pageNum,
      reportQueryInfo.sortDirection,
      reportQueryInfo.sortType,
      selectedAccount,
    ]
  );

  const onClickSearch = useCallback(
    (keyword: string) => {
      if (isMerged) {
        if (reportMergedQueryInfo.keyword !== keyword) {
          queryNavigate(
            navigate,
            location.pathname,
            {
              pageLimit: reportMergedQueryInfo.pageLimit,
              pageNum: 0,
              keyword: keyword,
              sortType: reportMergedQueryInfo.sortType,
              sortDirection:
                reportMergedQueryInfo.sortDirection as SORT_DIRECTION,
            },
            selectedAccount
          );
        } else {
          healthReportMergedQuery.refetch();
        }
        setPending(true);
        // setReportMergedQueryInfo((query) => {
        //   return {
        //     ...query,
        //     keyword,
        //   };
        // });
        // healthReportMergedQuery.refetch();
      } else {
        if (reportQueryInfo.keyword !== keyword) {
          queryNavigate(
            navigate,
            location.pathname,
            {
              pageLimit: reportQueryInfo.pageLimit,
              pageNum: 0,
              keyword: keyword,
              sortType: reportQueryInfo.sortType,
              sortDirection: reportQueryInfo.sortDirection as SORT_DIRECTION,
            },
            selectedAccount
          );
        } else {
          healthReportQuery.refetch();
        }
        setPending(true);
      }
    },
    [
      healthReportMergedQuery,
      healthReportQuery,
      isMerged,
      location.pathname,
      navigate,
      reportMergedQueryInfo.keyword,
      reportMergedQueryInfo.pageLimit,
      reportMergedQueryInfo.sortDirection,
      reportMergedQueryInfo.sortType,
      reportQueryInfo.keyword,
      reportQueryInfo.pageLimit,
      reportQueryInfo.sortDirection,
      reportQueryInfo.sortType,
      selectedAccount,
    ]
  );

  const col: TableColumnsType<RecorderHealthRow> = useMemo(() => {
    if (selectedAccount.accountLevel === LEVEL_TYPE.EU) {
      return columns.filter((col) => col.key !== "accountName");
    }
    return columns;
  }, [selectedAccount, columns]);

  return (
    <HealthPresenter
      selectedAccount={selectedAccount}
      title={reportTitle}
      columns={col}
      data={reportList}
      isMerged={isMerged}
      // mergedState={mergedState}
      reportId={reportId}
      mergedSystemName={mergedSystemName}
      totalCount={totalCount}
      alertDetail={alertDetail}
      onRowClicked={onRowClicked}
      onChangePage={onChangePage}
      onClickSearch={onClickSearch}
      paginationInfo={isMerged ? reportMergedQueryInfo : reportQueryInfo}
      tablePending={pending}
    />
  );
}
