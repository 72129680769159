import { TableTemplate } from "components/templates/default/table/TableTemplate";
import AddModal from "./add";
import { ClickSearch } from "components/atoms/input/search/SearchInput";
import { Notice, NoticeInfo } from "api/interfaces/noticeInterface.interface";
import {
  BasePagination,
  ValidateMsg,
} from "api/interfaces/commonInterface.interface";
import Status, { StatusType } from "components/blocks/status/Status";
import { TableColumnsType } from "antd";
import { CommonTable } from "components/atoms/table/AntdTable";

export type Props = {
  isAddModal: boolean;
  isEditModal: boolean;
  onModal: (type?: string) => void;
  columns: TableColumnsType<NoticeInfo>;
  data: Array<NoticeInfo>;
  onClickSearch: ClickSearch;
  onRowClicked: (row: NoticeInfo) => void;
  selectedNotice: NoticeInfo | undefined;
  onChangePage: (page: number, totalRows: number) => void;

  paginationInfo: BasePagination;
  status: StatusType;
  tablePending: boolean;
};

export function NoticePresenter({
  isAddModal,
  isEditModal,
  onModal,
  columns,
  data,
  selectedNotice,
  onClickSearch,
  onRowClicked,
  onChangePage,
  paginationInfo,
  status,
  tablePending,
}: Props): JSX.Element {
  return (
    <>
      <TableTemplate
        onClickBtn={() => onModal("add")}
        buttonLabel="Add Notice"
        onClickSearch={onClickSearch}
        keyword={paginationInfo.keyword}
        titleComponent="Site Notices"
        centerChildren={<Status status={status} />}
      >
        <CommonTable
          columns={columns}
          dataSource={data}
          queryInfo={paginationInfo}
          onChangePage={onChangePage}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                onRowClicked(record);
              },
            };
          }}
          loading={tablePending}
        />
      </TableTemplate>
      {isAddModal && <AddModal onModal={() => onModal("add")} />}
      {isEditModal && (
        <AddModal
          isEdit
          onModal={() => onModal("edit")}
          selectedNotice={selectedNotice}
        />
      )}
    </>
  );
}
