import { DashboardData } from "api/interfaces/dashboardInterface.interface";
import { ItemOneRecorder } from "./listInfoItem/ItemOneRecorder";
import { ItemMultiRecorder } from "./listInfoItem/ItemMultiRecorder";

interface InfoViewProps {
  system: DashboardData;
  onSelectRecorder: (system: DashboardData) => void;
}

export default function RecorderListInfoItem({
  system,
  onSelectRecorder,
}: InfoViewProps): JSX.Element {

  if (system.recorders.length === 1) {
    return <ItemOneRecorder
      system={system}
      onSelectRecorder={onSelectRecorder}
      />
  }
  return <ItemMultiRecorder
    system={system}
    onSelectRecorder={onSelectRecorder}
  />
}
