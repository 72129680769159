import styled from "styled-components";
import { ALERT_CHART_COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";

export const Item = styled.li<{ isMultiRecorders: boolean }>`
  ${FLEX.FlexBetweenStart};
  gap: 6px;
  color: ${({ theme }) => theme.COLORS.WHITE};
  white-space: nowrap;
  width: 100%;
  border-bottom: 1px solid #cacaca;
  padding: 8px 0;
  flex: 1;
  ${({ isMultiRecorders }) =>
    !isMultiRecorders &&
    `
      cursor: pointer;
    `}

  .left-info {
    ${FLEX.FlexStartStart};
    gap: 6px;
    flex: 1;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
  }

  .system-alert-marker {
    flex: 0 0 auto;
    width: 15px;
    img {
      width: 100%;
    }
  }

  .system-info {
    ${FLEX.FlexStartStart};
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    overflow: hidden;
  }

  .system-title {
    width: 100%;
    ${FLEX.FlexStartStart};
    gap: 4px;

    h5 {
      font-weight: 400;
      font-size: ${calcRem(16)};
      width: 100%;
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .spectrum-system-ul {
    width: 100%;
    ${FLEX.FlexStartStart};
    flex-direction: column;
    flex: 1;
    margin-top: 8px;
    overflow: hidden;

    li {
      width: 100%;
      ${FLEX.FlexBetweenCenter};
      flex: 1;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 2px;
      font-size: ${calcRem(12)};
      ${({ isMultiRecorders }) =>
    isMultiRecorders &&
    `
        cursor: pointer;
        `}

      .recorder-item-name {
        ${FLEX.FlexStartCenter};
        flex: 1;
        gap: 4px;
        width: 100%;

        span {
          overflow: hidden;
          text-overflow: ellipsis;
          flex: 1;
        }
      }
      
      .recorder-item-assignee {
        padding-left: 12px;
      }
    }
  }

  .web-app-link-wrapper {
    flex: 0 0 auto;
    .web-app-link {
      font-size: ${calcRem(11)};
    }
  }
`;
