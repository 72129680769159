import { useState, useEffect, useRef, useCallback } from "react";
import { mapOptions } from "./GoogleMapConfig";
import {
  DashboardAlertData,
  DashboardData,
  DashboardFilter,
  DashboardFilterAlertResponse,
  DashboardFilterRequest,
  DashboardFilterResult,
  KeywordSearchRecorder,
} from "api/interfaces/dashboardInterface.interface";
import { MAP_STATUS_COLORS } from "styles/colors";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  Cluster,
  ClusterStats,
  MarkerClusterer,
  Renderer,
  SuperClusterAlgorithm,
  onClusterClickHandler,
} from "@googlemaps/markerclusterer";
import {
  MapFilter,
  MapFilterKeyword,
  setKeyword,
} from "redux/reducers/map/mapFilter";
import HoverInfoView from "./sub/infoView/HoverInfoView";
import ReactDomServer from "react-dom/server";
import { GoogleMapPresenter } from "./GoogleMapPresenter";

import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { postFilterSearchRecorders } from "api/dashboardAPI";
import {
  setDashboardData,
  setDashboardDataInit,
} from "redux/reducers/map/dashboardData";
import { chain, compact, set } from "lodash";
import { useQuery, useQueryClient } from "react-query";
import { More } from "./sub/subheader/SubHeader";
import {
  AlertDetail,
  AlertViewInfo,
} from "api/interfaces/alertInterface.interface";
import { filteringRecorder } from "utils/DashboardUtil";
import { Theme } from "redux/reducers/theme/themeReducer";
import { useMediaQuery } from "react-responsive";
import * as mediaQuery from "components/MediaQuery";
import SingleMarker from "./sub/markers/SingleMarker";
import ClusterMarker from "./sub/markers/ClusterMarker";
import RecorderListInfoView from "./sub/infoView/RecorderListInfoView";
import RecorderInfoView from "./sub/infoView/RecorderInfoView";
import { ThemeProvider } from "styled-components";
import { dark, light } from "styles/theme";
import { Provider } from "react-redux";
import { store } from "redux/store";
import { IntlProvider } from "react-intl";
import { QueryClientProvider } from "react-query";
import { createRoot } from "react-dom/client";
import { enumRecorderStatus, getTopAlertStatus } from "./utils";

export interface clusterAlert {
  // recorderCount: number | 0;
  // status: number | 0;
  warningCount: number | 0;
  alertCount: number | 0;
  assignCount: number | 0;
  criticalCount: number | 0;
  alertType: AlertDetail;
  // thumbnail: string | undefined;
}

export type CustomMarker = google.maps.marker.AdvancedMarkerElement & {
  systems?: DashboardData[];
};

export const determineLableColor = (
  // status: number,
  warningCount: number,
  assignCount: number,
  alertCount: number,
  criticalCount: number
) => {
  let labelcolor = MAP_STATUS_COLORS.HEALTHY;

  if (warningCount > 0) {
    labelcolor = MAP_STATUS_COLORS.WARNING;
  }

  if (assignCount > 0) {
    labelcolor = MAP_STATUS_COLORS.ASSIGNED;
  }

  if (alertCount > 0) {
    labelcolor = MAP_STATUS_COLORS.ALERT;
  }
  if (criticalCount > 0) {
    labelcolor = MAP_STATUS_COLORS.CRITICAL;
  }
  return labelcolor;
};

export default function MapView(): JSX.Element {
  const isMobile = useMediaQuery({ maxWidth: mediaQuery.mobileMax });
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const mapFilter: MapFilter = useAppSelector((state) => state.mapFilter);
  const isNotMobile = useMediaQuery({ minWidth: mediaQuery.tabletMin });

  const theme: Theme = useAppSelector((state) => state.theme);

  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  // 로드뷰인지 확인
  const mapRef = useRef(null);
  const infoWindowRef = useRef<google.maps.InfoWindow | null>(null);
  const mapInstance = useRef<google.maps.Map>();
  const markerCluster = useRef<MarkerClusterer>();
  const rowRecorderList = useRef<DashboardFilterResult[]>();
  const isSearchMove = useRef<boolean>(false);
  const initDraw = useRef<boolean>(true);

  const dualModeRef = useRef<any>();

  const [isRoadview, setIsRoadview] = useState(true);
  const [markers, setMarkers] = useState<
    google.maps.marker.AdvancedMarkerElement[]
  >([]);

  const [recorders, setRecorders] = useState<DashboardData[]>([]);
  const [selectedMarker, setSelectedMarker] = useState<CustomMarker | null>(
    null
  );
  const [hoveredMarker, setHoveredMarker] = useState<CustomMarker[]>([]);

  // const [keyword, setKeyword] = useState<string>("");
  const [selectedAlert, setSelectedAlert] = useState<AlertViewInfo | null>(
    null
  );

  const [more, setMore] = useState<More>({
    types: false,
    recorders: false,
    alerts: false,
    recorderGroup: false,
  });
  const onSelectMoreFilter = (e: any) => {
    let {
      target: { checked, name },
    } = e;

    setMore({
      ...more,
      [name]: checked,
    });
  };

  const onDeleteMoreFilter = (name: string) => {
    setMore({
      ...more,
      [name]: false,
    });
  };
  const getInfoWindowContent = (systems: DashboardData[]) => {
    // console.log(systems);
    if (!systems || systems.length === 0) {
      return <></>;
    }
    if (systems.length > 1) {
      return (
        <RecorderListInfoView
          systems={systems}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onSelectRecorder={onSelectRecorder}
        />
      );
    }

    if (systems[0].type === "Spectrum" && systems[0].recorders.length > 1) {
      return (
        <RecorderListInfoView
          systems={systems}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onSelectRecorder={onSelectRecorder}
        />
      );
    }

    return (
      <RecorderInfoView
        system={systems[0]}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClickMarkerAlert={() => {
          isMobile && onClickMarkerAlert(systems);
        }}
      />
    );
  };

  const closeInfoWindowWithTimeout = useRef<NodeJS.Timeout>();

  const clusterRenderer: Renderer = {
    render: function (cluster: Cluster, stats: ClusterStats) {
      let totalRecorder = 0;
      let calculateAlertStatus: clusterAlert = {
        // recorderCount: 0,
        warningCount: 0,
        alertCount: 0,
        assignCount: 0,
        criticalCount: 0,
        alertType: {
          system: 0,
          disk: 0,
          video: 0,
        },
      };

      if (cluster.markers != null) {
        totalRecorder = cluster.markers.length;

        calculateAlertStatus = cluster.markers.reduce<clusterAlert>(
          (previousValue, currentValue, currentIndex) => {
            const subRecorder: DashboardData[] = (currentValue as any).data
              .systems;

            let warningCnt = 0;
            let alertCnt = 0;
            let assignCnt = 0;
            let criticalCnt = 0;
            let alertTypeCount = {
              system: 0,
              disk: 0,
              video: 0,
            };

            if (subRecorder !== undefined && subRecorder.length > 0) {
              subRecorder.forEach((value, index) => {
                if (
                  value.visible === undefined ||
                  (value.visible !== undefined && value.visible === true)
                ) {
                  warningCnt +=
                    value.warnings !== undefined ? value.warnings.length : 0;
                  alertCnt +=
                    value.alerts !== undefined ? value.alerts.length : 0;
                  assignCnt +=
                    value.assign !== undefined ? value.assign.length : 0;
                  criticalCnt +=
                    value.critical !== undefined ? value.critical.length : 0;
                  alertTypeCount.system += value.system;
                  alertTypeCount.disk += value.disk;
                  alertTypeCount.video += value.video;
                }
              });
            }

            return {
              alertCount: previousValue.alertCount + alertCnt,
              warningCount: previousValue.warningCount + warningCnt,
              assignCount: previousValue.assignCount + assignCnt,
              criticalCount: previousValue.criticalCount + criticalCnt,
              alertType: {
                system: previousValue.alertType.system + alertTypeCount.system,
                disk: previousValue.alertType.disk + alertTypeCount.disk,
                video: previousValue.alertType.video + alertTypeCount.video,
              },
            };
          },
          {
            alertCount: 0,
            warningCount: 0,
            assignCount: 0,
            criticalCount: 0,
            alertType: {
              system: 0,
              disk: 0,
              video: 0,
            },
          }
        );
      }

      const tempClusterContent = ReactDomServer.renderToString(
        <ClusterMarker
          warningCount={calculateAlertStatus.warningCount}
          alertCount={calculateAlertStatus.alertCount}
          criticalCount={calculateAlertStatus.criticalCount}
          totalRecorder={totalRecorder}
        />
      );
      const tempClusterPin = document.createElement("div");
      tempClusterPin.innerHTML = tempClusterContent;

      const tempClusterMarker = new google.maps.marker.AdvancedMarkerElement({
        map: mapInstance.current,
        position: cluster.position,
        content: tempClusterPin,
        // zIndex: 999,
      });

      const clusterInfoWindow = new google.maps.InfoWindow();
      clusterInfoWindow.setOptions({
        pixelOffset: new google.maps.Size(0, -30),
      });

      clusterInfoWindow.setContent(
        ReactDomServer.renderToString(
          <ThemeProvider theme={theme.theme === "light" ? light : dark}>
            <HoverInfoView
              theme={theme}
              count={totalRecorder}
              calculateAlertStatus={calculateAlertStatus}
              assignedFilter={mapFilter.filters.status.assigned}
            />
          </ThemeProvider>
        )
      );
      isNotMobile &&
        tempClusterPin.addEventListener("mouseover", () => {
          closeActiveMarker();
          clusterInfoWindow.open({ anchor: tempClusterMarker });
        });

      isNotMobile &&
        tempClusterPin.addEventListener("mouseout", () => {
          clusterInfoWindow.close();
        });
      return tempClusterMarker;
    },
  };

  /**
   * isSearchMove (Search As Moved) 에 따른 dashboarddata 변경
   */
  const adjustMap = useCallback(() => {
    // console.log(isSearchMove);
    if (markerCluster === undefined || markerCluster.current === undefined) {
      return;
    }

    if (
      rowRecorderList.current === undefined ||
      rowRecorderList.current.length === 0
    ) {
      dispatch(setDashboardDataInit());
      return;
    }

    if (isSearchMove.current) {
      const filterRecorder = rowRecorderList.current
        .filter((recorder: DashboardFilterResult) => {
          return mapInstance.current
            ?.getBounds()
            ?.contains(
              new google.maps.LatLng(
                recorder.location.latitude,
                recorder.location.longitude
              )
            );
        })
        .map((rec) => filteringRecorder(rec));
      dispatch(setDashboardData(filterRecorder));
    } else {
      dispatch(
        setDashboardData(
          rowRecorderList.current.map((rec) => filteringRecorder(rec))
        )
      );
    }
  }, [dispatch, isSearchMove, mapFilter, rowRecorderList]);

  const handleActiveMarker = useCallback(
    (marker: CustomMarker) => {
      if (!marker || !marker.systems) return;

      const infoWindowDiv = document.createElement("div");

      // React 컴포넌트 마운트
      const root = createRoot(infoWindowDiv);
      root.render(
        <Provider store={store}>
          <IntlProvider locale="en" defaultLocale="en">
            <QueryClientProvider client={queryClient}>
              <ThemeProvider theme={theme.theme === "light" ? light : dark}>
                {getInfoWindowContent(marker.systems)}
              </ThemeProvider>
            </QueryClientProvider>
          </IntlProvider>
        </Provider>
      );

      const markerInfoWindow = new google.maps.InfoWindow({
        content: infoWindowDiv,
      });

      infoWindowRef.current = markerInfoWindow;
      // console.log("??", infoWindowRef.current, (marker as any).popupPosition);
      infoWindowRef.current.setOptions({
        pixelOffset: (marker as any).popupPosition,
        disableAutoPan: true,
      });
      infoWindowRef.current.open({
        anchor: marker,
        map: mapInstance.current,
      });
    },
    [mapInstance.current, infoWindowRef.current]
  );

  const closeActiveMarker = useCallback(() => {
    console.log("closeActiveMarker");
    if (infoWindowRef.current) {
      infoWindowRef.current.close();
      infoWindowRef.current = null;
    }
    if (mapInstance.current !== undefined) {
      mapInstance.current.getDiv().focus();
    }
  }, []);

  const defaultOnClusterClickHandler: onClusterClickHandler = (
    _: google.maps.MapMouseEvent,
    cluster: Cluster,
    map: google.maps.Map
  ): void => {
    if (cluster.bounds !== undefined) {
      mapInstance.current?.fitBounds(cluster.bounds, 100);
    }
  };
  const initMap = (map: google.maps.Map) => {
    mapInstance.current = map;

    if (window.innerWidth > 3000) {
      mapInstance.current.setZoom(6);
    }

    mapInstance.current.addListener("zoom_changed", () => {
      closeActiveMarker();
      adjustMap();
    });

    mapInstance.current.addListener("bounds_changed", () => {
      setTimeout(adjustMap, 200);
    });

    mapInstance.current.addListener("click", () => closeActiveMarker());

    !isNotMobile &&
      mapInstance.current.addListener("touchstart", () => closeActiveMarker());
  };

  //BUG [RND-349] filter 적용 : filterAlarmVisible
  const recorderGroupByLocation = (recorders: DashboardData[]) => {
    let result = chain(recorders)
      .groupBy((recorder) => `${recorder.latitude}-${recorder.longitude}`)
      .map((value, key) => {
        // mergedSystemId 과 위경도로 그룹화함
        const { latitude, longitude, mergedSystemName } = value[0];

        const systemGrouped = chain(value)
          .groupBy((recorder) => recorder.mergedSystemId)
          .map((group, mergedSystemId) => {
            const summary = group.reduce(
              (acc, recorder) => {
                // acc.healthyCount += recorder.status ? 1 : 0;
                acc.system += recorder.system;
                acc.disk += recorder.disk;
                acc.video += recorder.video;
                acc.warningCount.push(...recorder.warnings);
                acc.alertCount.push(...recorder.alerts);
                acc.assignCount.push(...recorder.assign);
                acc.criticalCount.push(...recorder.critical);
                return acc;
              },
              {
                // healthyCount: 0,
                system: 0,
                disk: 0,
                video: 0,
                warningCount: [] as DashboardAlertData[],
                alertCount: [] as DashboardAlertData[],
                assignCount: [] as DashboardAlertData[],
                criticalCount: [] as DashboardAlertData[],
              }
            );
            // console.log(value, group, "group");
            return {
              ...group[0],
              // status: summary.healthyCount,
              system: summary.system,
              disk: summary.disk,
              video: summary.video,
              warnings: summary.warningCount,
              alerts: summary.alertCount,
              assign: summary.assignCount,
              critical: summary.criticalCount,
              recorders: group,
            };
          })
          .value();

        // console.log(
        //   systemGrouped,
        //   "groupedByMergedSystemId"
        // );

        return {
          name: mergedSystemName,
          latitude,
          longitude,
          systems: systemGrouped,
        };
      })
      .value();

    // console.log(result, "results");

    // convert the object into an array of objects
    return result;
  };

  //BUG [RND-305] MouseOve시에 여백에 따라 팝업 위치 변경.
  const calculateInfoPosition = (
    mouseEvent: MouseEvent,
    tempMarker: google.maps.marker.AdvancedMarkerElement
  ) => {
    const screenX = window.innerWidth;
    const screenY = window.innerHeight;
    const markerX = mouseEvent.clientX;
    const markerY = mouseEvent.clientY;

    const rightPad = markerX;
    const leftPad = screenX - markerX;

    const topPad = markerY;
    const bottomPad = screenY - markerY;

    let pos = new google.maps.Size(0, -25);

    const recorders: DashboardData[] | undefined = (tempMarker as any).recorder;

    const isMulti =
      recorders !== undefined && recorders.length !== undefined
        ? recorders.length
        : 2;

    if (rightPad > leftPad) {
      if (topPad > bottomPad) {
        pos = new google.maps.Size(-100, -25); //close
      } else {
        if (isMulti <= 1) {
          pos = new google.maps.Size(-125, 200);
        } else {
          const calcHeight = 20 + (isMulti - 2) * 20;
          pos = new google.maps.Size(-125, calcHeight > 200 ? 200 : calcHeight);
        }
      }
    } else {
      if (topPad > bottomPad) {
        pos = new google.maps.Size(100, -25);
      } else {
        if (isMulti <= 1) {
          pos = new google.maps.Size(100, 200);
        } else {
          const calcHeight = 20 + (isMulti - 2) * 20;
          pos = new google.maps.Size(125, calcHeight > 200 ? 200 : calcHeight);
        }
      }
    }
    return pos;
  };

  // 선택한 지도 marker 왼쪽 Alert View 표시 이벤트
  const onClickMarkerAlert = useCallback(
    (systems: DashboardData[]) => {
      // console.log(systems, 99);
      if (systems !== undefined && systems.length === 1) {
        // console.log(systems[0], "tmepmarker systems");
        let tempSelectedAlert = rowRecorderList.current?.find((row) => {
          if (systems !== undefined)
            return row.recorderId === systems[0].recorderId;
        });

        tempSelectedAlert
          ? setSelectedAlert({
              ...tempSelectedAlert,
              recorderName: tempSelectedAlert.name,
              location: tempSelectedAlert.location.location,
            })
          : setSelectedAlert(null);
        !isNotMobile && closeActiveMarker();
      }
    },

    [rowRecorderList, selectedAlert]
  );

  const refreshMap = () => {
    if (recorders === undefined && mapInstance.current === undefined) {
      return;
    }

    if (markerCluster.current) {
      markerCluster.current.clearMarkers();
    }

    const groupByLocation = recorderGroupByLocation(recorders);

    let tempMarkers: google.maps.marker.AdvancedMarkerElement[] = [];

    groupByLocation.forEach((data, index) => {
      if (data.latitude === undefined || data.longitude === undefined) {
        return;
      }

      let alertCount = 0;
      let assignCount = 0;
      let criticalCount = 0;
      let warningCount = 0;

      data.systems.reduce((acc, cur) => {
        alertCount += cur.alerts.length;
        assignCount += cur.assign.length;
        criticalCount += cur.critical.length;
        warningCount += cur.warnings.length;
        return acc;
      }, {});

      const tempMarkerContent = ReactDomServer.renderToString(
        <SingleMarker
          data={{
            alertCount,
            assignCount,
            criticalCount,
            warningCount,
            systems: data.systems,
            name: data.name,
          }}
        />
      );

      const tempPin = document.createElement("div");
      tempPin.innerHTML = tempMarkerContent;

      const tempMarker = new google.maps.marker.AdvancedMarkerElement({
        map: mapInstance.current,
        position: {
          lat: data.latitude,
          lng: data.longitude,
        },
        content: tempPin,
        title:
          data.systems !== undefined && data.systems.length > 1
            ? `${data.systems.length} SYS`
            : data.name,
      });

      // marker 안에 데이터 요소 넣고 싶은데 이렇게 하는 방법인지 의문임.
      (tempMarker as any).warningCount = warningCount;
      (tempMarker as any).alertCount = alertCount;
      (tempMarker as any).criticalCount = criticalCount;
      (tempMarker as any).assignCount = assignCount;
      (tempMarker as any).data = data;
      (tempMarker as any).systems = data.systems;

      tempPin.style.pointerEvents = "auto";

      // system 여러개일때 각각의 시스템 클릭 이벤트 (모바일은 클릭 / 웹은 마우스오버)
      tempPin.addEventListener(
        isNotMobile ? "mouseover" : "click",
        (e: MouseEvent) => {
          if (infoWindowRef.current) {
            infoWindowRef.current.close();
          }
          const mouseEvent = e as MouseEvent;
          const pos = calculateInfoPosition(mouseEvent, tempMarker);
          (tempMarker as any).popupPosition = pos;

          handleActiveMarker(tempMarker);
        }
      );

      isNotMobile &&
        tempMarker.addListener("click", () => {
          // 1개의 시스템인 marker 를 클릭했을 때 왼쪽 Alert View 보여주기 위함
          onClickMarkerAlert(data.systems);
        });

      tempMarkers.push(tempMarker);
    });

    markerCluster.current = new MarkerClusterer({
      map: mapInstance.current,
      markers: tempMarkers,
      algorithm: new SuperClusterAlgorithm({
        radius: 30, // cluster 거리 기준
      }),
      renderer: clusterRenderer,
      onClusterClick: defaultOnClusterClickHandler,
    });
    setMarkers(tempMarkers);
  };

  const smoothZoom = (
    max: number,
    cnt: number,
    loc?: google.maps.LatLng,
    bounds?: google.maps.LatLngBounds
  ) => {
    if (mapInstance.current !== undefined) {
      const currentZoom = cnt || (mapInstance.current.getZoom() as number);

      if (currentZoom < max) {
        google.maps.event.addListenerOnce(
          mapInstance.current,
          "zoom_changed",
          function (event: any) {
            if (loc !== undefined && mapInstance.current !== undefined) {
              mapInstance.current.setCenter(loc);
            }
            smoothZoom(max, cnt + (max > currentZoom ? 1 : -1), loc);
          }
        );

        setTimeout(function () {
          if (mapInstance.current) {
            mapInstance.current.setZoom(cnt + (max > currentZoom ? 1 : -1));
          }
        }, 200);
      }
    }
  };

  const smoothZoomOut = (min: number, cnt: number) => {
    if (cnt <= min) {
      setTimeout(function () {
        if (mapInstance.current) {
          mapInstance.current.setZoom(min);
        }
      }, 10);
      return;
    } else {
      if (mapInstance.current !== undefined) {
        let z = google.maps.event.addListenerOnce(
          mapInstance.current,
          "idle",
          function (event: any) {
            //google.maps.event.removeListener(z);
            smoothZoomOut(min, cnt - 3);
          }
        );
        // if (mapInstance.current) {
        //   mapInstance.current.setZoom(cnt);
        // };
        setTimeout(function () {
          if (mapInstance.current) {
            mapInstance.current.setZoom(cnt);
          }
        }, 80);
      }
    }
  };

  useEffect(() => {
    return () => {
      dispatch(setDashboardDataInit());
    };
  }, []);

  const onChangeCollapsed = () => {
    setIsCollapsed(!isCollapsed);
  };

  const onZoomPlus = useCallback(() => {
    if (mapInstance.current !== undefined) {
      const currentZoomLevel: number | undefined =
        mapInstance.current.getZoom();
      if (currentZoomLevel !== undefined && currentZoomLevel < 19) {
        mapInstance.current.setZoom(currentZoomLevel + 1);
      }
    }
  }, []);

  const onZoomOut = useCallback(() => {
    if (mapInstance.current !== undefined) {
      const currentZoomLevel: number | undefined =
        mapInstance.current.getZoom();
      if (currentZoomLevel !== undefined && currentZoomLevel > 4) {
        mapInstance.current.setZoom(currentZoomLevel + -1);
      }
    }
  }, []);

  const onZoomFit = useCallback(() => {
    if (mapInstance.current !== undefined) {
      console.log(markers, "markers");
      let bound = new google.maps.LatLngBounds();
      if (markers !== undefined) {
        markers.forEach((marker) => {
          if (marker !== undefined) {
            const loc = marker.position;
            if (loc !== undefined && loc !== null) {
              bound.extend(loc);
            }
          }
        });

        if (markers.length === 0) {
          mapInstance.current.setCenter(mapOptions.center);
          mapInstance.current.setZoom(5);
        } else {
          if (markers.length === 1) {
            //mapInstance.current.setCenter(bound.getCenter());
            //mapInstance.current.setZoom(7);
            //smoothZoom(5, mapInstance.current.getZoom() as number);
            mapInstance.current.fitBounds(bound, 0);
          } else {
            mapInstance.current.fitBounds(bound, 0);
          }
        }
      }
    }
  }, [markers]);

  const onChangeView = useCallback(() => {
    if (mapInstance.current !== undefined) {
      if (isRoadview) {
        mapInstance.current.setMapTypeId(google.maps.MapTypeId.SATELLITE);
      } else {
        mapInstance.current.setMapTypeId(google.maps.MapTypeId.ROADMAP);
      }
      setIsRoadview(!isRoadview);
    }
  }, [isRoadview]);

  const [isViewRecorderList, setIsViewRecorderList] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const onSelectRecorder = (rec: DashboardData) => {
    if (getTopAlertStatus(rec) <= enumRecorderStatus.WARNING) {
      return;
    }

    setSelectedAlert({
      type: rec.type,
      displayType: rec.displayType,
      recorderId: rec.recorderId,
      recorderName: rec.name,
      mergedSystemName: rec.mergedSystemName,
      mergedSystemId: rec.mergedSystemId,
      cloudSystemId: rec.cloudSystemId,
      location: rec.location,
      // model: rec.model,
    });
    !isNotMobile && closeActiveMarker();
  };

  const onClickRecorderKeyword = (rec: KeywordSearchRecorder) => {
    setSelectedAlert({
      ...rec,
      location: rec.location.location,
    });
  };

  const onCloseRecorderView = () => {
    setSelectedAlert(null);
  };
  const onCloseRecorderList = () => {
    onResetSearchInput();
    setIsViewRecorderList(false);
  };

  useEffect(() => {
    if (!isViewRecorderList) {
      onCloseRecorderView();
    }
  }, [isViewRecorderList]);

  const filteringToPayload = (filter: DashboardFilter, more: More) => {
    let tempFilters: DashboardFilterRequest = { ...filter };
    let tempMores = Object.entries(more)
      .filter((mo) => mo[1] === true)
      .map((r) => r[0]);

    if (
      tempFilters.types &&
      Object.values(tempFilters.types).every((type) => !type)
    ) {
      delete tempFilters["types"];
    }
    if (
      tempFilters.status &&
      Object.values(tempFilters.status).every((status) => !status)
    ) {
      delete tempFilters["status"];
    }
    if (tempFilters.alerts) {
      let tempAlert = Object.values(tempFilters.alerts)
        .map((f) => Object.values(f).every((i) => !i))
        .some((a) => !a);

      if (!tempAlert) {
        delete tempFilters["alerts"];
      }
    }

    // more filter
    tempFilters = Object.fromEntries(
      Object.entries(tempFilters).filter(([key]) => {
        const commonConditions =
          key === "status" ||
          (isCollapsed && key === "alerts") ||
          tempMores.includes(key);

        if (selectedAccount.accountLevel === "EU") {
          return commonConditions || key === "recorders";
        } else {
          return commonConditions || key === "accounts";
        }
      })
    );

    // console.log(tempFilters, "???");
    return tempFilters;
  };

  const postSearchFilter = useQuery(
    ["searchMapFilterResult", selectedAccount, mapFilter, more, isSearchMove],
    () => {
      let tempMapFilter = { ...filteringToPayload(mapFilter.filters, more) };

      let payload: any = {
        keyword: mapFilter.keyword,
        ...tempMapFilter,
      };

      // console.log(payload);
      return postFilterSearchRecorders({
        accountId: selectedAccount.accountId,
        payload: payload,
      });
    },
    {
      retry: 0,
      refetchOnWindowFocus: false,
      // refetchOnMount: false,
      refetchInterval: 10000,
      onSuccess: (res: DashboardFilterAlertResponse) => {
        // console.log("mutationSearchFilter ------", res);
        if (res.error !== 0 || res.result === undefined) {
          setRecorders([]);
        } else {
          rowRecorderList.current = res.result;
          const loadingRecorder: DashboardData[] = res.result
            .map((recorder: DashboardFilterResult) => {
              return filteringRecorder(recorder);
            })
            .filter((recorder: any) => recorder.location !== "");

          setRecorders(loadingRecorder);

          // console.log(res.result, "res result");
          if (initDraw.current) {
            if (mapInstance.current !== undefined) {
              let bound = new google.maps.LatLngBounds();
              if (loadingRecorder !== undefined) {
                loadingRecorder.forEach((marker: any) => {
                  if (marker !== undefined) {
                    const loc = new google.maps.LatLng(
                      marker.latitude,
                      marker.longitude
                    );
                    if (loc !== undefined && loc !== null) {
                      bound.extend(loc);
                    }
                  }
                });

                if (loadingRecorder.length > 0) {
                  mapInstance.current.fitBounds(bound, 10);
                  //   if (loadingRecorder.length === 1) {
                  //   //mapInstance.current.setCenter(bound.getCenter());
                  //   //mapInstance.current.setZoom(7);
                  // } else {
                  //   mapInstance.current.fitBounds(bound, 10);
                  // }
                }

                initDraw.current = false;
              }
            }
          }

          if (dualModeRef.current !== undefined) {
            dualModeRef.current.updateDualModeData();
          }

          if (isSearchMove.current) {
            adjustMap();
          } else {
            dispatch(setDashboardData(loadingRecorder));
          }
        }
      },
      onError: (e: any) => {
        if (e.response?.data !== undefined) {
          if (e.response?.data.error === 5001) {
          }
        }
      },
      onSettled: () => {
        setIsFetching(false);
      },
    }
  );

  useEffect(() => {
    refreshMap();
  }, [recorders]);

  const onMouseEnter = () => {
    if (closeInfoWindowWithTimeout.current !== undefined) {
      clearTimeout(closeInfoWindowWithTimeout.current);
    }
  };

  const onMouseLeave = useCallback(() => {
    setSelectedMarker(null);
  }, [selectedMarker]);

  const onResetSearchInput = () => {
    dispatch(
      setKeyword({
        type: "",
        keyword: "",
      })
    );
  };

  const onSearch = (keyword: MapFilterKeyword) => {
    dispatch(setKeyword(keyword));
    setIsFetching(true);
    queryClient.invalidateQueries("searchMapFilterResult");
    setIsViewRecorderList(true);
    setSelectedAlert(null);
  };

  const onClickRecorder = (id: string) => {
    // console.log(id, "onClickRecorder");
    if (mapInstance.current) {
      const selectedRecorder = rowRecorderList.current?.find(
        (recorder) => recorder.recorderId === id
      );
      // console.log(selectedRecorder, "selectedRecorder");
      if (selectedRecorder !== undefined) {
        mapInstance.current.setCenter(
          new google.maps.LatLng(
            selectedRecorder.location.latitude,
            selectedRecorder.location.longitude
          )
        );
        smoothZoom(17, mapInstance.current.getZoom() as number);
        //smoothlyAnimatePanTo(mapInstance.current, new google.maps.LatLng(selectedRecorder.location.latitude,selectedRecorder.location.longitude));
      }
    }
  };

  const onChangeSearchMove = useCallback(
    (checked: boolean) => {
      isSearchMove.current = checked;
      adjustMap();
    },
    [adjustMap]
  );

  useEffect(() => {
    adjustMap();
  }, [mapFilter, isSearchMove, rowRecorderList]);

  useEffect(() => {
    onCloseRecorderView();
  }, [mapFilter]);

  return (
    <GoogleMapPresenter
      isCollapsed={isCollapsed}
      isRoadview={isRoadview}
      isViewRecorderList={isViewRecorderList}
      onChangeCollapsed={onChangeCollapsed}
      onZoomPlus={onZoomPlus}
      onZoomOut={onZoomOut}
      onZoomFit={onZoomFit}
      onChangeView={onChangeView}
      mapRef={mapRef}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClickRecorder={onClickRecorder}
      onChangeSearchMove={onChangeSearchMove}
      onSearch={onSearch}
      onResetSearchInput={onResetSearchInput}
      onCloseRecorderList={onCloseRecorderList}
      onSelectRecorder={onSelectRecorder}
      onClickRecorderKeyword={onClickRecorderKeyword}
      more={more}
      onSelectMoreFilter={onSelectMoreFilter}
      onDeleteMoreFilter={onDeleteMoreFilter}
      selectedAlert={selectedAlert}
      onCloseRecorderView={onCloseRecorderView}
      isFetching={isFetching}
      onClickMarkerAlert={onClickMarkerAlert}
      initMap={initMap}
    />
  );
}
