import React, { useEffect, useState } from "react";
import { Table } from "antd";
import type { RowProps, TableProps } from "antd";
import { SortOrder } from "antd/es/table/interface";
import styled from "styled-components";
import { calcRem } from "styles/theme";
import {
  BasePagination,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import AntdPagination, {
  PaginationChangePage,
} from "components/blocks/pagination/AntdPagination";
import { COLORS, LIGHT_THEME_COLORS } from "styles/colors";
import * as mediaQuery from "components/MediaQuery";
import { FLEX } from "styles/flex";
import Spinner from "../loader/Spinner";
import NoData from "components/templates/nopage/NoData";

type PaginationProps = {
  queryInfo?: BasePagination;
  onChangePage?: PaginationChangePage;
};

const TableSpinner = () => {
  return (
    <TableSpinnerWrapper>
      <Spinner />
    </TableSpinnerWrapper>
  );
};

export const onSortAntd = (order: SORT_DIRECTION) => {
  if (order === SORT_DIRECTION.ASC) {
    return SORT_DIRECTION.DESC;
  } else return SORT_DIRECTION.ASC;
};

export const changeSortOrderString = (order?: SortOrder) => {
  switch (order) {
    case "ascend":
      return SORT_DIRECTION.ASC;
    case "descend":
      return SORT_DIRECTION.DESC;
    default:
      return SORT_DIRECTION.ASC;
  }
};

export const changeSortOrderAntd = (
  queryInfo: BasePagination,
  key: React.Key
) => {
  if (queryInfo.sortType === (key as string)) {
    switch (queryInfo.sortDirection) {
      case SORT_DIRECTION.ASC:
        return "ascend";
      case SORT_DIRECTION.DESC:
        return "descend";
      default:
        return "ascend";
    }
  } else return null;
};

const TableNoData = (props: { noImage?: boolean }): JSX.Element => {
  if (props.noImage) {
    return (
      <TableNoDataWrapper>
        <div className="no-data-text"> There is no data to display</div>
      </TableNoDataWrapper>
    );
  }
  return (
    <TableNoDataWrapper>
      <NoData />
    </TableNoDataWrapper>
  );
};

export const DashboardCardDetailTable = (
  props: TableProps<any>
): JSX.Element => (
  <DashboardCardDetailTableWrapper>
    <Table
      pagination={false}
      bordered={false}
      locale={{
        emptyText: "",
      }}
      {...props}
    />
  </DashboardCardDetailTableWrapper>
);

export const DashboardChartDetailTable = (
  props: TableProps<any> & PaginationProps
): JSX.Element => {
  return (
    <DashboardChartTableWrapper>
      <div className="table-wrapper">
        <Table
          showSorterTooltip
          bordered={false}
          pagination={false}
          sticky={true}
          {...(props as TableProps<any>)}
        />
      </div>
      {props.queryInfo !== undefined && props.onChangePage !== undefined && (
        <div className="pagination-wrapper">
          <AntdPagination
            rowsPerPage={props.queryInfo.pageLimit}
            rowCount={props.queryInfo.total}
            onChangePage={props.onChangePage}
            currentPage={props.queryInfo.pageNum}
          />
        </div>
      )}
    </DashboardChartTableWrapper>
  );
};

export const CommonTable = (
  props: TableProps<any> & PaginationProps
): JSX.Element => {
  const [hoveredRowKey, setHoveredRowKey] = useState<number | null>(null);

  const Row = (props: RowProps) => {
    const style: React.CSSProperties = {
      ...props.style,
      height: 52,
    };

    return <CommonTableTr {...props} style={style} />;
  };

  return (
    <CommonTableWrapper>
      <div className="table-wrapper">
        <Table
          // bordered={false}
          showSorterTooltip={false}
          pagination={false}
          components={{
            body: {
              row: Row,
            },
          }}
          scroll={{ x: mediaQuery.isMobile ? "100%" : undefined }}
          {...(props as TableProps<any>)}
          loading={{
            spinning: (props.loading as boolean) || false,
            indicator: <TableSpinner />,
          }}
          locale={{
            emptyText: props.loading ? "" : <TableNoData />,
          }}
          tableLayout={mediaQuery.isMobile ? "auto" : "fixed"} // column width 변동되는 점 방지
          rowClassName="antd-table-row-mydw"
        />
      </div>
      {props.queryInfo !== undefined && props.onChangePage !== undefined && (
        <div className="pagination-wrapper">
          <AntdPagination
            rowsPerPage={props.queryInfo.pageLimit}
            rowCount={props.queryInfo.total}
            onChangePage={props.onChangePage}
            currentPage={props.queryInfo.pageNum}
          />
        </div>
      )}
    </CommonTableWrapper>
  );
};

export const ExpandTable = (props: TableProps<any>): JSX.Element => {
  const Row = (props: RowProps) => {
    const style: React.CSSProperties = {
      ...props.style,
      height: 30,
    };

    return <CommonTableTr {...props} style={style} />;
  };
  const [hoveredRowKey, setHoveredRowKey] = useState<number | null>(null);

  // onRow 이벤트 부모와 합치기
  const localOnRow = (record: any, rowIndex?: number) => {
    // 자식 컴포넌트 내의 로직
    const localHandlers = {
      onMouseEnter: () => {
        if (rowIndex !== undefined && rowIndex !== null)
          setHoveredRowKey(rowIndex); // 마우스가 올라갔을 때 상태 업데이트
      },
      onMouseLeave: () => {
        setHoveredRowKey(null); // 마우스가 떠났을 때 상태 초기화
      },
      className: rowIndex === hoveredRowKey ? "hovered-row" : "", // 상태에 따라 클래스 적용
    };

    // 부모 컴포넌트로부터 받은 onRow 핸들러와 결합
    const parentHandlers = props.onRow ? props.onRow(record, rowIndex) : {};

    // 두 핸들러를 병합
    return {
      ...parentHandlers,
      ...localHandlers,
      onMouseEnter: (event: React.MouseEvent<HTMLElement>) => {
        if (parentHandlers.onMouseEnter) parentHandlers.onMouseEnter(event);
        localHandlers.onMouseEnter();
      },
      onMouseLeave: (event: React.MouseEvent<HTMLElement>) => {
        if (parentHandlers.onMouseLeave) parentHandlers.onMouseLeave(event);
        localHandlers.onMouseLeave();
      },
      className: `${parentHandlers.className || ""} ${
        localHandlers.className
      }`.trim(),
    };
  };
  return (
    <div>
      <Table
        // bordered={false}
        showSorterTooltip={false}
        pagination={false}
        components={{
          body: {
            row: Row,
          },
        }}
        {...(props as TableProps<any>)}
        locale={{
          emptyText: props.loading ? "" : <TableNoData />,
        }}
        onRow={localOnRow}
      />
    </div>
  );
};

export const ReportTable = (
  props: TableProps<any> & PaginationProps
): JSX.Element => {
  const [hoveredRowKey, setHoveredRowKey] = useState<number | null>(null);

  const Row = (props: RowProps) => {
    const style: React.CSSProperties = {
      ...props.style,
    };

    return <CommonTableTr {...props} style={style} />;
  };

  // onRow 이벤트 부모와 합치기
  const localOnRow = (record: any, rowIndex?: number) => {
    // 자식 컴포넌트 내의 로직
    const localHandlers = {
      onMouseEnter: () => {
        if (rowIndex !== undefined && rowIndex !== null)
          setHoveredRowKey(rowIndex); // 마우스가 올라갔을 때 상태 업데이트
      },
      onMouseLeave: () => {
        setHoveredRowKey(null); // 마우스가 떠났을 때 상태 초기화
      },
      className: rowIndex === hoveredRowKey ? "hovered-row" : "", // 상태에 따라 클래스 적용
    };

    // 부모 컴포넌트로부터 받은 onRow 핸들러와 결합
    const parentHandlers = props.onRow ? props.onRow(record, rowIndex) : {};

    // 두 핸들러를 병합
    return {
      ...parentHandlers,
      ...localHandlers,
      onMouseEnter: (event: React.MouseEvent<HTMLElement>) => {
        if (parentHandlers.onMouseEnter) parentHandlers.onMouseEnter(event);
        localHandlers.onMouseEnter();
      },
      onMouseLeave: (event: React.MouseEvent<HTMLElement>) => {
        if (parentHandlers.onMouseLeave) parentHandlers.onMouseLeave(event);
        localHandlers.onMouseLeave();
      },
      className: `${parentHandlers.className || ""} ${
        localHandlers.className
      }`.trim(),
    };
  };

  return (
    <ReportTableWrapper>
      <div className="table-wrapper">
        <Table
          // bordered={false}
          showSorterTooltip={false}
          pagination={false}
          components={{
            body: {
              row: Row,
            },
          }}
          scroll={{ x: mediaQuery.isMobile ? "100%" : undefined }}
          tableLayout={mediaQuery.isMobile ? "auto" : "fixed"}
          {...(props as TableProps<any>)}
          onRow={localOnRow}
          loading={{
            spinning: (props.loading as boolean) || false,
            indicator: <TableSpinner />,
          }}
          locale={{
            emptyText: props.loading ? "" : <TableNoData />,
          }}

          // dataSource={[]}
        />
      </div>
      {props.queryInfo !== undefined && props.onChangePage !== undefined && (
        <div className="pagination-wrapper">
          <AntdPagination
            rowsPerPage={props.queryInfo.pageLimit}
            rowCount={props.queryInfo.total}
            onChangePage={props.onChangePage}
            currentPage={props.queryInfo.pageNum}
          />
        </div>
      )}
    </ReportTableWrapper>
  );
};

export const AlertViewTable = (props: TableProps<any>): JSX.Element => {
  return (
    <AlertViewTableWrapper>
      <Table
        showSorterTooltip={false}
        pagination={false}
        scroll={{
          x: mediaQuery.isMobile ? "100%" : undefined,
          y: 250,
        }}
        loading={{
          spinning: (props.loading as boolean) || false,
          indicator: <TableSpinner />,
        }}
        locale={{
          emptyText: props.loading ? "" : <TableNoData noImage />,
        }}
        tableLayout={mediaQuery.isMobile ? "auto" : "fixed"}
        {...(props as TableProps<any>)}
      />
    </AlertViewTableWrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  .ant-table-wrapper {
    width: 100%;
    height: 100%;
  }
  .ant-table {
    font-size: ${calcRem(13.5)};
    line-height: ${calcRem(16)};
    color: ${({ theme }) => theme.COLORS.WHITE};
    scrollbar-color: ${({ theme }) =>
      theme.value === "light"
        ? `${COLORS.LIGHTGRAY} ${LIGHT_THEME_COLORS.LIGHTGRAY}`
        : "#545E77 #273457"};
  }
  .ant-empty-normal {
    margin-block: 0;
  }

  .ant-table-filter-dropdown {
    .ant-dropdown-menu {
      padding: 0;
    }
    .ant-dropdown-menu-item {
      padding: 4px;
    }
  }

  .ant-dropdown-menu-title-content {
    color: ${COLORS.BLACK} !important;
  }

  .ant-table-filter-trigger,
  .ant-table-column-sorter {
    color: unset !important;
  }

  .ant-table-filter-trigger.active {
    color: ${({ theme }) => theme.COLORS.SELECTED} !important;
  }

  // column header 아이콘 정렬 변경

  .ant-table-column-sorters {
    .ant-table-column-title {
      flex: unset;
    }
    justify-content: flex-start;
    gap: 4px;
  }
`;

const DashboardCardDetailTableWrapper = styled(Wrapper)`
  .ant-table-row,
  .ant-table-cell,
  td {
    border: none !important;
  }

  .ant-table-cell {
    padding: 2px 0 !important;
  }

  tbody {
    border-bottom: 1px solid #7883a1;
  }
`;

const DashboardChartTableWrapper = styled(Wrapper)`
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;

  .table-wrapper {
    flex: 1 1 auto;
  }

  .pagination-wrapper {
    flex: 0 0 auto;
  }

  .ant-table-thead th {
    padding: 4px 16px !important;
    background-color: ${({ theme }) =>
      theme.value === "light"
        ? LIGHT_THEME_COLORS.LIGHTGRAY
        : COLORS.PRIMARY3} !important;

    .ant-table-column-title {
      font-weight: 500 !important;
    }
  }
  .ant-table-cell {
    padding: 6px 16px !important;
  }
  tbody {
    tr {
      &:last-child {
        > td {
          border-bottom: none;
        }
      }
    }
  }

  .no-data {
    margin: 40px 0;
  }
`;

const CommonTableWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  .table-wrapper {
    flex: 1 1 auto;
  }
  .pagination-wrapper {
    flex: 0 0 auto;
    padding-bottom: 10px;
  }

  .ant-table-wrapper {
    > div {
      height: 100%;
    }

    .ant-spin-container {
      height: 100%;

      .ant-table {
        height: 100%;

        .ant-table-container {
          height: 100%;

          .ant-table-content {
            height: 100%;
          }
        }
      }
    }
  }

  .ant-table-thead {
    /* padding: 14px 6px; */
    background-color: ${({ theme }) =>
      theme.value === "light" ? LIGHT_THEME_COLORS.LIGHTGRAY : COLORS.PRIMARY3};
  }
  .ant-table-thead th {
    padding: 12px 4px !important;
    color: ${({ theme }) => theme.COLORS.WHITE} !important;
    padding-right: 10px !important;

    :first-child {
      padding-left: 10px !important;
    }

    .ant-table-column-title {
      font-size: ${calcRem(14)} !important;
      font-weight: 500 !important;
    }
  }
  .ant-table-cell {
    padding: 10px 4px !important;
    padding-right: 10px !important;
  }

  .antd-table-row-mydw {
    &:hover {
      .row-click-button {
        filter: brightness(120%) saturate(120%);
      }
    }
  }

  tbody {
    tr {
      &:last-child {
        > td {
          border-bottom: none;
        }
      }
    }
  }

  .table-button-wrapper {
    ${FLEX.FlexEndCenter};
  }

  .ant-table-expanded-row {
    > td {
      padding: 0 !important;
    }
  }

  .default-rule-row {
    color: ${({ theme }) => theme.COLORS.SELECTED} !important;
    td {
      color: ${({ theme }) => theme.COLORS.SELECTED} !important;
    }
  }
`;

const CommonTableTr = styled.tr`
  td {
    vertical-align: middle;

    &:first-child {
      padding-left: 10px !important;
    }
  }
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) =>
      theme.value === "light"
        ? `${theme.COLORS.WHITE}03`
        : `${theme.COLORS.WHITE}08`};
  }
`;

const ReportTableWrapper = styled(CommonTableWrapper)`
  .ant-table {
    font-size: ${calcRem(13)};
    line-height: ${calcRem(15)};
    color: ${({ theme }) => theme.COLORS.WHITE};
  }
  .ant-table-thead th {
    border: none !important;
    padding: 8px 4px !important;
    color: ${({ theme }) =>
      theme.value === "light" ? COLORS.BORDER : theme.COLORS.WHITE} !important;

    .ant-table-column-title {
      font-size: ${calcRem(13)} !important;
      font-weight: 500 !important;
    }
  }
`;

const AlertViewTableWrapper = styled(Wrapper)`
  .ant-table {
    font-size: ${calcRem(12)};
    line-height: ${calcRem(15)};
  }
  .ant-table-thead th {
    padding: 5px 4px !important;
  }

  .ant-table-cell {
    padding: 5px !important;
  }
`;

const TableSpinnerWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin: 50px 0;
`;

const TableNoDataWrapper = styled.div`
  padding: 30px 0;

  img {
    width: 65px;
    max-width: 65px !important;
  }

  > .no-data-text {
    font-size: ${calcRem(14)};
    color: ${({ theme }) => `${theme.COLORS.WHITE}90`};
  }
`;
