import TableButton from "components/atoms/buttons/TableButton";
import {
  ReportHealthDetailRecorder,
  ReportHealthDetailRecorderAlert,
} from "api/interfaces/reportInterface.interface";
import { useAppSelector } from "redux/hooks";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { getRecorderTimeFormat } from "utils/timeUtil";

import { RecorderDetail } from "../../HealthDetail";
import { ReportTable } from "components/atoms/table/AntdTable";
import { TableColumnsType } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { navigateAlertDetailPage } from "utils/AlertUtil";

type Props = {
  data: ReportHealthDetailRecorder;
  recorderInfo: RecorderDetail;
};

export default function Health({ data, recorderInfo }: Props): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const columns: TableColumnsType<ReportHealthDetailRecorderAlert> = [
    {
      title: "Type",
      render: (value, row) => row.type,
    },
    {
      title: "Alert",
      render: (value, row) =>
        row.alertReason !== "Video Loss"
          ? row.alertReason
          : `${row.alertReason} [${row.cameraName}]`,
    },
    {
      title: "Status",
      render: (value, row) => row.status.toUpperCase(),
    },
    {
      key: "eventDate",
      title: "Event Time",
      render: (row) =>
        row.eventDate !== undefined
          ? getRecorderTimeFormat(
              row.eventDate,
              selectedAccount,
              row.timezone,
              true
            )
          : "",
      responsive: ["xl"],
    },
    {
      title: "Alert Trigger Time",
      render: (value, row) =>
        row.alertTrigger !== undefined
          ? getRecorderTimeFormat(
              row.alertTrigger,
              selectedAccount,
              row.timezone,
              true
            )
          : "",
      responsive: ["xl"],
    },
    {
      title: "Rule",
      render: (value, row) => row.alertName,
      responsive: ["xxl"],
    },
    // 마지막 버튼
    {
      title: "",
      render: (value, row) => {
        if (row.status !== "Warning")
          return (
            <div className="table-button-wrapper">
              <TableButton label="View" onClick={() => onRowClick(row)} />
            </div>
          );
      },
      align: "right",
    },
  ];

  const onRowClick = (row: ReportHealthDetailRecorderAlert) => {
    navigateAlertDetailPage({
      navigate,
      recorderId: recorderInfo.recorderId,
      historyId: row.alertHistoryId,
      alertType: row.type,
    });
  };
  return (
    <ReportTable
      columns={columns}
      dataSource={data.alerts ?? []}
      onRow={(record, rowIndex) => {
        return {
          onClick: (event) => {
            onRowClick(record);
          },
        };
      }}
      loading={false}
    />
  );
}
