import * as s from "./MissingStyled";
import AddressInput from "components/atoms/input/address/AddressInput";
import AddressMap from "components/atoms/input/address/AddressMap";
import TinyButton from "components/atoms/buttons/TinyButton";
import RBACWrapper from "components/blocks/function/RBACWrapper";
import { useAppSelector } from "redux/hooks";
import {
  LEVEL_TYPE,
  ProfileAccountInfo,
} from "api/interfaces/accountInterface.interface";

type Props = {
  isLoaded: true;
  addressRef: any;
  isDisabled?: boolean;
  mapRef: any;
  selected: google.maps.LatLngLiteral;
  onMarkerChange: (
    address: google.maps.LatLngLiteral,
    addressString: string
  ) => void;
  onChangeAddress: (e: any) => void;
  onChangeLocationSelect: (
    address: google.maps.LatLngLiteral,
    addressString: string
  ) => void;
  location: {
    location: string;
    latitude: number;
    longitude: number;
  };
  onApply: () => void;
};
const EnterAddress = (props: Props): JSX.Element => {
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  return (
    <s.AddressContainer>
      <s.AddressWrapper>
        <AddressInput
          isLoaded={props.isLoaded}
          value={props.location.location}
          onChange={props.onChangeAddress}
          onChangeLocationSelect={props.onChangeLocationSelect}
          isDisabled={
            props.isDisabled || selectedAccount.accountLevel !== LEVEL_TYPE.EU
          }
        />
        <s.AddressMapWrapper>
          <AddressMap
            isLoaded={props.isLoaded}
            selected={props.selected}
            ref={props.mapRef}
            onMarkerChange={props.onMarkerChange}
          />
        </s.AddressMapWrapper>
        <s.ButtonWrapper>
          {selectedAccount.accountLevel === LEVEL_TYPE.EU && (
            <RBACWrapper requiredPermissions={"manageRecordersAndGroups"}>
              <TinyButton label="Apply" onClickBtn={props.onApply} />
            </RBACWrapper>
          )}
        </s.ButtonWrapper>
      </s.AddressWrapper>
    </s.AddressContainer>
  );
};

export default EnterAddress;
