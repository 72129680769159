import { useCallback, useEffect, useMemo, useState } from "react";
import { AccountPresenter, DataRow } from "./AccountPresenter";
import * as s from "./AccountStyled";
import { Level } from "components/atoms/level/Level";
import TableButton from "components/atoms/buttons/TableButton";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  deleteAccount,
  getAccoountList,
  postAccountCreate,
  putAccountUpdate,
} from "api/accountAPI";
import {
  CreateAccountRequest,
  GetAccountListQuery,
  ProfileAccountInfo,
} from "api/interfaces/accountInterface.interface";
import { Account, getAccountListMappger } from "api/mappers/accountMapper";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { calRowCount, handleCopyClipBoard } from "utils/functions";
import { notify } from "components/atoms/notification/Notification";
import { useAuth } from "components/Auth";
import { useLocation, useNavigate } from "react-router-dom";
import { clearBreadcrumbRoute } from "redux/reducers/breadcrumb/breadcrumb";
import { checkEmailRegEx, checkWhiteSpaceRegEx } from "utils/regEx";
import { useIntl } from "react-intl";
import {
  BasePagination,
  CustomError,
  LEVEL_TYPE,
  SORT_DIRECTION,
  Validate,
  ValidateMsg,
} from "api/interfaces/commonInterface.interface";
import {
  getAllAccountInfo,
  getSwitchAccountInfo,
  isCheckPermission,
  isSubCP,
} from "utils/AccountUtil";
import { isEmpty, isNull } from "lodash";
import RBACWrapper from "components/blocks/function/RBACWrapper";
import { IoIosCopy } from "react-icons/io";
import useApiError from "hook/useApiError";
import { queryNavigate } from "utils/MenuUtil";
import { ReactComponent as AccountSVG } from "assets/icons/sidenav/account.svg";
import { TableColumnsType } from "antd";
import {
  changeSortOrderAntd,
  onSortAntd,
} from "components/atoms/table/AntdTable";
import { ColumnType } from "antd/es/table";
import { navigateSwitchAccount } from "utils/AuthUtil";
import { useIsMobile } from "components/MediaQuery";

export interface Props {}
const initialQuery = {
  total: 0,
  pageNum: 0,
  pageLimit: 20,
  keyword: "",
  sortType: "name",
  sortDirection: SORT_DIRECTION.ASC,
};
export function AccountContainer(): JSX.Element {
  const queryClient = useQueryClient();
  const intl = useIntl();

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const dispatch = useAppDispatch();
  const auth = useAuth();
  const navigate = useNavigate();
  const location: any = useLocation();

  const [isAddModal, setIsAddModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [currAccount, setCurrAccount] = useState<Account>();
  const initIsSelf =
    location?.state?.isSwitchMenu !== undefined
      ? location?.state?.isSwitchMenu
      : false;
  const [isSelf, setIsSelf] = useState(initIsSelf);

  const params = new URLSearchParams(location.search);

  const [accountValidate, setAccountValidate] = useState<Validate>({
    isAccountName: false,
    isLevel: false,
    isUserName: false,
    isUserEmail: false,
  });

  const [accountValidateMsg, setAccountValidateMsg] = useState<ValidateMsg>({
    isAccountName: "",
    isLevel: "",
    isUserName: "",
    isUserEmail: "",
  });

  const [status, setStatus] = useState({
    totalTitle: "Total",
    totalValue: 0,
  });

  const [pending, setPending] = useState<boolean>(true);
  const { handleError } = useApiError();

  useEffect(() => {
    let state = (location?.state?.isSwitchMenu as boolean) || undefined;
    if (state !== undefined) {
      setIsSelf(state);
    } else {
      setIsSelf(false);
    }
    const changeParam = new URLSearchParams(location.search);
    if (changeParam.size > 0) {
      setAccountQueryInfo((info) => {
        return {
          ...info,
          pageNum: isNull(changeParam.get("pageNum"))
            ? 0
            : Number(changeParam.get("pageNum")),
          pageLimit: isNull(changeParam.get("pageLimit"))
            ? 20
            : Number(changeParam.get("pageLimit")),
          keyword: isNull(changeParam.get("keyword"))
            ? ""
            : (changeParam.get("keyword") as string),
          sortType: isNull(changeParam.get("sortType"))
            ? "name"
            : (changeParam.get("sortType") as string),
          sortDirection: isNull(changeParam.get("sortDirection"))
            ? SORT_DIRECTION.ASC
            : (changeParam.get("sortDirection") as SORT_DIRECTION),
        };
      });
    } else {
      setAccountQueryInfo({ ...initialQuery });
    }
  }, [location]);

  const [isRowClickEditable, setIsRowClickEditable] = useState<boolean>(
    isCheckPermission("manageAccount", selectedAccount)
  );
  useEffect(() => {
    if (selectedAccount) {
      setIsRowClickEditable(
        isCheckPermission("manageAccount", selectedAccount)
      );
    }
  }, [selectedAccount]);

  const mutationDeleteAccount = useMutation(deleteAccount, {
    onSuccess: (res: any) => {
      if (res.error !== 0) {
        notify(
          "error",
          intl.formatMessage({
            id: "validateMsg.account.delete.fail",
            defaultMessage: "Failed to delete organization.",
          })
        );
      } else {
        setIsEditModal(false);
        notify(
          "success",
          intl.formatMessage({
            id: "validateMsg.account.delete.success",
            defaultMessage: "Organization deleted successfully.",
          })
        );
        queryClient.invalidateQueries("account");
      }
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "validateMsg.account.delete.fail",
          defaultMessage: "Failed to delete organization.",
        })
      );
    },
  });

  const deleteAccountCb = useCallback(
    (accountId: string) => {
      mutationDeleteAccount.mutate(accountId);
    },
    [mutationDeleteAccount]
  );

  const onModal = (type?: string) => {
    if (type === "add") {
      if (selectedAccount.accountLevel !== "EU") {
        setIsAddModal(!isAddModal);
      }
    }
    if (type === "edit") {
      setIsEditModal(!isEditModal);
    }
  };

  // 이름만 클릭 시 다음 상세 페이지로 이동
  const onCellClick = (row: DataRow) => {
    getSwitchAccountInfo(row.accountId).then(function (selectAccount) {
      if (!!selectAccount) {
        navigateSwitchAccount({
          selectAccount,
          auth,
          navigate,
          dispatch,
          intl,
        });
        setPending(true);
        if (isSelf) {
          dispatch(clearBreadcrumbRoute());
          setIsSelf(false);
        }
      }
    });
    // if (!!selectAccount) {
    //   if (isSelf) {
    //     dispatch(clearBreadcrumbRoute());
    //     setIsSelf(false);
    //   }
    //   dispatch(setAccountSetting(selectAccount));
    //   if (row.levelFullName === "End User") {
    //     dispatch(
    //       pushBreadcrumbRoute({
    //         name: selectAccount.accountName,
    //         routeName: "/recorder/list",
    //         accountId: selectAccount.accountId,
    //       })
    //     );
    //     dispatch(setMenu("Recorders"));
    //     navigate("/recorders/list", { replace: true });
    //   } else {
    //     dispatch(
    //       pushBreadcrumbRoute({
    //         name: selectAccount.accountName,
    //         routeName: "/account",
    //         accountId: selectAccount.accountId,
    //       })
    //     );
    //   }
    // }
  };

  const [accountQueryInfo, setAccountQueryInfo] = useState<BasePagination>({
    ...initialQuery,
  });

  const [accounts, setAccounts] = useState<Account[]>([]);
  const { data, error, refetch, isLoading } = useQuery(
    ["account", accountQueryInfo, selectedAccount],
    () =>
      getAccoountList({
        payload: accountQueryInfo,
        accountId: selectedAccount.accountId,
        isSelf: false,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        setStatus({
          ...status,
          totalValue: res.page.total,
        });
        if (res.result === undefined) {
          setAccounts([]);
          setAccountQueryInfo({ ...initialQuery });
        } else {
          setAccounts(getAccountListMappger(res.result));
          setAccountQueryInfo({ ...res.page });
        }
      },
      onError: (e: any) => {
        setStatus({
          totalTitle: "Total ",
          totalValue: 0,
        });
        setAccounts([]);
        setAccountQueryInfo({ ...initialQuery });
      },
      onSettled: (e: any) => {
        setPending(false);
      },
    }
  );

  const accountList = useMemo((): DataRow[] => {
    return Array.from(accounts).map((account) => {
      const {
        name,
        level,
        levelFullName,
        hasAccounts,
        hasRecorders,
        hasUsers,
        accountId,
        accountNumber,
        timezone,
        dateFormat,
        timeFormat,
        accountAdditionalNumber,
        description,
        parentAccount,
      } = account;
      return {
        name,
        level,
        levelFullName,
        accounts: hasAccounts,
        recorders: hasRecorders,
        users: hasUsers,
        accountId,
        accountNumber,
        timezone,
        dateFormat,
        timeFormat,
        accountAdditionalNumber,
        description,
        parentAccount,
      };
    });
  }, [accounts]);

  const mutationCreateAccount = useMutation(postAccountCreate, {
    onSuccess: (res: any) => {
      notify(
        "success",
        intl.formatMessage({
          id: "validateMsg.account.create.success",
          defaultMessage: "Organization added successfully.",
        })
      );
      getAllAccountInfo()
        .then((response) => {
          if (!!response.totalAccount) {
            auth.updateTotalAccount(response.totalAccount);
          }
        })
        .catch()
        .finally();
      setIsAddModal(false);
      queryClient.invalidateQueries("account");
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "validateMsg.account.create.fail",
          defaultMessage: "Failed to add organization.",
        })
      );
    },
  });

  const onClickCrearte = useCallback(
    (accountInfo: CreateAccountRequest) => {
      if (checkWhiteSpaceRegEx(accountInfo.name)) {
        setAccountValidate((info) => {
          return { ...info, isAccountName: true as boolean };
        });

        setAccountValidateMsg((info) => {
          return {
            ...info,
            isAccountName: intl.formatMessage({
              id: "validateMsg.account.name",
              defaultMessage: "Please enter organization name.",
            }) as string,
          };
        });

        return;
      }

      if (checkWhiteSpaceRegEx(accountInfo.level)) {
        setAccountValidate((info) => {
          return { ...info, isLevel: true as boolean };
        });

        setAccountValidateMsg((info) => {
          return {
            ...info,
            isLevel: intl.formatMessage({
              id: "validateMsg.account.level",
              defaultMessage: "Organization level is mandatory.",
            }) as string,
          };
        });
      }

      if (!isEmpty(accountInfo.inviteName)) {
        if (isEmpty(accountInfo.inviteEmail)) {
          setAccountValidate((info) => {
            return { ...info, isUserEmail: true as boolean };
          });

          setAccountValidateMsg((info) => {
            return {
              ...info,
              isUserEmail: intl.formatMessage({
                id: "validateMsg.account.userMail.empty",
                defaultMessage: "Please enter email",
              }) as string,
            };
          });
          return;
        } else {
          if (!checkEmailRegEx(accountInfo.inviteEmail as string)) {
            setAccountValidate((info) => {
              return { ...info, isUserEmail: true as boolean };
            });

            setAccountValidateMsg((info) => {
              return {
                ...info,
                isUserEmail: intl.formatMessage({
                  id: "validateMsg.account.userMail.illegal",
                  defaultMessage: "Enter a valid email address",
                }) as string,
              };
            });
            return;
          }
        }
      }

      //setIsAddModal(true);
      mutationCreateAccount.mutate({
        payload: accountInfo, // Account -> UpdateRequest
        accountId: selectedAccount.accountId,
      });
    },
    [mutationCreateAccount, selectedAccount.accountId]
  );

  const mutationUpdateAccount = useMutation(putAccountUpdate, {
    onSuccess: (res: any) => {
      if (res.error === 0) {
        notify(
          "success",
          intl.formatMessage({
            id: "validateMsg.account.modify.success",
            defaultMessage: "Organization updated successfully.",
          })
        );
        setIsAddModal(false);
        queryClient.invalidateQueries("account");
      } else {
        notify(
          "error",
          intl.formatMessage({
            id: "validateMsg.account.modify.fail",
            defaultMessage: "Failed to update organization.",
          })
        );
      }
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "validateMsg.account.modify.fail",
          defaultMessage: "Failed to update organization.",
        })
      );
    },
  });

  const onClickUpdate = useCallback(
    (accountInfo: Account, file: File) => {
      setIsEditModal(false);
      const formData = new FormData();
      if (file !== undefined) {
        formData.append("file", file);
      }
      const json = JSON.stringify(accountInfo);
      const blob = new Blob([json], { type: "application/json" });
      formData.append("accountOptionDto", blob);

      mutationUpdateAccount.mutate({
        form: formData, // Account -> UpdateRequest
        accountId: selectedAccount.accountId,
      });
    },
    [mutationUpdateAccount, selectedAccount.accountId]
  );

  const onClickSearch = useCallback(
    (keyword: string) => {
      if (accountQueryInfo.keyword !== keyword) {
        queryNavigate(
          navigate,
          `/account`,
          {
            pageLimit: accountQueryInfo.pageLimit,
            pageNum: 0,
            keyword: keyword,
            sortType: accountQueryInfo.sortType,
            sortDirection: accountQueryInfo.sortDirection as SORT_DIRECTION,
          },
          selectedAccount
        );
      } else {
        refetch();
      }
      setPending(true);
    },
    [
      accountQueryInfo.keyword,
      accountQueryInfo.pageLimit,
      accountQueryInfo.sortDirection,
      accountQueryInfo.sortType,
      navigate,
      refetch,
      selectedAccount,
    ]
  );

  const onChangePage = (page: number, totalRows: number) => {
    // console.log(page, totalRows);
    if (page !== accountQueryInfo.pageNum + 1) {
      queryNavigate(
        navigate,
        `/account`,
        {
          // total: accountQueryInfo.total,
          pageLimit: accountQueryInfo.pageLimit,
          pageNum: page - 1,
          keyword: accountQueryInfo.keyword as string,
          sortType: accountQueryInfo.sortType,
          sortDirection: accountQueryInfo.sortDirection as SORT_DIRECTION,
        },
        selectedAccount
      );
      setPending(true);
      // setAccountQueryInfo((info) => {
      //   return { ...info, pageNum: (page - 1) as number };
      // });
    }
  };

  const onHeaderCell = (column: ColumnType<DataRow>) => {
    return {
      onClick: () => {
        if (column.key) {
          setAccountQueryInfo((info) => {
            return {
              ...info,
              sortType: column.key as string,
              sortDirection: onSortAntd(info.sortDirection),
            };
          });
        }
      },
    };
  };

  const columns: TableColumnsType<DataRow> = [
    {
      key: "name",
      title: "Name",
      render: (value, row) => (
        <s.NameLabel onClick={() => onCellClick(row)}>
          <Level level={row.levelFullName} />
          {row.name}
        </s.NameLabel>
      ),
      sorter: true,
      sortOrder: changeSortOrderAntd(accountQueryInfo, "name"),
      defaultSortOrder: "ascend",
      onHeaderCell: onHeaderCell,
      ellipsis: true,
      width: "40%",
    },
    {
      title: "Level",
      render: (value, row) => row.levelFullName,
      responsive: ["xxl"],
      sorter: true,
      key: "level",
      sortOrder: changeSortOrderAntd(accountQueryInfo, "level"),
      onHeaderCell: onHeaderCell,
      width: 200,
    },
    {
      title: "Organization ID",
      render: (value, row) => (
        <s.NameLabel>
          <div className="org">
            <AccountSVG />
          </div>
          {row.accountNumber}

          <button
            onClick={(e) => {
              e.stopPropagation();
              handleCopyClipBoard(row.accountNumber);
            }}
          >
            <IoIosCopy />
          </button>
        </s.NameLabel>
      ),
      sorter: true,
      key: "accountNumber",
      sortOrder: changeSortOrderAntd(accountQueryInfo, "accountNumber"),
      onHeaderCell: onHeaderCell,
      width: useIsMobile() ? 100 : 250,
    },
    {
      title: "Sub Organizations",
      render: (value, row) =>
        row.level === LEVEL_TYPE.EU ? `-` : row.accounts,
      align: "center",
      responsive: ["xl"],
      width: 150,
    },
    {
      title: "Recorders",
      render: (value, row) => row.recorders,
      align: "center",
      responsive: ["lg"],
      width: 150,
    },
    {
      title: "Users",
      render: (value, row) => row.users,
      align: "center",
      responsive: ["lg"],
      width: 150,
    },
    // 마지막 버튼
    {
      title: "",
      render: (value, row) => (
        <s.Row className="table-button-wrapper">
          {!isSelf && (
            <RBACWrapper requiredPermissions="manageAccount">
              <TableButton
                label="Edit"
                onClick={(e) => {
                  e.stopPropagation();
                  const account = Array.from(accounts).find(
                    (account) => account.accountId === row.accountId
                  );
                  setCurrAccount(account);
                  onModal("edit");
                }}
              />
            </RBACWrapper>
          )}
          <span className="row-click-button">
            <TableButton
              id={"dw_account_button_".concat(row.accountId)}
              label="Enter"
              onClick={() => onCellClick(row)}
            />
          </span>
        </s.Row>
      ),
      align: "right",
      width: useIsMobile() ? 50 : 200,
    },
  ];
  return (
    <AccountPresenter
      onModal={onModal}
      isAddModal={isAddModal}
      isEditModal={isEditModal}
      columns={columns}
      data={accountList}
      currAccount={currAccount as Account}
      onClickCreate={onClickCrearte}
      onClickDelete={deleteAccountCb}
      onClickSave={onClickUpdate}
      onClickSearch={onClickSearch}
      paginationPerPage={accountQueryInfo.pageLimit}
      queryInfo={accountQueryInfo}
      keyword={accountQueryInfo.keyword as string}
      onChangePage={onChangePage}
      accountValidate={accountValidate}
      accountValidateMsg={accountValidateMsg}
      isSelf={isSelf}
      status={status}
      onCellClick={onCellClick}
      initialPending={isLoading}
      isCreateLoading={mutationCreateAccount.isLoading}
      isUpdateLoading={mutationUpdateAccount.isLoading}
    />
  );
}
