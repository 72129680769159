import { BeatLoader } from "react-spinners";
import { LoaderSizeMarginProps } from "react-spinners/helpers/props";
import styled from "styled-components";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";

interface Props extends LoaderSizeMarginProps {
  children?: React.ReactNode;
  inline?: boolean;
}
function Spinner(props: Props): React.ReactElement<HTMLElement> {
  return (
    <Container inline={props.inline}>
      <BeatLoader color={COLORS.COMPANY} size={16} {...props} />
      {props.children}
    </Container>
  );
}

export default Spinner;

const Container = styled.div<{ inline?: boolean }>`
  width: 100%;
  ${FLEX.FlexCenterCenter};
  flex-direction: column;
  gap: 10px;
  padding-top: 50px;
  color: ${({ theme }) => theme.COLORS.WHITE};
  font-weight: 500;

  ${({ inline }) =>{
    if (inline) {
      return `
        display: inline;
        padding-top: 0;
      `
    }
  }}
  
`;
