/**
 * @author ydoni
 * @description modal 안 toggle
 *              ex) alert Rule show advanced settings
 */

import { Text } from "components/atoms/text/Text";
import Toggle from "components/atoms/toggle/Toggle";
import styled from "styled-components";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import { IoChevronDownOutline, IoChevronUp } from "react-icons/io5";

interface Items {
  title: string;
  value: boolean;
  isShow: boolean;
  children?: React.ReactNode;
  name: any;
}

type ShowToggleProps = {
  items: Array<Items>;
  onToggle: (name: any) => void;
  onChangeAlertRuleInfo?: (type: any, checked: boolean) => void;
  isRecorderPermission?: boolean;
};

function ShowToggle({
  items,
  onToggle,
  onChangeAlertRuleInfo,
  isRecorderPermission,
}: ShowToggleProps): JSX.Element {
  return (
    <Container>
      {items.map((item, index) => (
        <Item key={index}>
          <Title>
            <Label onClick={() => onToggle(item.name)}>
              <Text bold fontSize={18}>
                {item.title}
              </Text>
              {item.isShow ? <IoChevronUp /> : <IoChevronDownOutline />}
            </Label>
            {!isRecorderPermission && onChangeAlertRuleInfo && (
              <Toggle
                isToggled={item.value}
                checked={item.value}
                onChange={(
                  checked: boolean,
                  event: React.MouseEvent<HTMLButtonElement>
                ) => {
                  // e.stopPropagation();
                  onChangeAlertRuleInfo(item.name, checked);
                }}
              />
            )}
          </Title>
          {item.isShow && (
            <ShowContainer onClick={(e) => e.stopPropagation()}>
              {item.children}
            </ShowContainer>
          )}
        </Item>
      ))}
    </Container>
  );
}

const Container = styled.ul``;

const Item = styled.li`
  padding: 15px 0;
  border-bottom: 1px solid ${COLORS.BORDER};
`;

const Title = styled.p`
  ${FLEX.FlexBetweenStart};
`;

const Label = styled.div`
  ${FLEX.FlexStartCenter};
  gap: 7px;
  cursor: pointer;
  > img {
    width: 14px;
    cursor: pointer;
  }
`;

const ShowContainer = styled.div`
  padding: 10px 0;
`;

export default ShowToggle;
