import { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import * as s from "styles/ModalCommonStyled";
import { useIntl } from "react-intl";
import { getAccoountTotalList } from "api/accountAPI";
import { Account, getAccountListMappger } from "api/mappers/accountMapper";
import DebounceSearchInput from "components/atoms/input/search/DebounceSearchInput";
import React from "react";
import { TableColumnsType } from "antd";
import { VirtualTable } from "components/atoms/table/AntdVirtualTable";
import { TableRowSelection } from "antd/es/table/interface";
import { matchStringSearchKeyword } from "utils/functions";

export type SearchAccountProps = {
  accountId: string;
  placeholder?: string;
  selectedAccountId?: string[];
  disabledAccountId?: string[];
  isSingleMode?: boolean;
  onSelectedRowsChange?: (selectedRows: string[]) => void;
  disabled?: boolean;
  allSelect?: boolean;
  userId: string | undefined;
};

export interface DataRow {
  id: string;
  name: string;
  level: string;
  levelFullName: string;
  accounts: number;
  recorders: number;
}

export function SearchAccount(props: SearchAccountProps): JSX.Element {
  const intl = useIntl();

  const [accounts, setAccounts] = useState<Account[]>([]);
  const [pending, setPending] = useState<boolean>(true);
  const [searchText, setSearchText] = useState<string>("");
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>(
    (props.selectedAccountId as React.Key[]) ?? []
  );

  const columns = React.useMemo<TableColumnsType<Account>>(
    () => [
      {
        dataIndex: "name",
        title: "Name",
        render: (value) => value,
      },
      {
        dataIndex: "level",
        title: intl.formatMessage({
          id: "table.label.level",
          defaultMessage: "Level",
        }),
        render: (value) => value,
        width: 80,
        align: "center",
      },
      {
        dataIndex: "hasAccounts",
        title: intl.formatMessage({
          id: "table.label.accountCnt",
          defaultMessage: "Organizations",
        }),
        render: (value) => value,
        width: 100,
        align: "center",
      },
      {
        dataIndex: "hasRecorders",
        title: intl.formatMessage({
          id: "table.label.recorderCnt",
          defaultMessage: "Recorders",
        }),
        render: (value) => value,
        width: 80,
        align: "center",
      },
    ],
    []
  );

  const { isLoading, error, refetch } = useQuery(
    ["accountSearch", props.userId],
    () =>
      getAccoountTotalList({
        accountId: props.accountId,
        userId: props.userId,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res.result === undefined) {
          setAccounts([]);
          return;
        } else {
          // console.log(res.result);
          setAccounts(getAccountListMappger(res.result));
        }
      },
      onError: (e: any) => {
        setAccounts([]);
      },
      onSettled: () => {
        setPending(false);
      },
    }
  );

  useEffect(() => {
    if (props.allSelect) {
      let checkedRecorder: string[] = accounts.map(
        (account) => account.accountId as string
      );
      if (props.onSelectedRowsChange) {
        props.onSelectedRowsChange(checkedRecorder);
      }
    }
  }, [props.allSelect, isLoading]);

  useEffect(() => {
    if (props.selectedAccountId) {
      setSelectedRowKeys(props.selectedAccountId);
    }
  }, [props.selectedAccountId]);

  const onClickSearch = (keyword: string) => {
    setSearchText(keyword);
    // setAccountQueryInfo((info) => {
    //   return { ...info, keyword: keyword as string };
    // });
    // refetch();
  };

  const onChangeSearchText = useCallback((value: string) => {}, []);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    if (props.onSelectedRowsChange)
      props.onSelectedRowsChange(newSelectedRowKeys as string[]);
  };

  const rowSelection: TableRowSelection<Account> = {
    columnWidth: 40,
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const data: Account[] = useMemo(() => {
    return accounts.filter((rec: Account) => {
      return (
        matchStringSearchKeyword(rec.name, searchText) ||
        matchStringSearchKeyword(rec.level, searchText)
      );
    });
  }, [searchText, accounts]);

  return (
    <>
      <DebounceSearchInput
        placeholder={props.placeholder}
        onClickSearch={onClickSearch}
        onChange={onChangeSearchText}
      />
      <s.ModalTableContainer>
        <VirtualTable
          columns={columns}
          dataSource={data}
          rowSelection={rowSelection}
          loading={pending}
          rowKey="accountId"
        />
      </s.ModalTableContainer>
    </>
  );
}
