import { createSlice } from "@reduxjs/toolkit";

interface State {
  pageContainer: HTMLDivElement | null,
  pageContainerReq: number,
}

const initialState: State = {
  pageContainerReq: 0,
  pageContainer: null,
};


export const HtmlContainerReducer = createSlice({
  name: "htmlContainer",
  initialState,
  reducers: {
    requestPageContainer(state, action) {
      return {
        ...state,
        pageContainerReq: new Date().valueOf(),
      }
    },
    setPageContainer(state, action) {
      const pageContainer = action.payload;
      return {
        ...state,
        pageContainer: pageContainer || null,
      }
    },
  },
});

export const { setPageContainer, requestPageContainer } = HtmlContainerReducer.actions;

export default HtmlContainerReducer;
