import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import {
  CustomError,
  LEVEL_TYPE,
  REPORT_DETECT_TIME,
  Validate,
  ValidateMsg,
} from "api/interfaces/commonInterface.interface";
import {
  CPReport,
  CPReportDataRow,
  NotifyUser,
  NotifyUserGroup,
} from "api/interfaces/reportInterface.interface";
import {
  deleteReport,
  postCpReportCreate,
  putHealthReportCreate,
  putReportEnable,
} from "api/reportAPI";
import { notify } from "components/atoms/notification/Notification";
import { useAuth } from "components/Auth";
import { useCallback, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useAppSelector } from "redux/hooks";
import { NOTIFICATION_TYPE } from "../common/AddNotification";
import AddCpPresenter from "./AddCpPresenter";
import { useIntl } from "react-intl";
import { isEmpty } from "lodash";
import useApiError from "hook/useApiError";

export enum UPDATE_TYPE {
  AUTO_APPLY,
  ACCOUNT,
  REPORT_NAME,
  DESC,
  REPORT_ENABLE,
}

type Props = {
  onModal: (type?: string) => void;
  isEdit?: boolean;
  reportRow?: CPReportDataRow;
  data?: CPReport;
};

export default function AddCpContainer(props: Props): JSX.Element {
  const auth = useAuth();
  const intl = useIntl();
  const { handleError } = useApiError();

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const [isConfirm, SetIsConfirm] = useState<boolean>(false);
  const [saveIsInProgress, setSaveIsInProgress] = useState<boolean>(false);

  const [page, setPage] = useState<number>(0);

  const [checkedAccount, setCheckedAccount] = useState<string[]>([]);

  const [healthReport, setHealthReport] = useState<CPReport>({
    account: {
      autoApply:
        props.isEdit && props.data !== undefined
          ? (props.data.account.autoApply as boolean)
          : false,
      cpAccountIds:
        props.isEdit &&
        props.data !== undefined &&
        props.data.account.cpAccountIds !== undefined
          ? props.data.account.cpAccountIds
          : [],
      euAccountIds:
        props.isEdit &&
        props.data !== undefined &&
        props.data.account.euAccountIds !== undefined
          ? props.data.account.euAccountIds
          : [],
    },
    notification: {
      dayTime:
        props.isEdit && props.data !== undefined
          ? (props.data.notification.dayTime as string)
          : "09:00", // 하나있을때는 DAY만
      nightTime:
        props.isEdit && props.data !== undefined
          ? (props.data.notification.nightTime as string)
          : "00:00",
      intervalType:
        props.isEdit && props.data !== undefined
          ? (props.data.notification.intervalType as REPORT_DETECT_TIME)
          : REPORT_DETECT_TIME.WEEK, //Month, Week
      weeklyValue:
        props.isEdit && props.data !== undefined
          ? (props.data.notification.weeklyValue as Array<boolean>)
          : Array(7).fill(true),
      monthlyValue:
        props.isEdit && props.data !== undefined
          ? (props.data.notification.monthlyValue as number)
          : 1,
      isEndOfMonth:
        props.isEdit && props.data !== undefined
          ? (props.data.notification.isEndOfMonth as boolean)
          : false,
      users:
        props.isEdit && props.data !== undefined
          ? (props.data.notification.users as Array<NotifyUser>)
          : [],
      userGroups:
        props.isEdit && props.data !== undefined
          ? (props.data.notification.userGroups as Array<NotifyUserGroup>)
          : [],
    },
    preferences: {
      host:
        props.isEdit && props.data !== undefined
          ? (props.data.preferences.host as string)
          : auth.user.name.concat(`(${auth.user.email})`),
      title:
        props.isEdit && props.data !== undefined
          ? (props.data.preferences.title as string)
          : "",
      description:
        props.isEdit && props.data !== undefined
          ? (props.data.preferences.description as string)
          : "",
      isEnable:
        props.isEdit && props.data !== undefined
          ? (props.data.preferences.isEnable as boolean)
          : true,
      userId:
        props.isEdit && props.data !== undefined
          ? (props.data.preferences.userId as string)
          : undefined,
    },
  });

  useEffect(() => {
    if (props.data) {
      setHealthReport({
        account: {
          autoApply: props.isEdit
            ? (props.data.account.autoApply as boolean)
            : false,
          cpAccountIds:
            props.isEdit && props.data.account.cpAccountIds !== undefined
              ? props.data.account.cpAccountIds
              : [],
          euAccountIds:
            props.isEdit && props.data.account.euAccountIds !== undefined
              ? props.data.account.euAccountIds
              : [],
        },
        notification: {
          dayTime: props.isEdit
            ? (props.data.notification.dayTime as string)
            : "09:00", // 하나있을때는 DAY만
          nightTime: props.isEdit
            ? (props.data.notification.nightTime as string)
            : "00:00",
          intervalType: props.isEdit
            ? (props.data.notification.intervalType as REPORT_DETECT_TIME)
            : REPORT_DETECT_TIME.WEEK, //Month, Week
          weeklyValue: props.isEdit
            ? (props.data.notification.weeklyValue as Array<boolean>)
            : Array(7).fill(true),
          monthlyValue: props.isEdit
            ? (props.data.notification.monthlyValue as number)
            : 1,
          isEndOfMonth: props.isEdit
            ? (props.data.notification.isEndOfMonth as boolean)
            : false,
          users: props.isEdit
            ? (props.data.notification.users as Array<NotifyUser>)
            : [],
          userGroups: props.isEdit
            ? (props.data.notification.userGroups as Array<NotifyUserGroup>)
            : [],
        },
        preferences: {
          host: props.isEdit
            ? (props.data.preferences.host as string)
            : auth.user.name.concat(`(${auth.user.email})`),
          title: props.isEdit ? (props.data.preferences.title as string) : "",
          description: props.isEdit
            ? (props.data.preferences.description as string)
            : "",
          isEnable: props.isEdit
            ? (props.data.preferences.isEnable as boolean)
            : true,
          userId: props.isEdit
            ? (props.data.preferences.userId as string)
            : undefined,
        },
      });
      const originalChecked = mergedCheckAccount({
        cpAccountIds: props.data.account.cpAccountIds,
        euAccountIds: props.data.account.euAccountIds,
      });
      setCheckedAccount(originalChecked);
    }
  }, [auth.user.email, auth.user.name, props.data, props.isEdit]);

  const steps = [
    {
      label: "Organization",
    },
    {
      label: "Notification",
    },
    {
      label: "Preferences",
    },
  ];

  const onChangePage = (name: string, index?: number) => {
    if (name === "next") {
      setPage(page + 1);
    }
    if (name === "previous") {
      setPage(page - 1);
    }
    if (name === "save") {
      props.onModal();
    }
    if (name === "step" && index !== undefined) {
      setPage(index);
    }
  };

  const mutationCreateReport = useMutation(postCpReportCreate, {
    onSuccess: () => {
      notify(
        "success",
        intl.formatMessage({
          id: "label.report.notify.cp.create.success",
          defaultMessage: "CP report created successfully.",
        })
      );
      props.onModal("add");
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.report.notify.cp.create.fail",
          defaultMessage: "Failed to create CP report.",
        })
      );
    },
    onSettled: () => {
      setSaveIsInProgress(false);
    },
  });

  const mutationUpdateReport = useMutation(putHealthReportCreate, {
    onSuccess: () => {
      notify(
        "success",
        intl.formatMessage({
          id: "label.report.notify.cp.update.success",
          defaultMessage: "CP report updated successfully.",
        })
      );
      props.onModal("edit");
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.report.notify.cp.update.fail",
          defaultMessage: "Failed to update CP report.",
        })
      );
    },
    onSettled: () => {
      setSaveIsInProgress(false);
    },
  });

  const [reportRuleValidate, setReportRuleValidate] = useState<Validate>({
    isRecorder: false,
    isRuleName: false,
  });
  const [reportRuleValidateMsg, setReportRuleValidateMsg] =
    useState<ValidateMsg>({
      isRecorder: "",
      isRuleName: "",
    });
  const mergedCheckAccount = ({
    cpAccountIds,
    euAccountIds,
  }: {
    cpAccountIds: string[];
    euAccountIds: string[];
  }) => {
    let mergedAccount: string[] = [];
    if (cpAccountIds) {
      const tmpCp = cpAccountIds.map((value) => value.concat("_", "CP"));
      mergedAccount.push(...tmpCp);
    }

    if (euAccountIds) {
      const tmpEu = euAccountIds.map((value) => value.concat("_", "EU"));
      mergedAccount.push(...tmpEu);
    }
    return mergedAccount;
  };
  const initReportRuleValidate = useCallback((field: string) => {
    setReportRuleValidate((info) => {
      return { ...info, [field as keyof typeof reportRuleValidate]: false };
    });
    setReportRuleValidateMsg((info) => {
      return { ...info, [field as keyof typeof reportRuleValidateMsg]: "" };
    });
  }, []);

  const onClickCreate = useCallback(() => {
    //setIsAddModal(true);
    initReportRuleValidate("isRecorder");
    initReportRuleValidate("isRuleName");

    if (isEmpty(healthReport.preferences.title)) {
      setReportRuleValidate((info) => {
        return { ...info, isRuleName: true as boolean };
      });

      setReportRuleValidateMsg((info) => {
        return {
          ...info,
          isRuleName: intl.formatMessage({
            id: "validateMsg.report.rule.emptyRuleName",
            defaultMessage: "Please enter report rule name.",
          }) as string,
        };
      });
      setPage(2);
      return;
    }

    if (
      healthReport.account === undefined ||
      healthReport.account.autoApply === false
    ) {
      if (checkedAccount.length === 0) {
        setReportRuleValidate((info) => {
          return { ...info, isRecorder: true as boolean };
        });

        setReportRuleValidateMsg((info) => {
          return {
            ...info,
            isRecorder: intl.formatMessage({
              id: "validateMsg.report.rule.emptyRecorder",
              defaultMessage: "Select one or more recorders",
            }) as string,
          };
        });
        setPage(0);
        return;
      }
    }

    createAndUpdate();
  }, [
    mutationCreateReport,
    selectedAccount.accountId,
    healthReport,
    checkedAccount,
    props.isEdit,
  ]);

  useEffect(() => {
    let cpList: string[] = [];
    let euList: string[] = [];

    checkedAccount.forEach((value) => {
      if (value.endsWith(LEVEL_TYPE.CP)) {
        cpList.push(value.replace(`_CP`, ``));
      } else if (value.endsWith(LEVEL_TYPE.EU)) {
        euList.push(value.replace(`_EU`, ``));
      }
    });

    setHealthReport((info) => {
      return {
        ...info,
        account: {
          ...info.account,
          cpAccountIds: cpList,
          euAccountIds: euList,
        },
      };
    });
  }, [checkedAccount]);

  const createAndUpdate = useCallback(() => {
    // console.log(healthReport);
    setSaveIsInProgress(true);
    if (props.isEdit) {
      mutationUpdateReport.mutate({
        payload: healthReport,
        accountId: selectedAccount.accountId,
        reportId: props.reportRow?.id as string,
        type: "cp",
      });
    } else {
      mutationCreateReport.mutate({
        payload: healthReport,
        accountId: selectedAccount.accountId,
      });
    }
  }, [
    mutationUpdateReport,
    healthReport,
    mutationCreateReport,
    props.isEdit,
    selectedAccount.accountId,
    props.reportRow,
  ]);

  const onChangeNotification = useCallback(
    (
      type: NOTIFICATION_TYPE,
      value: string | number | boolean | string[] | boolean[] | number[]
    ) => {
      if (type === NOTIFICATION_TYPE.SEND_TIME) {
        setHealthReport((info) => {
          return {
            ...info,
            notification: {
              ...info.notification,
              dayTime: value as string,
            },
          };
        });
      } else if (type === NOTIFICATION_TYPE.INTERVAL) {
        setHealthReport((info) => {
          return {
            ...info,
            notification: {
              ...info.notification,
              intervalType: value as string,
            },
          };
        });
      } else if (type === NOTIFICATION_TYPE.WEEKLY_VALUE) {
        setHealthReport((info) => {
          return {
            ...info,
            notification: {
              ...info.notification,
              weeklyValue: value as boolean[],
            },
          };
        });
      } else if (type === NOTIFICATION_TYPE.MONTHLY_VALUE) {
        setHealthReport((info) => {
          return {
            ...info,
            notification: {
              ...info.notification,
              monthlyValue: value as number,
            },
          };
        });
      } else if (type === NOTIFICATION_TYPE.IS_END_OF_MONTH) {
        setHealthReport((info) => {
          return {
            ...info,
            notification: {
              ...info.notification,
              isEndOfMonth: value as boolean,
            },
          };
        });
      } else if (type === NOTIFICATION_TYPE.USER) {
        setHealthReport((info) => {
          return {
            ...info,
            notification: {
              ...info.notification,
              users: (value as string[]).map((data) => {
                return {
                  userId: data,
                  isEmailEnable: true,
                };
              }) as Array<NotifyUser>,
            },
          };
        });
      } else if (type === NOTIFICATION_TYPE.USER_GROUP) {
        setHealthReport((info) => {
          return {
            ...info,
            notification: {
              ...info.notification,
              userGroups: (value as string[]).map((data) => {
                return {
                  userGroupId: data,
                  isEmailEnable: true,
                };
              }) as Array<NotifyUserGroup>,
            },
          };
        });
      }
    },
    []
  );

  const onChangeHealthReportInfo = useCallback(
    (
      type: UPDATE_TYPE,
      value: string | number | boolean | string[] | boolean[] | number[]
    ) => {
      if (type === UPDATE_TYPE.AUTO_APPLY) {
        setHealthReport((info) => {
          return {
            ...info,
            account: {
              ...info.account,
              autoApply: value as boolean,
            },
          };
        });
      } else if (type === UPDATE_TYPE.ACCOUNT) {
        setCheckedAccount(value as string[]);
      } else if (type === UPDATE_TYPE.REPORT_NAME) {
        setHealthReport((info) => {
          return {
            ...info,
            preferences: {
              ...info.preferences,
              title: value as string,
            },
          };
        });
      } else if (type === UPDATE_TYPE.DESC) {
        setHealthReport((info) => {
          return {
            ...info,
            preferences: {
              ...info.preferences,
              description: value as string,
            },
          };
        });
      } else if (type === UPDATE_TYPE.REPORT_ENABLE) {
        setHealthReport((info) => {
          return {
            ...info,
            preferences: {
              ...info.preferences,
              isEnable: value as boolean,
            },
          };
        });
      }
    },
    []
  );

  const mutationDeleteReport = useMutation(deleteReport, {
    onSuccess: () => {
      notify(
        "success",
        intl.formatMessage({
          id: "label.report.notify.cp.delete.success",
          defaultMessage: "CP report deleted successfully.",
        })
      );
      props.onModal("edit");
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.report.notify.cp.delete.fail",
          defaultMessage: "Failed to delete CP report.",
        })
      );
    },
  });

  const onConfirmDelete = () => {
    mutationDeleteReport.mutate({
      reportId: props.reportRow?.id as string,
      accountId: selectedAccount.accountId,
    });
  };
  const setIsDeleteConfirm = (confirm: boolean) => {
    SetIsConfirm(confirm);
  };
  const onDelete = (value: boolean) => {
    SetIsConfirm(value);
  };

  const mutationEnableReport = useMutation(putReportEnable, {
    onSuccess: (res: any, variables: any) => {
      onChangeHealthReportInfo(
        UPDATE_TYPE.REPORT_ENABLE,
        variables.payload.enable
      );
      //notify("success", "Success : Success to Enable Health Report.");
    },
    onError: () => {
      //notify("error", "Error : Failed to delete Health Report.");
    },
  });

  const onChangeEnable = (value: boolean) => {
    mutationEnableReport.mutate({
      reportId: props.reportRow?.id as string,
      payload: {
        enable: value,
      },
    });
  };

  return (
    <AddCpPresenter
      steps={steps}
      page={page}
      reportRow={props.reportRow}
      onChangePage={onChangePage}
      selectedAccount={selectedAccount}
      onChangeHealthReportInfo={onChangeHealthReportInfo}
      onChangeNotification={onChangeNotification}
      hostName={auth.user?.name + "(" + auth.user?.email + ")"}
      onClickCreate={onClickCreate}
      data={healthReport}
      isEdit={props.isEdit}
      setIsDeleteConfirm={setIsDeleteConfirm}
      isConfirm={isConfirm}
      onDelete={onDelete}
      onConfirmDelete={onConfirmDelete}
      onChangeEnable={onChangeEnable}
      reportRuleValidate={reportRuleValidate}
      reportRuleValidateMsg={reportRuleValidateMsg}
      selectedAccountList={checkedAccount}
      saveIsInProgress={saveIsInProgress}
    />
  );
}
