import SystemSpecs from "pages/reports/view/health/component/type/systemspecs";
import * as s from "./RecorderDetailStyled";

type Props = { type: string; recorderId: string; brand?: string };

const RecorderDetailSystem = (props: Props): JSX.Element => {
  return (
    <s.SystemWrapper>
      <SystemSpecs
        type={props.type}
        recorderId={props.recorderId}
        brand={props.brand}
      />
    </s.SystemWrapper>
  );
};

export default RecorderDetailSystem;
