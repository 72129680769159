import styled from "styled-components";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import * as mediaQuery from "components/MediaQuery";
import { noScrollBar } from "styles/commonStyles";

export const Container = styled.div<{ center?: boolean }>`
  width: 100vw;
  min-height: 100dvh;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 998;
  background-color: rgba(0, 0, 0, 0.8);
  ${FLEX.FlexCenterStart};
  overflow-y: auto;
  ${noScrollBar};

  button {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  ${mediaQuery.isMobile} {
    ${({ center }) =>
      center &&
      `
    ${FLEX.FlexCenterCenter};
    
    `}
  }
`;

/**
 * big popup
 */

export const Modal = styled.section`
  min-width: 700px;
  max-width: 700px;
  background-color: ${({ theme }) => theme.COLORS.PRIMARY2};
  padding: 15px;
  border-radius: 4px;
  ${FLEX.FlexCenterCenter};
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 30px 0;

  ${mediaQuery.isMobile} {
    min-width: 95%;
    max-width: 95%;
    margin: 10px 0;
    border-radius: 8px;
  }
`;

export const Inner = styled.div`
  width: 90%;
  ${FLEX.FlexCenterCenter};
  flex-direction: column;
  padding: 20px 0;

  ${mediaQuery.isMobile} {
    min-width: 100%;
  }
`;

/**
 * small popup
 */

export const SmallContainer = styled.div`
  width: 100%;
  height: 100%;
  ${FLEX.FlexCenterCenter};

  ${mediaQuery.isMobile} {
    margin: 100px 50px;
  }
`;
export const SmallWrapper = styled.div`
  box-sizing: border-box;
  background-color: ${({ theme }) =>
    theme.value === "light" ? theme.COLORS.PRIMARY2 : theme.COLORS.PRIMARY1};
  border-radius: 10px;
  /* overflow: hidden; */
  position: relative;

  ${mediaQuery.isDefault} {
    width: 32vw;
    min-width: fit-content;
    max-width: 580px;
  }

  ${mediaQuery.isMobile} {
    width: 90vw;
  }
`;

export const SmallInner = styled.div`
  margin-top: 10px;
  padding: 30px;
  box-sizing: border-box;
  width: 100%;
`;

export const SmallContents = styled.div`
  padding: 20px 0;
`;

export const SmallTitleWrapper = styled.div`
  ${FLEX.FlexStartStart};
  flex-direction: column;
  gap: 6px;

  .subtitle {
    color: ${({ theme }) =>
      theme.value === "light" ? `${COLORS.BLACK}80` : "rgba(255,255,255,0.41)"};
  }
`;

export const SmallButtonWrapper = styled.div`
  background-color: ${({ theme }) =>
    theme.value === "light" ? theme.COLORS.PRIMARY3 : "#131b33"};
  width: 100%;
  ${FLEX.FlexCenterCenter};
  padding: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const SmallDeactivateTitle = styled(SmallTitleWrapper)`
  ${FLEX.FlexCenterCenter};
  color: ${({ theme }) => theme.COLORS.WHITE};
  font-weight: 500;
`;

export const ButtonWrapper = styled.div`
  ${FLEX.FlexCenterCenter};
  gap: 10px;

  button {
    min-width: 64px;
  }
`;
