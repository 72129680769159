import { ReactComponent as RecorderSVG } from "assets/icons/sidenav/recorder.svg";
import { ReactComponent as LocationSVG } from "assets/icons/map-pin.svg";
import { ReactComponent as UserSVG } from "assets/icons/user-white.svg";
import { ReactComponent as CameraSVG } from "assets/icons/camera.svg";
import { ReactComponent as UserGroupSVG } from "assets/icons/users-white.svg";
import { ReactComponent as ReportSVG } from "assets/icons/sidenav/reports.svg";
import { ReactComponent as AlertSVG } from "assets/icons/sidenav/alerts.svg";
import { ReactComponent as AccountSVG } from "assets/icons/sidenav/account.svg";
import { ReactComponent as VideoSVG } from "assets/icons/sidenav/videoshare.svg";

import {
  DashboardAlertData,
  DashboardData,
  DashboardFilterResult,
  DashboardReduxData,
} from "api/interfaces/dashboardInterface.interface";

export const SEARCH_TYPE = {
  RECORDER: { name: "recorder", value: 0 },
  RECORDERGROUP: { name: "recorderGroup", value: 8 },
  LOCATION: { name: "location", value: 1 },
  CAMERA: { name: "camera", value: 4 },
  ALERT: { name: "alert", value: 2 },
  REPORT: { name: "report", value: 3 },
  USER: { name: "user", value: 5 },
  USERGROUP: { name: "userGroup", value: 6 },
  ACCOUNT: { name: "account", value: 7 },
  VIDEOSHARE: { name: "videoShare", value: 9 },
};

// 아이콘 타입과 해당 SVG 컴포넌트의 매핑
const iconComponents: { [key: string]: JSX.Element } = {
  recorder: <RecorderSVG />,
  recorderGroup: <RecorderSVG />,
  location: <LocationSVG />,
  camera: <CameraSVG />,
  alert: <AlertSVG />,
  report: <ReportSVG />,
  videoShare: <VideoSVG />,
  user: <UserSVG />,
  userGroup: <UserGroupSVG />,
  account: <AccountSVG />,
};

export const changeTypeToIcon = (type: string): JSX.Element | null => {
  // 해당하는 아이콘이 있는지 확인하고 반환
  return iconComponents[type] || null;
};

export const changeDashboardDataFormat = (data: DashboardData[]) => {
  let newState: DashboardReduxData = {
    spectrum: {
      systemAlert: 0,
      diskAlert: 0,
      videoAlert: 0,
      alertList: [],
      recorderCount: 0,
    },
    nvr: {
      systemAlert: 0,
      diskAlert: 0,
      videoAlert: 0,
      alertList: [],
      recorderCount: 0,
    },
    dvr: {
      systemAlert: 0,
      diskAlert: 0,
      videoAlert: 0,
      alertList: [],
      recorderCount: 0,
    },
    cova: {
      systemAlert: 0,
      diskAlert: 0,
      videoAlert: 0,
      alertList: [],
      recorderCount: 0,
    },
    recorderList: [],
  };
  data.forEach((recorder: DashboardData, index) => {
    const recorderType =
      recorder.type !== undefined ? recorder.type.toUpperCase() : "";

    switch (recorderType) {
      case "SPECTRUM":
        newState.spectrum.recorderCount = newState.spectrum.recorderCount + 1;
        newState.spectrum.systemAlert =
          newState.spectrum.systemAlert + recorder.system;
        newState.spectrum.diskAlert =
          newState.spectrum.diskAlert + recorder.disk;
        newState.spectrum.videoAlert =
          newState.spectrum.videoAlert + recorder.video;
        newState.spectrum.alertList.push(
          ...recorder.alerts,
          ...recorder.assign,
          ...recorder.critical
        );
        newState.recorderList.push(recorder);
        break;
      case "NVR":
        newState.nvr.recorderCount = newState.nvr.recorderCount + 1;
        newState.nvr.systemAlert = newState.nvr.systemAlert + recorder.system;
        newState.nvr.diskAlert = newState.nvr.diskAlert + recorder.disk;
        newState.nvr.videoAlert = newState.nvr.videoAlert + recorder.video;
        newState.nvr.alertList.push(
          ...recorder.alerts,
          ...recorder.assign,
          ...recorder.critical
        );
        newState.recorderList.push(recorder);
        break;
      case "DVR":
        newState.dvr.recorderCount = newState.dvr.recorderCount + 1;
        newState.dvr.systemAlert = newState.dvr.systemAlert + recorder.system;
        newState.dvr.diskAlert = newState.dvr.diskAlert + recorder.disk;
        newState.dvr.videoAlert = newState.dvr.videoAlert + recorder.video;
        newState.dvr.alertList.push(
          ...recorder.alerts,
          ...recorder.assign,
          ...recorder.critical
        );
        newState.recorderList.push(recorder);
        break;
      case "COVA":
        newState.dvr.recorderCount = newState.dvr.recorderCount + 1;
        newState.dvr.systemAlert = newState.dvr.systemAlert + recorder.system;
        newState.dvr.diskAlert = newState.dvr.diskAlert + recorder.disk;
        newState.dvr.videoAlert = newState.dvr.videoAlert + recorder.video;
        newState.dvr.alertList.push(
          ...recorder.alerts,
          ...recorder.assign,
          ...recorder.critical
        );
        newState.recorderList.push(recorder);
        break;
      default:
        break;
    }
  });
  return newState;
};
export const filteringRecorder = (
  recorder: DashboardFilterResult
): DashboardData => {
  let systemCount = 0;
  let diskCount = 0;
  let videoCount = 0;

  const tempWarnings: DashboardAlertData[] = [];
  const tempAlerts: DashboardAlertData[] = [];
  const tempCritical: DashboardAlertData[] = [];

  const incrementCount = (category: string) => {
    switch (category.toLowerCase()) {
      case "system":
        systemCount++;
        break;
      case "disk":
      case "storage":
        diskCount++;
        break;
      case "video":
        videoCount++;
        break;
      default:
        break;
    }
  };

  const processAlerts = (
    alerts: DashboardAlertData[] | undefined,
    isWarning = false
  ) => {
    if (!alerts) return;

    alerts.forEach((alert) => {
      if (isWarning) {
        tempWarnings.push(alert);
      } else {
        incrementCount(alert.category);
        if (alert.isCriticalSeverity) {
          tempCritical.push(alert);
        } else {
          tempAlerts.push(alert);
        }
      }
    });
  };

  processAlerts(recorder.warnings, true);
  processAlerts(recorder.alerts);

  const tempRecorder: DashboardData = {
    ...recorder,
    location: recorder.location?.location || "",
    latitude: recorder.location?.latitude || 0,
    longitude: recorder.location?.longitude || 0,
    thumbnail: recorder.customMapUrl || recorder.thumbnailUrl,
    warnings: tempWarnings,
    alerts: tempAlerts,
    assign: [],
    critical: tempCritical,
    system: systemCount,
    disk: diskCount,
    video: videoCount,
    recorders: [],
  };

  return tempRecorder;
};
