import * as s from "./AlertViewStyled";
import { Text } from "components/atoms/text/Text";
import CLOSE_ICON from "assets/icons/sidenav/x.svg";
import * as mediaQuery from "components/MediaQuery";

import { AlertType, AlertViewInfo } from "api/interfaces/alertInterface.interface";

import Type from "components/atoms/text/labels/Type";
import { useAppSelector } from "redux/hooks";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import WebAppLink from "components/blocks/link/WebAppLink";
import { useQueryClient } from "react-query";
import { convertType } from "utils/functions";
import TinyButton from "components/atoms/buttons/TinyButton";
import RBACWrapper from "components/blocks/function/RBACWrapper";

interface AlertViewHeaderProps {
  alertInfo: AlertViewInfo | null;
  alertTypeList: AlertType[];
  isModal?: boolean;
  onModal?: () => void;
  onCloseAllStatus: () => void;
}

const AlertViewHeader = (props: AlertViewHeaderProps) => {
  const queryClient = useQueryClient();
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  return (
    <s.AlertModalHeader>
      <mediaQuery.Default>
        {props.isModal && (
          <s.CloseButton
            onClick={() => {
              props.onModal && props.onModal();
              queryClient.invalidateQueries("activeAlert");
            }}
          >
            <img alt="close button" src={CLOSE_ICON} />
          </s.CloseButton>
        )}

        <s.HeaderInner isModal={props.isModal}>
          {props.alertInfo && (
            <s.HeaderSub isModal={props.isModal}>
              <Type type={convertType(props.alertInfo)} />
              <div className="system-title">
                <Text bold className="system-name" fontSize={24}>
                  {props.alertInfo.mergedSystemName ??
                    props.alertInfo.recorderName}
                </Text>
                {props.alertInfo.type === "Spectrum" && (
                  <Text fontSize={14} color="#828B9B" className="server-name">
                    {props.alertInfo.recorderName}
                  </Text>
                )}
                <div className="web-app-link-wrapper">
                  <WebAppLink
                    {...props.alertInfo}
                    accountId={selectedAccount.accountId}
                  />
                </div>
              </div>
              
              {!props.isModal && !(
                props.alertTypeList?.length === 1 && 
                props.alertTypeList[0].status ==="Closed") && (
                <RBACWrapper requiredPermissions={"manageAlertRules"}>
                <s.AlertStatusStyled.AlertStatusChange>
                  <TinyButton
                    onClickBtn={props.onCloseAllStatus}
                    label="CLOSE ALL"
                    buttonType="warning"
                  />
                </s.AlertStatusStyled.AlertStatusChange>
                </RBACWrapper>
              )}
            </s.HeaderSub>
          )}
        </s.HeaderInner>
      </mediaQuery.Default>
      <mediaQuery.Mobile>
        <s.HeaderInner>
          <div className="wep-app-link">
            {props.alertInfo && (
              <WebAppLink
                {...props.alertInfo}
                accountId={selectedAccount.accountId}
              />
            )}
          </div>
          {props.isModal && (
            <s.CloseButton
              onClick={() => {
                props.onModal && props.onModal();
                queryClient.invalidateQueries("activeAlert");
              }}
            >
              <img alt="close button" src={CLOSE_ICON} />
            </s.CloseButton>
          )}
        </s.HeaderInner>
        <s.HeaderInner>
          {props.alertInfo && (
            <s.HeaderSub>
              <Type type={convertType(props.alertInfo)} />
              <div className="system-title">
                <Text fontSize={24} bold className="system-name">
                  {props.alertInfo.mergedSystemName ??
                    props.alertInfo.recorderName}
                </Text>
                {props.alertInfo.type === "Spectrum" && (
                  <Text fontSize={14} color="#828B9B" className="server-name">
                    {props.alertInfo.recorderName}
                  </Text>
                )}
              </div>
            </s.HeaderSub>
          )}
        </s.HeaderInner>
      </mediaQuery.Mobile>
    </s.AlertModalHeader>
  );
};

export default AlertViewHeader;
