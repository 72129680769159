import { Text } from "components/atoms/text/Text";
import { COLORS } from "styles/colors";
import SmallPopupDefaultTemplate from "components/templates/modal/small/SmallPopupDefaultTemplate";
import BigButton from "components/atoms/buttons/BigButton";
import styled from "styled-components";
import { calcRem } from "styles/theme";
import { FLEX } from "styles/flex";
import { useIntl } from "react-intl";
import {
  ActiveAlert,
  AlertList,
  HistoryDetailAlert,
  AlertViewInfo,
} from "api/interfaces/alertInterface.interface";
import * as s from "components/blocks/modalheader/ModalHeaderStyled";
import { useRef } from "react";
type Props = {
  isAll?: boolean;
  onConfirmCancel: () => void;
  onConfirmOk: (checked: boolean) => void;
  alertInfo: AlertViewInfo;
  currentAlert: AlertList;
  onChangeConfirm?: (e: any) => void;
};

export default function AlertChangeConfirm({
  isAll,
  onConfirmCancel,
  onConfirmOk,
  alertInfo,
  currentAlert,
  onChangeConfirm,
}: Props): JSX.Element {
  const intl = useIntl();

  const checkboxRef = useRef<any>(null);

  return (
    <SmallPopupDefaultTemplate
      onModal={onConfirmCancel}
      button={
        <ButtonWrapper>
          <BigButton
            buttonType="cancel"
            label="No"
            onClickBtn={onConfirmCancel}
          />
          <BigButton
            label="Yes"
            onClickBtn={() => {
              onConfirmOk(
                isAll ? false : checkboxRef.current.checked ? true : false
              );
            }}
          />
        </ButtonWrapper>
      }
    >
      <DeleteTextWrapper>
        <Text fontSize={20} bold color={COLORS.COMPANY}>
          <strong>
            {alertInfo.mergedSystemName !== undefined
              ? alertInfo.mergedSystemName
              : alertInfo.recorderName}
          </strong>
          {alertInfo.type === "Video Loss" && currentAlert.camera
            ? currentAlert.camera.cameraName !== undefined &&
              currentAlert.camera.cameraName === ""
              ? ` [ ${currentAlert.camera.channel} ]`
              : ` [ ${currentAlert.camera.cameraName} ]`
            : ""}
        </Text>
        <Text fontSize={18} bold>
          {intl.formatMessage({
            id: "alert.popup.label.confirm.message",
            defaultMessage: "Confirm the alert has been resolved?",
          })}
        </Text>
        {!isAll && (
          <CheckConfirm>
            <label>
              <input
                type="checkbox"
                ref={checkboxRef}
                key={"alert_change_check"}
                defaultChecked={false}
                onChange={onChangeConfirm}
              />
              <Text fontSize={14}>
                {intl.formatMessage({
                  id: "alert.popup.label.confirm.check",
                  defaultMessage: "Don't ask again in this alert view",
                })}
              </Text>
            </label>
          </CheckConfirm>
        )}
      </DeleteTextWrapper>
    </SmallPopupDefaultTemplate>
  );
}
export const DeleteTextWrapper = styled.div`
  text-align: center;
  padding-top: 20px;
  line-height: ${calcRem(28)};
  width: 100%;
  ${FLEX.FlexStartCenter};
  flex-direction: column;
  gap: 5px;
`;

const ButtonWrapper = styled.div`
  ${FLEX.FlexCenterCenter};
  gap: 10px;
`;

const CheckConfirm = styled(s.Row)`
  padding-top: 18px;
`;
