import { Client, IMessage } from '@stomp/stompjs';
import { setConnected, setDisconnected, recorderListUpdated, userUpdated, userGroupUpdated } from './stompSlice';
import { getCookie } from 'cookies/cookie';
import { ACCESS_TOKEN } from 'api/axiosInstance';
import { Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { StompMessageBody } from './interface';
import { UserInfo } from 'api/interfaces/userInterface.interface';

export const stompMiddleware = ({ dispatch }: { dispatch: Dispatch}) => {
  const client = new Client({
    brokerURL: process.env.REACT_APP_WEBSOCKET_URL,
    reconnectDelay: 5000,
    heartbeatIncoming: 4000,
    heartbeatOutgoing: 4000,
  });

  client.debug = (str) => {
    if (process.env.NODE_ENV === "production") {
      return;
    }
    console.log('=> ', str, new Date());
  }

  client.onStompError = (frame) => {
    console.error('Broker reported error: ' + frame.headers['message']);
    console.error('Additional details: ' + frame.body);
    dispatch(setDisconnected());
  }

  client.onDisconnect = () => {
    dispatch(setDisconnected());
  }

  const messageHandler = (message: IMessage) => {
    console.log('Message:' + message.body);
    const body = JSON.parse(message.body) as StompMessageBody;
    switch(body.kind) {
      case 'recorder': {
        dispatch(recorderListUpdated(body.payload));
        break;
      }
      case 'user': {
        dispatch(userUpdated(body.payload));
        break;
      }
      case 'userGroup': {
        dispatch(userGroupUpdated(body.payload));
        break;
      }
      default: {
        return;
      }
    }
  }

  const createConnectHandler = (user: UserInfo) => {
    return () => {
      dispatch(setConnected());
      client.subscribe(
        `/topic/${user.email}`,
        messageHandler,
        { ack: 'client' }
      );
    }
  }

  return (next: Dispatch) => (action: PayloadAction<UserInfo>) => {
    switch (action.type) {
      case 'connect': {
        client.connectHeaders = {
          Authorization: `Bearer ${getCookie(ACCESS_TOKEN)}`,
        };
        client.onConnect = createConnectHandler(action.payload);
        client.activate();
        break;
      }
      case 'disconect': {
        client.deactivate();
        break;
      }
      default: {
        return next(action);
      }
    }
  }
}