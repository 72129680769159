import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { getRecorderGroupTotalList } from "api/recorderAPI";
import React from "react";
import { VirtualTable } from "components/atoms/table/AntdVirtualTable";
import { TableColumnsType } from "antd";
import { TableRowSelection } from "antd/es/table/interface";
import { matchStringSearchKeyword } from "utils/functions";
import {
  RecorderGroup,
  RecorderGroupSystem,
} from "api/interfaces/recorderInterface.interface";

export type SearchRecorderGroupProps = {
  accountId: string;
  selectedRecorderGroupRow?: number[];
  onSelectedRowsChange?: (selectedRows: number[]) => void;
  search?: string;
  userId: string | undefined;
  isApplyRecorder?: boolean;
};

export function SearchRecorderGroup(
  props: SearchRecorderGroupProps
): JSX.Element {
  const [groups, setGroups] = useState<RecorderGroup[]>([]);
  const [pending, setPending] = useState<boolean>(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const columns: TableColumnsType<RecorderGroup> = useMemo(
    () => [
      {
        key: "name",
        dataIndex: "name",
        title: "Name",
        render: (value, row) => value,
        ellipsis: true,
        width: 180,
      },
      {
        key: "recorders",
        dataIndex: "recorderCount",
        title: "Recorders",
        render: (value, row) => value,
        align: "center",
        width: 100,
      },
      {
        key: "description",
        dataIndex: "description",
        title: "Description",
        render: (value, row) => value,
        width: 150,
        ellipsis: true,
      },
    ],
    []
  );

  const { isLoading, isSuccess, error, refetch } = useQuery(
    ["rgroup", props.selectedRecorderGroupRow],
    () =>
      getRecorderGroupTotalList({
        accountId: props.accountId,
        userId: props.userId,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (!res.result) {
          return;
        }

        setGroups(
          res.result.map((group: any) => {
            return {
              ...group,
              key: group.recorderGroupId,
              description: group.description ?? "-",
            };
          })
        );
      },
      onError: (e: any) => {},
      onSettled: () => {
        setPending(false);
      },
    }
  );

  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    selectedRows: RecorderGroup[]
  ) => {
    console.log(selectedRows, "group selectedRows");
    let tempSelectedRows: RecorderGroupSystem[] = selectedRows
      .filter((row) => row.systems)
      .flatMap((r) => r.systems || []);

    setSelectedRowKeys(newSelectedRowKeys);

    props.onSelectedRowsChange &&
      props.onSelectedRowsChange(newSelectedRowKeys as number[]);
  };

  const rowSelection: TableRowSelection<RecorderGroup> = {
    columnWidth: 40,
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    if (props.selectedRecorderGroupRow !== undefined) {
      setSelectedRowKeys(props.selectedRecorderGroupRow as React.Key[]);
    }
  }, [props.selectedRecorderGroupRow]);

  const data: RecorderGroup[] = useMemo(() => {
    return groups.filter((rec) => {
      if (props.search) {
        return (
          matchStringSearchKeyword(rec.name, props.search) ||
          matchStringSearchKeyword(rec.description, props.search)
        );
      } else return true;
    });
  }, [props.search, groups]);

  return (
    <>
      <VirtualTable
        columns={columns}
        dataSource={data}
        rowSelection={rowSelection}
        loading={pending}
      />
    </>
  );
}
