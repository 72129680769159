import styled from "styled-components";
import InfoBubble from "./bubble/InfoBubble";
import { useRef } from "react";

const tooltipTriangleHeightInPx = 23;

const Container = styled.div<{contentHeight: number, ref: any }>`
  position: relative;
  display: flex;
  width: inherit;
  height: inherit;
  flex-direction: column;
  align-items: center;

  & > p {
    z-index: 10;
    position: absolute;
    top: ${({ contentHeight }) => `${contentHeight + tooltipTriangleHeightInPx}px;`};
    transform: scale(0);
    transform-origin: center;
    transition: all ease-out 0.1s;
  }

  &:hover > p {
    transform: scale(1);
  }
`;

type WithTooltipType = {
  tooltip: string;
  children?: JSX.Element;
}

export const WithTooltip = ({ tooltip, children }: WithTooltipType) => {
  const ref = useRef<HTMLElement>();
  const contentHeight = ref.current?.getBoundingClientRect().height || 20;
  
  return (
    <Container ref={ref} contentHeight={contentHeight}>
      {children}
      <InfoBubble>{tooltip}</InfoBubble>
    </Container>
  )
}