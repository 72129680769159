import Select, { StylesConfig } from "react-select";
import { COLORS, LIGHT_THEME_COLORS } from "styles/colors";
import { Option } from "utils/options";
import TRIANGLE_DOWN from "assets/icons/triangle_down.svg";
import { StatusTxt } from "pages/alert/AlertStyled";
import styled from "styled-components";
import { FLEX } from "styles/flex";
import { useAppSelector } from "redux/hooks";

type OptionsType = Array<Option>;

type CommonProps = {
  // clearValue: () => void,
  // getStyles: (string, any) => {},
  // getValue: () => ValueType,
  // hasValue: boolean,
  isMulti?: boolean;
  options: OptionsType;
  // selectOption: OptionType => void,
  // selectProps: any,
  // setValue: (ValueType, ActionTypes) => void,
  // emotion: any,
  defaultInputValue?: string;
  defaultValue?: any;
  value?: any;
  isDisabled?: boolean;
  onChange?: (value: string) => void;
  isAlertSelect?: boolean;
};
const customLightStyles: StylesConfig<Option> = {
  control: (styles, { isDisabled }) => {
    const backgroundColor = isDisabled
      ? LIGHT_THEME_COLORS.LIGHTGRAY
      : "transparent";
    const borderColor = isDisabled ? LIGHT_THEME_COLORS.BORDER : "#cacaca";
    const paddingRight = "8px";
    const color = COLORS.WHITE;
    const width = "300px";
    const maxWidth = "280px";
    const minHeight = "32px";
    const fontSize = "12px";

    return {
      ...styles,
      backgroundColor,
      borderColor,
      paddingRight,
      color,
      maxWidth,
      minHeight,
      fontSize,
    };
  },

  container: (styles) => ({
    ...styles,
    width: "100%",
    maxWidth: "280px",
    fontSize: 12,
  }),
  // input: (styles) => ({ ...styles, padding: 4 }),
  placeholder: (styles) => ({
    ...styles,
    color: COLORS.LIGHTGRAY,
    fontWeight: 400,
  }),
  // dropdownIndicator: (styles) => ({ ...styles, color: COLORS.BUTTONS1 }),
  indicatorSeparator: () => ({}),
  singleValue: (styles) => ({
    ...styles,
    color: COLORS.BLACK,
    paddingTop: 4,
    paddingBottom: 4,
  }),
  menu: (styles) => ({
    ...styles,
    color: COLORS.BUTTONS1,
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
};
const customStyles: StylesConfig<Option> = {
  control: (styles, { isDisabled }) => {
    const backgroundColor = isDisabled ? COLORS.BUTTONS1 : "transparent";
    const borderColor = "#cacaca";
    const paddingRight = "8px";
    const color = COLORS.WHITE;
    const width = "300px";
    const maxWidth = "280px";
    const minHeight = "32px";
    const fontSize = "12px";

    return {
      ...styles,
      backgroundColor,
      borderColor,
      paddingRight,
      color,
      maxWidth,
      minHeight,
      fontSize,
    };
  },
  input: (styles) => ({
    ...styles,
    color: COLORS.WHITE,
  }),
  container: (styles) => ({
    ...styles,
    width: "100%",
    maxWidth: "280px",
    fontSize: 12,
  }),
  // input: (styles) => ({ ...styles, padding: 4 }),
  placeholder: (styles) => ({
    ...styles,
    color: COLORS.LIGHTGRAY,
    fontWeight: 400,
  }),
  // dropdownIndicator: (styles) => ({ ...styles, color: COLORS.BUTTONS1 }),
  indicatorSeparator: () => ({}),
  singleValue: (styles) => ({
    ...styles,
    color: "#eee",
    paddingTop: 4,
    paddingBottom: 4,
  }),

  menu: (styles) => ({
    ...styles,
    color: COLORS.BUTTONS1,
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
};
const DropdownIndicator = () => (
  <DropDown src={TRIANGLE_DOWN} alt="TRIANGLE_DOWN" />
);

const OptionComponent = (a: any) => (
  <CustomOption {...a.innerProps} isSelected={a.isSelected}>
    {a.label}
    <StatusTxt string={a.data.status}>{a.data.status}</StatusTxt>
  </CustomOption>
);

const AlertSelects = (props: CommonProps) => {
  const themeMode = useAppSelector((state) => state.theme.theme);

  return (
    <Select
      {...props}
      styles={themeMode === "light" ? customLightStyles : customStyles}
      components={
        props.isAlertSelect
          ? { DropdownIndicator, Option: OptionComponent }
          : { DropdownIndicator }
      }
      // theme={(theme) => ({
      //   ...theme,
      //   coor
      // })}
      placeholder="Select"
      menuPortalTarget={document.querySelector("body")}
      onChange={({ value }) => {
        if (props.onChange) {
          props.onChange(value);
        }
      }}
    />
  );
};

const CustomOption = styled.div<{ isSelected: boolean }>`
  ${FLEX.FlexBetweenCenter}
  padding: 8px;
  border-bottom: 1px solid ${COLORS.BUTTONS1}39;
  color: ${COLORS.BLACK};
  cursor: pointer;
  &:hover {
    background-color: ${COLORS.SELECTED}10;
  }
  background-color: ${({ isSelected }) => isSelected && COLORS.SELECTED};
  z-index: 8888;
`;

export default AlertSelects;
const DropDown = styled.img`
  width: 10px;
`;
