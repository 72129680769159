import { Location, useLocation, useNavigate } from "react-router-dom";
import { TroubleManagerPresenter } from "./TroubleManagerPresenter";
import { useIntl } from "react-intl";
import { useAuth } from "components/Auth";
import { useAppSelector } from "redux/hooks";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import {
  GetTechSupportListResponse,
  TechSupportDto,
} from "api/interfaces/managerInterface.interface";
import { useCallback, useEffect, useState } from "react";
import {
  BasePagination,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import TableButton from "components/atoms/buttons/TableButton";
import TableColumnFilterTitle from "components/blocks/table/TableColumnFilterTitle";
import { useQuery } from "react-query";
import { getTechSupportList } from "api/managerAPI";
import { isNull } from "lodash";
import { getAccountTimeFormat } from "utils/timeUtil";
import { queryNavigate } from "utils/MenuUtil";
import TechStatus from "components/atoms/text/labels/TechStatus";
import { useQueryClient } from "react-query";
import { TableColumnsType } from "antd";
import {
  changeSortOrderAntd,
  onSortAntd,
} from "components/atoms/table/AntdTable";
import { ColumnType } from "antd/es/table";
import { Row } from "pages/recorders/RecordersStyled";

const initialQueryInfo = {
  total: 0,
  pageNum: 0,
  pageLimit: 20,
  keyword: "",
  sortType: "createdAt",
  sortDirection: SORT_DIRECTION.DESC,
  status: null,
  category: null,
};

export default function TroubleManagerContainer(): JSX.Element {
  const navigate = useNavigate();
  const location: Location = useLocation();
  const queryClient = useQueryClient();
  const intl = useIntl();
  const auth = useAuth();

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const [troubleList, setTroubleList] = useState<TechSupportDto[]>([]);
  const [selectedTrouble, setSelectedTrouble] = useState<TechSupportDto>();

  const [pending, setPending] = useState<boolean>(true);

  const [status, setStatus] = useState({
    totalTitle: "Total",
    totalValue: 0,
  });
  const [showPopup, setShowPopup] = useState<boolean>(false);

  const [troubleListQueryInfo, setTroubleListQueryInfo] =
    useState<BasePagination>({
      ...initialQueryInfo,
    });

  const stausFilters = [
    {
      label: "Open",
      value: "open",
    },
    {
      label: "In-Progress",
      value: "inProgress",
    },
    {
      label: "Closed",
      value: "closed",
    },
  ];

  const categoryFilters = [
    {
      label: "Tech Support",
      value: "techSupport",
    },
    {
      label: "Issue Report",
      value: "issueReport",
    },
  ];

  useEffect(() => {
    const changeParam = new URLSearchParams(location.search);
    if (changeParam.size > 0) {
      setTroubleListQueryInfo((info) => {
        return {
          ...info,
          pageNum: isNull(changeParam.get("pageNum"))
            ? 0
            : Number(changeParam.get("pageNum")),
          pageLimit: isNull(changeParam.get("pageLimit"))
            ? 20
            : Number(changeParam.get("pageLimit")),
          keyword: isNull(changeParam.get("keyword"))
            ? ""
            : (changeParam.get("keyword") as string),
          sortType: isNull(changeParam.get("sortType"))
            ? "createdAt"
            : (changeParam.get("sortType") as string),
          sortDirection: isNull(changeParam.get("sortDirection"))
            ? SORT_DIRECTION.DESC
            : (changeParam.get("sortDirection") as SORT_DIRECTION),
          category: isNull(changeParam.get("category"))
            ? null
            : (changeParam.get("category") as string),
          status: isNull(changeParam.get("status"))
            ? null
            : (changeParam.get("status") as string),
        };
      });
    } else {
      setTroubleListQueryInfo((info) => {
        return {
          ...info,
          pageNum: 0,
          pageLimit: 20,
          keyword: "",
          sortType: "createdAt",
          sortDirection: SORT_DIRECTION.DESC,
          category: null,
          status: null,
        };
      });
    }
  }, [location]);

  const onChangeTechStatusType = (value: string | undefined) => {
    if (value !== undefined) {
      if (value === "all") {
        if (troubleListQueryInfo.status !== null) {
          queryNavigate(navigate, location.pathname, {
            pageLimit: troubleListQueryInfo.pageLimit,
            pageNum: 0,
            keyword: troubleListQueryInfo.keyword as string,
            sortType: troubleListQueryInfo.sortType,
            sortDirection: troubleListQueryInfo.sortDirection as SORT_DIRECTION,
            category: troubleListQueryInfo.category,
            status: null,
          });
        } else {
          troubleQuery.refetch();
        }
        setPending(true);
        // setTroubleListQueryInfo((info) => {
        //   return { ...info, status: null, pageNum: 0 };
        // });
      } else {
        if (troubleListQueryInfo.status !== value) {
          queryNavigate(navigate, location.pathname, {
            pageLimit: troubleListQueryInfo.pageLimit,
            pageNum: 0,
            keyword: troubleListQueryInfo.keyword as string,
            sortType: troubleListQueryInfo.sortType,
            sortDirection: troubleListQueryInfo.sortDirection as SORT_DIRECTION,
            category: troubleListQueryInfo.category,
            status: value,
          });
        } else {
          troubleQuery.refetch();
        }
        setPending(true);
        // setTroubleListQueryInfo((info) => {
        //   return { ...info, status: value as string, pageNum: 0 };
        // });
      }
    }
  };

  const onChangeCategory = (value: string | undefined) => {
    if (value !== undefined) {
      if (value === "all") {
        if (troubleListQueryInfo.category !== null) {
          queryNavigate(navigate, location.pathname, {
            pageLimit: troubleListQueryInfo.pageLimit,
            pageNum: 0,
            keyword: troubleListQueryInfo.keyword as string,
            sortType: troubleListQueryInfo.sortType,
            sortDirection: troubleListQueryInfo.sortDirection as SORT_DIRECTION,
            category: null,
            status: troubleListQueryInfo.status,
          });
        } else {
          troubleQuery.refetch();
        }
        // setTroubleListQueryInfo((info) => {
        //   return { ...info, category: null, pageNum: 0 };
        // });
      } else {
        if (troubleListQueryInfo.category !== value) {
          queryNavigate(navigate, location.pathname, {
            pageLimit: troubleListQueryInfo.pageLimit,
            pageNum: 0,
            keyword: troubleListQueryInfo.keyword as string,
            sortType: troubleListQueryInfo.sortType,
            sortDirection: troubleListQueryInfo.sortDirection as SORT_DIRECTION,
            category: value,
            status: troubleListQueryInfo.status,
          });
        } else {
          troubleQuery.refetch();
        }
        // setTroubleListQueryInfo((info) => {
        //   return { ...info, category: value as string, pageNum: 0 };
        // });
      }
      setPending(true);
    }
  };

  const onModal = useCallback(() => {
    setShowPopup(!showPopup);
    queryClient.invalidateQueries("techSupportList");
  }, [showPopup]);

  const onHeaderCell = (column: ColumnType<TechSupportDto>) => {
    return {
      onClick: () => {
        if (column.key) {
          setTroubleListQueryInfo((info) => {
            return {
              ...info,
              sortType: column.key as string,
              sortDirection: onSortAntd(info.sortDirection),
            };
          });
        }
      },
    };
  };

  const columns: TableColumnsType<TechSupportDto> = [
    {
      title: "Create At",
      render: (value, row) =>
        row.createdAt
          ? getAccountTimeFormat(row.createdAt, selectedAccount, true)
          : "",
      sorter: true,
      sortOrder: changeSortOrderAntd(troubleListQueryInfo, "createdAt"),
      defaultSortOrder: "ascend",
      onHeaderCell: onHeaderCell,
      key: "createdAt",
    },
    {
      title: (
        <TableColumnFilterTitle
          title="Category"
          filters={categoryFilters}
          onClick={onChangeCategory}
        />
      ),
      sorter: false,
      render: (value, row) =>
        categoryFilters.find((item) => item.value === row.category)!.label,
      width: 120,
    },
    {
      title: "Name",
      render: (value, row) => row.name,
      align: "center",
    },
    {
      title: "Email",
      render: (value, row) => row.email,
      align: "center",
    },
    {
      title: "Level",
      render: (value, row) => row.level,
      align: "center",
      width: 80,
    },
    {
      title: "Organization ID",
      render: (value, row) => row.accountNumber,
      width: 150,
    },
    {
      title: (
        <TableColumnFilterTitle
          title="Status"
          filters={stausFilters}
          onClick={onChangeTechStatusType}
        />
      ),
      sorter: false,
      render: (value, row) => (
        <TechStatus
          type={stausFilters.find((item) => item.value === row.status)!.label}
        />
      ),
      width: 150,
    },
    {
      title: "",
      render: (row) => (
        <Row className="table-button-wrapper">
          <TableButton label={"View"} onClick={() => onClickView(row)} />
        </Row>
      ),
      width: 80,
    },
  ];

  const onClickView = (row: TechSupportDto) => {
    setSelectedTrouble(row);
    setShowPopup(true);
  };

  const onChangePage = useCallback(
    (page: number, totalRows: number) => {
      if (page !== troubleListQueryInfo.pageNum + 1) {
        queryNavigate(navigate, location.pathname, {
          pageLimit: troubleListQueryInfo.pageLimit,
          pageNum: page - 1,
          keyword: troubleListQueryInfo.keyword as string,
          sortType: troubleListQueryInfo.sortType,
          sortDirection: troubleListQueryInfo.sortDirection as SORT_DIRECTION,
          category: troubleListQueryInfo.category,
          status: troubleListQueryInfo.status,
        });
        setPending(true);
        // setTroubleListQueryInfo((info) => {
        //   return { ...info, pageNum: (page - 1) as number };
        // });
      }
    },
    [
      location.pathname,
      navigate,
      troubleListQueryInfo.category,
      troubleListQueryInfo.keyword,
      troubleListQueryInfo.pageLimit,
      troubleListQueryInfo.pageNum,
      troubleListQueryInfo.sortDirection,
      troubleListQueryInfo.sortType,
      troubleListQueryInfo.status,
    ]
  );

  const troubleQuery = useQuery(
    ["techSupportList", troubleListQueryInfo],
    () =>
      getTechSupportList({
        payload: troubleListQueryInfo,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: GetTechSupportListResponse) => {
        setStatus({
          ...status,
          totalValue: res.page.total,
        });
        if (res.error !== 0 || res.result === undefined) {
          setTroubleList([]);
          setTroubleListQueryInfo({ ...initialQueryInfo });
        }
        setTroubleList(res.result);
        setTroubleListQueryInfo((info) => {
          return {
            ...info,
            ...res.page,
          };
        });
      },
      onError: (e: any) => {
        setTroubleList([]);
        setTroubleListQueryInfo({ ...initialQueryInfo });
      },
      onSettled: (e: any) => {
        setPending(false);
      },
    }
  );

  const onClickSearch = useCallback(
    (keyword: string) => {
      if (troubleListQueryInfo.keyword !== keyword) {
        queryNavigate(navigate, location.pathname, {
          pageLimit: troubleListQueryInfo.pageLimit,
          pageNum: 0,
          keyword: keyword,
          sortType: troubleListQueryInfo.sortType,
          sortDirection: troubleListQueryInfo.sortDirection as SORT_DIRECTION,
          category: troubleListQueryInfo.category,
          status: troubleListQueryInfo.status,
        });
      } else {
        troubleQuery.refetch();
      }
      setPending(true);

      // setTroubleListQueryInfo((query) => {
      //   return {
      //     ...query,
      //     keyword,
      //   };
      // });
      // //refetch();
    },
    [
      location.pathname,
      navigate,
      troubleListQueryInfo.category,
      troubleListQueryInfo.keyword,
      troubleListQueryInfo.pageLimit,
      troubleListQueryInfo.sortDirection,
      troubleListQueryInfo.sortType,
      troubleListQueryInfo.status,
      troubleQuery,
    ]
  );

  const onRowClick = useCallback((row: TechSupportDto) => {
    setSelectedTrouble(row);
    setShowPopup(true);
  }, []);

  return (
    <TroubleManagerPresenter
      data={troubleList}
      selectedData={selectedTrouble as TechSupportDto}
      columns={columns}
      status={status}
      onChangePage={onChangePage}
      onRowClick={onRowClick}
      onClickSearch={onClickSearch}
      paginationInfo={troubleListQueryInfo}
      showPopup={showPopup}
      onModal={onModal}
      tablePending={pending}
    />
  );
}
