import { DeviceType } from "api/interfaces/dashboardInterface.interface";
import { useCallback, useEffect, useMemo, useState } from "react";

import StatsPresenter, { changeLabel } from "./StatsPresenter";
import { useProSidebar } from "react-pro-sidebar";

import {
  getDashboardAlertActiveType,
  getDashboardDevice,
  getDashboardOverviewStatistic,
  getDashboardUserLogin,
} from "api/dashboardAPI";
import { useQuery } from "react-query";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import * as Interface from "api/interfaces/dashboardInterface.interface";
import { barKeyName } from "components/atoms/chart/BarChart";
import { BarDatum } from "@nivo/bar";
import { Option, intervalOptions } from "utils/options";
import { Datum, Serie } from "@nivo/line";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import moment from "moment";
import { excelDateFormat } from "utils/timeUtil";

const changeColorDevice = (label: string) => {
  if (label !== undefined) {
    if (label.includes("Window")) {
      return "#640F9D";
    }
    if (label.includes("Mac")) {
      return "#636363";
    }
    if (label.includes("Linux")) {
      return "#FF6700";
    }
    if (label.includes("iOS")) {
      return "#0099FF";
    }
    if (label.includes("Android")) {
      return "#43B104";
    }
    // if (label.includes("Android Phone")) {
    //   return "#FFA200";
    // }
    // if (label.includes("Android Tablet")) {
    //   return "#FF0000";
    // }
  }

  return "";
};

const excelFileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const excelFileExtension = ".xlsx";

export default function StatsContainer(): JSX.Element {
  const excelGlobalFileName = `global-stats-${excelDateFormat()}`;
  const excelAlertFileName = `alert-stats-${excelDateFormat()}`;

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const { collapsed } = useProSidebar();
  const [isSendEmail, setIsSendEmail] = useState(false);

  const [isDevice, setIsDevice] = useState<DeviceType>({
    all: true,
    Linux: true,
    Windows: true,
    Mac: true,
    Android: true,
    iOS: true,
  });

  const [loginInterval, setLoginInterval] = useState<Option>(
    intervalOptions[0]
  );

  const [totalData, setTotalData] = useState<Interface.StatisticArrayType[]>([
    {
      users: {
        total: 0,
        thisWeek: 0,
      },
    },
    {
      recorders: {
        total: 0,
        thisWeek: 0,
      },
    },
    {
      cameras: {
        total: 0,
        thisWeek: 0,
      },
    },
  ]);
  const [spectrumData, setSpectrumData] = useState<Interface.SpectrumArrayType>(
    {
      nvrStats: {
        nvrStorageAlertRatio: 0,
        nvrSystemAlertRatio: 0,
        nvrVideoAlertRatio: 0,
        totalNvrRecorder: 0,
      },
      spectrumStats: {
        spectrumStorageAlertRatio: 0,
        spectrumSystemAlertRatio: 0,
        spectrumVideoAlertRatio: 0,
        totalspectrumRecorder: 0,
      },
    }
  );

  const [loginData, setLoginData] = useState<Datum[]>([]);

  const [deviceData, setDeviceData] = useState<BarDatum[]>([]);

  const onChangeSendEmail = () => {
    setIsSendEmail(!isSendEmail);
  };

  const onChangeDeviceOption = (e: any) => {
    let {
      target: { checked, name },
    } = e;

    if (name === "all") {
      if (checked) {
        setIsDevice({
          all: true,
          Linux: true,
          Windows: true,
          Mac: true,
          Android: true,
          iOS: true,
        });
      } else {
        setIsDevice({
          all: false,
          Linux: false,
          Windows: false,
          Mac: false,
          Android: false,
          iOS: false,
        });
      }

      return;
    }

    setIsDevice({
      ...isDevice,
      all: false,
      [name]: checked,
    });
  };

  const onChangeLoginInterval = (e: any) => {
    setLoginInterval(
      intervalOptions.filter(function (option) {
        return option.value === String(e);
      })[0]
    );
  };

  useEffect(() => {
    // console.log(isDevice);
    if (
      isDevice.Linux &&
      isDevice.Windows &&
      isDevice.Mac &&
      isDevice.Android &&
      isDevice.iOS
    ) {
      setIsDevice({
        ...isDevice,
        all: true,
      });
    }
  }, [
    isDevice.Linux,
    isDevice.Windows,
    isDevice.Mac,
    isDevice.Android,
    isDevice.iOS,
  ]);
  // side bar collapse 할 때 size 조절
  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 200);
  }, [collapsed]);

  const statsOverview = useQuery(
    ["statsOverview", selectedAccount],
    () =>
      getDashboardOverviewStatistic({
        accountId: selectedAccount.accountId,
      }),
    {
      retry: 0,
      refetchInterval: 10000,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res.result) {
          // console.log(res.result);
          setTotalData([
            {
              users: {
                total: res.result.totalUserCount,
                thisWeek: res.result.thisWeekUserCount,
              },
            },
            {
              recorders: {
                total: res.result.totalRecorderCount,
                thisWeek: res.result.thisWeekRecorderCount,
              },
            },
            {
              cameras: {
                total: res.result.totalCameraCount,
                thisWeek: res.result.thisWeekCameraCount,
              },
            },
          ]);
        } else {
          setTotalData([
            {
              users: {
                total: 0,
                thisWeek: 0,
              },
            },
            {
              recorders: {
                total: 0,
                thisWeek: 0,
              },
            },
            {
              cameras: {
                total: 0,
                thisWeek: 0,
              },
            },
          ]);
        }
      },
      onError: (e: any) => {
        console.log(e, "error");
      },
    }
  );

  const statsActiveType = useQuery(
    ["statsActiveType", selectedAccount],
    () =>
      getDashboardAlertActiveType({
        accountId: selectedAccount.accountId,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      refetchInterval: 10000,
      onSuccess: (res: any) => {
        // console.log(res);
        if (res.result) {
          // console.log(res.result, "statsActiveType");
          setSpectrumData(res.result);
        } else {
          setSpectrumData({
            nvrStats: {
              nvrStorageAlertRatio: 0,
              nvrSystemAlertRatio: 0,
              nvrVideoAlertRatio: 0,
              totalNvrRecorder: 0,
            },
            spectrumStats: {
              spectrumStorageAlertRatio: 0,
              spectrumSystemAlertRatio: 0,
              spectrumVideoAlertRatio: 0,
              totalspectrumRecorder: 0,
            },
          });
        }
      },
      onError: (e: any) => {
        console.log(e, "error");
      },
    }
  );

  const statsUserLogin = useQuery(
    ["statsUserLogin", selectedAccount, loginInterval],
    () =>
      getDashboardUserLogin({
        accountId: selectedAccount.accountId,
        interval: loginInterval.value,
      }),
    {
      retry: 0,
      refetchInterval: 10000,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res.result) {
          let tempData = res.result.map((login: any) => {
            return {
              x: login.label,
              y: login.value,
            };
          });
          setLoginData(tempData);
        } else {
          setLoginData([]);
        }
      },
      onError: (e: any) => {
        console.log(e, "error");
      },
    }
  );

  const statsDevice = useQuery(
    ["statsDevice", selectedAccount, isDevice],
    () => {
      let tempDevice = Object.entries(isDevice)
        .filter((device, index) => device[1] === true)
        .map((d) => d[0]);

      let deviceTypes =
        tempDevice.includes("all") || tempDevice.length === 0
          ? null
          : tempDevice;

      return getDashboardDevice({
        accountId: selectedAccount.accountId,
        payload: {
          deviceTypes: deviceTypes,
        },
      });
    },
    {
      retry: 0,
      refetchInterval: 10000,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res.result) {
          // console.log(res.result);
          let tempData: BarDatum[] = [];

          if (res.result.array) {
            tempData = res.result.array
              .filter((r: any) => {
                return r.label !== "other" || r.label !== undefined;
              })
              .map((device: any) => {
                let value = +(
                  (device.value / res.result.totalValue) *
                  100
                ).toFixed(1);
                return {
                  indexName: device.label,
                  [barKeyName]: value,
                  [`${barKeyName}Color`]: changeColorDevice(device.label),
                };
              });
          }

          setDeviceData(tempData);
        } else {
          setDeviceData([]);
        }
      },
      onError: (e: any) => {
        console.log(e, "error");
      },
    }
  );

  const loginKeys: string[] = useMemo(() => {
    return ["Date", "Counts"];
  }, []);

  const systemKeys: string[] = useMemo(() => {
    return ["Spectrum", ""];
  }, []);

  const systemVmaxKeys: string[] = useMemo(() => {
    return ["VMAX Series", ""];
  }, []);

  const useExcelDownload = useCallback(
    (type: string) => {
      if (type === "overview") {
        // console.log(totalData, loginData);
        const rs = XLSX.utils.aoa_to_sheet([]);
        const ls = XLSX.utils.aoa_to_sheet([loginKeys]);

        let tempTotalData = [
          {
            label: "Total Users",
            value: totalData[0].users.total,
          },
          {
            label: "This Week New Users",
            value: totalData[0].users.thisWeek,
          },
          {
            label: "Total Recorders",
            value: totalData[1].recorders.total,
          },
          {
            label: "This Week New Recorders",
            value: totalData[1].recorders.thisWeek,
          },
          {
            label: "Total Cameras",
            value: totalData[2].cameras.total,
          },
          {
            label: "This Week New Cameras",
            value: totalData[2].cameras.thisWeek,
          },
        ];

        tempTotalData.map((total) => {
          XLSX.utils.sheet_add_aoa(rs, [[total.label, total.value] as any[]], {
            origin: -1,
          });
        });

        rs["!cols"] = [
          { wpx: 150 },
          { wpx: 150 },
          { wpx: 150 },
          { wpx: 150 },
          { wpx: 150 },
          { wpx: 150 },
        ];

        loginData.map((login) => {
          XLSX.utils.sheet_add_aoa(ls, [[login.x, login.y] as any[]], {
            origin: -1,
          });
        });

        ls["!cols"] = [{ wpx: 120 }, { wpx: 60 }];

        const wb: any = {
          // SheetName 과 Sheets key name 같아야함..
          Sheets: { GlobalStatics: rs, UserLogins: ls },
          SheetNames: ["GlobalStatics", "UserLogins"],
        };
        const excelButter = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const excelFile = new Blob([excelButter], { type: excelFileType });
        FileSaver.saveAs(excelFile, excelGlobalFileName + excelFileExtension);
        return;
      }
      if (type === "system") {
        const ws = XLSX.utils.aoa_to_sheet([systemKeys]);
        const vs = XLSX.utils.aoa_to_sheet([systemVmaxKeys]);

        Object.entries(spectrumData.spectrumStats).map((spec, idx) => {
          if (idx === 0) {
            return XLSX.utils.sheet_add_aoa(
              ws,
              [[changeLabel(spec[0]), spec[1]] as any[]],
              {
                origin: -1,
              }
            );
          } else
            return XLSX.utils.sheet_add_aoa(
              ws,
              [[`${changeLabel(spec[0])} Alerts (%)`, spec[1]] as any[]],
              {
                origin: -1,
              }
            );
        });

        Object.entries(spectrumData.nvrStats).map((nvr, idx) => {
          if (idx === 0) {
            return XLSX.utils.sheet_add_aoa(
              vs,
              [[changeLabel(nvr[0]), nvr[1]] as any[]],
              {
                origin: -1,
              }
            );
          } else
            return XLSX.utils.sheet_add_aoa(
              vs,
              [[`${changeLabel(nvr[0])} Alerts (%)`, nvr[1]] as any[]],
              {
                origin: -1,
              }
            );
        });

        ws["!cols"] = [{ wpx: 250 }, { wpx: 60 }];
        vs["!cols"] = [{ wpx: 250 }, { wpx: 60 }];


        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Alert Statistics By Spectrum");
        XLSX.utils.book_append_sheet(wb, vs, "Alert Statistics By COVA&Vmax");

        // const wb: any = {
        //   Sheets: { AlertStaticsBySpectrum: ws, AlertStaticsByCOVAVmax: vs },
        //   SheetNames: ["Alert Statistics By Spectrum", "Alert Statistics By COVA&Vmax"],
        // };
        // console.log(wb);
        const excelButter = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const excelFile = new Blob([excelButter], { type: excelFileType });
        FileSaver.saveAs(excelFile, excelAlertFileName + excelFileExtension);
        return;
      }
    },
    [totalData, loginData, spectrumData, loginKeys, systemKeys, systemVmaxKeys]
  );

  const isLoading = {
    device: statsDevice.isFetching,
    userLogin: statsDevice.isFetching,
    overView: statsOverview.isFetching,
    activeTpype: statsActiveType.isFetching,
  };

  const isFetching =
    statsDevice.isFetching ||
    statsActiveType.isFetching ||
    statsOverview.isFetching ||
    statsUserLogin.isFetching;

  // console.log(
  //   isLoading,
  //   statsDevice.isFetching,
  //   statsActiveType.isLoading,
  //   statsOverview.isLoading
  // );

  return (
    <StatsPresenter
      totalData={totalData}
      spectrumData={spectrumData}
      deviceData={deviceData}
      loginData={loginData}
      onChangeSendEmail={onChangeSendEmail}
      isSendEmail={isSendEmail}
      onChangeDeviceOption={onChangeDeviceOption}
      onChangeLoginInterval={onChangeLoginInterval}
      isDevice={isDevice}
      isLoading={isLoading}
      isFetching={isFetching}
      loginInterval={loginInterval}
      useExcelDownload={useExcelDownload}
    />
  );
}
