import { Option } from "utils/options";

export const menuOptions: Option[] = [
  { "value": "", "label": "All" },
  { "value": "AUTH", "label": "Auth" },
  { "value": "VIDEOSHARE", "label": "Video Share" },
  { "value": "REPORT", "label": "Report" },
  { "value": "ALERTRULE", "label": "Alert Rule" },
  { "value": "RECORDER", "label": "Recorder" },
  { "value": "CAMERA", "label": "Camera" },
  { "value": "RECORDERGROUP", "label": "Recorder Group" },
  { "value": "ACCOUNT", "label": "Organization" },
  { "value": "USER", "label": "User" },
  { "value": "USERGROUP", "label": "User Group" },
  { "value": "ALERTVIEW", "label": "Alert" },
  { "value": "LICENSE", "label": "License" },
  { "value": "MOBILE", "label": "Mobile" }
];

export const valueToLabel = (value: string): string => {
  if (!value) {
    return '';
  }
  const menuOptionFound = menuOptions.find((option) => {
    return option.value.toUpperCase() === value.toUpperCase();
  });
  if (!menuOptionFound) {
    return value;
  }
  return menuOptionFound.label;
}