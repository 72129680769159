import axios, { AxiosResponse } from "axios";
import { GetSignedUrlForRecordingPayload, GetSignedUrlForRecordingResponse, GetSignedUrlForRecordingResult, UploadRecordedVideoToVideoShareResult } from "./interfaces/screenRecorderInterface.interface";

const getSignedUrlForRecording = async (
  payload: GetSignedUrlForRecordingPayload
): Promise<GetSignedUrlForRecordingResult> => {
  const url = `${process.env.REACT_APP_BASE_URL}/ec2/getSignedUrlForRecording`;
  return axios.post(url, payload)
    .then((res: AxiosResponse<GetSignedUrlForRecordingResponse>) => {
      if (res.data.error !== "0") {
        throw new Error(res.data.errorString);
      }
      return res.data.reply;
    });
}

export const uploadRecordedVideoToVideoShare = async (
  blob: Blob,
  payload: GetSignedUrlForRecordingPayload
): Promise<UploadRecordedVideoToVideoShareResult> => {
  return getSignedUrlForRecording(payload)
    .then((res: GetSignedUrlForRecordingResult) => {
      const { url, id } = res;
      return axios.put(url, blob)
        .then(() => {
          return { videoId: id };
        });
    }).catch((err) => {
      console.error(err);
      throw new Error(err);
    });
}