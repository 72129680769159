import { TableTemplate } from "components/templates/default/table/TableTemplate";
import { GetOperationLogResponseResult as OperationLog } from 'api/interfaces/operationlogInterface.interface';
import styled from "styled-components";
import { SearchParams } from "./OperationContainer";
import { SearchFilter } from "./SearchFilter";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { BasePagination } from "api/interfaces/commonInterface.interface";
import { changeSortOrderAntd, CommonTable, onSortAntd } from "components/atoms/table/AntdTable";
import { TableColumnsType } from "antd";
import { ColumnType } from "antd/es/table";
import { valueToLabel } from "./menus";

const columns = (account: ProfileAccountInfo, paginationInfo: BasePagination, setPaginationInfo: React.Dispatch<React.SetStateAction<BasePagination>>): TableColumnsType<OperationLog> => {
  const onHeaderCell = (column: ColumnType<OperationLog>) => {
    return {
      onClick: (e: any) => {
        if (!column.key) {
          return;
        }
        setPaginationInfo((info) => {
          return {
            ...info,
            sortType: column.key as string,
            sortDirection: onSortAntd(info.sortDirection),
          };
        });
      },
    };
  };

  return [
    {
      title: "Date Time",
      key: "createdAt",
      responsive: ["md"],
      render: (value, row) => row.time,
      sorter: true,
      sortOrder: changeSortOrderAntd(paginationInfo, "createdAt"),
      defaultSortOrder: "descend",
      onHeaderCell,
    },
    {
      title: "Organization",
      responsive: ["md"],
      render: (row) => row.accountNumber || '-',
    },
    {
      title: "User",
      responsive: ["md"],
      render: (row) => row.username || '-',
    },
    {
      title: "Menu",
      width: '150px',
      align: "center",
      render: (row) => valueToLabel(row.page) || '-',
    },
    {
      title: "Action",
      width: '100px',
      align: "center",
      render: (row) => row.operationType || '-',
    },
    {
      title: "Result",
      width: '100px',
      align: "center",
      render: (row) => {
        const status = row.status;
        if (!status) {
          return '-';
        }
        if (row.status >= 200 && row.status < 300 ) {
          return 'Success';
        }
        return 'Fail';
      },
    },
    {
      title: "Reason",
      responsive: ["md"],
      ellipsis: true,
      render: (row) => row.errorStatus,
    },
  ];
};


type Props = {
  data: OperationLog[];
  accountInfo: ProfileAccountInfo,
  onClickSearch: () => void;
  onSearchParamChange: (name: string, value: any) => void;
  searchParams: SearchParams;
  paginationInfo: BasePagination;
  setPaginationInfo: React.Dispatch<React.SetStateAction<BasePagination>>;
  onChangePage: (page: number) => void;
};

export function OperationPresenter(props: Props): JSX.Element {

  return (
    <Container>
      <PageTitle>Logging</PageTitle>
      <SearchFilter
        onClickSearch={props.onClickSearch}
        onSearchParamChange={props.onSearchParamChange}
        searchParams={props.searchParams} />
      <TableTemplate
        noSearch={true}
      >
        <CommonTable
          columns={columns(props.accountInfo, props.paginationInfo, props.setPaginationInfo)}
          dataSource={props.data}
          queryInfo={props.paginationInfo}
          onChangePage={props.onChangePage}
          loading={false}
        />
      </TableTemplate>
    </Container>
  );
}

const Container = styled.div`
  & .table-template .subheader {
    display: none;
  }
`;

const PageTitle = styled.h1`
  padding: 53px 0px 5px;
  font-weight: 400;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.COLORS.PAGE_TITLE};
  white-space: nowrap;
`;
