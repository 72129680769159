import ONLINE_ICON from "assets/icons/online.svg";
import OFFLINE_ICON from "assets/icons/offline.svg";
import WARNING_RED_ICON from "assets/icons/warning_red.svg";
import WARNING_ICON from "assets/icons/warning.svg";
import ASSGINED_WARNING from "assets/icons/assigned_warning.svg";
import CRITICAL_WARNING from "assets/icons/critical_warning.svg";
import UNKNOWN_WARNING from "assets/icons/triangle_warning_red.svg";
import styled from "styled-components";
import { FLEX } from "styles/flex";

interface StatusProps {
  status?: string;
}

function Status({ status }: StatusProps): JSX.Element {
  return (
    <>
      {(status === "online" || status === "healthy") && (
        <Icon>
          <img src={ONLINE_ICON} alt="online icon" />
        </Icon>
      )}
      {status === "offline" && (
        <Icon>
          <img src={OFFLINE_ICON} alt="offline icon" />
        </Icon>
      )}
      {(status === "detected" || status === "alert") && (
        <Icon>
          <img src={WARNING_RED_ICON} alt="offline icon" />
        </Icon>
      )}
      {status === "warning" && (
        <Icon>
          <img src={WARNING_ICON} alt="warning icon" />
        </Icon>
      )}
      {status === "assigned" && (
        <Icon>
          <img src={ASSGINED_WARNING} alt="assigned icon" />
        </Icon>
      )}
      {status === "critical" && (
        <Icon>
          <img src={CRITICAL_WARNING} alt="critical icon" />
        </Icon>
      )}
      {status === "unknown" && (
        <Icon>
          <img src={UNKNOWN_WARNING} alt="unknown icon" />
        </Icon>
      )}
    </>
  );
}

const Icon = styled.div`
  width: 16px;
  ${FLEX.FlexStartCenter};

  img {
    width: 100%;
  }
`;
export default Status;
