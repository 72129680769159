import * as s from "../signin/LoginStyled";
import * as cs from "../SignCommonStyled";
import {
  ResendCodePopup,
  VERIFY_TYPE,
  VerifyTypeString,
} from "pages/auth/verify2fa/resendCode/resendCode";
import { Validate, ValidateMsg } from "../signin/LoginContainer";
import { useIntl } from "react-intl";
import VerifyTitle from "components/blocks/verify/VerifyTitle";
import VerfiyCode from "components/blocks/verify/VerfiyCode";
import { Text, UnderlineText, ValidateWord } from "components/atoms/text/Text";
import { COLORS } from "styles/colors";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  LoginResponse,
  VerifyInfoType,
} from "api/interfaces/authInterface.interface";
import { useCallback, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { postResendCode, postVerify2fa } from "api/authAPI";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "components/Auth";
import { setItemWithExpireTime } from "hook/useLocalStorage";
import { ACCESS_TOKEN } from "api/axiosInstance";
import { getCookie } from "cookies/cookie";
import { onSuccessLogin } from "utils/AuthUtil";
import { setMenu } from "redux/reducers/menu/menuSlice";
import { menuNavigation } from "utils/MenuUtil";
import { notify } from "components/atoms/notification/Notification";

export default function Verify2FA(): JSX.Element {
  const intl = useIntl();
  const auth = useAuth();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isInitialRender, setIsInitialRender] = useState(true);

  const themeMode = useAppSelector((state) => state.theme.theme);
  const noticeLength: number = useAppSelector(
    (state) => state.notice.notices.length
  );
  const location = useLocation();
  const state = location.state;
  const toSearchParam = new URLSearchParams(state.search);

  const loginInfo = state.loginInfo ?? { ...auth.user };

  // console.log(state, loginInfo);
  const [isResendCodePopup, setIsResendCodePopup] = useState<boolean>(false);
  const [trustDevice, setTrustDevice] = useState<boolean>(false);

  const [verifyType, setVerifyType] = useState<VERIFY_TYPE>(
    VERIFY_TYPE[state.verifyType as VerifyTypeString] ?? VERIFY_TYPE.EMAIL
  );
  const [verifyCode, setVerifyCode] = useState<string>("");
  const [mfaToken, setMfaToken] = useState<string>("");

  const [isValidated, setIsValidated] = useState<Validate>({
    resend: false,
    verifyCode: false,
  });

  const [validateMsg, setValidateMsg] = useState<ValidateMsg>({
    resend: "",
    verifyCode: "",
  });

  const onClickResend = useCallback(() => {
    if (!state.isAuth) {
      onClickVerifyResend(VERIFY_TYPE.EMAIL);
      return;
    }
    setIsResendCodePopup(true);
  }, []);

  const onChangeVerifyCode = useCallback(
    (value: string) => {
      setVerifyCode(value);
    },
    [verifyCode]
  );

  const onErrorVerify = (msg?: string) => {
    setIsValidated({ ...isValidated, verifyCode: true });
    setValidateMsg({
      ...validateMsg,
      verifyCode: msg
        ? msg
        : intl.formatMessage({
            id: "validateMsg.signup.verifyNumber",
            defaultMessage: "The code you entered does not match.",
          }),
    });
  };

  const mutationResendCode = useMutation(postResendCode, {
    onSuccess: (res: any) => {
      if (res.error === 0) {
        if (!state.isAuth) {
          if (!isInitialRender) {
            notify(
              "info",
              intl.formatMessage({
                id: "label.videoshare.guest.verify.resend.success",
                defaultMessage: "Success to resend code.",
              })
            );
          }

          setMfaToken(res.result.mfaToken);
        }

        setIsResendCodePopup(false);
      } else {
        setIsValidated({ ...isValidated, resend: true });
        setValidateMsg({
          ...validateMsg,
          resend: intl.formatMessage({
            id: "validateMsg.signup.noExistEmail",
            defaultMessage: "Email is not exist",
          }),
        });
      }
    },
    onError: () => {
      setIsValidated({ ...isValidated, resend: true });
      setValidateMsg({
        ...validateMsg,
        resend: intl.formatMessage({
          id: "validateMsg.signup.noExistEmail",
          defaultMessage: "Email is not exist",
        }),
      });
    },
  });

  const mutationSend2fa = useMutation(postVerify2fa, {
    onSuccess: () => {
      // console.log(state);
      auth.updateVerify2fa(true);
      if (!state.isAuth && state.videoInfo) {
        const searchParams = new URLSearchParams({
          videoId: state.videoInfo.videoId,
          userId: state.videoInfo.userId,
        }).toString();
        navigate(`/guest/video/player?${searchParams}`, {
          state: {
            isVerified: true,
            tokenInfo: state.videoInfo,
          },
        });
      }

      if (state.isLogin) {
        onLogin(state.isLogin as LoginResponse);
      }

      if (state.selectAccount) {
        if (state.selectAccount.accountLevel === "EU") {
          dispatch(setMenu("Recorders"));
          menuNavigation(state.selectAccount, navigate, "/recorders/list");
        } else {
          dispatch(setMenu("Organization"));
          menuNavigation(state.selectAccount, navigate, "/account");
        }
      }
    },
    onError: (e: any) => {
      onErrorVerify(e.response.data.errorString);
    },
  });

  const onMutateResendCode = (type: VERIFY_TYPE) => {
    // console.log("onMutateResendCode");
    setIsValidated({ resend: false, verifyCode: false });
    setValidateMsg({
      resend: "",
      verifyCode: "",
    });
    setVerifyCode("");
    mutationResendCode.mutate({
      payload: state.isAuth
        ? {
            isAuth: state.isAuth ?? true,
            accessToken: state.accessToken ?? getCookie(ACCESS_TOKEN),
            twoFactorType: type,
            email: loginInfo.email,
          }
        : {
            isAuth: state.isAuth ?? true,
            twoFactorType: type,
            email: loginInfo.email,
          },
    });
  };

  const onMutateSend2fa = () => {
    mutationSend2fa.mutate({
      payload: state.isAuth
        ? {
            email: loginInfo.email,
            accessToken: state.accessToken ?? getCookie(ACCESS_TOKEN),
            code: verifyCode,
            isAuth: state.isAuth ?? true,
          }
        : {
            email: loginInfo.email,
            accessToken: mfaToken,
            code: verifyCode,
            isAuth: state.isAuth ?? true,
          },
    });
  };

  const onLogin = (loginData: LoginResponse) => {
    if (trustDevice) {
      setItemWithExpireTime(
        "trust_" + loginInfo.email + state.accountId,
        String(true),
        2592000000
      );
    }
    const onError = () => {
      setIsValidated({ ...isValidated, password: true });
      setValidateMsg({
        ...validateMsg,
        password: intl.formatMessage({
          id: "validateMsg.signup.loginIncorrect",
          defaultMessage: "Incorrect Login Credentials.",
        }),
      });
    };
    onSuccessLogin(auth, loginInfo.email, loginData, onError, toSearchParam);
  };

  const onVerify = useCallback(() => {
    onMutateSend2fa();
  }, [verifyCode, loginInfo.email]);

  const onClickVerifyResend = useCallback(
    (type: VERIFY_TYPE) => {
      // console.log(type, "type");
      setVerifyType(type);
      onMutateResendCode(type);
    },
    [loginInfo.email, ACCESS_TOKEN]
  );

  const onChangeTrustDevice = useCallback((e: any) => {
    setTrustDevice(e.currentTarget.checked);
  }, []);

  useEffect(() => {
    if (isInitialRender) {
      // 첫 렌더링일 때만 실행
      onMutateResendCode(verifyType);
      setIsInitialRender(false);
    }
  }, [isInitialRender]);

  return (
    <>
      <cs.BG noticeLength={noticeLength}>
        <cs.Container>
          <cs.LoginBox>
            <VerifyTitle
              title={intl.formatMessage({
                id: "dw_login_two_factor_authentication",
                defaultMessage: "Two-factor Authentication",
              })}
              desc={
                verifyType === VERIFY_TYPE.EMAIL
                  ? intl.formatMessage({
                      id: "enter_pin_message",
                      defaultMessage:
                        "Enter the 6-digit code sent to your email.",
                    })
                  : intl.formatMessage({
                      id: "enter_pin_message_phone",
                      defaultMessage:
                        "Enter the 6-digit code sent to your phone.",
                    })
              }
            />
            <VerfiyCode
              verifyCode={verifyCode}
              onChange={onChangeVerifyCode}
              onClickOtpEnter={onVerify}
            />
            <cs.CenterBox>
              <UnderlineText
                fontSize={14}
                color={themeMode === "light" ? COLORS.COMPANY : COLORS.BUTTONS2}
                onClick={onClickResend}
              >
                Resend Code
              </UnderlineText>
              {state.isLogin && (
                <s.CheckboxInput htmlFor="login-form-trust-device">
                  <input
                    type="checkbox"
                    id="login-form-trust-device"
                    className=""
                    defaultChecked={trustDevice}
                    onChange={onChangeTrustDevice}
                  />
                  <Text fontSize={12}>Trust this device for 30 days</Text>
                </s.CheckboxInput>
              )}
            </cs.CenterBox>
            <cs.ValidateWrapper>
              <ValidateWord>{validateMsg.verifyCode}</ValidateWord>
            </cs.ValidateWrapper>
            <cs.BigButton onClick={onVerify}>Verify</cs.BigButton>
          </cs.LoginBox>
        </cs.Container>
      </cs.BG>
      {isResendCodePopup && (
        <ResendCodePopup
          {...(loginInfo as VerifyInfoType)}
          verifyType={verifyType}
          onClickCancel={() => {
            setIsResendCodePopup(false);
          }}
          onClickSend={onClickVerifyResend}
        />
      )}
    </>
  );
}
