import styled from "styled-components";
import { FLEX } from "styles/flex";
import * as mediaQuery from "components/MediaQuery";
import { COLORS } from "styles/colors";
import { calcRem } from "styles/theme";
import { ColumnTitle } from "../card/CardItemStyled";

export const Item = styled.li`
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.COLORS.WHITE};
  ${FLEX.FlexStartStart};
  flex: 1;
  flex-direction: column;
  gap: 14px;
  border-radius: 5px;
  padding: 18px;
  background-color: ${({ theme }) =>
    theme.value === "light" ? COLORS.WHITE : COLORS.PRIMARY3};

  .bold {
    font-weight: 500;
    font-size: ${calcRem(20)};
  }
`;

export const Title = styled.div`
  ${FLEX.FlexBetweenCenter};
  border-bottom: 1px solid #2e374a;
  width: 100%;
  padding-bottom: 8px;
  flex: 0 0 auto;
  cursor: move;

  .title-name {
    ${FLEX.FlexStartCenter};
    /* gap: 8px; */

    .table-item-alert-button {
      position: relative;
      cursor: pointer;
      ${FLEX.FlexCenterCenter};
      height: 16px;
    }
    .table-item-alert-svg {
      color: ${COLORS.RED};
      height: 100%;
    }

    .bubble-container {
      position: absolute;
      top: 28px;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: 299;
    }
  }

  .title-list-wrapper {
    position: relative;
  }

  .title-list {
    cursor: pointer;
    ${FLEX.FlexCenterCenter};
    color: ${({ theme }) =>
      theme.value === "light" ? COLORS.BLACK : "#7883A1"};
  }
`;

export const ColumnText = styled(ColumnTitle)`
  white-space: normal;
  align-items: flex-start;
`;

export const Contents = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1 1 auto; /* 남은 높이를 채움 */
  overflow: auto; /* 내용이 넘칠 경우 스크롤 가능 */
`;

export const ContentWrapper = styled.div`
  ${FLEX.FlexStartStart};
  flex-direction: column;
  gap: 8px;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;

  .modal-submenu {
    padding-top: 0;
    gap: 10px;

    li {
      padding: 4px 0;
      font-size: ${calcRem(14)};
    }
  }
`;

export const ContentHeader = styled.div`
  width: 100%;

  ${FLEX.FlexBetweenCenter};
  flex: 0 0 auto;
  padding: 1px;

  > div {
    flex: 1;
    ${FLEX.FlexStartCenter};
    gap: 4px;
    white-space: nowrap;

    :first-child {
      flex: 1.5;
    }

    :nth-child(2) {
      justify-content: flex-end;

      .account-select-wrapper {
        width: 145px;
      }
    }
  }
  .select-wrapper {
    width: 120px;
  }
  .dashboard-search-input {
    max-width: 30%;
  }

  .modal-select-container {
    max-width: 30%;
  }

  .interval-select {
    width: 83px;
  }

  .search-input-wrapper {
    min-width: 145px;
  }
  strong,
  .cloud-storage {
    color: ${COLORS.BUTTONS2};
  }

  .select-container {
    font-size: ${calcRem(13)};
  }

  ${mediaQuery.isMobile} {
    flex-direction: column;
    gap: 4px;
    > div {
      width: 100%;
      flex: 1;
      flex-direction: column;
    }

    .modal-submenu {
      width: 100%;

      > li {
        flex: 1;
      }
    }
  }
`;

export const ContentInner = styled.div`
  flex: 1 1 auto; /* 남은 높이를 채움 */
  overflow: auto; /* 내용이 넘칠 경우 스크롤 가능 */
  width: 100%;
  height: 100%;
  max-height: 100%;

  .title {
    font-size: ${calcRem(14)};
  }
  .recorder-type {
    min-width: 70px;

    height: 25px;
    font-size: ${calcRem(12)};
  }

  .bar-chart-wrapper {
    width: 100%;
    height: 90%;
  }

  .red {
    color: ${COLORS.RED};
  }
`;

export const TableItemListStyled = {
  Container: styled.ul`
    z-index: 88;
    position: absolute;
    right: 0;

    border-radius: 5px;
    background-color: ${COLORS.WHITE};
    ${FLEX.FlexStartStart};
    flex-direction: column;
    gap: 12px;
    padding: 14px;
    min-width: max-content;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

    li:last-child {
      color: ${COLORS.RED};
    }
  `,

  Item: styled.li<{ isDisabled?: boolean }>`
    width: 100%;
    font-size: ${calcRem(14)};
    color: ${({ isDisabled }) =>
      isDisabled ? `${COLORS.BLACK}60` : COLORS.BLACK};

    ${({ isDisabled }) =>
      !isDisabled &&
      `
        cursor: pointer;
      `}
  `,
};
