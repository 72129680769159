import { RecorderHealthMoreDetailInterface } from "api/interfaces/reportInterface.interface";
import styled from "styled-components";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";
import * as mediaQuery from "components/MediaQuery";
import { useState } from "react";
import { IoChevronDownOutline, IoChevronUp } from "react-icons/io5";

type Props = {
  isCurrentMenu: number;
  onChangeMenu: (index: number) => void;
  menus: Array<RecorderHealthMoreDetailInterface>;
};

const statusToColor = (status?: string) => {
  if (status === "ALERT") {
    return COLORS.PROGRESSBAR;
  }
  if (status === "WARNING") {
    return COLORS.WARNING;
  }
};

export default function HealthMenus(props: Props): JSX.Element {
  const currentMenu = props.menus[props.isCurrentMenu];

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const upperStatus = (status?: string) => {
    return status?.toUpperCase();
  };

  return (
    <>
      <mediaQuery.Default>
        <Container>
          {props.menus.map((item, index) => (
            <Item
              key={index}
              isCurrent={props.isCurrentMenu === index}
              onClick={() => props.onChangeMenu(index)}
              status={upperStatus(item.status)}
            >
              <div className="alert-wrapper"></div>
              <div className="health-menu-icon-wrapper">{item.icon}</div>
              <div className="health-menu-label-wrapper">
                <span className="label">{item.label}</span>
                <Value status={upperStatus(item.status)}>
                  {item.subLabel
                    ? `${item.value} ${item.subLabel}`
                    : item.value}
                </Value>
              </div>
            </Item>
          ))}
        </Container>
      </mediaQuery.Default>

      <mediaQuery.Mobile>
        <MobileWrapper>
          <MobileItem onClick={() => setIsOpen(!isOpen)}>
            <MobileInnerItem>
              <div className="health-menu-icon-wrapper">
                {currentMenu.icon}
                {/* <img src={currentMenu.img} alt={`${currentMenu.label} icon`} /> */}
              </div>
              <div className="health-menu-label-wrapper">
                <span className="label">{currentMenu.label}</span>
                <Value status={currentMenu.status}>
                  {currentMenu.subLabel
                    ? currentMenu.subLabel
                    : currentMenu.value}
                </Value>
              </div>
            </MobileInnerItem>
            <button>
              {isOpen ? (
                <IoChevronUp size={24} />
              ) : (
                <IoChevronDownOutline size={24} />
              )}
            </button>
          </MobileItem>
          {isOpen && (
            <MobileOpenBox>
              {props.menus.map((item, index) => (
                <MobileOpenItem
                  key={index}
                  index={index}
                  onClick={() => {
                    setIsOpen(false);
                    props.onChangeMenu(index);
                  }}
                >
                  <div className="report-menu-icon">{item.icon}</div>
                  <div className="report-menu-label">{item.label}</div>
                </MobileOpenItem>
              ))}
            </MobileOpenBox>
          )}
        </MobileWrapper>
      </mediaQuery.Mobile>
    </>
  );
}

const Container = styled.ul`
  ${FLEX.FlexBetweenCenter};
  gap: 10px;
  flex-direction: column;
  width: 100%;
  grid-area: menu;
  max-height: 680px;
  /* min-width: 340px;
  max-width: 340px; */
  white-space: nowrap;
  margin-right: -15px;
`;

const Item = styled.li<{ isCurrent?: boolean; status?: string }>`
  width: 100%;
  ${FLEX.FlexStartCenter};
  background-color: ${({ theme }) =>
    theme.value === "light" ? theme.COLORS.PRIMARY3 : "#070e17"};
  border-radius: 5px;
  padding: 20px;
  color: ${({ theme }) => theme.COLORS.WHITE};
  font-size: ${calcRem(18)};
  gap: 26px;
  cursor: pointer;
  box-sizing: border-box;
  flex: 1;
  position: relative;
  overflow: hidden;

  ${({ isCurrent, theme }) =>
    isCurrent &&
    `
    box-shadow: 0 0 0 3px ${theme.COLORS.SELECTED} inset; 
  `}

  .alert-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 888;
    left: 50%;
    transform: translateX(-50%);
    background-color: ${({ status }) => statusToColor(status)}15;
  }

  .health-menu-icon-wrapper {
    width: 35px;
    ${FLEX.FlexCenterCenter};
    color: ${({ status }) => statusToColor(status)};
  }
  img {
    width: 90%;
  }
  .health-menu-label-wrapper {
    ${FLEX.FlexStartStart};
    flex-direction: column;
    gap: 8px;
  }
`;

export const Value = styled.span<{ status?: string }>`
  color: #828b9b;
  color: ${({ status }) => statusToColor(status)};
  color: ${({ status }) => status === "HEALTHY" && COLORS.BUTTONS2};

  ${mediaQuery.isMobile} {
    font-size: ${calcRem(14)};
  }
`;

const MobileWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const MobileItem = styled(Item)`
  ${FLEX.FlexBetweenCenter};
  padding: 14px 20px;

  button {
    color: ${({ theme }) => theme.COLORS.WHITE};
  }
`;

const MobileInnerItem = styled.div`
  ${FLEX.FlexStartCenter};
  color: ${COLORS.WHITE};
  font-size: ${calcRem(20)};
  gap: 16px;
  flex: 1;

  .health-menu-icon-wrapper {
    width: 35px;
    color: ${({ theme }) => theme.COLORS.WHITE};
  }
  img {
    width: 100%;
  }
  .health-menu-label-wrapper {
    ${FLEX.FlexStartStart};
    flex-direction: column;
    gap: 4px;

    color: ${({ theme }) => theme.COLORS.WHITE};
  }
`;

const MobileOpenBox = styled.ul`
  border: 1px solid ${COLORS.BUTTONS1};
  border-radius: 5px;
  padding: 0 10px;
  background-color: ${({ theme }) =>
    theme.value === "light" ? COLORS.WHITE : "#070e17"};
  width: 100%;
  position: absolute;
  z-index: 990;
`;

const MobileOpenItem = styled.li<{ index: number }>`
  cursor: pointer;
  padding: 12px 0;
  ${FLEX.FlexStartCenter};
  flex: 1;
  gap: 10px;
  ${({ index }) =>
    index !== 0 &&
    `
      border-top: 1px solid ${COLORS.BORDER};
  `}
  width: 100%;

  color: ${({ theme }) => theme.COLORS.WHITE};

  .report-menu-icon {
    /* flex: 1; */
    width: 30px;
    height: 30px;
    > svg {
      width: 100%;
      height: 100%;
    }
  }
  .report-menu-label {
    flex: 1;
  }
`;
