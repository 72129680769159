import { useState, useCallback, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import * as s from "styles/ModalCommonStyled";
import BigPopupTemplate from "components/templates/modal/big/BigPopupTemplate";
import { ModalInput } from "components/atoms/input/modal/ModalInput";
import Input from "components/atoms/input/Input";
import TextArea from "components/atoms/input/TextArea";
import BigButton from "components/atoms/buttons/BigButton";

import Toggle from "components/atoms/toggle/Toggle";
import PhoneInput from "components/atoms/input/phone/PhoneInput";
import {
  BasePagination,
  CustomError,
  SORT_DIRECTION,
  Validate,
} from "api/interfaces/commonInterface.interface";
import {
  EditUserDto,
  Group,
  UserDto,
} from "api/interfaces/userInterface.interface";
import {
  deactiveProfile,
  deletableUser,
  deleteUser,
  getGroupList,
  getGroupListForEdit,
  getUserDetail,
} from "api/userAPI";
import type { Option, UserGroupSelectOption } from "utils/options";
import { useAppSelector } from "redux/hooks";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";

import Deactivate from "../deactivate";
import { notify } from "components/atoms/notification/Notification";

import { MultiValue } from "react-select";
import { isEmpty } from "lodash";
import { ValidateMsg } from "api/interfaces/commonInterface.interface";
import { Text, ValidateWord } from "components/atoms/text/Text";
import { useIntl } from "react-intl";
import useApiError from "hook/useApiError";
import { useAuth } from "components/Auth";
import Spinner from "components/atoms/loader/Spinner";
import styled from "styled-components";
import { checkEmailRegEx } from "utils/regEx";
import { isPhoneNumberValid } from "utils/validHelper";
import { EditUserCompany, ReceiveTitle } from "pages/users/UsersStyled";
import InfoBubble from "components/blocks/bubble/InfoBubble";
import { BubbleContainer } from "pages/license/LicenseStyled";
import { FaInfoCircle } from "react-icons/fa";
import UserGroupSelectCombo from "components/atoms/select/UserGroupSelectCombo";

interface Props {
  onModal: (type?: string) => void;
  onClickSave: (userDto: UserDto, update?: boolean) => void;
  selectedUser: UserDto | undefined;
  onClickDeactive?: (userId: string) => void;
  isLoading: boolean;
}

export enum UPDATE_TYPE {
  FIRST_NAME,
  LAST_NAME,
  EMAIL,
  GROUP,
  COMPANY_NAME,
  COMPANY_TEL,
  DEPARTMENT,
  USER_GROUP,
  DESC,
  JOB_TITLE,
}

type ToggleListType = "notifyAlertMail" | "notifyReportMail" | "notifyPush";
type ToggleType = {
  [key in ToggleListType]: boolean;
};

function EditUser(props: Props): JSX.Element {
  const intl = useIntl();
  const { user, logout } = useAuth();
  const { handleError } = useApiError();

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);
  const [isActivate, setIsActivate] = useState<boolean>(true);
  const [isDeactivateMode, setIsDeactivateMode] = useState<boolean>(false);
  // 토글 상태
  // 우선 한가지로 통일함. ** 추후 상태 지정해줘야함

  const [isSelfDeActive, setIsSelfDeActive] = useState<boolean>(false);
  const [isBubble, setIsBubble] = useState<boolean>(false);

  // 나라 선택
  const [isFlagSelected, setIsFlagSelected] = useState("US");

  const [selectGroup, setSelectGroup] = useState<Option[]>([]);

  const [validate, setValidate] = useState<Validate>({
    firstName: false,
    lastName: false,
    email: false,
    group: false,
    companyPhone: false,
    description: false,
  });
  const [validateMsg, setValidateMsg] = useState<ValidateMsg>({
    firstName: "",
    lastName: "",
    email: "",
    group: "",
    companyPhone: "",
    description: "",
  });

  const isDisableChange = props.selectedUser?.userId !== user.userId;

  // 나라 선택 함수
  const onSelectFlag = (code: string) => {
    setIsFlagSelected(code);
  };

  const [userDto, setUserInfo] = useState<UserDto>({
    userId: "",
    firstName: "",
    lastName: "",
    email: "",
    parentAccount: selectedAccount.accountId,
    companyName: "",
    companyPhoneNumber: "",
    department: "",
    description: "",
    jobTitle: "",
    userTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    // uerRole: {
    //   roleId: "",
    // },
    startPage: 1,
    userGroups: [],
    notifyAlertMail: true,
    notifyReportMail: true,
    notifyPush: true,
  });

  useEffect(() => {
    // console.log(props.selectedUser);
    if (props.selectedUser !== undefined) {
      setUserInfo(props.selectedUser);

      if (props.selectedUser.isActivate !== undefined) {
        setIsActivate(props.selectedUser.isActivate as boolean);
      }
    }
  }, [props.selectedUser]);

  const onChangeUserInfo = useCallback(
    (
      type: UPDATE_TYPE,
      value: string | number | string[] | MultiValue<Option>
    ) => {
      if (!isDisableChange) {
        if (type === UPDATE_TYPE.FIRST_NAME) {
          setUserInfo((info) => {
            return { ...info, firstName: value as string };
          });
        } else if (type === UPDATE_TYPE.LAST_NAME) {
          setUserInfo((info) => {
            return { ...info, lastName: value as string };
          });
        } else if (type === UPDATE_TYPE.EMAIL) {
          // level 설정 client/server 상위 선택못하게 막아야 함
          setUserInfo((info) => {
            return { ...info, email: (value as string).trim() };
          });
        } else if (type === UPDATE_TYPE.COMPANY_NAME) {
          setUserInfo((info) => {
            return { ...info, companyName: value as string };
          });
        } else if (type === UPDATE_TYPE.COMPANY_TEL) {
          setUserInfo((info) => {
            return { ...info, companyPhoneNumber: value as string };
          });
        } else if (type === UPDATE_TYPE.DEPARTMENT) {
          setUserInfo((info) => {
            return { ...info, department: value as string };
          });
        } else if (type === UPDATE_TYPE.JOB_TITLE) {
          setUserInfo((info) => {
            return { ...info, jobTitle: value as string };
          });
        }
      }
      if (type === UPDATE_TYPE.GROUP) {
        setUserInfo((info) => {
          // setSelectGroup(value.);
          const onSelectValue = new Array<Group>();
          (value as MultiValue<any>).forEach((option) => {
            const obj = {} as Group;
            obj.groupId = option.value;
            onSelectValue.push(obj);
          });
          return { ...info, userGroups: onSelectValue as Group[] };
        });

        const onSetValue = new Array<Option>();
        (value as MultiValue<any>).forEach((option) => {
          onSetValue.push(option);
        });
        setSelectGroup(onSetValue);
      } else if (type === UPDATE_TYPE.DESC) {
        setUserInfo((info) => {
          return { ...info, description: value as string };
        });
      }
    },
    []
  );

  const [isDeletable, setIsDeletable] = useState<boolean>(true);

  const deletable = useQuery(
    ["userDeletable", props.selectedUser],
    () =>
      deletableUser({
        accountId: selectedAccount.accountId,
        userId: props.selectedUser?.userId as string,
      }),
    {
      retry: 0,
      enabled: props.selectedUser?.userId !== undefined,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res.error !== 0 || res.result === undefined) {
          setIsDeletable(false);
          return;
        }
        setIsDeletable(res.result);
      },
      onError: (e: any) => {
        setIsDeletable(false);
      },
    }
  );

  const queryInfo: BasePagination = {
    total: 0,
    pageNum: 0,
    pageLimit: 1000,
    keyword: "",
    sortType: "name",
    sortDirection: SORT_DIRECTION.ASC,
  };

  const [groups, setGroups] = useState<UserGroupSelectOption[]>([]);
  const { error } = useQuery(
    ["group", queryInfo],
    () =>
      getGroupListForEdit({
        payload: queryInfo,
        accountId: selectedAccount.accountId,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res.result === undefined) {
          return;
        }
        setGroups(
          res.result.map((group: any) => {
            const { groupId, name, enableEdit, userPermission } = group;
            return {
              value: groupId as string,
              label: name,
              isDisabled: enableEdit !== undefined ? !enableEdit : false,
              userPermission,
            };
          })
        );

        if (userDto.userGroups !== undefined) {
          const idList = userDto.userGroups.map((group) => group.groupId);

          setSelectGroup(
            res.result
              .map((group: any) => {
                const { groupId, name } = group;
                return {
                  value: groupId as string,
                  label: name,
                };
              })
              .filter((option: any) => idList.includes(option.value))
          );
        }
      },
      onError: (e: any) => {},
    }
  );

  const mutationDeleteUser = useMutation(deleteUser, {
    onSuccess: () => {
      notify(
        "success",
        intl.formatMessage({
          id: "label.user.notify.delete.success",
          defaultMessage: "User removed successfully.",
        })
      );
      if (isSelfDeActive) {
        props.onModal("deActive");
      } else {
        props.onModal("edit");
      }
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.user.notify.delete.fail",
          defaultMessage: "Failed to deactivate user.",
        })
      );
    },
  });

  const mutationDeactiveProfile = useMutation(deactiveProfile, {
    onSuccess: () => {
      logout();
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.profile.notify.deactivate.fail",
          defaultMessage: "Failed to deactivate profile.",
        })
      );
    },
  });

  const onClickDelete = useCallback(
    (result: boolean) => {
      setIsDeleteConfirm(false);
      if (result) {
        if (user.userId === userDto.userId) {
          //Self DeActive
          setIsSelfDeActive(true);
          getUserDetail()
            .then((response) => {
              if (
                response.totalAccount !== undefined &&
                response.totalAccount === 1
              ) {
                //profile DeActive
                mutationDeactiveProfile.mutate({
                  userId: userDto.userId,
                });
              } else {
                //only account DeActive
                mutationDeleteUser.mutate({
                  accountId: selectedAccount.accountId,
                  userId: userDto.userId,
                });
              }
            })
            .catch((error: CustomError) => {});
        } else {
          mutationDeleteUser.mutate({
            accountId: selectedAccount.accountId,
            userId: userDto.userId,
          });
        }
      }
    },
    [
      mutationDeactiveProfile,
      mutationDeleteUser,
      selectedAccount.accountId,
      user.userId,
      userDto.userId,
    ]
  );

  const onSaveBefore = useCallback(() => {
    setValidate((info) => {
      return {
        ...info,
        firstName: false,
        lastName: false,
        email: false,
        group: false,
        companyPhone: false,
        description: false,
      };
    });
    setValidateMsg((info) => {
      return {
        ...info,
        firstName: "",
        lastName: "",
        email: "",
        group: "",
        companyPhone: "",
        description: "",
      };
    });

    let checkedFlag = false;

    if (!isDisableChange) {
      if (isEmpty(userDto.firstName)) {
        setValidate((info) => {
          return { ...info, firstName: true };
        });
        setValidateMsg((info) => {
          return {
            ...info,
            firstName: intl.formatMessage({
              id: "validateMsg.user.name",
              defaultMessage: "Enter name",
            }) as string,
          };
        });
        return;
      }
      if (isEmpty(userDto.lastName)) {
        setValidate((info) => {
          return { ...info, lastName: true };
        });
        setValidateMsg((info) => {
          return {
            ...info,
            lastName: intl.formatMessage({
              id: "validateMsg.user.name",
              defaultMessage: "Enter name",
            }) as string,
          };
        });
        return;
      }

      if (
        !isEmpty(userDto.companyPhoneNumber) &&
        !isPhoneNumberValid(userDto.companyPhoneNumber)
      ) {
        setValidate((info) => {
          return { ...info, companyPhone: true };
        });
        setValidateMsg((info) => {
          return {
            ...info,
            companyPhone: intl.formatMessage({
              id: "validateMsg.profile.phone.validate",
              defaultMessage: "Please try again with the correct format.",
            }) as string,
          };
        });
        return;
      }
    }

    if (userDto.userGroups === undefined || userDto.userGroups.length === 0) {
      checkedFlag = true;
      setValidate((info) => {
        return { ...info, group: true };
      });
      setValidateMsg((info) => {
        return {
          ...info,
          group: intl.formatMessage({
            id: "validateMsg.user.group",
            defaultMessage: "A User Group must be selected.",
          }) as string,
        };
      });
    }

    if (!isEmpty(userDto.description) && userDto.description.length > 256) {
      setValidate((info) => {
        return { ...info, description: false };
      });
      setValidateMsg((info) => {
        return {
          ...info,
          description: intl.formatMessage({
            id: "validateMsg.profile.description",
            defaultMessage: "Please do not exceed 255 characters.",
          }) as string,
        };
      });
      return;
    }

    if (checkedFlag) {
      return;
    }

    props.onClickSave(userDto, true);
  }, [props, userDto]);

  const onClickDeactiveBefore = useCallback(() => {
    if (user.userId === userDto.userId) {
      getUserDetail()
        .then((response) => {
          if (
            response.totalAccount !== undefined &&
            response.totalAccount === 1
          ) {
            //profile DeActive
            setIsDeactivateMode(true);
          } else {
            //only account DeActive
            setIsDeactivateMode(false);
          }
          setIsDeleteConfirm(true);
        })
        .catch((err: CustomError) => {
          handleError(
            err,
            intl.formatMessage({
              id: "label.user.notify.delete.fail",
              defaultMessage: "Failed to deactivate user.",
            })
          );
        });
    } else {
      setIsDeactivateMode(false);
      setIsDeleteConfirm(true);
    }
  }, [handleError, intl, user.userId, userDto.userId]);

  return (
    <BigPopupTemplate title={"Edit User"} onModal={() => props.onModal("edit")}>
      <Inner>
        {props.isLoading && (
          <s.LoadingWrapper>
            <Spinner />
          </s.LoadingWrapper>
        )}

        <s.Row>
          <ModalInput label="Email" essential half>
            <Input
              autoComplete="off"
              type="search"
              placeholder="Enter this user's email address."
              disabled
              pattern="[A-Za-z]+"
              maxLength={100}
              value={userDto.email}
              onChange={(e) => {
                onChangeUserInfo(UPDATE_TYPE.EMAIL, e.target.value);
              }}
              isValidated={validate.email}
            />
            <ValidateWord>{validateMsg.email}</ValidateWord>
          </ModalInput>
          <ModalInput label="User Groups" essential half>
            <UserGroupSelectCombo
              options={groups}
              value={selectGroup}
              isDisabled={!isActivate as boolean}
              onChangeSelectCombo={(value: MultiValue<Option>) => {
                onChangeUserInfo(UPDATE_TYPE.GROUP, value);
              }}
            />
            <ValidateWord>{validateMsg.group}</ValidateWord>
          </ModalInput>
        </s.Row>
        <s.Row>
          <ModalInput label="First Name" essential>
            <Input
              value={userDto.firstName}
              placeholder="Enter a name for this user."
              disabled={isDisableChange}
              maxLength={100}
              onChange={(e) => {
                onChangeUserInfo(UPDATE_TYPE.FIRST_NAME, e.target.value);
              }}
              isValidated={validate.firstName}
            />
            <ValidateWord>{validateMsg.firstName}</ValidateWord>
          </ModalInput>
          <ModalInput label="Last Name" essential>
            <Input
              value={userDto.lastName}
              placeholder="Enter a name for this user."
              disabled={isDisableChange}
              maxLength={100}
              onChange={(e) => {
                onChangeUserInfo(UPDATE_TYPE.LAST_NAME, e.target.value);
              }}
              isValidated={validate.lastName}
            />
            <ValidateWord>{validateMsg.lastName}</ValidateWord>
          </ModalInput>
        </s.Row>
        <EditUserCompany>
          <Text bold>Company</Text>
          <s.Row>
            <ModalInput label="Name" half>
              <Input
                value={userDto.companyName}
                placeholder="Enter a name their company."
                disabled={isDisableChange}
                maxLength={100}
                onChange={(e) => {
                  onChangeUserInfo(UPDATE_TYPE.COMPANY_NAME, e.target.value);
                }}
              />
            </ModalInput>

            <ModalInput label="Phone" half>
              <PhoneInput
                selected={userDto.companyPhoneNumber}
                disabled={isDisableChange}
                onSelect={onSelectFlag}
                placeholder="Enter number"
                onChange={(value: string) => {
                  onChangeUserInfo(UPDATE_TYPE.COMPANY_TEL, value);
                }}
                isValidated={validate.companyPhone}
              />
              <ValidateWord>{validateMsg.companyPhone}</ValidateWord>
            </ModalInput>
          </s.Row>
          <s.Row>
            <ModalInput label="Department" half>
              <Input
                value={userDto.department}
                placeholder="Enter department."
                disabled={isDisableChange}
                maxLength={100}
                onChange={(e) => {
                  onChangeUserInfo(UPDATE_TYPE.DEPARTMENT, e.target.value);
                }}
              />
            </ModalInput>

            <ModalInput label="Job Title" half>
              <Input
                value={userDto.jobTitle}
                placeholder="Enter jobTitle."
                disabled={isDisableChange}
                maxLength={100}
                onChange={(e) => {
                  onChangeUserInfo(UPDATE_TYPE.JOB_TITLE, e.target.value);
                }}
              />
            </ModalInput>
          </s.Row>
        </EditUserCompany>

        <EditUserCompany>
          <ReceiveTitle>
            Receive
            <button
              onMouseEnter={() => setIsBubble(true)}
              onMouseLeave={() => setIsBubble(false)}
            >
              <FaInfoCircle />
              {isBubble && (
                <BubbleContainer className="bubble-container">
                  <InfoBubble left>
                    Notification settings can only be configured on the personal
                    profile page.
                  </InfoBubble>
                </BubbleContainer>
              )}
            </button>
          </ReceiveTitle>
          <s.ToggleWrapper>
            <s.ToggleItem>
              Receive alert emails
              <Toggle
                isToggled={userDto.notifyAlertMail}
                checked={userDto.notifyAlertMail}
                disabled
              />
            </s.ToggleItem>
            <s.ToggleItem>
              Receive report emails
              <Toggle
                isToggled={userDto.notifyReportMail}
                checked={userDto.notifyReportMail}
                disabled
              />
            </s.ToggleItem>
            <s.ToggleItem>
              Receive push notifications
              <Toggle
                isToggled={userDto.notifyPush}
                checked={userDto.notifyPush}
                disabled
              />
            </s.ToggleItem>
          </s.ToggleWrapper>
        </EditUserCompany>
        <ModalInput label="Description" isValidated={validate.description}>
          <TextArea
            value={userDto.description}
            placeholder="Enter description"
            disabled={!isActivate as boolean}
            maxLength={200}
            onChange={(e) => {
              onChangeUserInfo(UPDATE_TYPE.DESC, e.target.value);
            }}
          />
          <ValidateWord>{validateMsg.description}</ValidateWord>
        </ModalInput>
        <s.ButtonWrapper>
          <BigButton
            buttonType="warning"
            label="Remove from Organization"
            disabled={!isDeletable}
            onClickBtn={() => onClickDeactiveBefore()}
          />
          <BigButton
            label="Save"
            onClickBtn={onSaveBefore}
            disabled={!isActivate as boolean}
          />
        </s.ButtonWrapper>
        {isDeleteConfirm && (
          <Deactivate
            onModal={onClickDelete}
            type="user"
            targetId={userDto.email}
            isDeactivateMode={isDeactivateMode}
          />
        )}
      </Inner>
    </BigPopupTemplate>
  );
}

export default EditUser;

const Inner = styled.div`
  position: relative;
  width: 100%;
`;
