import styled from "styled-components";
import { FLEX } from "styles/flex";
import {
  COLORS,
  LIGHT_THEME_COLORS,
  MAP_STATUS_COLORS,
  statusToColor,
} from "styles/colors";
import { calcRem } from "styles/theme";
import VideoThumbnail from "components/atoms/image/thumbnail/VideoThumbnail";
import {
  DashboardAlertData,
  DashboardData,
} from "api/interfaces/dashboardInterface.interface";
import { BsFillExclamationCircleFill } from "react-icons/bs";
import { convertType } from "utils/functions";
import WebAppLink from "components/blocks/link/WebAppLink";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import { AssigneeInfo } from "./listInfoItem/AssigneeInfo";

interface InfoViewProps {
  system: DashboardData;
  onMouseEnter?: React.MouseEventHandler<HTMLDivElement> | undefined;
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement> | undefined;
  onClickMarkerAlert?: () => void;
}

export default function RecorderInfoView(props: InfoViewProps): JSX.Element {
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const total: DashboardAlertData[] = [
    ...props.system.alerts,
    ...props.system.assign,
    ...props.system.critical,
    ...props.system.warnings,
  ];

  return (
    <Wrapper
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      onClick={props.onClickMarkerAlert}
    >
      <RecorderName>
        <div className="recorder-text type">
          {convertType(props.system)}
          <div className="web-app-link-wrapper">
            <WebAppLink
              {...props.system}
              accountId={selectedAccount.accountId}
            />
          </div>
        </div>
        <div className="recorder-text name">
          {props.system.mergedSystemName}
        </div>
        {props.system.type === "Spectrum" && (
          <div className="merged-name">{props.system.name}</div>
        )}
      </RecorderName>
      <RecorderImage>
        <VideoThumbnail
          videoLoss={false}
          thumbnailUrl={props.system.thumbnail}
        />
      </RecorderImage>
      <AlertList>
        {props.system.warnings.length > 0 && (
          <AlertStatus status="WARNING">
            <BsFillExclamationCircleFill
              size={13}
              color={MAP_STATUS_COLORS.WARNING}
            />
            {props.system.warnings.length}
          </AlertStatus>
        )}
        {props.system.alerts.length > 0 && (
          <AlertStatus status="ALERT">
            <BsFillExclamationCircleFill
              size={13}
              color={MAP_STATUS_COLORS.ALERT}
            />
            {props.system.alerts.length}
          </AlertStatus>
        )}
        {props.system.critical.length > 0 && (
          <AlertStatus status="CRITICAL">
            <BsFillExclamationCircleFill
              size={13}
              color={MAP_STATUS_COLORS.CRITICAL}
            />
            {props.system.critical.length}
          </AlertStatus>
        )}
        {(total.length === undefined || total.length === 0) && (
          <AlertStatus status="RESOLVED">No Alerts</AlertStatus>
        )}
        <AssigneeInfo recorder={props.system} />
      </AlertList>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: ${({ theme }) =>
    theme.value === "light" ? LIGHT_THEME_COLORS.FILTERITEM : COLORS.PRIMARY1};
  border-radius: 8px;
  padding: 14px;
  ${FLEX.FlexStartStart};
  flex-direction: column;
  gap: 6px;
  width: 200px;
`;

const RecorderName = styled.div`
  width: 100%;
  ${FLEX.FlexStartStart};
  flex-direction: column;
  gap: 3px;

  line-height: ${calcRem(16)};

  .recorder-text {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: ${calcRem(13)};
    white-space: nowrap;
    font-weight: 500;
    color: ${({ theme }) => theme.COLORS.WHITE};
  }
  .name {
    font-size: ${calcRem(17)};
  }

  .type {
    color: ${({ theme }) => theme.COLORS.SELECTED};
    ${FLEX.FlexBetweenCenter};

    .web-app-link {
      font-size: ${calcRem(12)};
    }
  }

  .merged-name {
    font-size: ${calcRem(15)};
    color: ${({ theme }) => theme.COLORS.WHITE};
  }
`;

const RecorderImage = styled.div`
  width: 100%;
`;

const AlertList = styled.ul`
  ${FLEX.FlexStartCenter};
  gap: 8px;
`;
const AlertStatus = styled.li<{ status: string }>`
  ${FLEX.FlexStartCenter};
  gap: 4px;
  color: ${({ status }) => MAP_STATUS_COLORS[status]};
  font-size: ${calcRem(13)};
  flex: 1;
`;
