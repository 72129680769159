import styled from "styled-components";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";
import * as mediaQuery from "components/MediaQuery";

export const Container = styled.div`
  width: 100%;
  height: calc(100% - 160px);
  background-color: ${({ theme }) =>
    theme.value === "light" ? COLORS.WHITE : "#0c1724"};
  overflow-y: auto;
  border-radius: 7px;

  ${mediaQuery.isDefault} {
    padding: 0 30px;
    margin: 6px 0 30px;
    display: flex;
    flex: 1;
    flex-direction: column;

    ${({ theme }) =>
      theme.value === "light" &&
      `
      border: 1px solid ${theme.COLORS.BORDER};
    `}
  }
  ${mediaQuery.isMobile} {
    padding: 0 10px;
  }
`;

export const Header = styled.div`
  ${FLEX.FlexBetweenCenter};
  padding-bottom: 8px;

  ${mediaQuery.isDefault} {
    justify-content: flex-start;
    gap: 50px;
  }

  ${mediaQuery.isMobile} {
    flex: 0 0 auto;
  }
`;

export const TableContainer = styled.div`
  display: flex;
  flex: 1;

  .table-container {
    min-height: calc(100% - 260px);
  }
`;

export const Status = styled.ul`
  ${FLEX.FlexEndCenter};
  gap: 20px;

  ${mediaQuery.isDefault} {
    justify-content: flex-start;
  }
`;

export const StatusItem = styled.li`
  font-weight: 500;
  font-size: ${calcRem(18)};
  color: ${COLORS.BUTTONS1};

  strong {
    color: #828b9b;
  }
`;

export const ButtonWrapper = styled.div`
  ${FLEX.FlexEndCenter};
  gap: 10px;

  button {
    padding: 10px;
    min-width: 100px;
  }
`;

export const InforamtionTitle = styled.div`
  width: 100%;
  .information-title {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    ${mediaQuery.isMobile} {
      font-size: ${calcRem(14)};
    }
  }
`;
