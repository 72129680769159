import styled from "styled-components";
import { FLEX } from "styles/flex";
import { COLORS } from "styles/colors";
import Status from "components/atoms/text/labels/Status";
import { Text, UnderlineText } from "components/atoms/text/Text";
import Cloud from "components/atoms/text/labels/Cloud";
import * as mediaQuery from "components/MediaQuery";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { isEmpty } from "lodash";
import { calcRem } from "styles/theme";

export interface TableTitleRow {
  status: string;
  cloudSystemId?: string;
  type: string;
  title: string;
  recorderCount?: number;
  mergedSystemId?: string;
  version: string;
}

type TableTitleProps = {
  report?: boolean;
  row: TableTitleRow;
  onCellClick?: () => void;
};

function TableTitle({
  report,
  row,
  onCellClick,
}: TableTitleProps): JSX.Element {
  const location = useLocation();
  const theme = useAppSelector((state) => state.theme.theme);
  const isLight = theme === "light";

  // TODO Location 정보가 없는 경우 triangle_warning_red 아이콘 표시.
  return (
    <TitleContainer onClick={onCellClick}>
      <TitleWrapper>
        <NameLabel>
          {!report && <Status status={row.status} />}
          {!isEmpty(row.cloudSystemId) &&
            (row.type === "Spectrum" || row.type === "SPECTRUM") && (
              <mediaQuery.Default>
                <Cloud />
              </mediaQuery.Default>
            )}

          <Text className="title" fontSize={14}>
            {row.title}
          </Text>
        </NameLabel>
      </TitleWrapper>
      <mediaQuery.Default>
        <div className="recorder-title-bottom-info">
          <Text fontSize={12} color="#828B9B">
            [{row.version}]
          </Text>
          {row.recorderCount !== undefined &&
            row.recorderCount > 1 &&
            !location.pathname.includes("/billing/recorders") && (
              <Text
                id="recorder-count"
                color={isLight ? COLORS.BUTTONS2 : COLORS.SELECTED}
                fontSize={12}
              >
                [Recorders: {row.recorderCount}]
              </Text>
            )}
        </div>
      </mediaQuery.Default>
    </TitleContainer>
  );
}
const TitleContainer = styled.div`
  width: 100%;
  max-width: 500px;

  .recorder-title-bottom-info {
    ${FLEX.FlexStartCenter};
    font-size: ${calcRem(12)};
    gap: 6px;
  }
`;

const TitleWrapper = styled.div`
  ${FLEX.FlexBetweenCenter};
  width: 100%;
  flex: 1;

  #recorder-count {
    flex: 0 0 auto;
  }
`;

const NameLabel = styled.div`
  ${FLEX.FlexStartCenter};
  gap: 5px;
  width: 100%;
  flex: 1 1 auto;
  //cursor: pointer;

  .title {
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1 1 auto;
    width: 100%;
  }
`;

export default TableTitle;
