import DateTimeSelect from "components/atoms/select/DateTimeSelect";
import Input from "components/atoms/input/Input";
import PrimaryButton from "components/atoms/buttons/PrimaryButton";
import { SearchParams } from "./OperationContainer";
import Selects from "components/atoms/select/Select";
import { getTimeZoneOptions } from "utils/timeUtil";
import { Option } from "utils/options";
import { FilterItem, FilterItemSearchBtn, Label, SearchFilterContainer, SearchFilterRow } from "./SearchFilterStyled";
import { menuOptions } from "./menus";

const timezoneOptions: Option[] = getTimeZoneOptions();

type SearchFilterType = {
  searchParams: SearchParams;
  onClickSearch: () => void;
  onSearchParamChange: (name: string, value: any) => void;
};

export const SearchFilter = ({
  onClickSearch,
  onSearchParamChange,
  searchParams,
}: SearchFilterType) => {
  return (
    <SearchFilterContainer>
      <SearchFilterRow>
        <FilterItem className="organizationid">
          <Label>Organization ID</Label>
          <Input
            name="accountNumber"
            onChange={(e: any) =>
              onSearchParamChange(e.target.name, e.target.value)
            }
            placeholder="Organization id"
          />
        </FilterItem>

        <FilterItem className="useremail">
          <Label>User Email</Label>
          <Input
            name="username"
            onChange={(e: any) =>
              onSearchParamChange(e.target.name, e.target.value)
            }
            placeholder="User email"
          />
        </FilterItem>

        <FilterItem className="menu">
          <Label>Menu</Label>
          <SelectWrapper
            value={searchParams.page}
            onChange={(value: any) => onSearchParamChange("page", value)}
            options={menuOptions}
            placeholder="Menu"
          />
        </FilterItem>

        <FilterItem className="timezone">
          <Label>Timezone</Label>
          <TimezoneSelectWrapper
            value={searchParams.timezone}
            options={timezoneOptions}
            onChange={(value: any) => onSearchParamChange("timezone", value)}
            placeholder="Select Timezone" />
        </FilterItem>

        <FilterItem className="start">
          <Label>From</Label>
          <DateTimeSelect
            valueDate={searchParams.startTime}
            onChange={(date: Date) => onSearchParamChange("startTime", date)}
          />
        </FilterItem>

        <FilterItem className="end">
          <Label>To</Label>
          <DateTimeSelect
            valueDate={searchParams.endTime}
            onChange={(date: Date) => onSearchParamChange("endTime", date)}
          />
        </FilterItem>

        <FilterItemSearchBtn className="submit">
          <PrimaryButton label="Search" onClickBtn={onClickSearch} />
        </FilterItemSearchBtn>
      </SearchFilterRow>
    </SearchFilterContainer>
  );
};

const SelectWrapper = (props: any) => {
  const selectedOption: Option = menuOptions.find((menu) => {
    return menu.value === props.value;
  }) || menuOptions[0];

  return (
    <Selects
      defaultValue={menuOptions[0]}
      value={selectedOption}
      onChange={props.onChange}
      options={props.options}
      placeholder={props.placeholder}
    />
  );
};

const TimezoneSelectWrapper = (props: any) => {
  const optionFound = timezoneOptions.find((option) => {
    return option.value === props.value;
  });
  let value;
  if (optionFound) {
    value = { label: optionFound.label, value: optionFound.value };
  } else {
    value = { label: '(GMT+0:00) UTC (GMT)', value: 'Etc/GMT' };
  }
  
  return (
    <Selects 
      defaultValue={value}
      value={value}
      onChange={props.onChange}
      options={props.options}
      placeholder={props.placeholder} />
  );
}