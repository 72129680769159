import * as s from "styles/ModalCommonStyled";
import StepperComponent, { StepType } from "components/blocks/stepper/Stepper";
import BigButton from "components/atoms/buttons/BigButton";
import { ModalInput } from "components/atoms/input/modal/ModalInput";
import Input from "components/atoms/input/Input";

import TextArea from "components/atoms/input/TextArea";
import PhoneInput from "components/atoms/input/phone/PhoneInput";
import DateSelect from "components/atoms/select/DateSelect";
import AddressInput from "components/atoms/input/address/AddressInput";
import { UPDATE_ADDRESS_TYPE, UPDATE_TYPE } from "./AddInstallContainer";
import { SearchRecorder } from "components/element/SearchRecorder";
import { SelectedRowWithAddress } from "components/element/SearchRecorderList";
import {
  InstallReport,
  ReportDataRow,
} from "api/interfaces/reportInterface.interface";
import { UserInfo } from "api/interfaces/userInterface.interface";
import DeleteConfirmModal from "components/templates/modal/small/confirm/delete";
import {
  Validate,
  ValidateMsg,
} from "api/interfaces/commonInterface.interface";
import { ValidateWord } from "components/atoms/text/Text";

type Props = {
  steps: Array<StepType>;
  page: number;
  isEdit: boolean | undefined;
  installationReport: InstallReport;
  isFlagSelected: string;
  accountId: string;
  onSelectFlag: (code: string) => void;
  onChangePage: (name: string, index?: number) => void;
  onChangeLocationSelect: (
    address: google.maps.LatLngLiteral,
    addressString: string
  ) => void;
  onChangeAddress: (type: UPDATE_ADDRESS_TYPE, address: string) => void;
  onChangeReportInfo: (
    type: UPDATE_TYPE,
    value:
      | string
      | number
      | boolean
      | string[]
      | boolean[]
      | Date
      | SelectedRowWithAddress
  ) => void;
  onClickCreate: () => void;
  onSelectRowChangeAddress?: (row: SelectedRowWithAddress) => void;
  userInfo: UserInfo;
  setIsDeleteConfirm?: (confirm: boolean) => void;
  onConfirmDelete: () => void;
  onDelete: (value: boolean) => void;
  isConfirm: boolean;
  reportRuleValidate: Validate;
  reportRuleValidateMsg: ValidateMsg;
  reportRow?: ReportDataRow;
  saveIsInProgress: boolean;
};

export default function AddInstallPresenter(props: Props): JSX.Element {
  return (
    <>
      <StepperComponent
        activeStep={props.page}
        steps={props.steps}
        onClick={props.onChangePage}
      />
      <s.ModalInnerHalf>
        <div style={{ display: props.page === 0 ? "block" : "none" }}>
          <s.ModalTableContainer>
            <SearchRecorder
              accountId={props.accountId}
              isSingleMode={true}
              isEditable={props.isEdit}
              selectedRow={props.installationReport.recorder.recorderId}
              onSelectedRowsChange={(value: string[]) =>
                props.onChangeReportInfo(UPDATE_TYPE.RECORDER, value)
              }
              onSelectRowChangeAddress={(row: SelectedRowWithAddress) => {
                props.onChangeReportInfo(UPDATE_TYPE.RECORDER, row);
              }}
              userId={props.isEdit ? props.reportRow?.userId : undefined}
            />
          </s.ModalTableContainer>
          <ValidateWord>{props.reportRuleValidateMsg.isRecorder}</ValidateWord>
        </div>
        <div style={{ display: props.page === 1 ? "block" : "none" }}>
          <s.InstallationGroupTitle>Customer</s.InstallationGroupTitle>
          <s.Row>
            <ModalInput label="Name" essential>
              <Input
                placeholder="Enter a name"
                defaultValue={props.installationReport.information.customerName}
                value={props.installationReport.information.customerName}
                //disabled={props.isEdit && !props.installationReport.updatable}
                onChange={(e) =>
                  props.onChangeReportInfo(
                    UPDATE_TYPE.CUSTOMER_NAME,
                    e.target.value
                  )
                }
              />
            </ModalInput>
            <ModalInput label="Phone Number" essential>
              <PhoneInput
                selected={
                  props.installationReport.information.customerPhoneNumber
                }
                onSelect={props.onSelectFlag}
                placeholder="Enter a phone number"
                //disabled={props.isEdit && !props.installationReport.updatable}
                onChange={(phoneNumber: string) =>
                  props.onChangeReportInfo(
                    UPDATE_TYPE.CUSTOMER_PHONE,
                    phoneNumber
                  )
                }
              />
            </ModalInput>
          </s.Row>
          <s.Row>
            <ValidateWord>
              {props.reportRuleValidateMsg.isCustomName}
            </ValidateWord>
            <ValidateWord>
              {props.reportRuleValidateMsg.isCustomPhone}
            </ValidateWord>
          </s.Row>
          <ModalInput label="Address" essential>
            <AddressInput
              isLoaded={true}
              // defaultValue={
              //   props.installationReport.information.customerLocation
              // }
              value={props.installationReport.information.customerLocation}
              //isDisabled={props.isEdit && !props.installationReport.updatable}
              onChange={(e) =>
                props.onChangeAddress(
                  UPDATE_ADDRESS_TYPE.CUSTOMER,
                  e.currentTarget.value
                )
              }
              onChangeLocationSelect={(
                address: google.maps.LatLngLiteral,
                addressString?: string
              ) => {
                if (addressString !== undefined) {
                  props.onChangeReportInfo(
                    UPDATE_TYPE.CUSTOMER_ADDRESS,
                    addressString
                  );
                }
              }}
            />
          </ModalInput>
          <ValidateWord>
            {props.reportRuleValidateMsg.isCustomAddress}
          </ValidateWord>
          <s.InstallationGroupTitle style={{ marginTop: 20 }}>
            Installer
          </s.InstallationGroupTitle>
          <s.Row>
            <ModalInput label="Name" essential>
              <Input
                placeholder="Enter a name"
                defaultValue={props.userInfo.name}
                value={props.installationReport.information.installerName}
                //disabled={props.isEdit && !props.installationReport.updatable}
                onChange={(e) =>
                  props.onChangeReportInfo(
                    UPDATE_TYPE.INSTALLER_NAME,
                    e.target.value
                  )
                }
              />
            </ModalInput>
            <ModalInput label="Phone Number" essential>
              <PhoneInput
                selected={
                  props.installationReport.information.installerPhoneNumber
                }
                onSelect={props.onSelectFlag}
                placeholder="Enter a phone number"
                //disabled={props.isEdit && !props.installationReport.updatable}
                onChange={(phoneNumber: string) =>
                  props.onChangeReportInfo(
                    UPDATE_TYPE.INSTALLER_PHONE,
                    phoneNumber
                  )
                }
              />
            </ModalInput>
          </s.Row>
          <s.Row>
            <ValidateWord>
              {props.reportRuleValidateMsg.isInstallerName}
            </ValidateWord>
            <ValidateWord>
              {props.reportRuleValidateMsg.isInstallerPhone}
            </ValidateWord>
          </s.Row>
          <s.Row>
            <ModalInput label="Company">
              <Input
                placeholder="Enter company name"
                defaultValue={
                  props.installationReport.information.installerCompany
                }
                value={props.installationReport.information.installerCompany}
                //disabled={props.isEdit && !props.installationReport.updatable}
                onChange={(e) =>
                  props.onChangeReportInfo(
                    UPDATE_TYPE.COMPANY_NAME,
                    e.target.value
                  )
                }
              />
            </ModalInput>
            <ModalInput label="Company Phone Number">
              <PhoneInput
                selected={
                  props.installationReport.information
                    .installerCompanyPhoneNumber
                }
                onSelect={props.onSelectFlag}
                placeholder="Enter a phone number"
                //disabled={props.isEdit && !props.installationReport.updatable}
                onChange={(phoneNumber: string) =>
                  props.onChangeReportInfo(
                    UPDATE_TYPE.COMPANY_PHONE,
                    phoneNumber
                  )
                }
              />
            </ModalInput>
          </s.Row>
          <ModalInput label="Company Adddress">
            <AddressInput
              isLoaded={true}
              defaultValue={
                props.installationReport.information.installerCompanyAddress
              }
              value={
                props.installationReport.information.installerCompanyAddress
              }
              //isDisabled={props.isEdit && !props.installationReport.updatable}
              onChange={(e) =>
                props.onChangeAddress(
                  UPDATE_ADDRESS_TYPE.INSTALLER,
                  e.currentTarget.value
                )
              }
              onChangeLocationSelect={(
                address: google.maps.LatLngLiteral,
                addressString?: string
              ) => {
                if (addressString !== undefined) {
                  props.onChangeReportInfo(
                    UPDATE_TYPE.COMPANY_ADDRESS,
                    addressString
                  );
                }
              }}
            />
            {/* <PhoneInput
          selected={props.isFlagSelected}
          onSelect={props.onSelectFlag}
          placeholder="Enter company address"
        /> */}
          </ModalInput>
          <ModalInput label="Installation Date" half>
            {/* date picker */}
            <DateSelect
              placeholder="Enter date"
              value={props.installationReport.information.installationDate}
              //disabled={props.isEdit &&  !props.installationReport.updatable}
              onChange={(value) => {
                props.onChangeReportInfo(UPDATE_TYPE.INSTALL_DATE, value);
              }}
              tileDisabled={({ date, view }) =>
                view === "month" && date > new Date()
              }
            />
          </ModalInput>
          <ModalInput label="Description">
            <TextArea
              placeholder="Enter description"
              value={props.installationReport.information.deviceDescription}
              //disabled={props.isEdit &&  !props.installationReport.updatable}
              onChange={(e) =>
                props.onChangeReportInfo(
                  UPDATE_TYPE.DEVICE_DESC,
                  e.currentTarget.value
                )
              }
            />
          </ModalInput>
          {/* <s.InstallationGroupTitle>Device</s.InstallationGroupTitle>
          <ModalInput label="Serial">
            <Input placeholder="Enter serial number" />
          </ModalInput>
          
          <ModalInput label="Analog Camera Model" half>
            <s.RowMobileNoColumn>
              <Input placeholder="Enter model name" />
              <img src={PLUS_CIRCLE} alt="plus in circle icon" />
            </s.RowMobileNoColumn>
          </ModalInput> */}
        </div>
      </s.ModalInnerHalf>
      <s.ButtonWrapper>
        {props.isEdit && (
          <BigButton
            buttonType="warning"
            label="Delete"
            onClickBtn={() =>
              props.setIsDeleteConfirm !== undefined
                ? props.setIsDeleteConfirm(true)
                : null
            }
            disabled={false}
          />
        )}
        {props.page !== 0 && (
          <BigButton
            buttonType="cancel"
            label="Previous"
            onClickBtn={() => props.onChangePage("previous")}
          />
        )}

        {props.page === 1 ? (
          <BigButton
            label="Save"
            onClickBtn={props.onClickCreate}
            //disabled={props.isEdit && !props.installationReport.updatable }
            disabled={props.saveIsInProgress}
          />
        ) : (
          <BigButton
            buttonType="cancel"
            label="Next"
            onClickBtn={() => props.onChangePage("next")}
          />
        )}
        {props.isConfirm && (
          <DeleteConfirmModal
            label="report rule"
            onDelete={() => props.onDelete(false)}
            onConfirmDelete={props.onConfirmDelete}
          />
        )}
      </s.ButtonWrapper>
    </>
  );
}
