import { Fragment, useCallback, useEffect, useState } from "react";
import { RecordersPresenter } from "./ListPresenter";
import * as s from "../RecordersStyled";
import TableButton from "components/atoms/buttons/TableButton";
import Type from "components/atoms/text/labels/Type";
import ONLINE_ICON from "assets/icons/online.svg";
import OFFLINE_ICON from "assets/icons/offline.svg";
import TableTitle from "components/blocks/table/TableTitle";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import TableColumnFilterTitle from "components/blocks/table/TableColumnFilterTitle";
import {
  BasePagination,
  CustomError,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import { useAppSelector } from "redux/hooks";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useQuery, useQueryClient } from "react-query";
import { deleteMergedSystem, getRecorderList } from "api/recorderAPI";
import { checkStatus, convertType, findPopup } from "utils/functions";
import { StatusName, StatusValue } from "components/blocks/status/Status";
import RBACWrapper from "components/blocks/function/RBACWrapper";
import {
  LicenseCount,
  MergedRecorder,
  MergedRecorderListResponse,
  Recorder,
} from "api/interfaces/recorderInterface.interface";
import { LicenseRowType } from "api/interfaces/billing.interface";
import { isCheckPermission } from "utils/AccountUtil";
import { AlertRecorerTypeFilter } from "utils/AlertUtil";
import WebAppLink from "components/blocks/link/WebAppLink";
import { useIntl } from "react-intl";
import { isNull } from "lodash";
import { queryNavigate } from "utils/MenuUtil";
import { useMutation } from "react-query";
import { notify } from "components/atoms/notification/Notification";
import * as mediaQuery from "components/MediaQuery";
import { Text } from "components/atoms/text/Text";
import { COLORS } from "styles/colors";
import { NeedActionCell } from "./NeedActionCell";
import { TableColumnsType } from "antd";
import {
  changeSortOrderAntd,
  onSortAntd,
} from "components/atoms/table/AntdTable";
import { ColumnType } from "antd/es/table";
import { useSelector } from "react-redux";
import { selectStomp } from "redux/stomp/stompSelector";

export interface DataRow {
  id: string;
  recordId: string;
  cloudSystemId: string;
  type: string;
  brand?: string;
  title: string;
  status: string;
  //host: string;
  recorderCount: number;
  version: string;
  cameras: number;
  onlineStatusCount: number;
  mergedSystemId: string;
  licenses: LicenseRowType;
  createBy: string;
  createByEmail: string;
  enableEdit?: boolean;
  isMerged?: boolean;
  hasAuthError?: boolean;
  hasIncompleteRegistration?: boolean;
  hasDetached?: boolean;
  hostValidation: boolean;
  row: MergedRecorder | Recorder;
}

function mergedRecorderToDataRow(recorder: MergedRecorder): DataRow {
  return {
    id: recorder.mergedSystemId,
    recordId:
      recorder.recorderCount === 1
        ? recorder.recorders[0]?.recorderId
        : recorder.mergedSystemId,
    mergedSystemId: recorder.mergedSystemId,
    type: recorder.type,
    title: recorder.mergedSystemName,
    status:
      recorder.recorderCount !== undefined &&
      recorder.onlineStatusCount !== undefined
        ? checkStatus(recorder.recorderCount, recorder.onlineStatusCount)
        : "offline",
    createBy: recorder.createBy,
    brand: recorder.recorders[0]?.brand,
    createByEmail: recorder.createByEmail,
    //host: recorder.installer,
    onlineStatusCount: recorder.onlineStatusCount,
    recorderCount: recorder.recorderCount,
    cloudSystemId: recorder.cloudSystemId,
    version: recorder.firmwareVersion,
    cameras: recorder.cameraCount,
    enableEdit: recorder.enableEdit,
    licenses: {
      count: 0,
      status: "PREPARING",
    },
    hostValidation: recorder.hostValidation,
    hasAuthError: recorder.recorders.some((child) => child.isAuthError),
    hasDetached: recorder.recorders.some((child) => child.isDetached),
    hasIncompleteRegistration: recorder.incompleteRegistration,
    row: recorder,
  };
}

const statusInitValue = {
  totalTitle: "Total",
  totalValue: 0,
  items: [
    <>
      <s.Label>
        <img src={ONLINE_ICON} alt="online icon" />
        <StatusName>Online</StatusName>
      </s.Label>
      <StatusValue>0</StatusValue>
    </>,
    <>
      <s.Label>
        <img src={OFFLINE_ICON} alt="offline icon" />
        <StatusName>Offline</StatusName>
      </s.Label>
      <StatusValue>0</StatusValue>
    </>,
  ],
};
const initialQueryinfo = {
  total: 0,
  pageNum: 0,
  pageLimit: 25,
  keyword: "",
  sortType: "mergedSystemName",
  sortDirection: SORT_DIRECTION.ASC,
  type: null,
};
export function RecordersContainer(): JSX.Element {
  const intl = useIntl();
  const location = useLocation();

  const navigate = useNavigate();
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const queryClient = useQueryClient();
  const [recorder, setRecorder] = useState<DataRow[]>([]);
  const [recorderQueryInfo, setRecorderQueryInfo] = useState<BasePagination>({
    ...initialQueryinfo,
  });
  const { recorderListUpdated, userPermissionUpdated } = useSelector(selectStomp);

  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);
  const [isDeregisterHelp, setIsDeregisterHelp] = useState<boolean>(false);

  const [status, setStatus] = useState(statusInitValue);
  const [licenseCount, setLicenseCount] = useState<LicenseCount>({
    totalCameraCount: 0,
    totalLicensedCameraCount: 0,
  });

  const [isAddModal, setIsAddModal] = useState<boolean>(false);
  const [isEditModal, setIsEditModal] = useState<boolean>(false);
  const [isLicenseModal, setIsLicenseModal] = useState<boolean>(false);
  const [isRemoveLicenseModal, setIsRemoveLicenseModal] =
    useState<boolean>(false);
  const [selectRow, setSelectRow] = useState<DataRow>();

  const [isBubble, setIsBubble] = useState<boolean>(false);

  useEffect(() => {
    if (recorderListUpdated) {
      recorderQuery.refetch();
    }
  }, [recorderListUpdated]);

  useEffect(() => {
    if (userPermissionUpdated) {
      recorderQuery.refetch();
    }
  }, [userPermissionUpdated]);

  useEffect(() => {
    const changeParam = new URLSearchParams(location.search);
    if (changeParam.size > 0) {
      setRecorderQueryInfo((info) => {
        return {
          ...info,
          pageNum: isNull(changeParam.get("pageNum"))
            ? 0
            : Number(changeParam.get("pageNum")),
          pageLimit: isNull(changeParam.get("pageLimit"))
            ? 25
            : Number(changeParam.get("pageLimit")),
          keyword: isNull(changeParam.get("keyword"))
            ? ""
            : (changeParam.get("keyword") as string),
          sortType: isNull(changeParam.get("sortType"))
            ? "mergedSystemName"
            : (changeParam.get("sortType") as string),
          sortDirection: isNull(changeParam.get("sortDirection"))
            ? SORT_DIRECTION.ASC
            : (changeParam.get("sortDirection") as SORT_DIRECTION),
          type: isNull(changeParam.get("type"))
            ? null
            : (changeParam.get("type") as string),
        };
      });
    } else {
      setRecorderQueryInfo((info) => {
        return {
          ...initialQueryinfo,
        };
      });
    }
  }, [location]);

  const initialData = () => {
    setRecorder([]);
    setLicenseCount({
      totalCameraCount: 0,
      totalLicensedCameraCount: 0,
    });
    setStatus(statusInitValue);
    setRecorderQueryInfo((info) => {
      return {
        ...initialQueryinfo,
      };
    });
  };

 useEffect(() => {
  const interval = setInterval(() => {
      if(findPopup("recorder-polling-popup") == null){
        recorderQuery.refetch();
      } 
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const recorderQuery = useQuery(
    ["recorder", recorderQueryInfo, selectedAccount],
    () =>
      getRecorderList({
        payload: recorderQueryInfo,
        accountId: selectedAccount.accountId,
      }),
    {
      enabled: true,
      //refetchInterval: 10000,
      refetchOnWindowFocus: false,
      retry: 0,
      onSuccess: (res: MergedRecorderListResponse) => {
        if (res.error !== 0 || !res.result) {
          initialData();
          return;
        }

        setRecorder(
          res.result.mergedSystemList.map(
            (recorder: MergedRecorder): DataRow => {
              return mergedRecorderToDataRow(recorder);
            }
          )
        );

        setStatus({
          totalTitle: "Total",
          totalValue: res.result.summary.totalRecorderCount,
          items: [
            <>
              <s.Label>
                <img src={ONLINE_ICON} alt="online icon" width={16} />
                <StatusName>Online</StatusName>
              </s.Label>
              <StatusValue>{res.result.summary.onlineStatusCount}</StatusValue>
            </>,
            <>
              <s.Label>
                <img src={OFFLINE_ICON} alt="offline icon" width={16} />
                <StatusName>Offline</StatusName>
              </s.Label>
              <StatusValue>
                {res.result.summary.totalRecorderCount -
                  res.result.summary.onlineStatusCount}
              </StatusValue>
            </>,
          ],
        });
        setLicenseCount({
          totalCameraCount: res.result.licenseCount.totalCameraCount,
          totalLicensedCameraCount:
            res.result.licenseCount.totalLicensedCameraCount,
        });
        setRecorderQueryInfo((info) => {
          return {
            ...info,
            ...res.page,
          };
        });
      },
      onError: (e: CustomError) => {
        initialData();
      },
      onSettled: (e: any) => {
        setPending(false);
      },
    }
  );

  const onChangeReportType = useCallback(
    (value: string | undefined) => {
      if (value !== undefined) {
        if (value === "all") {
          if (recorderQueryInfo.type !== null) {
            setRecorderQueryInfo((info) => {
              return { ...info, type: null, pageNum: 0 };
            });
          } else {
            recorderQuery.refetch();
          }
        } else {
          if (recorderQueryInfo.type !== value) {
            setRecorderQueryInfo((info) => {
              return { ...info, type: value as string, pageNum: 0 };
            });
          } else {
            recorderQuery.refetch();
          }
        }
      }
    },
    [recorderQuery, recorderQueryInfo.type]
  );
  const onHeaderCell = (column: ColumnType<DataRow>) => {
    return {
      onClick: () => {
        if (column.key) {
          setRecorderQueryInfo((info) => {
            return {
              ...info,
              sortType: column.key as string,
              sortDirection: onSortAntd(info.sortDirection),
            };
          });
        }
      },
    };
  };

  const columns: TableColumnsType<DataRow> = [
    {
      title: (
        <TableColumnFilterTitle
          title="Type"
          filters={AlertRecorerTypeFilter}
          onClick={onChangeReportType}
        />
      ),
      width: 110,
      render: (value, row) => <Type type={convertType(row)} />,
    },
    {
      title: "Title",
      sorter: true,
      key: "mergedSystemName",
      defaultSortOrder: "ascend",
      sortOrder: changeSortOrderAntd(recorderQueryInfo, "mergedSystemName"),
      onHeaderCell: onHeaderCell,
      render: (value, row) =>
        row.recorderCount !== undefined && row.recorderCount > 0 ? (
          <TableTitle row={row} onCellClick={() => onClickEdit(row)} />
        ) : (
          <Fragment />
        ),
      ellipsis: true,
    },
    {
      title: "",
      render: (value, row) => (
        <s.Row onClick={(e) => e.stopPropagation()}>
          <WebAppLink
            {...row}
            accountId={selectedAccount.accountId}
            recorderId={row.recordId}
          />
        </s.Row>
      ),
      width: 80,
      align: "right",
    },
    {
      title: "Host",
      sorter: true,
      sortOrder: changeSortOrderAntd(recorderQueryInfo, "createBy"),
      onHeaderCell: onHeaderCell,
      key: "createBy",
      render: (value, row) => (
        <s.Row>
          <Text fontSize={14} isDeleted={!row.hostValidation}>
            {row.createBy}
          </Text>
          <mediaQuery.Default>
            <Text
              color={COLORS.BUTTONS1}
              fontSize={13}
              isDeleted={!row.hostValidation}
            >
              {row.hostValidation
                ? `(${row.createByEmail})`
                : `(${row.createByEmail}) - Deactivated User`}
            </Text>
          </mediaQuery.Default>
        </s.Row>
      ),
      responsive: ["lg"],
    },
    {
      title: "Cameras",
      render: (value, row) => row.cameras,
      align: "center",
      responsive: ["xl"],
      width: 100,
    },
    {
      title: "",
      width: 150,
      render: (value, row) => <NeedActionCell recorder={row} />,
      responsive: ["xl"],
    },
    {
      title: "",
      width: 150,
      render: (row) => {
        if (row.recorderCount === 1 && row.enableEdit) {
          return (
            <RBACWrapper requiredPermissions="manageRecordersAndGroups">
              <s.Row className="table-button-wrapper">
                <span className="row-click-button">
                  <TableButton label="Edit" onClick={() => onClickEdit(row)} />
                </span>
              </s.Row>
            </RBACWrapper>
          );
        }
        if (convertType(row) === "SPECTRUM") {
          return (
            <RBACWrapper requiredPermissions="manageRecordersAndGroups">
              <s.Row className="table-button-wrapper">
                <TableButton
                  label="Delete"
                  buttonType="warning"
                  onClick={(e) => {
                    e.stopPropagation();
                    onClickRemove(row);
                  }}
                />
                {/* TODO tree구조에서는 이 버튼 삭제 */}
                <span className="row-click-button">
                  <TableButton
                    label="Enter"
                    onClick={(e) => {
                      e.stopPropagation();
                      onEnter(row);
                    }}
                  />
                </span>
              </s.Row>
            </RBACWrapper>
          );
        }
        return <Fragment />;
      },
      align: "right",
    },
  ];

  const onModal = (type: string, row?: DataRow) => {
    if (type === "add") {
      if (isAddModal) {
        queryClient.invalidateQueries("recorder");
      }
      setIsAddModal(!isAddModal);
    }
    if (type === "edit") {
      setSelectRow(row);
      setIsEditModal(!isEditModal);
    }
    if (type === "license") {
      setIsLicenseModal(!isLicenseModal);
    }
    if (type === "remove") {
      setIsRemoveLicenseModal(!isRemoveLicenseModal);
    }
  };

  const onClickRemove = (row: DataRow) => {
    setSelectRow(row);
    setIsDeleteConfirm(true);
  };

  const onClickEdit = (row: DataRow) => {
    onModal("edit", row);
  };

  const onEnter = (row: DataRow) => {
    navigate({
      pathname: `/recorders/list/detail/${row.mergedSystemId}`,
      search: `?${createSearchParams({
        account: selectedAccount.accountId,
      })}`,
    });
  };

  const onRow = (row: DataRow) => {
    if (
      row.recorderCount === 1 &&
      row.enableEdit &&
      isCheckPermission("manageRecordersAndGroups", selectedAccount)
    ) {
      onClickEdit(row);
      return;
    }
    // TODO Tree 구조 변경 시 expand 이벤트로 변경
    if (convertType(row) === "SPECTRUM") {
      onEnter(row);
      return;
    }
  };

  const [pending, setPending] = useState<boolean>(true);

  const onChangePage = (page: number, totalRows: number) => {
    if (page !== recorderQueryInfo.pageNum + 1) {
      queryNavigate(
        navigate,
        location.pathname,
        {
          pageLimit: recorderQueryInfo.pageLimit,
          pageNum: page - 1,
          keyword: recorderQueryInfo.keyword as string,
          sortType: recorderQueryInfo.sortType,
          sortDirection: recorderQueryInfo.sortDirection as SORT_DIRECTION,
        },
        selectedAccount
      );
      setPending(true);
      // setRecorderQueryInfo((info) => {
      //   return { ...info, pageNum: (page - 1) as number };
      // });
    }
  };

  const onClickSearch = useCallback(
    (keyword: string) => {
      if (recorderQueryInfo.keyword !== keyword) {
        queryNavigate(
          navigate,
          location.pathname,
          {
            pageLimit: recorderQueryInfo.pageLimit,
            pageNum: 0,
            keyword: keyword,
            sortType: recorderQueryInfo.sortType,
            sortDirection: recorderQueryInfo.sortDirection as SORT_DIRECTION,
          },
          selectedAccount
        );
      } else {
        recorderQuery.refetch();
      }
      setPending(true);
    },
    [
      false,
      location.pathname,
      navigate,
      recorderQuery,
      recorderQueryInfo.keyword,
      recorderQueryInfo.pageLimit,
      recorderQueryInfo.sortDirection,
      recorderQueryInfo.sortType,
      selectedAccount,
    ]
  );

  // const handleSort = async (
  //   selectedColumn: TableColumn<any>,
  //   sortDirection: SortOrder
  // ) => {
  //   if (selectedColumn.sortField !== undefined) {
  //     setRecorderQueryInfo((info) => {
  //       return {
  //         ...info,
  //         sortType: selectedColumn.sortField as string,
  //         sortDirection: sortDirection.toUpperCase() as SORT_DIRECTION,
  //       };
  //     });
  //     //setPending(true);
  //   }
  // };

  const mutationDeleteSystem = useMutation(deleteMergedSystem, {
    onSuccess: () => {
      notify(
        "success",
        intl.formatMessage({
          id: "label.recorder.notify.deRegistration.success",
          defaultMessage: "System deleted successfully.",
        })
      );
      queryClient.invalidateQueries("recorder");
      if (selectRow && selectRow.type.toUpperCase() === "SPECTRUM") {
        setIsDeregisterHelp(true);
      }
    },
    onError: () => {
      notify(
        "error",
        intl.formatMessage({
          id: "label.recorder.notify.deRegistration.fail",
          defaultMessage: "Failed to delete system.",
        })
      );
    },
    onSettled: () => {
      setIsDeleteConfirm(false);
    },
  });
  const onClickDelete = useCallback(
    (result: boolean) => {
      setIsDeleteConfirm(false);
      if (!result) {
        return;
      }
      if (!selectRow || !selectRow.mergedSystemId) {
        return;
      }
      mutationDeleteSystem.mutate({
        accountId: selectedAccount.accountId,
        mergedSystemId: selectRow.mergedSystemId,
      });
    },
    [mutationDeleteSystem, selectedAccount.accountId, selectRow?.mergedSystemId]
  );

  return (
    <RecordersPresenter
      onModal={onModal}
      selectRow={selectRow as DataRow}
      data={recorder}
      isAddModal={isAddModal}
      isEditModal={isEditModal}
      isBubble={isBubble}
      licenseCount={licenseCount}
      setIsBubble={setIsBubble}
      columns={columns}
      status={status}
      paginationInfo={recorderQueryInfo}
      onChangePage={onChangePage}
      onClickSearch={onClickSearch}
      onClickEdit={onClickEdit}
      initialPending={pending}
      selectedAccount={selectedAccount}
      isDeleteConfirm={isDeleteConfirm}
      onClickDelete={onClickDelete}
      isDeregisterHelp={isDeregisterHelp}
      closeDeregisterHelp={() => {
        setIsDeregisterHelp(false);
      }}
      openDeregisterHelp={() => {
        setIsDeregisterHelp(true);
      }}
      onRow={onRow}
    />
  );
}
