import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { persistor, store } from "redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthProvider } from "components/Auth";
import { CookiesProvider } from "react-cookie";
import ReactGA from "react-ga4";
import { datadogRum } from "@datadog/browser-rum";
import { IntlProvider } from "react-intl";
import enMessage from "./lang/en-US.json";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

// if (process.env.REACT_APP_GOOGLE_ANALYTICS) {
//   ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
// }

if (
  process.env.NODE_ENV === "production" &&
  process.env.REACT_APP_DATADOG_ENV !== "prod"
) {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID as string,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN as string,
    site: "datadoghq.com",
    service: process.env.REACT_APP_DATADOG_SERVICE,
    env: process.env.REACT_APP_DATADOG_ENV,
    version: process.env.REACT_APP_VERSION,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackSessionAcrossSubdomains: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    startSessionReplayRecordingManually: true,
    allowedTracingUrls: [process.env.REACT_APP_BASE_URL as string],
    traceSampleRate: 100,
  });

  datadogRum.startSessionReplayRecording();
}

root.render(
  <React.StrictMode>
    <CookiesProvider>
      <BrowserRouter>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <IntlProvider messages={enMessage} locale="en" defaultLocale="en">
              <QueryClientProvider client={queryClient}>
                <AuthProvider>
                  <App />
                </AuthProvider>
              </QueryClientProvider>
            </IntlProvider>
          </PersistGate>
        </Provider>
      </BrowserRouter>
    </CookiesProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
