import * as s from "../RecorderListInfoItemStyled";
import { COLORS, LIGHT_THEME_COLORS } from "styles/colors";
import { DashboardData } from "api/interfaces/dashboardInterface.interface";
import RecorderInfoView from "../RecorderInfoView";
import Popover from "antd/lib/popover";

import WebAppLink from "components/blocks/link/WebAppLink";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import { useMediaQuery } from "react-responsive";
import { tabletMin } from "components/MediaQuery";
import { ChildrenRecorder } from "../ChildrenRecorder";
import {
  getMarker,
  getTopAlertStatusString,
  RecorderStatus,
} from "../../../utils";
import { AssigneeInfo } from "./AssigneeInfo";

type ItemOneRecorderType = {
  system: DashboardData;
  onSelectRecorder: (system: DashboardData) => void;
};

export const ItemOneRecorder = ({
  system,
  onSelectRecorder,
}: ItemOneRecorderType) => {
  const isNotMobile = useMediaQuery({ minWidth: tabletMin });
  const theme = useAppSelector((state) => state.theme.theme);
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const tmpStatus: RecorderStatus = getTopAlertStatusString(system);

  return (
    <Popover
      trigger="hover"
      placement="right"
      content={isNotMobile && <RecorderInfoView system={system.recorders[0]} />}
      style={{
        padding: 0,
      }}
      zIndex={980}
      color={
        theme === "light" ? LIGHT_THEME_COLORS.FILTERITEM : COLORS.PRIMARY1
      }
    >
      <s.Item
        key={`recorder-list-map-view-${system.recorderId}`}
        onClick={() => onSelectRecorder(system)}
        isMultiRecorders={true}
      >
        <div className="left-info">
          <div className="system-alert-marker">
            <img src={getMarker(tmpStatus)} alt={`marker-${tmpStatus}`} />
          </div>

          <div className="system-info">
            <div className="system-title">
              <h5>{system.mergedSystemName}</h5>
              <div className="web-app-link-wrapper">
                <WebAppLink {...system} accountId={selectedAccount.accountId} />
              </div>
            </div>

            {system.type === "Spectrum" && system.recorders ? (
              <ul className="spectrum-system-ul">
                <ChildrenRecorder
                  rec={system.recorders[0]}
                  onSelectRecorder={onSelectRecorder}
                />
              </ul>
            ) : (
              <div>
                <AssigneeInfo recorder={system} />
              </div>
            )}
          </div>
        </div>
      </s.Item>
    </Popover>
  );
};
