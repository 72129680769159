import {
  ActiveAlert,
  AlertActiveSummary,
  AlertCamera,
  AlertHistoryTraceResponse,
  AlertList,
  AlertTraceList,
  AlertType,
  AlertViewListResponse,
  AlertViewInfo,
} from "api/interfaces/alertInterface.interface";
import AlertViewPresenter from "./AlertViewPresenter";
import { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
  getAlertTraceList,
  getAlertViewList,
  putAlertStatusAllClose,
} from "api/alertAPI";
import { notify } from "components/atoms/notification/Notification";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import { Option } from "utils/options";
import { useQueryClient } from "react-query";
import useApiError from "hook/useApiError";
import { CustomError } from "api/interfaces/commonInterface.interface";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import { filteringDiskAlertBoolean } from "utils/AlertUtil";

interface Props {
  isModal?: boolean;
  onModal?: () => void;
  searchParams?: URLSearchParams;
}

const isEqualCategory = (category: string, alertType: string) => {
  return category.toUpperCase() === alertType.toUpperCase();
};

const AlertViewContainer = ({ searchParams, onModal, isModal }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const intl = useIntl();
  const { handleError } = useApiError();

  const [urlSearchParams] = useSearchParams();

  const { onChangeTitle, onChangeStatusAlertSummary } =
    useOutletContext<any>() ?? {};

  const recorderId =
    searchParams?.get("recorderId") ?? urlSearchParams.get("recorderId") ?? "";
  const historyId =
    searchParams?.get("historyId") ?? urlSearchParams.get("historyId") ?? "";

  const alertType =
    searchParams?.get("alertType")?.toUpperCase() ??
    urlSearchParams.get("alertType")?.toUpperCase() ??
    "";

  const queryClient = useQueryClient();
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const isMap = location.pathname.includes("map");
  const isHistory = location.pathname.includes("history");

  const [originList, setOriginList] = useState<AlertList[]>([]);

  const [alertInfo, setAlertInfo] = useState<AlertViewInfo | null>(null);
  const [traceList, setTraceList] = useState<AlertTraceList[]>([]);
  const [alertTypeList, setAlertTypeList] = useState<AlertType[]>([]);
  const [currentAlert, setCurrentAlert] = useState<AlertList | null>(null);
  const [count, setCount] = useState<AlertActiveSummary>({
    total: 0,
    systemCount: 0,
    diskCount: 0,
    videoCount: 0,
  });

  const [isConfirmStatus, SetIsConfirmStatus] = useState<boolean>(false);
  const [checkNoConfirm, setCheckNoConfirm] = useState<boolean>(false);

  const onCheckNoConfirm = (value: boolean) => {
    setCheckNoConfirm(value);
  };

  const onConfirmCancel = () => {
    SetIsConfirmStatus(false);
  };

  const onConfirmOk = (checked: boolean) => {
    mutationAlertStatusAllClose.mutate({
      accountId: selectedAccount.accountId,
      recorderId: recorderId,
    });
  };

  const alertViewListQuery = useQuery(
    ["alertViewList", selectedAccount, recorderId, historyId],
    () =>
      getAlertViewList({
        accountId: selectedAccount.accountId,
        recorderId,
        ...(historyId && isHistory && { historyId }),
      }),
    {
      retry: 0,
      enabled: !!recorderId,
      refetchOnWindowFocus: false,
      onSuccess: (res: AlertViewListResponse) => {
        // console.log(res.result, res.result.alerts, alertInfo, "0000");
        if (res.error !== 0 && !res.result) {
          setAlertTypeList([]);
          return;
        }

        setCount(res.count);
        onChangeStatusAlertSummary && onChangeStatusAlertSummary(res.count);
        onChangeTitle && onChangeTitle(res.result.mergedSystemName);

        if (!res.result.alerts) {
          setAlertTypeList([]);
          return;
        }

        setAlertInfo((sel) => {
          return { ...sel, ...res.result };
        });
        setOriginList(res.result.alerts);
        let tempAlertTypeList = res.result.alerts
          .reduce((acc: AlertType[], cur: AlertList) => {
            if (
              (filteringDiskAlertBoolean(cur) && cur.storageName) ||
              (cur.type === "Video Loss" && cur.camera)
            ) {
              const index = acc.findIndex(
                (item: any) => item.type === cur.type
              );

              const newItem: AlertType = {
                ...cur,
                ...(filteringDiskAlertBoolean(cur) &&
                  cur.storageName && {
                    storageList: [
                      { value: cur.alertHistoryId, label: cur.storageName },
                    ],
                  }),
                ...(cur.type === "Video Loss" &&
                  cur.camera && {
                    cameraList: [
                      {
                        ...cur.camera,
                        value: cur.alertHistoryId,
                        label: cur.camera.cameraName,
                      },
                    ],
                  }),
              };
              // type 같은 것 끼리 이미 storageList 나 cameraList group 이 없을 경우 group 생성
              if (index !== -1) {
                if (cur.type === "Video Loss") {
                  acc[index] = {
                    ...acc[index],
                    ...newItem,
                    cameraList: [
                      ...(acc[index].cameraList || []),
                      ...(newItem.cameraList || []),
                    ],
                  };
                }
                if (filteringDiskAlertBoolean(cur)) {
                  acc[index] = {
                    ...acc[index],
                    ...newItem,
                    storageList: [
                      ...(acc[index].storageList || []),
                      ...(newItem.storageList || []),
                    ],
                  };
                }
                acc[index].cameraList?.sort((a: Option, b: Option) => {
                  return a.label.localeCompare(b.label, undefined, {
                    numeric: true,
                    sensitivity: "base",
                  });
                });
                acc[index].storageList?.sort((a: Option, b: Option) => {
                  return a.label.localeCompare(b.label, undefined, {
                    numeric: true,
                    sensitivity: "base",
                  });
                });
              } else {
                acc.push(newItem);
              }
            } else {
              acc.push({
                ...cur,
              });
            }
            return acc;
          }, [])
          .sort((a) => {
            // console.log(a);
            return isEqualCategory(a.category, alertType) ? -1 : 1;
          });
        setAlertTypeList(tempAlertTypeList);
        // 선택한 Alert 상태
        let tempCurrentAlert = res.result.alerts.find((alert, index) => {
          // console.log(alert, alertType, historyId);
          if (alertType) {
            if (historyId && !isHistory) {
              return historyId === alert.alertHistoryId;
            }

            return isEqualCategory(alert.category, alertType);
          }

          return index === 0;
        });

        setCurrentAlert(
          tempCurrentAlert ? tempCurrentAlert : res.result.alerts[0]
        );
      },
      onError: (err: any) => {
        setAlertTypeList([]);

        if (!isMap) {
          notify(
            "error",
            intl.formatMessage({
              id: "label.alert.notify.list.error",
              defaultMessage: "Error while searching for alert",
            })
          );
        }
      },
    }
  );

  const alertTraceListQuery = useQuery(
    ["alertTraceList", selectedAccount, alertInfo],
    () =>
      getAlertTraceList({
        accountId: selectedAccount.accountId,
        recorderId,
        ...(historyId && isHistory && { historyId }),
      }),
    {
      retry: 0,
      enabled: !!recorderId,
      refetchOnWindowFocus: false,
      onSuccess: (res: AlertHistoryTraceResponse) => {
        // console.log(res, "alert trace list");
        if (res.error !== 0 && res.result === undefined) {
          setTraceList([]);
        } else {
          setTraceList(res.result);
        }
      },
      onError: (err: any) => {
        setTraceList([]);

        if (!isMap) {
          notify(
            "error",
            intl.formatMessage({
              id: "label.alert.notify.list.error",
              defaultMessage: "Error while searching for alert",
            })
          );
        }
      },
    }
  );

  const mutationAlertStatusAllClose = useMutation(putAlertStatusAllClose, {
    onSuccess: () => {
      if (isModal) {
        onModal && onModal();
      } else {
        navigate(-1);
      }
      notify(
        "success",
        intl.formatMessage({
          id: "label.alert.notify.allClose.success",
          defaultMessage: "All statuses were successfully closed",
        })
      );
      // queryClient.invalidateQueries("alertViewList");
      // queryClient.invalidateQueries("alertTraceList");
      queryClient.invalidateQueries("activeAlert");
      queryClient.invalidateQueries("alertHistoryList");
      queryClient.invalidateQueries("searchMapFilterResult");
      SetIsConfirmStatus(false);
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.alert.notify.allClose.fail",
          defaultMessage: "Failed to close all statuses.",
        })
      );
    },
  });

  const onCloseAllStatus = () => {
    SetIsConfirmStatus(true);
  };

  const onChangeCurrentAlert = useCallback(
    (alertType: AlertType, value?: string) => {
      // value가 들어올 때는 VideoLoss 나 Disk Alert 일 때 선택한 cameralist / storagelist 아이템 선택했을 떄임
      let type = alertType.type;

      const findItem: AlertList | undefined = originList.find(
        (origin) => origin.type === type && origin.alertHistoryId === value
      );
      // console.log(value, alertType, originList);

      if (value && findItem) {
        setCurrentAlert(findItem);
      } else {
        setCurrentAlert(originList.filter((origin) => origin.type === type)[0]);
      }
    },
    [currentAlert, originList, checkNoConfirm]
  );

  return (
    <AlertViewPresenter
      alertInfo={alertInfo}
      currentAlert={currentAlert}
      alertTypeList={alertTypeList}
      traceList={traceList}
      count={count}
      isFetching={
        alertViewListQuery.isFetching || alertTraceListQuery.isFetching
      }
      onCloseAllStatus={onCloseAllStatus}
      isConfirmStatus={isConfirmStatus}
      onConfirmCancel={onConfirmCancel}
      onConfirmOk={onConfirmOk}
      onChangeCurrentAlert={onChangeCurrentAlert}
      onCheckNoConfirm={onCheckNoConfirm}
      checkNoConfirm={checkNoConfirm}
      onModal={onModal}
      isModal={isModal}
      historyId={historyId}
    />
  );
};

export default AlertViewContainer;
