import BigButton from "components/atoms/buttons/BigButton";
import TableButton from "components/atoms/buttons/TableButton";
import {
  CenterlineText,
  Text,
  UnderlineText,
} from "components/atoms/text/Text";
import PaginationComponent from "components/blocks/pagination";
import { COLORS } from "styles/colors";
import * as s from "./AboutStyled";
import MYDW_ICON from "assets/icons/mydw-logo.png";
import * as mediaQuery from "components/MediaQuery";
import {
  BasePagination,
  LEVEL_TYPE,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getLicenseList, getVersion } from "api/helpAPI";
import {
  GetLicenseListResponse,
  GetVersionResponse,
  LicenseItem,
} from "api/interfaces/helpInterface.interface";
import BigPopupTemplate from "components/templates/modal/big/BigPopupTemplate";
import TermOfCondition from "./TermOfCondition";
import { IssueReport } from "./IssueReport";
import { useAppSelector } from "redux/hooks";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";

type Props = {};

export default function About(props: Props): JSX.Element {
  const [version, setVersion] = useState<string>("");
  const [licenseData, setLicenseData] = useState<LicenseItem[]>([]);

  const account: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const [licenseQueryInfo, setLicenseQueryInfo] = useState<BasePagination>({
    total: 0,
    pageNum: 0,
    pageLimit: 10,
    keyword: "",
    sortType: "name",
    sortDirection: SORT_DIRECTION.ASC,
  });

  const versionQuery = useQuery(["getVersion"], () => getVersion(), {
    retry: 0,
    refetchOnWindowFocus: false,
    onSuccess: (res: GetVersionResponse) => {
      if (res.error !== 0 || res.result === undefined) {
        setVersion("");
        return;
      }
      setVersion(res.result);
    },
    onError: (e: any) => {
      setVersion("");
    },
  });

  const { data, error, refetch } = useQuery(
    ["licenseList", licenseQueryInfo],
    () =>
      getLicenseList({
        payload: licenseQueryInfo,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: GetLicenseListResponse) => {
        if (res.error !== 0 || res.result === undefined) {
          setLicenseData([]);
          setLicenseQueryInfo((info) => {
            return { ...info, total: 0 as number, pageNum: 0 };
          });
          return;
        }
        setLicenseData(res.result);
        setLicenseQueryInfo((info) => {
          return { ...info, total: res.page.total as number };
        });
      },
      onError: (e: any) => {
        setLicenseData([]);
        setLicenseQueryInfo((info) => {
          return { ...info, total: 0 as number, pageNum: 0 };
        });
      },
    }
  );

  const onChangePage = useCallback(
    (page: number, totalRows: number) => {
      if (page !== licenseQueryInfo.pageNum + 1) {
        setLicenseQueryInfo((info) => {
          return { ...info, pageNum: (page - 1) as number };
        });
      }
    },
    [licenseQueryInfo.pageNum]
  );

  const [visibleTC, setVisibleTC] = useState<boolean>(false);
  const [issue, setIssue] = useState<boolean>(false);

  function onModelIssue(): void {
    setIssue(false);
  }

  return (
    <>
      <s.Container>
        <s.Section>
          <s.Inner>
            <s.Title>About myDW</s.Title>

            <s.Info>
              <img src={MYDW_ICON} alt={"about_dw_logo"} />
              <s.VersionInfo>
                <Text fontSize={24} color={COLORS.BUTTONS1}>
                  Version [ v{process.env.REACT_APP_VERSION} ]
                </Text>
                {/* <Text fontSize={24} color={COLORS.BUTTONS1}>
                  Server Version [ {version} ]
                </Text> */}
              </s.VersionInfo>
              {account.accountLevel !== LEVEL_TYPE.DW && (
                <BigButton
                  buttonType="cancel"
                  label="Report An Issue"
                  onClickBtn={() => setIssue(true)}
                />
              )}
            </s.Info>

            <CenterlineText center color={COLORS.BUTTONS1} fontSize={20}>
              myDW
            </CenterlineText>
            <s.Copyright>
              Copyright 2022 Digital Watchdog Inc. All rights reserved.
              <br />
              <br />
              myDW is made possible by the contributions of various open source
              software developers.
              <br />
              <br />
              <button>
                <Text
                  color={COLORS.BUTTONS2}
                  fontSize={18}
                  onClick={(e) => setVisibleTC(true)}
                >
                  Terms of Service
                </Text>
              </button>
            </s.Copyright>
          </s.Inner>
        </s.Section>
        <s.Section>
          <s.Inner>
            <s.Title>Open Source Software Used</s.Title>
            <s.ListWrapper>
              {licenseData.map(
                (item, index) =>
                  index <= 9 && (
                    <s.ListItem key={index}>
                      <Text>{item.name}</Text>
                      <Text>{item.version}</Text>
                      <Text>{item.moduleLicense}</Text>
                      <s.ButtonWrapper>
                        <mediaQuery.Default>
                          {/* <TableButton
                          label="Show License"
                          onClick={() => console.log("show license")}
                        /> */}
                          <TableButton
                            label="Homepage"
                            onClick={() =>
                              item.moduleUrl !== undefined
                                ? window.open(
                                    item.moduleUrl.replaceAll("git+", ""),
                                    "_blank"
                                  )
                                : ""
                            }
                          />
                        </mediaQuery.Default>
                        <mediaQuery.Mobile>
                          {/* <UnderlineText color={COLORS.SELECTED}>
                          Show License
                        </UnderlineText> */}
                          <UnderlineText
                            onClick={() =>
                              item.moduleUrl !== undefined
                                ? window.open(
                                    item.moduleUrl.replaceAll("git+", ""),
                                    "_blank"
                                  )
                                : ""
                            }
                          >
                            Homepage
                          </UnderlineText>
                        </mediaQuery.Mobile>
                      </s.ButtonWrapper>
                    </s.ListItem>
                  )
              )}
            </s.ListWrapper>
            <s.PageWrapper>
              <PaginationComponent
                rowsPerPage={licenseQueryInfo.pageLimit}
                rowCount={licenseQueryInfo.total}
                currentPage={licenseQueryInfo.pageNum}
                onChangePage={onChangePage}
              />
            </s.PageWrapper>
          </s.Inner>
        </s.Section>
      </s.Container>
      {issue && <IssueReport onModal={onModelIssue} />}
      {visibleTC && (
        <BigPopupTemplate
          title="Term of Service"
          onModal={() => setVisibleTC(false)}
        >
          <TermOfCondition />
        </BigPopupTemplate>
      )}
    </>
  );
}
