import { useCallback, useEffect, useMemo, useState } from "react";
import * as s from "../AlertViewStyled";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import {
  ActiveAlert,
  AlertList,
  AlertStatusResponse,
  AlertTraceList,
  AlertType,
  AlertViewInfo,
} from "api/interfaces/alertInterface.interface";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { BsChevronCompactRight, BsChevronCompactLeft } from "react-icons/bs";
import { COLORS } from "styles/colors";
import { useLocation } from "react-router-dom";
import { useMutation } from "react-query";
import { putAlertStatusClose } from "api/alertAPI";
import { notify } from "components/atoms/notification/Notification";
import { useIntl } from "react-intl";
import AlertChangeConfirm from "../../../../components/templates/modal/small/alert/AlertChangeConfirm";
import { useQueryClient } from "react-query";
import AlertTypeItem from "./AlertTypeItem";
import useApiError from "hook/useApiError";
import { CustomError } from "api/interfaces/commonInterface.interface";
import { filteringDiskAlertBoolean } from "utils/AlertUtil";

interface Props {
  alertTypeList: AlertType[];
  currentAlert: AlertList;
  alertInfo: AlertViewInfo;
  onChangeCurrentAlert: (alertType: AlertType, value?: string) => void;
  onCheckNoConfirm: (value: boolean) => void;
  checkNoConfirm: boolean;
  onModal?: () => void;
}

const mapResponsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 5000, min: 2000 },
    items: 2,
  },
  desktop: {
    breakpoint: { max: 2000, min: 1024 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 5000, min: 2000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 2000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const AlertTypeList = (props: Props) => {
  const intl = useIntl();
  const location = useLocation();
  const { handleError } = useApiError();

  const queryClient = useQueryClient();

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const alertListStyled = s.AlertTypeListStyled;

  const [isConfirmStatus, setIsConfirmStatus] = useState<boolean>(false);

  const CustomRightArrow = ({ onClick, ...rest }: any) => {
    const {
      onMove,
      carouselState: { currentSlide, deviceType },
    } = rest;
    // onMove means if dragging or swiping in progress.
    return (
      <alertListStyled.ScrollButton onClick={onClick}>
        <BsChevronCompactRight size={30} color={COLORS.WHITE} />
      </alertListStyled.ScrollButton>
    );
  };

  const CustomLeftArrow = ({ onClick, ...rest }: any) => {
    const {
      onMove,
      carouselState: { currentSlide, deviceType },
    } = rest;
    // onMove means if dragging or swiping in progress.
    return (
      <alertListStyled.ScrollButton onClick={onClick} className="left-button">
        <BsChevronCompactLeft size={30} color={COLORS.WHITE} />
      </alertListStyled.ScrollButton>
    );
  };

  const mutationAlertStatusClose = useMutation(putAlertStatusClose, {
    onSuccess: (res: AlertStatusResponse) => {
      // alerttypelist 다시 불러오기
      queryClient.invalidateQueries("activeAlert");
      queryClient.invalidateQueries("alertHistoryList");
      queryClient.invalidateQueries("alertViewList");
      queryClient.invalidateQueries("searchMapFilterResult");

      notify(
        "success",
        intl.formatMessage({
          id: "label.alert.notify.status.success",
          defaultMessage: "Status Closed successfully",
        })
      );

      const isVideoLoss = props.currentAlert.type === "Video Loss";
      const isDiskAlert = filteringDiskAlertBoolean(props.currentAlert);

      const cameraList = props.alertTypeList[0].cameraList;
      const storageList = props.alertTypeList[0].storageList;

      // alertType 이 한개일때
      if (props.alertTypeList.length === 1) {
        /**
         * type Video Loss or Disk Alert 일 때는
         * cameraList 나 storageList 가 여러 개일 수 있기 떄문에
         * 예외처리해야함
         */
        if (isVideoLoss || isDiskAlert) {
          if (
            /**
             * videoloss 의 cameralist 나 diskAlert 의 storageList 가 1개 이상일 경우에
             *
             *  */
            (cameraList && cameraList.length > 1) ||
            (storageList && storageList.length > 1)
          ) {
            queryClient.invalidateQueries("alertTraceList");

            // cameraList 나 storageList 없을 경우 modal 닫기
            if ((isVideoLoss && !cameraList) || (isDiskAlert && !storageList)) {
              props.onModal && props.onModal();
            }
          } else {
            props.onModal && props.onModal();
          }
        } else {
          props.onModal && props.onModal();
        }
      } else {
        queryClient.invalidateQueries("alertTraceList");
      }
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.alert.notify.status.fail",
          defaultMessage: "Failed to close status.",
        })
      );
    },
  });

  const onMutateAlertStatusClose = () => {
    // console.log("onMutateAlertStatusClose", props.currentAlert);
    mutationAlertStatusClose.mutate({
      accountId: selectedAccount.accountId,
      recorderId: props.alertInfo.recorderId,
      historyId: props.currentAlert.alertHistoryId,
    });
  };

  const onConfirmCancel = () => {
    setIsConfirmStatus(false);
  };

  const onConfirmOk = (checked: boolean) => {
    if (checked) {
      props.onCheckNoConfirm(true);
    }

    setIsConfirmStatus(false);
    onMutateAlertStatusClose();
  };
  const onChangeStatus = useCallback(
    (value: string) => {
      if (value !== "Closed") {
        return;
      } else {
        if (!props.checkNoConfirm) {
          setIsConfirmStatus(true);
        } else {
          onMutateAlertStatusClose();
        }
      }
    },
    [isConfirmStatus, props.checkNoConfirm]
  );

  const isMap = location.pathname.includes("map");

  return (
    <>
      <alertListStyled.Wrapper>
        <Carousel
          responsive={isMap ? mapResponsive : responsive}
          customRightArrow={<CustomRightArrow />}
          customLeftArrow={<CustomLeftArrow />}
          itemClass={"alert-item"}
        >
          {props.alertTypeList.map((alertType: AlertType, index: number) => {
            return (
              <AlertTypeItem
                key={index}
                alertType={alertType}
                currentAlert={props.currentAlert}
                onChangeCurrentAlert={props.onChangeCurrentAlert}
                onChangeStatus={onChangeStatus}
              />
            );
          })}
        </Carousel>
      </alertListStyled.Wrapper>
      {isConfirmStatus && !props.checkNoConfirm && (
        <AlertChangeConfirm
          onConfirmCancel={onConfirmCancel}
          onConfirmOk={onConfirmOk}
          alertInfo={props.alertInfo}
          currentAlert={props.currentAlert}
        />
      )}
    </>
  );
};

export default AlertTypeList;
