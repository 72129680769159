import { GoAlertFill } from "react-icons/go";
import styled from "styled-components";
import { COLORS } from "styles/colors";

export const ErrorMessage = styled.p`
  max-width: 500px;
  line-height: 1.4;
  color: ${({ theme }) => theme.COLORS.TEXT};
  white-space: pre-line ;
`;

export const titleIcon = (
  <GoAlertFill fill={COLORS.RED} style={{verticalAlign: "text-top", marginRight: "8px"}} />
);
