import { TableTemplate } from "components/templates/default/table/TableTemplate";
import AddModal from "./add";
import ViewModal from "./view";
import ModalSubMenu, { Menus } from "components/blocks/modal/menu/ModalSubMenu";
import { AddEmail, Email } from "api/interfaces/emailInterface.interface";
import { useLocation, useNavigate } from "react-router-dom";
import EmailRecipient from "./recipients/EmailRecipient";
import { BasePagination } from "api/interfaces/commonInterface.interface";
import Status, { StatusType } from "components/blocks/status/Status";
import { ClickSearch } from "components/atoms/input/search/SearchInput";
import { TableColumnsType } from "antd";
import { CommonTable } from "components/atoms/table/AntdTable";

export type Props = {
  isAddModal: boolean;
  isEditModal: boolean;
  isViewModal: boolean;
  onModal: (type?: string) => void;
  columns: TableColumnsType<Email>;
  scheduledColumns: TableColumnsType<Email>;
  data: Array<Email>;
  currentMenu: number;
  emailMenus: Array<Menus>;
  selectedEmail: Email | undefined;
  onChangeCurrentMenu: (index: number) => void;
  onClickSendEmail: (email: AddEmail, emailId?: string) => void;
  onRowClicked: (row: Email) => void;
  onChangePage: (page: number, totalRows: number) => void;
  paginationInfo: BasePagination;
  status: StatusType;
  onClickSearch: ClickSearch;
  tablePending: boolean;
};

export function EmailPresenter({
  isAddModal,
  isEditModal,
  isViewModal,
  onModal,
  columns,
  scheduledColumns,
  data,
  emailMenus,
  currentMenu,
  onChangeCurrentMenu,
  onClickSendEmail,
  selectedEmail,
  onRowClicked,
  onChangePage,
  paginationInfo,
  status,
  onClickSearch,
  tablePending,
}: Props): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const isRecipient = location.pathname.includes("/recipients");

  const titleComponent = isRecipient ? (
    [
      {
        label: "Sent Emails",
        onClick: () => navigate(-1),
      },
      {
        label: selectedEmail?.subject,
      },
    ]
  ) : (
    <ModalSubMenu
      menus={emailMenus}
      currentMenu={currentMenu}
      onChange={onChangeCurrentMenu}
      subTitle={true}
    />
  );

  return (
    <>
      {!isRecipient ? (
        <TableTemplate
          onClickBtn={() => onModal("add")}
          buttonLabel="New Email"
          onClickSearch={onClickSearch}
          keyword={paginationInfo.keyword}
          titleComponent={titleComponent}
          centerChildren={<Status status={status} />}
        >
          {currentMenu === 0 && (
            <CommonTable
              columns={columns}
              dataSource={data}
              queryInfo={paginationInfo}
              onChangePage={onChangePage}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    onRowClicked(record);
                  },
                };
              }}
              loading={tablePending}
            />
          )}

          {currentMenu === 1 && (
            <CommonTable
              columns={scheduledColumns}
              dataSource={data}
              queryInfo={paginationInfo}
              onChangePage={onChangePage}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    onRowClicked(record);
                  },
                };
              }}
              loading={tablePending}
            />
          )}
        </TableTemplate>
      ) : (
        <EmailRecipient titleComponent={titleComponent} />
      )}
      {isAddModal && (
        <AddModal
          onModal={() => onModal("add")}
          onClickSendEmail={onClickSendEmail}
        />
      )}
      {isEditModal && selectedEmail !== undefined && (
        <AddModal
          isEdit
          onModal={() => onModal("edit")}
          onClickSendEmail={onClickSendEmail}
          selectedEmail={selectedEmail}
        />
      )}
      {isViewModal && selectedEmail !== undefined && (
        <ViewModal
          onModal={() => onModal("view")}
          selectedEmail={selectedEmail}
        />
      )}
    </>
  );
}
