import * as s from "../RecorderListInfoItemStyled";
import { COLORS } from "styles/colors";
import { DashboardData } from "api/interfaces/dashboardInterface.interface";
import RecorderInfoView from "../RecorderInfoView";
import Popover from "antd/lib/popover";
import WebAppLink from "components/blocks/link/WebAppLink";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import { ChildrenRecorder } from "../ChildrenRecorder";
import { getMarker, getTopAlertStatusString } from "../../../utils";

type ItemMultiRecorderType = {
  system: DashboardData;
  onSelectRecorder: (system: DashboardData) => void;
};

export const ItemMultiRecorder = ({
  system,
  onSelectRecorder,
}: ItemMultiRecorderType) => {
  const theme = useAppSelector((state) => state.theme.theme);
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const tmpStatus = getTopAlertStatusString(system);

  // recorder 개수 여러개일때
  const isMultiRecorders = system.recorders.length >= 1;

  return (
    <s.Item
      key={`recorder-list-map-view-${system.recorderId}`}
      isMultiRecorders={isMultiRecorders}
    >
      <div className="left-info">
        <div className="system-alert-marker">
          <img src={getMarker(tmpStatus)} alt={`marker-${tmpStatus}`} />
        </div>

        <div className="system-info">
          <div className="system-title">
            <h5>{system.mergedSystemName}</h5>
            <div className="web-app-link-wrapper">
              <WebAppLink {...system} accountId={selectedAccount.accountId} />
            </div>
          </div>

          {system.type === "Spectrum" && system.recorders && (
            <ul className="spectrum-system-ul">
              {system.recorders.map((rec: DashboardData, index: number) => {
                return (
                  <Popover
                    key={`popover-${index}`}
                    trigger="hover"
                    placement="right"
                    content={<RecorderInfoView system={rec} />}
                    zIndex={980}
                    style={{
                      padding: 0,
                    }}
                    color={theme === "light" ? COLORS.WHITE : "#0d1119"}
                  >
                    <ChildrenRecorder
                      rec={rec}
                      onSelectRecorder={onSelectRecorder}
                    />
                  </Popover>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    </s.Item>
  );
};
