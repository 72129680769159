import React, { Fragment, useState } from "react";
import * as s from "../BillingPaymentStyled";
import {
  BillingAccount,
  BillingDetail,
  BillingLicenses,
} from "api/interfaces/licenseInterface.interface";
import { useQuery } from "react-query";
import { getLicenseBillingDetail } from "api/licenseAPI";
import { Date } from "../BillingPaymentContainer";
import { useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import { notify } from "components/atoms/notification/Notification";
import { PaymentTable } from "components/atoms/table/AntdBillingTable";
import { TableColumnsType } from "antd";

export type Props = {
  date: Date;
  sortBy: number;
};

export default function PaymentDetailTable(props: Props): JSX.Element {
  const intl = useIntl();
  const location = useLocation();
  const accountId = location.state.accountId;
  const detailAccountId = location.state.detailAccountId;
  // console.log(location);
  const [detailData, setDetailData] = useState<BillingDetail[]>([]);
  const columns = React.useMemo<TableColumnsType<BillingDetail>>(
    () => [
      {
        dataIndex: "partNumber",
        title: "PART NUMBER",
        render: (value) => value,
      },
      {
        dataIndex: "description",
        title: "DESCRIPTION",
        render: (value) => value,
      },
      {
        dataIndex: "qty",
        title: "QTY",
        render: (value) => value,
      },
    ],
    []
  );
  const getBillingDetail = useQuery(
    ["billingDetail", accountId, props.date, detailAccountId],
    () => {
      return getLicenseBillingDetail({
        accountId: accountId,
        payload: {
          year: props.date.year,
          month: props.date.month,
          detailAccountId: detailAccountId,
        },
      });
    },
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res.result) {
          var tempArr = [];
          if (Object.keys(res.result).length !== 0) {
            // licenses
            if (props.sortBy === 0) {
              tempArr = res.result.licenses.map((license: BillingLicenses) => {
                return {
                  partNumber: license.partNumber,
                  description: license.description,
                  qty: license.totalQty,
                };
              });
            }
            // account
            if (props.sortBy === 1) {
              tempArr = res.result.accounts
                .filter(
                  (account: BillingAccount) =>
                    account.accountId === detailAccountId
                )[0]
                .licenses.map((license: BillingLicenses) => {
                  return {
                    partNumber: license.partNumber,
                    description: license.description,
                    qty: license.totalQty,
                  };
                });
            }
          }

          setDetailData(tempArr);
        }
      },
      onError: (e: any) => {
        notify(
          "error",
          intl.formatMessage({
            id: "label.billing.notify.search.billing.fail",
            defaultMessage: "Billing detail search error",
          })
        );
      },
    }
  );

  return (
    <s.TableTitleBox>
      <s.TableWrapper>
        <PaymentTable columns={columns} dataSource={detailData} />
      </s.TableWrapper>
    </s.TableTitleBox>
  );
}
