import { useCallback, useState, useEffect } from "react";
import TableButton from "components/atoms/buttons/TableButton";
import { useQueryClient, useQuery } from "react-query";
import { notify } from "components/atoms/notification/Notification";
import { useAppSelector } from "redux/hooks";
import {
  LEVEL_TYPE,
  ProfileAccountInfo,
} from "api/interfaces/accountInterface.interface";
import {
  GetAlertActiveListResponse,
  ActiveAlert,
  AlertActiveSummary,
} from "api/interfaces/alertInterface.interface";
import {
  SORT_DIRECTION,
  BasePagination,
} from "api/interfaces/commonInterface.interface";
import { getAlertActiveList } from "api/alertAPI";
import {
  Location,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import { getRecorderTimeFormat } from "utils/timeUtil";
import { useAuth } from "components/Auth";
import { useIntl } from "react-intl";
import { isCheckPermission } from "utils/AccountUtil";
import TableColumnFilterTitle from "components/blocks/table/TableColumnFilterTitle";
import {
  ActiveAlertStatusTypeFilter,
  AlertCategoryTypeFilter,
  displayAlert,
  navigateAlertDetailPage,
} from "utils/AlertUtil";
import * as s from "../AlertStyled";
import RecorderTitle from "components/blocks/recorder/RecorderTitle";
import { isNull } from "lodash";
import { queryNavigate } from "utils/MenuUtil";
import AccountId from "components/atoms/text/labels/AccountId";
import { TableColumnsType } from "antd";
import { SortOrder } from "antd/es/table/interface";
import {
  changeSortOrderAntd,
  changeSortOrderString,
  CommonTable,
  onSortAntd,
} from "components/atoms/table/AntdTable";
import * as mediaQuery from "components/MediaQuery";
import { ColumnsType, ColumnType } from "antd/lib/table";
import { InitialAlertSummary } from "../AlertPage";

const initialQueryInfo = {
  total: 0,
  pageNum: 0,
  pageLimit: 20,
  keyword: "",
  sortType: "alert_trigger",
  type: null,
  status: null,
  sortDirection: SORT_DIRECTION.DESC,
};

export default function AlertActive(): JSX.Element {
  const navigate = useNavigate();
  const intl = useIntl();
  const auth = useAuth();
  const location: Location = useLocation();
  const params = new URLSearchParams(location.search);

  const {
    totalCount,
    setTotalCount,
    pending,
    setPending,
    onChangeStatusAlertSummary,
  } = useOutletContext<any>();

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const queryClient = useQueryClient();

  const [alertList, setAlertList] = useState<ActiveAlert[]>([]);

  const [alertActiveQueryInfo, setAlertActiveQueryInfo] =
    useState<BasePagination>({
      ...initialQueryInfo,
    });

  useEffect(() => {
    const changeParam = new URLSearchParams(location.search);
    if (changeParam.size > 0) {
      setAlertActiveQueryInfo((info) => {
        return {
          ...info,
          pageNum: isNull(changeParam.get("pageNum"))
            ? 0
            : Number(changeParam.get("pageNum")),
          pageLimit: isNull(changeParam.get("pageLimit"))
            ? 20
            : Number(changeParam.get("pageLimit")),
          keyword: isNull(changeParam.get("keyword"))
            ? ""
            : (changeParam.get("keyword") as string),
          sortType: isNull(changeParam.get("sortType"))
            ? "alert_trigger"
            : (changeParam.get("sortType") as string),
          sortDirection: isNull(changeParam.get("sortDirection"))
            ? SORT_DIRECTION.DESC
            : (changeParam.get("sortDirection") as SORT_DIRECTION),
          type: isNull(changeParam.get("type"))
            ? null
            : (changeParam.get("type") as string),
          status: isNull(changeParam.get("status"))
            ? null
            : (changeParam.get("status") as string),
        };
      });
    } else {
      setAlertActiveQueryInfo((info) => {
        return {
          ...initialQueryInfo,
        };
      });
    }
  }, [location]);

  const [isRowClickEditable, setIsRowClickEditable] = useState<boolean>(
    isCheckPermission("manageAlertRules", selectedAccount)
  );
  useEffect(() => {
    if (selectedAccount) {
      setIsRowClickEditable(
        isCheckPermission("manageAlertRules", selectedAccount)
      );
    }
  }, [selectedAccount]);

  const onClickAlertCategoryType = useCallback(
    (value: string | undefined) => {
      if (value !== undefined) {
        if (value === "all") {
          if (alertActiveQueryInfo.type !== undefined) {
            queryNavigate(
              navigate,
              location.pathname,
              {
                pageLimit: alertActiveQueryInfo.pageLimit,
                pageNum: 0,
                keyword: alertActiveQueryInfo.keyword as string,
                sortType: alertActiveQueryInfo.sortType,
                sortDirection:
                  alertActiveQueryInfo.sortDirection as SORT_DIRECTION,
                type: null,
                status: alertActiveQueryInfo.status,
              },
              selectedAccount
            );
          } else {
            refetch();
          }
          // setAlertActiveQueryInfo((info) => {
          //   return { ...info, type: null, pageNum: 0 };
          // });
        } else {
          if (alertActiveQueryInfo.type !== value) {
            queryNavigate(
              navigate,
              location.pathname,
              {
                pageLimit: alertActiveQueryInfo.pageLimit,
                pageNum: 0,
                keyword: alertActiveQueryInfo.keyword as string,
                sortType: alertActiveQueryInfo.sortType,
                sortDirection:
                  alertActiveQueryInfo.sortDirection as SORT_DIRECTION,
                type: value,
                status: alertActiveQueryInfo.status,
              },
              selectedAccount
            );
          } else {
            refetch();
          }
          // setAlertActiveQueryInfo((info) => {
          //   return { ...info, type: value as string, pageNum: 0 };
          // });
        }
      }
    },
    [
      alertActiveQueryInfo.keyword,
      alertActiveQueryInfo.pageLimit,
      alertActiveQueryInfo.sortDirection,
      alertActiveQueryInfo.sortType,
      alertActiveQueryInfo.status,
      alertActiveQueryInfo.type,
      location.pathname,
      navigate,
      selectedAccount,
    ]
  );
  const onHeaderCell = (column: ColumnType<ActiveAlert>) => {
    return {
      onClick: () => {
        if (column.key) {
          setAlertActiveQueryInfo((info) => {
            return {
              ...info,
              sortType: column.key as string,
              sortDirection: onSortAntd(info.sortDirection),
            };
          });
        }
      },
    };
  };
  const onClickAlertStatusType = useCallback(
    (value: string | undefined) => {
      if (value !== undefined) {
        if (value === "all") {
          if (alertActiveQueryInfo.status !== null) {
            queryNavigate(
              navigate,
              location.pathname,
              {
                pageLimit: alertActiveQueryInfo.pageLimit,
                pageNum: 0,
                keyword: alertActiveQueryInfo.keyword as string,
                sortType: alertActiveQueryInfo.sortType,
                sortDirection:
                  alertActiveQueryInfo.sortDirection as SORT_DIRECTION,
                status: null,
                type: alertActiveQueryInfo.type,
              },
              selectedAccount
            );
          } else {
            refetch();
          }
          // setAlertActiveQueryInfo((info) => {
          //   return { ...info, status: null, pageNum: 0 };
          // });
        } else {
          if (alertActiveQueryInfo.status !== value) {
            queryNavigate(
              navigate,
              location.pathname,
              {
                pageLimit: alertActiveQueryInfo.pageLimit,
                pageNum: 0,
                keyword: alertActiveQueryInfo.keyword as string,
                sortType: alertActiveQueryInfo.sortType,
                sortDirection:
                  alertActiveQueryInfo.sortDirection as SORT_DIRECTION,
                status: value,
                type: alertActiveQueryInfo.type,
              },
              selectedAccount
            );
          } else {
            refetch();
          }
          // setAlertActiveQueryInfo((info) => {
          //   return { ...info, status: value as string, pageNum: 0 };
          // });
        }
      }
    },
    [
      alertActiveQueryInfo.keyword,
      alertActiveQueryInfo.pageLimit,
      alertActiveQueryInfo.sortDirection,
      alertActiveQueryInfo.sortType,
      alertActiveQueryInfo.status,
      alertActiveQueryInfo.type,
      location.pathname,
      navigate,
      selectedAccount,
    ]
  );
  const columns: TableColumnsType<ActiveAlert> =
    selectedAccount.accountLevel !== "EU"
      ? [
          {
            title: (
              <TableColumnFilterTitle
                title="Category"
                filters={AlertCategoryTypeFilter}
                onClick={onClickAlertCategoryType}
              />
            ),
            render: (value, row) => row.type,
            width: 150,
          },
          {
            title: "Organization",
            // grow: 1.5,
            render: (value, row) => (
              <s.AccountBox>
                {row.accountName}
                <AccountId
                  accountId={row.accountNumber}
                  level={LEVEL_TYPE.EU}
                />
              </s.AccountBox>
            ),
          },
          {
            title: "Recorder",
            // grow: 1.5,
            render: (value, row) => (
              <RecorderTitle
                mergedSystemName={row.mergedSystemName}
                recorderName={row.recorderName}
              />
              // <s.AccountBox>
              //   {row.mergedSystemName}
              // </s.AccountBox>
            ),
          },
          {
            title: (
              <TableColumnFilterTitle
                title="Status"
                filters={ActiveAlertStatusTypeFilter}
                onClick={onClickAlertStatusType}
              />
            ),
            // grow: 1,
            render: (value, row) => (
              <s.StatusTxt string={row.status.toUpperCase()}>
                {row.status}
              </s.StatusTxt>
            ),
          },
          {
            title: "Alert",
            // grow: 2,
            render: (value, row) => displayAlert(row),
          },
          {
            title: "Date",
            dataIndex: "alertTrigger",
            key: "alert_trigger",
            sorter: true,
            sortOrder: changeSortOrderAntd(
              alertActiveQueryInfo,
              "alert_trigger"
            ),
            defaultSortOrder: "descend",
            onHeaderCell: onHeaderCell,
            render: (value, row) =>
              row.alertTrigger !== undefined
                ? getRecorderTimeFormat(
                    row.alertTrigger,
                    selectedAccount,
                    row.timezone,
                    true
                  )
                : "",
          },
          {
            title: "Assignee",
            // grow: 1,
            render: (value, row) => row.assignee,
          },
          {
            title: "",
            // width: "175px",
            // grow: 2,
            render: (value, row) => (
              <s.Row className="table-button-wrapper">
                {/* <span className="row-click-button"> */}
                <TableButton
                  buttonType="view"
                  label="View Alerts"
                  onClick={() => onRowClick(row)}
                  // disabled={
                  //   row.systemCount + row.diskCount + row.videoCount === 0
                  //     ? true
                  //     : false
                  // }
                  // onClick={() => onModal("view")}
                />
                {/* </span> */}
              </s.Row>
            ),
            align: "right",
          },
        ]
      : [
          {
            title: (
              <TableColumnFilterTitle
                title="Category"
                filters={AlertCategoryTypeFilter}
                onClick={onClickAlertCategoryType}
              />
            ),
            render: (value, row) => row.type,
            width: 120,
          },
          {
            title: "Recorder",
            render: (value, row) => (
              <RecorderTitle
                mergedSystemName={row.mergedSystemName}
                recorderName={row.recorderName}
              />
            ),
          },
          {
            title: (
              <TableColumnFilterTitle
                title="Status"
                filters={ActiveAlertStatusTypeFilter}
                onClick={onClickAlertStatusType}
              />
            ),
            render: (value, row) => (
              <s.StatusTxt string={row.status.toUpperCase()}>
                {row.status}
              </s.StatusTxt>
            ),
            width: 120,
          },
          {
            title: "Alert",
            render: (value, row) => displayAlert(row),
          },
          {
            dataIndex: "alertTrigger",
            title: "Date",
            key: "alert_trigger",
            render: (value, row) =>
              row.alertTrigger !== undefined
                ? getRecorderTimeFormat(
                    row.alertTrigger,
                    selectedAccount,
                    row.timezone,
                    true
                  )
                : "",
            width: 200,
            responsive: ["lg"],
            sorter: true,
            sortOrder: changeSortOrderAntd(
              alertActiveQueryInfo,
              "alert_trigger"
            ),
            defaultSortOrder: "descend",
            onHeaderCell: onHeaderCell,
          },
          {
            title: "Assignee",
            render: (row) => row.assignee,
            responsive: ["xl"],
            width: 150,
          },
          {
            title: "",
            render: (row) => (
              <s.Row className="table-button-wrapper">
                <TableButton
                  buttonType="view"
                  label="View Alerts"
                  onClick={() => onRowClick(row)}
                />
              </s.Row>
            ),
            align: "right",
            width: 120,
          },
        ];

  const onRowClick = (row: ActiveAlert) => {
    navigateAlertDetailPage({
      // location,
      navigate,
      recorderId: row.recorderId,
      historyId: row.alertHistoryId,
      alertType: row.type,
    });
  };

  const { error, refetch } = useQuery(
    ["activeAlert", alertActiveQueryInfo],
    () =>
      getAlertActiveList({
        accountId: selectedAccount.accountId,
        payload: alertActiveQueryInfo,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: GetAlertActiveListResponse) => {
        if (res.error !== 0 && res.result?.alerts === undefined) {
          setAlertList([]);
          setTotalCount(0);
          setAlertActiveQueryInfo((info) => {
            return {
              ...initialQueryInfo,
            };
          });
          onChangeStatusAlertSummary(InitialAlertSummary);
        } else {
          onChangeStatusAlertSummary(res.result as AlertActiveSummary);
          setAlertList(res.result.alerts);
          setTotalCount(res.page.total);
          setAlertActiveQueryInfo((info) => {
            return {
              ...info,
              ...res.page,
            };
          });
        }
      },
      onError: (err: any) => {
        setAlertList([]);
        setTotalCount(0);
        setAlertActiveQueryInfo((info) => {
          return {
            ...initialQueryInfo,
          };
        });
        onChangeStatusAlertSummary(InitialAlertSummary);
        notify(
          "error",
          intl.formatMessage({
            id: "label.alert.notify.list.error",
            defaultMessage: "Error while searching for alert",
          })
        );
      },
      onSettled: (e: any) => {
        setPending(false);
      },
    }
  );

  const onChangePage = useCallback(
    (page: number, totalRows: number) => {
      if (page !== alertActiveQueryInfo.pageNum + 1) {
        queryNavigate(
          navigate,
          location.pathname,
          {
            pageLimit: alertActiveQueryInfo.pageLimit,
            pageNum: page - 1,
            keyword: alertActiveQueryInfo.keyword as string,
            sortType: alertActiveQueryInfo.sortType,
            sortDirection: alertActiveQueryInfo.sortDirection as SORT_DIRECTION,
          },
          selectedAccount
        );
        setPending(true);
      }
    },
    [
      alertActiveQueryInfo.keyword,
      alertActiveQueryInfo.pageLimit,
      alertActiveQueryInfo.pageNum,
      alertActiveQueryInfo.sortDirection,
      alertActiveQueryInfo.sortType,
      location.pathname,
      navigate,
      selectedAccount,
    ]
  );

  return (
    <CommonTable
      columns={columns}
      dataSource={alertList}
      onRow={(record, rowIndex) => {
        return {
          onClick: (event) => {
            onRowClick(record);
          },
        };
      }}
      queryInfo={alertActiveQueryInfo}
      onChangePage={onChangePage}
      loading={pending}
    />
  );
}
