import { TableTemplate } from "components/templates/default/table/TableTemplate";
import { DataRow } from "./ChildrenListContainer";
import AddListModal from "../add/list";
import AddListModal2 from "../edit/list";
import Status, {
  StatusName,
  StatusType,
  StatusValue,
} from "components/blocks/status/Status";
import { FaInfoCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Text } from "components/atoms/text/Text";
import * as s from "../RecordersStyled";
import { COLORS } from "styles/colors";
import { ClickSearch } from "components/atoms/input/search/SearchInput";
import InfoBubble from "components/blocks/bubble/InfoBubble";
import { BubbleContainer } from "pages/license/LicenseStyled";
import { LicenseCount } from "api/interfaces/recorderInterface.interface";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { changeToTrialType } from "utils/AccountUtil";
import * as mediaQuery from "components/MediaQuery";
import WebAppLink from "components/blocks/link/WebAppLink";
import { BasePagination } from "api/interfaces/commonInterface.interface";
import { menuNavigation } from "utils/MenuUtil";
import Deactivate from "pages/users/add/deactivate";
import { DeregisterHelp } from "../DeregisterHelp";
import { CommonTable } from "components/atoms/table/AntdTable";
import { TableColumnsType } from "antd";

type Props = {
  isAddModal: boolean;
  isEditModal: boolean;
  isBubble: boolean;
  licenseCount: LicenseCount;
  setIsBubble: any;
  columns: TableColumnsType<DataRow>;
  onModal: (type: string) => void;
  data: Array<DataRow>;
  status: StatusType;
  selectRow: DataRow;
  paginationInfo: BasePagination;
  onChangePage: (page: number, totalRows: number) => void;
  onClickEdit: (row: DataRow) => void;
  onClickSearch: ClickSearch;
  selectedMergedSystemId: string;
  selectedMergedSystemName: string;
  selectedMergedSystemVersion: string;
  selectedCloudSystemId: string;
  initialPending: boolean;
  selectedAccount: ProfileAccountInfo;
  isDeleteConfirm: boolean;
  onClickDelete: (result: boolean) => void;
  isDeregisterHelp: boolean;
  closeDeregisterHelp: () => void;
};

export function RecordersChildrenListPresenter({
  isAddModal,
  isEditModal,
  isBubble,
  licenseCount,
  setIsBubble,
  onModal,
  columns,
  data,
  status,
  selectRow,
  paginationInfo,
  onChangePage,
  onClickEdit,
  onClickSearch,
  selectedMergedSystemId,
  selectedMergedSystemName,
  selectedMergedSystemVersion,
  selectedCloudSystemId,
  initialPending,
  selectedAccount,
  isDeleteConfirm,
  onClickDelete,
  isDeregisterHelp,
  closeDeregisterHelp,
}: Props): JSX.Element {
  const navigate = useNavigate();

  const centerChildren = (
    <>
      <Status status={status} />{" "}
      <mediaQuery.Default>
        {data.length > 0 && (
          <s.Row>
            <WebAppLink
              {...data[0]}
              accountId={selectedAccount.accountId}
              recorderId={data[0].recordId}
              mergedSystemId={selectedMergedSystemId}
              cloudSystemId={selectedCloudSystemId}
            />
          </s.Row>
        )}
      </mediaQuery.Default>
    </>
  );

  const titleComponent = [
    {
      label: "Recorder List",
      onClick: () =>
        menuNavigation(selectedAccount, navigate, "/recorders/list"),
    },
    {
      label: (
        <s.SubTitleWrapper>
          <Text fontSize={24} id="selected-merged-name">
            {selectedMergedSystemName}
          </Text>
          <mediaQuery.Default>
            <Text fontSize={12} color="#828B9B">
              [Ver.{selectedMergedSystemVersion}]
            </Text>
          </mediaQuery.Default>
        </s.SubTitleWrapper>
      ),
    },
  ];

  const licenseInfo = (
    <s.LicenseInfo>
      {/*
      isTrial === true && traialDays > 0 && license > 0 일경우에는 안보여줌
      trial 기간에 license 구매하면 안보여줌
      */}
      {!(
        selectedAccount.isTrial &&
        selectedAccount.trialDays !== undefined &&
        selectedAccount.trialDays > 0 &&
        licenseCount.totalLicensedCameraCount > 0
      ) &&
        !selectedAccount.isSuspended && (
          <li className="trial-type">
            {changeToTrialType(selectedAccount)}
            {/* trial 일때 trial 기간 보여주기 */}
            {changeToTrialType(selectedAccount) === "Trial" &&
              selectedAccount.trialDays !== undefined &&
              selectedAccount.trialDays > 0 &&
              ` ${selectedAccount.trialDays} day(s)`}
          </li>
        )}

      {selectedAccount.isSuspended && <li className="trial-type">Suspended</li>}

      <s.LicenseInfoItem
        isLicense={selectedAccount.isAdmin}
        onClick={() => {
          if (selectedAccount.isAdmin) {
            menuNavigation(selectedAccount, navigate, "/settings/license");
            // navigate("/settings/license", {
            //   state: { accountId: selectedAccount.accountId },
            // });
            // dispatch(setMenu("Settings"));
          }
        }}
      >
        <StatusName className="license-title">Licensed&nbsp;&nbsp;</StatusName>
        <StatusValue
          color={
            licenseCount.totalLicensedCameraCount <
            licenseCount.totalCameraCount
              ? COLORS.RED
              : COLORS.BUTTONS2
          }
        >
          {licenseCount.totalLicensedCameraCount}
        </StatusValue>
      </s.LicenseInfoItem>
      <s.LicenseInfoItem>/</s.LicenseInfoItem>
      <s.LicenseInfoItem>
        <StatusName className="license-title">Cameras&nbsp;&nbsp;</StatusName>
        <StatusValue>{licenseCount.totalCameraCount}</StatusValue>
      </s.LicenseInfoItem>
      {licenseCount.totalLicensedCameraCount <
        licenseCount.totalCameraCount && (
        <button
          style={{ position: "relative" }}
          onMouseOver={() => setIsBubble(true)}
          onMouseLeave={() => setIsBubble(false)}
        >
          <FaInfoCircle />
          {isBubble && (
            <BubbleContainer right>
              <InfoBubble right>
                You don't have a sufficient number of camera channel licenses.
                <br />
                You require{" "}
                {licenseCount.totalCameraCount -
                  licenseCount.totalLicensedCameraCount}{" "}
                additional licenses
                <br />
                to keep using the exclusive features on myDW.
              </InfoBubble>
            </BubbleContainer>
          )}
        </button>
      )}
    </s.LicenseInfo>
  );
  return (
    <>
      <TableTemplate
        titleComponent={titleComponent}
        centerChildren={centerChildren}
        licenseInfo={licenseInfo}
        keyword={paginationInfo.keyword}
        onClickSearch={onClickSearch}
        requiredPermissions="manageRecordersAndGroups"
      >
        <CommonTable
          columns={columns}
          dataSource={data}
          queryInfo={paginationInfo}
          onChangePage={onChangePage}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                event.stopPropagation();
                onClickEdit(record);
              },
            };
          }}
          loading={initialPending}
        />
      </TableTemplate>
      {isAddModal && <AddListModal type="add" onModal={() => onModal("add")} />}
      {isEditModal && selectRow.recordId && (
        <AddListModal2
          type="edit"
          selectRowId={selectRow.recordId}
          onModal={() => onModal("edit")}
        />
      )}
      {isDeleteConfirm && (
        <Deactivate
          onModal={onClickDelete}
          type="system"
          targetId={selectRow.title}
        />
      )}
      {isDeregisterHelp && <DeregisterHelp onModal={closeDeregisterHelp} />}
    </>
  );
}
