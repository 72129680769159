import { useState, useCallback, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { DataRow, UsersPresenter } from "./../UsersPresenter";
import * as s from "./../UsersStyled";
import TableButton from "components/atoms/buttons/TableButton";

import { getGroupList } from "api/userAPI";
import {
  BasePagination,
  CustomError,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import { Group } from "api/interfaces/userInterface.interface";
import { createGroup, deleteGroup } from "api/userAPI";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import { FaRegUser } from "react-icons/fa";
import RBACWrapper from "components/blocks/function/RBACWrapper";
import { isCheckPermission } from "utils/AccountUtil";
import { useAuth } from "components/Auth";
import { useIntl } from "react-intl";
import { notify } from "components/atoms/notification/Notification";
import useApiError from "hook/useApiError";
import { useLocation, useNavigate } from "react-router-dom";
import { isNull } from "lodash";
import { queryNavigate } from "utils/MenuUtil";
import { TableColumnsType } from "antd";
import {
  changeSortOrderAntd,
  onSortAntd,
} from "components/atoms/table/AntdTable";
import { ColumnType } from "antd/es/table";
import { Default, useIsMobile } from "components/MediaQuery";

const initialQueryInfo: BasePagination = {
  total: 0,
  pageNum: 0,
  pageLimit: 20,
  keyword: "",
  sortType: "name",
  sortDirection: SORT_DIRECTION.ASC,
};

export function GroupsContainer(): JSX.Element {
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();

  const { handleError } = useApiError();
  const menuType: string = "group";
  const queryClient = useQueryClient();
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const auth = useAuth();

  const [isAddModal, setIsAddModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);

  const [status, setStatus] = useState({
    totalTitle: "Total Groups",
    totalValue: 0,
  });

  const [isRowClickEditable, setIsRowClickEditable] = useState<boolean>(
    isCheckPermission("manageUsersAndGroups", selectedAccount)
  );
  useEffect(() => {
    if (selectedAccount) {
      setIsRowClickEditable(
        isCheckPermission("manageUsersAndGroups", selectedAccount)
      );
    }
  }, [selectedAccount]);

  useEffect(() => {
    const changeParam = new URLSearchParams(location.search);
    if (changeParam.size > 0) {
      setGroupQueryInfo((info) => {
        return {
          ...info,
          pageNum: isNull(changeParam.get("pageNum"))
            ? 0
            : Number(changeParam.get("pageNum")),
          pageLimit: isNull(changeParam.get("pageLimit"))
            ? 20
            : Number(changeParam.get("pageLimit")),
          keyword: isNull(changeParam.get("keyword"))
            ? ""
            : (changeParam.get("keyword") as string),
          sortType: isNull(changeParam.get("sortType"))
            ? "name"
            : (changeParam.get("sortType") as string),
          sortDirection: isNull(changeParam.get("sortDirection"))
            ? SORT_DIRECTION.ASC
            : (changeParam.get("sortDirection") as SORT_DIRECTION),
        };
      });
    } else {
      setGroupQueryInfo((info) => {
        return {
          ...info,
          pageNum: 0,
          pageLimit: 20,
          keyword: "",
          sortType: "name",
          sortDirection: SORT_DIRECTION.ASC,
        };
      });
    }
  }, [location]);

  const [pending, setPending] = useState<boolean>(true);

  const [selectedEdit, setSelectedEdit] = useState<Group>();
  const [groups, setGroups] = useState<DataRow[]>([]);
  const [groupQueryInfo, setGroupQueryInfo] = useState<BasePagination>({
    ...initialQueryInfo,
  });
  const onHeaderCell = (column: ColumnType<DataRow>) => {
    return {
      onClick: () => {
        if (column.key) {
          setGroupQueryInfo((info) => {
            return {
              ...info,
              sortType: column.key as string,
              sortDirection: onSortAntd(info.sortDirection),
            };
          });
        }
      },
    };
  };

  const isEditable = ({ enableDelete, email }: DataRow) => {
    if (enableDelete) {
      return selectedAccount.isAdminGroup || email === auth.user.email;
    }
    return selectedAccount.isAdminGroup;
  };
  // user roles
  const columns: TableColumnsType<DataRow> = [
    {
      title: "Name",
      render: (value, row) => (
        <s.Row isAdmin={!row.enableDelete}>
          <Default>
            <div>
              <FaRegUser size={16} />
            </div>
          </Default>
          {row.name}
        </s.Row>
      ),
      sorter: true,
      key: "name",
      sortOrder: changeSortOrderAntd(groupQueryInfo, "name"),
      onHeaderCell: onHeaderCell,
      defaultSortOrder: "ascend",
    },
    {
      title: "Host",
      render: (value, row) => (
        <s.Text isDeleted={!row.hostValidation}>
          {row.enableDelete
            ? row.hostValidation
              ? row.host!
              : `${row.host} - Deactivated User`
            : "Provided by MyDW"}
        </s.Text>
      ),
      sorter: true,
      sortOrder: changeSortOrderAntd(groupQueryInfo, "hostName"),
      onHeaderCell: onHeaderCell,
      key: "hostName",
    },
    {
      title: "Users",
      render: (value, row) => row.userCnt!,
      align: "center",
      width: useIsMobile() ? 50 : 120,
    },
    {
      title: "Description",
      render: (value, row) => row.description,
      sorter: true,
      sortOrder: changeSortOrderAntd(groupQueryInfo, "description"),
      onHeaderCell: onHeaderCell,
      key: "description",
      responsive: ["md"],
      ellipsis: true,
    },
    // 마지막 버튼
    {
      title: "",
      width: useIsMobile() ? 30 : 80,
      render: (row) => (
        <RBACWrapper requiredPermissions="manageUsersAndGroups">
          <s.Row className="table-button-wrapper">
            <span className="row-click-button">
              <TableButton
                label={isEditable(row) ? "Edit" : "View"}
                onClick={() => {
                  setSelectedEdit(row.raw as Group);
                  onModal("edit");
                }}
              />
            </span>
          </s.Row>
        </RBACWrapper>
      ),
      align: "right",
    },
  ];

  const onRowClicked = (row: DataRow) => {
    if (isCheckPermission("manageUsersAndGroups", selectedAccount)) {
      setSelectedEdit(row.raw as Group);
      onModal("edit");
    }
  };

  const onModal = (type?: string) => {
    if (type === "add") {
      if (isAddModal) {
        queryClient.invalidateQueries("group");
      }
      setIsAddModal(!isAddModal);
    }
    if (type === "edit") {
      if (isEditModal) {
        queryClient.invalidateQueries("group");
      }
      setIsEditModal(!isEditModal);
    }
  };

  const { error, refetch } = useQuery(
    ["group", groupQueryInfo],
    () =>
      getGroupList({
        payload: groupQueryInfo,
        accountId: selectedAccount.accountId,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res.error !== 0 || res.result === undefined) {
          setGroups([]);
          setGroupQueryInfo((info) => {
            return { ...initialQueryInfo };
          });
          setStatus((info) => {
            return { ...info, totalValue: 0 };
          });
          return;
        }
        setGroupQueryInfo((info) => {
          return { ...info, ...res.page };
        });
        setGroups(
          res.result.map((group: any) => {
            return {
              id: group.groupId as string,
              name: group.name,
              host:
                group.hostName !== undefined
                  ? group.hostName +
                    (group.hostEmail !== undefined
                      ? " (" + group.hostEmail + ")"
                      : "")
                  : "",
              email: group.hostEmail,
              userCnt: group.hasUsers,
              description: group.description,
              enableDelete: group.enableDelete,
              hostValidation: group.hostValidation,
              raw: group,
            };
          })
        );
        setStatus({
          totalTitle: "Total Groups",
          totalValue: res.page.total,
        });
      },
      onError: (e: any) => {
        notify(
          "error",
          intl.formatMessage({
            id: "label.userGroup.notify.list.fail",
            defaultMessage: "User group search error.",
          })
        );
        setGroups([]);
        setStatus((info) => {
          return { ...info, totalValue: 0 };
        });
      },
      onSettled: (e: any) => {
        setPending(false);
      },
    }
  );

  const mutationDeleteGroup = useMutation(deleteGroup, {
    onSuccess: () => {
      notify(
        "success",
        intl.formatMessage({
          id: "label.userGroup.notify.delete.success",
          defaultMessage: "User group deleted successfully.",
        })
      );
      queryClient.invalidateQueries("group");
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.userGroup.notify.delete.fail",
          defaultMessage: "Failed to delete user group.",
        })
      );
      // notify(
      //   "error",
      //   intl.formatMessage({
      //     id: "label.userGroup.notify.delete.fail",
      //     defaultMessage: "Fail to delete User group.",
      //   })
      // );
    },
  });

  const mutationCreateGroup = useMutation(createGroup, {
    onSuccess: () => {
      notify(
        "success",
        intl.formatMessage({
          id: "label.userGroup.notify.add.success",
          defaultMessage: "User group added successfully.",
        })
      );
      queryClient.invalidateQueries("group");
      setIsAddModal(false);
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.userGroup.notify.add.fail",
          defaultMessage: "Failed to add user group.",
        })
      );
    },
  });

  const onClickSave = useCallback(
    (group: Group) => {
      mutationCreateGroup.mutate({
        accountId: selectedAccount.accountId,
        payload: group,
      });
    },
    [mutationCreateGroup, selectedAccount.accountId]
  );

  const onChangePage = useCallback(
    (page: number, totalRows: number) => {
      console.log(page, totalRows);
      if (page !== groupQueryInfo.pageNum + 1) {
        queryNavigate(
          navigate,
          location.pathname,
          {
            pageLimit: groupQueryInfo.pageLimit,
            pageNum: page - 1,
            keyword: groupQueryInfo.keyword as string,
            sortType: groupQueryInfo.sortType,
            sortDirection: groupQueryInfo.sortDirection as SORT_DIRECTION,
          },
          selectedAccount
        );
        setPending(true);
        // setGroupQueryInfo((info) => {
        //   return { ...info, pageNum: (page - 1) as number };
        // });
      }
    },
    [
      groupQueryInfo.keyword,
      groupQueryInfo.pageLimit,
      groupQueryInfo.pageNum,
      groupQueryInfo.sortDirection,
      groupQueryInfo.sortType,
      location.pathname,
      navigate,
      selectedAccount,
    ]
  );

  const onClickSearch = useCallback(
    (keyword: string) => {
      if (groupQueryInfo.keyword !== keyword) {
        queryNavigate(
          navigate,
          location.pathname,
          {
            pageLimit: groupQueryInfo.pageLimit,
            pageNum: 0,
            keyword: keyword,
            sortType: groupQueryInfo.sortType,
            sortDirection: groupQueryInfo.sortDirection as SORT_DIRECTION,
          },
          selectedAccount
        );
      } else {
        refetch();
      }
      setPending(true);
      // setGroupQueryInfo((query) => {
      //   return {
      //     ...query,
      //     keyword,
      //   };
      // });
      // refetch();
    },
    [
      groupQueryInfo.keyword,
      groupQueryInfo.pageLimit,
      groupQueryInfo.sortDirection,
      groupQueryInfo.sortType,
      location.pathname,
      navigate,
      refetch,
      selectedAccount,
    ]
  );

  return (
    <UsersPresenter
      menuType={menuType}
      columns={columns}
      data={groups}
      onModal={onModal}
      isAddModal={isAddModal}
      isEditModal={isEditModal}
      status={status}
      onClickSave={onClickSave}
      onChangePage={onChangePage}
      selectedEdit={selectedEdit}
      onClickSearch={onClickSearch}
      onRowClicked={onRowClicked}
      initialPending={pending}
      paginationInfo={groupQueryInfo}
      isSwitchModal={false}
      isCreateLoading={mutationCreateGroup.isLoading}
      isUpdateLoading={mutationCreateGroup.isLoading}
    />
  );
}
