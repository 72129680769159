import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { requestPageContainer } from "redux/reducers/htmlContainer/htmlContainerReducer";

const useHtmlContainer = () => {
  const dispatch = useAppDispatch();
  const { pageContainer } = useAppSelector((state) => state.htmlContainer);

  useEffect(() => {
    dispatch(requestPageContainer({}));
  }, [])

  const scrollToTop = () => {
    if (!pageContainer) {
      dispatch(requestPageContainer({}));
      return;
    }
    pageContainer.scroll({ top: 0 });
  }

  return {
    scrollToTop
  }
};

export default useHtmlContainer;
