/**
 * @author ydoni
 * @description Recorder cell 클릭 후 health detail 로 넘어오는데
 *              props.location.state 로 row 들 넘기거나 id 만 넘긴 후
 *               api 조회하도록한다
 *
 */
import { TableTemplate } from "components/templates/default/table/TableTemplate";
import { useEffect, useMemo, useState } from "react";
import HealthContent from "./component/HealthContent";
import HealthHeader from "./component/HealthHeader";
import HealthMenus, { Value } from "./component/HealthMenus";
import * as s from "./HealthStyled";
import * as mediaQuery from "components/MediaQuery";
import { useMediaQuery } from "react-responsive";

// 아이콘
import { MdOutlineHealthAndSafety } from "react-icons/md";
import { FiCamera } from "react-icons/fi";
import { VscGraphLine } from "react-icons/vsc";
import { PiGauge } from "react-icons/pi";
import { BsDatabase } from "react-icons/bs";
import { TbAccessPoint } from "react-icons/tb";

import CAMERA_ICON from "assets/icons/camera_report.svg";
import HEALTH_ICON from "assets/icons/health.svg";
import PERFORMANCE_ICON from "assets/icons/performance.svg";
import REMOTE_ICON from "assets/icons/remote_access.svg";
import STORAGE_ICON from "assets/icons/storage.svg";
import SYSTEM_SPECS_ICON from "assets/icons/system_specs.svg";
import {
  Location,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import { useQuery } from "react-query";
import {
  getReportDetailPerformance,
  getReportHealthDetailTotal,
} from "api/reportAPI";
import {
  BasePagination,
  LEVEL_TYPE,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import {
  ReportDetailRecorderInfo,
  ReportHealthDetailCamera,
  ReportHealthDetailPerformance,
  ReportHealthDetailRecorder,
  ReportHealthDetailRemote,
  ReportHealthDetailStorage,
  PerformanceData,
  RecorderHealthRow,
  ReportHealthDetailUser,
  Interval,
  ReportHealthDetailCloudStorage,
  ReportHealthDetailRecorderAlert,
  GetReportHealthDetail,
} from "api/interfaces/reportInterface.interface";
import { formatBytes } from "utils/functions";
import { useAppSelector } from "redux/hooks";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { getAccountTimeFormat } from "utils/timeUtil";
import { round } from "lodash";
import { AlertTypeEnumKeys } from "utils/AlertUtil";
import qs from "qs";
import { getRecorder } from "api/recorderAPI";
import { RecorderResposne } from "api/interfaces/recorderInterface.interface";
import { menuNavigation } from "utils/MenuUtil";

type Props = {};

type HealthStatus = {
  label: string;
  status: string;
};

export type RecorderDetail = {
  type: string;
  recorderId: string;
  recorderName: string;
  mergedSystemId: string;
  mergedSystemName: string;
  cloudSystemId: string;
  timezone: string;
  accountName?: string;
  accountNumber?: string;
  accountId?: string;
  brand?: string;
};

export default function HealthDetail(props: Props): JSX.Element {
  const navigate = useNavigate();
  const [isCurrentMenu, setIsCurrentMenu] = useState<number>(0);
  const isNotMobile = useMediaQuery({ minWidth: mediaQuery.tabletMin });

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const location: Location = useLocation();

  const params = useParams();
  const reportId = params.reportId as string;
  const recorderId = params.recorderId as string;
  const { account } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    decoder: (s) => decodeURIComponent(s),
  });

  const [reportTitle, setReportTitle] = useState<string>("");
  const [mergedCount, setMergedCount] = useState<number>(0);

  const [recorderHealth, setRecorderHealth] =
    useState<ReportHealthDetailRecorder>({
      onlineStatus: true,
      totalAlerts: 0,
      alerts: [],
    });
  const [cameraHealth, setCameraHealth] = useState<ReportHealthDetailCamera>({
    lastUpdate: "",
    totalCamera: 0,
    videoLoss: 0,
    cameras: [],
  });
  const [userHealth, setUserHealth] = useState<ReportHealthDetailUser>({
    totalUsers: 0,
    users: [],
  });
  const [performance, setPerformance] = useState<ReportHealthDetailPerformance>(
    {
      cpuPercentage: 0,
      memoryPercentage: 0,
      performance: [],
    }
  );
  const [remoteAccess, setRemoteAccess] = useState<ReportHealthDetailRemote>({
    userTotalCount: 0,
    users: [],
  });
  const [storages, setStorages] = useState<ReportHealthDetailStorage[]>([]);

  const [cloudStorage, setCloudStorage] =
    useState<ReportHealthDetailCloudStorage>({
      totalStorage: 0,
      usedStorage: 0,
    });

  const [recorderInfo, setRecorderInfo] = useState<ReportDetailRecorderInfo>({
    recorderName: "",
    recorderModel: "",
    installedDate: "",
    location: "",
  });

  const [recorderDetailInfo, setRecorderDetailInfo] = useState<RecorderDetail>({
    type: "",
    recorderId: "",
    recorderName: "",
    mergedSystemId: "",
    mergedSystemName: "",
    cloudSystemId: "",
    timezone: "",
  });

  const [lastUpdateTime, setLastUpdateTime] = useState<string>("");

  const onChangeMenu = (index: number) => {
    setIsCurrentMenu(index);
  };

  const [healthStatus, setHealthStatus] = useState<HealthStatus>({
    label: "",
    status: "healthy",
  });
  const [cameraStatus, setCameraStatus] = useState<HealthStatus>({
    label: "",
    status: "healthy",
  });
  const [performanceStatus, setPerformanceStatus] = useState<HealthStatus>({
    label: "",
    status: "",
  });
  const [performanceIntervalValue, setIntervalValue] =
    useState<Interval>("DAILY");

  const [isSystemNotResponding, setIsSystemNotResponding] =
    useState<ReportHealthDetailRecorderAlert | null>(null);

  const onChangeIntervalOption = (e: any) => {
    setIntervalValue(e);
  };

  const menus = useMemo(
    () => [
      {
        label: "Recorder Health",
        contentLabel: "Alerts",
        value: healthStatus.label,
        img: HEALTH_ICON,
        icon: <MdOutlineHealthAndSafety size={40} />,
        status: healthStatus.status, // value 빨간색 표시 위함
      },
      {
        label: "Cameras",
        value: cameraStatus.label,
        img: CAMERA_ICON,
        icon: <FiCamera size={30} />,
        status: cameraStatus.status, // value 빨간색 표시 위함
      },
      {
        label: "Performance",
        value: performanceStatus.label,
        img: PERFORMANCE_ICON,
        icon: <VscGraphLine size={30} />,
        status: performanceStatus.status,
      },
      {
        label: "Remote Access",
        subLabel: "Users",
        value: remoteAccess.userTotalCount,
        img: REMOTE_ICON,
        icon: <TbAccessPoint size={40} />,
      },
      {
        label: "System Status",
        value: "",
        img: SYSTEM_SPECS_ICON,
        icon: <PiGauge size={35} />,
      },
      {
        label: "Storage",
        value: (
          <s.HealthMenuStorage>
            {/* <Value status={"warning"}>0/ {formatBytes(cameraHealth.totalCamera*1024)}</Value> */}
            <Value>
              {" "}
              {formatBytes(cloudStorage.usedStorage)} /{" "}
              {formatBytes(cloudStorage.totalStorage)}
            </Value>
            <s.HealthMenuStorageValue> Used</s.HealthMenuStorageValue>
          </s.HealthMenuStorage>
        ),
        img: STORAGE_ICON,
        icon: <BsDatabase size={30} />,
      },
    ],
    [healthStatus, cameraStatus, performanceStatus, remoteAccess, cloudStorage]
  );

  const headerItems = [
    {
      label: "Recorder",
      value: recorderInfo?.recorderName,
    },
    {
      label: "Model",
      value: recorderInfo?.recorderModel,
    },
    {
      label: "Installation Date",
      value: recorderInfo?.installedDate,
    },
    {
      label: "Location",
      value: recorderInfo?.location,
    },
  ];

  // sub header breadcumb 배열 (비효율적인것 같음 redux 로 받아온 후 push 해주는게 좋을거같음)
  const titleComponent =
    mergedCount !== undefined && mergedCount > 1
      ? [
          {
            label: "Report List",
            onClick: () =>
              menuNavigation(selectedAccount, navigate, "/reports"),
          },
          {
            label: reportTitle,
            onClick: () =>
              menuNavigation(
                selectedAccount,
                navigate,
                `/reports/health/${reportId}`
              ),
          },
          {
            label: recorderDetailInfo.mergedSystemName,
            onClick: () =>
              menuNavigation(
                selectedAccount,
                navigate,
                `/reports/health/merged/${reportId}/${recorderDetailInfo.mergedSystemId}`
              ),
            isNoshow: !isNotMobile,
          },
          {
            // 추후 변경 필요
            label: recorderInfo.recorderName,
            isNoshow: !isNotMobile,
          },
        ]
      : [
          {
            label: "Report List",
            onClick: () =>
              menuNavigation(selectedAccount, navigate, "/reports"),
          },
          {
            label: reportTitle,
            onClick: () =>
              menuNavigation(
                selectedAccount,
                navigate,
                `/reports/health/${reportId}`
              ),
          },
          {
            // 추후 변경 필요
            label: recorderInfo.recorderName,
            isNoshow: !isNotMobile,
          },
        ];
  const isDesktop = useMediaQuery({ minWidth: mediaQuery.desktop });

  const recorderQuery = useQuery(
    ["recorderQueryInfo"],
    () =>
      getRecorder({
        accountId: account as string,
        recorderId: recorderId as string,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: RecorderResposne) => {
        if (res.result === undefined || res.error !== 0) {
          return;
        }
        setMergedCount(res.result.mergedCount as number);
        setRecorderDetailInfo({
          ...recorderDetailInfo,
          ...res.result,
          type: res.result.type,
          recorderId: res.result.recorderId,
          recorderName: res.result.name,
          mergedSystemId: res.result.mergedSystemId,
          mergedSystemName: res.result.mergedSystemName,
          cloudSystemId: res.result.cloudSystemId,
          timezone: res.result.timezone,
        });
      },
      onError: (e: any) => {
        console.log(e, "error");
      },
    }
  );

  const [reporDetailQueryInfo, setReporDetailQueryInfo] =
    useState<BasePagination>({
      total: 0,
      pageNum: 0,
      pageLimit: 20,
      keyword: "",
      sortType: "",
      sortDirection: SORT_DIRECTION.ASC,
    });

  const { isLoading, error, refetch } = useQuery(
    ["reportHealthDetail", reporDetailQueryInfo],
    () =>
      getReportHealthDetailTotal({
        reportId: reportId as string,
        recorderId: recorderId as string,
        accountId: selectedAccount.accountId,
        payload: reporDetailQueryInfo,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: GetReportHealthDetail) => {
        // console.log(res.result);
        const result = res.result;
        if (result && res.error === 0) {
          const resCameraHealth = result.cameraHealth;
          const resPerformance = result.performance;
          const resRecorderHealth = result.recorderHealth;

          setReportTitle(result.title);
          setRecorderInfo({
            recorderName: result.recorderName || "",
            recorderModel: result.recorderModel || "",
            installedDate:
              result.installedDate !== undefined
                ? getAccountTimeFormat(
                    result.installedDate,
                    selectedAccount,
                    true
                  )
                : "",

            location: result.location?.location || "",
          });
          setRecorderDetailInfo({
            ...recorderDetailInfo,
            ...result,
            accountName: result.accountName || "",
            accountNumber: result.accountNumber || "",
            accountId: result.accountId || "",
          });

          if (resCameraHealth) {
            setCameraHealth(resCameraHealth);
            if (resCameraHealth.cameras !== undefined) {
              if (resCameraHealth.videoLoss > 0) {
                setCameraStatus({ label: "Loss Detected", status: "alert" });
              } else {
                !resRecorderHealth.alerts?.find(
                  (alert: ReportHealthDetailRecorderAlert) =>
                    alert.alertReason ===
                    AlertTypeEnumKeys.SYSTEM_NOT_RESPONDING
                ) && setCameraStatus({ label: "Good", status: "healthy" });
              }
            }
          }

          if (resPerformance !== undefined) {
            changeToDataFormat(resPerformance);
          }

          if (resRecorderHealth) {
            setRecorderHealth(resRecorderHealth);
            if (resRecorderHealth.alerts) {
              const isFindCritical = resRecorderHealth.alerts.find(
                (alert: ReportHealthDetailRecorderAlert) => alert.isCritical
              );

              const isFindAlert = resRecorderHealth.alerts.find(
                (alert: ReportHealthDetailRecorderAlert) =>
                  alert.status === "ACTIVE"
              );
              const isFindWarning = resRecorderHealth.alerts.find(
                (alert: ReportHealthDetailRecorderAlert) =>
                  alert.status === "Warning"
              );
              const findNotResponding = resRecorderHealth.alerts.find(
                (alert: ReportHealthDetailRecorderAlert) =>
                  alert.alertReason === AlertTypeEnumKeys.SYSTEM_NOT_RESPONDING
              );

              if (findNotResponding) {
                setIsSystemNotResponding(findNotResponding);
              }

              if (isFindCritical) {
                setHealthStatus({ label: "Critical", status: "alert" });
              } else if (isFindAlert) {
                setHealthStatus({ label: "Alert", status: "alert" });
              } else if (isFindWarning) {
                setHealthStatus({ label: "Warning", status: "warning" });
              } else {
                setHealthStatus({ label: "Good", status: "healthy" });
              }
            } else {
              setHealthStatus({ label: "Good", status: "healthy" });
            }
          }

          if (result.users) {
            setUserHealth(result.users);
          }

          if (result.remoteAccess) {
            setRemoteAccess(result.remoteAccess);
          }
          if (result.storages) {
            setStorages(result.storages);
          }

          if (result.cloudStorage) {
            setCloudStorage(result.cloudStorage);
          }
        }
      },
      onError: (e: any) => {
        console.log(e, "error");
      },
    }
  );

  const changeToDataFormat = (data: PerformanceData[]) => {
    let tempCpuArr = data.map((per) => per.cpu);
    let tempMemoArr = data.map((per) => per.memory);
    let tempCpuTotal = tempCpuArr.reduce((acc, cur) => acc + cur, 0);
    let tempMemTotal = tempMemoArr.reduce((acc, cur) => acc + cur, 0);
    let tempCpuPercen =
      tempCpuArr.length === 0 ? 0 : round(tempCpuTotal / tempCpuArr.length, 2);
    let tempMemPercen: number =
      tempMemoArr.length === 0
        ? 0
        : round(tempMemTotal / tempMemoArr.length, 2);

    if (tempCpuPercen <= 80 && tempMemPercen <= 80) {
      setPerformanceStatus({
        ...performanceStatus,
        label: "Good",
        status: "healthy",
      });
    } else {
      setPerformanceStatus({
        ...performanceStatus,
        label: "Poor",
        status: "alert",
      });
    }
    if (tempCpuArr.length === 0 && tempMemoArr.length === 0) {
      setPerformanceStatus({
        ...performanceStatus,
        label: "",
      });
    }

    setPerformance({
      ...performance,
      cpuPercentage: tempCpuPercen,
      memoryPercentage: tempMemPercen,
      performance: data,
    });
  };
  /**
   * intervalValue 바뀔때만 호출 && 첫 렌더링은 미호출
   */
  const healthReportPerformanceQuery = useQuery(
    ["performance", performanceIntervalValue],
    () =>
      getReportDetailPerformance({
        reportId: reportId as string,
        recorderId: recorderId as string,
        accountId: selectedAccount.accountId,
        periodicalType: performanceIntervalValue,
      }),
    {
      retry: 0,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res.result) {
          changeToDataFormat(res.result);
        } else {
          changeToDataFormat([]);
        }
      },
      onError: (e: any) => {},
    }
  );

  useEffect(() => {
    if (isSystemNotResponding) {
      setPerformanceStatus({
        label: "",
        status: "",
      });
      setCameraStatus({ label: "", status: "" });
      setLastUpdateTime(isSystemNotResponding.alertTrigger || "");
    }
  }, [isSystemNotResponding]);

  return (
    <TableTemplate noSearch titleComponent={titleComponent}>
      <s.HealthDetailGrid>
        <HealthHeader items={headerItems} recorderInfo={recorderDetailInfo} />
        <HealthMenus
          menus={menus}
          onChangeMenu={onChangeMenu}
          isCurrentMenu={isCurrentMenu}
        />
        <HealthContent
          accountId={selectedAccount.accountId}
          recorderInfo={recorderDetailInfo}
          recorderHealth={recorderHealth}
          cameraHealth={cameraHealth}
          userHealth={userHealth}
          performance={performance}
          remoteAccess={remoteAccess}
          storages={storages}
          cloudStorage={cloudStorage}
          menus={menus}
          isCurrentMenu={isCurrentMenu}
          reportId={reportId as string}
          recorderId={recorderId as string}
          performanceIntervalValue={performanceIntervalValue}
          onChangeIntervalOption={onChangeIntervalOption}
          lastUpdateTime={lastUpdateTime}
        />
      </s.HealthDetailGrid>
    </TableTemplate>
  );
}
