import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useQuery } from "react-query";

import {
  BasePagination,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import { getGroupList } from "api/userAPI";
import React from "react";
import { Checkbox, TableColumnsType } from "antd";
import { VirtualTable } from "components/atoms/table/AntdVirtualTable";
import { matchStringSearchKeyword } from "utils/functions";
import { TableRowSelection } from "antd/es/table/interface";
import { AlertRuleGroupRecipients } from "api/interfaces/alertInterface.interface";

export type SearchUserGroupProps = {
  accountId: string;
  keyword?: string;
  selectedUserGroupId?: string[];
  onSelectedRowsChangeUserGroup?: (selectedRows: string[]) => void;
  onChangeEnableCheckUserGroup?: (
    selectedRows: AlertRuleGroupRecipients[]
  ) => void;

  isSupportEmail?: boolean;
  enableCheckUserGroupList?: AlertRuleGroupRecipients[];
};

interface DataRow {
  id: string;
  name: string;
  description: string;
}

export const SearchUserGroup = forwardRef(
  (props: SearchUserGroupProps, ref: ForwardedRef<any>): JSX.Element => {
    console.log(props.enableCheckUserGroupList);
    const [groups, setGroups] = useState<DataRow[]>([]);
    const [pending, setPending] = useState<boolean>(true);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const [selectedEnable, setSelectedEnable] = useState<
      AlertRuleGroupRecipients[]
    >(props.enableCheckUserGroupList || []);

    const [groupQueryInfo] = useState<BasePagination>({
      total: 0,
      pageNum: 0,
      pageLimit: 2000,
      keyword: "",
      sortType: "name",
      sortDirection: SORT_DIRECTION.ASC,
    });

    const data: DataRow[] = useMemo(() => {
      return groups.filter((rec) => {
        if (props.keyword) {
          return (
            matchStringSearchKeyword(rec.name, props.keyword) ||
            matchStringSearchKeyword(rec.description, props.keyword)
          );
        } else return true;
      });
    }, [props.keyword, groups]);

    const onCheckRecipient = useCallback(
      (recipients: AlertRuleGroupRecipients[]) => {
        setSelectedEnable(recipients);
        if (props.onChangeEnableCheckUserGroup) {
          props.onChangeEnableCheckUserGroup(recipients);
        }
      },
      [selectedEnable]
    );

    const emailHeaderToggleHandler = useCallback(
      (checked: boolean) => {
        const tmpData = [...selectedEnable];
        tmpData.forEach((data) => {
          data.enableEmail = checked;
        });
        onCheckRecipient(tmpData);
      },
      [selectedEnable]
    );

    const pushHeaderToggleHandler = useCallback(
      (checked: boolean) => {
        const tmpData = [...selectedEnable];
        tmpData.forEach((data) => {
          data.enablePush = checked;
        });
        onCheckRecipient(tmpData);
      },
      [selectedEnable]
    );

    const columns: TableColumnsType<DataRow> = useMemo(
      () => [
        {
          key: "name",
          dataIndex: "name",
          title: "Name",
          render: (value, row) => value,
          width: 200,
          ellipsis: true,
        },
        {
          key: "description",
          dataIndex: "description",
          title: "Description",
          render: (value, row) => value,
          ellipsis: true,
          responsive: ["md"],
        },
        {
          key: "enableEmail",
          dataIndex: "enableEmail",
          title: ({ filters }) => {
            let tmpCheckPush = selectedEnable
              .map((g) => g.enableEmail)
              .every((gc) => gc);
            let tmpCheckPushInter = selectedEnable
              .map((g) => g.enableEmail)
              .some((gc) => gc);

            return (
              <div>
                <Checkbox
                  defaultChecked={false}
                  checked={tmpCheckPush}
                  indeterminate={!tmpCheckPush ? tmpCheckPushInter : false}
                  onChange={(e) => {
                    emailHeaderToggleHandler(e.target.checked);
                  }}
                />
                Email
              </div>
            );
          },
          render: (value, row, index) => (
            <Checkbox
              checked={selectedEnable.some(
                (item) => item.userGroupId === row.id && item.enableEmail
              )}
              onChange={(e) => {
                const tempArr = [...selectedEnable].map((item) =>
                  item.userGroupId === row.id
                    ? { ...item, enableEmail: !item.enableEmail }
                    : item
                );
                onCheckRecipient(tempArr);
              }}
            />
          ),
          width: 80,
          hidden: !!!props.isSupportEmail,
        },
        {
          key: "enablePush",
          dataIndex: "enablePush",
          title: ({ filters }) => {
            let tmpCheckPush = selectedEnable
              .map((g) => g.enablePush)
              .every((gc) => gc);
            let tmpCheckPushInter = selectedEnable
              .map((g) => g.enablePush)
              .some((gc) => gc);

            return (
              <div>
                <Checkbox
                  defaultChecked={false}
                  checked={tmpCheckPush}
                  indeterminate={!tmpCheckPush ? tmpCheckPushInter : false}
                  onChange={(e) => {
                    pushHeaderToggleHandler(e.target.checked);
                  }}
                />
                Push
              </div>
            );
          },
          render: (value, row, index) => (
            <Checkbox
              checked={selectedEnable.some(
                (item) => item.userGroupId === row.id && item.enablePush
              )}
              onChange={(e) => {
                const tempArr = [...selectedEnable].map((item) =>
                  item.userGroupId === row.id
                    ? { ...item, enablePush: !item.enablePush }
                    : item
                );
                onCheckRecipient(tempArr);
              }}
            />
          ),
          width: 80,
          hidden: !!!props.isSupportEmail,
        },
      ],
      [props.isSupportEmail, data, groups, selectedEnable]
    );

    const { error, refetch } = useQuery(
      ["group", groupQueryInfo],
      () =>
        getGroupList({
          payload: groupQueryInfo,
          accountId: props.accountId,
        }),
      {
        retry: 0,
        refetchOnWindowFocus: false,
        onSuccess: (res: any) => {
          if (res.result === undefined) {
            return;
          }
          setGroups(
            res.result.map((group: any) => {
              return {
                ...group,
                id: group.groupId as string,
                key: group.groupId as string,
                name: group.name,
                description: group.description,
              };
            })
          );
        },
        onError: (e: any) => {},
        onSettled: () => {
          setPending(false);
        },
      }
    );

    const onSelectChange = (
      newSelectedRowKeys: React.Key[],
      selectedRows: DataRow[]
    ) => {
      setSelectedRowKeys(newSelectedRowKeys);
      if (props.onSelectedRowsChangeUserGroup)
        props.onSelectedRowsChangeUserGroup(newSelectedRowKeys as string[]);
    };

    const rowSelection: TableRowSelection<DataRow> = {
      columnWidth: 40,
      selectedRowKeys,
      onChange: onSelectChange,
    };

    useEffect(() => {
      // console.log(props.selectedUserGroupId, props.enableCheckUserGroupList);
      if (props.selectedUserGroupId !== undefined) {
        setSelectedRowKeys(props.selectedUserGroupId);
      }
    }, [props.selectedUserGroupId]);

    useEffect(() => {
      if (props.enableCheckUserGroupList) {
        setSelectedEnable(
          groups.map((user: DataRow) => {
            return {
              userGroupId: user.id,
              enableEmail:
                (props.enableCheckUserGroupList?.find(
                  (temp) => temp.userGroupId === user.id
                )?.enableEmail as boolean) || false,
              enablePush:
                (props.enableCheckUserGroupList?.find(
                  (temp) => temp.userGroupId === user.id
                )?.enablePush as boolean) || false,
            };
          })
        );
      }
    }, [props.enableCheckUserGroupList, groups]);

    return (
      <>
        <VirtualTable
          columns={columns}
          dataSource={data}
          rowSelection={!!!props.isSupportEmail ? rowSelection : undefined}
          loading={pending}
        />
      </>
    );
  }
);
