import styled from "styled-components";
import * as s from "../../ModalTemplateStyled";
import SmallPopupDefaultTemplate from "../SmallPopupDefaultTemplate";
import PrimaryButton from "components/atoms/buttons/PrimaryButton";
import ModalHeader from "components/blocks/modalheader/ModalHeader";

export type Props = {
  onModal: () => void;
  text: string | JSX.Element;
  title?: string;
  titleIcon?: JSX.Element;
};

export default function GeneralAlertModal({
  onModal,
  text,
  title,
  titleIcon,
}: Props): JSX.Element {
  const buttons = (
    <s.ButtonWrapper>
      <PrimaryButton label="OK" onClickBtn={onModal} />
    </s.ButtonWrapper>
  );
  return (
    <SmallPopupDefaultTemplate button={buttons} onModal={onModal}>
      {(title && !titleIcon) && <ModalHeader title={title} onModal={onModal} disableClose={true} />}
      {(title && titleIcon) && <ModalHeader title={title} titleIcon={titleIcon} onModal={onModal} disableClose={true} />}
      <s.SmallContainer>
        <Contents>
          <Text>{text}</Text>
        </Contents>
      </s.SmallContainer>
    </SmallPopupDefaultTemplate>
  );
}

const Contents = styled.div`
  margin: 40px 0;
`;

const Text = styled.div`
  max-width: 600px;
  line-height: 1.4;
  color: ${({ theme }) => theme.COLORS.WHITE};
  white-space: pre-line;
`;