import Input from "components/atoms/input/Input";
import TextArea from "antd/es/input/TextArea"
import { postAlertSendEmail } from "api/alertAPI"
import { ModalInput } from "components/atoms/input/modal/ModalInput"
import SmallPopupTemplate from "components/templates/modal/small/SmallPopupTemplate"
import { useCallback, useState } from "react"
import { Assignee } from "./AssigneeInfo"
import { useMutation } from "react-query";
import { notify } from "components/atoms/notification/Notification"
import { useIntl } from "react-intl"
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface"
import { useAppSelector } from "redux/hooks"
import useApiError from "hook/useApiError"
import { CustomError } from "api/interfaces/commonInterface.interface"

type SendEmailModalType = {
  assignee: Assignee;
  recorderId: string;
  onModal: () => void;
}

export const SendEmailModal = ({
  assignee,
  recorderId,
  onModal,
}: SendEmailModalType) => {
  const intl = useIntl();
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const { handleError } = useApiError();
  const [message, setMessage] = useState<string>('');
  const [error, setError] = useState({
    status: false,
    msg: "",
  });

  const mutationSendAlertEmail = useMutation(postAlertSendEmail, {
    onSuccess: () => {
      notify(
        "success",
        intl.formatMessage({
          id: "label.common.notify.email.success",
          defaultMessage: "Email sent successfully",
        })
      );
      onModal();
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.common.notify.email.fail",
          defaultMessage: "Failed to send email.",
        })
      );
    },
  });

  const onClickSendEmail = useCallback(() => {
    if (!assignee) {
      return;
    }
    setError({
      status: false,
      msg: "",
    });
    mutationSendAlertEmail.mutate({
      accountId: selectedAccount.accountId,
      recorderId: recorderId,
      payload: {
        email: assignee.email,
        message: message,
      },
    });
  }, [
    selectedAccount.accountId,
    recorderId,
    assignee,
    message,
  ]);

  return (
    <SmallPopupTemplate
      cancel
      title="Send an Email"
      subTitle="Send a email to your technician"
      onClick={onClickSendEmail}
      onModal={onModal}
      confirmLabel="Send"
    >
      <ModalInput label="Email" essential>
        <Input
          placeholder="Enter email address"
          disabled={true}
          defaultValue={assignee.email}
        />
      </ModalInput>
      <ModalInput
        label="Message (Optional)"
        isValidated={error.status}
        validatedWord={error.msg}
      >
        <TextArea
          placeholder="Enter your message here"
          value={message}
          onChange={((e) => {
            setMessage(e.target.value);
          })}
        />
      </ModalInput>
    </SmallPopupTemplate>
  )
}