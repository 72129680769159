import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ProfileAccountInfo,
  ProfileAccountInfoFromServer,
} from "api/interfaces/accountInterface.interface";
import { LEVEL_TYPE } from "api/interfaces/commonInterface.interface";
import { UserGroupUserPermission } from "api/interfaces/userInterface.interface";

import {
  checkedUserAdmin,
  checkOnlyAdminGroup,
  checkPermission,
  CheckPermissionParamType,
  isHiddenGroup,
} from "utils/AccountUtil";

const initialState: ProfileAccountInfo = {
  accountId: "",
  accountName: "",
  accountLevel: LEVEL_TYPE.INTL,
  accountNumber: "",
  accountAdditionalNumber: "",
  parentAccountId: "",
  parentLevel: "",
  timezone: "",
  dateFormat: "",
  timeFormat: "",
  companyName: "",
  companyLocation: "",
  companyLogo: "",
  description: "",
  isAdmin: false,
  isAdminGroup: false,
  isManager: false,
  checkedUserAdmin: false,
  use2faAuth: false,
  userPermission: {
    enableAllPermissions: false,
    manageAlertRules: false,
    viewAlerts: false,
    manageUsersAndGroups: false,
    viewUsersAndGroups: false,
    manageReportRules: false,
    viewReports: false,
    manageRecordersAndGroups: false,
    viewRecordersAndGroups: false,
    manageAccount: false,
    viewAccount: false,
    view: false,
    manageBillingAndLicenses: false,
  },
  recorderPermission: [],
  isHidden: false,
  isSuspended: false,
  isFavorite: false,
};

// 사용자 로그인 후 Account 정보와 선택정보를 전역으로 관리.

export const accountSlice = createSlice({
  name: "accountSettings",
  initialState,
  reducers: {
    setAccountSetting(
      state,
      action: PayloadAction<ProfileAccountInfoFromServer>
    ) {
      let tmpInfo = {
        ...action.payload,
        isAdminGroup: checkOnlyAdminGroup(action.payload.userPermission), // admin group 에 속해있는 user
        isAdmin: checkGroup(action.payload.userPermission, "administrator"), // admin 기능만 할 수 있거나 admin group 에 속해있는 user
        isManager: checkGroup(action.payload.userPermission, "manager"),
        checkedUserAdmin: checkedUserAdmin(action.payload.userPermission), // admin 기능만 할 수 있는 user
        userPermission: checkPermission(
          action.payload as CheckPermissionParamType
        ),
        isHidden: isHiddenGroup(action.payload.userPermission),
      };

      const newState: ProfileAccountInfo = Object.assign({}, tmpInfo);
      return newState;
    },
    updateAccountThumbnail(state, action: PayloadAction<string>) {
      state.companyLogo = action.payload;
    },
    purgeAccount(state, action) {
      return initialState;
    },
  },
});

export const { setAccountSetting, purgeAccount } = accountSlice.actions;

export default accountSlice;

const checkGroup = (permission: UserGroupUserPermission[], group: string) => {
  const check = permission?.filter(
    (permit) =>
      (!permit.enableDelete && permit.groupName?.toLowerCase() === group) ||
      permit.enableAllPermissions
  );
  return check.length !== 0 ? true : false;
};
