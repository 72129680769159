import { DashboardData } from "api/interfaces/dashboardInterface.interface";
import MARKWE_ALERT from "assets/icons/map/marker_alert.svg";
import MARKWE_HEALTHY from "assets/icons/map/marker_healthy.svg";
import MARKWE_CRITICAL from "assets/icons/map/marker_critical.svg";
import MARKWE_WARNING from "assets/icons/map/marker_warning.svg";

export type RecorderStatus = "CRITICAL" | "ALERT" | "WARNING" | "HEALTHY";

export enum enumRecorderStatus {
  CRITICAL = 4,
  ALERT = 3,
  WARNING = 2,
  HEALTHY = 1,
}

const enumToString: {[key: number]: RecorderStatus} = {
  [enumRecorderStatus.CRITICAL]: "CRITICAL",
  [enumRecorderStatus.ALERT]: "ALERT",
  [enumRecorderStatus.WARNING]: "WARNING",
  [enumRecorderStatus.HEALTHY]: "HEALTHY",
}

export const getTopAlertStatusString = (recorder: DashboardData): RecorderStatus => {
  const status = getTopAlertStatus(recorder);
  return enumToString[status];
}

export const getTopAlertStatus = (recorder: DashboardData): enumRecorderStatus => {
  if (recorder.critical && recorder.critical.length > 0) {
    return enumRecorderStatus.CRITICAL;
  }
  if (recorder.alerts && recorder.alerts.length > 0) {
    return enumRecorderStatus.ALERT;
  }
  if (recorder.warnings && recorder.warnings.length > 0) {
    return enumRecorderStatus.WARNING;
  }
  return enumRecorderStatus.HEALTHY;
}

const marker = {
  [enumRecorderStatus.HEALTHY]: MARKWE_HEALTHY,
  [enumRecorderStatus.WARNING]: MARKWE_WARNING,
  [enumRecorderStatus.ALERT]: MARKWE_ALERT,
  [enumRecorderStatus.CRITICAL]: MARKWE_CRITICAL,
};

export const getMarker = (statusStr: RecorderStatus): string => {
  return marker[enumRecorderStatus[statusStr]];
}