import * as s from "./ModalHeaderStyled";
import CLOSE_ICON from "assets/icons/sidenav/x.svg";

type ModalHeaderProps = {
  onModal: () => void;
  disableClose?: boolean;
};

function ModalHeaderNoTitle({
  onModal,
}: ModalHeaderProps): JSX.Element {
  return (
    <s.HeaderContainerNoTitle>
      <s.TitleContainer></s.TitleContainer>
      <s.CloseButton onClick={onModal}>
        <img alt="close button" src={CLOSE_ICON} />
      </s.CloseButton>
    </s.HeaderContainerNoTitle>
  );
}

export default ModalHeaderNoTitle;
