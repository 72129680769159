import { getUserDetail, getUserDetailAccount } from "api/userAPI";
import { CustomError } from "api/interfaces/commonInterface.interface";
import {
  LEVEL_TYPE,
  ProfileAccountInfoFromServer,
} from "api/interfaces/accountInterface.interface";
import { notify } from "components/atoms/notification/Notification";
import { isHiddenGroup } from "./AccountUtil";
import { NavigateFunction } from "react-router-dom";
import { setAccountSetting } from "redux/reducers/account/accountSlice";
import { setBreadcrumbRoute } from "redux/reducers/breadcrumb/breadcrumb";
import { menuNavigation } from "./MenuUtil";
import { IntlShape } from "react-intl";
import { datadogRum } from "@datadog/browser-rum";
import { LoginResponse } from "api/interfaces/authInterface.interface";
import { ACCESS_TOKEN, AxiosInstance, REFRESH_TOKEN } from "api/axiosInstance";
import { setCookie } from "cookies/cookie";
import { getCookieExpireTime } from "./timeUtil";
import { UserInfo } from "api/interfaces/userInterface.interface";

interface NavigateSwitchProp {
  selectAccount: ProfileAccountInfoFromServer;
  auth: any;
  navigate: NavigateFunction;
  dispatch: any;
  intl: IntlShape;
}

export const setUserInfo = (
  auth: any,
  accountId?: string,
  toSearchParam?: URLSearchParams
) => {
  if (toSearchParam && toSearchParam.get("accountId")) {
    return getUserDetailAccount(toSearchParam.get("accountId") as string)
      .then((response) => {
        auth.login(response);
        return response;
      })
      .catch(function (err: CustomError) {
        return err;
      });
  }

  if (accountId) {
    return getUserDetailAccount(accountId)
      .then((response) => {
        auth.login(response);
        return response;
      })
      .catch(function (err: CustomError) {
        return err;
      });
  }

  return getUserDetail()
    .then((response) => {
      auth.login(response);
      return response;
    })
    .catch(function (err: CustomError) {
      return err;
    });
};

// account 변경 시 페이지 이동 공통 함수
export const navigateSwitchAccount = ({
  selectAccount,
  auth,
  navigate,
  dispatch,
  intl,
}: NavigateSwitchProp) => {
  // console.log(selectAccount, auth);
  if (selectAccount.userPermission === undefined) {
    notify(
      "warning",
      intl.formatMessage({
        id: "validateMsg.account.permissionsDeny",
        defaultMessage:
          "The organization needs permissions. Please contact your administrator.",
      })
    );
    return;
  }

  if (
    !auth.user.verify2fa &&
    selectAccount.use2faAuth &&
    !isHiddenGroup(selectAccount.userPermission)
  ) {
    navigate(`/verify/2fa`, {
      state: {
        isAuth: true,
        selectAccount: selectAccount,
        verifyType: auth.user.use2faCode,
        loginInfo: {
          email: auth.user.email,
          phone: auth.user.phone,
        },
      },
    });
    return;
  }

  dispatch(setAccountSetting(selectAccount));

  if (
    selectAccount.accountLevel === LEVEL_TYPE.CP ||
    selectAccount.accountLevel === LEVEL_TYPE.DW
  ) {
    menuNavigation(selectAccount, navigate, `/account`);
    return;
  }

  const parentBread = [...(selectAccount.parentAccount || [])]
    .reverse()
    .map((link) => {
      return {
        name: link.accountName,
        routeName: "/account",
        accountId: link.accountId,
      };
    });

  const recorderListBread = {
    name: selectAccount.accountName,
    routeName: "/recorder/list",
    accountId: selectAccount.accountId,
  };

  const breadCrumb = [...parentBread, recorderListBread];
  dispatch(setBreadcrumbRoute(breadCrumb));
  menuNavigation(selectAccount, navigate, `/recorders/list`);
};

export const onSuccessLogin = (
  auth: any,
  email: string,
  loginData: LoginResponse,
  onError: () => void,
  toSearchParam?: URLSearchParams
) => {
  datadogRum.setUser({
    email,
  });

  // 2fa 필요 없을 때 로그인
  setCookie({
    name: ACCESS_TOKEN,
    value: loginData.accessToken,
    options: {
      path: "/",
      expires: getCookieExpireTime(6),
    },
  });
  setCookie({
    name: REFRESH_TOKEN,
    value: loginData.refreshToken,
    options: {
      path: "/",
      expires: getCookieExpireTime(72),
    },
  });
  AxiosInstance.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${loginData.accessToken}`;

  setUserInfo(auth, loginData.accountId, toSearchParam)
    .then(function (resolvedData) {
      const accountData = resolvedData as UserInfo;
      //BUG [RND-330] email link
      if (accountData !== undefined && accountData.isAccount !== 0) {
        if (
          toSearchParam &&
          toSearchParam.size > 0 &&
          toSearchParam.get("url") !== null
        ) {
          auth.processLinkNavigate(
            toSearchParam.get("url") as string,
            toSearchParam,
            accountData
          );
        } else {
          auth.loginNavigator("/", accountData);
        }
      } else {
        console.error("Account information bot found");
      }
    })
    .catch(function (err) {
      onError();
    });
};
