import ModalTemplate from "components/templates/modal/ModalTemplate";
import AlertViewPage from "pages/alert/view";
import { AlertModal } from "pages/alert/view/AlertViewStyled";

export interface AlertViewModalProps {
  searchParams: URLSearchParams;
  onModal: () => void;
}

const AlertViewModalContainer = (props: AlertViewModalProps) => {
  return (
    <ModalTemplate>
      <AlertModal>
        <AlertViewPage isModal {...props} />
      </AlertModal>
    </ModalTemplate>
  );
};
export default AlertViewModalContainer;
