import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import * as s from "styles/ModalCommonStyled";
import BigPopupTemplate from "components/templates/modal/big/BigPopupTemplate";
import { ModalInput } from "components/atoms/input/modal/ModalInput";
import Input from "components/atoms/input/Input";
import TextArea from "components/atoms/input/TextArea";
import BigButton from "components/atoms/buttons/BigButton";
import StepperComponent from "components/blocks/stepper/Stepper";
import { ValidateWord } from "components/atoms/text/Text";
import { SearchUsers } from "components/element/SearchUsers";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { SearchRecorder } from "components/element/SearchRecorder";
import {
  Group,
  UserGroupRecorderTypePermission,
  UserGroupUserPermission,
  UserInfo,
} from "api/interfaces/userInterface.interface";
import { useMutation } from "react-query";
import { notify } from "components/atoms/notification/Notification";
import {
  createGroup,
  deleteGroup,
  getUserDetailAccount,
  updateGroup,
} from "api/userAPI";
import { SearchAccount } from "components/element/SearchAccount";
import { useIntl } from "react-intl";
import { checkWhiteSpaceRegEx } from "utils/regEx";
import {
  CustomError,
  LEVEL_TYPE,
  Validate,
  ValidateMsg,
} from "api/interfaces/commonInterface.interface";
import { checkRecorderPermission, getAccountInfo } from "utils/AccountUtil";
import { setAccountSetting } from "redux/reducers/account/accountSlice";
import { useAuth } from "components/Auth";
import useApiError from "hook/useApiError";
import DeleteConfirmModal from "components/templates/modal/small/confirm/delete/DeleteConfirmModal";
import RecorderPermissionStep from "./steps/RecorderPermission";
import DwPermissionStep from "./steps/DwPermission";
import {
  RecorderGroupListResponse,
  RecorderGroupSystem,
  RecorderTotalList,
  RecorderTotalListResposne,
} from "api/interfaces/recorderInterface.interface";
import {
  getRecorderGroupTotalList,
  getRecorderTotalList,
} from "api/recorderAPI";
import {
  allDisabledUserPermission,
  defaultGroupName,
  defaultRoleCheckList,
  defaultCheckedUserPermission,
  checkUserPermission,
  checkAutoApply,
  defaultDisabledUserPermission,
  invertPermissionBooleans,
} from "./PermissionPolicy";

interface Props {
  isEdit?: boolean; // 편집 모드일 때
  onModal: (type?: string) => void;
  onClickSave: (data: any) => void;
  selectedEdit?: Group;
}

export enum UPDATE_TYPE {
  USERS,
  USER_PERMISSION,
  ACCOUNT,
  AUTO_APPLY,
  AUTO_APPLY_ACCOUNT,
  RECORDERS,
  RECORDER_GROUPS,
  RECORDER_LEVEL,
  RECORDER_PERMISSION,
  NAME,
  DESC,
}

const userGroupEuSteps = [
  {
    label: "Users",
  },
  {
    label: "myDW Permissions",
  },
  {
    label: "Recorders",
  },
  {
    label: "Recorder Permissions",
  },
  {
    label: "Preferences",
  },
];

const userGroupSteps = [
  {
    label: "Users",
  },
  {
    label: "myDW Permissions",
  },
  {
    label: "Organizations",
  },
  {
    label: "Preferences",
  },
];

const formatLevelCheckList = (value: string) => {
  if (!value) {
    return { ...defaultRoleCheckList.custom };
  }
  return {
    ...defaultRoleCheckList[
      value.toLowerCase() as keyof typeof defaultRoleCheckList
    ],
  };
};

interface RecorderGroupList extends RecorderGroupSystem {
  recorderGroupId: number;
}

function AddUser(props: Props): JSX.Element {
  const dispatch = useAppDispatch();

  const [page, setPage] = useState<number>(0);
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const isAdminGroup = selectedAccount.isAdminGroup;

  // console.log(selectedAccount.userPermission);

  const initialUserGroupData: Group = {
    users: [],
    accounts: [],
    userPermission: {
      ...selectedAccount.userPermission,
    },
    userGroupId: "",
    systems: [],
    recorderGroups: [],
    recorderPermission: {
      recorderPermissionLevel: "Custom",
      ...defaultRoleCheckList.custom.check,
    },
    name: "",
    hostEmail: "",
    description: "",
    userId: undefined,
    ...checkAutoApply({
      selectedAccount: selectedAccount,
      enableAllPermissions: selectedAccount.userPermission.enableAllPermissions,
    }),
  };
  const { handleError } = useApiError();
  const auth = useAuth();

  const [userGroupValidate, setUserGroupValidate] = useState<Validate>({
    isUserGroupName: false,
    isUserPermission: false,
    isUsers: false,
  });

  const [userGroupValidateMsg, setUserGroupValidateMsg] = useState<ValidateMsg>(
    {
      isUserGroupName: "",
      isUserPermission: "",
      isUsers: "",
    }
  );

  const [isDeleteConfirm, setIsDeleteConfirm] = useState<boolean>(false);

  const intl = useIntl();

  const [userGroup, setUserGroup] = useState<Group>({
    ...initialUserGroupData,
  });

  const applyPage =
    selectedAccount.accountLevel === "EU" ? userGroupEuSteps : userGroupSteps;

  const [myDwDisabledList, setMyDwDisabledList] =
    useState<UserGroupUserPermission>({
      ...defaultDisabledUserPermission(selectedAccount),
    });
  const [recorderRolepermissionDisabled, setRecorderRolePermissionDisabled] =
    useState<boolean>(false);

  const [recorderPermissionDisabledList, setRecorderPermissionDisableList] =
    useState<UserGroupRecorderTypePermission>({
      ...defaultRoleCheckList.custom.disable,
    });

  const [isEditableUser, setIsEditableUser] = useState<boolean>(
    props.isEdit ? false : true
  );

  const [selectedRecorderTypes, setSelectedRecorderTypes] = useState<string[]>(
    []
  );

  const [recorderList, setRecorderList] = useState<{
    recorders: RecorderTotalList[];
    recorderGroups: RecorderGroupList[];
  }>({ recorders: [], recorderGroups: [] });

  const getRecorderList = useQuery(
    ["recorder", selectedAccount.accountId, auth.user.userId],
    () =>
      getRecorderTotalList({
        accountId: selectedAccount.accountId,
        userId: auth.user.userId,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: RecorderTotalListResposne) => {
        if (!res.result) {
          return;
        }
        setRecorderList((list) => {
          return { ...list, recorders: res.result };
        });
      },
      onError: (e: any) => {},
    }
  );

  const getRecorderGroup = useQuery(
    ["recorderGroup", selectedAccount.accountId, auth.user.userId],
    () =>
      getRecorderGroupTotalList({
        accountId: selectedAccount.accountId,
        userId: auth.user.userId,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: RecorderGroupListResponse) => {
        if (!res.result) {
          return;
        }

        setRecorderList((list) => {
          return {
            ...list,
            recorderGroups: res.result.flatMap(
              (fil) =>
                fil.systems?.map((sys) => {
                  return {
                    ...sys,
                    recorderGroupId: fil.recorderGroupId,
                  };
                }) || []
            ),
          };
        });
      },
      onError: (e: any) => {},
    }
  );

  useEffect(() => {
    if (props.isEdit && props.selectedEdit) {
      // console.log(props.selectedEdit);
      const enableEditRule = props.selectedEdit.enableDelete; //default rule 이 아닐 때를 의미
      const isHostUser = props.selectedEdit.hostEmail === auth.user.email; // usergroup 만든 host

      // console.log(
      //   enableEditRule,
      //   isAdminGroup,
      //   isEnableAllPermissionUser,
      //   isHostUser,
      //   props.selectedEdit
      // );

      if (enableEditRule) {
        if (isAdminGroup || isHostUser) {
          setIsEditableUser(true);
        } else {
          setIsEditableUser(false);
          setMyDwDisabledList({
            ...allDisabledUserPermission,
          });
          setRecorderPermissionDisableList({
            ...defaultRoleCheckList.administrator.disable,
          });
        }
      } else {
        setIsEditableUser(false);
        setMyDwDisabledList({
          ...allDisabledUserPermission,
        });
        setRecorderPermissionDisableList({
          ...defaultRoleCheckList.administrator.disable,
        });
      }

      if (
        selectedAccount.accountLevel === LEVEL_TYPE.EU &&
        (!isAdminGroup ||
          !enableEditRule ||
          props.selectedEdit.userPermission.enableAllPermissions)
      ) {
        setRecorderRolePermissionDisabled(true);
      }

      setUserGroup({
        ...checkAutoApply({
          selectedAccount: selectedAccount,
          enableAllPermissions:
            props.selectedEdit.userPermission.enableAllPermissions,
        }),
        ...(props.selectedEdit as Group),
        users: props.selectedEdit.users ?? [],
        accounts: props.selectedEdit.accounts ?? [],
        systems: props.selectedEdit.systems ?? [],
        recorderGroups: props.selectedEdit.recorderGroups ?? [],
      });
      return;
    }

    // user 권한이 admin이 아니면 role custom disabled
    if (selectedAccount.accountLevel === "EU") {
      setRecorderRolePermissionDisabled(!isAdminGroup);
    }

    setUserGroup((info) => {
      const recorderPermission = isAdminGroup
        ? checkRecorderPermission(selectedAccount.recorderPermission)
        : {
            recorderPermissionLevel: "Custom",
            ...defaultRoleCheckList.custom.check,
          };
      return {
        ...info,
        ...initialUserGroupData,
        ...checkAutoApply({
          selectedAccount: selectedAccount,
          enableAllPermissions:
            selectedAccount.userPermission.enableAllPermissions,
        }),
        ...(selectedAccount.accountLevel === LEVEL_TYPE.EU &&
          recorderPermission),
      };
    });
  }, [props.isEdit, props.selectedEdit, selectedAccount]);

  useEffect(() => {
    //"Modify Bookmarks" depends on "View Bookmarks" checkbox​
    const spectrumPermission = userGroup.recorderPermission.spectrum;

    if (userGroup.recorderPermission.recorderPermissionLevel === "Custom") {
      setRecorderPermissionDisableList((disable) => {
        return {
          ...disable,
          spectrum: {
            ...disable.spectrum,
            modifyBookmark: !spectrumPermission.viewBookmark,
          },
        };
      });

      if (!spectrumPermission.viewBookmark) {
        setUserGroup((info) => {
          return {
            ...info,
            recorderPermission: {
              ...info.recorderPermission,
              spectrum: {
                ...info.recorderPermission.spectrum,
                modifyBookmark: false,
              },
            },
          };
        });
      }
    }
  }, [userGroup.recorderPermission.spectrum.viewBookmark]);

  useEffect(() => {
    if (isEditableUser) {
      setRecorderPermissionDisableList({
        ...formatLevelCheckList(
          userGroup.recorderPermission.recorderPermissionLevel
        ).disable,
      });
    }
  }, [isEditableUser, userGroup.recorderPermission.recorderPermissionLevel]);

  useEffect(() => {
    const getUniqueBrands = (items: Array<{ brand: string }>) => [
      ...new Set(items.map((item) => item.brand)),
    ];

    const filteredRecorders = recorderList.recorders.filter((rec) =>
      userGroup.systems.includes(rec.mergedSystemId)
    );

    const filteredRecorderGroups = recorderList.recorderGroups.filter((rec) =>
      userGroup.recorderGroups.includes(rec.recorderGroupId)
    );

    const tempSelectedTypes = getUniqueBrands([
      ...filteredRecorders,
      ...filteredRecorderGroups,
    ]);

    const allRecorderTypes = getUniqueBrands([
      ...recorderList.recorders,
      ...recorderList.recorderGroups,
    ]);

    // console.log(
    //   filteredRecorderGroups,
    //   filteredRecorders,
    //   tempSelectedTypes,
    //   allRecorderTypes,
    //   userGroup.autoApplyRecorder
    // );
    if (userGroup.autoApplyRecorder) {
      setSelectedRecorderTypes(allRecorderTypes);
    } else {
      setSelectedRecorderTypes(tempSelectedTypes);
    }
  }, [
    recorderList,
    userGroup.autoApplyRecorder,
    userGroup.systems,
    userGroup.recorderGroups,
  ]);

  const onChangePage = (name: string, index?: number) => {
    if (name === "next") {
      setPage(page + 1);
    }
    if (name === "previous") {
      setPage(page - 1);
    }
    if (name === "save") {
      props.onModal();
    }
    if (name === "step" && index !== undefined) {
      setPage(index);
    }
  };

  const mutationCreateUserGroup = useMutation(createGroup, {
    onSuccess: (res: any) => {
      if (res.error !== 0) {
        notify(
          "error",
          intl.formatMessage({
            id: "label.user.group.notify.add.fail",
            defaultMessage: "Failed to add User group.",
          })
        );
      } else {
        notify(
          "success",
          intl.formatMessage({
            id: "label.user.group.notify.add.success",
            defaultMessage: "User group added successfully.",
          })
        );
        getAccountInfo(selectedAccount.accountId).then((res: any) => {
          dispatch(setAccountSetting(res));
        });
        props.onModal("edit");
      }
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.user.group.notify.add.fail",
          defaultMessage: "Failed to add User group.",
        })
      );
    },
  });

  const mutationUpdateUserGroup = useMutation(updateGroup, {
    onSuccess: (res: any) => {
      if (res.error !== 0) {
        notify(
          "error",
          intl.formatMessage({
            id: "label.user.group.notify.update.fail",
            defaultMessage: "Failed to update User group.",
          })
        );
      } else {
        notify(
          "success",
          intl.formatMessage({
            id: "label.user.group.notify.update.success",
            defaultMessage: "User group updated successfully.",
          })
        );
        getAccountInfo(selectedAccount.accountId).then((res: any) => {
          dispatch(setAccountSetting(res));
        });
        props.onModal("edit");
      }
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.user.group.notify.update.fail",
          defaultMessage: "Failed to update User group.",
        })
      );
    },
  });

  const onClickCreate = useCallback(() => {
    //setIsAddModal(true);

    if (checkWhiteSpaceRegEx(userGroup.name)) {
      setUserGroupValidate((info) => {
        return { ...info, isUserGroupName: true as boolean };
      });

      setUserGroupValidateMsg((info) => {
        return {
          ...info,
          isUserGroupName: intl.formatMessage({
            id: "validateMsg.userGroup.name",
            defaultMessage: "Please enter a user group name.",
          }) as string,
        };
      });
      return;
    }

    if (userGroup.enableDelete && defaultGroupName.includes(userGroup.name)) {
      setUserGroupValidate((info) => {
        return { ...info, isUserGroupName: true as boolean };
      });

      setUserGroupValidateMsg((info) => {
        return {
          ...info,
          isUserGroupName: intl.formatMessage({
            id: "validateMsg.userGroup.sameDefault",
            defaultMessage:
              "Administrator, Manager, and Viewer group names are not allowed as they already exist.",
          }) as string,
        };
      });
      return;
    }

    if (userGroup.name === "administrator" && userGroup.users.length === 0) {
      setUserGroupValidate((info) => {
        return { ...info, isUsers: true as boolean };
      });

      setUserGroupValidateMsg((info) => {
        return {
          ...info,
          isUsers: intl.formatMessage({
            id: "validateMsg.userGroup.emptyAdmin",
            defaultMessage: "Select one or more users.",
          }) as string,
        };
      });
      setPage(0);
      return;
    }

    if (props.isEdit) {
      mutationUpdateUserGroup.mutate({
        payload: userGroup,
        accountId: selectedAccount.accountId,
        groupId: userGroup.groupId as string,
      });
    } else {
      mutationCreateUserGroup.mutate({
        payload: userGroup,
        accountId: selectedAccount.accountId,
      });
    }
  }, [userGroup, mutationCreateUserGroup, selectedAccount.accountId]);

  const mutationDeleteUserGroup = useMutation(deleteGroup, {
    onSuccess: (res: any) => {
      if (res.error !== 0) {
        notify(
          "error",
          intl.formatMessage({
            id: "label.user.group.notify.delete.fail",
            defaultMessage: "Failed to delete User group.",
          })
        );
      } else {
        notify(
          "success",
          intl.formatMessage({
            id: "label.user.group.notify.delete.success",
            defaultMessage: "User group deleted successfully.",
          })
        );
        props.onModal("edit");
      }
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.userGroup.notify.delete.fail",
          defaultMessage: "Failed to delete user group.",
        })
      );
    },
  });

  const onClickDelete = useCallback(() => {
    setIsDeleteConfirm(false);
    mutationDeleteUserGroup.mutate({
      accountId: selectedAccount.accountId,
      groupId: userGroup.groupId as string,
    });
  }, [mutationDeleteUserGroup, selectedAccount.accountId, userGroup.groupId]);

  const onChangeRecorderLevel = (value: string) => {
    setUserGroup((info) => {
      return {
        ...info,
        recorderPermission: {
          recorderPermissionLevel: value,
          ...formatLevelCheckList(value).check,
        },
      };
    });
  };

  const onChangeDwPermission = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let {
        target: { name, checked },
      } = e;
      const permissionTitle = e.target.getAttribute("data-title-name");

      if (name === "enableAllPermissions" && checked) {
        setUserGroup((info) => {
          return {
            ...info,
            userPermission: {
              ...defaultCheckedUserPermission(
                selectedAccount.isAdminGroup,
                selectedAccount.checkedUserAdmin,
                props.isEdit
              )[selectedAccount.accountLevel],
            },
            ...checkAutoApply({
              selectedAccount: selectedAccount,
              enableAllPermissions: checked,
            }),
          };
        });
      }

      // manage 가 check === true 되면 view 도 자동으로 체크된다. (이 때 view 는 체크해제할 수 없음)
      if (permissionTitle === "organization") {
        if (name === "viewAccount" && userGroup.userPermission.manageAccount) {
          return;
        }
        if (name === "manageAccount") {
          setUserGroup((info) => {
            return {
              ...info,
              userPermission: {
                ...info.userPermission,
                viewAccount: checked,
              },
            };
          });
        }
      }
      if (permissionTitle === "user") {
        if (
          name === "viewUsersAndGroups" &&
          userGroup.userPermission.manageUsersAndGroups
        ) {
          return;
        }
        if (name === "manageUsersAndGroups") {
          setUserGroup((info) => {
            return {
              ...info,
              userPermission: {
                ...info.userPermission,
                viewUsersAndGroups: checked,
              },
            };
          });
        }
      }
      if (permissionTitle === "alert") {
        if (
          name === "viewAlerts" &&
          userGroup.userPermission.manageAlertRules
        ) {
          return;
        }
        if (name === "manageAlertRules") {
          setUserGroup((info) => {
            return {
              ...info,
              userPermission: {
                ...info.userPermission,
                viewAlerts: checked,
              },
            };
          });
        }
      }
      if (permissionTitle === "report") {
        if (
          name === "viewReports" &&
          userGroup.userPermission.manageReportRules
        ) {
          return;
        }
        if (name === "manageReportRules") {
          setUserGroup((info) => {
            return {
              ...info,
              userPermission: {
                ...info.userPermission,
                viewReports: checked,
              },
            };
          });
        }
      }
      if (permissionTitle === "recorders") {
        if (
          name === "viewRecordersAndGroups" &&
          userGroup.userPermission.manageRecordersAndGroups
        ) {
          return;
        }
        if (name === "manageRecordersAndGroups") {
          setUserGroup((info) => {
            return {
              ...info,
              userPermission: {
                ...info.userPermission,
                viewRecordersAndGroups: checked,
              },
            };
          });
        }
      }
      if (permissionTitle === "dwBilling") {
        if (name === "viewBilling" && userGroup.userPermission.managePlan) {
          return;
        }
        if (name === "managePlan") {
          setUserGroup((info) => {
            return {
              ...info,
              userPermission: {
                ...info.userPermission,
                viewBilling: checked,
              },
            };
          });
        }
      }

      setUserGroup((info) => {
        return {
          ...info,
          userPermission: {
            ...info.userPermission,
            [name]: checked,
          },
        };
      });
    },
    [userGroup.userPermission]
  );

  const onChangeRecorderPermission = useCallback(
    (type: "spectrum" | "vmax" | "cova", e: ChangeEvent<HTMLInputElement>) => {
      let {
        target: { name, checked },
      } = e;

      if (type === "spectrum") {
        /**
            ”View Bookmarks" depends on ” View Archive" checkbox​
            ”Export Archive" depends on ”View Archive" checkbox
         */
        if (name === "viewArchive") {
          setRecorderPermissionDisableList((disable) => {
            return {
              ...disable,
              spectrum: {
                ...disable.spectrum,
                viewBookmark: !checked,
                exportArchive: !checked,
              },
            };
          });

          if (!checked) {
            setUserGroup((info) => {
              return {
                ...info,
                recorderPermission: {
                  ...info.recorderPermission,
                  spectrum: {
                    ...info.recorderPermission.spectrum,
                    viewBookmark: false,
                    exportArchive: false,
                  },
                },
              };
            });
          }
        }
      }

      setUserGroup((info) => {
        return {
          ...info,
          recorderPermission: {
            ...info.recorderPermission,
            [type]: {
              ...info.recorderPermission[type],
              [name]: checked,
            },
          },
        };
      });
    },
    [userGroup]
  );

  const onChangeGroupInfo = useCallback(
    (
      type: UPDATE_TYPE,
      value: boolean | string | number | string[] | number[]
    ) => {
      if (type === UPDATE_TYPE.USERS) {
        setUserGroup((info) => {
          return { ...info, users: value as string[] };
        });
      } else if (type === UPDATE_TYPE.ACCOUNT) {
        setUserGroup((info) => {
          return { ...info, accounts: value as string[] };
        });
      } else if (type === UPDATE_TYPE.AUTO_APPLY) {
        if (value) {
          setUserGroup((info) => {
            return { ...info, systems: [] };
          });
        }
        setUserGroup((info) => {
          return { ...info, autoApplyRecorder: value as boolean };
        });
      } else if (type === UPDATE_TYPE.AUTO_APPLY_ACCOUNT) {
        if (value) {
          setUserGroup((info) => {
            return { ...info, accounts: [] };
          });
        }
        setUserGroup((info) => {
          return { ...info, autoApplyAccount: value as boolean };
        });
      } else if (type === UPDATE_TYPE.RECORDERS) {
        setUserGroup((info) => {
          return { ...info, systems: value as string[] };
        });
      } else if (type === UPDATE_TYPE.RECORDER_GROUPS) {
        setUserGroup((info) => {
          return { ...info, recorderGroups: value as number[] };
        });
      } else if (type === UPDATE_TYPE.NAME) {
        setUserGroup((info) => {
          return { ...info, name: value as string };
        });
      } else if (type === UPDATE_TYPE.DESC) {
        setUserGroup((info) => {
          return { ...info, description: value as string };
        });
      }
    },
    []
  );

  const isDisabledAutoApply = isEditableUser
    ? userGroup.userPermission.enableAllPermissions
    : true;

  const enableEditButton =
    props.isEdit &&
    (isEditableUser || (!userGroup.enableDelete && isAdminGroup));
  // 1 users
  const usersPage = (
    <ModalInput label="Add Users">
      <SearchUsers
        isEditUserGroup={props.isEdit}
        isTab={false}
        userGroupId={userGroup.groupId}
        accountId={selectedAccount.accountId}
        selectedUserId={userGroup.users}
        onSelectedRowsChangeUser={(value: string[]) => {
          onChangeGroupInfo(UPDATE_TYPE.USERS, value);
        }}
      />
      <ValidateWord>{userGroupValidateMsg.isUsers}</ValidateWord>
    </ModalInput>
  );
  const accountsPage = (
    <>
      <s.CheckInput>
        <input
          type="checkbox"
          defaultChecked={userGroup.autoApplyAccount}
          checked={userGroup.autoApplyAccount}
          disabled={isDisabledAutoApply}
          onChange={(e) =>
            onChangeGroupInfo(UPDATE_TYPE.AUTO_APPLY_ACCOUNT, e.target.checked)
          }
        />
        Apply to all current and future Organization.
      </s.CheckInput>
      <s.ModalTableContainer>
        {!(
          userGroup.userPermission.enableAllPermissions ||
          userGroup.autoApplyAccount
        ) && (
          <ModalInput label="Add Organization">
            <SearchAccount
              accountId={selectedAccount.accountId}
              selectedAccountId={userGroup.accounts}
              disabled={props.isEdit && !userGroup.enableDelete}
              allSelect={userGroup.userPermission.enableAllPermissions}
              onSelectedRowsChange={(value: string[]) => {
                onChangeGroupInfo(UPDATE_TYPE.ACCOUNT, value);
              }}
              userId={props.isEdit ? userGroup.userId : auth.user.userId}
            />
          </ModalInput>
        )}
      </s.ModalTableContainer>
    </>
  );
  // 3 Recorders
  const recordersPage = [
    <>
      <s.CheckInput>
        <input
          type="checkbox"
          defaultChecked={userGroup.autoApplyRecorder}
          checked={userGroup.autoApplyRecorder}
          onChange={(e) =>
            onChangeGroupInfo(UPDATE_TYPE.AUTO_APPLY, e.target.checked)
          }
          disabled={isDisabledAutoApply}
        />
        Apply to all current and future Recorders.
      </s.CheckInput>

      <s.ModalTableContainer>
        {!(
          userGroup.userPermission.enableAllPermissions ||
          userGroup.autoApplyRecorder
        ) && (
          <SearchRecorder
            isTab={true}
            accountId={selectedAccount.accountId}
            selectedRow={userGroup.systems}
            selectedGroupRow={userGroup.recorderGroups}
            onSelectedRowsChange={(value: string[]) =>
              onChangeGroupInfo(UPDATE_TYPE.RECORDERS, value)
            }
            onSelectedGroupRowsChange={(value: number[]) =>
              onChangeGroupInfo(UPDATE_TYPE.RECORDER_GROUPS, value)
            }
            userId={
              props.isEdit ? props.selectedEdit?.userId : auth.user.userId
            }
            isEditable={!(props.isEdit ? isEditableUser : true)}
          />
        )}
      </s.ModalTableContainer>
    </>,
  ];

  // 5 preferences
  const preferencesPage = [
    <>
      <ModalInput label="Group Name" essential>
        <Input
          placeholder="Enter Name"
          disabled={!isEditableUser}
          defaultValue={userGroup.name}
          onChange={(e) => onChangeGroupInfo(UPDATE_TYPE.NAME, e.target.value)}
          isValidated={userGroupValidate.isUserGroupName}
          onFocus={() => {
            setUserGroupValidate((info) => {
              return { ...info, isUserGroupName: false as boolean };
            });

            setUserGroupValidateMsg((info) => {
              return { ...info, isUserGroupName: "" };
            });
          }}
        />
        <ValidateWord>{userGroupValidateMsg.isUserGroupName}</ValidateWord>
      </ModalInput>
      <ModalInput label="Description">
        <TextArea
          placeholder="Enter description"
          disabled={!isEditableUser}
          defaultValue={userGroup.description}
          onChange={(e) => onChangeGroupInfo(UPDATE_TYPE.DESC, e.target.value)}
        />
      </ModalInput>
    </>,
  ];
  const renderPageContent = () => {
    switch (page) {
      case 0:
        return usersPage;
      case 1:
        return (
          <DwPermissionStep
            isEdit={props.isEdit}
            userCheckedList={userGroup.userPermission}
            myDwDisabledList={myDwDisabledList}
            onChangeDwPermission={onChangeDwPermission}
            validateMsg={userGroupValidateMsg.isUserPermission}
          />
        );
      case 2:
        return selectedAccount.accountLevel === "EU"
          ? recordersPage
          : accountsPage;
      case 3:
        return selectedAccount.accountLevel === "EU" ? (
          <RecorderPermissionStep
            recorderPermission={userGroup.recorderPermission}
            permissionDisabled={recorderRolepermissionDisabled}
            onChangeRecorderLevel={onChangeRecorderLevel}
            disableList={recorderPermissionDisabledList}
            onChangeRecorderPermission={onChangeRecorderPermission}
            selectedRecorderTypes={selectedRecorderTypes}
          />
        ) : (
          preferencesPage
        );
      case 4:
        return selectedAccount.accountLevel === "EU" && preferencesPage;
      default:
        return null;
    }
  };

  return (
    <BigPopupTemplate
      title={props.isEdit ? "Edit User Group" : "Add User Group"}
      onModal={props.onModal}
    >
      <StepperComponent
        activeStep={page}
        steps={applyPage}
        onClick={onChangePage}
      />
      <s.ModalInnerHalf>{renderPageContent()}</s.ModalInnerHalf>
      <s.ButtonWrapper>
        {enableEditButton ? (
          <BigButton
            buttonType="warning"
            label="Delete"
            onClickBtn={() => setIsDeleteConfirm(true)}
            delay
          />
        ) : null}
        {page !== 0 && (
          <BigButton
            buttonType="cancel"
            label="Previous"
            onClickBtn={() => onChangePage("previous")}
          />
        )}

        {page === applyPage.length - 1 ? (
          (!props.isEdit || enableEditButton) && (
            <BigButton label="Save" onClickBtn={onClickCreate} delay />
          )
        ) : (
          <BigButton
            buttonType="cancel"
            label="Next"
            onClickBtn={() => onChangePage("next")}
          />
        )}
      </s.ButtonWrapper>
      {props.isEdit && isDeleteConfirm && (
        <DeleteConfirmModal
          label={`group`}
          onDelete={() => setIsDeleteConfirm(false)}
          onConfirmDelete={onClickDelete}
        />
      )}
    </BigPopupTemplate>
  );
}

export default AddUser;
