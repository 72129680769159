import TinyButton from "components/atoms/buttons/TinyButton";
import { PiStopFill } from "react-icons/pi";
import { getMinSecStr } from "utils/timeUtil";

type ScreenRecorderRecordingType = {
  stop: () => void,
  durationInMs: number,
};

export function ScreenRecorderRecording({ stop, durationInMs }: ScreenRecorderRecordingType) {
  const durationStr = getMinSecStr(durationInMs);
  return (
    <TinyButton
      label={`Stop Recording | ${durationStr}`}
      onClickBtn={stop}
      buttonType="warning"
      icon={<PiStopFill />}
      />
  )
}
