import Input from "components/atoms/input/Input";
import TextArea from "antd/es/input/TextArea"
import { postAlertSendMessage } from "api/alertAPI"
import { ModalInput } from "components/atoms/input/modal/ModalInput"
import SmallPopupTemplate from "components/templates/modal/small/SmallPopupTemplate"
import { useCallback, useState } from "react"
import { Assignee } from "./AssigneeInfo"
import { useMutation } from "react-query";
import { notify } from "components/atoms/notification/Notification"
import { useIntl } from "react-intl"
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface"
import { useAppSelector } from "redux/hooks"
import useApiError from "hook/useApiError"
import { CustomError } from "api/interfaces/commonInterface.interface"

type SendEmailModalType = {
  assignee: Assignee;
  recorderId: string;
  onModal: () => void;
}

export const SendSMSModal = ({
  assignee,
  recorderId,
  onModal,
}: SendEmailModalType) => {
  const intl = useIntl();
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const { handleError } = useApiError();
  const [message, setMessage] = useState<string>('');
  const [error, setError] = useState({
    status: false,
    msg: "",
  });


  const mutationSendAlertMessage = useMutation(postAlertSendMessage, {
    onSuccess: () => {
      notify(
        "success",
        intl.formatMessage({
          id: "label.common.notify.message.success",
          defaultMessage: "Message sent successfully",
        })
      );
      onModal();
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.common.notify.message.fail",
          defaultMessage: "Message sending failed.",
        })
      );
    },
  });

  const onClickSendSms = useCallback(() => {
    if (!assignee || !assignee.phone) {
      return;
    }

    mutationSendAlertMessage.mutate({
      accountId: selectedAccount.accountId,
      recorderId: recorderId,
      payload: {
        phoneNumber: assignee.phone,
        message: message
      },
    });
  }, [
    selectedAccount.accountId,
    recorderId,
    assignee,
    message
  ]);

  return (
    <SmallPopupTemplate
      cancel
      title="Send a Text Message"
      subTitle="Send a message to your technician"
      onClick={() => onClickSendSms()}
      onModal={onModal}
      confirmLabel="Send"
    >
      <ModalInput label="Phone Number" essential>
        <Input
          placeholder="Enter a phone number"
          defaultValue={assignee.phone}
          disabled={true}
        />
      </ModalInput>
      <ModalInput label="Message" essential>
        <TextArea
          placeholder="Enter your message here"
          value={message}
          onChange={((e) => {
            setMessage(e.target.value);
          })}
        />
      </ModalInput>
    </SmallPopupTemplate>
  )
}