import {
  GetVideoShareViewUSerListResponse,
  VideoShareUserListResult,
} from "api/interfaces/videoshareInterface.interface";
import TableButton from "components/atoms/buttons/TableButton";
import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import { useQuery } from "react-query";
import { ReactComponent as ADD_USER_ICON } from "assets/icons/add_user.svg";
import { getVideoShareViewUserListByVideoId } from "api/videoshareAPI";
import EditModal from "./edit";
import AddModal from "./add";
import {
  BasePagination,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import { useAppSelector } from "redux/hooks";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { getAccountTimeFormat, getAccountTimeFormatDate } from "utils/timeUtil";
import { TableColumnsType } from "antd";
import {
  changeSortOrderAntd,
  CommonTable,
  onSortAntd,
} from "components/atoms/table/AntdTable";
import { ColumnType } from "antd/es/table";
import { ImUserPlus } from "react-icons/im";
import { useSearchParams } from "react-router-dom";

type Props = {
  videoId: string;
  isDisabledAdd: boolean;
};

const ViewVSUserList = forwardRef(
  (props: Props, ref: ForwardedRef<any>): JSX.Element => {
    const [userList, setUserList] = useState<VideoShareUserListResult[]>([]);
    const [editUser, setEditUser] = useState<VideoShareUserListResult>();
    const selectedAccount: ProfileAccountInfo = useAppSelector(
      (state) => state.accountSettings
    );
    const [videoUserListQueryInfo, setVideoUserListQueryInfo] =
      useState<BasePagination>({
        total: 0,
        pageNum: 0,
        pageLimit: 10,
        keyword: "",
        sortType: "name",
        sortDirection: SORT_DIRECTION.ASC,
      });
    const [searchParams] = useSearchParams();
    const [isAdd, setIsAdd] = useState<boolean>(() => {
      if (!!searchParams.get("share") && !!searchParams.get("sharelabel")) {
        return true;
      }
      return false;
    });
    //edit participant
    const [isEdit, setIsEdit] = useState<boolean>(false);

    useImperativeHandle(ref, () => ({
      openSharePopup() {
        onChangeModal("add", true);
      },
    }));

    const onRowClicked = (row: VideoShareUserListResult) => {
      setEditUser(row);
      onChangeModal("edit", true);
    };
    const onHeaderCell = (column: ColumnType<VideoShareUserListResult>) => {
      return {
        onClick: () => {
          if (column.key) {
            setVideoUserListQueryInfo((info) => {
              return {
                ...info,
                sortType: column.key as string,
                sortDirection: onSortAntd(info.sortDirection),
              };
            });
          }
        },
      };
    };
    const columns: TableColumnsType<VideoShareUserListResult> = [
      {
        title: "Name",
        sorter: true,
        sortOrder: changeSortOrderAntd(videoUserListQueryInfo, "name"),
        defaultSortOrder: "ascend",
        onHeaderCell: onHeaderCell,
        key: "name",
        render: (value, row) => row.name,
      },
      {
        title: "Email",
        sorter: true,
        key: "email",
        sortOrder: changeSortOrderAntd(videoUserListQueryInfo, "email"),
        onHeaderCell: onHeaderCell,
        render: (value, row) => row.email,
        responsive: ["md"],
      },
      {
        title: "Shared Date",
        // sorter: true,
        // key: "share_date",
        render: (value, row) =>
          getAccountTimeFormat(row.shareDate, selectedAccount, true),
        responsive: ["md"],
      },
      {
        title: "Share Expiration Date",
        // sorter: true,
        // key: "expire",
        render: (value, row) =>
          row.expires !== undefined
            ? getAccountTimeFormatDate(row.expires, selectedAccount, true)
            : "None",
      },
      {
        title: "Allow Download",
        render: (value, row) => (row.canDownload ? "Yes" : "No"),
        align: "center",
        responsive: ["md"],
      },
      {
        title: (
          <div className="table-button-wrapper">
            <TableButton
              label="Add"
              buttonType="primary"
              icon={<ADD_USER_ICON />}
              disabled={!props.isDisabledAdd}
              onClick={() => {
                props.isDisabledAdd && onChangeModal("add", true);
              }}
            />
          </div>
        ),
        align: "right",
        width: 150,
        render: (value, row) => (
          <span className="row-click-button table-button-wrapper">
            <TableButton label="Edit" onClick={() => onRowClicked(row)} />
          </span>
        ),
      },
    ];

    const onChangeModal = (type: string, value: boolean) => {
      if (type === "edit") {
        setIsEdit(value);
      }
      if (type === "add") {
        setIsAdd(value);
      }
    };

    const getVideoShareUserList = useQuery(
      ["videoshareViewUserList", videoUserListQueryInfo],
      () =>
        getVideoShareViewUserListByVideoId({
          videoId: props.videoId,
          payload: videoUserListQueryInfo,
        }),
      {
        retry: 0,
        refetchOnWindowFocus: false,
        onSuccess: (res: GetVideoShareViewUSerListResponse) => {
          if (res.error !== 0 || res.result === undefined) {
            setUserList([]);
            setVideoUserListQueryInfo((info) => {
              return { ...info, total: 0, pageNum: 0 };
            });
            return;
          }
          setUserList(res.result);
          setVideoUserListQueryInfo((info) => {
            return {
              ...info,
              total: res.page.total,
              pageNum: res.page.pageNum as number,
            };
          });
        },
        onError: (e: any) => {
          setUserList([]);
          setVideoUserListQueryInfo((info) => {
            return { ...info, total: 0, pageNum: 0 };
          });
        },
      }
    );

    const onChangePage = useCallback(
      (page: number, totalRows: number) => {
        if (page !== videoUserListQueryInfo.pageNum + 1) {
          setVideoUserListQueryInfo((info) => {
            return { ...info, pageNum: (page - 1) as number };
          });
        }
      },
      [videoUserListQueryInfo.pageNum]
    );

    return (
      <>
        <CommonTable
          columns={columns}
          dataSource={userList}
          queryInfo={videoUserListQueryInfo}
          onChangePage={onChangePage}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                onRowClicked(record);
              },
            };
          }}
          loading={getVideoShareUserList.isFetching}
        />

        {isAdd && (
          <AddModal
            isShare={false}
            index={1}
            videoId={props.videoId}
            onChangeModal={() => onChangeModal("add", false)}
          />
        )}
        {isEdit && editUser !== undefined && (
          <EditModal
            videoId={props.videoId}
            state={editUser}
            onChangeModal={() => onChangeModal("edit", false)}
          />
        )}
      </>
    );
  }
);

export default ViewVSUserList;
