import Type from "components/atoms/text/labels/Type";
import InventoryPresenter from "./InventoryPresenter";
import {
  ReportInventoryCameraRow,
  ReportInventoryRecorderRow,
} from "api/interfaces/reportInterface.interface";
import { ReportDataRow } from "api/interfaces/reportInterface.interface";
import { useCallback, useMemo, useState } from "react";
import { Text } from "components/atoms/text/Text";
import * as mediaQuery from "components/MediaQuery";
import { InforamtionTitle } from "./InventoryStyled";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { useQuery } from "react-query";
import {
  getInventoryCamera,
  getInventoryRecorder,
  getReportInfo,
} from "api/reportAPI";
import {
  BasePagination,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import { convertType, formatBytesFromMB } from "utils/functions";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  LEVEL_TYPE,
  ProfileAccountInfo,
} from "api/interfaces/accountInterface.interface";
import { useIntl } from "react-intl";
import { excelDateFormat, getAccountTimeFormat } from "utils/timeUtil";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { notify } from "components/atoms/notification/Notification";
import RecorderTitle from "components/blocks/recorder/RecorderTitle";
import { recorderTitleString } from "utils/RecorderUtil";
import qs from "qs";
import { AccountBox } from "pages/alert/AlertStyled";
import AccountId from "components/atoms/text/labels/AccountId";
import { getSwitchAccountInfo } from "utils/AccountUtil";
import { useAuth } from "components/Auth";
import { navigateSwitchAccount } from "utils/AuthUtil";
import { TableColumnsType } from "antd";
import { ColumnType } from "antd/es/table";
import {
  changeSortOrderAntd,
  onSortAntd,
} from "components/atoms/table/AntdTable";

type Props = {
  state: ReportDataRow | undefined;
};

export interface TotalCountInterface {
  [key: string]: number;
}

const initialRecorderQueryinfo = {
  total: 0,
  pageNum: 0,
  pageLimit: 20,
  keyword: "",
  sortType: "installedDate",
  sortDirection: SORT_DIRECTION.DESC,
};
const initialCameraQueryinfo = {
  total: 0,
  pageNum: 0,
  pageLimit: 20,
  keyword: "",
  sortType: "mergedSystemName",
  sortDirection: SORT_DIRECTION.ASC,
};

export default function InventoryContainer(props: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const intl = useIntl();
  const auth = useAuth();
  const location = useLocation();
  const params = useParams();
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const paginationPerPage = 20;
  // const navigate = useNavigate();

  const [currentMenu, setCurrentMenu] = useState(0);

  const [recorderData, setRecorderData] = useState<
    ReportInventoryRecorderRow[]
  >([]);
  const [cameraData, setCameraData] = useState<ReportInventoryCameraRow[]>([]);
  const [totalDataCount, setTotalDataCount] = useState<TotalCountInterface>({
    recorder: 0,
    camera: 0,
  });

  const { account } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    decoder: (s) => decodeURIComponent(s),
  });
  const reportId = params.reportId as string;
  const [reportTitle, setReportTitle] = useState<string>("");

  const [isExportEmail, setIsExportEmail] = useState<boolean>(false);

  const onChange = (index: number) => {
    setCurrentMenu(index);
  };

  const onClickAccount = (accountId: string) => {
    getSwitchAccountInfo(accountId).then(function (selectAccount) {
      if (!!selectAccount) {
        navigateSwitchAccount({
          selectAccount,
          auth,
          navigate,
          dispatch,
          intl,
        });
      }
    });
  };
  const [recorderQueryInfo, setRecorderQueryInfo] = useState<BasePagination>({
    ...initialRecorderQueryinfo,
  });

  const [cameraQueryInfo, setCameraQueryInfo] = useState<BasePagination>({
    ...initialCameraQueryinfo,
  });

  const onHeaderCellRecorder = useCallback(
    (column: ColumnType<ReportInventoryRecorderRow>) => {
      return {
        onClick: () => {
          if (column.key) {
            setRecorderQueryInfo((info) => {
              return {
                ...info,
                sortType: column.key as string,
                sortDirection: onSortAntd(info.sortDirection),
              };
            });
          }
        },
      };
    },
    [recorderQueryInfo]
  );

  const onHeaderCellCamera = useCallback(
    (column: ColumnType<ReportInventoryCameraRow>) => {
      return {
        onClick: () => {
          if (column.key) {
            setCameraQueryInfo((info) => {
              return {
                ...info,
                sortType: column.key as string,
                sortDirection: onSortAntd(info.sortDirection),
              };
            });
          }
        },
      };
    },
    [setCameraQueryInfo]
  );

  // table recorderColumns column currentMenu === 0 일때
  const recorderColumns: TableColumnsType<ReportInventoryRecorderRow> = [
    {
      title: "Type",
      width: 120,
      render: (value, row) => <Type type={convertType(row)} />,
    },
    {
      title: "Title",
      key: "name",
      sorter: true,
      sortOrder: changeSortOrderAntd(recorderQueryInfo, "name"),
      onHeaderCell: onHeaderCellRecorder,
      defaultSortOrder: "descend",
      render: (value, row) => (
        <InforamtionTitle>
          {/* <Text className="information-title">{row.title}</Text> */}
          <RecorderTitle
            mergedSystemName={row.mergedSystemName}
            recorderName={row.title}
            type={row.type}
          />
          <mediaQuery.Default>
            <Text fontSize={12} color="#828B9B">
              [{row.firmwareVersion}]
            </Text>
          </mediaQuery.Default>
        </InforamtionTitle>
      ),
    },
    {
      title: "Organization",
      render: (value, row) => (
        <AccountBox onClick={() => onClickAccount(row.accountId)}>
          {row.accountName}
          <AccountId accountId={row.accountNumber} level={LEVEL_TYPE.EU} />
        </AccountBox>
      ),
      responsive: ["md"],
    },
    {
      title: "Model",
      render: (value, row) => row.model,
    },
    {
      title: "MAC Address",
      render: (value, row) => (row.macAddr ? row.macAddr.toUpperCase() : "N/A"),
    },
    {
      title: "Cameras",
      align: "center",
      render: (value, row) => row.cameraCount,
    },
    {
      title: "Installer",
      render: (value, row) => row.installerName,
      responsive: ["md"],
    },
    {
      key: "installedDate",
      title: "Registered Date",
      sorter: true,
      sortOrder: changeSortOrderAntd(recorderQueryInfo, "installedDate"),
      onHeaderCell: onHeaderCellRecorder,
      //BUG [RND-434] Date Format change
      render: (value, row) =>
        getAccountTimeFormat(row.installedDate, selectedAccount, true),
      responsive: ["md"],
    },
    {
      title: "Location",
      sorter: true,
      sortOrder: changeSortOrderAntd(recorderQueryInfo, "location"),
      onHeaderCell: onHeaderCellRecorder,
      key: "location",
      render: (value, row) => (row.location ? row.location : "Unknown"),
      responsive: ["md"],
    },
  ];

  // table recorderColumns column currentMenu === 0 일때
  const cameraColumns: TableColumnsType<ReportInventoryCameraRow> = [
    {
      title: "Recorder",
      sorter: true,
      sortOrder: changeSortOrderAntd(cameraQueryInfo, "mergedSystemName"),
      onHeaderCell: onHeaderCellCamera,
      defaultSortOrder: "ascend",
      key: "mergedSystemName",
      render: (value, row) => (
        <RecorderTitle
          mergedSystemName={row.mergedSystemName}
          recorderName={row.recorderName}
        />
      ),
    },
    {
      title: "Camera",
      sorter: true,
      sortOrder: changeSortOrderAntd(cameraQueryInfo, "name"),
      onHeaderCell: onHeaderCellCamera,
      key: "name",
      render: (value, row) => row.name,
    },
    {
      title: "Organization",
      render: (value, row) => (
        <AccountBox onClick={() => onClickAccount(row.accountId)}>
          {row.accountName}
          <AccountId accountId={row.accountNumber} level={LEVEL_TYPE.EU} />
        </AccountBox>
      ),
      responsive: ["md"],
    },
    {
      title: "Manufacturer",
      render: (value, row) => row.manufacturer,
      responsive: ["md"],
    },
    {
      title: "Model",
      render: (value, row) => row.model,
      responsive: ["md"],
    },
    {
      title: "MAC address",
      render: (value, row) => (row.macAddr ? row.macAddr : "N/A"),
    },
    {
      title: "Storage",
      render: (value, row) => formatBytesFromMB(row.totalStorage),
      responsive: ["md"],
    },
    {
      title: "Resolution",
      render: (value, row) => row.resolution,
      responsive: ["md"],
    },
  ];

  const reportDetailInfo = useQuery(
    ["reportDetailInfo", reportId],
    () =>
      getReportInfo({
        accountId: selectedAccount.accountId,
        reportId: reportId,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res.result !== undefined && res.error === 0) {
          setReportTitle(res.result.preferences?.title);
        } else {
          setReportTitle("N/A");
        }
      },
      onError: (e: any) => {
        setReportTitle("N/A");
      },
    }
  );
  const excelFileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const excelFileExtension = ".xlsx";
  const excelFileName = `report-inventory-${excelDateFormat()}`;

  const RecorderKeys: string[] = useMemo(() => {
    const keys = [
      "Type",
      "Title",
      "Organization",
      "Firmware Version",
      "Model",
      "MAC Address",
      "Cameras",
      "Installer",
      "Registered Date",
      "location",
    ];
    if (selectedAccount.accountLevel === LEVEL_TYPE.EU) {
      return keys.filter((col) => col !== "Organization");
    }
    return keys;
  }, [selectedAccount]);
  const CameraKeys: string[] = useMemo(() => {
    const keys = [
      "Recorder",
      "Camera",
      "Organization",
      "Manufacturer",
      "Model",
      "MAC Address",
      "Storage",
      "Resolution",
    ];
    if (selectedAccount.accountLevel === LEVEL_TYPE.EU) {
      return keys.filter((col) => col !== "Organization");
    }
    return keys;
  }, []);

  const useExcelDownload = useCallback(() => {
    // console.log({matchInfoArray});
    const rs = XLSX.utils.aoa_to_sheet([RecorderKeys]);
    const ws = XLSX.utils.aoa_to_sheet([CameraKeys]);

    getInventoryRecorder({
      payload: {
        total: 0,
        pageNum: 0,
        pageLimit: totalDataCount.recorder,
        keyword: recorderQueryInfo.keyword,
        sortType: recorderQueryInfo.sortType,
        sortDirection: recorderQueryInfo.sortDirection,
      },
      reportId: reportId,
    }).then((res: any) => {
      (res.result !== undefined
        ? res.result.map((recorder: any) => {
            return {
              ...recorder,
              location: recorder.location?.location,
            };
          })
        : []
      )?.map((data: ReportInventoryRecorderRow) => {
        let sheetArr = [
          convertType(data),
          recorderTitleString(data.title, data.mergedSystemName, data.type),
          `${data.accountName} - ${data.accountNumber}`,
          data.firmwareVersion,
          data.model ? data.model : "N/A",
          data.macAddr ? data.macAddr : "N/A",
          data.cameraCount,
          data.installerName,
          getAccountTimeFormat(data.installedDate, selectedAccount, true),
          data.location,
        ];

        let cols = [
          { wpx: 200 },
          { wpx: 200 },
          { wpx: 200 },
          { wpx: 200 },
          { wpx: 120 },
          { wpx: 100 },
          { wpx: 100 },
          { wpx: 50 },
          { wpx: 100 },
        ];

        if (selectedAccount.accountLevel == LEVEL_TYPE.EU) {
          sheetArr = sheetArr.filter((sh, index) => index !== 2);
          cols = cols.filter((col, index) => index !== 2);
        }

        XLSX.utils.sheet_add_aoa(rs, [sheetArr as any[]], { origin: -1 });
        rs["!cols"] = cols;
        return false;
      });

      getInventoryCamera({
        payload: {
          total: 0,
          pageNum: 0,
          pageLimit: totalDataCount.camera,
          keyword: cameraQueryInfo.keyword,
          sortType: cameraQueryInfo.sortType,
          sortDirection: cameraQueryInfo.sortDirection,
        },
        reportId: reportId,
      }).then((res: any) => {
        (res.result !== undefined ? res.result : []).map(
          (data: ReportInventoryCameraRow) => {
            let resolution = data.streams
              ?.filter((stream: any) => {
                if (stream.encoderIndex === 0) {
                  return stream.resolution;
                } else {
                  return null;
                }
              })
              .map(
                (stream: any) => stream.encoderIndex === 0 && stream.resolution
              );
            let sheetArr = [
              recorderTitleString(data.recorderName, data.mergedSystemName),
              data.name,
              `${data.accountName} - ${data.accountNumber}`,
              data.manufacturer,
              data.model,
              data.macAddr ? data.macAddr : "N/A",
              formatBytesFromMB(
                data.storage !== undefined
                  ? data.storage?.reduce(function (prev: any, next: any) {
                      return prev + next.total;
                    }, 0)
                  : 0
              ),
              resolution ? resolution.toString() : "Not Detected",
            ];

            let cols = [
              { wpx: 200 },
              { wpx: 200 },
              { wpx: 200 },
              { wpx: 120 },
              { wpx: 100 },
              { wpx: 100 },
              { wpx: 50 },
              { wpx: 100 },
            ];
            if (selectedAccount.accountLevel == LEVEL_TYPE.EU) {
              sheetArr = sheetArr.filter((item, index) => index !== 2);
              cols = cols.filter((col, index) => index !== 2);
            }
            XLSX.utils.sheet_add_aoa(ws, [sheetArr as any[]], { origin: -1 });
            ws["!cols"] = cols;
            return false;
          }
        );
        const wb: any = {
          Sheets: { Recorder: rs, Cameras: ws },
          SheetNames: ["Recorder", "Cameras"],
        };
        // console.log(wb);
        const excelButter = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const excelFile = new Blob([excelButter], { type: excelFileType });
        FileSaver.saveAs(excelFile, excelFileName + excelFileExtension);
      });
    });
  }, [
    reportId,
    CameraKeys,
    RecorderKeys,
    cameraQueryInfo.keyword,
    cameraQueryInfo.sortDirection,
    cameraQueryInfo.sortType,
    excelFileName,
    recorderQueryInfo.keyword,
    recorderQueryInfo.sortDirection,
    recorderQueryInfo.sortType,
    selectedAccount,
    totalDataCount.camera,
    totalDataCount.recorder,
  ]);

  const [recorderPending, setRecorderPending] = useState<boolean>(true);

  const inventoryRecorderQuery = useQuery(
    ["inventoryRecorder", recorderQueryInfo, reportId],
    () =>
      getInventoryRecorder({
        payload: recorderQueryInfo,
        reportId: reportId,
      }),
    {
      retry: 0,
      enabled: reportId !== undefined,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res.result === undefined) {
          setRecorderQueryInfo({ ...initialRecorderQueryinfo });
          return;
        }
        setTotalDataCount((count) => {
          return {
            ...count,
            recorder: res.page.total,
          };
        });
        setRecorderData(
          res.result.map((recorder: any) => {
            // console.log(recorder);
            return {
              ...recorder,
              location: recorder.location?.location,
            };
          })
        );
        setRecorderQueryInfo((info) => {
          return {
            ...info,
            ...res.page,
          };
        });
      },
      onError: (e: any) => {
        setRecorderQueryInfo({ ...initialRecorderQueryinfo });
        notify(
          "error",
          intl.formatMessage({
            id: "label.report.notify.inventory.list.error",
            defaultMessage: "Inventory search error.",
          })
        );
      },
      onSettled: (e: any) => {
        setRecorderPending(false);
      },
    }
  );

  const [cameraPending, setCameraPending] = useState<boolean>(true);
  const inventoryCameraQuery = useQuery(
    ["inventoryCamera", cameraQueryInfo, reportId],
    () =>
      getInventoryCamera({
        payload: cameraQueryInfo,
        reportId: reportId,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      enabled: reportId !== undefined,
      onSuccess: (res: any) => {
        if (res.result === undefined) {
          setCameraQueryInfo({ ...initialCameraQueryinfo });
          return;
        }
        setTotalDataCount((count) => {
          return {
            ...count,
            camera: res.page.total,
          };
        });
        setCameraData(
          res.result.map((camera: any) => {
            // 우선은 encoderIndex === 0 인것을 표시
            let resolution = camera.streams
              ?.filter((stream: any) => {
                if (stream.encoderIndex === 0) {
                  return stream.resolution;
                } else {
                  return null;
                }
                // else return;
              })
              .map(
                (stream: any) => stream.encoderIndex === 0 && stream.resolution
              );
            return {
              ...camera,
              resolution: resolution?.toString(),
              totalStorage:
                camera.storage !== undefined
                  ? camera.storage?.reduce(function (prev: any, next: any) {
                      return prev + next.total;
                    }, 0)
                  : 0,
            };
          })
        );
        setCameraQueryInfo((info) => {
          return {
            ...info,
            ...res.page,
          };
        });
      },
      onError: (e: any) => {
        // console.error(e, "error");
        if (e.data) {
          setCameraQueryInfo({ ...initialCameraQueryinfo });
          e.data.error === 7001 && setCameraData([]);

          return;
        } else throw e;
      },
      onSettled: (e: any) => {
        setCameraPending(false);
      },
    }
  );

  const onChangePage = useCallback(
    (page: number, totalRows: number) => {
      if (currentMenu === 0) {
        if (page !== recorderQueryInfo.pageNum + 1) {
          setRecorderQueryInfo((info) => {
            return { ...info, pageNum: (page - 1) as number };
          });
        }
      }
      if (currentMenu === 1) {
        if (page !== cameraQueryInfo.pageNum + 1) {
          setCameraQueryInfo((info) => {
            return { ...info, pageNum: (page - 1) as number };
          });
        }
      }
    },
    [cameraQueryInfo, recorderQueryInfo, currentMenu]
  );

  const onClickSearch = useCallback((keyword: string) => {
    setRecorderQueryInfo((query) => {
      return {
        ...query,
        pageNum: 0,
        keyword,
      };
    });
    setCameraQueryInfo((query) => {
      return {
        ...query,
        pageNum: 0,
        keyword,
      };
    });
  }, []);

  const onClickExcelEmail = useCallback(() => {
    setIsExportEmail(true);
  }, []);

  const onModalExportMail = useCallback(() => {
    setIsExportEmail(false);
  }, []);

  const recColumns: TableColumnsType<ReportInventoryRecorderRow> =
    useMemo(() => {
      if (selectedAccount.accountLevel === LEVEL_TYPE.EU) {
        return recorderColumns.filter((col) => col.title !== "Organization");
      }

      return recorderColumns;
    }, [selectedAccount, recorderColumns]);

  const camColumns: TableColumnsType<ReportInventoryCameraRow> = useMemo(() => {
    if (selectedAccount.accountLevel === LEVEL_TYPE.EU) {
      return cameraColumns.filter((col) => col.title !== "Organization");
    }

    return cameraColumns;
  }, [selectedAccount, cameraColumns]);

  // console.log(cameraData, recorderData);

  return (
    <InventoryPresenter
      title={reportTitle}
      cameraColumns={camColumns}
      recorderColumns={recColumns}
      currentMenu={currentMenu}
      onChange={onChange}
      excelDownload={useExcelDownload}
      excelEmail={onClickExcelEmail}
      cameraData={cameraData}
      recorderData={recorderData}
      totalDataCount={totalDataCount}
      paginationPerPage={paginationPerPage}
      onChangePage={onChangePage}
      onClickSearch={onClickSearch}
      isExportEmail={isExportEmail}
      onModalExportMail={onModalExportMail}
      reportId={reportId}
      recorderTablePending={recorderPending}
      cameraTablePending={cameraPending}
      selectedAccount={selectedAccount}
      recorderPaginationInfo={recorderQueryInfo}
      cameraPaginationInfo={cameraQueryInfo}
    />
  );
}
