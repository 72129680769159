import { ClickSearch } from "components/atoms/input/search/SearchInput";
import SubHeader from "components/blocks/subheader/SubHeader";
import { TitleComponentArrayType } from "components/blocks/subheader/SubHeader";
export type TitleComponentType =
  | string
  | React.ReactNode
  | Array<TitleComponentArrayType>;

type Props = {
  children?: React.ReactNode;
  onClickBtn?: () => void;
  buttonLabel?: string;
  status?: boolean;
  centerChildren?: React.ReactNode | null;
  keyword?: string;
  onClickSearch?: ClickSearch;
  titleComponent?: TitleComponentType;
  noSearch?: boolean; // 검색창 없을 때
  buttons?: React.ReactNode;
  isSelf?: boolean;
  requiredPermissions?: string;
  licenseInfo?: React.ReactNode | null;
};

export const TableTemplate = ({
  children,
  onClickBtn,
  buttonLabel,
  status,
  centerChildren,
  keyword,
  onClickSearch,
  titleComponent,
  noSearch,
  buttons,
  isSelf,
  requiredPermissions,
  licenseInfo,
}: Props) => {
  return (
    <div className="table-template">
      <SubHeader
        onClickBtn={onClickBtn}
        buttonLabel={buttonLabel}
        status={status}
        centerChildren={centerChildren}
        keyword={keyword}
        onClickSearch={onClickSearch}
        titleComponent={titleComponent}
        noSearch={noSearch}
        buttons={buttons}
        isSelf={isSelf}
        requiredPermissions={requiredPermissions}
        licenseInfo={licenseInfo}
      />
      {children}
    </div>
  );
};
