import * as s from "./CvvStyled";
import {
  CvvCheckNowRequestPartial,
  CVVPairItem,
  DayNightItem,
} from "api/interfaces/reportInterface.interface";
import { CenterlineText } from "components/atoms/text/Text";
import CvvLabel from "./CvvLabel";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { Option } from "utils/options";
import { convertType } from "utils/functions";
import { useCallback, useState } from "react";
import { CvvBase } from "./CvvContainer";
import CvvDetailPopup from "./CvvDetailPopup";
import CvvCameraThumbnailPair from "./CvvCameraThumbnail";

type Props = {
  data: DayNightItem;
  timezone: string;
  timezoneOffset: number;
  selectedAccount: ProfileAccountInfo;
  onClickUpdate: (data: DayNightItem, pairItem: CVVPairItem) => Promise<void>;
  onClickCheckNow: (params: CvvCheckNowRequestPartial) => Promise<void>;
  selectFilterRecorder: string;
  recorderList: Option[];
  recorderType?: string;
};

export default function CvvCamera({
  data,
  timezone,
  timezoneOffset,
  selectedAccount,
  onClickUpdate,
  onClickCheckNow,
  selectFilterRecorder,
  recorderList,
  recorderType,
}: Props): JSX.Element {
  const [isCvvOpen, setIsCvvOpen] = useState<boolean>(false);
  const [popupBase, setPopupBase] = useState<CvvBase>(CvvBase.REFERENCE);
  const [currentPairItem, setCurrentPairItem] = useState<CVVPairItem | null>(
    null
  );

  const onChangeOpen = useCallback(
    (data: DayNightItem, pairItem: CVVPairItem, base: CvvBase) => {
      if (pairItem !== undefined && pairItem !== null) {
        setPopupBase(base);
        setCurrentPairItem(pairItem);
        setIsCvvOpen(!isCvvOpen);
      }
    },
    [isCvvOpen]
  );

  const renderDataName = () => {
    console.log(recorderType);
    if (!recorderType) {
      return `${data.name}`;
    }
    if (convertType({ type: recorderType }) === "SPECTRUM") {
      return `${data.name}`;
    }
    return `${data.name} #${data.channel + 1}`;
  };

  return (
    <>
      <s.CameraItem>
        <s.CameraTitle>
          <CenterlineText color="#828B9B" bold>
            {renderDataName()}
            {data.isCvv && (
              <span className="cvv-label">
                <CvvLabel status={data.isWarning ? "warning" : "normal"} />
              </span>
            )}
            {/* <span className="cvv-label">
            <CvvLabel />
          </span> */}
          </CenterlineText>
        </s.CameraTitle>

        <s.CameraWrapper>
          <CvvCameraThumbnailPair
            data={data}
            type={"night"}
            onChangeOpen={onChangeOpen}
            onClickUpdate={onClickUpdate}
            onClickCheckNow={() => {
              const params: CvvCheckNowRequestPartial = {
                accountId: selectedAccount.accountId,
                recorderId: selectFilterRecorder,
                cameraId: data.id,
                dayNight: "night",
              };
              return onClickCheckNow(params);
            }}
            selectedAccount={selectedAccount}
            timezoneOffset={timezoneOffset}
            timezone={timezone}
          />
          <CvvCameraThumbnailPair
            data={data}
            type={"day"}
            onChangeOpen={onChangeOpen}
            onClickUpdate={onClickUpdate}
            onClickCheckNow={() => {
              const params: CvvCheckNowRequestPartial = {
                accountId: selectedAccount.accountId,
                recorderId: selectFilterRecorder,
                cameraId: data.id,
                dayNight: "day",
              };
              return onClickCheckNow(params);
            }}
            selectedAccount={selectedAccount}
            timezoneOffset={timezoneOffset}
            timezone={timezone}
          />
        </s.CameraWrapper>
      </s.CameraItem>

      {isCvvOpen && (
        <CvvDetailPopup
          data={data}
          selectFilterRecorder={selectFilterRecorder}
          recorderList={recorderList}
          renderDataName={renderDataName}
          popupBase={popupBase}
          currentPairItem={currentPairItem}
          setIsCvvOpen={setIsCvvOpen}
        />
      )}
    </>
  );
}
