import Livefeed from "pages/dashboard/common/livefeed";
import * as mediaQuery from "components/MediaQuery";
import styled from "styled-components";
import { ReportHealthDetailCamera } from "api/interfaces/reportInterface.interface";

type Props = {
  data: ReportHealthDetailCamera;
};

export default function Cameras({ data }: Props): JSX.Element {
  return (
    <>
      <mediaQuery.Default>
        <Livefeed data={data} columns={5} />
      </mediaQuery.Default>
      <mediaQuery.Mobile>
        <MobileWrapper>
          <Livefeed data={data} columns={5} />
        </MobileWrapper>
      </mediaQuery.Mobile>
    </>
  );
}

const MobileWrapper = styled.div`
  max-height: 500px;
  overflow-y: auto;
  width: 100%;
`;
