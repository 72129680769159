import { useCallback, useEffect, useState } from "react";
import {
  BasePagination,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import { useQuery } from "react-query";
import { getEmailRecipientList } from "api/emailAPI";
import { Email, Recipients } from "api/interfaces/emailInterface.interface";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import { getAccountTimeFormat } from "utils/timeUtil";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TitleComponentArrayType } from "components/blocks/subheader/SubHeader";
import { TableTemplate } from "components/templates/default/table/TableTemplate";
import Status from "components/blocks/status/Status";
import { isNull } from "lodash";
import { menuNavigation, queryNavigate } from "utils/MenuUtil";
import { TableColumnsType } from "antd";
import {
  changeSortOrderAntd,
  CommonTable,
  onSortAntd,
} from "components/atoms/table/AntdTable";
import { ColumnType } from "antd/es/table";

interface Props {
  titleComponent: string | React.ReactNode | Array<TitleComponentArrayType>;
}

const initialQueryInfo = {
  total: 0,
  pageNum: 0,
  pageLimit: 20,
  keyword: "",
  sortType: "email",
  sortDirection: SORT_DIRECTION.DESC,
};
export default function EmailRecipient(props: Props): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();

  const { state } = location;
  const params = useParams();
  const emailId = params.id as string;
  //const emailId = state.row.emailId;
  const subject = params.subject as string;

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const titleComponent = [
    {
      label: "Sent Emails",
      onClick: () =>
        menuNavigation(selectedAccount, navigate, "/manager/emails"),
    },
    {
      label: subject,
    },
  ];
  const [emailRecipientQueryInfo, setEmailRecipientQueryInfo] =
    useState<BasePagination>({
      ...initialQueryInfo,
    });

  const [status, setStatus] = useState({
    totalTitle: "Total",
    totalValue: 0,
  });
  const onHeaderCell = (column: ColumnType<Recipients>) => {
    return {
      onClick: () => {
        if (column.key) {
          setEmailRecipientQueryInfo((info) => {
            return {
              ...info,
              sortType: column.key as string,
              sortDirection: onSortAntd(info.sortDirection),
            };
          });
        }
      },
    };
  };
  const recipientsColumns: TableColumnsType<Recipients> = [
    {
      title: "Recipient",
      render: (value, row) => row.username,
    },
    {
      title: "Email Address",
      sorter: true,
      sortOrder: changeSortOrderAntd(emailRecipientQueryInfo, "email"),
      defaultSortOrder: "descend",
      onHeaderCell: onHeaderCell,
      key: "email",
      render: (value, row) => row.email,
    },
    {
      title: "Email Status",
      render: (value, row) => row.emailStatus,
    },
    {
      title: "Received",
      render: (value, row) =>
        row.receivedTime !== undefined
          ? getAccountTimeFormat(row.receivedTime, selectedAccount, true)
          : "N/A",
    },
    {
      title: "Opened",
      render: (value, row) =>
        row.openedTime !== undefined
          ? getAccountTimeFormat(row.openedTime, selectedAccount, true)
          : "N/A",
    },
  ];

  const [recipients, setRecipients] = useState<Recipients[]>([]);

  useEffect(() => {
    const changeParam = new URLSearchParams(location.search);
    if (changeParam.size > 0) {
      setEmailRecipientQueryInfo((info) => {
        return {
          ...info,
          pageNum: isNull(changeParam.get("pageNum"))
            ? 0
            : Number(changeParam.get("pageNum")),
          pageLimit: isNull(changeParam.get("pageLimit"))
            ? 20
            : Number(changeParam.get("pageLimit")),
          keyword: isNull(changeParam.get("keyword"))
            ? ""
            : (changeParam.get("keyword") as string),
          sortType: isNull(changeParam.get("sortType"))
            ? "email"
            : (changeParam.get("sortType") as string),
          sortDirection: isNull(changeParam.get("sortDirection"))
            ? SORT_DIRECTION.DESC
            : (changeParam.get("sortDirection") as SORT_DIRECTION),
        };
      });
    } else {
      setEmailRecipientQueryInfo((info) => {
        return {
          ...info,
          pageNum: 0,
          pageLimit: 20,
          keyword: "",
          sortType: "email",
          sortDirection: SORT_DIRECTION.DESC,
        };
      });
    }
  }, [location]);

  const { error, refetch } = useQuery(
    ["emailRecipient", emailRecipientQueryInfo, emailId],
    () =>
      getEmailRecipientList({
        payload: emailRecipientQueryInfo,
        accountId: selectedAccount.accountId,
        emailId: emailId,
      }),

    {
      retry: 4,
      onSuccess: (res: any) => {
        if (res.error !== 0) {
          setRecipients([]);
          setStatus((info) => {
            return {
              ...info,
              totalValue: 0,
            };
          });
          setEmailRecipientQueryInfo({ ...initialQueryInfo });
          return;
        }
        setRecipients(res.result);
        setStatus((info) => {
          return {
            ...info,
            totalValue: res.page.total,
          };
        });
        setEmailRecipientQueryInfo((info) => {
          return { ...info, ...res.page };
        });
      },
      onError: (e: any) => {
        setRecipients([]);
        setStatus((info) => {
          return {
            ...info,
            totalValue: 0,
          };
        });
        setEmailRecipientQueryInfo({ ...initialQueryInfo });
      },
    }
  );

  const onChangePage = useCallback(
    (page: number, totalRows: number) => {
      // console.log(page, totalRows);
      if (page !== emailRecipientQueryInfo.pageNum + 1) {
        queryNavigate(
          navigate,
          location.pathname,
          {
            pageLimit: emailRecipientQueryInfo.pageLimit,
            pageNum: page - 1,
            keyword: emailRecipientQueryInfo.keyword as string,
            sortType: emailRecipientQueryInfo.sortType,
            sortDirection:
              emailRecipientQueryInfo.sortDirection as SORT_DIRECTION,
          },
          selectedAccount
        );
        // setEmailRecipientQueryInfo((info) => {
        //   return { ...info, pageNum: (page - 1) as number };
        // });
      }
    },
    [
      emailRecipientQueryInfo.keyword,
      emailRecipientQueryInfo.pageLimit,
      emailRecipientQueryInfo.pageNum,
      emailRecipientQueryInfo.sortDirection,
      emailRecipientQueryInfo.sortType,
      location.pathname,
      navigate,
    ]
  );

  const onClickSearch = useCallback(
    (keyword: string) => {
      queryNavigate(
        navigate,
        location.pathname,
        {
          pageLimit: emailRecipientQueryInfo.pageLimit,
          pageNum: 0,
          keyword: keyword as string,
          sortType: emailRecipientQueryInfo.sortType,
          sortDirection:
            emailRecipientQueryInfo.sortDirection as SORT_DIRECTION,
        },
        selectedAccount
      );
      // setEmailRecipientQueryInfo((query) => {
      //   return {
      //     ...query,
      //     keyword,
      //   };
      // });
      // refetch();
    },
    [
      emailRecipientQueryInfo.pageLimit,
      emailRecipientQueryInfo.sortDirection,
      emailRecipientQueryInfo.sortType,
      location.pathname,
      navigate,
      selectedAccount,
    ]
  );

  return (
    <TableTemplate
      titleComponent={titleComponent}
      keyword={emailRecipientQueryInfo.keyword}
      onClickSearch={onClickSearch}
      centerChildren={<Status status={status} />}
    >
      <CommonTable
        columns={recipientsColumns}
        dataSource={recipients}
        queryInfo={emailRecipientQueryInfo}
        onChangePage={onChangePage}
      />
    </TableTemplate>
  );
}
