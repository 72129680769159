import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import {
  InstallReport,
  ReportDataRow,
} from "api/interfaces/reportInterface.interface";
import {
  deleteReport,
  postInstallReportCreate,
  putInstallReportCreate,
} from "api/reportAPI";
import { notify } from "components/atoms/notification/Notification";
import { useAuth } from "components/Auth";
import { useCallback, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useAppSelector } from "redux/hooks";
import AddInstallPresenter from "./AddInstallPresenter";
import { SelectedRowWithAddress } from "components/element/SearchRecorderList";
import { isEmpty, merge } from "lodash";
import {
  CustomError,
  Validate,
  ValidateMsg,
} from "api/interfaces/commonInterface.interface";
import { useIntl } from "react-intl";
import { convertDateToStringOrigin } from "utils/timeUtil";
import useApiError from "hook/useApiError";

export enum UPDATE_TYPE {
  RECORDER,
  RECORDER_GROUP,
  CUSTOMER_NAME,
  CUSTOMER_PHONE,
  CUSTOMER_ADDRESS,
  INSTALLER_NAME,
  INSTALLER_PHONE,
  COMPANY_NAME,
  COMPANY_PHONE,
  COMPANY_ADDRESS,
  INSTALL_DATE,
  DEVICE_DESC,
}

export enum UPDATE_ADDRESS_TYPE {
  CUSTOMER,
  INSTALLER,
}

type Props = {
  onModal: (type?: string) => void;
  isEdit?: boolean;
  reportRow?: ReportDataRow;
  data?: InstallReport;
};

const initialReportState: InstallReport = {
  recorder: {
    recorderId: "",
  },
  information: {
    customerName: "",
    customerPhoneNumber: "",
    customerLocation: "",
    installerName: "",
    installerPhoneNumber: "",
    installerCompany: "",
    installerCompanyPhoneNumber: "",
    installerCompanyAddress: "",
    installationDate: new Date().toISOString(),
    deviceSerial: "",
    deviceDescription: "",
    analogCameraModels: [],
  },
};

export default function AddInstallContainer(props: Props): JSX.Element {
  const auth = useAuth();
  const intl = useIntl();
  const { handleError } = useApiError();

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const queryClient = useQueryClient();

  const [selected, setSelected] = useState<google.maps.LatLngLiteral>({
    lat: 33.8583483,
    lng: -118.0647871,
  });
  const [page, setPage] = useState<number>(0);

  // 나라 선택
  const [isFlagSelected, setIsFlagSelected] = useState("US");

  const [saveIsInProgress, setSaveIsInProgress] = useState<boolean>(false);

  const [installationReport, setInstallationReport] =
    useState<InstallReport>(initialReportState);

  useEffect(() => {
    if (props.data !== undefined) {
      const newObj = merge({}, initialReportState, props.data);
      setInstallationReport(newObj);

      if (props.isEdit) {
        setPage(1);
      }
    }
  }, [props.data]);

  const steps = [
    {
      label: "Recorder",
    },
    {
      label: "Information",
    },
  ];

  const onChangeLocationSelect = (address: google.maps.LatLngLiteral) => {
    setSelected(address);
  };

  const onChangePage = (name: string, index?: number) => {
    if (name === "next") {
      setPage(page + 1);
    }
    if (name === "previous") {
      setPage(page - 1);
    }
    if (name === "save") {
      props.onModal();
    }
    if (name === "step" && index !== undefined) {
      console.log(name, index);
      setPage(index);
    }
  };

  // 나라 선택 함수
  const onSelectFlag = (code: string) => {
    setIsFlagSelected(code);
  };

  const mutationCreateReport = useMutation(postInstallReportCreate, {
    onSuccess: () => {
      setSaveIsInProgress(false);
      notify(
        "success",
        intl.formatMessage({
          id: "label.report.notify.install.create.success",
          defaultMessage: "Installation report created successfully.",
        })
      );
      props.onModal();
    },
    onError: (err: CustomError) => {
      setSaveIsInProgress(false);
      handleError(
        err,
        intl.formatMessage({
          id: "label.report.notify.install.create.fail",
          defaultMessage: "Failed to create Installation report.",
        })
      );
    },
  });

  const mutationUpdateReport = useMutation(putInstallReportCreate, {
    onSuccess: () => {
      setSaveIsInProgress(false);
      notify(
        "success",
        intl.formatMessage({
          id: "label.report.notify.install.update.success",
          defaultMessage: "Installation report updated successfully.",
        })
      );
      props.onModal();
    },
    onError: (err: CustomError) => {
      setSaveIsInProgress(false);
      handleError(
        err,
        intl.formatMessage({
          id: "label.report.notify.install.update.fail",
          defaultMessage: "Failed to update Installation report.",
        })
      );
    },
  });

  const [reportRuleValidate, setReportRuleValidate] = useState<Validate>({
    isRecorder: false,
    isCustomName: false,
    isCustomPhone: false,
    isCustomAddress: false,
    isInstallerName: false,
    isInstallerPhone: false,
  });
  const [reportRuleValidateMsg, setReportRuleValidateMsg] =
    useState<ValidateMsg>({
      isRecorder: "",
      isCustomName: "",
      isCustomPhone: "",
      isCustomAddress: "",
      isInstallerName: "",
      isInstallerPhone: "",
    });

  const initReportRuleValidate = useCallback((field: string) => {
    setReportRuleValidate((info) => {
      return { ...info, [field as keyof typeof reportRuleValidate]: false };
    });
    setReportRuleValidateMsg((info) => {
      return { ...info, [field as keyof typeof reportRuleValidateMsg]: "" };
    });
  }, []);

  const onClickCreate = useCallback(() => {
    //setIsAddModal(true);
    setSaveIsInProgress(true);
    initReportRuleValidate("isRecorder");
    initReportRuleValidate("isCustomName");
    initReportRuleValidate("isCustomPhone");
    initReportRuleValidate("isCustomAddress");
    initReportRuleValidate("isInstallerName");
    initReportRuleValidate("isInstallerPhone");

    if (!props.isEdit && (
        installationReport.recorder.recorderId === undefined ||
        installationReport.recorder.recorderId.length === 0)
    ) {
      setReportRuleValidate((info) => {
        return { ...info, isRecorder: true as boolean };
      });

      setReportRuleValidateMsg((info) => {
        return {
          ...info,
          isRecorder: intl.formatMessage({
            id: "validateMsg.report.rule.emptySingleRecorder",
            defaultMessage: "Select one recorder.",
          }) as string,
        };
      });
      setPage(0);
      return;
    }

    if (isEmpty(installationReport.information.customerName)) {
      setReportRuleValidate((info) => {
        return { ...info, isCustomName: true as boolean };
      });

      setReportRuleValidateMsg((info) => {
        return {
          ...info,
          isCustomName: intl.formatMessage({
            id: "validateMsg.report.rule.emptyCustomName",
            defaultMessage: "Please enter customer name.",
          }) as string,
        };
      });
      setPage(1);
      return;
    }

    if (isEmpty(installationReport.information.customerPhoneNumber)) {
      setReportRuleValidate((info) => {
        return { ...info, isCustomPhone: true as boolean };
      });

      setReportRuleValidateMsg((info) => {
        return {
          ...info,
          isCustomPhone: intl.formatMessage({
            id: "validateMsg.report.rule.emptyInstallationName",
            defaultMessage: "Please enter Customer Phone Number",
          }) as string,
        };
      });
      setPage(1);
      return;
    }

    if (isEmpty(installationReport.information.customerLocation)) {
      setReportRuleValidate((info) => {
        return { ...info, isCustomAddress: true as boolean };
      });

      setReportRuleValidateMsg((info) => {
        return {
          ...info,
          isCustomAddress: intl.formatMessage({
            id: "validateMsg.report.rule.emptyCustomAddress",
            defaultMessage: "Please enter customer address.",
          }) as string,
        };
      });
      setPage(1);
      return;
    }

    if (isEmpty(installationReport.information.installerName)) {
      setReportRuleValidate((info) => {
        return { ...info, isInstallerName: true as boolean };
      });

      setReportRuleValidateMsg((info) => {
        return {
          ...info,
          isInstallerName: intl.formatMessage({
            id: "validateMsg.report.rule.emptyInstallName",
            defaultMessage: "Please enter installer name.",
          }) as string,
        };
      });
      setPage(1);
      return;
    }

    if (isEmpty(installationReport.information.installerPhoneNumber)) {
      setReportRuleValidate((info) => {
        return { ...info, isInstallerPhone: true as boolean };
      });

      setReportRuleValidateMsg((info) => {
        return {
          ...info,
          isInstallerPhone: intl.formatMessage({
            id: "validateMsg.report.rule.emptyInstallPhone",
            defaultMessage: "Please enter installer phone number.",
          }) as string,
        };
      });
      setPage(1);
      return;
    }

    if (props.isEdit) {
      mutationUpdateReport.mutate({
        payload: installationReport,
        reportId: props.reportRow?.id as string,
      });
    } else {
      mutationCreateReport.mutate({
        payload: installationReport,
        accountId: selectedAccount.accountId,
      });
    }
  }, [installationReport, mutationCreateReport, mutationUpdateReport]);

  const onChangeInstallReportInfo = useCallback(
    (
      type: UPDATE_TYPE,
      value:
        | string
        | number
        | boolean
        | string[]
        | boolean[]
        | Date
        | SelectedRowWithAddress
    ) => {
      console.log(type, value);
      if (type === UPDATE_TYPE.RECORDER) {
        setInstallationReport((info) => {
          return {
            ...info,
            recorder: {
              ...info.recorder,
              recorderId: (value as SelectedRowWithAddress).id,
            },
          };
        });

        if (!props.isEdit) {
          setInstallationReport((info) => {
            return {
              ...info,
              recorderId: (value as SelectedRowWithAddress).id,
            };
          });
          setInstallationReport((info) => {
            return {
              ...info,
              information: {
                ...info.information,
                customerLocation: (value as SelectedRowWithAddress).address,
              },
            };
          });
        }
      } else if (type === UPDATE_TYPE.CUSTOMER_NAME) {
        setInstallationReport((info) => {
          return {
            ...info,
            information: { ...info.information, customerName: value as string },
          };
        });
      } else if (type === UPDATE_TYPE.CUSTOMER_PHONE) {
        setInstallationReport((info) => {
          return {
            ...info,
            information: {
              ...info.information,
              customerPhoneNumber: value as string,
            },
          };
        });
      } else if (type === UPDATE_TYPE.CUSTOMER_ADDRESS) {
        setInstallationReport((info) => {
          return {
            ...info,
            information: {
              ...info.information,
              customerLocation: value as string,
            },
          };
        });
      } else if (type === UPDATE_TYPE.INSTALLER_NAME) {
        setInstallationReport((info) => {
          return {
            ...info,
            information: {
              ...info.information,
              installerName: value as string,
            },
          };
        });
      } else if (type === UPDATE_TYPE.INSTALLER_PHONE) {
        setInstallationReport((info) => {
          return {
            ...info,
            information: {
              ...info.information,
              installerPhoneNumber: value as string,
            },
          };
        });
      } else if (type === UPDATE_TYPE.COMPANY_NAME) {
        setInstallationReport((info) => {
          return {
            ...info,
            information: {
              ...info.information,
              installerCompany: value as string,
            },
          };
        });
      } else if (type === UPDATE_TYPE.COMPANY_PHONE) {
        setInstallationReport((info) => {
          return {
            ...info,
            information: {
              ...info.information,
              installerCompanyPhoneNumber: value as string,
            },
          };
        });
      } else if (type === UPDATE_TYPE.COMPANY_ADDRESS) {
        setInstallationReport((info) => {
          return {
            ...info,
            information: {
              ...info.information,
              installerCompanyAddress: value as string,
            },
          };
        });
      } else if (type === UPDATE_TYPE.INSTALL_DATE) {
        //BUG [RND-409] calendar 선택
        const selectDate = convertDateToStringOrigin(value as Date);
        setInstallationReport((info) => {
          return {
            ...info,
            information: {
              ...info.information,
              installationDate: selectDate as string,
            },
          };
        });
      } else if (type === UPDATE_TYPE.DEVICE_DESC) {
        setInstallationReport((info) => {
          return {
            ...info,
            information: {
              ...info.information,
              deviceDescription: value as string,
            },
          };
        });
      }
    },
    []
  );

  const onChangeAddress = (type: UPDATE_ADDRESS_TYPE, address: string) => {
    if (type === UPDATE_ADDRESS_TYPE.CUSTOMER) {
      setInstallationReport((info) => {
        return {
          ...info,
          information: {
            ...info.information,
            customerLocation: address as string,
          },
        };
      });
    } else {
      setInstallationReport((info) => {
        return {
          ...info,
          information: {
            ...info.information,
            installerCompanyAddress: address as string,
          },
        };
      });
    }
  };

  const [isConfirm, setIsConfirm] = useState<boolean>(false);

  const mutationDeleteReport = useMutation(deleteReport, {
    onSuccess: () => {
      notify(
        "success",
        intl.formatMessage({
          id: "label.report.notify.install.delete.success",
          defaultMessage: "Installation report deleted successfully.",
        })
      );
      props.onModal("edit");
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.report.notify.install.delete.fail",
          defaultMessage: "Failed to delete Installation report.",
        })
      );
    },
  });

  const onConfirmDelete = () => {
    mutationDeleteReport.mutate({
      reportId: props.reportRow?.id as string,
      accountId: selectedAccount.accountId,
    });
  };
  const setIsDeleteConfirm = (confirm: boolean) => {
    setIsConfirm(confirm);
  };
  const onDelete = (value: boolean) => {
    setIsConfirm(value);
  };

  return (
    <AddInstallPresenter
      isEdit={props.isEdit}
      steps={steps}
      page={page}
      userInfo={auth.user}
      installationReport={installationReport}
      isFlagSelected={isFlagSelected}
      accountId={selectedAccount.accountId}
      onChangePage={onChangePage}
      onSelectFlag={onSelectFlag}
      onChangeLocationSelect={onChangeLocationSelect}
      onChangeReportInfo={onChangeInstallReportInfo}
      onClickCreate={onClickCreate}
      onChangeAddress={onChangeAddress}
      onConfirmDelete={onConfirmDelete}
      onDelete={onDelete}
      setIsDeleteConfirm={setIsDeleteConfirm}
      isConfirm={isConfirm}
      reportRuleValidate={reportRuleValidate}
      reportRuleValidateMsg={reportRuleValidateMsg}
      reportRow={props.reportRow}
      saveIsInProgress={saveIsInProgress}
    />
  );
}
