import { SimpleFileViewerModal } from "components/templates/modal/simpleFileViewer/SimpleFileViewerModal";

const HELP_PDF_ADMIN_PASSWORD = 'https://mydw-resource-front.s3.us-west-2.amazonaws.com/help_pdf/Admin+Password+Alert.pdf';
const HELP_PDF_INCOMPLETE_REGISTRATION = 'https://mydw-resource-front.s3.us-west-2.amazonaws.com/help_pdf/Incomplete+Registration+Alert.pdf';
const HELP_PDF_DETACHED = 'https://mydw-resource-front.s3.us-west-2.amazonaws.com/help_pdf/Detached+Alert.pdf';

type AlertHelpType = {
  onModal: () => void;
}

export const AuthErrorHelp = ({ onModal }: AlertHelpType): JSX.Element => {
  return <PdfHelp title="Admin Password" fileUrl={HELP_PDF_ADMIN_PASSWORD} onModal={onModal} />
}

export const IncompleteRegistrationHelp = ({ onModal }: AlertHelpType): JSX.Element => {
  return <PdfHelp title="Incomplete Registration" fileUrl={HELP_PDF_INCOMPLETE_REGISTRATION} onModal={onModal} />
}

export const DetachedHelp = ({ onModal }: AlertHelpType): JSX.Element => {
  return <PdfHelp title="Detached" fileUrl={HELP_PDF_DETACHED} onModal={onModal} />
} 

type VideoHelpType = {
  title: string;
  fileUrl: string;
  onModal: () => void;
}

type PdfHelpType = {
  title?: string;
  fileUrl: string;
  onModal: () => void;
}

const VideoHelp = ({ title, fileUrl, onModal }: VideoHelpType) => {
  return (
    <SimpleFileViewerModal
      title={title}
      fileUrl={fileUrl}
      fileType="video"
      warn={true}
      onModal={onModal}
      />
  )
}

const PdfHelp = ({ fileUrl, onModal }: PdfHelpType) => {
  return (
    <SimpleFileViewerModal
      fileUrl={fileUrl}
      fileType="pdf"
      onModal={onModal}
      />
  )
}