import styled from "styled-components";
import { COLORS } from "../../../styles/colors";
import { FLEX } from "../../../styles/flex";

export const HeaderContainer = styled.header`
  width: 100%;
  ${FLEX.FlexBetweenCenter};
  padding-bottom: 15px;
  border-bottom: 1px solid ${COLORS.BORDER};
`;

export const HeaderContainerNoTitle = styled(HeaderContainer)`
  border: none;
`;

export const CloseButton = styled.button``;

export const TitleContainer = styled.div`
  flex: 1;
  ${FLEX.FlexCenterCenter};
`;

export const Row = styled.span`
  ${FLEX.FlexCenterCenter};

  .chevron-right {
    margin: 0 6px;
    color: ${({ theme }) => theme.COLORS.WHITE};
  }
`;

export const Chevron = styled.img`
  padding: 0 10px;

  /* width: 20px; */
`;

export const TitleIcon = styled.span`
  margin-right: 4px;
`;