import styled from "styled-components";
import { COLORS } from "styles/colors";
import { calcRem } from "styles/theme";
import { FLEX } from "styles/flex";
import * as mediaQuery from "components/MediaQuery";

interface Props {
  icon?: any;
  label: string;
  color: string;
}

const SmallLabel = ({ label, icon, color }: Props) => {
  const labelFormatted = label[0].toUpperCase() + label.substring(1);
  return (
    <Label color={color}>
      {icon && <img src={icon} alt="Label Icon" />}
      {labelFormatted} 
    </Label>
  );
};

export const Label = styled.span<{ color: string }>`
  padding: 6px 10px;
  background-color: ${({ color, theme }) =>
    theme.value === "light" ? theme.COLORS.LIGHTGRAY : color};
  border-radius: 3px;
  font-weight: 500;
  ${FLEX.FlexCenterCenter};
  gap: 4px;
  font-size: ${calcRem(14)};
  color: ${({ theme }) => theme.COLORS.WHITE};

  img {
    width: 14px;
  }

  ${mediaQuery.isMobile} {
    padding: 4px;

    img {
      width: 12px;
    }
  }
`;
export default SmallLabel;
