import { TableTemplate } from "components/templates/default/table/TableTemplate";
import { DataRow } from "./ListContainer";
import AddListModal from "../add/list";
import AddListModal2 from "../edit/list";
import Status, {
  StatusName,
  StatusType,
  StatusValue,
} from "components/blocks/status/Status";
import { FaInfoCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import * as s from "../RecordersStyled";
import { COLORS } from "styles/colors";
import { ClickSearch } from "components/atoms/input/search/SearchInput";
import InfoBubble from "components/blocks/bubble/InfoBubble";
import { BubbleContainer } from "pages/license/LicenseStyled";
import {
  LicenseCount,
  RecorderItem,
} from "api/interfaces/recorderInterface.interface";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { changeToTrialType } from "utils/AccountUtil";
import { BasePagination } from "api/interfaces/commonInterface.interface";
import { menuNavigation } from "utils/MenuUtil";
import Deactivate from "pages/users/add/deactivate";
import { DeregisterHelp } from "../DeregisterHelp";
import { CommonTable, ExpandTable } from "components/atoms/table/AntdTable";
import { TableColumnsType, TableProps } from "antd";
import RBACWrapper from "components/blocks/function/RBACWrapper";
import TableButton from "components/atoms/buttons/TableButton";
import { useMemo } from "react";

type Props = {
  isAddModal: boolean;
  isEditModal: boolean;
  isBubble: boolean;
  licenseCount: LicenseCount;
  setIsBubble: any;
  columns: TableColumnsType<DataRow>;
  onModal: (type: string) => void;
  data: Array<DataRow>;
  status: StatusType;
  selectRow: DataRow;
  paginationInfo: BasePagination;
  onChangePage: (page: number, totalRows: number) => void;
  onClickEdit: (row: DataRow) => void;
  onClickSearch: ClickSearch;
  initialPending: boolean;
  selectedAccount: ProfileAccountInfo;
  isDeleteConfirm: boolean;
  onClickDelete: (result: boolean) => void;
  isDeregisterHelp: boolean;
  closeDeregisterHelp: () => void;
  openDeregisterHelp: () => void;
  onRow: (row: DataRow) => void;
};

export function RecordersPresenter({
  isAddModal,
  isEditModal,
  isBubble,
  licenseCount,
  setIsBubble,
  onModal,
  columns,
  data,
  status,
  selectRow,
  paginationInfo,
  onChangePage,
  onClickEdit,
  onClickSearch,
  initialPending,
  selectedAccount,
  isDeleteConfirm,
  onClickDelete,
  isDeregisterHelp,
  closeDeregisterHelp,
  openDeregisterHelp,
  onRow,
}: Props): JSX.Element {
  const navigate = useNavigate();

  const centerChildren = <Status status={status} />;

  const titleComponent = "Recorder List";

  const licenseInfo = (
    <s.LicenseInfo>
      {/*
      isTrial === true && traialDays > 0 && license > 0 일경우에는 안보여줌
      trial 기간에 license 구매하면 안보여줌
      */}
      {!(
        selectedAccount.isTrial &&
        selectedAccount.trialDays !== undefined &&
        selectedAccount.trialDays > 0 &&
        licenseCount.totalLicensedCameraCount > 0
      ) &&
        !selectedAccount.isSuspended && (
          <li className="trial-type">
            {changeToTrialType(selectedAccount)}
            {/* trial 일때 trial 기간 보여주기 */}
            {changeToTrialType(selectedAccount) === "Trial" &&
              selectedAccount.trialDays !== undefined &&
              selectedAccount.trialDays > 0 &&
              ` ${selectedAccount.trialDays} day(s)`}
          </li>
        )}

      {selectedAccount.isSuspended && <li className="trial-type">Suspended</li>}

      <s.LicenseInfoItem
        isLicense={selectedAccount.isAdmin}
        onClick={() => {
          if (selectedAccount.isAdmin) {
            menuNavigation(selectedAccount, navigate, "/settings/license");
            // navigate("/settings/license", {
            //   state: { accountId: selectedAccount.accountId },
            // });
            // dispatch(setMenu("Settings"));
          }
        }}
      >
        <StatusName className="license-title">Licensed&nbsp;&nbsp;</StatusName>
        <StatusValue
          color={
            licenseCount.totalLicensedCameraCount <
            licenseCount.totalCameraCount
              ? COLORS.RED
              : COLORS.BUTTONS2
          }
        >
          {licenseCount.totalLicensedCameraCount}
        </StatusValue>
      </s.LicenseInfoItem>
      <s.LicenseInfoItem>/</s.LicenseInfoItem>
      <s.LicenseInfoItem>
        <StatusName className="license-title">Cameras&nbsp;&nbsp;</StatusName>
        <StatusValue>{licenseCount.totalCameraCount}</StatusValue>
      </s.LicenseInfoItem>
      {licenseCount.totalLicensedCameraCount <
        licenseCount.totalCameraCount && (
        <button
          style={{ position: "relative" }}
          onMouseOver={() => setIsBubble(true)}
          onMouseLeave={() => setIsBubble(false)}
        >
          <FaInfoCircle />
          {isBubble && (
            <BubbleContainer right>
              <InfoBubble right>
                You don't have a sufficient number of camera channel licenses.
                <br />
                You require{" "}
                {licenseCount.totalCameraCount -
                  licenseCount.totalLicensedCameraCount}{" "}
                additional licenses
                <br />
                to keep using the exclusive features on myDW.
              </InfoBubble>
            </BubbleContainer>
          )}
        </button>
      )}
    </s.LicenseInfo>
  );
  const expandedRowRender = (data: RecorderItem[]) => {
    const onClickExpandRow = (event: any, row: RecorderItem) => {
      event.stopPropagation();
      // onModal("edit", row); TODO
    };
    const columns: TableColumnsType<RecorderItem> = [
      { title: "", width: 158 },
      { title: "Title", dataIndex: "name", key: "name" },
      // { title: "Host", dataIndex: "createBy", key: "createBy" },
      {
        title: "Cameras",
        dataIndex: "cameraCount",
        key: "cameraCount",
        align: "center",
        width: 100,
      },
      {
        title: "",
        render: (value, row) => (
          <RBACWrapper requiredPermissions="manageRecordersAndGroups">
            <s.Row className="table-button-wrapper">
              <span className="row-click-button">
                <TableButton
                  label="Edit"
                  onClick={(event) => {
                    onClickExpandRow(event, row);
                  }}
                />
              </span>
            </s.Row>
          </RBACWrapper>
        ),
        align: "right",
        width: 80,
      },
    ];
    return (
      <ExpandTable
        columns={columns}
        dataSource={data}
        onRow={(record) => {
          return {
            onClick: (event) => {
              onClickExpandRow(event, record.recorderId);
            },
          };
        }}
      />
    );
  };

  const expandableProps = useMemo<TableProps<DataRow>["expandable"]>(() => {
    return {
      expandRowByClick: true,
      // expandedRowRender: (record) => expandedRowRender(record.recorde), //TODO
      // rowExpandable: (record) =>
      //   record.recorders && record.recorders.length > 1,
    };
  }, []);
  return (
    <>
      <TableTemplate
        titleComponent={titleComponent}
        // onClickBtn={() => onModal("add")}
        // buttonLabel="Add Recorder"
        centerChildren={centerChildren}
        licenseInfo={licenseInfo}
        keyword={paginationInfo.keyword}
        onClickSearch={onClickSearch}
        requiredPermissions="manageRecordersAndGroups"
      >
        <CommonTable
          columns={columns}
          dataSource={data}
          queryInfo={paginationInfo}
          onChangePage={onChangePage}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                event.stopPropagation();
                onRow(record);
              },
            };
          }}
          loading={initialPending}
          expandable={expandableProps}
        />
      </TableTemplate>
      {isAddModal && <AddListModal type="add" onModal={() => onModal("add")} />}
      {isEditModal && selectRow.recordId && (
        <AddListModal2
          type="edit"
          selectRowId={selectRow.recordId}
          onModal={(deleted?: boolean) => {
            if (
              deleted &&
              selectRow &&
              selectRow.type.toUpperCase() === "SPECTRUM"
            ) {
              openDeregisterHelp();
            }
            onModal("edit");
          }}
        />
      )}
      {isDeleteConfirm && (
        <Deactivate
          onModal={onClickDelete}
          type="system"
          targetId={selectRow.title}
        />
      )}
      {isDeregisterHelp && <DeregisterHelp onModal={closeDeregisterHelp} />}
    </>
  );
}
