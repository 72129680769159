import BigButton from "components/atoms/buttons/BigButton";
import Input from "components/atoms/input/Input";
import { ModalInput } from "components/atoms/input/modal/ModalInput";
import PhoneInput from "components/atoms/input/phone/PhoneInput";
import TextArea from "components/atoms/input/TextArea";
import { CenterlineText, ValidateWord } from "components/atoms/text/Text";
import { COLORS } from "styles/colors";
import { iAmAOptions, splitFullName } from "utils/options";
import * as s from "./TechStyled";
import { useAuth } from "components/Auth";
import AddressInput from "components/atoms/input/address/AddressInput";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { TechSupportSubmit } from "api/interfaces/managerInterface.interface";
import {
  TECH_USER_TYPE,
  Validate,
  ValidateMsg,
} from "api/interfaces/commonInterface.interface";
import { createTechSupport } from "api/managerAPI";
import { notify } from "components/atoms/notification/Notification";
import { useMutation } from "react-query";
import { useIntl } from "react-intl";
import { isEmpty } from "lodash";
import { useAppSelector } from "redux/hooks";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import MapUpload from "components/atoms/input/upload/map/MapUpload";
import Selects from "components/atoms/select/Select";
import { useSearchParams } from "react-router-dom";
import { PiDownloadSimple } from "react-icons/pi";
import { RecordeVideoFileInput } from "./RecordedVideoFileInput";
type Props = {};

export enum UPDATE_TYPE {
  EMAIL,
  USER_TYPE,
  FIRST_NAME,
  LAST_NAME,
  PHONE,
  ADDRESS,
  DESC,
}

export default function TechSupport(props: Props): JSX.Element {
  const { user } = useAuth();
  const intl = useIntl();

  const imgRef = useRef<any>();
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const [imgFile, setImgFile] = useState<any>("");
  const [uploadFile, setUploadFile] = useState<File>();

  const [validate, setValidate] = useState<Validate>({
    description: false,
  });

  const [validateMsg, setValidateMsg] = useState<ValidateMsg>({
    description: "",
  });

  const [techSupport, setTechSupport] = useState<TechSupportSubmit>({
    email: user.email,
    userType: TECH_USER_TYPE.consumerEU,
    name: user.name,
    phone: "",
    address: "",
    description: "",
    firstName: splitFullName(user.name),
    lastName: splitFullName(user.name, true),
  });

  const [hasRecordedVideo, setHasRecordedVideo] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [fileUrl, setFileUrl] = useState<string>('');

  useEffect(() => {
    const blobUrl = searchParams.get("bloburl");
    if (!blobUrl) {
      return;
    }
    setHasRecordedVideo(true);
    fetch(blobUrl).then((res: any) => {
      return res.blob();
    }).then((blob) => {
      const fileName = searchParams.get('filename') || 'Recorded.webm';
      const file = new File([blob], fileName);
      setUploadFile(file);
      setFileUrl(blobUrl);
    }).catch((err) => {
      console.error(err);
      setHasRecordedVideo(false);
    });

    return () => {
      setHasRecordedVideo(false);
    }
  }, [searchParams]);

  const saveImgFile = (e: any) => {
    let files = e.target.files[0];
    setUploadFile(files);
    const reader = new FileReader();
    reader.readAsDataURL(files);
    return new Promise((resolve: any) => {
      reader.onload = () => {
        setImgFile(reader.result);
        resolve();
      };
    });
  };

  const onDeleteImgFile = () => {
    imgRef.current.value = "";
    setImgFile("");
  };

  const onChangeTech = useCallback(
    (type: UPDATE_TYPE, value: string | boolean) => {
      if (type === UPDATE_TYPE.EMAIL) {
        setTechSupport((info) => {
          return { ...info, email: value as string };
        });
      } else if (type === UPDATE_TYPE.USER_TYPE) {
        setTechSupport((info) => {
          return { ...info, userType: value as string };
        });
      } else if (type === UPDATE_TYPE.FIRST_NAME) {
        setTechSupport((info) => {
          return { ...info, firstName: value as string };
        });
        setTechSupport((info) => {
          return { ...info, name: (value + " " + info.lastName) as string };
        });
      } else if (type === UPDATE_TYPE.LAST_NAME) {
        setTechSupport((info) => {
          return { ...info, lastName: value as string };
        });
        setTechSupport((info) => {
          return { ...info, name: (info.firstName + " " + value) as string };
        });
      } else if (type === UPDATE_TYPE.PHONE) {
        setTechSupport((info) => {
          return { ...info, phone: value as string };
        });
      } else if (type === UPDATE_TYPE.ADDRESS) {
        setTechSupport((info) => {
          return { ...info, address: value as string };
        });
      } else if (type === UPDATE_TYPE.DESC) {
        setTechSupport((info) => {
          return { ...info, description: value as string };
        });
      }
    },
    []
  );

  const onChangeAddress = useCallback(
    (type: UPDATE_TYPE, value: string | boolean) => {
      if (type === UPDATE_TYPE.ADDRESS) {
        setTechSupport((info) => {
          return { ...info, address: value as string };
        });
      }
    },
    []
  );

  const contactInfo = [
    {
      label: "Email",
      input: (
        <Input
          placeholder={"Enter email address"}
          value={techSupport.email}
          onChange={(e) =>
            onChangeTech(UPDATE_TYPE.EMAIL, e.currentTarget.value)
          }
          disabled
        />
      ),
      placeholder: "Enter email address",
      essential: true,
      disabled: true,
    },
    {
      label: "I Am A(n)",
      input: (
        <Selects
          options={iAmAOptions}
          value={iAmAOptions.filter(
            (option) => option.value === techSupport.userType
          )}
          onChange={(value) => onChangeTech(UPDATE_TYPE.USER_TYPE, value)}
        />
      ),
      essential: true,
      disabled: false,
    },
    {
      label: "First Name",
      input: (
        <Input
          placeholder={"Enter first name"}
          value={
            techSupport.firstName !== undefined ? techSupport.firstName : ""
          }
          onChange={(e) =>
            onChangeTech(UPDATE_TYPE.FIRST_NAME, e.currentTarget.value)
          }
        />
      ),
      placeholder: "Enter first name",
      essential: false,
      disabled: false,
    },
    {
      label: "Last Name",
      input: (
        <Input
          placeholder={"Enter first name"}
          value={techSupport.lastName !== undefined ? techSupport.lastName : ""}
          onChange={(e) =>
            onChangeTech(UPDATE_TYPE.LAST_NAME, e.currentTarget.value)
          }
        />
      ),
      placeholder: "Enter last name",
      essential: false,
      disabled: false,
    },
    // {
    //   label: "Company Name",
    //   placeholder: "Enter company name",
    // },
    {
      label: "Phone Number",
      input: (
        <PhoneInput
          selected={techSupport.phone !== undefined ? techSupport.phone : ""}
          onChange={(phoneNumber) => {
            onChangeTech(UPDATE_TYPE.PHONE, phoneNumber);
          }}
        />
      ),
      essential: false,
      disabled: false,
    },
    {
      label: "Address",
      input: (
        <AddressInput
          isLoaded={true}
          value={techSupport.address}
          onChange={(e) =>
            onChangeAddress(UPDATE_TYPE.ADDRESS, e.currentTarget.value)
          }
          onChangeLocationSelect={(
            address: google.maps.LatLngLiteral,
            addressString?: string
          ) => {
            if (addressString !== undefined) {
              onChangeTech(UPDATE_TYPE.ADDRESS, addressString);
            }
          }}
        />
      ),
      placeholder: "Enter company address",
    },
    // {
    //   label: "City",
    //   placeholder: "Enter company city",
    // },
    // {
    //   label: "Zip Code",
    //   placeholder: "Enter company zip code",
    // },
  ];

  const imageFileInput = {
    label: "Upload File",
    input: (
      <MapUpload
        onUpload={saveImgFile}
        onDelete={onDeleteImgFile}
        ref={imgRef}
      />
    ),
    placeholder: ''
  };

  const videoFileInput = {
    label: "Recorded Video File",
    input: <RecordeVideoFileInput fileUrl={fileUrl} uploadFile={uploadFile} />,
    essential: false,
    disabled: true,
  }

  if (hasRecordedVideo) {
    contactInfo.push(videoFileInput)
  } else {
    contactInfo.push(imageFileInput);
  }

  const productInfo = [
    {
      label: "Problem Description",
      input: (
        <Fragment>
          <TextArea
            placeholder="Tell us what's wrong"
            value={techSupport.description}
            onChange={(e) =>
              onChangeTech(UPDATE_TYPE.DESC, e.currentTarget.value)
            }
          />
          {validate.description && (
            <ValidateWord>{validateMsg.description}</ValidateWord>
          )}
        </Fragment>
      ),
    },
  ];

  const mutationCreateTechSupport = useMutation(createTechSupport, {
    onSuccess: (res: any) => {
      notify(
        "success",
        intl.formatMessage({
          id: "label.about.notify.tech.send.success",
          defaultMessage: "Submission completed successfully.",
        })
      );
      setSearchParams({});
      setTechSupport((info) => {
        return { ...info, description: '' };
      });
    },
    onError: () => {
      notify(
        "error",
        intl.formatMessage({
          id: "label.about.notify.tech.send.fail",
          defaultMessage: "Failed to submit.",
        })
      );
    },
  });

  const onSubmit = useCallback(() => {
    setValidate((info) => {
      return { ...info, description: false };
    });
    setValidateMsg((info) => {
      return { ...info, description: "" };
    });

    if (isEmpty(techSupport.description)) {
      setValidate((info) => {
        return { ...info, description: true };
      });
      setValidateMsg((info) => {
        return {
          ...info,
          description: intl.formatMessage({
            id: "label.about.notify.tech.desc.isEmpty",
            defaultMessage:
              "Required information is missing. Please fill in the description.",
          }),
        };
      });
      return;
    }

    const formData = new FormData();
    if (imgFile !== undefined && imgFile !== "" && uploadFile !== undefined) {
      formData.append("file", uploadFile as File);
    }

    if (hasRecordedVideo) {
      formData.append("file", uploadFile as File);
    }

    const json = JSON.stringify(techSupport);
    const blob = new Blob([json], { type: "application/json" });
    formData.append("techSupportFormDto", blob);

    mutationCreateTechSupport.mutate({
      accountId: selectedAccount.accountId, // Account -> UpdateRequest
      form: formData,
    });
  }, [
    imgFile,
    intl,
    mutationCreateTechSupport,
    selectedAccount.accountId,
    techSupport,
    uploadFile,
  ]);

  return (
    <s.Container>
      <s.Inner>
        <s.Title>Tech Support Form</s.Title>
        <s.FormItem>
          <s.SubTitle>
            <CenterlineText color={COLORS.BUTTONS1} fontSize={20} bold>
              Your Contact Information
            </CenterlineText>
          </s.SubTitle>

          <s.FormWrapper>
            {contactInfo.map((item, index) => (
              <ModalInput
                label={item.label}
                essential={item.essential}
                key={index}
              >
                {item.input ? (
                  item.input
                ) : (
                  <Input
                    placeholder={item.placeholder}
                    isValidated={!validate.description}
                  />
                )}
              </ModalInput>
            ))}
          </s.FormWrapper>
          {productInfo.map((item, index) => (
            <ModalInput label={item.label} essential key={index}>
              {item.input}
            </ModalInput>
          ))}
        </s.FormItem>
        {/* 
        <s.FormItem>
          <s.SubTitle>
            <CenterlineText color={COLORS.BUTTONS1} fontSize={20} bold>
              Product Information
            </CenterlineText>
          </s.SubTitle>

          <s.FormWrapper>
            {productInfo.map((item, index) => (
              <ModalInput label={item.label} essential key={index}>
                {item.input}
              </ModalInput>
            ))}
          </s.FormWrapper>
        </s.FormItem> 
        */}

        <s.ButtonWrapper>
          <BigButton onClickBtn={onSubmit} label="Submit" />
        </s.ButtonWrapper>
      </s.Inner>
    </s.Container>
  );
}
