import * as s from "../SystemSpecStyled";
import { ReactComponent as STATUSCAMERACHANNEL } from "assets/icons/status-camera-channel.svg";
import NetworkGraph from "./NetworkGraph";
import { VmaxSystemStatus } from "api/interfaces/reportInterface.interface";

import { useLocation } from "react-router-dom";

import NoData from "components/templates/nopage/NoData";
import GuageList, { GuageListInterface } from "../GuageList";

type Props = {
  vmaxStatus: VmaxSystemStatus;
  brand?: string;
};

export default function VMAX(props: Props): JSX.Element {
  const location = useLocation();
  const isMap = location.pathname.includes("map");

  const convertUsage = (input: number | undefined, unit: number) => {
    if (input === undefined || input === 0) {
      return 0;
    } else {
      return input * unit;
    }
  };

  const guageList: GuageListInterface[] = [
    {
      title: "CPU",
      value: convertUsage(props.vmaxStatus.system.cpu, 100),
    },
    {
      title: "Memory",
      value: convertUsage(props.vmaxStatus.system.memory, 100),
    },
    {
      title: "Network",
      value: convertUsage(props.vmaxStatus.network.txMbps, 10),
    },
  ];
  return (
    <>
      {isMap && (
        <s.TopContents>
          <li>
            <span className="title">Model: </span>
            {props.vmaxStatus.model}
          </li>

          <li>
            <span className="title">MAC Address:</span>

            {props.vmaxStatus.mac}
          </li>
          <li>
            <span className="title">Version: </span>
            {props.vmaxStatus.firmwareVersion}
          </li>
        </s.TopContents>
      )}
      {props.vmaxStatus.network.client ? (
        <>
          <div className="graph">
            <s.VmaxBox>
              <NetworkGraph vmaxStatus={props.vmaxStatus} brand={props.brand} />
            </s.VmaxBox>

            {props.vmaxStatus.cameras && (
              <s.CameraStatusList isMap={isMap}>
                {props.vmaxStatus.cameras.map((cam, idx) => {
                  return (
                    <s.CameraStatus
                      status={cam.status}
                      key={`camera-status-${idx}`}
                    >
                      <div className="icon">
                        <STATUSCAMERACHANNEL />
                        <span>{idx + 1}</span>
                      </div>
                      <div className="content">
                        <span>{cam.txMbps}M</span>
                        <span>{cam.firstStreamFrame}FPS</span>
                        <span>{cam.secondStreamFrame}FPS</span>
                        <span>
                          {cam.poe !== 0
                            ? `PoE${cam.poe}`
                            : props.vmaxStatus.type !== "dvr"
                            ? " LAN"
                            : ""}
                        </span>
                      </div>
                    </s.CameraStatus>
                  );
                })}
              </s.CameraStatusList>
            )}
          </div>
          <GuageList guageList={guageList} />
        </>
      ) : (
        <NoData />
      )}
    </>
  );
}
