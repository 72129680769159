import * as s from "../TableItemStyled";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { notify } from "components/atoms/notification/Notification";
import {
  BasePagination,
  LEVEL_TYPE,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import { useIntl } from "react-intl";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import SearchInput from "components/atoms/input/search/SearchInput";

import AccountSelects from "../AccountSelects";
import { DashboardChartDetailTable } from "components/atoms/table/AntdTable";
import { TableColumnsType } from "antd";
import {
  calTickValues,
  changeStatsToBarDatum,
  commonOrgColumn,
  commonPaginationPerPage,
  getAxisBottomValue,
  getMaxYValue,
} from "pages/dashboard/dashboard/WidgetContainer";
import { postStatCvv, postWidgetCvvDetection } from "api/widgetAPI";
import {
  GetWidgetCvvStatsResponse,
  PostWidgetCvvDetectionResponse,
  WidgetCvvDetection,
  WidgetCvvEvent,
} from "api/interfaces/widgetInterface.interface";
import { changeFirstWordToUppercase, showCount } from "utils/functions";
import { getRecorderTimeFormat } from "utils/timeUtil";
import IntervalSelects, { WidgetChart } from "../TypeSelects";
import TypeSelects from "../TypeSelects";
import BarChart, { barKeyName } from "components/atoms/chart/BarChart";
import { BarDatum } from "@nivo/bar";
import { COLORS } from "styles/colors";
import TableItemHeader from "../TableItemHeader";
import { Layout } from "react-grid-layout";
import { TableItemName } from "../TableItemList";
import NoDataChart from "components/templates/nopage/NoDataChart";

interface Props {
  layout: Layout;
  chartLayout: Layout[];
  onDeleteWidget: (key: TableItemName) => void;
  onLayoutChange: (layout: Layout[]) => void;
}

const changeDetection = (detection: string) => {
  switch (detection) {
    case "spider":
      return "Spider Webs";
    default:
      return changeFirstWordToUppercase(detection);
  }
};

export default function CvvDetection(props: Props): JSX.Element {
  const intl = useIntl();
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const [type, setType] = useState<WidgetChart>("graph");

  const [accountIds, setAccountIds] = useState<string[]>([]);
  const [data, setData] = useState<WidgetCvvDetection[]>([]);
  const [graphData, setGraphData] = useState<BarDatum[]>([]);
  const initialQueryInfo = {
    total: 0,
    pageNum: 0,
    pageLimit: commonPaginationPerPage,
    keyword: "",
    sortType: "createdAt",
    sortDirection: SORT_DIRECTION.DESC,
  };
  const [queryInfo, setQueryInfo] = useState<BasePagination>({
    ...initialQueryInfo,
  });

  const columns: TableColumnsType<WidgetCvvDetection> = [
    commonOrgColumn(selectedAccount),

    {
      title: "Recorder",
      dataIndex: "recorderName",
    },

    {
      title: "Camera",
      dataIndex: "cameraName",
      render: (value, record) => `${record.cameraName}`,
    },
    {
      title: "Detection",
      render: (value, row) => {
        let tempDetectionArr: string[] = row.events
          .filter((event) => event.isDetect)
          .map((ev) => changeDetection(ev.type));

        return <div className="red">{showCount(tempDetectionArr)}</div>;
      },
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      render: (value, row) =>
        value !== undefined
          ? getRecorderTimeFormat(value, selectedAccount, row.timezone, true)
          : "",
    },
    // {
    //   title: "",
    //   render: (value, row) => <UnderlineText fontSize={14}>View</UnderlineText>,
    //   align: "right",
    //   fixed: "right",
    // },
  ];

  const getCvvDetection = useQuery(
    ["postWidgetCvvDetection", accountIds, queryInfo, selectedAccount, type],
    () =>
      postWidgetCvvDetection({
        accountId: selectedAccount.accountId,
        payload: {
          accountIds,
          type: "",
          listDto: queryInfo,
        },
      }),
    {
      enabled: type === "list",
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: PostWidgetCvvDetectionResponse) => {
        if (res.error !== 0 && res.result === undefined) {
          setData([]);
          setQueryInfo((info) => {
            return { ...info, total: 0 as number, pageNum: 0 };
          });
        } else {
          // console.log(res.result, "cvv detection");
          setData(res.result);
          setQueryInfo((info) => {
            return { ...info, total: res.page.total as number };
          });
        }
      },
      onError: (err: any) => {
        setData([]);
        // setQueryInfo((info) => {
        //   return { ...info, total: 0 as number, pageNum: 0 };
        // });
      },
    }
  );

  const getCvvStats = useQuery(
    ["postStatCvv", accountIds, selectedAccount, type, queryInfo.keyword],
    () =>
      postStatCvv({
        accountId: selectedAccount.accountId,
        interval: "daily",
        payload: {
          accountIds,
          keyword: queryInfo.keyword ? queryInfo.keyword : "",
        },
      }),
    {
      enabled: type === "graph",
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: GetWidgetCvvStatsResponse) => {
        if (!res.result || !res.result.stats) {
          setGraphData([]);
          return;
        }
        setGraphData(changeStatsToBarDatum(res.result.stats));
      },
      onError: (err: any) => {
        setGraphData([]);
        notify(
          "error",
          intl.formatMessage({
            id: "label.dash.notify.cvv.error",
            defaultMessage: "CVV Stats error.",
          })
        );
      },
    }
  );

  const onChangeAccount = (value: string[]) => {
    setQueryInfo((quer) => {
      return {
        ...quer,
        pageNum: 0,
      };
    });
    setAccountIds(value);
  };

  const onChangeType = (value: WidgetChart) => {
    setType(value);
  };

  const onClickSearch = (keyword: string) => {
    setQueryInfo((quer) => {
      return {
        ...initialQueryInfo,
        keyword,
      };
    });
  };
  const onChangePage = useCallback(
    (page: number, totalRows: number) => {
      if (page !== queryInfo.pageNum + 1) {
        setQueryInfo((info) => {
          return { ...info, pageNum: (page - 1) as number };
        });
      }
    },
    [queryInfo.pageNum]
  );

  useEffect(() => {
    if (props.layout.h >= 2) {
      setQueryInfo((info) => {
        return {
          ...info,
          pageLimit: commonPaginationPerPage * props.layout.h,
        };
      });
    } else {
      setQueryInfo((info) => {
        return {
          ...info,
          pageLimit: commonPaginationPerPage,
        };
      });
    }
  }, [props.layout.h]);

  const isError =
    (type === "list" && getCvvDetection.isError) ||
    (type === "graph" && getCvvStats.isError);

  return (
    <>
      <TableItemHeader
        layout={props.layout}
        chartLayout={props.chartLayout}
        onDeleteWidget={props.onDeleteWidget}
        onLayoutChange={props.onLayoutChange}
        isError={isError}
      />
      <s.Contents className="drag-cancel-content">
        <s.ContentWrapper>
          <s.ContentHeader>
            <div>
              <AccountSelects onChange={onChangeAccount} value={accountIds} />
              <TypeSelects onChange={onChangeType} value={type} />
            </div>
            <div className="dashboard-search-input">
              <SearchInput onClickSearch={onClickSearch} />
            </div>
          </s.ContentHeader>
          <s.ContentInner>
            {type === "list" && (
              <DashboardChartDetailTable
                dataSource={data}
                columns={columns}
                queryInfo={queryInfo}
                onChangePage={onChangePage}
              />
            )}
            {type === "graph" && (
              <div className="bar-chart-wrapper">
                {graphData.length === 0 ||
                graphData.every((graph) => graph.barChartKey === 0) ? (
                  <NoDataChart />
                ) : (
                  <BarChart
                    data={graphData}
                    layout="vertical"
                    margin={{ top: 50, right: 20, bottom: 30, left: 55 }}
                    gridYValues={4}
                    enableGridY={
                      graphData.every((d) => d.barChartKey === 0) ? false : true
                    }
                    legendLabel={(value) => `${value}`}
                    axisLeft={{
                      format: (value) => `${value}`,
                      tickSize: 0,
                      tickValues: calTickValues(graphData),
                    }}
                    axisBottom={{
                      tickValues: getAxisBottomValue(graphData, props.layout.w),
                      tickSize: 7, // 눈금의 길이 설정
                      tickPadding: 1, // 눈금과 레이블 간의 간격 설정
                    }}
                    isInteractive={false}
                    layers={[
                      "grid",
                      "axes",
                      "bars",
                      "markers",
                      "legends",
                      "annotations",
                    ]}
                    maxValue={getMaxYValue(graphData)}
                  />
                )}
              </div>
            )}
          </s.ContentInner>
        </s.ContentWrapper>
      </s.Contents>
    </>
  );
}
