import { TableTemplate } from "components/templates/default/table/TableTemplate";
import AddGroupsModal from "../add/groups";
import Status, { StatusType } from "components/blocks/status/Status";
import { ClickSearch } from "components/atoms/input/search/SearchInput";
import { BasePagination } from "api/interfaces/commonInterface.interface";
import { TableColumnsType } from "antd";
import { CommonTable } from "components/atoms/table/AntdTable";
import { RecorderGroup } from "api/interfaces/recorderInterface.interface";

type Props = {
  isAddModal: boolean;
  isEditModal: boolean;
  onModal: (type: string) => void;
  columns: TableColumnsType<RecorderGroup>;
  status: StatusType;
  data: Array<RecorderGroup>;
  selectGroup?: RecorderGroup;
  onClickEdit: (row: RecorderGroup) => void;
  onChangePage: (page: number, totalRows: number) => void;
  onClickSearch: ClickSearch;
  paginationInfo: BasePagination;
  totalRows: number | undefined;
  isRowClickEditable: boolean;
  initialPending: boolean;
};

export default function GroupPresenter({
  isAddModal,
  isEditModal,
  onModal,
  columns,
  status,
  data,
  selectGroup,
  paginationInfo,
  onClickEdit,
  onChangePage,
  onClickSearch,
  isRowClickEditable,
  initialPending,
}: Props): JSX.Element {
  const centerChildren = <Status status={status} />;

  return (
    <>
      <TableTemplate
        onClickBtn={() => onModal("add")}
        buttonLabel="Add Groups"
        centerChildren={centerChildren}
        onClickSearch={onClickSearch}
        requiredPermissions="manageRecordersAndGroups"
        keyword={paginationInfo.keyword}
      >
        <CommonTable
          columns={columns}
          dataSource={data}
          queryInfo={paginationInfo}
          onChangePage={onChangePage}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                onClickEdit(record);
              },
            };
          }}
          loading={initialPending}
        />
      </TableTemplate>
      {isAddModal && <AddGroupsModal onModal={() => onModal("add")} />}
      {isEditModal && (
        <AddGroupsModal
          isEditModal={isEditModal}
          selectGroup={selectGroup}
          onModal={() => onModal("edit")}
        />
      )}
    </>
  );
}
