import { AlertDetail } from "api/interfaces/alertInterface.interface";
import { useCallback, useMemo, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import DetailButton from "components/atoms/buttons/detail/DetailButton";
import Status from "components/blocks/status/Status";
import { TableTemplate } from "components/templates/default/table/TableTemplate";

export default function AlertHistoryPage(): JSX.Element {
  const [keyword, setKeyword] = useState<string>("");

  const [alertTotal, setAlertTotal] = useState<number>(0);
  const [titleComponent, setTitleComponent] = useState<string | any[]>(
    "Alert History"
  );

  const [alertDetail, setAlertDetail] = useState<AlertDetail>({
    system: 0,
    disk: 0,
    video: 0,
  });

  const detailItems = useMemo(() => {
    return [
      {
        name: "system",
        value: alertDetail.system,
        label: "System",
      },
      {
        name: "storage",
        value: alertDetail.disk,
        label: "Disk",
      },
      {
        name: "video",
        value: alertDetail.video,
        label: "Video",
      },
    ];
  }, [alertDetail]);

  const detailStatus = useMemo(() => {
    return {
      totalTitle: "Total Active Alerts",
      totalValue: alertTotal,
      items: [
        <DetailButton
          title="Alert Quantities by category"
          contents={detailItems}
        />,
      ],
    };
  }, [alertTotal, detailItems]);

  const onClickSearch = useCallback(
    (string: string) => {
      setKeyword(string);
    },
    [keyword, setKeyword]
  );

  const centerChildren = <Status status={detailStatus} />;

  const outletContext = {
    keyword,
    setAlertDetail,
    setAlertTotal,
    setTitleComponent,
  };
  return (
    <TableTemplate
      titleComponent={titleComponent}
      centerChildren={centerChildren}
      onClickSearch={onClickSearch}
      keyword={keyword}
    >
      <Outlet context={outletContext} />
    </TableTemplate>
  );
}
