import React from "react";
import * as s from "../BillingPaymentStyled";
import { UnderlineText } from "components/atoms/text/Text";
import { COLORS } from "styles/colors";
import {
  BillingAccount,
  BillingLicenseByAccount,
} from "api/interfaces/licenseInterface.interface";
import { PaymentTable } from "components/atoms/table/AntdBillingTable";
import { TableColumnsType } from "antd";

export interface Props {
  data: BillingAccount;
  onChangeSort: (type: number) => void;
  onDetail: (accountId: string) => void;
}

export default function PaymentAccountTable(props: Props): JSX.Element {
  const columns = React.useMemo<TableColumnsType<BillingLicenseByAccount>>(
    () => [
      {
        dataIndex: "partNumber",
        title: "PART NUMBER",
        render: (value) => value,
      },
      {
        dataIndex: "description",
        title: "DESCRIPTION",
        render: (value) => value,
      },
      {
        dataIndex: "qty",
        title: "QTY",
        render: (value) => value,
      },
      {
        dataIndex: "status",
        title: "STATUS",
        render: (value, row, index) => {
          if (index === 0) {
            return (
              <s.StatusCell>
                <li>{row.status}</li>
                {props.data.accountId !== undefined &&
                  props.data.accountId !== null &&
                  props.data.accountId !== "" && (
                    <li>
                      <UnderlineText
                        fontSize={14}
                        onClick={() => props.onDetail(props.data.accountId)}
                      >
                        Details
                      </UnderlineText>
                    </li>
                  )}
              </s.StatusCell>
            );
          }
        },
      },
    ],
    []
  );

  return (
    <s.TableTitleBox>
      <s.TableTitle>
        {props.data.companyLogo !== undefined && (
          <img src={props.data.companyLogo} alt="company logo" />
        )}
        <span className="bold">{props.data.name}</span>
        {props.data.accountId !== undefined &&
          props.data.accountId !== null &&
          props.data.accountId !== "" && (
            <li>
              <UnderlineText
                color={COLORS.SELECTED}
                fontSize={14}
                onClick={() => props.onDetail(props.data.accountId)}
              >
                Details
              </UnderlineText>
            </li>
          )}
      </s.TableTitle>
      <s.TableWrapper>
        <PaymentTable columns={columns} dataSource={props.data.licenses} />
      </s.TableWrapper>
    </s.TableTitleBox>
  );
}
