import { LEVEL_TYPE } from "api/interfaces/commonInterface.interface";
import BigButton from "components/atoms/buttons/BigButton";
import { PiDownloadSimple, PiShareFat, PiUploadSimple } from "react-icons/pi";
import styled from "styled-components";
import { FLEX } from "styles/flex";

const Wrapper = styled.div`
  ${FLEX.FlexStartCenter}
  margin-top: 20px; 
  gap: 8px;
`;

type ActionButtonsType = {
  accountLevel: LEVEL_TYPE;
  isInProgress: boolean;
  download: () => void;
  uploadToVideoShare: () => void;
  sendToTechSupport: () => void;
}

export function ActionButtons({ accountLevel, isInProgress, download, uploadToVideoShare, sendToTechSupport }: ActionButtonsType) {
  const btnDownload = (
    <BigButton
      disabled={isInProgress}
      label="Save to Local Computer"
      iconComponent={<PiDownloadSimple />}
      onClickBtn={download} />
  );

  const btnVideoShare = (
    <BigButton
      disabled={isInProgress}
      label="Upload to Video Share"
      iconComponent={<PiUploadSimple />}
      onClickBtn={uploadToVideoShare} />
  );

  const btnTechSupport = (
    <BigButton
      disabled={isInProgress}
      label="Send to Tech Support"
      iconComponent={<PiShareFat />}
      onClickBtn={sendToTechSupport} />
  );

  switch (accountLevel) {
    case LEVEL_TYPE.DW: {
      return <Wrapper>{btnDownload}{btnTechSupport}</Wrapper>;
    }
    case LEVEL_TYPE.CP: {
      return <Wrapper>{btnDownload}{btnTechSupport}</Wrapper>;
    }
    case LEVEL_TYPE.EU: {
      return <Wrapper>{btnDownload}{btnVideoShare}{btnTechSupport}</Wrapper>;
    }
    default: {
      return <></>;
    }
  }
}