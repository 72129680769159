import Spin from "antd/lib/spin";
import { getCloudToken } from "api/authAPI";
import {
  CloudTokenResponse,
  NVRTokenResponse,
  NvrLinkTokenResponse,
} from "api/interfaces/authInterface.interface";
import {
  getLocalSpectrumAppLinkToken,
  getLocalSpectrumWebLinkToken,
  getNvrLinkToken,
  getPfToken,
  postLocalConnectCreate,
  postLocalConnectTest,
} from "api/recorderAPI";
import { notify } from "components/atoms/notification/Notification";
import { UnderlineText } from "components/atoms/text/Text";
import { SpectrumLogin } from "pages/auth/signin/SpectrumLogin";
import { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { useAppSelector } from "redux/hooks";
import styled from "styled-components";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import { setupAppLauncher, setupWebLauncher } from "utils/RecorderUtil";
import SpectrumLoginConfirmModal from "./confirm";
import { isEmpty } from "lodash";
import { useMutation } from "react-query";
import {
  CustomError,
  LEVEL_TYPE,
} from "api/interfaces/commonInterface.interface";
import useApiError from "hook/useApiError";
import {
  LocalConnectionInfo,
  LocalConnectionInfoResponse,
} from "api/interfaces/recorderInterface.interface";
import { convertType, getClientDevice } from "utils/functions";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { RecorderType } from "components/atoms/text/labels/Type";

interface LinkProps {
  type: string;
  accountId: string;
  recorderId: string;
  mergedSystemId: string;
  cloudSystemId?: string;
  brand?: string;
  displayType?: string;
}

function WebAppLink(props: LinkProps): JSX.Element {
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const intl = useIntl();
  const theme = useAppSelector((state) => state.theme.theme);
  const { handleError } = useApiError();

  const [webLoading, setWebLoading] = useState<boolean>(false);
  const [appLoading, setAppLoading] = useState<boolean>(false);
  const [selectLinkType, setSelectLinkType] = useState<string>("web");

  const [isOpenSpectrumLogin, setIsOpenSpectrumLogin] =
    useState<boolean>(false);
  const [isLocalSpectrumLogin, setIsLocalSpectrumLogin] =
    useState<boolean>(false);

  const OnClickWebLink = useCallback(() => {
    setSelectLinkType("web");
    setWebLoading(true);
    try {
      if (props.type !== undefined && props.type.toUpperCase() === "SPECTRUM") {
        if (props.cloudSystemId) {
          const linkWindow = window.open(
            `https://dwspectrum.digital-watchdog.com`,
            `_blank`
          );
          linkWindow?.blur();
          window.focus();
          getCloudToken({
            accountId: props.accountId,
            mergedSystemId: props.mergedSystemId as string,
          })
            .then((res: CloudTokenResponse) => {
              if (res.error !== 0) {
                linkWindow?.close();
                window.focus();
                notify(
                  "info",
                  intl.formatMessage({
                    id: "label.link.notify.token.error",
                    defaultMessage:
                      "Failed to find your connection information.",
                  })
                );
                return;
              }

              if (
                res.result.token === undefined ||
                res.result.token === "" ||
                res.result.token === null
              ) {
                setAppLoading(false);
                setIsOpenSpectrumLogin(true);
                linkWindow?.close();
                window.focus();
                // notify(
                //   "info",
                //   intl.formatMessage({
                //     id: "label.link.notify.token.error",
                //     defaultMessage: "Fail to find your connection information.",
                //   })
                // );
                return;
              } else {
                setupWebLauncher(
                  props.type,
                  // sessionStorage.getItem("code") as string
                  res.result.token,
                  props.cloudSystemId,
                  linkWindow
                );
              }
            })
            .catch((err: CustomError) => {
              //setIsOpenSpectrumLogin(true);
              linkWindow?.close();
              window.focus();
              handleError(
                err,
                intl.formatMessage({
                  id: "label.link.notify.token.error",
                  defaultMessage: "Failed to find your connection information.",
                })
              );
              // notify(
              //   "error",
              //   intl.formatMessage({
              //     id: "label.link.notify.token.error",
              //     defaultMessage: "Fail to find your connection information.",
              //   })
              // );
            });
        } else {
          const linkWindow = window.open(``, `_blank`);
          linkWindow?.blur();
          window.focus();
          getLocalSpectrumWebLinkToken({
            accountId: props.accountId,
            mergedSystemId: props.mergedSystemId as string,
          })
            .then((res: LocalConnectionInfoResponse) => {
              if (res.error !== 0) {
                linkWindow?.close();
                window.focus();
                notify(
                  "info",
                  intl.formatMessage({
                    id: "label.link.notify.token.error",
                    defaultMessage:
                      "Failed to find your connection information.",
                  })
                );
                return;
              }

              if (
                res.result.token === undefined ||
                res.result.token === "" ||
                res.result.token === null
              ) {
                setIsLocalSpectrumLogin(true);
                linkWindow?.close();
                window.focus();
                // notify(
                //   "info",
                //   intl.formatMessage({
                //     id: "label.link.notify.token.error",
                //     defaultMessage: "Fail to find your connection information.",
                //   })
                // );
              } else {
                if (linkWindow !== null) {
                  linkWindow.location.href = `https://${res.result.host}:${res.result.port}/static/index.html#/?token=${res.result.token}`;
                } else {
                }
                // window.open(
                //   `https://${res.result.host}:${res.result.port}/static/index.html#/?token=${res.result.token}`,
                //   "_blank",
                //   "noreferrer"
                // );
              }
            })
            .catch(() => {
              setIsLocalSpectrumLogin(true);
              linkWindow?.close();
              window.focus();
              notify(
                "info",
                intl.formatMessage({
                  id: "label.link.notify.token.error",
                  defaultMessage: "Failed to find your connection information.",
                })
              );
            });
        }
      } else {
        //NVR
        let pfLinkUrl = `https://pf.mydw.cloud`;
        if (
          props.brand === "tvt" &&
          process.env.REACT_APP_DATADOG_ENV === "development"
        ) {
          pfLinkUrl = `https://testpf.mydw.cloud`;
        }
        const linkWindow = window.open(pfLinkUrl, `_blank`);
        linkWindow?.blur();
        window.focus();
        getPfToken({
          accountId: props.accountId,
          recorderId: props.recorderId,
        })
          .then((res: NVRTokenResponse) => {
            if (res.error !== 0 || res.result === undefined) {
              setAppLoading(false);
              linkWindow?.close();
              window.focus();
              notify(
                "info",
                intl.formatMessage({
                  id: "label.link.notify.token.error",
                  defaultMessage: "Failed to find your connection information.",
                })
              );
              return;
            }
            setupWebLauncher(
              props.type,
              // sessionStorage.getItem("code") as string
              res.result,
              props.recorderId,
              linkWindow,
              props.brand
            );
          })
          .catch(() => {
            linkWindow?.close();
            window.focus();
            notify(
              "info",
              intl.formatMessage({
                id: "label.link.notify.token.error",
                defaultMessage: "Failed to find your connection information.",
              })
            );
          });
      }
    } catch {
      //
    } finally {
      setWebLoading(false);
    }
  }, [
    props.type,
    props.cloudSystemId,
    props.accountId,
    props.mergedSystemId,
    props.recorderId,
    intl,
  ]);

  const OnClickAppLink = useCallback(() => {
    setSelectLinkType("app");
    setAppLoading(true);
    try {
      if (props.type !== undefined && props.type.toUpperCase() === "SPECTRUM") {
        if (props.cloudSystemId) {
          getCloudToken({
            accountId: props.accountId,
            mergedSystemId: props.mergedSystemId as string,
          })
            .then((res: any) => {
              if (
                res.error !== 0 ||
                res.result.token === undefined ||
                res.result.token === "" ||
                res.result.token === null ||
                props.type === undefined ||
                props.cloudSystemId === undefined
              ) {
                setAppLoading(false);
                setIsOpenSpectrumLogin(true);
                return;
              }
              setupAppLauncher(
                props.type,
                props.cloudSystemId,
                // sessionStorage.getItem("code") as string
                res.result.token
              );
            })
            .catch(() => {
              setIsOpenSpectrumLogin(true);
            });
        } else {
          getLocalSpectrumAppLinkToken({
            accountId: props.accountId,
            mergedSystemId: props.mergedSystemId as string,
          })
            .then((res: LocalConnectionInfoResponse) => {
              if (
                res.error !== 0 ||
                res.result.auth === undefined ||
                res.result.token === undefined ||
                res.result.token === "" ||
                res.result.token === null
              ) {
                setAppLoading(false);
                notify(
                  "info",
                  intl.formatMessage({
                    id: "label.recorder.notify.connectionTest.registration",
                    defaultMessage: "Please enter your connection information.",
                  })
                );
                setIsLocalSpectrumLogin(true);
                return;
              }
              setupAppLauncher(
                props.type,
                "",
                // sessionStorage.getItem("code") as string
                res.result.token,
                res.result.host,
                res.result.port
              );
            })
            .catch(() => {
              setIsLocalSpectrumLogin(true);
            });
        }
      } else {
        //"dw-c3cms://c3cms.digital-watchdog.com/client?ip=P2P-A50F81F4B6BA-201FF5&mac=a8:dc:5a:20:1f:f5&p2p=1&cammax=12&auth=YWRtaW46ZElnSXRBTHdBVENIZE9HOkFkbWluMTIzNCE="
        getNvrLinkToken({
          accountId: props.accountId,
          recorderId: props.recorderId,
        }).then((res: NvrLinkTokenResponse) => {
          if (res.error !== 0 || res.result === undefined) {
            setAppLoading(false);
            return;
          }
          setupAppLauncher(
            props.type,
            props.cloudSystemId !== undefined ? props.cloudSystemId : "",
            res.result.auth,
            res.result.ip,
            undefined,
            res.result.mac,
            res.result.p2p,
            res.result.camMax
          );
        });
      }
    } catch {
    } finally {
      setAppLoading(false);
    }
  }, [props.type, props.cloudSystemId, props.mergedSystemId]);

  const onCloseWebLogin = () => {
    setIsOpenSpectrumLogin(false);
  };

  const onCloseLocalLogin = () => {
    setIsLocalSpectrumLogin(false);
  };

  const mutationSpectrumConnection = useMutation(postLocalConnectCreate, {
    onSuccess: (res: LocalConnectionInfoResponse) => {
      if (
        res.result.token !== undefined &&
        res.result.token !== "" &&
        res.result.token !== null &&
        props.cloudSystemId !== undefined
      ) {
        if (selectLinkType === "web") {
          setupWebLauncher(
            props.type,
            // sessionStorage.getItem("code") as string
            res.result.token,
            props.cloudSystemId
          );
        } else if (selectLinkType === "app") {
          setupAppLauncher(
            props.type,
            props.cloudSystemId,
            // sessionStorage.getItem("code") as string
            res.result.token
          );
        }
      } else {
        notify(
          "error",
          intl.formatMessage({
            id: "label.recorder.notify.connectionTest.fail",
            defaultMessage: "The connection test failed.",
          })
        );
      }
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.recorder.notify.connectionTest.fail",
          defaultMessage: "The connection test failed.",
        })
      );
    },
  });
  const onCode = useCallback(() => {
    setIsOpenSpectrumLogin(false);
    if (selectLinkType === "web") {
      OnClickWebLink();
    } else if (selectLinkType === "app") {
      OnClickAppLink();
    } else {
    }
  }, [OnClickAppLink, OnClickWebLink, selectLinkType]);
  // const onCode = useCallback(
  //   (code: string, params: URLSearchParams) => {
  //     if (!isEmpty(code)) {
  //       setIsOpenSpectrumLogin(false);
  //       window.open("", "_newWin"+ props.cloudSystemId);
  //       if (selectLinkType === "web") {
  //         if (props.cloudSystemId) {
  //           mutationSpectrumConnection.mutate({
  //             accountId: props.accountId,
  //             mergedSystemId: props.mergedSystemId,
  //             payload: { code: code },
  //           });
  //         }
  //       } else if (selectLinkType === "app") {
  //         if (props.cloudSystemId) {
  //           mutationSpectrumConnection.mutate({
  //             accountId: props.accountId,
  //             mergedSystemId: props.mergedSystemId,
  //             payload: { code: code },
  //           });
  //         }
  //       }
  //     } else {
  //     }
  //   },
  //   [
  //     mutationSpectrumConnection,
  //     props.accountId,
  //     props.cloudSystemId,
  //     props.mergedSystemId,
  //     selectLinkType,
  //   ]
  // );

  const mutationConnectionCreate = useMutation(postLocalConnectCreate, {
    onSuccess: (res: LocalConnectionInfoResponse) => {
      setIsLocalSpectrumLogin(false);
      if (selectLinkType === "web") {
        OnClickWebLink();
      } else {
        OnClickAppLink();
      }

      // if (res.result.token !== undefined) {
      //   window.open(
      //     `https://${res.result.host}:${res.result.port}/static/index.html#/?token=${res.result.token}`,
      //     "_blank",
      //     "noreferrer"
      //   );
      // } else {
      // }
      // notify(
      //   "success",
      //   intl.formatMessage({
      //     id: "label.common.notify.email.success",
      //     defaultMessage: "Email sent successfully",
      //   })
      // );
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.recorder.notify.connection.fail",
          defaultMessage: "Failed to save connection information.",
        })
      );
    },
  });

  const onClickSave = useCallback(
    (connection: LocalConnectionInfo) => {
      mutationConnectionCreate.mutate({
        accountId: props.accountId,
        mergedSystemId: props.mergedSystemId,
        payload: connection,
      });
    },
    [mutationConnectionCreate, props.accountId, props.mergedSystemId]
  );

  const isReux = getClientDevice() === "Linux PC";

  if (
    selectedAccount.accountLevel === LEVEL_TYPE.EU &&
    !selectedAccount.isHidden
  ) {
    return (
      <>
        <Container onClick={(e) => e.stopPropagation()}>
          {/* {isOpenSpectrumLogin && <SpectrumLogin />} */}
          <UnderlineText
            fontSize={14}
            onClick={(e) => {
              e.stopPropagation();             
              OnClickWebLink();
            }}
            className="web-app-link"
          >
            Web
          </UnderlineText>
          {convertType(props) !== "COVA" && (
            <UnderlineText
              fontSize={14}
              onClick={(e) => {
                e.stopPropagation();
                !isReux && OnClickAppLink();
              }}
              className="web-app-link"
              disabled={isReux}
            >
              APP
            </UnderlineText>
          )}
        </Container>
        {isOpenSpectrumLogin && (
          <ModalWrapper data-key="recorder-polling-popup">
            <SpectrumLoginConfirmModal
              onClose={onCloseWebLogin}
              onCode={onCode}
              accountId={props.accountId}
              mergedSystemId={props.mergedSystemId}
            />
          </ModalWrapper>
        )}
        {isLocalSpectrumLogin && (
          <ModalWrapper data-key="recorder-polling-popup">
            <SpectrumLogin
              onClose={onCloseLocalLogin}
              onClickSave={onClickSave}
              accountId={props.accountId}
              mergedSystemId={props.mergedSystemId}
            />
          </ModalWrapper>
        )}
      </>
    );
  } else {
    return <></>;
  }
}

export default WebAppLink;

const Container = styled.div`
  ${FLEX.FlexStartCenter};
  gap: 6px;
`;

const ModalWrapper = styled.div`
  position: absolute;
  z-index: 990;
  width: 100vw;
  height: 100vh;
`;
