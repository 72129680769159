import * as s from "../SystemSpecStyled";
import { useQuery } from "react-query";
import { getVmaxStatus } from "api/reportAPI";
import { useLocation } from "react-router-dom";

import Spinner from "components/atoms/loader/Spinner";
import { Content } from "pages/dashboard/map/sub/leftAside/detail/RecorderDetailStyled";
import VMAX from "./VMAX";
import Storage from "../../Storage";
import NoData from "components/templates/nopage/NoData";

type Props = {
  recorderId: string;
  brand?: string;
};

export default function SystemVMAXSpec(props: Props): JSX.Element {
  const location = useLocation();
  const isMap = location.pathname.includes("map");

  const vmaxStatusQuery = useQuery(
    ["vmaxStatusQuery", props.recorderId],
    () =>
      getVmaxStatus({
        recorderId: props.recorderId,
      }),
    {
      retry: 0,
      staleTime: 30000, // 30초
      cacheTime: 60000, // 1분
      refetchInterval: 30000, // 30초마다 refetch
      refetchOnWindowFocus: false,
      onSettled: () => {
        console.log("on seltted");
      },
    }
  );

  const vmaxStatus = vmaxStatusQuery.data?.result;

  return vmaxStatusQuery.isFetching ? (
    <Spinner />
  ) : vmaxStatus ? (
    <Content isNoMap={!isMap}>
      <s.VmaxInner isMap={isMap}>
        <VMAX vmaxStatus={vmaxStatus} brand={props.brand} />
      </s.VmaxInner>
      {isMap && <Storage recorderId={props.recorderId} />}
    </Content>
  ) : (
    <NoData />
  );
}
