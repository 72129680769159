import { LoaderOptions } from "@googlemaps/js-api-loader";
import { Theme } from "redux/reducers/theme/themeReducer";

export const MAP_ID = "d1c7699e1f8ce52f";

// helper function to keep maps in sync
export const sync = (...maps: google.maps.Map[]): void => {
  let center: google.maps.LatLng;
  let zoom: number;

  function update(changedMap: google.maps.Map) {
    maps.forEach((m) => {
      if (m === changedMap) {
        return;
      }

      m.setCenter(center);
      m.setZoom(zoom);
    });
  }

  maps.forEach((m) => {
    m.addListener("bounds_changed", () => {
      let changedCenter: google.maps.LatLng = m.getCenter()!;
      let changedZoom: number = m.getZoom()!;

      if (changedCenter !== center || changedZoom !== zoom) {
        center = changedCenter;
        zoom = changedZoom;
        update(m);
      }
    });
  });
};

export const mapStyle = {
  light: [
    {
      featureType: "administrative.country",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#ffffff",
        },
      ],
    },
    {
      featureType: "landscape",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "landscape.man_made",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "landscape.natural.landcover",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "poi",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "landscape.natural.terrain",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },

    {
      featureType: "transit",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
  ],
  dark: [
    {
      elementType: "geometry",
      stylers: [
        {
          color: "#2a3656",
        },
      ],
    },
    {
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#80a4e8",
        },
      ],
    },
    {
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#212121",
        },
      ],
    },
    {
      featureType: "administrative",
      elementType: "geometry",
      stylers: [
        {
          color: "#303e62",
        },
      ],
    },
    {
      featureType: "administrative.country",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#80a4e8",
        },
      ],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "administrative.locality",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#80a4e8",
        },
      ],
    },
    {
      featureType: "administrative.province",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#4b6878",
        },
      ],
    },
    {
      featureType: "landscape.man_made",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#ffffff",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#80a4e8",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [
        {
          color: "#2C3C64",
        },
      ],
    },

    /* 구글맵 업데이트로 인하여 해당 text color가 중첩이므로 불투명 처리 https://developers.google.com/maps/documentation/javascript/style-reference 공식문서 참조 */
    {
      featureType: "poi.park", // 공원
      elementType: "labels.text.fill",
      stylers: [
        {
          // "color": "#ffffff"
          color: "transparent",
        },
      ],
    },
    /* 업데이트 끝 */

    {
      featureType: "poi.park",
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#1b1b1b",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#80a4e8",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#fff",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#1b1b1b",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "geometry",
      stylers: [
        {
          color: "#32436D",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "labels",
      stylers: [
        {
          color: "#80a4e8",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#1d1d1d",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [
        {
          color: "#32436D",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "labels",
      stylers: [
        {
          color: "#213768",
        },
      ],
    },

    /* 구글맵 업데이트로 인하여 해당 text color가 중첩이므로 불투명 처리 https://developers.google.com/maps/documentation/javascript/style-reference 공식문서 참조 */
    {
      featureType: "road.highway.controlled_access", // 접근이 통제된 고속도로
      elementType: "geometry",
      stylers: [
        {
          // "color": "#213768"
          color: "transparent",
        },
      ],
    },
    /* 업데이트 끝 */

    {
      featureType: "road.local",
      stylers: [
        {
          color: "#32436d",
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "labels",
      stylers: [
        {
          color: "#80a4e8",
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#80a4e8",
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#1d1d1d",
        },
      ],
    },
    {
      featureType: "transit",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#80a4e8",
        },
      ],
    },

    /* 구글맵 업데이트로 인하여 해당 text color가 중첩이므로 불투명 처리 https://developers.google.com/maps/documentation/javascript/style-reference 공식문서 참조 */
    {
      featureType: "transit.line", // 대중교통 노선
      elementType: "geometry.fill",
      stylers: [
        {
          // "color": "#ffffff"
          color: "transparent",
        },
      ],
    },
    {
      featureType: "transit.station", // 모든 대중교통 역
      elementType: "geometry",
      stylers: [
        {
          // "color": "#ffffff"
          color: "transparent",
        },
      ],
    },
    /* 업데이트 끝 */

    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          color: "#384568",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#80a4e8",
        },
      ],
    },
  ],
};

interface MapStyle {
  maxZoom: number;
  minZoom: number;
  zoom: number;
  zoomControl: boolean;
  mapTypeControl: boolean;
  streetViewControl: boolean;
  disableDefaultUI: boolean;
  gestureHandling: string;
  styles: any;
  clickableIcons: boolean;
}

export const getMapOption = (type: string, isPopUp = false): MapStyle => {
  return {
    maxZoom: 17,
    minZoom: 4,
    zoom: !isPopUp ? 5 : 17,
    zoomControl: false,
    mapTypeControl: false,
    streetViewControl: false,
    disableDefaultUI: true,
    gestureHandling: "greedy",
    clickableIcons: false,
    styles: type === "dark" ? mapStyle.dark : mapStyle.light,
  };
};

export const mapOptions = {
  mapId: MAP_ID,
  center: { lat: 39.8097343, lng: -98.5556199 },
  maxZoom: 18,
  minZoom: 3,
  zoom: 5, //RND-446
  zoomControl: false,
  mapTypeControl: false,
  streetViewControl: false,
  disableDefaultUI: true,
  gestureHandling: "greedy",
  // styles: mapStyle.light,
  panControl: false,
  fullscreenControl: false,
  clickableIcons: false,
  restriction: {
    latLngBounds: {
      north: 85,
      south: -85,
      west: -180,
      east: 180,
    },
    strictBounds: false,
  },
};
