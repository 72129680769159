import BigPopupNoHeaderTemplate from "components/templates/modal/big/BigPopupNoHeaderTemplate";
import * as s from "./CvvStyled";
import { Text } from "components/atoms/text/Text";
import { Option } from "utils/options";
import { getRecorderTimeFormat } from "utils/timeUtil";
import { CvvBase } from "./CvvContainer";
import { CVVPairItem, DayNightItem } from "api/interfaces/reportInterface.interface";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import CLOSE_ICON from "assets/icons/sidenav/x.svg";
import WARNIN_CIRCLE_ICON from "assets/icons/warning_circle.svg";
import CVVThumbnail from "components/atoms/image/thumbnail/CVVThumbnail";
import SmallLabel from "components/atoms/text/labels/SmallLabel";
import { COLORS } from "styles/colors";
import { isEmpty } from "lodash";
import {
  checkCVVError,
  checkCVVErrorBlocking,
} from "utils/functions";
import { useAppSelector } from "redux/hooks";
import { cvvEvent } from "./constants";

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

type Props = {
  data: DayNightItem;
  selectFilterRecorder: string,
  recorderList: Option[],
  renderDataName: () => string,
  popupBase: CvvBase,
  currentPairItem: CVVPairItem | null,
  setIsCvvOpen: React.Dispatch<React.SetStateAction<boolean>>,
}

export default function CvvDetailPopup(props: Props) {
  const { data, selectFilterRecorder, recorderList, renderDataName, popupBase, currentPairItem, setIsCvvOpen } = props;

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  return (
    <BigPopupNoHeaderTemplate>
      <s.ModalHeader>
        <s.ModalInfo>
          <s.CameraHeaderItem>
            <Text className="cvv-header-title">Recorders</Text>
            <Text bold>
              {selectFilterRecorder !== undefined &&
                selectFilterRecorder !== ""
                ? recorderList.find(
                  (option) => option.value === selectFilterRecorder
                )?.label
                : ""}
            </Text>
          </s.CameraHeaderItem>

          <s.CameraHeaderItem>
            <Text className="cvv-header-title">Camera</Text>
            <Text bold>{renderDataName()}</Text>
          </s.CameraHeaderItem>

          <s.CameraHeaderItem>
            <Text className="cvv-header-title">Date / Time</Text>
            <Text bold>
              {getRecorderTimeFormat(
                popupBase === CvvBase.EVENT
                  ? currentPairItem!.eventThumbnail.date
                  : currentPairItem!.referenceThumbnail.date,
                selectedAccount,
                timezone,
                true,
                true
              )}
            </Text>
          </s.CameraHeaderItem>
        </s.ModalInfo>

        <button onClick={() => setIsCvvOpen(false)}>
          <img alt="close button" src={CLOSE_ICON} />
        </button>
      </s.ModalHeader>
      <s.CvvContainer
        isError={
          popupBase === CvvBase.EVENT
            ? checkCVVError(currentPairItem!.eventThumbnail)
            : false
        }
      >
        {popupBase === CvvBase.EVENT &&
          checkCVVErrorBlocking(currentPairItem!.eventThumbnail) && (
            <s.CvvHeader>
              <img src={WARNIN_CIRCLE_ICON} alt="warning circle icon" />
              CVV has detected obstructions blocking the camera's view. For
              optimal viewing quality, please remove them as soon as
              possible.
            </s.CvvHeader>
          )}

        <s.CvvThumbnailWrapper>
          <s.ErrorWrapper>
            {popupBase === CvvBase.EVENT &&
              cvvEvent.map((evt, index) => {
                if (
                  data !== null &&
                  currentPairItem!.eventThumbnail[evt] === true
                ) {
                  return <SmallLabel label={evt} color={COLORS.RED} />;
                } else return null;
              })}
          </s.ErrorWrapper>
          <CVVThumbnail
            errorStatus={
              currentPairItem !== undefined &&
                popupBase === CvvBase.REFERENCE
                ? !isEmpty(currentPairItem!.referenceThumbnail.errorStatus)
                  ? `${currentPairItem!.referenceThumbnail.errorStatus}`
                  : undefined
                : !isEmpty(currentPairItem!.eventThumbnail.errorStatus)
                  ? `${currentPairItem!.eventThumbnail.errorStatus}`
                  : undefined
            }
            videoLoss={false}
            thumbnailUrl={`${popupBase === CvvBase.EVENT
              ? currentPairItem!.eventThumbnail.url
              : currentPairItem!.referenceThumbnail.url
              }?v=${Date.now()}`}
          />
        </s.CvvThumbnailWrapper>
      </s.CvvContainer>
    </BigPopupNoHeaderTemplate>

  )
}