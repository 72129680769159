import { TableTemplate } from "components/templates/default/table/TableTemplate";
import {
  VideoGuideGroupDto,
  VideoGuideSetting,
  VideoGuideVideoDto,
} from "api/interfaces/managerInterface.interface";
import * as s from "./VideoManagerStyled";
import TableButton from "components/atoms/buttons/TableButton";
import {
  SelectGroup,
  SelectedCategory,
  VIDEO_GUIDE_TYPE,
} from "./VideoManagerContainer";
import AddVideo from "./add/video";
import AddGroup from "./add/group";
import VideoTree, { Data } from "./tree/VideoTree";
import { TableColumnsType } from "antd";
import { CommonTable } from "components/atoms/table/AntdTable";

type Props = {
  onModal: (type: VIDEO_GUIDE_TYPE, isEdit: boolean) => void;
  data: VideoGuideSetting[];
  treeData: Data[];
  columns: TableColumnsType<VideoGuideSetting>;
  isGroupAddModal: boolean;
  isGroupEditModal: boolean;
  isVideoAddModal: boolean;
  isVideoEditModal: boolean;
  treeRef: React.MutableRefObject<any>;
  selectedCategory: SelectedCategory;
  selectGroup: SelectGroup;
  selectedVideo: VideoGuideSetting | undefined;
  onClickCategory?: (category: SelectedCategory) => void;
  onClickGroup?: (group: SelectGroup) => void;
  onClickAddGroup: (
    groupDto: VideoGuideGroupDto,
    update?: boolean | undefined
  ) => void;
  onClickAddVideo: (
    groupDto: VideoGuideVideoDto,
    update?: boolean | undefined
  ) => void;
  onClickEditGroup: (group: SelectGroup) => void;
};

export function VideoManagerPresenter(props: Props): JSX.Element {
  return (
    <TableTemplate
      onClickBtn={() => props.onModal(VIDEO_GUIDE_TYPE.VIDEO, false)}
      // buttonLabel="Add Video"
      noSearch
    >
      <s.Grid>
        <s.LeftAside>
          <TableButton
            buttonType="primary"
            label={"Add Group"}
            onClick={() => props.onModal(VIDEO_GUIDE_TYPE.GROUP, false)}
          ></TableButton>
          <s.TreeContainer>
            <VideoTree
              data={props.treeData}
              onClickCategory={props.onClickCategory}
              onClickGroup={props.onClickGroup}
              onDelete={props.onClickEditGroup}
              selectedCategory={props.selectedCategory}
              treeRef={props.treeRef}
            />
          </s.TreeContainer>
        </s.LeftAside>
        <s.RightAside>
          <TableButton
            buttonType="primary"
            label={"Add Video"}
            disabled={props.selectGroup.groupId === ""}
            onClick={() => props.onModal(VIDEO_GUIDE_TYPE.VIDEO, false)}
          ></TableButton>
          <CommonTable columns={props.columns} dataSource={props.data} />
        </s.RightAside>
      </s.Grid>
      {props.isGroupAddModal && (
        <AddGroup
          isEdit={false}
          onModal={() => props.onModal(VIDEO_GUIDE_TYPE.GROUP, false)}
          selectedCategory={props.selectedCategory}
          onClickSave={props.onClickAddGroup}
        />
      )}
      {props.isGroupEditModal && (
        <AddGroup
          isEdit={true}
          selectedCategory={props.selectedCategory}
          onModal={() => props.onModal(VIDEO_GUIDE_TYPE.GROUP, true)}
          selectedGroup={props.selectGroup}
          onClickSave={props.onClickAddGroup}
        />
      )}
      {props.isVideoAddModal && (
        <AddVideo
          selectedGroup={props.selectGroup}
          selectedCategory={props.selectedCategory}
          isEdit={false}
          onModal={() => props.onModal(VIDEO_GUIDE_TYPE.VIDEO, false)}
          onClickSave={props.onClickAddVideo}
          selectedVideo={props.selectedVideo}
        />
      )}
      {props.isVideoEditModal && (
        <AddVideo
          isEdit={true}
          selectedGroup={props.selectGroup}
          selectedCategory={props.selectedCategory}
          onModal={() => props.onModal(VIDEO_GUIDE_TYPE.VIDEO, true)}
          onClickSave={props.onClickAddVideo}
          selectedVideo={props.selectedVideo}
        />
      )}
    </TableTemplate>
  );
}
