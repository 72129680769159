import { useLocation } from "react-router-dom";
import { Text, UnderlineText } from "components/atoms/text/Text";
import * as s from "./BillingPaymentStyled";
import PaymentCustomerTable from "./table/PaymentCustomerTable";
import PaymentContactTable, {
  PaymentContactDataInterface,
} from "./table/PaymentContactTable";
import { monthOptions, yearOptions } from "utils/options";
import PaymentPartTable from "./table/PaymentPartTable";
import ModalSubMenu, { Menus } from "components/blocks/modal/menu/ModalSubMenu";
import PaymentAccountTable from "./table/PaymentAccountTable";
import PaymentDetailTable from "./table/PaymentDetailTable";
import {
  BillingCommon,
  GetBilling,
} from "api/interfaces/licenseInterface.interface";
import { changeDateToFormat } from "utils/functions";
import { Date } from "./BillingPaymentContainer";
import {
  BiChevronRight,
  BiChevronLeft,
  BiChevronsRight,
  BiChevronsLeft,
} from "react-icons/bi";
import { COLORS } from "styles/colors";
import * as mediaQuery from "components/MediaQuery";
import BackButton from "components/blocks/subheader/sub/BackButton";
import { useAppSelector } from "redux/hooks";

type Props = {
  accountId: string;
  sortBy: number;
  data: GetBilling;
  date: Date;
  commonData: BillingCommon;
  isCollapsed: boolean;
  onChangeSort: (type: number) => void;
  onChangeDate: (e: any) => void;
  onMoveDate: (name: "year" | "month", direction: "right" | "left") => void;
  onDetail: (accountId: string) => void;
  onCollapsePartItem: (idx: number) => void;
  initialPending: boolean;
};
const menus: Menus[] = [{ label: "PART" }, { label: "ORGANIZATION" }];

export function BillingPaymentPresenter(props: Props): JSX.Element {
  const location = useLocation();
  const billingCommon = props.commonData;
  const themeMode = useAppSelector((state) => state.theme.theme);

  /**
   * {info: ""}
   * contact table 사용 위해 배열로 변환
   */
  const billingContact: PaymentContactDataInterface[] = Object.entries(
    billingCommon.contactInfo
  ).map((contact) => Object.fromEntries(contact.map((c) => ["info", c])));

  return (
    <s.Container>
      <s.Header>
        <li>
          <mediaQuery.Mobile>
            <BackButton />
          </mediaQuery.Mobile>
        </li>
        <li>
          <button onClick={() => props.onMoveDate("year", "left")}>
            <BiChevronsLeft size={30} />
          </button>
          <button onClick={() => props.onMoveDate("month", "left")}>
            <BiChevronLeft size={30} />
          </button>
          <s.Select
            name="month"
            onChange={props.onChangeDate}
            defaultValue={props.date.month}
          >
            {monthOptions.map((month) => {
              return (
                <option
                  key={month.label}
                  value={month.value}
                  selected={month.value === props.date.month}
                >
                  {month.label}
                </option>
              );
            })}
          </s.Select>
          <s.Select
            name="year"
            onChange={props.onChangeDate}
            defaultValue={props.date.year}
          >
            {yearOptions().map((year) => {
              return (
                <option
                  key={year.label}
                  value={year.value}
                  selected={year.value === props.date.year}
                >
                  {year.label}
                </option>
              );
            })}
          </s.Select>
          <button onClick={() => props.onMoveDate("month", "right")}>
            <BiChevronRight size={30} />
          </button>
          <button onClick={() => props.onMoveDate("year", "right")}>
            <BiChevronsRight size={30} />
          </button>
        </li>
        <li>
          {/* <TableButton label="Print" onClick={() => console.log("print")} />
          <TableButton label="Email" onClick={() => console.log("email")} /> */}
        </li>
      </s.Header>
      <s.InfoTitleContainer>
        <li>
          {billingCommon.company &&
            Object.keys(billingCommon.company).length !== 0 && (
              <>
                {billingCommon.company.companyLogo !== "" &&
                  billingCommon.company.companyLogo !== null && (
                    <div className="company-image">
                      <img
                        src={billingCommon.company.companyLogo}
                        alt="company image"
                      />
                    </div>
                  )}

                <Text className="date">{billingCommon.company.name}</Text>
                <Text className="date">{billingCommon.company.address}</Text>
              </>
            )}
        </li>
        <li>
          <h1>Licensing Bill</h1>
          <ul className="billing-info-box">
            <li>
              <Text>Date Issued:</Text>
              <Text className="date">
                {changeDateToFormat(billingCommon.licensing.issued)}
              </Text>
            </li>
            <li>
              <Text>Due Date:</Text>
              <Text className="date">
                {changeDateToFormat(billingCommon.licensing.dueDate)}
              </Text>
            </li>
            <li>
              <Text>Account #:</Text>
              <Text className="date">
                {billingCommon.licensing.accountNumber}
              </Text>
            </li>
            {/* <li>
              <Text>myDW #:</Text>
              <Text className="date">{billingCommon.licensing.mydwNumber}</Text>
            </li> */}
          </ul>
        </li>
      </s.InfoTitleContainer>

      <s.TableBox>
        <PaymentContactTable data={billingContact} />
      </s.TableBox>
      <s.TableBox>
        <PaymentCustomerTable data={billingCommon.customers} />
      </s.TableBox>
      <s.TableBox>
        {location.pathname.includes("billing/payment/detail") ? (
          <PaymentDetailTable sortBy={props.sortBy} date={props.date} />
        ) : (
          <>
            <s.SubMenuWrapper>
              {props.sortBy === 0 && (
                <UnderlineText
                  color={
                    themeMode === "light" ? COLORS.ORANGE : COLORS.SELECTED
                  }
                  fontSize={14}
                  onClick={() => props.onCollapsePartItem(-1)}
                >
                  {props.isCollapsed ? "Collapse All" : "Expand All"}
                </UnderlineText>
              )}
              <span className="title">SORT BY:</span>
              <ModalSubMenu
                menus={menus}
                currentMenu={props.sortBy}
                onChange={props.onChangeSort}
              />
            </s.SubMenuWrapper>
            <s.TableContainer>
              {props.sortBy === 0 &&
                props.data.licenses !== undefined &&
                props.data.licenses.map((license, index) => {
                  return (
                    <PaymentPartTable
                      key={`part-table-${index}`}
                      data={license}
                      onChangeSort={props.onChangeSort}
                      onDetail={props.onDetail}
                      onCollapsePartItem={() => props.onCollapsePartItem(index)}
                    />
                  );
                })}
              {props.sortBy === 1 &&
                props.data.accounts !== undefined &&
                props.data.accounts.map((account, index) => {
                  return (
                    <PaymentAccountTable
                      key={`account-table-${index}`}
                      data={account}
                      onChangeSort={props.onChangeSort}
                      onDetail={props.onDetail}
                    />
                  );
                })}
            </s.TableContainer>
          </>
        )}
      </s.TableBox>
    </s.Container>
  );
}
