import { AxiosResponse } from "axios";
import { AxiosInstance } from "./axiosInstance";
import {
  CheckExistResponse,
  CloudTokenResponse,
  GetLoginRequest,
  GetLoginResponse,
  HTTP_RESULT,
  PostCheckEmailRequest,
  PostCheckEmailResponse,
  PostEmailResendCordRequest,
  PostForgotEmailRequest,
  PostForgotEmailResponse,
  PostLoginRequest,
  PostLoginResponse,
  PostPasswordResetRequest,
  PostPasswordResetResponse,
  PostPhoneResendCordRequest,
  PostRegisterRequest,
  PostRegisterResponse,
  PostResendCordRequest,
  PostResendCordResponse,
  ResponseNewToken,
  Verify2faRequest,
  resendEmailRequest,
} from "./interfaces/authInterface.interface";
import {
  GetNoticeResponse,
  NoticeInfo,
} from "./interfaces/noticeInterface.interface";
import {
  CommonResponse,
  CustomError,
} from "./interfaces/commonInterface.interface";
import { StringLiteral } from "typescript";
import qs from "qs";

const API_PATH = "/auth";

export const postCheckEmail = async (
  payload: PostCheckEmailRequest
): Promise<PostCheckEmailResponse> => {
  try {
    const { data }: AxiosResponse<PostCheckEmailResponse> =
      await AxiosInstance.post(`${API_PATH}/checkEmail`, payload);
    return data;
  } catch (e) {
    const customErr = e as CustomError;
    throw customErr;
  }
};

export const checkPassword = async ({
  payload,
}: {
  payload: PostLoginRequest;
}): Promise<CheckExistResponse> => {
  try {
    const { data }: AxiosResponse<CheckExistResponse> =
      await AxiosInstance.post(`${API_PATH}/checkPassword`, payload);
    return data;
  } catch (e) {
    const customErr = e as CustomError;
    throw customErr;
  }
};

export const getLogin = async (
  payload: GetLoginRequest
): Promise<PostLoginResponse> => {
  try {
    const { data }: AxiosResponse<GetLoginResponse> = await AxiosInstance.get(
      `${API_PATH}/login?code=${encodeURIComponent(payload.code)}`,
    );
    return data;
  } catch (e: any) {
    console.error(e);
    throw e.response.data;
  }
};

export const postLogin = async (
  payload: PostLoginRequest
): Promise<PostLoginResponse> => {
  try {
    const { data }: AxiosResponse<PostLoginResponse> = await AxiosInstance.post(
      `${API_PATH}/login`,
      payload
    );
    //  AxiosInstance.defaults.headers.common["Authorization"] = `Bearer ${data.result.accessToken}`;
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getAuthNotices = async (): Promise<NoticeInfo[]> => {
  try {
    const res: AxiosResponse<GetNoticeResponse> = await AxiosInstance.get(
      // `${API_PATH}/list?${qs.stringify(payload)}&isDisplay=true`
      `${API_PATH}/notice`
    );

    if (res.data.result) return res.data.result;
    else return [];
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};
export const getLogout = async (): Promise<void> => {
  try {
    if (process.env.NODE_ENV === "development") {
      return;
    }
    await AxiosInstance.get(`${API_PATH}/logout`);
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const postVerify = async ({
  payload,
}: {
  payload: PostPhoneResendCordRequest;
}): Promise<PostResendCordResponse> => {
  try {
    const { data }: AxiosResponse<PostResendCordResponse> =
      await AxiosInstance.post(`${API_PATH}/verifyPhoneNumber`, payload);
    return data;
  } catch (e) {
    const customErr = e as CustomError;
    throw customErr;
  }
};

export const postVerifyEmail = async ({
  payload,
}: {
  payload: PostEmailResendCordRequest;
}): Promise<PostResendCordResponse> => {
  try {
    const { data }: AxiosResponse<PostResendCordResponse> =
      await AxiosInstance.post(`${API_PATH}/verifyEmail`, payload);
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const postRegister = async ({
  payload,
  token,
}: {
  payload: PostRegisterRequest;
  token: string;
}): Promise<PostRegisterResponse> => {
  try {
    const { data }: AxiosResponse<PostRegisterResponse> =
      await AxiosInstance.post(`${API_PATH}/register`, payload);
    return data;
  } catch (e) {
    const customErr = e as CustomError;
    throw customErr;
  }
};

export const postResetPassword = async ({
  payload,
}: {
  payload: PostPasswordResetRequest;
}): Promise<AxiosResponse<PostPasswordResetResponse>> => {
  try {
    const res: AxiosResponse<PostPasswordResetResponse> =
      await AxiosInstance.post(`${API_PATH}/reset`, payload);
    return res;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const postResendCode = async ({
  payload,
}: {
  payload: PostResendCordRequest;
}): Promise<PostResendCordResponse> => {
  try {
    const { data }: AxiosResponse<PostResendCordResponse> =
      await AxiosInstance.post(`${API_PATH}/resendCode`, payload);
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const postForgotPassword = async ({
  payload,
}: {
  payload: PostCheckEmailRequest;
}): Promise<PostCheckEmailResponse> => {
  try {
    const { data }: AxiosResponse<PostCheckEmailResponse> =
      await AxiosInstance.post(`${API_PATH}/forgotPassword`, payload);
    return data;
  } catch (e) {
    const customErr = e as CustomError;
    throw customErr;
  }
};

export const postForgotEmail = async ({
  payload,
}: {
  payload: PostForgotEmailRequest;
}): Promise<PostForgotEmailResponse> => {
  try {
    const { data }: AxiosResponse<PostForgotEmailResponse> =
      await AxiosInstance.post(`${API_PATH}/forgotEmail`, payload);
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getCloudToken = async ({
  accountId,
  mergedSystemId,
}: {
  accountId: string;
  mergedSystemId: string;
}): Promise<CloudTokenResponse> => {
  try {
    const { data }: AxiosResponse<CloudTokenResponse> = await AxiosInstance.get(
      `${API_PATH}/cloud/token/${accountId}/${mergedSystemId}`
    );
    return data;
  } catch (e) {
    const customErr = e as CustomError;
    throw customErr;
  }
};

const headers = {
  "Content-Type": "text/plain",
};
export const resendUser = async ({
  accountId,
  userId,
}: {
  accountId: string;
  userId: string;
}): Promise<CommonResponse> => {
  try {
    const { data }: AxiosResponse<CommonResponse> = await AxiosInstance.post(
      `${API_PATH}/resendEmail/${accountId}`,
      userId,
      { headers }
    );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const newToken = async ({
  grantType,
  username,
  refreshToken,
}: {
  grantType: string;
  username: string;
  refreshToken: string;
}): Promise<ResponseNewToken> => {
  try {
    const { data }: AxiosResponse<ResponseNewToken> = await AxiosInstance.post(
      `${API_PATH}/accessToken`,
      { grantType, username, refreshToken }
    );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const postVerify2fa = async ({
  payload,
}: {
  payload: Verify2faRequest;
}): Promise<string> => {
  try {
    const { data }: AxiosResponse<string> = await AxiosInstance.post(
      `${API_PATH}/verify/2fa`,
      payload
    );
    return data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const submitFreeTrial = async({
  payload,
}: {
  payload: { email: string, orgName: string }
}): Promise<string> => {
  try {
    const { data }: AxiosResponse<string> = await AxiosInstance.post(
      `${API_PATH}/freeSignUp`,
      payload
    );
    return data;
  } catch (e) {
    console.error(e);
    throw e;
  }
}