import { useAppSelector } from "redux/hooks";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";

import CheckList, { ListInterface } from "../CheckList";
import { UserGroupUserPermission } from "api/interfaces/userInterface.interface";
import { useIntl } from "react-intl";
import { useMemo } from "react";

interface Props {
  userCheckedList: UserGroupUserPermission;
  userDisabledList: UserGroupUserPermission;
  onChangeDwPermission: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function DwPermissionCheckList(props: Props): JSX.Element {
  const intl = useIntl();
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const userCheckedList: { [key: string]: boolean } = useMemo(() => {
    const { groupName, ...rest } = props.userCheckedList;
    return rest;
  }, [props.userCheckedList]);

  const userDisabledList: { [key: string]: boolean } = useMemo(() => {
    const { groupName, ...rest } = props.userDisabledList;
    return rest;
  }, [props.userDisabledList]);

  const alertPermission = {
    title: "Alert",
    permissionName: "alert",
    check: [
      {
        name: "manageAlertRules",
        label: intl.formatMessage({
          id: "user.permission.manageAlertRules",
          defaultMessage: "Manage Alert Rules",
        }),
      },
      {
        name: "viewAlerts",
        label: intl.formatMessage({
          id: "user.permission.viewAlerts",
          defaultMessage: "View Alerts",
        }),
      },
    ],
  };
  const userPermission = {
    title: "User Management",
    permissionName: "user",
    check: [
      {
        name: "manageUsersAndGroups",
        label: intl.formatMessage({
          id: "user.permission.manageUsersAndGroups",
          defaultMessage: "Manage Users and Groups",
        }),
      },
      {
        name: "viewUsersAndGroups",
        label: intl.formatMessage({
          id: "user.permission.viewUsersAndGroups",
          defaultMessage: "View Users and Groups",
        }),
      },
    ],
  };
  const reportPermission = {
    title: "Report",
    permissionName: "report",
    check: [
      {
        name: "manageReportRules",
        label: intl.formatMessage({
          id: "user.permission.manageReportRules",
          defaultMessage: "Manage Report Rules",
        }),
      },
      {
        name: "viewReports",
        label: intl.formatMessage({
          id: "user.permission.viewReports",
          defaultMessage: "View Reports",
        }),
      },
    ],
  };

  const recorderPermission = {
    title: "Recorders",
    permissionName: "recorders",
    check: [
      {
        name: "manageRecordersAndGroups",
        label: intl.formatMessage({
          id: "user.permission.manageRecordersAndGroups",
          defaultMessage: "Manage Recorders and Groups",
        }),
      },
      {
        name: "viewRecordersAndGroups",
        label: intl.formatMessage({
          id: "user.permission.viewRecordersAndGroups",
          defaultMessage: "View Recorders and Groups",
        }),
      },
    ],
  };
  const orgPermission = {
    title: "Organization Management",
    permissionName: "organization",
    check: [
      {
        name: "manageAccount",
        label: intl.formatMessage({
          id: "user.permission.manageAccount",
          defaultMessage: "Manage Organization",
        }),
      },
      {
        name: "viewAccount",
        label: intl.formatMessage({
          id: "user.permission.viewAccount",
          defaultMessage: "View Organization",
        }),
      },
    ],
  };

  const videoPermission = {
    title: "Video Share",
    permissionName: "videoShare",
    check: [
      {
        name: "view",
        label: intl.formatMessage({
          id: "user.permission.videoShare",
          defaultMessage: "View",
        }),
      },
    ],
  };

  const billingPermission = {
    title: "Billing",
    permissionName: "billing",
    check: [
      {
        name: "manageBillingAndLicenses",
        label: intl.formatMessage({
          id: "user.permission.manageBillingAndLicenses",
          defaultMessage: "Manage Billing",
        }),
      },
    ],
  };

  const dwBillingPermission = {
    title: "Billing",
    permissionName: "dwBilling",
    check: [
      {
        name: "managePlan",
        label: intl.formatMessage({
          id: "user.permission.managePlan",
          defaultMessage: "Manage Plan",
        }),
      },
      {
        name: "viewBilling",
        label: intl.formatMessage({
          id: "user.permission.viewBilling",
          defaultMessage: "View Billing",
        }),
      },
      {
        name: "suspend",
        label: intl.formatMessage({
          id: "user.permission.suspend",
          defaultMessage: "Suspend",
        }),
      },
    ],
  };

  const settingPermission = {
    title: "Settings",
    permissionName: "settings",
    check: [
      {
        name: "myOrganization",
        label: intl.formatMessage({
          id: "user.permission.myOrganization",
          defaultMessage: "My Organization",
        }),
      },
      {
        name: "videoGuideSetting",
        label: intl.formatMessage({
          id: "user.permission.videoGuideSetting",
          defaultMessage: "Video Guide Setting",
        }),
      },
      {
        name: "techSupport",
        label: intl.formatMessage({
          id: "user.permission.techSupport",
          defaultMessage: "Tech Support",
        }),
      },
      {
        name: "siteNotice",
        label: intl.formatMessage({
          id: "user.permission.siteNotice",
          defaultMessage: "Site Notices",
        }),
      },
      {
        name: "emails",
        label: intl.formatMessage({
          id: "user.permission.emails",
          defaultMessage: "Emails",
        }),
      },
    ],
  };

  const renderCheckLists = (lists: Array<ListInterface[]>) => {
    return (
      <>
        {lists.map((list, index) => (
          <CheckList
            key={index}
            list={list}
            isCheck={userCheckedList}
            isDisabled={userDisabledList}
            onCheckedElement={props.onChangeDwPermission}
          />
        ))}
      </>
    );
  };

  const accountLevelRendering = () => {
    const { accountLevel } = selectedAccount;

    if (accountLevel === "DW") {
      return renderCheckLists([
        [orgPermission, userPermission],
        [dwBillingPermission, settingPermission],
      ]);
    }
    if (accountLevel === "EU") {
      return renderCheckLists([
        [userPermission, alertPermission],
        [recorderPermission, reportPermission],
        [videoPermission],
      ]);
    }
    return renderCheckLists([
      [userPermission, alertPermission],
      [orgPermission, reportPermission],
      [billingPermission],
    ]);
  };

  return accountLevelRendering();
}

export default DwPermissionCheckList;
