import { VideoShareUserPost } from "api/interfaces/videoshareInterface.interface";
import { createUserVideoShare, updateVideoSharing } from "api/videoshareAPI";
import { notify } from "components/atoms/notification/Notification";
import { forwardRef, useCallback, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import AddVSPresenter from "./AddVSPresenter";
import { MultiValue } from "react-select";
import { useAppSelector } from "redux/hooks";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { isEmpty } from "lodash";
import { checkEmailRegEx } from "utils/regEx";
import {
  convertDateToStringOrigin,
  getNextDayString,
  setVideoShareExpire,
} from "utils/timeUtil";
import { useIntl } from "react-intl";
import useApiError from "hook/useApiError";
import { CustomError } from "api/interfaces/commonInterface.interface";
import { useSearchParams } from "react-router-dom";
type Props = {
  small?: boolean;
  index: number;
  videoId: string;
  isShare: boolean;
  onChangeModal: (type: string, value: boolean) => void;
};

const nextDay = (today: Date, next: number) => {
  let allowDate = [];
  for (let index = 0; index < next; index++) {
    const nextDay = new Date(today);
    nextDay.setDate(nextDay.getDate() + index);
    nextDay.setHours(0, 0, 0, 0);
    allowDate.push(nextDay);
  }
  return allowDate;
};

const getInitialState = (searchParams: URLSearchParams) => {
  const defaultState = {
    user: [],
    message: "",
    expires: getNextDayString(7),
    canDownload: false,
  };
  const shareEmail = searchParams.get('share');
  const shareLabel = searchParams.get('sharelabel');
  if (!!shareEmail && !!shareLabel) {
    return {
      ...defaultState,
      user: [{label: shareLabel, value: shareEmail}] };
  }
  return defaultState;
}

const AddVSContainer = forwardRef<HTMLDivElement, Props>(
  (
    { small, index, videoId, isShare, onChangeModal }: Props,
    ref
  ): JSX.Element => {
    const intl = useIntl();
    const { handleError } = useApiError();

    const selectedAccount: ProfileAccountInfo = useAppSelector(
      (state) => state.accountSettings
    );

    const [isExpired, setIsExpired] = useState<boolean>(true);
    const [searchParams] = useSearchParams();
    const [state, setState] = useState<VideoShareUserPost>(getInitialState(searchParams));
    const [isValid, setIsValid] = useState<boolean>(true);
    const [isValidEmail, setIsValidEmail] = useState<boolean>(true);

    const queryClient = useQueryClient();

    const today = new Date();
    //const validateShareDate = nextDay(today, 7);

    const mutationCreateUser = useMutation(createUserVideoShare, {
      onSuccess: () => {
        notify(
          "success",
          intl.formatMessage({
            id: "label.videoshare.notify.share.success",
            defaultMessage: "Video shared successfully.",
          })
        );
        queryClient.invalidateQueries("videoshareViewUserList");
        onChangeModal("add", false);
      },
      onError: (err: CustomError) => {
        handleError(
          err,
          intl.formatMessage({
            id: "label.videoshare.notify.share.fail",
            defaultMessage: "Failed to share video.",
          })
        );
      },
    });

    const mutationUpdateSharing = useMutation(updateVideoSharing, {
      onSuccess: () => {
        notify(
          "success",
          intl.formatMessage({
            id: "label.videoshare.notify.share.update.success",
            defaultMessage: "Video share update successfully.",
          })
        );
        queryClient.invalidateQueries("videoShare");
      },
      onError: (err: CustomError) => {
        handleError(
          err,
          intl.formatMessage({
            id: "label.videoshare.notify.share.update.fail",
            defaultMessage: "Failed to update video share.",
          })
        );
      },
    });

    const onChangeSharing = (checked: boolean) => {
      mutationUpdateSharing.mutate({
        videoId: videoId,
        sharing: checked,
      });
    };

    const onSelectUser = (user: MultiValue<any>) => {
      setState({
        ...state,
        user: user,
      });
    };

    useEffect(() => {}, [isShare]);
    const onChangeValue = (e: any) => {
      let {
        target: { checked, name, value },
      } = e;

      if (name === "isExpired") {
        setIsExpired(checked);
        return;
      }
      if (name === "canDownload") {
        setState({
          ...state,
          canDownload: checked,
        });
        return;
      }

      setState({
        ...state,
        [name]: value,
      });
    };

    const onChangeDateValue = (date: Date) => {
      const selectDate = convertDateToStringOrigin(date);

      setState({
        ...state,
        expires: selectDate,
      });
    };

    const onAddUser = useCallback(() => {
      // console.log(state);
      setIsValid(true);
      setIsValidEmail(true);
      if (state.user.length === 0) {
        setIsValid(false);
        return;
      }

      //BUG [RND-380]
      let checkInvalidEmail = true;
      state.user.forEach((value) => {
        if (
          value === undefined ||
          isEmpty(value.value) ||
          !checkEmailRegEx(value.value)
        ) {
          checkInvalidEmail = false;
        }
      });

      if (!checkInvalidEmail) {
        setIsValidEmail(false);
        return;
      }

      mutationCreateUser.mutate({
        videoId: videoId,
        accountId: selectedAccount.accountId,
        payload: {
          ...state,
          user: state.user.map((item) => {
            return {
              name: item.label,
              email: item.value,
            };
          }),
          expires: isExpired
            ? setVideoShareExpire(state.expires, selectedAccount.timezone)
            : null,
        },
      });
    }, [
      isExpired,
      mutationCreateUser,
      selectedAccount.accountId,
      selectedAccount.timezone,
      state,
      videoId,
    ]);

    return (
      <AddVSPresenter
        ref={ref}
        small={small}
        index={index}
        onChangeModal={onChangeModal}
        onChangeValue={onChangeValue}
        onChangeDateValue={onChangeDateValue}
        state={state}
        isExpired={isExpired}
        onAddUser={onAddUser}
        onSelectUser={onSelectUser}
        today={today}
        //validateShareDate={validateShareDate}
        isValid={isValid}
        isValidEmail={isValidEmail}
        isShare={isShare}
        onChangeSharing={onChangeSharing}
      />
    );
  }
);

export default AddVSContainer;
