import { useState } from "react";
import styled from "styled-components";
import INFO_ICON from "assets/icons/info.svg";
import { FLEX } from "styles/flex";
import { Text } from "components/atoms/text/Text";
import InfoBubble from "components/blocks/bubble/InfoBubble";
import * as mediaQuery from "components/MediaQuery";

type CheckIncludeProps = {
  isChecked?: boolean;
  onChange?: (checked: boolean) => void;
  label?: string;
  isCVV?: boolean;
};

//BUG [RND-397] CVV content add
function CheckInclude({
  isChecked,
  onChange,
  label,
  isCVV,
}: CheckIncludeProps): JSX.Element {
  const [isHover, setIsHover] = useState<boolean>(false);
  return (
    <Container>
      <input
        type="checkbox"
        onChange={(e) => {
          if (onChange) {
            onChange(e.currentTarget.checked);
          }
        }}
      />
      <Text bold>{label}</Text>
      <mediaQuery.Default>
        <InfoButton
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          <img src={INFO_ICON} alt="info icon" />
          {isHover &&
            (isCVV === undefined ? (
              <BubbleContainer>
                <InfoBubble>
                  {/* <BubbleContents> */}
                  This option allows you to see alert rules for all of the
                  accounts that operate under
                  <br /> the one you've logged into. This is especially helpful
                  for channel partners who want
                  <br /> to see all of their customer's alerts.
                  {/* </BubbleContents> */}
                </InfoBubble>
              </BubbleContainer>
            ) : (
              <BubbleContainer>
                <InfoBubble>
                  {/* <BubbleContents> */}
                  Using Artificial Intelligence (AI), CVV monitors the image
                  quality of your cameras and alerts you immediately of any
                  deterioration
                  <br /> such as blurriness, spider webs, dust, paint on the
                  enclosure or a change of scene. You can filter the view on
                  this page to only
                  <br /> display cameras and references with CVV by toggling the
                  checkmark next to the bold text that says "Camera View
                  Verification."
                  {/* </BubbleContents> */}
                </InfoBubble>
              </BubbleContainer>
            ))}
        </InfoButton>
      </mediaQuery.Default>
    </Container>
  );
}

const Container = styled.label`
  ${FLEX.FlexStartCenter};
  gap: 6px;
  /* border: 1px solid blue; */
`;

const InfoButton = styled.button`
  ${FLEX.FlexCenterCenter};
  position: relative;
  width: 20px;
  height: 20px;

  ${mediaQuery.isMobile} {
    width: 14px;
    height: 14px;
  }

  img {
    width: 100%;
    height: 100%;
  }
`;

const BubbleContainer = styled.div`
  position: absolute;
  z-index: 299;
  bottom: -80px;
  /* left: 50%;
  right: 50%;
  transform: translate(-50%, 0); */
  left: -10px;
  min-width: max-content;

  .info-bubble {
    :after {
      left: 20px;
    }
  }
`;

const BubbleContents = styled.p`
  left: 100px;
  text-align: left;
`;

export default CheckInclude;
