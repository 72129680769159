import SmallPopupTemplate from "components/templates/modal/small/SmallPopupTemplate";
import * as s from './ScreenRecorderExceptionStyled';
import { detect } from 'detect-browser';
import { IntlShape, useIntl } from "react-intl";

interface ExceptionMessage {
  title: string;
  description: string;
}

type ScreenRecorderExceptionType = {
  error: string,
  onFinish: () => void;
}

export function ScreenRecorderException({ error, onFinish }: ScreenRecorderExceptionType) {
  const intl = useIntl();
  const errorMessage = getErrorMessage(error, intl);

  return (
    <SmallPopupTemplate
      title={errorMessage.title}
      onClick={onFinish}
      onModal={onFinish}
      confirmLabel="OK"
    >
      <s.ErrorMessage>
        {errorMessage.description}
      </s.ErrorMessage>
    </SmallPopupTemplate>
  )
}


function getErrorMessage(error: string, intl: IntlShape): ExceptionMessage {
  const title = intl.formatMessage({
    id: `screenRecordingError.${error}.title`
  });
  let description = intl.formatMessage({
    id: `screenRecordingError.${error}.description`
  });

  if (error === 'permission_denied') {
    const browser = detect();
    if (browser && browser.name) {
      description = intl.formatMessage({
        id: `screenRecordingError.${error}.description.${browser.name}`
      });
    }
  }
  return { title, description };
}
