import * as s from "./MissingStyled";
import { Text } from "components/atoms/text/Text";
import EnterAddress from "./EnterAddress";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { remove } from "lodash";
import { useMutation } from "react-query";
import { notify } from "components/atoms/notification/Notification";
import { putMissingLocation } from "api/recorderAPI";
import { useAppSelector } from "redux/hooks";
import { useIntl } from "react-intl";
import { UnknownRecorderList } from "api/interfaces/recorderInterface.interface";

import MissingListItem from "./MissingListItem";
import { LEVEL_TYPE } from "api/interfaces/commonInterface.interface";

type Props = {
  recorder: UnknownRecorderList[];
  isModalClose: () => void;
  refreshData: () => void;
  itemRef: any;
};

const MissingList = (props: Props): JSX.Element => {
  const intl = useIntl();
  const selectedAccount = useAppSelector((state) => state.accountSettings);
  const [isAssigned, setIsAssigned] = useState<boolean>(false);
  const [checkList, setCheckList] = useState<string[]>([]);

  const listRef = useRef<any>(null);
  const addressRef = useRef<any>(null);
  const mapRef = useRef<any>();

  const [location, setLocation] = useState({
    location: "",
    latitude: 0,
    longitude: 0,
  });

  useEffect(() => {
    const onClick = (event: any) => {
      if (
        props.itemRef.current &&
        !props.itemRef.current.contains(event.target) &&
        listRef.current &&
        !listRef.current.contains(event.target)
      ) {
        props.isModalClose();
        if (addressRef.current && !addressRef.current.contains(event.target)) {
          setIsAssigned(false);
        }
      }
    };

    return function cleanup() {
      window.removeEventListener("click", onClick);
    };
  }, [props.itemRef, listRef, addressRef]);

  const onChangeEach = (e: any, id: string) => {
    // 체크할 시 CheckList에 id값 넣기
    if (e.target.checked) {
      setCheckList([...checkList, id]);
      // 체크 해제할 시 CheckList에서 해당 id값이 `아닌` 값만 배열에 넣기
    } else {
      setCheckList(checkList.filter((checkedId) => checkedId !== id));
    }
  };
  const onChangeAll = useCallback(
    (checked: boolean) => {
      let tempArray = Array.from(checkList);
      props.recorder.forEach((data) => {
        if (checked) {
          if (!tempArray.includes(data.recorderId)) {
            tempArray.push(data.recorderId);
          }
        } else {
          tempArray = remove(tempArray, function (tmp) {
            return tmp !== data.recorderId;
          });
        }
      });
      setCheckList(tempArray);
    },
    [checkList, props.recorder]
  );

  const checkAll = useCallback(() => {
    let checked = true;
    if (props.recorder.length === 0) {
      checked = false;
      return checked;
    }
    props.recorder.forEach((data) => {
      if (!checkList.includes(data.recorderId)) {
        checked = false;
      }
    });
    return checked;
  }, [checkList, props.recorder]);

  const [selected, setSelected] = useState<google.maps.LatLngLiteral>({
    lat: 33.8583483,
    lng: -118.0647871,
  });

  const onChangeLocationSelect = useCallback(
    (address: google.maps.LatLngLiteral, addressString: string) => {
      setLocation({
        location: addressString as string,
        latitude: address.lat as number,
        longitude: address.lng as number,
      });

      setSelected(address);
      mapRef.current.updateInputLocation(address);
    },
    []
  );

  const onChangeAddress = (e: any) => {
    setLocation((location) => {
      return {
        ...location,
        location: e.target.value as string,
      };
    });
  };

  const mutationMissingLocation = useMutation(putMissingLocation, {
    onSuccess: () => {
      if (checkList.length === props.recorder.length) {
        props.isModalClose();
      }
      notify(
        "success",
        intl.formatMessage({
          id: "label.dash.notify.missing.update.success",
          defaultMessage: "Recorder location updated successfully.",
        })
      );

      setCheckList([]);
      props.refreshData();
    },
    onError: () => {
      notify(
        "error",
        intl.formatMessage({
          id: "label.dash.notify.missing.update.fail",
          defaultMessage: "Failed to update recorder location.",
        })
      );
    },
  });

  const onApply = useCallback(() => {
    if (checkList.length === 0) {
      return;
    }

    if (location.location === "" || location.latitude === 0) {
      return;
    }

    mutationMissingLocation.mutate({
      payload: {
        location: location,
        recorderIds: checkList,
      },
      accountId: selectedAccount.accountId,
    });
  }, [checkList, selectedAccount.accountId, location, mutationMissingLocation]);

  return (
    <s.MissingWrapper>
      <s.ListContainer
        ref={listRef}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <s.ListTitle>
          {selectedAccount.accountLevel === LEVEL_TYPE.EU ? (
            <Fragment>
              <input
                className="input_checkbox"
                type="checkbox"
                // onChange={() => setIsAssigned(!isAssigned)}
                onChange={(e) => onChangeAll(e.currentTarget.checked)}
                checked={checkAll()}
                disabled={selectedAccount.accountLevel !== LEVEL_TYPE.EU}
              />
              <Text bold fontSize={14}>
                Select All
              </Text>
            </Fragment>
          ) : (
            <Fragment>
              <Text bold fontSize={14}>
                Missing List
              </Text>
            </Fragment>
          )}
        </s.ListTitle>

        <s.ListWrapper>
          {props.recorder.map((item, index) => (
            <MissingListItem
              key={`unknown-list-${index}`}
              item={item}
              onChangeEach={onChangeEach}
              checkList={checkList}
            />
          ))}
        </s.ListWrapper>
        {selectedAccount.accountLevel === LEVEL_TYPE.EU && (
          <EnterAddress
            addressRef={addressRef}
            isLoaded={true}
            mapRef={mapRef}
            location={location}
            selected={selected}
            onMarkerChange={onChangeLocationSelect}
            onChangeLocationSelect={onChangeLocationSelect}
            onChangeAddress={onChangeAddress}
            onApply={onApply}
          />
        )}
      </s.ListContainer>
    </s.MissingWrapper>
  );
};

export default MissingList;
