import * as s from "./SideNavBarStyled";
import MYDW_ICON from "assets/icons/mydw-logo.png";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setMenu } from "redux/reducers/menu/menuSlice";
import { useAuth } from "components/Auth";
import {
  LEVEL_TYPE,
  ProfileAccountInfo,
} from "api/interfaces/accountInterface.interface";
import * as mediaQuery from "components/MediaQuery";
import UserProfile from "../headernav/sub/UserProfile";
import { checkGroup, checkTrialShowMenu } from "utils/AccountUtil";
import { GoPageNav, menuNavigation } from "utils/MenuUtil";
import { getStartPageUrl } from "utils/functions";

type SideBarProps = {
  isCollapsed: boolean;
  onOpen?: () => void;
  onChangeSwitch: () => void;
};

function TopInfo({
  isCollapsed,
  onOpen,
  onChangeSwitch,
}: SideBarProps): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const auth = useAuth();
  const account: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  return (
    <s.ProfileContainer>
      {/* logo */}
      <s.Logo
        alt="logo"
        src={
          account.companyLogo !== "" && account.companyLogo !== undefined
            ? account.companyLogo
            : MYDW_ICON
        }
        isCollapsed={isCollapsed}
        onClick={() => {
          //auth.loginNavigator( "/", auth.user);
          if (checkTrialShowMenu(account)) {
            return;
          } else {
            //RND-737
            if (account.accountLevel === LEVEL_TYPE.DW) {
              if (account.isAdmin) {
                dispatch(setMenu("Dashboard"));
                menuNavigation(account, navigate, getStartPageUrl(3), true);
              } else {
                dispatch(setMenu("Dashboard"));
                menuNavigation(account, navigate, getStartPageUrl(2), true);
              }
              // navigate(getStartPageUrl(3), {
              //   replace: true,
              // });
            } else {
              dispatch(setMenu("Dashboard"));
              menuNavigation(account, navigate, getStartPageUrl(1), true);
              // navigate(getStartPageUrl(1), {
              //   replace: true,
              // });
            }
            // if ([1, 2, 3].includes(auth.user.startPage)) {
            //   if(account.accountLevel === LEVEL_TYPE.DW && auth.user.startPage  === 1){
            //     dispatch(setMenu("Dashboard"));
            //     navigate(getStartPageUrl(3), {
            //       replace: true,
            //     });
            //   }else if(( account.accountLevel === LEVEL_TYPE.CP ||  account.accountLevel === LEVEL_TYPE.EU) && !account.isAdmin && auth.user.startPage  === 3){
            //     dispatch(setMenu("Dashboard"));
            //     navigate(getStartPageUrl(1), {
            //       replace: true,
            //     });
            //   }else{
            //     dispatch(setMenu("Dashboard"));
            //     navigate(getStartPageUrl(auth.user.startPage), {
            //       replace: true,
            //     });
            //   }
            // } else {
            //   GoPageNav(navigate, dispatch, getStartPageUrl(3), {
            //     replace: true,
            //   });
            //}
            onOpen && onOpen();
          }
        }}
      />
      <mediaQuery.Mobile>
        <UserProfile onChangeSwitch={onChangeSwitch} isScreenRecording={false} onConfirmScreenRecording={() => {}} />
        <s.UserInfo>
          <s.SidebarLevel level={account.accountLevel}>
            {/* 직급 ex. DW, CP */}
            {account.accountLevel}
          </s.SidebarLevel>
        </s.UserInfo>
      </mediaQuery.Mobile>
    </s.ProfileContainer>
  );
}

export default TopInfo;
