import SearchInput from "components/atoms/input/search/SearchInput";

import * as s from "../AccountStyled";
import {
  GetAccountListQuery,
  ProfileAccountInfo,
} from "api/interfaces/accountInterface.interface";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { SORT_TYPE } from "api/interfaces/commonInterface.interface";
import { Account } from "api/mappers/accountMapper";
import { useQuery } from "react-query";
import { getSwitchAccountList } from "api/accountAPI";
import { getSwitchAccountInfo } from "utils/AccountUtil";
import { useIntl } from "react-intl";
import SwitchAccountList from "./SwitchAccountList";
import ModalTemplate from "components/templates/modal/ModalTemplate";
import ModalHeader from "components/blocks/modalheader/ModalHeader";
import Spinner from "components/atoms/loader/Spinner";
import { UnderlineText } from "components/atoms/text/Text";
import { COLORS } from "styles/colors";
import { useAuth } from "components/Auth";
import { navigateSwitchAccount } from "utils/AuthUtil";

type Props = {
  onChangeSwitch: () => void;
  isForceSwitch?: boolean;
};

export type SwitchAccount = {
  level: string;
  accounts: Account[];
};

export default function SwitchAccount(props: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const intl = useIntl();
  const auth = useAuth();
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const preventGoBack = () => {
    window.history.pushState(null, "", "");
  };
  useEffect(() => {
    window.history.pushState(null, "", "");
    window.history.replaceState(null, "", window.location.href);
    window.addEventListener("popstate", preventGoBack);
    return () => {
      window.removeEventListener("popstate", preventGoBack);
    };
  }, [navigate]);

  const onChangeSwitch = () => {
    if (props.isForceSwitch) {
      return;
    }
    props.onChangeSwitch();
  };

  const onClickAccount = (account: Account) => {
    getSwitchAccountInfo(account.accountId).then(function (selectAccount) {
      if (!!selectAccount) {
        navigateSwitchAccount({
          selectAccount,
          auth,
          navigate,
          dispatch,
          intl,
        });
      }
    });
    onChangeSwitch();
  };
  const [accountQueryInfo, setAccountQueryInfo] = useState<GetAccountListQuery>(
    {
      pageNum: 0,
      pageLimit: 10000,
      keyword: "",
      sortType: "name",
      sortDirection: SORT_TYPE.ASC,
    }
  );

  const [accounts, setAccounts] = useState<SwitchAccount[]>([]);
  const getAccountSwitch = useQuery(
    ["accountSwitch", accountQueryInfo, selectedAccount],
    () =>
      getSwitchAccountList({
        payload: accountQueryInfo,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        // console.log(res);
        if (res.result === undefined) {
          setAccounts([]);
        } else {
          let tempDW: Account[] = [];
          let tempEU: Account[] = [];
          let tempCP: Account[] = [];
          let tempSubCP: Account[] = [];

          res.result.map((acc: Account) => {
            if (acc.level === "EU") {
              tempEU.push(acc);
            }
            if (acc.level === "CP") {
              if (acc.parentLevel === "CP") {
                tempSubCP.push(acc);
              } else {
                tempCP.push(acc);
              }
            }
            if (acc.level === "DW") {
              tempDW.push(acc);
            }
          });
          let tempArr: SwitchAccount[] = [
            {
              level: "DW",
              accounts: tempDW,
            },

            {
              level: "CP",
              accounts: tempCP,
            },
            {
              level: "SUB_CP",
              accounts: tempSubCP,
            },
            {
              level: "EU",
              accounts: tempEU,
            },
          ];
          setAccounts(tempArr);
        }
      },
      onError: (e: any) => {
        setAccounts([]);
      },
    }
  );

  const onClickSearch = (value: string) => {
    setAccountQueryInfo({ ...accountQueryInfo, keyword: value });
  };

  return (
    <ModalTemplate>
      <s.SwitchInner>
        <ModalHeader
          title={"Switch Organization"}
          onModal={onChangeSwitch}
          disableClose={props.isForceSwitch}
        />
        {getAccountSwitch.isLoading ? (
          <s.SpinnerContainer>
            <Spinner />
          </s.SpinnerContainer>
        ) : (
          <div className="inner">
            <SearchInput
              placeholder="Search Organization"
              onClickSearch={onClickSearch}
            />

            <s.GroupWrapper>
              {accounts.map((account: SwitchAccount, idx: number) => {
                if (account.accounts.length > 0 && account.level !== "DW") {
                  return (
                    <SwitchAccountList
                      key={idx}
                      account={account}
                      onClickAccount={onClickAccount}
                    />
                  );
                }
              })}
            </s.GroupWrapper>
            {accounts.length > 0 &&
              accounts.filter((ac) => ac.level === "DW")[0].accounts.length >
                0 && (
                <UnderlineText
                  color={COLORS.COMPANY}
                  fontSize={14}
                  onClick={() =>
                    onClickAccount(
                      accounts.filter((ac) => ac.level === "DW")[0].accounts[0]
                    )
                  }
                >
                  DW Admin Account
                </UnderlineText>
              )}
          </div>
        )}
      </s.SwitchInner>
    </ModalTemplate>
  );
}
