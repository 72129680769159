import BigButton from "components/atoms/buttons/BigButton";
import Spinner from "components/atoms/loader/Spinner";
import SmallPopupTemplate from "components/templates/modal/small/SmallPopupTemplate";
import * as s from './ScreenRecorderVideoShareStyled';

export function VideoShareUploading() {
  const buttons: JSX.Element = (
    <BigButton
      disabled
      label={'Share this video with\ntechsupport@digital-watchdog.com'}
      onClickBtn={() => {}}
    />
  )

  return (
    <SmallPopupTemplate
      title="Uploading..."
      disableClose
      buttons={buttons}
      onClick={() => {}}
    >
      <s.Content>
        <Spinner />
        <br /><br /><br />
        <p>Once the upload is complete,<br />you can check it on Video Share.</p>
      </s.Content>
    </SmallPopupTemplate>
  )
}
