import { getAlertHistoryMergedList } from "api/alertAPI";
import { AlertHistoryList } from "api/interfaces/alertInterface.interface";
import { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import {
  BasePagination,
  LEVEL_TYPE,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import TableButton from "components/atoms/buttons/TableButton";
import * as s from "../AlertStyled";
import Type from "components/atoms/text/labels/Type";
import * as mediaQuery from "components/MediaQuery";
import { notify } from "components/atoms/notification/Notification";
import { getRecorderTimeFormat } from "utils/timeUtil";
import { convertType } from "utils/functions";
import { useIntl } from "react-intl";
import { isNull } from "lodash";
import { queryNavigate } from "utils/MenuUtil";
import { TableColumnsType } from "antd";
import {
  changeSortOrderAntd,
  CommonTable,
  onSortAntd,
} from "components/atoms/table/AntdTable";
import { ColumnType } from "antd/es/table";
import AccountId from "components/atoms/text/labels/AccountId";

const initialQueryMergedInfo = {
  total: 0,
  pageNum: 0,
  pageLimit: 20,
  keyword: "",
  sortType: "name",
  sortDirection: SORT_DIRECTION.ASC,
};

export default function AlertHistoryMergedList(): JSX.Element {
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const { keyword, setAlertDetail, setAlertTotal, setTitleComponent } =
    useOutletContext<any>();

  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const { mergedSystemId } = useParams();

  const [isPending, setIsPending] = useState<boolean>(true);

  useEffect(() => {
    const changeParam = new URLSearchParams(location.search);
    if (changeParam.size > 0) {
      setAlertHistoryMergedQueryInfo((info) => {
        return {
          ...info,
          pageNum: isNull(changeParam.get("pageNum"))
            ? 0
            : Number(changeParam.get("pageNum")),
          pageLimit: isNull(changeParam.get("pageLimit"))
            ? 20
            : Number(changeParam.get("pageLimit")),
          keyword: isNull(changeParam.get("keyword"))
            ? ""
            : (changeParam.get("keyword") as string),
          sortType: isNull(changeParam.get("sortType"))
            ? "name"
            : (changeParam.get("sortType") as string),
          sortDirection: isNull(changeParam.get("sortDirection"))
            ? SORT_DIRECTION.ASC
            : (changeParam.get("sortDirection") as SORT_DIRECTION),
        };
      });
    } else {
      setAlertHistoryMergedQueryInfo((info) => {
        return {
          ...info,
          pageNum: 0,
          pageLimit: 20,
          keyword: "",
          sortType: "name",
          sortDirection: SORT_DIRECTION.ASC,
        };
      });
    }
  }, [location]);

  const [data, setData] = useState<AlertHistoryList[]>([]);

  const [systemTitle, setSystemTitle] = useState<string>("");

  const [alertHistoryMergedQueryInfo, setAlertHistoryMergedQueryInfo] =
    useState<BasePagination>({
      ...initialQueryMergedInfo,
    });

  const onHeaderCell = (column: ColumnType<AlertHistoryList>) => {
    return {
      onClick: () => {
        if (column.key) {
          setAlertHistoryMergedQueryInfo((info) => {
            return {
              ...info,
              sortType: column.key as string,
              sortDirection: onSortAntd(info.sortDirection),
            };
          });
        }
      },
    };
  };

  // alert history
  const columns: TableColumnsType<AlertHistoryList> = [
    {
      title: "Type",
      render: (value, row) => <Type type={convertType(row)} />,
      width: 110,
    },
    {
      title: "Organization",
      render: (value, row) => (
        <s.AccountBox>
          {row.accountName}
          <AccountId accountId={row.accountNumber} level={LEVEL_TYPE.EU} />
        </s.AccountBox>
      ),
      width: 200,
      hidden: selectedAccount.accountLevel === LEVEL_TYPE.EU,
    },
    {
      title: "Recorder Title",
      // minWidth: "160px",
      render: (row) => row.recorderName,
      key: "name",
      sortOrder: changeSortOrderAntd(alertHistoryMergedQueryInfo, "name"),
      sorter: true,
      onHeaderCell: onHeaderCell,
      defaultSortOrder: "ascend",
      ellipsis: true,
    },
    {
      title: "System",
      render: (row) => (row.systemCount !== undefined ? row.systemCount : 0),
      align: "center",
      responsive: ["md"],
      width: 100,
    },
    {
      title: "Disk",
      render: (row) => (row.diskCount !== undefined ? row.diskCount : 0),
      align: "center",
      responsive: ["md"],
      width: 100,
    },
    {
      title: "Video",
      render: (row) => (row.videoCount !== undefined ? row.videoCount : 0),
      align: "center",
      responsive: ["md"],
      width: 100,
    },
    {
      title: "Last Alert",
      render: (row) =>
        row.date !== undefined
          ? getRecorderTimeFormat(row.date, selectedAccount, row.timezone, true)
          : "",
      responsive: ["md"],
      width: 200,
    },
    {
      title: "",
      render: (row) => (
        <span className="row-click-button table-button-wrapper">
          <TableButton
            label="View"
            onClick={() => onRowClick(row)}
            // disabled={
            //   row.systemCount + row.diskCount + row.videoCount === 0
            //     ? true
            //     : false
            // }
          />
        </span>
      ),
      align: "right",
      width: mediaQuery.useIsMobile() ? 30 : 100,
    },
  ];
  const onClickMerged = (row: AlertHistoryList) => {
    navigate({
      pathname: `/alert/history/merged/${row.mergedSystemId}`,
      search: `?${createSearchParams({
        account: selectedAccount.accountId,
      })}`,
    });
  };

  const onRowClick = (row: AlertHistoryList) => {
    if (row.recorderCount !== undefined && row.recorderCount !== 1) {
      onClickMerged(row);
    } else {
      navigate({
        pathname: `/alert/history/detail`,
        search: `?${createSearchParams({
          recorderId: row.recorderId,
          account: selectedAccount.accountId,
        })}`,
      });
    }
  };

  const onInitialData = () => {
    setSystemTitle("");
    setData([]);
    setAlertDetail({
      system: 0,
      disk: 0,
      video: 0,
    });
    setAlertHistoryMergedQueryInfo({
      ...initialQueryMergedInfo,
    });
    setAlertTotal(0);
  };

  const alertHistoryMergedQuery = useQuery(
    ["alertHistoryMerged", alertHistoryMergedQueryInfo, mergedSystemId],
    () =>
      getAlertHistoryMergedList({
        accountId: selectedAccount.accountId,
        systemId: mergedSystemId as string,
        payload: alertHistoryMergedQueryInfo,
      }),
    {
      retry: 0,
      enabled: selectedAccount.accountId !== "",
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res.error !== 0 && res.result === undefined) {
          onInitialData();
        } else {
          setAlertTotal(res.result.total);
          setSystemTitle(res.result.mergedSystemName);
          setAlertDetail({
            system: res.result.systemCount || 0,
            disk: res.result.diskCount || 0,
            video: res.result.videoCount || 0,
          });
          setData(res.result.alertRule);
          setAlertHistoryMergedQueryInfo((info) => {
            return {
              ...info,
              ...res.page,
            };
          });
        }
      },
      onError: (e: any) => {
        notify(
          "error",
          intl.formatMessage({
            id: "label.alertHistory.notify.subSystem.fail",
            defaultMessage: "Could not search recorder",
          })
        );
        onInitialData();
      },
      onSettled: (e: any) => {
        setIsPending(false);
      },
    }
  );

  useEffect(() => {
    setAlertHistoryMergedQueryInfo((query) => {
      return {
        ...query,
        keyword,
      };
    });
  }, [keyword]);

  useEffect(() => {
    setTitleComponent([
      {
        label: "Alert History",
        onClick: () => navigate("/alert/history"),
      },
      {
        label: systemTitle,
      },
    ]);
  }, [systemTitle]);

  const onChangePage = (page: number, totalRows: number) => {
    if (page !== alertHistoryMergedQueryInfo.pageNum + 1) {
      queryNavigate(
        navigate,
        location.pathname,
        {
          pageLimit: alertHistoryMergedQueryInfo.pageLimit,
          pageNum: page - 1,
          keyword: alertHistoryMergedQueryInfo.keyword as string,
          sortType: alertHistoryMergedQueryInfo.sortType,
          sortDirection:
            alertHistoryMergedQueryInfo.sortDirection as SORT_DIRECTION,
          type: alertHistoryMergedQueryInfo.type,
        },
        selectedAccount
      );
      setIsPending(true);
    }
  };

  return (
    <CommonTable
      columns={columns}
      dataSource={data}
      queryInfo={alertHistoryMergedQueryInfo}
      onChangePage={onChangePage}
      onRow={(record, rowIndex) => {
        return {
          onClick: (event) => {
            onRowClick(record);
          },
        };
      }}
      loading={isPending}
    />
  );
}
