import { useEffect, useRef, useState } from "react";
import * as s from "../AlertViewStyled";
import "react-multi-carousel/lib/styles.css";
import { useLocation } from "react-router-dom";

import { AlertList, AlertType } from "api/interfaces/alertInterface.interface";
import { Option, alertStatusOptions } from "utils/options";
import AlertTypeSelect from "components/atoms/select/modal/AlertTypeSelect";
import CCTVRED from "assets/icons/cctv_red.svg";
import SYSTEMALERT from "assets/icons/system_alert.svg";

import { useIntl } from "react-intl";
import VideoThumbnail from "components/atoms/image/thumbnail/VideoThumbnail";
import { useQueryClient } from "react-query";
import { filteringDiskAlertBoolean } from "utils/AlertUtil";
import { isCheckPermission } from "utils/AccountUtil";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";

interface Props {
  alertType: AlertType;
  onChangeCurrentAlert: (alertType: AlertType, value?: string) => void;
  currentAlert: AlertList;
  onChangeStatus: (value: string) => void;
}

const AlertTypeItem = (props: Props) => {
  const intl = useIntl();
  const location = useLocation();
  const queryClient = useQueryClient();
  const isMap = location.pathname.includes("map");
  const selectRef = useRef(null);
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const alertListStyled = s.AlertTypeListStyled;

  const [selectIds, setSelectIds] = useState<string>(
    props.alertType.alertHistoryId
  );

  const onChangeDetailId = (value: string) => {
    setSelectIds(value);
  };

  const onChangeTypeSelect = (value: string) => {
    onChangeDetailId(value);
    props.onChangeCurrentAlert(props.alertType, value);
  };

  const onChangeCurrentAlert = (event: any) => {
    if (!event.target.classList.value.includes("alert-type-select-box")) {
      if (
        props.alertType.type === "Video Loss" ||
        filteringDiskAlertBoolean(props.alertType)
      ) {
        props.onChangeCurrentAlert(props.alertType, selectIds);
      } else {
        props.onChangeCurrentAlert(props.alertType);
      }
    }
  };

  const icon = (type: string) => {
    if (type === "Video Loss") {
      return CCTVRED;
    } else {
      return SYSTEMALERT;
    }
  };

  useEffect(() => {
    setSelectIds(props.currentAlert.alertHistoryId);
  }, [props.currentAlert]);

  return (
    <alertListStyled.AlertItem
      selected={props.currentAlert.type === props.alertType.type}
      isMap={isMap}
      onClick={onChangeCurrentAlert}
    >
      <alertListStyled.TitleWrapper isMap={isMap}>
        <div className="title">
          <div className="alert-type-icon">
            <img src={icon(props.alertType.type)} />
          </div>
          <span>{props.alertType.type}</span>
        </div>
        {props.alertType.type === "Video Loss" &&
          props.alertType.cameraList !== undefined && (
            <alertListStyled.VideoLossThumbnail>
              <VideoThumbnail
                videoLoss={false}
                thumbnailUrl={
                  props.alertType.cameraList.find(
                    (cam) => cam.value === selectIds
                  )?.thumbnailUrl ?? props.alertType.cameraList[0].thumbnailUrl
                }
                isRecorderList={true}
              />
            </alertListStyled.VideoLossThumbnail>
          )}
      </alertListStyled.TitleWrapper>
      <alertListStyled.SelectWrapper isMap={isMap}>
        {props.alertType.type === "Video Loss" ||
        filteringDiskAlertBoolean(props.alertType) ? (
          <div className="select-box video" ref={selectRef}>
            {props.alertType.type === "Video Loss" &&
              props.alertType.cameraList !== undefined && (
                <AlertTypeSelect
                  classNamePrefix="alert-type-select-box"
                  options={props.alertType.cameraList}
                  value={props.alertType.cameraList.find(function (option) {
                    return option.value === selectIds;
                  })}
                  // value={props.alertType.cameraList.find(function (option) {
                  //   return option.value === selectIds.cameraId;
                  // })}
                  onChange={(value: Option) => {
                    onChangeTypeSelect(value.value);
                  }}
                  isDisabled={props.alertType.cameraList.length === 1}
                />
              )}

            {filteringDiskAlertBoolean(props.alertType) &&
              props.alertType.storageList !== undefined && (
                <AlertTypeSelect
                  // defaultValue={props.alertType.storageList[0]}
                  classNamePrefix="alert-type-select-box"
                  options={props.alertType.storageList}
                  value={props.alertType.storageList.find(function (option) {
                    return option.value === selectIds;
                  })}
                  onChange={(value: Option) => {
                    onChangeTypeSelect(value.value);
                  }}
                  isDisabled={props.alertType.storageList.length === 1}
                />
              )}
          </div>
        ) : (
          <div></div>
        )}
        {isCheckPermission("manageAlertRules", selectedAccount) && (
          <div className="select-box" ref={selectRef}>
            <AlertTypeSelect
              options={alertStatusOptions}
              onChange={(value: Option) => props.onChangeStatus(value.value)}
              isDisabled={props.alertType.status === "Closed"}
              value={
                props.alertType.status === "Assigned"
                  ? alertStatusOptions.find(function (option) {
                      return option.value === "Active";
                    })
                  : alertStatusOptions.find(function (option) {
                      return option.value === props.alertType.status;
                    })
              }
              // defaultValue={defaultValue}
            />
          </div>
        )}
      </alertListStyled.SelectWrapper>
    </alertListStyled.AlertItem>
  );
};

export default AlertTypeItem;
