import * as s from "./AlertViewStyled";
import {
  AlertActiveSummary,
  AlertList,
  AlertTraceList,
  AlertType,
  AlertViewInfo,
} from "api/interfaces/alertInterface.interface";
import { useAppSelector } from "redux/hooks";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import Status, {
  StatusName,
  StatusValue,
} from "components/blocks/status/Status";
import TraceList from "./components/TraceList";
import AlertInfo from "./components/AlertInfo";
import { COLORS } from "styles/colors";

import TinyButton from "components/atoms/buttons/TinyButton";
import AlertChangeConfirm from "../../../components/templates/modal/small/alert/AlertChangeConfirm";
import AlertTypeList from "./components/AlertTypeList";
import { useLocation } from "react-router-dom";
import { FaCircleCheck } from "react-icons/fa6";
import Spinner from "components/atoms/loader/Spinner";
import NoData from "components/templates/nopage/NoData";
import { isCheckPermission } from "utils/AccountUtil";
import AlertViewHeader from "./AlertViewHeader";

interface Props {
  alertInfo: AlertViewInfo | null;
  currentAlert: AlertList | null;
  alertTypeList: AlertType[];
  traceList: AlertTraceList[];
  count: AlertActiveSummary;
  isModal?: boolean;
  onModal?: () => void;
  isFetching: boolean;
  onCloseAllStatus: () => void;
  isConfirmStatus: boolean;
  onConfirmCancel: () => void;
  onConfirmOk: (checked: boolean) => void;
  onChangeCurrentAlert: (alertType: AlertType, value?: string) => void;
  onCheckNoConfirm: (value: boolean) => void;
  checkNoConfirm: boolean;
  historyId: string | null;
}

export interface TraceListData {
  action: string;
  createdAt: string; // 추후 Date로 변경
  username: string;
  comment: string;
  assigned?: string;
}

const alertStatusStyled = s.AlertStatusStyled;
const AlertViewPresenter = (props: Props) => {
  const location = useLocation();
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const isMap = location.pathname.includes("map");
  const isClosed =
    (props.historyId && props.currentAlert?.status === "Closed") ||
    selectedAccount.isHidden;

  const status = {
    items: [
      <>
        <StatusName>System</StatusName>

        <StatusValue color={COLORS.RED}>{props.count.systemCount}</StatusValue>
      </>,
      <>
        <StatusName>Disk</StatusName>

        <StatusValue color={COLORS.RED}>{props.count.diskCount}</StatusValue>
      </>,
      <>
        <StatusName>Video</StatusName>

        <StatusValue color={COLORS.RED}>{props.count.videoCount}</StatusValue>
      </>,
    ],
  };

  if (isMap) {
    return props.isFetching ? (
      <Spinner />
    ) : !props.currentAlert ||
      props.currentAlert.status === "Healthy" ||
      props.currentAlert.status === "Warning" ||
      !props.alertInfo ? (
      <s.AlertViewNoData>
        <FaCircleCheck color={COLORS.GREEN} size={42} />
        No Alert
      </s.AlertViewNoData>
    ) : (
      <s.AlertMapViewWrapper>
        <AlertTypeList
          alertInfo={props.alertInfo}
          alertTypeList={props.alertTypeList}
          currentAlert={props.currentAlert}
          onChangeCurrentAlert={props.onChangeCurrentAlert}
          onCheckNoConfirm={props.onCheckNoConfirm}
          checkNoConfirm={props.checkNoConfirm}
          onModal={props.onModal}
        />
        <AlertInfo data={props.currentAlert} />
        <TraceList
          data={props.traceList}
          alertHistoryId={props.currentAlert.alertHistoryId}
          recorderId={props.alertInfo.recorderId}
          assignee={props.currentAlert.assignee}
          isClosed={isClosed}
        />
      </s.AlertMapViewWrapper>
    );
  }

  return (
    <s.AlertWrapper>
      {props.alertInfo && props.currentAlert && props.isConfirmStatus && (
        <AlertChangeConfirm
          isAll
          onConfirmCancel={props.onConfirmCancel}
          onConfirmOk={props.onConfirmOk}
          alertInfo={props.alertInfo}
          currentAlert={props.currentAlert}
        />
      )}

      {props.isModal && <AlertViewHeader {...props}></AlertViewHeader>}

      <s.Inners isModal={props.isModal}>
        {props.isFetching ? (
          <Spinner />
        ) : !props.currentAlert || !props.alertInfo ? (
          <s.AlertViewNoData>
            <NoData />
          </s.AlertViewNoData>
        ) : (
          <>
            {!props.historyId && props.isModal && (
              <alertStatusStyled.AlertStatusWrapper>
                <Status status={status} />
                {isCheckPermission("manageAlertRules", selectedAccount) &&
                  !(
                    props.historyId && props.currentAlert?.status === "Closed"
                  ) && (
                    <alertStatusStyled.AlertStatusChange>
                      <TinyButton
                        onClickBtn={props.onCloseAllStatus}
                        label="CLOSE ALL"
                        buttonType="warning"
                      />
                    </alertStatusStyled.AlertStatusChange>
                  )}
              </alertStatusStyled.AlertStatusWrapper>
            )}
            <s.InnerContents>
              {!props.isModal && <AlertViewHeader {...props}></AlertViewHeader>}
              <AlertTypeList
                alertInfo={props.alertInfo}
                alertTypeList={props.alertTypeList}
                currentAlert={props.currentAlert}
                onChangeCurrentAlert={props.onChangeCurrentAlert}
                onCheckNoConfirm={props.onCheckNoConfirm}
                checkNoConfirm={props.checkNoConfirm}
                onModal={props.onModal}
              />
              <AlertInfo data={props.currentAlert} />
              <TraceList
                data={props.traceList}
                alertHistoryId={props.currentAlert.alertHistoryId}
                recorderId={props.alertInfo.recorderId}
                assignee={props.currentAlert.assignee}
                isClosed={isClosed}
              />
            </s.InnerContents>
          </>
        )}
      </s.Inners>
    </s.AlertWrapper>
  );
};

export default AlertViewPresenter;
