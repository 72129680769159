import SearchInput from "components/atoms/input/search/SearchInput";
import * as s from "components/blocks/headernav/HeaderNavBarStyled";
import styled from "styled-components";
import { COLORS } from "styles/colors";
import * as mediaQuery from "components/MediaQuery";
import { useRef } from "react";
import { IoChevronBackOutline, IoChevronForward } from "react-icons/io5";
import {
  LeftScrollButton,
  RightFilter,
  RightScroll,
  RightScrollButton,
} from "pages/videoshare/VideoShareStyled";
import { FLEX } from "styles/flex";

type Props = {
  isCurrentMenu: string;
  onClickMenu: (index: string) => void;
};

function VideoGuidesHeader(props: Props): JSX.Element {
  const horizontalScrollRef = useRef<any>(null);
  let submenu = [
    {
      name: "About myDW",
      key: "about",
    },
    {
      name: "User Organization",
      key: "account",
    },
    {
      name: "Recorders",
      key: "recorders",
    },
    {
      name: "Report",
      key: "report",
    },
    {
      name: "Alert",
      key: "alert",
    },
    {
      name: "Supported Equipment",
      key: "equipment",
    },
  ];
  const handleNextButtonClick = (nextType: "prev" | "next") => {
    if (!horizontalScrollRef.current) return;
    if (nextType === "prev") {
      horizontalScrollRef.current.scrollTo({
        left:
          horizontalScrollRef.current.scrollLeft -
          horizontalScrollRef.current.offsetWidth,
        behavior: "smooth",
      });
    } else {
      horizontalScrollRef.current.scrollTo({
        left:
          horizontalScrollRef.current.scrollLeft +
          horizontalScrollRef.current.offsetWidth,
        behavior: "smooth",
      });
    }
  };

  return (
    <HeaderContainer>
      <s.Inner>
        <Header>
          <Title>Video Guides</Title>
          {/* <ScrollWrapper>
            <mediaQuery.Mobile>
              <LeftScrollButton onClick={() => handleNextButtonClick("prev")}>
                <IoChevronBackOutline />
              </LeftScrollButton>
              <RightScrollButton
                onClick={() => handleNextButtonClick("next")}
                className="right-scroll-button"
              >
                <IoChevronForward />
              </RightScrollButton>
            </mediaQuery.Mobile>

            <RightScroll ref={horizontalScrollRef}>
              {submenu.map((item, index: number) => (
                <MenuItem
                  key={index}
                  isCurrent={props.isCurrentMenu === item.key}
                  onClick={() => props.onClickMenu(item.key)}
                >
                  {item.name}
                </MenuItem>
              ))}
            </RightScroll>
          </ScrollWrapper> */}
        </Header>
        <mediaQuery.Default>
          <Search>
            <SearchInput />
          </Search>
        </mediaQuery.Default>
      </s.Inner>
    </HeaderContainer>
  );
}

const HeaderContainer = styled(s.HeaderContainer)` 
  z-index: 89;
  background-color: ${({ theme }) => theme.COLORS.FILTERITEM};
`;

const Header = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  ${FLEX.FlexStartCenter};
  gap: 10px;
`;

const Title = styled(s.Title)`
  color: ${({ theme }) => theme.COLORS.WHITE};
  flex: 0 0 auto;
`;

const MenuItem = styled(s.MenuItem)<{ isCurrent: boolean }>`
  color: ${({ theme }) => theme.COLORS.WHITE};
  ${({ isCurrent }) =>
    isCurrent &&
    `
        border-color: ${COLORS.PROGRESSBAR};
        color: ${COLORS.PROGRESSBAR};
      `}
`;

const Search = styled.div`
  max-width: 280px;
`;

const ScrollWrapper = styled(RightFilter)`
  width: 100%;
  height: 100%;
  flex: 1;
  overflow: hidden;
  max-width: unset;

  > ul {
    height: 100%;
    gap: 20px;
  }

  .right-scroll-button {
    right: 0;
  }

  ${mediaQuery.isMobile} {
    padding: 0 15px;
  }
`;

export default VideoGuidesHeader;
