import WARNING_CIRCLE_ICON from "assets/icons/warning_circle_red_border.svg";
import NOT_AVAILABLE_ICON from "assets/icons/warning_circle.svg";
import DefaultThumbnail from "../DefaultThumbnail";
import * as s from "./ThumbnailStyled";

import { useEffect, useLayoutEffect, useState } from "react";
import { getThumbnailErrorString } from "utils/ReportUtil";
import ThumbnailAvailable from "./ThumbnailAvailable";
import Spinner from "components/atoms/loader/Spinner";

type CVVThumbnailProps = {
  videoLoss: boolean;
  thumbnailUrl: string | undefined;
  isLazy?: boolean;
  isRecorderList?: boolean;
  errorStatus?: string;
  isAnalyzing?: boolean;
};

function CVVThumbnail(props: CVVThumbnailProps): JSX.Element {
  const [thumbnailAvailable, setThumbnailAvailable] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);

  const imageOnErrorHandler = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    setThumbnailAvailable(true);
    event.currentTarget.style.display = "none";
  };

  return (
    <DefaultThumbnail>
      {props.thumbnailUrl === undefined || props.thumbnailUrl === "" ? (
        <s.Container
          className="video-loss-image"
          isRecorderList={props.isRecorderList}
        >
          <img
            src={WARNING_CIRCLE_ICON}
            className="empty_thumb"
            alt="warning circle icon"
            loading={
              props.isLazy !== undefined && props.isLazy ? "lazy" : "eager"
            }
          />
          <p>{`Thumbnail Not Found`}</p>
        </s.Container>
      ) : (
        <>
          <s.ImageBg>
            <div className="black-filter"></div>
            {thumbnailAvailable ? (
              <s.Container className="video-loss-image transcoding-image">
                <ThumbnailAvailable isRecorderList={props.isRecorderList} />
              </s.Container>
            ) : (<>
              <img
                src={props.thumbnailUrl}
                alt="recorder thumbnail"
                onLoad={() => {
                  setLoaded(true);
                }}
                onError={imageOnErrorHandler}
                loading="lazy"
                className="thumbnail-url"
              />
              {!loaded && (
                <s.Loading>
                  <Spinner />
                </s.Loading>
              )}
              </>)}
          </s.ImageBg>
        </>
      )}
      {props.isAnalyzing && <AnalyzingCover />}
    </DefaultThumbnail>
  );
}

export default CVVThumbnail;

const AnalyzingCover = () => {
  return (
    <s.AnalyzingCover>
      <span className="txt">Analyzing</span><Spinner inline={true} size={8} />
    </s.AnalyzingCover>
  )
}