import React, {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
} from "react";
import { useState } from "react";
import { useQuery } from "react-query";
import {
  BasePagination,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import { getUserTotalList } from "api/userAPI";
import { Checkbox, TableColumnsType } from "antd";
import { VirtualTable } from "components/atoms/table/AntdVirtualTable";
import { matchStringSearchKeyword } from "utils/functions";
import { TableRowSelection } from "antd/es/table/interface";
import { UserTotalList } from "api/interfaces/userInterface.interface";
import { AlertRuleRecipients } from "api/interfaces/alertInterface.interface";

export type SearchUserProps = {
  accountId: string;
  keyword: string;
  selectedUserId?: string[];
  onSelectedRowsChangeUser?: (selectedRows: string[]) => void;
  onChangeEnableCheckUserList?: (selectedRows: AlertRuleRecipients[]) => void;
  isSupportEmail?: boolean;
  userGroupId?: string;
  enableCheckUserList?: AlertRuleRecipients[];
  isEditUserGroup?: boolean;
};

export interface DataRow extends UserTotalList {
  name: string;
}

export const SearchUserList = forwardRef(
  (props: SearchUserProps, ref: ForwardedRef<any>): JSX.Element => {
    // console.log(props.enableCheckUserList);
    const [users, setUsers] = useState<DataRow[]>([]);
    const [pending, setPending] = useState<boolean>(true);

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const [selectedEnable, setSelectedEnable] = useState<AlertRuleRecipients[]>(
      props.enableCheckUserList || []
    );

    const [userQueryInfo] = useState<BasePagination>({
      total: 0,
      pageNum: 0,
      pageLimit: 2000,
      keyword: props.keyword as string,
      sortType: "name",
      sortDirection: SORT_DIRECTION.ASC,
    });

    const checkDisabledForOnlyOneGroup = ({
      userId,
      userGroups,
      userGroupCount,
    }: UserTotalList) => {
      if (
        !props.selectedUserId ||
        props.selectedUserId.length > 0 ||
        !props.selectedUserId.includes(userId) ||
        !(userGroupCount > 1)
      ) {
        if (userGroups && userGroups.length > 0) {
          return (
            userGroups.length === 1 &&
            userGroups[0].groupId === props.userGroupId
          );
        }
        return true;
      }
      return false;
    };
    const emailHeaderToggleHandler = useCallback(
      (checked: boolean) => {
        const tmpData = [...selectedEnable];
        tmpData.forEach((data) => {
          data.enableEmail = checked;
        });
        onCheckRecipient(tmpData);
      },
      [selectedEnable]
    );

    const pushHeaderToggleHandler = useCallback(
      (checked: boolean) => {
        const tmpData = [...selectedEnable];
        tmpData.forEach((data) => {
          data.enablePush = checked;
        });
        onCheckRecipient(tmpData);
      },
      [selectedEnable]
    );

    const { error, refetch } = useQuery(
      ["user", userQueryInfo],
      () =>
        getUserTotalList({
          payload: userQueryInfo,
          accountId: props.accountId,
        }),
      {
        retry: 0,
        refetchOnWindowFocus: false,
        onSuccess: (res: any) => {
          // console.log(res);
          if (!res.result) {
            return;
          }
          setUsers(
            res.result.map((user: UserTotalList) => {
              return {
                ...user,
                id: user.userId,
                key: user.userId,
              };
            })
          );
        },
        onError: (e: any) => {},
        onSettled: () => {
          setPending(false);
        },
      }
    );
    const onSelectChange = (
      newSelectedRowKeys: React.Key[],
      selectedRows: DataRow[]
    ) => {
      setSelectedRowKeys(newSelectedRowKeys);
      if (props.onSelectedRowsChangeUser)
        props.onSelectedRowsChangeUser(newSelectedRowKeys as string[]);
    };

    const onCheckRecipient = useCallback(
      (recipients: AlertRuleRecipients[]) => {
        setSelectedEnable(recipients);
        if (props.onChangeEnableCheckUserList) {
          props.onChangeEnableCheckUserList(recipients);
        }
      },
      [selectedEnable]
    );

    const rowSelection: TableRowSelection<DataRow> = {
      columnWidth: 40,
      selectedRowKeys,
      onChange: onSelectChange,
      getCheckboxProps: (record: DataRow) => {
        if (props.isEditUserGroup) {
          return { disabled: checkDisabledForOnlyOneGroup(record) };
        }
        return {};
      },
    };

    useEffect(() => {
      if (props.selectedUserId) {
        setSelectedRowKeys(props.selectedUserId);
      }
    }, [props.selectedUserId]);

    useEffect(() => {
      if (props.enableCheckUserList) {
        setSelectedEnable(
          users.map((user: UserTotalList) => {
            return {
              userId: user.userId,
              enableEmail:
                (props.enableCheckUserList?.find(
                  (temp) => temp.userId === user.userId
                )?.enableEmail as boolean) || false,
              enablePush:
                (props.enableCheckUserList?.find(
                  (temp) => temp.userId === user.userId
                )?.enablePush as boolean) || false,
            };
          })
        );
      }
    }, [props.enableCheckUserList, users]);

    const columns: TableColumnsType<DataRow> = useMemo(
      () => [
        {
          dataIndex: "name",
          title: "Name",
          render: (value, row) => value,
          width: 200,
          ellipsis: true,
        },
        {
          dataIndex: "email",
          title: () => "Email",
          render: (value, row) => value,
          responsive: ["md"],
        },
        {
          dataIndex: "enableEmail",
          title: ({ filters }) => {
            let tmpCheckPush = selectedEnable
              .map((g) => g.enableEmail)
              .every((gc) => gc);
            let tmpCheckPushInter = selectedEnable
              .map((g) => g.enableEmail)
              .some((gc) => gc);

            return (
              <div>
                <Checkbox
                  defaultChecked={false}
                  checked={tmpCheckPush}
                  indeterminate={!tmpCheckPush ? tmpCheckPushInter : false}
                  onChange={(e) => {
                    emailHeaderToggleHandler(e.target.checked);
                  }}
                />
                Email
              </div>
            );
          },
          render: (value, row, index) => {
            return (
              <Checkbox
                checked={selectedEnable.some(
                  (item) => item.userId === row.userId && item.enableEmail
                )}
                onChange={(e) => {
                  const tempArr = [...selectedEnable].map((item) =>
                    item.userId === row.userId
                      ? { ...item, enableEmail: !item.enableEmail }
                      : item
                  );
                  onCheckRecipient(tempArr);
                }}
              />
            );
          },
          width: 80,
          hidden: !!!props.isSupportEmail,
        },
        {
          key: "enablePush",
          dataIndex: "enablePush",
          title: ({ filters }) => {
            let tmpCheckPush = selectedEnable
              .map((g) => g.enablePush)
              .every((gc) => gc);
            let tmpCheckPushInter = selectedEnable
              .map((g) => g.enablePush)
              .some((gc) => gc);

            return (
              <div>
                <Checkbox
                  defaultChecked={false}
                  checked={tmpCheckPush}
                  indeterminate={!tmpCheckPush ? tmpCheckPushInter : false}
                  onChange={(e) => {
                    pushHeaderToggleHandler(e.target.checked);
                  }}
                />
                Push
              </div>
            );
          },
          render: (value, row, index) => {
            return (
              <Checkbox
                checked={selectedEnable.some(
                  (item) => item.userId === row.userId && item.enablePush
                )}
                onChange={(e) => {
                  const tempArr = [...selectedEnable].map((item) =>
                    item.userId === row.userId
                      ? { ...item, enablePush: !item.enablePush }
                      : item
                  );
                  onCheckRecipient(tempArr);
                }}
              />
            );
          },
          width: 80,
          hidden: !!!props.isSupportEmail,
        },
      ],
      [emailHeaderToggleHandler, pushHeaderToggleHandler, users, selectedEnable]
    );

    const data: DataRow[] = useMemo(() => {
      return users.filter((rec) => {
        if (props.keyword) {
          return (
            matchStringSearchKeyword(rec.name, props.keyword) ||
            matchStringSearchKeyword(rec.email, props.keyword)
          );
        } else return true;
      });
    }, [props.keyword, users]);

    return (
      <>
        <VirtualTable
          columns={columns}
          dataSource={data}
          rowSelection={!!!props.isSupportEmail ? rowSelection : undefined}
          loading={pending}
        />
      </>
    );
  }
);
