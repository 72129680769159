import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import List from "./list";
import Groups from "./groups";
import { useEffect } from "react";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import { returnToStartPage } from "utils/MenuUtil";
import { useAuth } from "components/Auth";

type Props = {};

export default function Recorders(props: Props): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useAuth();
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  useEffect(() => {
    if (!selectedAccount.userPermission.viewRecordersAndGroups) {
      returnToStartPage(selectedAccount, navigate, auth.user);
    }
  }, [selectedAccount.userPermission.viewRecordersAndGroups]);

  // const state = location.state;

  if (location.pathname.includes("groups")) return <Groups />;
  else return <List />;
}
