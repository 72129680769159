import {
  ForwardedRef,
  Fragment,
  useCallback,
  useEffect,
  useState,
} from "react";
import * as s from "styles/ModalCommonStyled";
import BigPopupTemplate from "components/templates/modal/big/BigPopupTemplate";
import StepperComponent, { StepType } from "components/blocks/stepper/Stepper";
import Toggle from "components/atoms/toggle/Toggle";
import { ModalInput } from "components/atoms/input/modal/ModalInput";
import Input from "components/atoms/input/Input";
import TextArea from "components/atoms/input/TextArea";
import BigButton from "components/atoms/buttons/BigButton";
import { Text, ValidateWord } from "components/atoms/text/Text";
import ShowToggle from "components/blocks/modal/toggle/show/ShowToggle";
import AdvancedSettings from "components/blocks/modal/advanced/AdvancedSettings";
import { timeUnitsOptions, timeUnitsOptionsDayHour } from "utils/options";
import { UPDATE_TYPE } from "./AddRuleContainer";
import {
  AlertRuleGroupRecipients,
  AlertRuleRecipients,
  PostAlertRequest,
} from "api/interfaces/alertInterface.interface";
import { SearchRecorder } from "components/element/SearchRecorder";
import { SearchUsers } from "components/element/SearchUsers";
import {
  Validate,
  ValidateMsg,
} from "api/interfaces/commonInterface.interface";
import {
  LEVEL_TYPE,
  ProfileAccountInfo,
} from "api/interfaces/accountInterface.interface";
import { SearchAlertAccount } from "components/element/SearchAlertAccount";
import DeleteConfirmModal from "components/templates/modal/small/confirm/delete/DeleteConfirmModal";
import { useIntl } from "react-intl";
import Selects from "components/atoms/select/Select";

interface Props {
  onModal: (type?: string) => void;
  page: number;
  onChangePage: (name: string) => void;
  isEdit?: boolean;
  isView?: boolean;
  steps: Array<StepType>;
  accountId: string;
  hostName: string;
  data: PostAlertRequest;
  onChangeAlertRuleInfo: (
    type: UPDATE_TYPE,
    value: string | number | boolean | string[] | number[]
  ) => void;
  onChangeAlertRuleCheckInfo: (type: UPDATE_TYPE, checked: boolean) => void;
  onChangeRecipients: (
    value: AlertRuleRecipients[] | AlertRuleGroupRecipients[],
    isGroup?: boolean
  ) => void;
  onClickCreate: () => void;
  onClickSave: () => void;
  onClickDelete: () => void;
  selectedRecorder?: string[];
  selectedAccountList?: string[];
  selectedRecorderGroup?: number[];
  selectedUser: AlertRuleRecipients[];
  selectedGroup: AlertRuleGroupRecipients[];
  alertRuleValidate: Validate;
  alertRuleValidateMsg: ValidateMsg;
  selectedAccount: ProfileAccountInfo;
  isDeleteConfirm?: boolean;
  userId: string | undefined;
}
export function AddRulePresenter(props: Props): JSX.Element {
  const intl = useIntl();

  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);

  const [alertToggle, setAlertToggles] = useState<{ [key: string]: boolean }>({
    system: true,
    disk: false,
    video: false,
  });

  const onChangeCollapse = useCallback(
    (name: UPDATE_TYPE) => {
      if (name === UPDATE_TYPE.SYSTEM_ENABLE) {
        setAlertToggles((toggle) => {
          return {
            ...toggle,
            system: !toggle.system,
          };
        });
      }
      if (name === UPDATE_TYPE.DISK_ENABLE) {
        setAlertToggles((toggle) => {
          return {
            ...toggle,
            disk: !toggle.disk,
          };
        });
      }
      if (name === UPDATE_TYPE.VIDEO_ENABLE) {
        setAlertToggles((toggle) => {
          return {
            ...toggle,
            video: !toggle.video,
          };
        });
      }
    },
    [alertToggle]
  );

  const onChangeAlertRuleInfoIntercept = useCallback(
    (
      type: UPDATE_TYPE,
      value: string | number | boolean | string[] | number[]
    ) => {
      if (type === UPDATE_TYPE.SYSTEM_ENABLE) {
        setAlertToggles({
          ...alertToggle,
          system: value as boolean,
        });
      }
      if (type === UPDATE_TYPE.DISK_ENABLE) {
        setAlertToggles({
          ...alertToggle,
          disk: value as boolean,
        });
      }
      if (type === UPDATE_TYPE.VIDEO_ENABLE) {
        setAlertToggles({
          ...alertToggle,
          video: value as boolean,
        });
      }
      props.onChangeAlertRuleInfo(type, value);
    },
    [alertToggle, props]
  );

  // step 1 - rule items
  const ruleItems = [
    {
      title: "System Alerts",
      value: props.data.systemAlert.isEnable, // toggle value
      isShow: alertToggle.system,
      name: UPDATE_TYPE.SYSTEM_ENABLE,
      children: (
        <>
          <AdvancedSettings
            label="Not responding more than:"
            min={1}
            max={99}
            tooltip={intl.formatMessage({
              id: "label.alertRule.tooltip.recorderNotResponse",
              defaultMessage: "Recorder is down or offline.",
            })}
            unit={
              <Selects
                options={timeUnitsOptions}
                defaultValue={timeUnitsOptions[0]}
                value={timeUnitsOptions.filter((option) => {
                  return (
                    option.value ===
                    props.data.systemAlert.notResponseType.toUpperCase()
                  );
                })}
                onChange={(value) =>
                  props.onChangeAlertRuleInfo(
                    UPDATE_TYPE.SYSTEM_NOTRESPONSE_DETECTTIME,
                    value
                  )
                }
              />
            }
            defaultValue={props.data.systemAlert.notResponse}
            defaultChecked={props.data.systemAlert.isCriticalNotResponse}
            onChangeValue={(value) =>
              props.onChangeAlertRuleInfo(UPDATE_TYPE.SYSTEM_NOTRESPONSE, value)
            }
            checked={props.data.systemAlert.isCriticalNotResponse}
            onChangeToggle={(checked) =>
              props.onChangeAlertRuleCheckInfo(
                UPDATE_TYPE.SYSTEM_NOTRESPONSE,
                checked
              )
            }
          />
          <AdvancedSettings
            label="Abnormal Restart (occurences per 24h):"
            min={1}
            max={99}
            unit=""
            tooltip={intl.formatMessage({
              id: "label.alertRule.tooltip.abnormal",
              defaultMessage:
                "Number of abnormal restarts in the last 24 hours.",
            })}
            defaultValue={props.data.systemAlert.abnormalStart}
            defaultChecked={props.data.systemAlert.isCriticalAbnormalStart}
            checked={props.data.systemAlert.isCriticalAbnormalStart}
            onChangeValue={(value) =>
              props.onChangeAlertRuleInfo(
                UPDATE_TYPE.SYSTEM_ABNORMALSTART,
                value
              )
            }
            onChangeToggle={(e) =>
              props.onChangeAlertRuleCheckInfo(
                UPDATE_TYPE.SYSTEM_ABNORMALSTART,
                e
              )
            }
          />
          <AdvancedSettings
            label="Overheated more than:"
            unit="Day(s)"
            min={1}
            max={99}
            tooltip={`${intl.formatMessage({
              id: "label.alertRule.tooltip.systemOverheat",
              defaultMessage: "[Vmax Only] System temperature over 70",
            })}${String.fromCharCode(0x2103)}`}
            defaultValue={props.data.systemAlert.overHeated}
            defaultChecked={props.data.systemAlert.isCriticalOverheated}
            checked={props.data.systemAlert.isCriticalOverheated}
            onChangeValue={(value) =>
              props.onChangeAlertRuleInfo(UPDATE_TYPE.SYSTEM_OVERHEATED, value)
            }
            onChangeToggle={(checked) =>
              props.onChangeAlertRuleCheckInfo(
                UPDATE_TYPE.SYSTEM_OVERHEATED,
                checked
              )
            }
          />
        </>
      ),
    },
    {
      title: "Disk Alerts",
      value: props.data.diskAlert.isEnable, // toggle value
      isShow: alertToggle.disk,
      name: UPDATE_TYPE.DISK_ENABLE,
      children: (
        <>
          <AdvancedSettings
            label="Hardware failure"
            noCount
            tooltip={intl.formatMessage({
              id: "label.alertRule.tooltip.hardwareFailure",
              defaultMessage:
                "[Vmax only] Disk error or hdd smart error detected",
            })}
            defaultChecked={props.data.diskAlert.isCriticalHardwareFailure}
            checked={props.data.diskAlert.isCriticalHardwareFailure}
            onChangeToggle={(e) =>
              props.onChangeAlertRuleCheckInfo(
                UPDATE_TYPE.SYSTEM_HARDWAREFAILURE,
                e
              )
            }
          />
          <AdvancedSettings
            label="Overheated more than:"
            unit="Day(s)"
            min={1}
            max={99}
            tooltip={`${intl.formatMessage({
              id: "label.alertRule.tooltip.diskOverheat",
              defaultMessage: "[Vmax only] Disk temperature over 70",
            })}${String.fromCharCode(0x2103)}`}
            defaultValue={props.data.diskAlert.overheated}
            defaultChecked={props.data.diskAlert.isCriticalOverheated}
            checked={props.data.diskAlert.isCriticalOverheated}
            onChangeValue={(value) =>
              props.onChangeAlertRuleInfo(UPDATE_TYPE.DISK_OVERHEATED, value)
            }
            onChangeToggle={(checked) =>
              props.onChangeAlertRuleCheckInfo(
                UPDATE_TYPE.DISK_OVERHEATED,
                checked
              )
            }
          />
          <AdvancedSettings
            label="BAD Sector Over:"
            unit="%"
            min={1}
            max={99}
            tooltip={intl.formatMessage({
              id: "label.alertRule.tooltip.badSector",
              defaultMessage: "[Vmax only] Percentage of bad sectors",
            })}
            defaultValue={props.data.diskAlert.badSectorOver}
            defaultChecked={props.data.diskAlert.isCriticalBadSectorOver}
            checked={props.data.diskAlert.isCriticalBadSectorOver}
            onChangeValue={(value) =>
              props.onChangeAlertRuleInfo(UPDATE_TYPE.DISK_BADSECTOR, value)
            }
            onChangeToggle={(e) =>
              props.onChangeAlertRuleCheckInfo(UPDATE_TYPE.DISK_BADSECTOR, e)
            }
          />
          <AdvancedSettings
            label="Storage status checked more than:"
            unit="Hour(s)"
            min={1}
            max={99}
            tooltip={intl.formatMessage({
              id: "label.alertRule.tooltip.storageStatus",
              defaultMessage:
                "[Spectrum Only] One or more storage devices are being checked by media server.",
            })}
            defaultValue={props.data.diskAlert.storageStatus}
            defaultChecked={props.data.diskAlert.isCriticalStorageStatus}
            checked={props.data.diskAlert.isCriticalStorageStatus}
            onChangeValue={(value) =>
              props.onChangeAlertRuleInfo(
                UPDATE_TYPE.DISK_STORAGE_STATUS,
                value
              )
            }
            onChangeToggle={(e) =>
              props.onChangeAlertRuleCheckInfo(
                UPDATE_TYPE.DISK_STORAGE_STATUS,
                e
              )
            }
          />
          <AdvancedSettings
            label="Reindexing is taking more than:"
            unit="Hour(s)"
            min={1}
            max={99}
            tooltip={intl.formatMessage({
              id: "label.alertRule.tooltip.reIndex",
              defaultMessage:
                "[[Spectrum Only] Recorder is reindexing of one or more storage devices.",
            })}
            defaultValue={props.data.diskAlert.reindexing}
            defaultChecked={props.data.diskAlert.isCriticalReindexing}
            checked={props.data.diskAlert.isCriticalReindexing}
            onChangeValue={(value) =>
              props.onChangeAlertRuleInfo(UPDATE_TYPE.DISK_REINDEXING, value)
            }
            onChangeToggle={(e) =>
              props.onChangeAlertRuleCheckInfo(UPDATE_TYPE.DISK_REINDEXING, e)
            }
          />
          <AdvancedSettings
            label="Storage space near full:"
            unit="Hour(s)"
            min={1}
            max={99}
            tooltip={intl.formatMessage({
              id: "label.alertRule.tooltip.storageFull",
              defaultMessage:
                "[Spectrum Only] Free space on the disk is below 20 GB.",
            })}
            defaultValue={props.data.diskAlert.storageSpaceNearFull}
            defaultChecked={props.data.diskAlert.isCriticalStorageSpaceNearFull}
            checked={props.data.diskAlert.isCriticalStorageSpaceNearFull}
            onChangeValue={(value) =>
              props.onChangeAlertRuleInfo(
                UPDATE_TYPE.DISK_STORAGE_SPACE_FULL,
                value
              )
            }
            onChangeToggle={(e) =>
              props.onChangeAlertRuleCheckInfo(
                UPDATE_TYPE.DISK_STORAGE_SPACE_FULL,
                e
              )
            }
          />
          <AdvancedSettings
            label="Reserved space not clearing for over:"
            unit="Hour(s)"
            min={1}
            max={99}
            tooltip={intl.formatMessage({
              id: "label.alertRule.tooltip.reserveSpace",
              defaultMessage:
                "[Spectrum Only] Reserved space on the disk exceeds 110% of free space.",
            })}
            defaultValue={props.data.diskAlert.reservedSpaceNotClearing}
            defaultChecked={
              props.data.diskAlert.isCriticalReservedSpaceNotClearing
            }
            checked={props.data.diskAlert.isCriticalReservedSpaceNotClearing}
            onChangeValue={(value) =>
              props.onChangeAlertRuleInfo(
                UPDATE_TYPE.DISK_RESERVE_SPACE_CLEAR,
                value
              )
            }
            onChangeToggle={(e) =>
              props.onChangeAlertRuleCheckInfo(
                UPDATE_TYPE.DISK_RESERVE_SPACE_CLEAR,
                e
              )
            }
          />
        </>
      ),
    },
    {
      title: "Video Alerts",
      value: props.data.videoAlert.isEnable, // toggle value
      isShow: alertToggle.video,
      name: UPDATE_TYPE.VIDEO_ENABLE,
      children: (
        <>
          <AdvancedSettings
            label="Lost connection to camera for more than:"
            min={1}
            max={99}
            tooltip={intl.formatMessage({
              id: "label.alertRule.tooltip.loss",
              defaultMessage: "Camera is offline or unauthorized.",
            })}
            defaultValue={props.data.videoAlert.lostConCamera}
            defaultChecked={props.data.videoAlert.isCriticalLostConCamera}
            checked={props.data.videoAlert.isCriticalLostConCamera}
            unit={
              <Selects
                options={timeUnitsOptionsDayHour}
                defaultValue={timeUnitsOptionsDayHour[1]}
                value={timeUnitsOptionsDayHour.filter(
                  (option) =>
                    option.value === props.data.videoAlert.lostConCameraType
                )}
                onChange={(value) =>
                  props.onChangeAlertRuleInfo(
                    UPDATE_TYPE.VIDEO_LOST_CAMERA_DETECTTIME,
                    value
                  )
                }
              />
            }
            onChangeValue={(value) =>
              props.onChangeAlertRuleInfo(UPDATE_TYPE.VIDEO_LOST_CAMERA, value)
            }
            onChangeToggle={(e) =>
              props.onChangeAlertRuleCheckInfo(UPDATE_TYPE.VIDEO_LOST_CAMERA, e)
            }
          />
          <AdvancedSettings
            label="Recording"
            noCount
            tooltip={intl.formatMessage({
              id: "label.alertRule.tooltip.notRecording",
              defaultMessage: "The Recording software may be malfunctioning",
            })}
            defaultChecked={props.data.videoAlert.isCriticalVideoRecording}
            checked={props.data.videoAlert.isCriticalVideoRecording}
            onChangeToggle={(e) =>
              props.onChangeAlertRuleCheckInfo(UPDATE_TYPE.VIDEO_RECORDING, e)
            }
          />
        </>
      ),
    },
  ];

  return (
    <BigPopupTemplate
      title={
        props.isEdit
          ? props.isView
            ? "View Alert Rule"
            : "Edit Alert Rule"
          : "Add New Rule"
      }
      onModal={props.onModal}
    >
      <StepperComponent
        steps={props.steps}
        activeStep={props.page}
        onClick={props.onChangePage}
      />
      <s.ModalInnerHalf>
        {/* {pages[props.page]} */}

        <div style={{ display: props.page === 0 ? "block" : "none" }}>
          {props.selectedAccount.accountLevel === LEVEL_TYPE.EU ? (
            <>
              <s.CheckInput>
                <input
                  type="checkbox"
                  // defaultChecked={true}
                  checked={props.data.recorderRule.autoApplyRecorder}
                  onChange={(e) =>
                    props.onChangeAlertRuleInfo(
                      UPDATE_TYPE.AUTO_APPLY_RECORDER,
                      e.target.checked
                    )
                  }
                />
                Apply to all current and future recorders.
              </s.CheckInput>

              {!props.data.recorderRule.autoApplyRecorder && (
                <s.ModalTableContainer>
                  <SearchRecorder
                    isTab
                    accountId={props.accountId}
                    selectedRow={props.selectedRecorder}
                    selectedGroupRow={props.selectedRecorderGroup}
                    onSelectedRowsChange={(value: string[]) =>
                      props.onChangeAlertRuleInfo(UPDATE_TYPE.RECORDER, value)
                    }
                    onSelectedGroupRowsChange={(value: number[]) =>
                      props.onChangeAlertRuleInfo(
                        UPDATE_TYPE.RECORDER_GROUP,
                        value
                      )
                    }
                    userId={props.userId}
                  />
                </s.ModalTableContainer>
              )}

              <ValidateWord>
                {props.alertRuleValidateMsg.isRecorder}
              </ValidateWord>
            </>
          ) : (
            <>
              <s.CheckInput>
                <input
                  type="checkbox"
                  // defaultChecked={true}
                  checked={props.data.accountRule.autoApplyAccount}
                  onChange={(e) =>
                    props.onChangeAlertRuleInfo(
                      UPDATE_TYPE.AUTO_APPLY_ACCOUNT,
                      e.target.checked
                    )
                  }
                />
                Apply to all current and future organization.
              </s.CheckInput>
              {!props.data.accountRule.autoApplyAccount && (
                <s.ModalTableContainer>
                  <SearchAlertAccount
                    accountId={props.selectedAccount.accountId}
                    selectedAccountId={props.selectedAccountList}
                    onSelectedRowsChange={(value: string[]) => {
                      props.onChangeAlertRuleInfo(UPDATE_TYPE.ACCOUNT, value);
                    }}
                  />
                </s.ModalTableContainer>
              )}

              <ValidateWord>
                {props.alertRuleValidateMsg.isAccount}
              </ValidateWord>
            </>
          )}
        </div>
        <div style={{ display: props.page === 1 ? "block" : "none" }}>
          <ShowToggle
            items={ruleItems}
            onToggle={onChangeCollapse}
            onChangeAlertRuleInfo={onChangeAlertRuleInfoIntercept}
          />
        </div>
        <div style={{ display: props.page === 2 ? "block" : "none" }}>
          <SearchUsers
            isTab
            accountId={props.accountId}
            enableCheckUserList={props.selectedUser}
            enableCheckUserGroupList={props.selectedGroup}
            onSelectedRowsChangeUser={(value: string[]) => {
              props.onChangeAlertRuleInfo(UPDATE_TYPE.USER, value);
            }}
            onChangeEnableCheckUserList={(value: AlertRuleRecipients[]) => {
              props.onChangeRecipients(value);
            }}
            onChangeEnableCheckUserGroup={(
              value: AlertRuleGroupRecipients[]
            ) => {
              props.onChangeRecipients(value, true);
            }}
            isSupportEmail={true}
          />
        </div>
        <div style={{ display: props.page === 3 ? "block" : "none" }}>
          <ModalInput label="Alert Rule Name" essential>
            <Input
              placeholder="Enter a name"
              defaultValue={props.data.preferenceRule.ruleName}
              value={props.data.preferenceRule.ruleName}
              maxLength={100}
              disabled={props.isView !== undefined && props.isView}
              onChange={(e) =>
                props.onChangeAlertRuleInfo(
                  UPDATE_TYPE.RULE_NAME,
                  e.target.value
                )
              }
            />
            <ValidateWord>{props.alertRuleValidateMsg.isRuleName}</ValidateWord>
          </ModalInput>
          <ModalInput label="Host">
            <Input
              disabled
              defaultValue={props.data.preferenceRule.host}
              // value 고정
              value={props.data.preferenceRule.host}
            />
          </ModalInput>
          <ModalInput label="Description">
            <TextArea
              placeholder="Enter Description"
              defaultValue={props.data.preferenceRule.description}
              value={props.data.preferenceRule.description}
              disabled={props.isView !== undefined && props.isView}
              onChange={(e) =>
                props.onChangeAlertRuleInfo(UPDATE_TYPE.DESC, e.target.value)
              }
            />
          </ModalInput>
          {props.isEdit && (
            <s.RowModalInput>
              <Text bold>Enable</Text>
              <Toggle
                isToggled={props.data.isEnable}
                checked={props.data.isEnable}
                onChange={(checked) =>
                  props.onChangeAlertRuleInfo(UPDATE_TYPE.ENABLE, checked)
                }
              />
            </s.RowModalInput>
          )}
        </div>
        {props.isEdit ? (
          props.isView ? (
            <s.ButtonWrapper>
              {props.page !== 0 && (
                <BigButton
                  buttonType="cancel"
                  label="Previous"
                  onClickBtn={() => props.onChangePage("previous")}
                />
              )}
              {props.page !== 3 ? (
                <BigButton
                  buttonType="cancel"
                  label="Next"
                  onClickBtn={() => props.onChangePage("next")}
                />
              ) : (
                <Fragment />
              )}
            </s.ButtonWrapper>
          ) : (
            <s.DeleteButtonWrapper>
              <BigButton
                buttonType="warning"
                label="Delete"
                onClickBtn={() => setIsDeleteConfirm(true)}
                disabled={props.isView !== undefined && props.isView}
              />
              <s.ButtonWrapper>
                {props.page !== 0 && (
                  <BigButton
                    buttonType="cancel"
                    label="Previous"
                    onClickBtn={() => props.onChangePage("previous")}
                  />
                )}
                {props.page !== 3 ? (
                  <BigButton
                    buttonType="cancel"
                    label="Next"
                    onClickBtn={() => props.onChangePage("next")}
                  />
                ) : (
                  <BigButton
                    label="Save"
                    onClickBtn={props.onClickSave}
                    delay
                    disabled={props.isView !== undefined && props.isView}
                  />
                )}
              </s.ButtonWrapper>
            </s.DeleteButtonWrapper>
          )
        ) : (
          <s.ButtonWrapper>
            {props.page !== 0 && (
              <BigButton
                buttonType="cancel"
                label="Previous"
                onClickBtn={() => props.onChangePage("previous")}
              />
            )}

            {props.page === 3 ? (
              <BigButton label="Save" onClickBtn={props.onClickCreate} delay />
            ) : (
              <BigButton
                buttonType="cancel"
                label="Next"
                onClickBtn={() => props.onChangePage("next")}
              />
            )}
          </s.ButtonWrapper>
        )}
        {props.isEdit && isDeleteConfirm && (
          <DeleteConfirmModal
            label={`rule`}
            onDelete={() => setIsDeleteConfirm(false)}
            onConfirmDelete={() => {
              props.onClickDelete();
              setIsDeleteConfirm(false);
            }}
          />
        )}
      </s.ModalInnerHalf>
    </BigPopupTemplate>
  );
}
