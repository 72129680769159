import React, { useState } from "react";

import * as s from "../BillingPaymentStyled";
import { TableColumnsType } from "antd";
import { PaymentTable } from "components/atoms/table/AntdBillingTable";

export type Props = {
  data: PaymentContactDataInterface[];
};

export interface PaymentContactDataInterface {
  [key: string]: string;
}

export default function PaymentContactTable(props: Props): JSX.Element {
  const columns = React.useMemo<TableColumnsType<PaymentContactDataInterface>>(
    () => [
      {
        dataIndex: "info",
        title: "CONTACT INFO",
        render: (value) => value,
        width: "50%",
      },
      {
        dataIndex: "",
        title: "PAID",
        render: (value) => "",
        width: "50%",
      },
    ],
    []
  );

  return (
    <s.TableWrapper>
      <PaymentTable columns={columns} dataSource={props.data} />
    </s.TableWrapper>
  );
}
