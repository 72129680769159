import styled from "styled-components";
import { COLORS, statusToColor } from "styles/colors";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";

import { AlertContents, AlertStatus } from "../float/FloatListStyled";
import { clusterAlert } from "../../GoogleMapContainer";
import { Theme } from "redux/reducers/theme/themeReducer";
import { changeFirstWordToUppercase } from "utils/functions";
import { useAppSelector } from "redux/hooks";

interface InfoViewProps {
  theme: Theme;
  count: number;
  calculateAlertStatus: clusterAlert;
  assignedFilter: boolean;
}

export default function HoverInfoView(props: InfoViewProps): JSX.Element {
  // console.log(props.calculateAlertStatus);

  const filteringTopAlert = (alertStatus: clusterAlert) => {
    if (alertStatus.criticalCount > 0) {
      return "CRITICAL";
    } else if (alertStatus.alertCount > 0) {
      return "ALERT";
    } else {
      return "HEALTHY";
    }
  };

  return (
    <Wrapper themeValue={props.theme} className="map-info-view">
      <AlertStatusList status={filteringTopAlert(props.calculateAlertStatus)}>
        <span className="recorder-count">
          <strong>{props.count}</strong> Systems
        </span>
        <div>
          {props.calculateAlertStatus.alertCount > 0 && (
            <strong>{props.calculateAlertStatus.alertCount} Alert(s)</strong>
          )}

          {props.calculateAlertStatus.alertCount > 0 &&
            props.calculateAlertStatus.criticalCount > 0 &&
            ` / `}

          {props.calculateAlertStatus.criticalCount > 0 && (
            <strong>
              {props.calculateAlertStatus.criticalCount} Critical(s)
            </strong>
          )}
        </div>
      </AlertStatusList>
      <AlertTypeList themeValue={props.theme}>
        <ul>
          {Object.entries(props.calculateAlertStatus.alertType).map((item) => (
            <li key={`alert-type-${item[0]}`}>
              <p className="title">{changeFirstWordToUppercase(item[0])}</p>
              <p className="count">{item[1]}</p>
            </li>
          ))}
        </ul>
      </AlertTypeList>
      {props.assignedFilter && <AssignMessage>Assigned Alert</AssignMessage>}
    </Wrapper>
  );
}

const Wrapper = styled.div<{ themeValue: Theme }>`
  background-color: ${({ themeValue }) =>
    themeValue.theme === "light" ? COLORS.WHITE : "#0d1119"};
  border-radius: 3px;
  padding: 10px;
  ${FLEX.FlexStartStart};
  flex-direction: column;
  gap: 4px;
  min-width: 180px;
`;

const AlertStatusList = styled(AlertStatus)`
  width: 100%;
  gap: 4px;
  padding: 8px;
`;

const AlertTypeList = styled(AlertContents)<{ themeValue: Theme }>`
  background-color: ${({ themeValue }) =>
    themeValue.theme === "light" ? "#fbfbfb" : COLORS.PRIMARY1};
  padding: 10px;

  ul {
    width: 100%;
    flex: 1;
    ${FLEX.FlexBetweenCenter};
    gap: 18px;

    li {
      flex: 1;
      text-align: center;
      font-size: ${calcRem(14)};
      ${FLEX.FlexCenterCenter};
      gap: 10px;
      flex-direction: column;
      color: ${({ themeValue }) =>
        themeValue.theme === "light" ? COLORS.BLACK : COLORS.WHITE};

      .count {
        font-weight: 500;
        /* font-size: ${calcRem(16)}; */
      }
    }
  }
`;

const AssignMessage = styled.div`
  width: 100%;
  ${FLEX.FlexCenterCenter};
  background-color: ${COLORS.BUTTONS2};
  color: ${COLORS.WHITE};
  font-weight: 500;
  padding: 4px;
  border-radius: 4px;
`;
