import { WithTooltip } from "components/blocks/WithTooltip";
import { PiDownloadSimple } from "react-icons/pi";
import styled from "styled-components";
import { downloadFromUrl } from "utils/functions";

const Button = styled.button`
  width: inherit;
  height: inherit;
  line-height: 10px;
  cursor: pointer;
`;

type DownloadButtonType = {
  fileUrl: string;
  fileName: string;
}

export const DownloadButton = ({ fileUrl, fileName }: DownloadButtonType) => {
  const download = () => {
    downloadFromUrl(fileUrl, fileName);
  }
  return (
    <WithTooltip tooltip="Download">
      <Button onClick={download}>
        <PiDownloadSimple fill="white" />
      </Button>
    </WithTooltip>
  )
}