import { ForwardedRef, forwardRef, useCallback, useRef, useState } from "react";
import * as s from "styles/ModalCommonStyled";
import ModalSubMenu from "components/blocks/modal/menu/ModalSubMenu";
import { SearchUserList } from "./SearchUserList";
import { SearchUserGroup } from "./SearchUserGroup";
import DebounceSearchInput from "components/atoms/input/search/DebounceSearchInput";
import {
  AlertRuleGroupRecipients,
  AlertRuleRecipients,
} from "api/interfaces/alertInterface.interface";

export type SearchUserProps = {
  accountId: string;
  placeholder?: string;
  selectedUserId?: string[];
  selectedUserGroupId?: string[];
  enableCheckUserList?: AlertRuleRecipients[];
  enableCheckUserGroupList?: AlertRuleGroupRecipients[];
  isTab?: boolean;
  onSelectedRowsChangeUser?: (selectedRows: string[]) => void;
  onSelectedRowsChangeUserGroup?: (selectedRows: string[]) => void;
  onChangeEnableCheckUserList?: (selectedRows: AlertRuleRecipients[]) => void;
  onChangeEnableCheckUserGroup?: (
    selectedRows: AlertRuleGroupRecipients[]
  ) => void;
  isSupportEmail?: boolean;
  userGroupId?: string;
  isEditUserGroup?: boolean;
};

export const SearchUsers = forwardRef(
  (props: SearchUserProps, ref: ForwardedRef<any>): JSX.Element => {
    const [searchText, setSearchText] = useState<string>("");

    const onClickSearch = (keyword: string) => {
      setSearchText(keyword);
    };

    // step 2 - recipients sub menu
    const recipientsMenus = [
      {
        label: "Add Users",
        value: true,
        placeholder: "Search Users",
      },
      {
        label: "Add User Groups",
        value: false,
        placeholder: "Search Groups",
      },
    ];
    const [currentMenu, setCurrentMenu] = useState(0);
    const onChangeMenu = (index: number) => {
      setCurrentMenu(index);
    };

    const onChangeSearchText = useCallback((value: string) => {
      setSearchText(value);
    }, []);

    //FIXME EDIT시 기존에 체크된 User정보를 테이블에 적용하는 로직 필요.
    return (
      <>
        {props.isTab ? (
          <s.ModalTabSearchWrapper>
            <ModalSubMenu
              menus={recipientsMenus}
              currentMenu={currentMenu}
              onChange={onChangeMenu}
            />
            <div className="search-box">
              <DebounceSearchInput
                placeholder={recipientsMenus[currentMenu].placeholder}
                onClickSearch={onClickSearch}
                onChange={onChangeSearchText}
              />
            </div>
          </s.ModalTabSearchWrapper>
        ) : (
          <DebounceSearchInput
            placeholder={recipientsMenus[currentMenu].placeholder}
            onClickSearch={onClickSearch}
            onChange={onChangeSearchText}
          />
        )}
        <s.ModalTableContainer>
          {currentMenu === 0 && (
            <div>
              <SearchUserList
                accountId={props.accountId}
                keyword={searchText}
                onSelectedRowsChangeUser={props.onSelectedRowsChangeUser}
                onChangeEnableCheckUserList={props.onChangeEnableCheckUserList}
                selectedUserId={props.selectedUserId}
                isSupportEmail={props.isSupportEmail}
                userGroupId={props.userGroupId}
                enableCheckUserList={props.enableCheckUserList}
                isEditUserGroup={props.isEditUserGroup}
              />
            </div>
          )}
          {currentMenu === 1 && (
            <div>
              <SearchUserGroup
                accountId={props.accountId}
                keyword={searchText}
                onSelectedRowsChangeUserGroup={
                  props.onSelectedRowsChangeUserGroup
                }
                onChangeEnableCheckUserGroup={
                  props.onChangeEnableCheckUserGroup
                }
                selectedUserGroupId={props.selectedUserGroupId}
                enableCheckUserGroupList={props.enableCheckUserGroupList}
                isSupportEmail={props.isSupportEmail}
              />
            </div>
          )}
        </s.ModalTableContainer>
      </>
    );
  }
);
