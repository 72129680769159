import moment from "moment";
import * as s from "./CardItemStyled";
import { FaArrowCircleDown, FaArrowCircleUp } from "react-icons/fa";
import { COLORS } from "styles/colors";
import LineChart from "components/atoms/chart/LineChart";
import {
  WidgetCardData,
  WidgetCardDetail,
  WidgetCardResult,
} from "api/interfaces/widgetInterface.interface";
import { CardItemName } from "./CardItem";
import { DashboardCardDetailTable } from "components/atoms/table/AntdTable";
import { Table, TableColumnsType } from "antd";
import { getLevelFullName } from "api/mappers/accountMapper";
import { Level } from "components/atoms/level/Level";
import { FaGears } from "react-icons/fa6";
import { IoVideocam } from "react-icons/io5";
import { FiHardDrive } from "react-icons/fi";
import { typeList } from "pages/dashboard/map/sub/subheader/filters/type/TypeFilter";
interface Props {
  name: CardItemName;
  details: WidgetCardDetail[];
}

const changeIconToAlert = (alert: string) => {
  switch (alert) {
    case "System":
      return <FaGears size={16} />;
    case "Disk":
      return <FiHardDrive size={16} />;
    case "Video":
      return <IoVideocam size={16} />;
    default:
      return "";
  }
};

const changeCameraName = (camera: string) => {
  const cameraName = camera.toUpperCase();
  switch (cameraName) {
    case "SPECTRUM":
      return "SPECTRUM";
    case "VMAX-IP":
      return "VMAX-IP";
    case "VMAX-A1":
      return "VMAX-A1";
    case "COVA":
      return "COVA";
    default:
      return cameraName;
  }
};
export default function CardItemDetail(props: Props): JSX.Element {
  const columns: TableColumnsType<WidgetCardDetail> = [
    {
      title: "",
      dataIndex: "type",
      render: (value) =>
        `${changeCameraName(value)} ${
          value === "spectrum" ? "Systems" : "Recorders"
        }`,
      width: 150,
    },
    {
      title: "QTY",
      dataIndex: "count",
      align: "center",
    },
    {
      title: "Alert",
      dataIndex: "alertCount",
      align: "center",
    },
  ];

  const cameraColumns: TableColumnsType<WidgetCardDetail> = [
    {
      title: "",
      dataIndex: "type",
      render: (value) => `Cameras (${changeCameraName(value)})`,
      width: 150,
    },
    {
      title: "Total",
      dataIndex: "count",
      align: "center",
    },
    {
      title: "Alert",
      dataIndex: "alertCount",
      align: "center",
    },
  ];

  const alertColumns: TableColumnsType<WidgetCardDetail> = [
    {
      title: "",
      dataIndex: "type",
      render: (value) => (
        <s.ColumnTitle>
          {changeIconToAlert(value)}
          {value}
        </s.ColumnTitle>
      ),
    },
    {
      title: "",
      dataIndex: "count",
      align: "center",
    },
  ];

  const accountColumns: TableColumnsType<WidgetCardDetail> = [
    {
      title: "",
      dataIndex: "type",
      render: (value) => (
        <s.ColumnTitle>
          <Level level={getLevelFullName(value)} /> {getLevelFullName(value)}
        </s.ColumnTitle>
      ),
    },
    {
      title: "",
      dataIndex: "count",
      align: "center",
    },
  ];

  const contents = {
    recorderCount: (
      <DashboardCardDetailTable
        columns={columns}
        dataSource={props.details}
        summary={(pageData) => {
          let totalCount = 0;
          let totalAlertCount = 0;

          pageData.forEach(({ count, alertCount }) => {
            totalCount += count;
            totalAlertCount += alertCount;
          });

          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  <div className="total-count">{totalCount}</div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                  <div className="total-count alert">{totalAlertCount}</div>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    ),
    cameraCount: (
      <DashboardCardDetailTable
        columns={cameraColumns}
        dataSource={props.details}
        summary={(pageData) => {
          let totalCount = 0;
          let totalAlertCount = 0;

          pageData.forEach(({ count, alertCount }) => {
            totalCount += count;
            totalAlertCount += alertCount;
          });

          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  <div className="total-count">{totalCount}</div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                  <div className="total-count alert">{totalAlertCount}</div>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    ),
    alertCount: (
      <DashboardCardDetailTable
        columns={alertColumns}
        dataSource={props.details}
        showHeader={false}
        summary={(pageData) => {
          let totalCount = 0;

          pageData.forEach(({ count, alertCount }) => {
            totalCount += count;
          });

          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>Total Alerts</Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  <div className="total-count alert">{totalCount}</div>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    ),
    organizationCount: (
      <DashboardCardDetailTable
        columns={accountColumns}
        dataSource={props.details}
        showHeader={false}
        summary={(pageData) => {
          let totalCount = 0;

          pageData.forEach(({ count }) => {
            totalCount += count;
          });

          if (props.details.length === 0) return <></>;

          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  <div className="total-count">{totalCount}</div>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    ),
  };
  return <s.DetailWrapper>{contents[props.name]}</s.DetailWrapper>;
}
