import PrimaryButton from "components/atoms/buttons/PrimaryButton";
import { Text } from "components/atoms/text/Text";
import SmallPopupDefaultTemplate from "components/templates/modal/small/SmallPopupDefaultTemplate";
import { useCallback, useState } from "react";
import * as s from "./resendCodeStyled";
import { maskInternationalPhoneNumber } from "utils/functions";

export type VerifyTypeString = "phone" | "email" | "PHONE" | "EMAIL";
export enum VERIFY_TYPE {
  PHONE = "SMS",
  EMAIL = "EMAIL",
  phone = "SMS",
  email = "EMAIL",
}

interface Props {
  onClickCancel: () => void;
  onClickSend: (type: VERIFY_TYPE) => void;
  email: string;
  phone?: string;
  verifyType: VERIFY_TYPE;
}

export function ResendCodePopup({
  phone,
  email,
  verifyType,
  onClickSend,
  onClickCancel,
}: Props): JSX.Element {
  const [resendType, setResendType] = useState<VERIFY_TYPE>(verifyType);

  const onClickBtn = useCallback(() => {
    onClickSend(resendType);
  }, [onClickSend, resendType]);

  const button = (
    <s.ButtonWrapper>
      <PrimaryButton
        buttonType="cancel"
        label="Cancel"
        onClickBtn={onClickCancel}
      />
      <PrimaryButton label="Send" onClickBtn={onClickBtn} />
    </s.ButtonWrapper>
  );

  const onchangeHandle = useCallback((e: any) => {
    setResendType(e.currentTarget.value as VERIFY_TYPE);
  }, []);

  return (
    <SmallPopupDefaultTemplate onModal={onClickCancel} button={button}>
      <s.Title>
        <Text bold fontSize={20}>
          Resend Code
        </Text>
        {phone && (
          <Text color="#828B9B" fontSize={14}>
            Choose where you'd like the code to be sent to.
          </Text>
        )}
      </s.Title>

      <s.Inner>
        {phone && (
          <s.Label>
            <input
              type="radio"
              name="resendRadio"
              value={VERIFY_TYPE.PHONE}
              onChange={onchangeHandle}
              disabled={phone === undefined || phone === ""}
              checked={resendType === VERIFY_TYPE.PHONE}
            />
            <Text bold>Tel:</Text>
            {phone === "" ? (
              <Text color="#828B9B">Not Registered</Text>
            ) : (
              maskInternationalPhoneNumber(phone)
            )}
          </s.Label>
        )}

        <s.Label>
          <input
            type="radio"
            name="resendRadio"
            value={VERIFY_TYPE.EMAIL}
            onChange={onchangeHandle}
            checked={resendType === VERIFY_TYPE.EMAIL}
          />
          <Text bold>Email:</Text>
          {email}
        </s.Label>
      </s.Inner>
    </SmallPopupDefaultTemplate>
  );
}
