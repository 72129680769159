import AlertViewPage from "pages/alert/view";
import { AlertViewInfo } from "api/interfaces/alertInterface.interface";
import { Suspense } from "react";
type Props = {
  selectedAlert: AlertViewInfo;
};

const RecorderDetailAlert = ({ selectedAlert }: Props): JSX.Element => {
  const params = new URLSearchParams({
    recorderId: selectedAlert.recorderId,
    // alertType: selectedAlert.category ?? "",
    // historyId: selectedAlert.historyId,
  });

  return (
    <Suspense>
      <AlertViewPage searchParams={params} />
    </Suspense>
  );
};

export default RecorderDetailAlert;
