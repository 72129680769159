import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { ModalInput } from "components/atoms/input/modal/ModalInput";
import CheckList from "../CheckList";
import {
  UserGroupRecorderPermission,
  UserGroupRecorderTypePermission,
} from "api/interfaces/userInterface.interface";
import Selects from "components/atoms/select/Select";
import { recorderLevelOptions, type Option } from "utils/options";
import ShowToggle from "components/blocks/modal/toggle/show/ShowToggle";

interface Props {
  recorderPermission: UserGroupRecorderPermission;
  permissionDisabled: boolean;
  onChangeRecorderLevel: (value: string) => void;
  disableList: UserGroupRecorderTypePermission;
  onChangeRecorderPermission: (
    type: "spectrum" | "vmax" | "cova",
    e: any
  ) => void;
  selectedRecorderTypes: string[];
}
const recorderPermissionCheckList = {
  spectrum: [
    {
      title: "spectrum",
      check: [
        {
          name: "cameraSetting",
          label: "Camera Settings",
        },
        {
          name: "viewEventLog",
          label: "View Event Log",
        },
        {
          name: "viewArchive",
          label: "View Archive",
        },
        {
          name: "exportArchive",
          label: "Export Archive",
        },
        {
          name: "viewBookmark",
          label: "View Bookmarks",
        },
        {
          name: "modifyBookmark",
          label: "Modify Bookmarks",
        },
        {
          name: "cameraUserInput",
          label: "Camera User Input",
          extra: "(PTZ, Output, 2-Way Audio)",
        },
      ],
    },
  ],

  vmax: [
    {
      title: "vmax",
      check: [
        {
          name: "system",
          label: "System",
        },
        {
          name: "device",
          label: "Device",
        },
        {
          name: "alarm",
          label: "Alarm",
        },
        {
          name: "record",
          label: "Record",
        },
        {
          name: "network",
          label: "Network",
        },
        {
          name: "export",
          label: "Export",
        },
        {
          name: "shutdown",
          label: "Shutdown",
        },
        {
          name: "ptz",
          label: "PTZ",
        },
        {
          name: "playback",
          label: "Playback",
        },
        {
          name: "backup",
          label: "Backup",
        },
        {
          name: "search",
          label: "Log",
        },
      ],
    },
  ],
  cova: [
    {
      title: "cova",
      check: [
        {
          name: "localCamera",
          label: "Local Camera",
        },
        {
          name: "remoteCamera",
          label: "Remote Camera",
        },
        {
          name: "disk",
          label: "Disk",
        },
        {
          name: "aiEvent",
          label: "AI / EVENT",
        },
        {
          name: "network",
          label: "Network",
        },
        {
          name: "schedule",
          label: "Schedule",
        },
        {
          name: "record",
          label: "Record",
        },
        {
          name: "localSystem",
          label: "Local System",
        },
        {
          name: "remoteSystem",
          label: "Remote System",
        },
        {
          name: "accountAuthority",
          label: "Accont and Authority",
        },
        {
          name: "remoteLogin",
          label: "Remote Login",
        },
      ],
    },
  ],
};
function RecorderPermissionStep(props: Props): JSX.Element {
  const [permissionToggle, setPermissionToggle] = useState<{
    [key: string]: boolean;
  }>({
    spectrum: true,
    cova: true,
    vmax: true,
  });

  // console.log(props.recorderPermission);

  const onChangeCollapse = useCallback(
    (name: string) => {
      setPermissionToggle({
        ...permissionToggle,
        [name]: !permissionToggle[name],
      });
    },
    [permissionToggle]
  );

  const recorderPermissionList = useMemo(() => {
    const lists = [
      {
        title: "Spectrum Permissions",
        value: true,
        isShow: permissionToggle.spectrum,
        name: "spectrum",
        children: (
          <CheckList
            isNoTitle
            list={recorderPermissionCheckList.spectrum}
            isCheck={props.recorderPermission.spectrum}
            isDisabled={props.disableList.spectrum}
            onCheckedElement={(e: ChangeEvent<HTMLInputElement>) =>
              props.onChangeRecorderPermission("spectrum", e)
            }
          />
        ),
      },
      {
        title: "COVA Permissions",
        value: true,
        isShow: permissionToggle.cova,
        name: "cova",
        children: (
          <CheckList
            isNoTitle
            list={recorderPermissionCheckList.cova}
            isCheck={props.recorderPermission.cova}
            isDisabled={props.disableList.cova}
            onCheckedElement={(e: ChangeEvent<HTMLInputElement>) =>
              props.onChangeRecorderPermission("cova", e)
            }
          />
        ),
      },
      {
        title: "VMAX Permissions",
        value: true,
        isShow: permissionToggle.vmax,
        name: "vmax",
        children: (
          <CheckList
            isNoTitle
            list={recorderPermissionCheckList.vmax}
            isCheck={props.recorderPermission.vmax}
            isDisabled={props.disableList.vmax}
            onCheckedElement={(e: ChangeEvent<HTMLInputElement>) =>
              props.onChangeRecorderPermission("vmax", e)
            }
          />
        ),
      },
    ];

    let returnList = lists.filter((list) =>
      props.selectedRecorderTypes.includes(list.name.toUpperCase())
    );

    return returnList;
  }, [
    permissionToggle.spectrum,
    permissionToggle.cova,
    permissionToggle.vmax,
    recorderPermissionCheckList,
    props.disableList,
    props.selectedRecorderTypes,
    props.onChangeRecorderPermission,
  ]);

  return (
    <>
      <ModalInput label="Role">
        <Selects
          options={recorderLevelOptions}
          onChange={props.onChangeRecorderLevel}
          value={recorderLevelOptions.find((option) => {
            const isValueIncluded = (value: string): boolean => {
              return recorderLevelOptions.some(
                (option) => option.value === value
              );
            };

            if (
              !isValueIncluded(props.recorderPermission.recorderPermissionLevel)
            )
              return option.value === "Custom";
            return (
              option.value === props.recorderPermission.recorderPermissionLevel
            );
          })}
          isDisabled={props.permissionDisabled}
        />
      </ModalInput>
      <ShowToggle
        isRecorderPermission
        items={recorderPermissionList}
        onToggle={onChangeCollapse}
      />
    </>
  );
}

export default RecorderPermissionStep;
