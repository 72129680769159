import { ScreenRecorderRecording } from "./ScreenRecorderRecording";
import { ScreenRecorderResult } from "./ScreenRecorderResult";
import { ScreenRecorderException } from "./ScreenRecorderException";
import { ScreenRecorderHookProps } from "./useScreenRecorder";

type ScreenRecorderType = {
  hookProps: ScreenRecorderHookProps
  onFinish: () => void,
  onStop: () => void,
}

export function ScreenRecorder({ hookProps, onStop, onFinish }: ScreenRecorderType) {
  const { status, error, durationInMs} = hookProps;
  return (
    <>
      {status === 'recording' && (
        <ScreenRecorderRecording stop={onStop} durationInMs={durationInMs} />
      )}
      {status === 'stopped' && (
        <ScreenRecorderResult hookProps={hookProps} onFinish={onFinish} />
      )}
      {error && (
        <ScreenRecorderException error={error} onFinish={onFinish} />
      )}
    </>
  )
}
