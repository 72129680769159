import * as s from "./LoginStyled";
import * as cs from "../SignCommonStyled";
import { FaInfoCircle } from "react-icons/fa";
import MYDW_ICON from "assets/icons/myDW.svg";
import { LoginInfo, LOGIN_STEP, Validate, ValidateMsg } from "./LoginContainer";
import { useIntl } from "react-intl";
import Input from "components/atoms/input/Input";
import { Text, UnderlineText, ValidateWord } from "components/atoms/text/Text";
import { COLORS } from "styles/colors";
import PhoneInput from "components/atoms/input/phone/PhoneInput";
import { KeyboardEvent } from "react";
import Spin from "antd/lib/spin";
import { useAppSelector } from "redux/hooks";
import AuthPopup from "components/templates/modal/auth/AuthPopup";
import { getSpectrumAuthUrl } from "utils/AccountUtil";
import PasswordInput from "components/atoms/input/PasswordInput";
import InfoBubble from "components/blocks/bubble/InfoBubble";
import { BubbleContainer } from "pages/license/LicenseStyled";
import { URLSearchParams } from "url";
import FreeTrial from "./step/FreeTrial";

interface Props {
  step: LOGIN_STEP;
  loginInfo: LoginInfo;
  checkedRemember: boolean;
  isValidated: Validate;
  validateMsg: ValidateMsg;
  onClickNext: () => void;
  onClickLogin: () => void;
  onChangeLoginInfo: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangePhoneNumber: (number: string) => void;
  onChangeRemember: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeFreeTrialInfo: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClickForgotEmail: () => void;
  onClickStepChange: (step: LOGIN_STEP) => void;
  onClickForgotPassword: () => void;
  onClickCloudLogin: () => void;
  cloudLoading: boolean;
  onCode: (code: string, params: URLSearchParams) => void;
  isSpectrumMouseOver: boolean;
  onMouseOverSpectrumLogin: () => void;
  onSubmitFreeTrial: () => void;
  modal: JSX.Element | null;
}

export function LoginPresenter(props: Props): JSX.Element {
  const {
    step,
    loginInfo,
    checkedRemember,
    isValidated,
    validateMsg,
    onClickNext,
    onClickLogin,
    onChangeLoginInfo,
    onChangePhoneNumber,
    onChangeRemember,
    onClickForgotEmail,
    onClickStepChange,
    onClickForgotPassword,
    onChangeFreeTrialInfo,
    cloudLoading,
    isSpectrumMouseOver,
    onMouseOverSpectrumLogin,
    onSubmitFreeTrial,
    modal,
  } = props;
  const intl = useIntl();
  const noticeLength: number = useAppSelector(
    (state) => state.notice.notices.length
  );

  return (
    <>
      {/* <SpectrumLogin /> */}
      <Spin
        spinning={cloudLoading}
        size="large"
        indicator={
          <div className="progress-indicator progress-indicator--responsive" />
        }
      >
        <cs.BG noticeLength={noticeLength}>
          <cs.Container>
            <cs.LoginBox>
              {step === LOGIN_STEP.INPUT_EMAIL && (
                <s.LoginIdPWWrapper>
                  <button onClick={() => window.location.reload()}>
                    <img
                      src={MYDW_ICON}
                      width="120"
                      alt="MyDW Icon"
                      className="mydw-logo"
                    />
                  </button>
                  <p className="title">Log in to myDW Web Portal</p>

                  <s.InputWrapper>
                    <Input
                      id="dw_login_input_email"
                      type="new-email"
                      name="email"
                      value={loginInfo.email}
                      placeholder="Enter your email."
                      onChange={onChangeLoginInfo}
                      onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
                        if (e.key === "Enter") {
                          onClickNext();
                        }
                      }}
                      autoComplete="false"
                      autoFocus
                      isValidated={isValidated.email}
                    />
                    <cs.ValidateWrapper>
                      <ValidateWord>{validateMsg.email}</ValidateWord>
                    </cs.ValidateWrapper>
                    <cs.BigButton
                      id="dw_login_button_next"
                      onClick={onClickNext}
                    >
                      Next
                    </cs.BigButton>
                    <Text fontSize={12} bold>
                      OR
                    </Text>
                    <AuthPopup
                      url={getSpectrumAuthUrl()}
                      onCode={props.onCode}
                      onClose={() => null}
                      title={"DW Spectrum Web Portal"}
                      width={500}
                      height={500}
                    >
                      <cs.BigButton dw>
                        DW Spectrum Web Login
                        <button
                          onMouseEnter={onMouseOverSpectrumLogin}
                          onMouseLeave={onMouseOverSpectrumLogin}
                        >
                          <FaInfoCircle />
                          {isSpectrumMouseOver && (
                            <BubbleContainer>
                              <InfoBubble>
                                If you have a DW Spectrum Web Portal account,
                                you can log into myDW with it.
                              </InfoBubble>
                            </BubbleContainer>
                          )}
                        </button>
                      </cs.BigButton>
                    </AuthPopup>
                  </s.InputWrapper>

                  <cs.Bottom>
                    <label>
                      <input
                        id="dw_login_input_checkedRemember"
                        type="checkbox"
                        checked={checkedRemember}
                        onChange={onChangeRemember}
                      />
                      <Text fontSize={14}>Remember Email</Text>
                    </label>
                    <UnderlineText
                      id="dw_login_button_forward_forgotEmail"
                      fontSize={14}
                      color={COLORS.BUTTONS2}
                      onClick={() => onClickStepChange(LOGIN_STEP.FORGOT_EMAIL)}
                    >
                      Forgot Email?
                    </UnderlineText>
                    <UnderlineText
                      fontSize={14}
                      color={COLORS.BUTTONS2}
                      onClick={() => onClickStepChange(LOGIN_STEP.FREE_TRIAL)}
                    >
                      Free Trial?
                    </UnderlineText>
                  </cs.Bottom>
                </s.LoginIdPWWrapper>
              )}

              {step === LOGIN_STEP.FORGOT_EMAIL && (
                <>
                  <p className="title">FORGOT EMAIL</p>
                  <s.InputWrapper>
                    <p className="description">
                      Enter the phone number saved in your profile to receive
                      the associated email address.
                    </p>
                    <PhoneInput
                      login
                      placeholder="Enter your phone number"
                      onChange={onChangePhoneNumber}
                      onEnterKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
                        if (e.key === "Enter") {
                          onClickForgotEmail();
                        }
                      }}
                      isValidated={isValidated.tel}
                    />
                    <cs.ValidateWrapper>
                      <ValidateWord>{validateMsg.tel}</ValidateWord>
                    </cs.ValidateWrapper>
                    <cs.BigButton
                      id="dw_login_button_sendForgotEmail"
                      onClick={onClickForgotEmail}
                    >
                      Send
                    </cs.BigButton>
                    <cs.Bottom>
                      <UnderlineText
                        id="dw_login_button_forward_email"
                        fontSize={14}
                        color={COLORS.BUTTONS1}
                        onClick={() =>
                          onClickStepChange(LOGIN_STEP.INPUT_EMAIL)
                        }
                      >
                        Go Back
                      </UnderlineText>
                    </cs.Bottom>
                  </s.InputWrapper>
                </>
              )}

              {step === LOGIN_STEP.FORGOT_EMAIL_SENT && (
                <>
                  <p className="title">EMAIL REMINDER SENT</p>
                  <s.InputWrapper>
                    <p className="description">
                      We've sent a text message to&nbsp;
                      <strong>{props.loginInfo.tel}</strong>&nbsp;with your myDW
                      email. Click "resend" if you did not receive it.
                    </p>
                    <cs.MarginBottom>
                      <cs.BigButton
                        cancel
                        id="dw_login_button_resend"
                        onClick={onClickForgotEmail}
                      >
                        Resend Message
                      </cs.BigButton>
                      <cs.BigButton
                        id="dw_login_button_forward_email"
                        onClick={() =>
                          onClickStepChange(LOGIN_STEP.INPUT_EMAIL)
                        }
                      >
                        Back to login screen
                      </cs.BigButton>
                    </cs.MarginBottom>
                  </s.InputWrapper>
                </>
              )}
              {step === LOGIN_STEP.FORGOT_PASSWORD && (
                <>
                  <p className="title">FORGOT PASSWORD</p>
                  <s.InputWrapper>
                    <p className="description">
                      We've sent an email to{" "}
                      <strong>{props.loginInfo.email}</strong>, please follow
                      the link in the email to reset your password.
                    </p>
                    <cs.Bottom>
                      <UnderlineText
                        id="dw_login_button_forward_password"
                        fontSize={14}
                        color={COLORS.BUTTONS1}
                        onClick={() =>
                          onClickStepChange(LOGIN_STEP.INPUT_PASSWORD)
                        }
                      >
                        Go Back
                      </UnderlineText>
                    </cs.Bottom>
                  </s.InputWrapper>
                </>
              )}
              {step === LOGIN_STEP.FREE_TRIAL && (
                <FreeTrial
                  isValidated={isValidated}
                  validateMsg={validateMsg}
                  onChangeFreeTrialInfo={(e: React.ChangeEvent<HTMLInputElement>) => onChangeFreeTrialInfo(e)}
                  onClickStepChange={onClickStepChange} onSubmitFreeTrial={onSubmitFreeTrial} />
              )}
              {step === LOGIN_STEP.INPUT_PASSWORD && (
                <s.LoginIdPWWrapper>
                  <p className="title-wrapper" />
                  <button onClick={() => window.location.reload()}>
                    <img
                      src={MYDW_ICON}
                      width="120"
                      alt="MyDW Icon"
                      className="mydw-logo"
                    />
                  </button>
                  <p className="title">Log in to myDW Web Portal</p>

                  <cs.EmailBadge>{loginInfo.email}</cs.EmailBadge>
                  <s.InputWrapper>
                    <PasswordInput
                      value={loginInfo.password}
                      isValidated={isValidated.password}
                      onChange={onChangeLoginInfo}
                      onClickLogin={onClickLogin}
                    />

                    <cs.ValidateWrapper>
                      <ValidateWord>{validateMsg.password}</ValidateWord>
                    </cs.ValidateWrapper>
                    <cs.BigButton
                      id="dw_login_button_login"
                      onClick={onClickLogin}
                    >
                      Login
                    </cs.BigButton>
                  </s.InputWrapper>
                  <cs.Bottom>
                    <UnderlineText
                      id="dw_login_button_forward_email"
                      color={COLORS.BUTTONS1}
                      fontSize={14}
                      onClick={() => onClickStepChange(LOGIN_STEP.INPUT_EMAIL)}
                    >
                      Go Back
                    </UnderlineText>
                    <UnderlineText
                      id="dw_login_button_forward_forgotEmail"
                      color={COLORS.TOGGLE}
                      fontSize={14}
                      onClick={onClickForgotPassword}
                    >
                      {intl.formatMessage({
                        id: "forgot_password",
                        defaultMessage: "Forgot Password",
                      })}
                      ?
                    </UnderlineText>
                  </cs.Bottom>
                </s.LoginIdPWWrapper>
              )}
            </cs.LoginBox>
            <s.Version>v{process.env.REACT_APP_VERSION}</s.Version>
          </cs.Container>
        </cs.BG>
      </Spin>
      {modal && modal}
    </>
  );
}
