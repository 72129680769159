import styled from "styled-components";
import * as mediaQuery from "components/MediaQuery";

export const InnerPX = 40;
const InnerContainer = styled.div<{ map?: boolean; help?: boolean; ref?: any }>`
  margin: 0 auto;
  height: calc(100% - 50px);
  overflow-y: auto;

  ${mediaQuery.isDefault} {
    /* width: 90%; */
    padding: 0 ${InnerPX}px;

    ${({ map }) =>
      map &&
      `
      padding: 0;
      width: 100%;
      position: relative;
  `}

    .table-template {
      display: flex;
      flex: 1;
      flex-direction: column;
      height: 100%;

      > .subheader {
        flex: 0 0 auto;
      }
    }
  }
  ${mediaQuery.isMobile} {
    width: 100%;
    padding: 0 10px;

    ${({ map }) =>
      map &&
      `
      padding: 0;
      width: 100%;
      height: 100%;
      overflow-y: unset;
    `}
    ${({ help }) =>
      help &&
      `
      padding: 0;
    `}
  }
`;

export default InnerContainer;
