import TinyButton from "components/atoms/buttons/TinyButton";
import SearchInput from "components/atoms/input/search/SearchInput";
import { Text, UnderlineText } from "components/atoms/text/Text";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { COLORS } from "styles/colors";
import * as s from "../FilterStyled";
import { RecorderFilterTable } from "./RecorderFilterTable";
import {
  DashboardFilterRecorderData,
  DashboardFilterRecorderGroup,
  DashboardFilterRecorderGroupTableType,
  DashboardFilterRecorderResult,
} from "api/interfaces/dashboardInterface.interface";
import { MapFilter, setRecorders } from "redux/reducers/map/mapFilter";
import { useQuery } from "react-query";
import { getFilterRecorders } from "api/dashboardAPI";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useQueryClient } from "react-query";
import { convertType } from "utils/functions";
type Props = {
  isOpen: boolean;
  type: "recorder" | "group";
  onApply: () => void;
  onChangeFiltering: (name: string, value: boolean) => void;
  onChangeFilterName: (changeFilterName: string) => void;
};

const RecorderFilter = (props: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const filter: MapFilter = useAppSelector((state) => state.mapFilter);

  // const [currentMenu, setCurrentMenu] = useState<number>(0);
  const [searchInput, setSearchInput] = useState<string>("");

  const [recorderData, setRecorderData] = useState<
    DashboardFilterRecorderData[]
  >([]);

  const [recorderRowSelection, setRecorderRowSelection] = useState<React.Key[]>(
    []
  );

  const getRecorders = useQuery(
    ["dashboard_recorder", selectedAccount, props.isOpen],
    () =>
      getFilterRecorders({
        accountId: selectedAccount.accountId,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,

      onSuccess: (res: any) => {
        // console.log(res, "recroder filter");
        if (res.error === 0 && res.result !== undefined) {
          setRecorderData(
            res.result.map((r: DashboardFilterRecorderResult) => {
              return {
                ...r,
                type: convertType(r),
                locationName: r.location.location,
              };
            })
          );
        }
      },
      onError: (e: any) => {},
    }
  );

  const onApply = () => {
    // let tempArr: string[] = [];

    // if (props.type === "recorder") {
    //   // console.log(recorderRowSelection);
    //   // recorderId 로 변경해야함
    //   tempArr = recorderRowSelection.map((r) => r.recorderId);
    //   dispatch(setRecorders(tempArr));
    // } else {
    //   recorderGroupRowSelection.length !== 0 &&
    //     recorderGroupRowSelection.map((r) => {
    //       tempArr.push(`accountId:${r.accountId}`);
    //       tempArr.push(
    //         `accountId:${r.accountId}>recorderGroupId:${r.recorderGroupId}`
    //       );
    //       if (r.recorderId) tempArr.push(r.recorderId);
    //     });
    // }
    // console.log(tempArr);
    props.onApply();
  };
  const onChangeSearch = (e: any) => {
    setSearchInput(e);
  };

  const onResetFilter = () => {
    setSearchInput("");
    queryClient.invalidateQueries("searchMapFilterResult");
    setRecorderRowSelection([]);
  };

  useEffect(() => {
    setSearchInput("");
    setRecorderRowSelection([]);
  }, [filter.keyword]);

  useEffect(() => {
    setRecorderRowSelection([]);
  }, [recorderData]);

  useEffect(() => {
    let isFilter = recorderRowSelection.length === 0;

    if (isFilter) {
      dispatch(setRecorders([]));
      props.onChangeFilterName("Recorder");
    } else {
      dispatch(setRecorders(recorderRowSelection as string[]));
      if (recorderRowSelection.length === 1) {
        let filterName = recorderData.find(
          (rec) => rec.recorderId === recorderRowSelection[0]
        )?.name;
        props.onChangeFilterName(filterName ? filterName : "Recorder");
      } else if (recorderRowSelection.length === recorderData.length) {
        props.onChangeFilterName("All Recorders");
      } else {
        props.onChangeFilterName(`${recorderRowSelection.length} Recorders`);
      }
    }

    props.onChangeFiltering("recorders", !isFilter);
  }, [recorderRowSelection]);

  return (
    <s.Container>
      <s.Title>
        <Text fontSize={14} bold>
          Recorder List
        </Text>
        <SearchInput map onClickSearch={onChangeSearch} keyword={searchInput} />
      </s.Title>
      <s.RecorderContents>
        {props.type === "recorder" && (
          <RecorderFilterTable
            searchInput={searchInput}
            recorderData={recorderData}
            recorderRowSelection={recorderRowSelection}
            setRecorderRowSelection={setRecorderRowSelection}
          />
        )}
        {/* {props.type === "group" && (
          <RecorderGroupFilterTable
            searchInput={searchInput}
            recorderGroupData={props.recorderGroupData}
            setRecorderRowSelection={setRecorderGroupRowSelection}
          />
        )} */}
      </s.RecorderContents>
      <s.Reset>
        <UnderlineText
          color={COLORS.LIGHTGRAY}
          fontSize={12}
          onClick={onResetFilter}
        >
          Clear
        </UnderlineText>
        <TinyButton label="Done" onClickBtn={onApply} />
      </s.Reset>
    </s.Container>
  );
};

export default RecorderFilter;
