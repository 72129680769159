import SmallPopupTemplate from "components/templates/modal/small/SmallPopupTemplate";
import { GoAlertFill } from "react-icons/go";
import * as s from './ScreenRecorderExceptionStyled';
import { COLORS } from "styles/colors";


type VideoShareExceptionType = {
  onClose: () => void;
}

export function VideoShareException({ onClose }: VideoShareExceptionType) {
  return (
    <SmallPopupTemplate
      titleIcon={s.titleIcon}
      title="Something went wrong"
      onClick={onClose}
      onModal={onClose}
      confirmLabel="OK"
    >
      <s.ErrorMessage>Unable to upload video</s.ErrorMessage>
    </SmallPopupTemplate>
  )
}
