import styled from "styled-components";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import ReactPaginate from "react-paginate";
import { calcRem } from "styles/theme";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { useAppSelector } from "redux/hooks";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { isNull } from "lodash";

// rowsPerPage: PropTypes.number.isRequired, 한 페이지 당 row 수
// rowCount: PropTypes.number.isRequired,
// onChangePage: PropTypes.func.isRequired,
// onChangeRowsPerPage: PropTypes.func.isRequired, 한 페이지 당 row 개수 변경 함수
// currentPage: PropTypes.number.isRequired 현재 page

export type PaginationChangePage = (page: number, totalRows: number) => void;
export type PaginationComponentProps = {
  rowsPerPage: number;
  rowCount: number;
  currentPage: number;
  onChangePage: PaginationChangePage;
};

function AntdPagination({
  rowsPerPage,
  rowCount,
  currentPage,
  onChangePage,
}: PaginationComponentProps): JSX.Element {
  // 총 데이터 개수 / 한 페이지 당 row 개수
  let totalPages = Math.ceil(rowCount / rowsPerPage);
  const theme = useAppSelector((state) => state.theme.theme);
  const isLight = theme === "light";

  const location: any = useLocation();

  const [forcePage, setForcePage] = useState<number>(0);

  useEffect(() => {
    const changeParam = new URLSearchParams(location.search);
    if (changeParam.size > 0) {
      if (!isNull(changeParam.get("pageNum"))) {
        setForcePage(Number(changeParam.get("pageNum")));
      } else {
        setForcePage(0);
      }
    } else {
      //RND-975
      setForcePage(0);
    }
  }, [location]);

  useEffect(() => {
    setForcePage(currentPage);
  }, [currentPage]);

  // console.log(currentPage, rowCount, totalPages, forcePage);
  return (
    <PaginateContainer>
      <MyPaginate
        breakLabel="..."
        nextLabel={
          <ArrowButton
            onClick={() => {
              if (currentPage < totalPages) {
                onChangePage(currentPage + 1, rowCount);
              }
            }}
            disabled={currentPage === rowCount}
          >
            <FiChevronRight
              size={14}
              color={isLight ? COLORS.BLACK : COLORS.WHITE}
            />
          </ArrowButton>
        }
        onPageChange={(e) => {
          // console.log(e.selected, currentPage,22);
          onChangePage(e.selected + 1, rowCount);
        }}
        forcePage={forcePage}
        pageRangeDisplayed={5}
        pageCount={totalPages === 0 ? 1 : totalPages}
        // totalPages 0 이면 pagination 에 표시되는 값이 아예 없음.. 그래서 1로 처리했는데 추후에 문제될 것 같음
        previousLabel={
          <ArrowButton
            onClick={() => {
              if (currentPage > 0) onChangePage(currentPage - 1, rowCount);
            }}
            disabled={currentPage === 0}
          >
            <FiChevronLeft
              size={14}
              color={isLight ? COLORS.BLACK : COLORS.WHITE}
            />
          </ArrowButton>
        }
      />
    </PaginateContainer>
  );
}

const ArrowButton = styled.button`
  ${FLEX.FlexCenterCenter};
  /* padding: 0 15px; */
`;

const PaginateContainer = styled.div`
  width: 100%;
  /* border: 1px solid red; */
  border-top: 1px solid ${({ theme }) => theme.COLORS.BORDER};
`;

const MyPaginate = styled(ReactPaginate).attrs({
  // You can redifine classes here, if you want.
  activeClassName: "active", // default to "disabled"
})`
  ${FLEX.FlexCenterCenter};
  gap: 10px;
  margin: 20px 0;
  /* padding: 10px 0 20px; */

  // 기본 페이지 버튼
  li a {
    ${FLEX.FlexCenterCenter};
    border: none;
    padding: 0 5px;
    cursor: pointer;
    color: ${({ theme }) => theme.COLORS.WHITE};
    font-size: ${calcRem(16)};
  }

  li a:hover {
    /* transform: translateY(-2px); */
    text-decoration: underline;
  }

  li.active a {
    color: ${({ theme }) => theme.COLORS.SELECTED};

    text-decoration: underline;
  }
  li.disabled a {
    color: grey;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }

  // 화살표는 스타일 없음
  li.previous,
  li.next {
    background-color: transparent;
  }
  li.previous a,
  li.next a:hover {
    border: none;
  }
`;

export default AntdPagination;
