import * as s from "../VideoShareStyled";
import { TableTemplate } from "components/templates/default/table/TableTemplate";
import { useNavigate } from "react-router-dom";
import { ReactComponent as VIDEO_SHARE_ICON } from "assets/icons/videoshare_white.svg";
import * as mediaQuery from "components/MediaQuery";
import { useMediaQuery } from "react-responsive";
import VideoPlayer, {
  MemoVideoPlayer,
} from "components/blocks/video/VideoPlayer";
import {
  VideoPlayList,
  VideoShareViewResult,
} from "api/interfaces/videoshareInterface.interface";
import ViewVSContent from "./ViewVSContent";
import DeleteConfirmModal from "components/templates/modal/small/confirm/delete";
import ViewVSUserList from "./ViewVSUserList";
import { useRef, useState } from "react";
import { menuNavigation } from "utils/MenuUtil";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";

type Props = {
  videoId: string | undefined;
  videoUrl: string;
  state: VideoShareViewResult | undefined;
  isLoading: boolean;
  isDelete: boolean;
  isDisabledAdd: boolean;
  playerReady: (player: any) => void;
  onDelete: (value: boolean) => void;
  onConfirmDelete: () => void;
  onUpdateContentValues: (key: string, value: any) => void;
  selectedAccount: ProfileAccountInfo;
};

export default function ViewVSPresenter({
  videoId,
  videoUrl,
  state,
  isLoading,
  isDelete,
  isDisabledAdd,
  playerReady,
  onDelete,
  onConfirmDelete,
  onUpdateContentValues,
  selectedAccount,
}: Props): JSX.Element {
  const navigate = useNavigate();
  const ref = useRef<any>();

  const titleComponent = [
    {
      label: (
        <s.TitleComponent>
          <p className="icon">
            <VIDEO_SHARE_ICON />
          </p>
          Video Share
        </s.TitleComponent>
      ),
      onClick: () => menuNavigation(selectedAccount, navigate, "/videoShare"),
    },
    {
      label: state?.title ? state.title : "Undefined",
    },
  ];

  // setting the video-js option for the player
  const videoJsOptions = {
    autoplay: false,
    controls: true,
    fill: true,
    crossorigin: "anonymous",
    muted: true,
    controlBar: {
      pictureInPictureToggle: false,
    },
    hls: {
      overrideNative: true,
    },
    html5: {
      vhs: {
        withCredentials: true,
      },
    },
    // sources: [
    //   {
    //     src: videoUrl,
    //     // type: "application/x-mpegURL",
    //   },
    // ],
  };

  const ismediaQuery = useMediaQuery({ minWidth: mediaQuery.tabletMin });

  return (
    <>
      {!isLoading &&
        state !== undefined &&
        state.title !== "" &&
        videoId !== undefined && (
          <TableTemplate titleComponent={titleComponent} noSearch>
            <s.ViewInner>
              <MemoVideoPlayer
                title={state.title}
                videoUrl={state.url}
                videoResult={state}
                videoId={videoId}
                options={videoJsOptions}
                onReady={playerReady}
                isAuth={true}
                timezone={state.timeZone}
                isDisabledAdd={isDisabledAdd}
                onClickShare={() => {
                  if (ref.current !== undefined) {
                    ref.current.openSharePopup();
                  }
                }}
              />
              <ViewVSContent
                videoId={videoId}
                state={state}
                onDelete={onDelete}
                onUpdateContentValues={onUpdateContentValues}
              />
            </s.ViewInner>
            <ViewVSUserList
              videoId={videoId}
              isDisabledAdd={isDisabledAdd}
              ref={ref}
            />
          </TableTemplate>
        )}
      {isDelete && (
        <DeleteConfirmModal
          onDelete={() => onDelete(false)}
          onConfirmDelete={onConfirmDelete}
        />
      )}
    </>
  );
}
