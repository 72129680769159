import BigButton from "components/atoms/buttons/BigButton";
import SmallPopupTemplate from "components/templates/modal/small/SmallPopupTemplate";
import { PiCheckCircleFill } from "react-icons/pi";
import * as s from './ScreenRecorderVideoShareStyled';
import { COLORS } from "styles/colors";
import { useNavigate } from "react-router-dom";

const TECH_SUPPORT_EMAIL = 'DW-Tech@digital-watchdog.com';

type VideoShareUploadCompletedType = {
  videoId: string;
  onClose: () => void;
  onFinish: () => void;
}

export function VideoShareUploadCompleted({ videoId, onClose, onFinish }: VideoShareUploadCompletedType) {
  const navigate = useNavigate();

  function goToVideoShareDetail() {
    if (!videoId) {
      return;
    }
    const searchParams = new URLSearchParams();
    searchParams.append('share', TECH_SUPPORT_EMAIL);
    searchParams.append('sharelabel', 'Tech Support');
    navigate(`videoShare/${videoId}?${searchParams.toString()}`);
    onFinish();
  }

  const buttons: JSX.Element = (
    <BigButton
      disabled={!videoId}
      label={`Share this video with\n${TECH_SUPPORT_EMAIL}`}
      onClickBtn={goToVideoShareDetail}
    />
  );

  return (
    <SmallPopupTemplate
      title="Success!"
      onClick={() => { }}
      onModal={onClose}
      buttons={buttons}
    >
      <s.Content>
        <br />
        <PiCheckCircleFill title="dd?" color={COLORS.GREEN} size="60px" />
        <br /><br />
        <p>Upload completed.<br />You can view the recorded video in Video Share.</p>
      </s.Content>
    </SmallPopupTemplate>
  )
}
