import * as s from "./SubHeaderStyled";
import BigButton from "components/atoms/buttons/BigButton";
import SearchInput, {
  ClickSearch,
} from "components/atoms/input/search/SearchInput";
import { useLocation, useNavigate } from "react-router-dom";
import type {
  RoutesProp,
  RoutesProps,
} from "api/interfaces/menuInterface.interface";
import { useAppSelector } from "redux/hooks";
import { isNil, noop } from "lodash";
import { BsChevronRight } from "react-icons/bs";
import * as mediaQuery from "components/MediaQuery";
import RoutesMark from "../headernav/sub/RoutesMark";
import RBACWrapper from "../function/RBACWrapper";
import { Fragment } from "react";
import {
  LEVEL_TYPE,
  ProfileAccountInfo,
} from "api/interfaces/accountInterface.interface";

export interface HeaderRoutesProps {
  name: string;
  routeName?: string;
  accountId?: string;
}
export interface TitleComponentArrayType {
  label: string | React.ReactNode;
  onClick?: () => void | undefined;
  isNoshow?: boolean;
}

type SubHeaderProps = {
  onClickBtn?: () => void; // Add 버튼 이벤트
  buttonLabel?: string | null; // Add 버튼 라벨
  status?: boolean; // 상태
  centerChildren?: React.ReactNode | null; //상태값이나 메뉴들과 같은 컴포넌트 children
  keyword?: string;
  onClickSearch?: ClickSearch; // 검색 돋보기 버튼 클릭 이벤트
  titleComponent?: string | React.ReactNode | Array<TitleComponentArrayType>;
  noSearch?: boolean; // 검색창 없을 때
  buttons?: React.ReactNode;
  isSelf?: boolean; // account page breadcrumb
  requiredPermissions?: string; // button Permission
  licenseInfo?: React.ReactNode | null;
};

function SubHeader({
  buttonLabel,
  onClickBtn,
  centerChildren,
  onClickSearch = () => {},
  keyword,
  titleComponent,
  noSearch,
  buttons,
  isSelf,
  requiredPermissions,
  licenseInfo,
}: SubHeaderProps): JSX.Element {
  const location = useLocation();
  const menu: Array<RoutesProps> = useAppSelector(
    (state) => state.menuSelect.menuData
  );
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  let submenu: Array<RoutesProp> | [] = isNil(menu) ? [] : menu[0].children;

  const isReportPage = location.pathname.includes("/reports");
  const isEULicense =
    location.pathname.includes("/recorders") &&
    selectedAccount.accountLevel === LEVEL_TYPE.EU;

  const titleReturn = (
    titleComponent: string | React.ReactNode | Array<TitleComponentArrayType>
  ) => {
    if (titleComponent) {
      if (Array.isArray(titleComponent)) {
        return (
          <s.Title>
            <s.TitleWrapper>
              {titleComponent.map(
                (item, index) =>
                  !item.isNoshow && (
                    <Fragment key={index}>
                      {index > 0 && (
                        <span className="chevron-right">
                          <BsChevronRight size={16} />
                        </span>
                      )}
                      <s.TitleCrumb
                        key={index}
                        index={index}
                        onClick={item.onClick ? item.onClick : noop}
                      >
                        {item.label}
                      </s.TitleCrumb>
                    </Fragment>
                  )
              )}
            </s.TitleWrapper>
          </s.Title>
        );
      }
      if (typeof titleComponent === "string")
        return (
          <s.Title>
            <s.FirstTitle>{titleComponent}</s.FirstTitle>
          </s.Title>
        );
      return titleComponent;
    } else
      return (
        <s.Title>
          <s.FirstTitle>
            {submenu?.map((item: RoutesProp, index: number) => (
              <Fragment key={index}>
                {location.pathname.includes(item.routeName as string) &&
                  item.title}
                {/* {location.pathname === item.routeName && item.title} */}
              </Fragment>
            ))}
          </s.FirstTitle>
        </s.Title>
      );
  };

  return (
    <s.SubHeader className="subheader">
      <s.TopWrapper>
        <mediaQuery.Default>
          <RoutesMark isSelf={isSelf} />
          {isEULicense && licenseInfo}
        </mediaQuery.Default>
      </s.TopWrapper>

      <s.HeaderContainer>
        <mediaQuery.Mobile>
          <s.MobileRow>
            {titleReturn(titleComponent)}

            {centerChildren && <s.CenterAside>{centerChildren}</s.CenterAside>}
          </s.MobileRow>
        </mediaQuery.Mobile>
        <mediaQuery.Default>
          <s.TitleLeft>
            {titleReturn(titleComponent)}

            {centerChildren && <s.CenterAside>{centerChildren}</s.CenterAside>}
          </s.TitleLeft>
        </mediaQuery.Default>

        <s.RightAside>
          <mediaQuery.Mobile>
            {buttons && buttons}
            {!noSearch && (
              <s.SearchInputWrapper>
                <SearchInput
                  keyword={keyword}
                  placeholder="Search"
                  onClickSearch={onClickSearch}
                />
              </s.SearchInputWrapper>
            )}
            {/* {buttonLabel && onClickBtn && (
              <RBACWrapper requiredPermissions={requiredPermissions as string}>
                <s.BigButtonBox>
                  <BigButton label={buttonLabel} add onClickBtn={onClickBtn} />
                </s.BigButtonBox>
              </RBACWrapper>
            )} */}
          </mediaQuery.Mobile>
          <mediaQuery.Default>
            {!noSearch && (
              <s.SearchInputWrapper>
                <SearchInput
                  keyword={keyword}
                  placeholder="Search"
                  onClickSearch={onClickSearch}
                />
              </s.SearchInputWrapper>
            )}
            {buttonLabel && onClickBtn && (
              <RBACWrapper requiredPermissions={requiredPermissions as string}>
                <BigButton
                  id={"dw_button_".concat(
                    buttonLabel.replace(/(\s*)/g, "").toLowerCase()
                  )}
                  label={buttonLabel}
                  add
                  onClickBtn={onClickBtn}
                />
              </RBACWrapper>
            )}
            {buttons && buttons}
          </mediaQuery.Default>
        </s.RightAside>
      </s.HeaderContainer>
    </s.SubHeader>
  );
}

export default SubHeader;
