import { UserGroupUserPermission } from "api/interfaces/userInterface.interface";
import { useIntl } from "react-intl";
import { CheckInput, ModalTableContainer } from "styles/ModalCommonStyled";
import DwPermissionCheckList from "./DwPermissionCheckList";

interface Props {
  isEdit?: boolean;
  userCheckedList: UserGroupUserPermission;
  myDwDisabledList: UserGroupUserPermission;
  onChangeDwPermission: (e: React.ChangeEvent<HTMLInputElement>) => void;
  validateMsg: string;
}

function DwPermissionStep(props: Props): JSX.Element {
  const intl = useIntl();

  return (
    <>
      <CheckInput>
        <input
          type="checkbox"
          name="enableAllPermissions"
          defaultChecked={props.userCheckedList.enableAllPermissions}
          checked={props.userCheckedList.enableAllPermissions}
          onChange={props.onChangeDwPermission}
          disabled={props.myDwDisabledList.enableAllPermissions}
        />
        {intl.formatMessage({
          id: "user.permission.enableAllPermissions",
          defaultMessage: "Administrator",
        })}
      </CheckInput>
      <ModalTableContainer style={{ paddingTop: 16 }}>
        {!props.userCheckedList.enableAllPermissions && (
          <DwPermissionCheckList
            userCheckedList={props.userCheckedList}
            userDisabledList={props.myDwDisabledList}
            onChangeDwPermission={props.onChangeDwPermission}
          />
        )}
      </ModalTableContainer>
    </>
  );
}

export default DwPermissionStep;
