import { AxiosResponse } from "axios";
import { AxiosInstance } from "./axiosInstance";
import { GetOperationLogRequest, GetOperationLogResponse } from "./interfaces/operationlogInterface.interface";
import qs from "qs";

export const getOperationLogs = async(request: GetOperationLogRequest, dwAccountId: string): Promise<GetOperationLogResponse> => {
  try {
    const { data }: AxiosResponse<GetOperationLogResponse> = 
      await AxiosInstance.get(
      `actionLoggers/${dwAccountId}?${qs.stringify(request,{ skipNulls: true })}`
      );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};
