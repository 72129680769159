import {
  LEVEL_TYPE,
  ProfileAccountInfo,
  ProfileAccountInfoFromServer,
} from "api/interfaces/accountInterface.interface";
import {
  getSwitchAccount,
  getUserDetailAccount,
  getUserDetailAll,
} from "api/userAPI";
import {
  RecorderPermission,
  UserInfo,
  UserGroupUserPermission,
  UserGroupRecorderPermission,
  UserGroupRecorderTypePermission,
} from "api/interfaces/userInterface.interface";
import { changeFirstWordToUppercase } from "./functions";
import { useNavigate } from "react-router-dom";
import qs from "qs";
import { isEmpty } from "lodash";
import {
  defaultCheckedUserPermission,
  defaultRoleCheckList,
} from "pages/users/add/group/PermissionPolicy";

export const getAllAccountInfo = () => {
  return new Promise<UserInfo>(function (resolve, reject) {
    getUserDetailAll()
      .then((response) => {
        console.log(response, " response");
        resolve(response);
      })
      .catch(function (err) {
        reject("communication fail");
      });
  });
};

export const getAccountInfo = (accountId: string) => {
  return new Promise<ProfileAccountInfoFromServer>(function (resolve, reject) {
    getUserDetailAccount(accountId)
      .then((response) => {
        // console.log(response, " response");
        resolve(response.accounts);
      })
      .catch(function (err) {
        reject("communication fail");
      });
  });
};

export const getSwitchAccountInfo = (accountId: string) => {
  return new Promise<ProfileAccountInfoFromServer>(function (resolve, reject) {
    getSwitchAccount(accountId)
      .then((response) => {
        // console.log(response, " response");
        resolve(response.accounts);
      })
      .catch(function (err) {
        reject("communication fail");
      });
  });
};

export interface CheckPermissionParamType {
  userPermission: UserGroupUserPermission[];
  accountLevel: LEVEL_TYPE;
}

export const checkOnlyAdminGroup = (permission: UserGroupUserPermission[]) => {
  const check = permission?.filter(
    (permit) =>
      !permit.enableDelete &&
      permit.groupName?.toLowerCase() === "administrator"
  );
  return check?.length !== 0 ? true : false;
};
export const checkedUserAdmin = (permission: UserGroupUserPermission[]) => {
  const check = permission?.filter(
    (permit) => permit.enableAllPermissions === true
  );
  return check.length !== 0 ? true : false;
};

export const checkPermission = ({
  userPermission,
  accountLevel,
}: CheckPermissionParamType): UserGroupUserPermission => {
  // 기본 권한 정보 가져오기
  const defaultPermissions = {
    ...defaultCheckedUserPermission(
      checkOnlyAdminGroup(userPermission),
      checkedUserAdmin(userPermission)
    )[accountLevel],
  };

  // 기본 권한 객체를 그대로 반환할 새로운 객체
  let finalPermissions: UserGroupUserPermission = { ...defaultPermissions };

  // userPermission이 비어있으면 기본 권한 반환
  if (userPermission.length === 0) return finalPermissions;

  // userPermission 배열을 순회하며 권한 업데이트
  Object.keys(defaultPermissions).forEach((key) => {
    const typedKey = key as keyof UserGroupUserPermission;

    // boolean 타입인 경우 처리
    if (typeof defaultPermissions[typedKey] === "boolean") {
      finalPermissions = {
        ...finalPermissions,
        [typedKey]: userPermission.some(
          (permission) => permission[typedKey] === true
        ),
      };
    }
    // string 타입인 경우 처리
    else if (typeof defaultPermissions[typedKey] === "string") {
      const stringPermission = userPermission.find(
        (permission) => typeof permission[typedKey] === "string"
      );
      finalPermissions = {
        ...finalPermissions,
        [typedKey]: stringPermission?.[typedKey] ?? "",
      };
    }
  });

  return finalPermissions;
};

export const isCheckPermission = (
  requiredPermissions: string,
  selectedAccount: ProfileAccountInfo | ProfileAccountInfoFromServer
) => {
  const hasRequiredPermissions =
    selectedAccount.userPermission[
      requiredPermissions as keyof typeof selectedAccount.userPermission
    ];
  return hasRequiredPermissions as boolean;
};

export const checkGroup = (
  permission: UserGroupUserPermission[],
  group: string
) => {
  const check = permission.filter(
    (permit) =>
      (!permit.enableDelete && permit.groupName?.toLowerCase() === group) ||
      permit.enableAllPermissions
  );
  return check.length !== 0 ? true : false;
};

export const checkRecorderPermission = (
  array: UserGroupRecorderPermission[]
): UserGroupRecorderPermission => {
  return array.reduce(
    (acc, cur) => {
      Object.keys(acc).forEach((key) => {
        if (key !== "recorderPermissionLevel") {
          const typedKey = key as keyof UserGroupRecorderTypePermission;
          (
            Object.keys(acc[typedKey]) as Array<
              keyof UserGroupRecorderTypePermission[typeof typedKey]
            >
          ).forEach((subKey) => {
            acc[typedKey][subKey] =
              acc[typedKey][subKey] || cur[typedKey][subKey];
          });
        }
      });

      const levels = array.map((item) => item.recorderPermissionLevel);
      if (levels.includes("Administrator")) {
        acc.recorderPermissionLevel = "Administrator";
      } else if (levels.includes("Viewer")) {
        acc.recorderPermissionLevel = "Viewer";
      } else {
        acc.recorderPermissionLevel = "Custom";
      }

      return acc;
    },
    {
      recorderPermissionLevel: "Custom",
      ...(defaultRoleCheckList.custom.check as UserGroupRecorderTypePermission),
    } as UserGroupRecorderPermission
  );
};

export const isHiddenGroup = (array: UserGroupUserPermission[]) => {
  if (array === undefined || array.length > 1) {
    return false;
  } else {
    if (
      array.length === 1 &&
      array[0].groupName?.toUpperCase() === "HIDDEN"
      // array[0].isHidden !== undefined &&
      // array[0].isHidden
    ) {
      return true;
    } else {
      return false;
    }
  }
};

// isTrial 과 licenseType 에 따른 menu 보여주기
export const checkTrialShowMenu = (
  accounts: ProfileAccountInfo | ProfileAccountInfoFromServer
) => {
  if (!accounts.isTrial && accounts.licenseType === "none") return true;
  else if (accounts.isSuspended) return true;
  else return false;
};

export const changeToTrialType = (accounts: ProfileAccountInfo) => {
  if (
    accounts.trialDays === undefined ||
    accounts.isTrial === undefined ||
    accounts.licenseType === undefined
  )
    return "";

  if (accounts.isTrial && accounts.trialDays > 0) {
    return "Trial";
  }

  if (!accounts.isTrial && accounts.licenseType !== "none") {
    return changeFirstWordToUppercase(accounts.licenseType);
  } else return "Free";
};

export const getSpectrumAuthUrl = (loginEmail?: string) => {
  const OATUH_HOST = "https://dwspectrum.digital-watchdog.com/authorize";
  const client_id = "mydw-api";
  const redirect_uri = process.env.REACT_APP_OAUTH_CALLBACK_URL;
  const response_type = "code";
  const email =
    loginEmail !== undefined && !isEmpty(loginEmail)
      ? loginEmail.toLowerCase()
      : null;

  const AUTHORIZE_URI = `${OATUH_HOST}?${qs.stringify(
    {
      client_id,
      redirect_uri,
      response_type,
      email,
    },
    { skipNulls: true }
  )}`;

  return AUTHORIZE_URI;
};

export const isSubCP = (
  selectedAccount: ProfileAccountInfo
): boolean | undefined => {
  if (selectedAccount) {
    if (
      selectedAccount.accountLevel === LEVEL_TYPE.CP &&
      selectedAccount.parentLevel === LEVEL_TYPE.CP
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const displayCountOrg = (orgArray: string[], totalCount: number) => {
  if (orgArray === undefined || orgArray.length === 0 || totalCount === 0) {
    return "Not Selected";
  }
  if (orgArray.length === 1) {
    return orgArray[0];
  } else {
    return `${orgArray[0]}+${orgArray.length - 1}`;
  }
};
