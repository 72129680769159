// import ARROWDOWN from "assets/icons/arrow_down.svg";
import { FocusEventHandler, useCallback, useRef, useState } from "react";
import Select, {
  InputActionMeta,
  MultiValue,
  MultiValueProps,
  OptionProps,
  StylesConfig,
  components,
} from "react-select";
import CreatableSelect from "react-select/creatable";
import { COLORS, LIGHT_THEME_COLORS } from "styles/colors";
import { Option } from "utils/options";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import {
  BasePagination,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import { useAppSelector } from "redux/hooks";
import { useQuery } from "react-query";
import { getUserList, getUserTotalList } from "api/userAPI";
import styled from "styled-components";
import { FLEX } from "styles/flex";
import { Text } from "components/atoms/text/Text";
import { isEmpty } from "lodash";
import { useIntl } from "react-intl";
import { calcRem } from "styles/theme";
// type OptionType = {
//   options: Array<Option>;
//   isDisabled?: boolean;
// };

// type OptionType = { [string: string]: any; isDisabled?: boolean };

export interface UserEmailSelect {
  [key: string]: string;
}
type OptionsType = Array<Option>;

type CommonProps = {
  // clearValue: () => void,
  // getStyles: (string, any) => {},
  // getValue: () => ValueType,
  // hasValue: boolean,

  // selectOption: OptionType => void,
  // selectProps: any,
  // setValue: (ValueType, ActionTypes) => void,
  // emotion: any,
  value?: any;
  defaultValue?: any;
  isDisabled?: boolean;
  onChange?: (value: MultiValue<any>) => void;
  placeholder?: string;
  id?: string;
  isVs?: boolean;
  isMulti?: true | undefined;
};

const popStyles: StylesConfig<Option> = {
  control: (styles, state) => {
    let isLightTheme = state.selectProps.className === "light-select";
    const backgroundColor = state.isDisabled
      ? isLightTheme
        ? LIGHT_THEME_COLORS.LIGHTGRAY
        : COLORS.BUTTONS1
      : COLORS.WHITE;
    const borderColor =
      state.isDisabled && isLightTheme ? LIGHT_THEME_COLORS.BORDER : "#cacaca";

    return { ...styles, backgroundColor, borderColor };
  },
  singleValue: (styles) => {
    const lineHeight = calcRem(20);

    return { ...styles, lineHeight };
  },
  multiValue: (styles) => ({
    ...styles,
    borderColor: "#394f7c",
    padding: "2px",
    borderRadius: "50px",
    color: COLORS.WHITE,
    lineHeight: calcRem(20),
  }),
  input: (styles, state) => {
    let isLightTheme = state.selectProps.className === "light-select";

    const color = state.isDisabled
      ? isLightTheme
        ? COLORS.BLACK
        : COLORS.WHITE
      : COLORS.BLACK;
    return {
      ...styles,
      color,
    };
  },
  option: (styles) => ({
    ...styles,
    ":active": {
      ...styles[":active"],
      backgroundColor: `${COLORS.SELECTED}30 !important`,
    },
  }),
  container: (styles) => ({ ...styles, width: "100%", color: COLORS.BLACK }),
  placeholder: (styles) => ({
    ...styles,
    color: "#abb5c5",
    fontSize: calcRem(14),
  }),
  dropdownIndicator: (styles) => ({ ...styles, color: COLORS.BUTTONS1 }),
  indicatorSeparator: () => ({}),
  clearIndicator: () => ({ display: "none" }),
};

const customStyles: StylesConfig<Option> = {
  control: (styles, state) => {
    let isLightTheme = state.selectProps.className === "light-select";
    const backgroundColor = state.isDisabled
      ? isLightTheme
        ? LIGHT_THEME_COLORS.LIGHTGRAY
        : COLORS.BUTTONS1
      : COLORS.WHITE;
    const borderColor =
      state.isDisabled && isLightTheme ? LIGHT_THEME_COLORS.BORDER : "#cacaca";

    const fontSize = calcRem(14);

    return { ...styles, backgroundColor, borderColor, fontSize };
  },
  singleValue: (styles) => {
    const lineHeight = calcRem(20);

    return { ...styles, lineHeight };
  },
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: COLORS.WHITE,
    padding: "2px",
    borderRadius: "50px",
    lineHeight: calcRem(20),
  }),
  input: (styles, state) => {
    let isLightTheme = state.selectProps.className === "light-select";

    const color = state.isDisabled
      ? isLightTheme
        ? COLORS.BLACK
        : COLORS.WHITE
      : COLORS.BLACK;
    return {
      ...styles,
      color,
    };
  },
  option: (styles) => ({
    ...styles,
    ":active": {
      ...styles[":active"],
      backgroundColor: `${COLORS.SELECTED}30 !important`,
    },
  }),
  container: (styles) => ({ ...styles, width: "100%", color: COLORS.BLACK }),
  placeholder: (styles) => ({
    ...styles,
    color: "#abb5c5",
    fontSize: calcRem(14),
  }),
  dropdownIndicator: (styles) => ({ ...styles, color: COLORS.BUTTONS1 }),
  indicatorSeparator: () => ({}),
};

const OptionComponent = (props: OptionProps<any, true>) => (
  <components.Option {...props}>
    <OptionWrapper>
      <Text fontSize={15} bold color={COLORS.BUTTONS1}>
        {props.data.label}
      </Text>
      <Text fontSize={14} color={COLORS.BUTTONS1}>
        {props.data.value}
      </Text>
    </OptionWrapper>
  </components.Option>
);

const MultiValueComponent = (props: MultiValueProps<any, true>) => {
  return (
    <components.MultiValue {...props}>
      <Text fontSize={13} bold={props.data.label} color={COLORS.PRIMARY1}>
        {props.data.label ? props.data.label : props.data.value}
      </Text>
    </components.MultiValue>
  );
};

const EmailAddSelect = (props: CommonProps) => {
  const intl = useIntl();
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const themeMode = useAppSelector((state) => state.theme.theme);
  const [inputValue, setInputValue] = useState<string>("");

  const [users, setUsers] = useState<UserEmailSelect[]>([]);
  const [userQueryInfo] = useState<BasePagination>({
    total: 0,
    pageNum: 0,
    pageLimit: 1000,
    keyword: "",
    sortType: "name",
    sortDirection: SORT_DIRECTION.ASC,
  });

  const { data, error, refetch, isLoading } = useQuery(
    ["user", userQueryInfo],
    () =>
      getUserTotalList({
        payload: userQueryInfo,
        accountId: selectedAccount.accountId,
      }),
    {
      retry: 0,
      enabled: props.value === undefined || props.isVs,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        let tempRes = res.result.map((item: any) => {
          return {
            userId: item.userId,
            value: item.email,
            label: item.name,
          };
        });

        setUsers(tempRes);
      },
      onError: (e: any) => {},
    }
  );

  //BUG RND-328 FIX
  //TODO onbllur 처리 필요.
  const handleBlur = useCallback(() => {
    const label = inputValue?.trim() || "";
    const optionExists = users.find((opt) => opt.label === label);

    if (!label || optionExists) {
      return;
    }

    const option = { label, value: label };

    // Add new option to menu list
    setUsers([...users, option]);
    // Add value to selected options
    if (props.onChange) {
      props.onChange([...(props.value || []), option]);
    }
    // Clear input value
    setInputValue("");
  }, [inputValue, props, users]);

  const onInputChange = (newValue: string, actionMeta: InputActionMeta) => {
    if (actionMeta.action === "input-change") {
      setInputValue(newValue);
    }
    if (actionMeta.action === "input-blur") {
      handleBlur();
    }
  };

  const isInputPreviouslyBlurred = useRef(false);

  const createOptionFromInputValue = () => {
    if (!inputValue) return;
    if (props.onChange) {
      props.onChange([
        ...(props.value || []),
        { label: inputValue, value: inputValue },
      ]);
    }
  };

  const onInputBlur = () => {
    console.log("blur");
    isInputPreviouslyBlurred.current = true;
    createOptionFromInputValue();
  };

  return props.isMulti === true ? (
    <CreatableSelect
      id={props.id}
      styles={props.isVs ? popStyles : customStyles}
      options={users}
      placeholder={intl.formatMessage({
        id: "label.select.email.placeholder",
        defaultMessage:
          "Click to select user or enter email address to share video",
      })}
      isMulti
      closeMenuOnSelect={true}
      onChange={props.onChange}
      components={{ Option: OptionComponent, MultiValue: MultiValueComponent }}
      className={themeMode === "light" ? "light-select" : "dark-select"}
      // menuIsOpen={true}
      {...props}
    />
  ) : (
    <CreatableSelect
      id={props.id}
      styles={props.isVs ? popStyles : customStyles}
      options={users}
      placeholder={intl.formatMessage({
        id: "label.comp.select.email.placeholder",
        defaultMessage:
          "Click to select user or enter email address to share video",
      })}
      closeMenuOnSelect={false}
      onChange={props.onChange}
      components={{ Option: OptionComponent }}
      className={themeMode === "light" ? "light-select" : "dark-select"}
      // menuIsOpen={true}
      {...props}
    />
  );
};

export default EmailAddSelect;

const OptionWrapper = styled.div`
  padding: 5px;
  ${FLEX.FlexStartStart};
  flex-direction: column;
  gap: 6px;
  cursor: pointer;

  &:hover {
    background-color: ${COLORS.SELECTED}10;
  }
`;
