import { useCallback, useState, useEffect } from "react";
import { AlertRulePresenter } from "./AlertRulePresenter";
import * as s from "./AlertRuleStyled";
import TableButton from "components/atoms/buttons/TableButton";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { notify } from "components/atoms/notification/Notification";
import { useAppSelector } from "redux/hooks";
import {
  LEVEL_TYPE,
  ProfileAccountInfo,
} from "api/interfaces/accountInterface.interface";
import {
  PostAlertRequest,
  AlertRuleListResponse,
  GetAlertListResponse,
} from "api/interfaces/alertInterface.interface";
import {
  SORT_DIRECTION,
  BasePagination,
  CustomError,
} from "api/interfaces/commonInterface.interface";
import { getAlertRuleList, postAlertRuleList } from "api/alertAPI";
import { useLocation, useNavigate } from "react-router-dom";
import RBACWrapper from "components/blocks/function/RBACWrapper";
import { getAccountTimeFormat } from "utils/timeUtil";
import { useAuth } from "components/Auth";
import { useIntl } from "react-intl";
import { isCheckPermission } from "utils/AccountUtil";
import useApiError from "hook/useApiError";
import { isNull } from "lodash";
import { queryNavigate } from "utils/MenuUtil";
import { TableColumnsType } from "antd";
import { ColumnType } from "antd/es/table";
import {
  changeSortOrderAntd,
  onSortAntd,
} from "components/atoms/table/AntdTable";

const initialQuery = {
  total: 0,
  pageNum: 0,
  pageLimit: 20,
  keyword: "",
  sortType: "createdAt",
  sortDirection: SORT_DIRECTION.DESC,
};
export function AlertRuleContainer(): JSX.Element {
  const navigate = useNavigate();
  const intl = useIntl();
  const auth = useAuth();
  const location: any = useLocation();
  const { handleError } = useApiError();

  const isListPage: boolean = location.pathname.endsWith("/rules");

  const [isAddModal, setIsAddModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [isViewModal, setIsViewModal] = useState(false);

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const queryClient = useQueryClient();

  const [alertRules, setAlertRules] = useState<{ totalEnable: number }>({
    totalEnable: 0,
  });
  const [alertList, setAlertList] = useState<AlertRuleListResponse[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [selectedRuleId, setSelectedRuleId] = useState<AlertRuleListResponse>();
  //status
  const status = {
    totalTitle: "Total",
    totalValue: alertRules.totalEnable,
    // items: [
    //   <Text fontSize={18} color={COLORS.BUTTONS1}>
    //     Enabled {alertRules?.result[0].enabled}
    //   </Text>,
    //   <Text fontSize={18} color={COLORS.BUTTONS1}>
    //     Disabled {alertRules?.result[0].disabled}
    //   </Text>,
    // ],
    isMobileShow: {
      totalTitle: true,
      totalValue: true,
      items: false,
    },
  };
  const [alertRuleQueryInfo, setAlertRuleQueryInfo] = useState<BasePagination>({
    ...initialQuery,
  });
  const [isRowClickEditable, setIsRowClickEditable] = useState<boolean>(
    isCheckPermission("manageAlertRules", selectedAccount)
  );
  const [pending, setPending] = useState<boolean>(true);

  useEffect(() => {
    const changeParam = new URLSearchParams(location.search);
    if (changeParam.size > 0) {
      setAlertRuleQueryInfo((info) => {
        return {
          ...info,
          pageNum: isNull(changeParam.get("pageNum"))
            ? 0
            : Number(changeParam.get("pageNum")),
          pageLimit: isNull(changeParam.get("pageLimit"))
            ? 20
            : Number(changeParam.get("pageLimit")),
          keyword: isNull(changeParam.get("keyword"))
            ? ""
            : (changeParam.get("keyword") as string),
          sortType: isNull(changeParam.get("sortType"))
            ? "createdAt"
            : (changeParam.get("sortType") as string),
          sortDirection: isNull(changeParam.get("sortDirection"))
            ? SORT_DIRECTION.DESC
            : (changeParam.get("sortDirection") as SORT_DIRECTION),
        };
      });
    } else {
      setAlertRuleQueryInfo((info) => {
        return {
          ...initialQuery,
        };
      });
    }
  }, [location]);

  useEffect(() => {
    if (selectedAccount) {
      setIsRowClickEditable(
        isCheckPermission("manageAlertRules", selectedAccount)
      );
    }
  }, [selectedAccount]);

  const displayCount = (
    autoApply: boolean,
    nameArray: string[],
    total: number,
    count: number
  ) => {
    if (autoApply) {
      if (total === count) {
        return "ALL";
      } else {
        if (nameArray !== undefined) {
          if (nameArray.length > 1) {
            return `${nameArray[0]}+${nameArray.length - 1}`;
          } else {
            return nameArray[0];
          }
        } else {
          return "ALL";
        }
      }
    } else {
      if (nameArray !== undefined) {
        if (nameArray.length > 1) {
          return `${nameArray[0]}+${nameArray.length - 1}`;
        } else {
          return nameArray[0];
        }
      } else {
        return "-";
      }
    }
  };
  const onHeaderCell = (column: ColumnType<AlertRuleListResponse>) => {
    return {
      onClick: () => {
        if (column.key) {
          setAlertRuleQueryInfo((info) => {
            return {
              ...info,
              sortType: column.key as string,
              sortDirection: onSortAntd(info.sortDirection),
            };
          });
        }
      },
    };
  };

  // alert rules
  const columns: TableColumnsType<AlertRuleListResponse> =
    selectedAccount.accountLevel !== "EU"
      ? [
          {
            title: "Title",
            render: (render, row) => row.title,
            sorter: true,
            key: "name",
            onHeaderCell: onHeaderCell,
            sortOrder: changeSortOrderAntd(alertRuleQueryInfo, "name"),
          },
          {
            title: "Organization",
            render: (render, row) =>
              row.autoApply !== undefined && row.autoApply ? `ALL` : row.count,
            align: "center",
            width: 120,
          },
          {
            title: "Created By",
            sorter: true,
            key: "createdBy",
            render: (render, row) => (row.createdBy ? row.createdBy : "System"),
            responsive: ["lg"],
            onHeaderCell: onHeaderCell,
            sortOrder: changeSortOrderAntd(alertRuleQueryInfo, "createdBy"),
          },

          {
            key: "createdAt",
            title: "Date Created",
            render: (render, row) =>
              row.createdAt !== undefined
                ? getAccountTimeFormat(row.createdAt, selectedAccount, true)
                : "",
            responsive: ["md"],
            sorter: true,
            sortOrder: changeSortOrderAntd(alertRuleQueryInfo, "createdAt"),
            onHeaderCell: onHeaderCell,
            defaultSortOrder: "descend",
            width: 200,
          },
          {
            title: "Description",
            render: (render, row) => row.description,
            responsive: ["xl"],
          },

          {
            title: "",
            render: (render, row) => (
              <s.Row className="table-button-wrapper">
                {selectedAccount.accountLevel !== "DW" ? (
                  <RBACWrapper requiredPermissions={"manageAlertRules"}>
                    <TableButton
                      label={"Edit"}
                      onClick={() => onClickEdit(row)}
                    />
                  </RBACWrapper>
                ) : null}
              </s.Row>
            ),
            align: "right",
            width: 100,
          },
        ]
      : [
          {
            title: "Title",
            sorter: true,
            key: "name",
            render: (render, row) => row.title,
            sortOrder: changeSortOrderAntd(alertRuleQueryInfo, "name"),
            onHeaderCell: onHeaderCell,
          },
          {
            title: "Recorder",
            render: (render, row) =>
              displayCount(
                row.autoApply,
                row.recorders,
                row.totalRecorderCount,
                row.count
              ),
          },
          {
            title: "Created By",
            sorter: true,
            key: "createdBy",
            render: (render, row) => (row.createdBy ? row.createdBy : "System"),
            responsive: ["lg"],
            sortOrder: changeSortOrderAntd(alertRuleQueryInfo, "createdBy"),
            onHeaderCell: onHeaderCell,
          },

          {
            title: "Date Created",
            sorter: true,
            key: "createdAt",
            render: (render, row) =>
              row.createdAt !== undefined
                ? getAccountTimeFormat(row.createdAt, selectedAccount, true)
                : "",
            responsive: ["md"],
            sortOrder: changeSortOrderAntd(alertRuleQueryInfo, "createdAt"),
            onHeaderCell: onHeaderCell,
            defaultSortOrder: "descend",
            width: 200,
          },
          {
            title: "Description",
            render: (render, row) => row.description,
            responsive: ["xl"],
          },

          {
            title: "",
            render: (render, row) => (
              <s.Row className="table-button-wrapper">
                <RBACWrapper requiredPermissions={"manageAlertRules"}>
                  <TableButton
                    label={"Edit"}
                    onClick={() => onClickEdit(row)}
                  />
                </RBACWrapper>
              </s.Row>
            ),
            align: "right",
            width: 100,
          },
        ];

  const onRowClick = (row: AlertRuleListResponse) => {
    // if (checkDisabled(row)) return;
    if (row?.alertRuleId !== undefined) {
      onClickEdit(row);
    }
    // navigate(`/alert/rules/detail`, {
    //   state: {
    //     alertRuleId: row.alertRuleId,
    //     title: row.title,
    //   },
    // });
  };

  const onModal = (type?: string) => {
    if (type === "add") {
      if (isAddModal) {
        queryClient.invalidateQueries("alertRule");
      }
      setIsAddModal(!isAddModal);
    }
    if (type === "edit") {
      if (isEditModal) {
        queryClient.invalidateQueries("alertRule");
      }
      setIsEditModal(!isEditModal);
    }
    if (type === "view") {
      setIsViewModal(!isViewModal);
    }
  };

  const onClickEdit = (row: AlertRuleListResponse) => {
    if (isCheckPermission("manageAlertRules", selectedAccount)) {
      setSelectedRuleId(row);
      onModal("edit");
    }
  };

  const mutationCreateAlert = useMutation(postAlertRuleList, {
    onSuccess: () => {
      setIsAddModal(false);
      queryClient.invalidateQueries("alertRule");
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.alertRule.notify.create.fail",
          defaultMessage: "Alert Rule create failed",
        })
      );
    },
  });

  const onClickCreate = useCallback(
    (alertInfo: PostAlertRequest) => {
      //setIsAddModal(true);
      console.log("alertInfo", alertInfo);
      mutationCreateAlert.mutate({
        payload: alertInfo,
        accountId: selectedAccount.accountId,
      });
    },
    [mutationCreateAlert, selectedAccount.accountId]
  );

  const { error, refetch } = useQuery(
    ["alertRule", alertRuleQueryInfo],
    () =>
      getAlertRuleList({
        accountId: selectedAccount.accountId,
        payload: alertRuleQueryInfo,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      enabled: isListPage,
      onSuccess: (res: GetAlertListResponse) => {
        if (res.error !== 0 && res.result === undefined) {
          setAlertRules({ totalEnable: 0 });
          setAlertList([]);
          setTotalCount(0);
          setAlertRuleQueryInfo({ ...initialQuery });
        } else {
          setAlertRules({ totalEnable: res.page.total });
          setAlertList(res.result);
          // setTotalCount(res.page.total);
          setAlertRuleQueryInfo((info) => {
            return { ...info, ...res.page };
          });
        }
      },
      onError: (err: any) => {
        notify(
          "error",
          intl.formatMessage({
            id: "label.alert.notify.list.error",
            defaultMessage: "Alert search error.",
          })
        );
        setAlertRuleQueryInfo({ ...initialQuery });
      },
      onSettled: (e: any) => {
        setPending(false);
      },
    }
  );

  const onClickSearch = useCallback(
    (keyword: string) => {
      if (alertRuleQueryInfo.keyword !== keyword) {
        queryNavigate(
          navigate,
          location.pathname,
          {
            pageLimit: alertRuleQueryInfo.pageLimit,
            pageNum: 0,
            keyword: keyword,
            sortType: alertRuleQueryInfo.sortType,
            sortDirection: alertRuleQueryInfo.sortDirection as SORT_DIRECTION,
          },
          selectedAccount
        );
      } else {
        refetch();
      }
      setPending(true);
      // setAlertRuleQueryInfo((query) => {
      //   return {
      //     ...query,
      //     keyword,
      //   };
      // });
      // refetch();
    },
    [
      alertRuleQueryInfo.keyword,
      alertRuleQueryInfo.pageLimit,
      alertRuleQueryInfo.sortDirection,
      alertRuleQueryInfo.sortType,
      location.pathname,
      navigate,
      refetch,
      selectedAccount,
    ]
  );

  const onChangePage = useCallback(
    (page: number, totalRows: number) => {
      if (page !== alertRuleQueryInfo.pageNum + 1) {
        queryNavigate(
          navigate,
          location.pathname,
          {
            pageLimit: alertRuleQueryInfo.pageLimit,
            pageNum: page - 1,
            keyword: alertRuleQueryInfo.keyword as string,
            sortType: alertRuleQueryInfo.sortType,
            sortDirection: alertRuleQueryInfo.sortDirection as SORT_DIRECTION,
          },
          selectedAccount
        );
        setPending(true);
        // setAlertRuleQueryInfo((info) => {
        //   return { ...info, pageNum: (page - 1) as number };
        // });
      }
    },
    [
      alertRuleQueryInfo.keyword,
      alertRuleQueryInfo.pageLimit,
      alertRuleQueryInfo.pageNum,
      alertRuleQueryInfo.sortDirection,
      alertRuleQueryInfo.sortType,
      location.pathname,
      navigate,
      selectedAccount,
    ]
  );

  return (
    <AlertRulePresenter
      columns={columns}
      onModal={onModal}
      isAddModal={isAddModal}
      isEditModal={isEditModal}
      isViewModal={isViewModal}
      status={status}
      onClickCreate={onClickCreate}
      data={alertList}
      onRowClick={onRowClick}
      paginationInfo={alertRuleQueryInfo}
      onChangePage={onChangePage}
      paginationTotalRows={totalCount}
      onClickSearch={onClickSearch}
      selectedAccount={selectedAccount}
      selectedRule={selectedRuleId}
      isRowClickEditable={isRowClickEditable}
      tablePending={pending}
    />
  );
}
