import { useMediaQuery } from "react-responsive";

const plus = 50;

export const hd4K = 3000;
export const desktop = 992;
export const tabletMax = 991 - plus;
export const tabletMin = 768;
export const mobileMax = 769;
export const mobileMin = 576;

type Props = {
  children?: any;
};

export const Desktop = ({ children }: Props) => {
  const isDesktop = useMediaQuery({ minWidth: desktop });
  return isDesktop ? children : null;
};
export const Tablet = ({ children }: Props) => {
  const isTablet = useMediaQuery({ minWidth: tabletMin, maxWidth: tabletMax });
  return isTablet ? children : null;
};
export const Mobile = ({ children }: Props) => {
  const isMobile = useMediaQuery({ maxWidth: mobileMax });
  return isMobile ? children : null;
};
export const Default = ({ children }: Props) => {
  const isNotMobile = useMediaQuery({ minWidth: tabletMin });
  return isNotMobile ? children : null;
};

export const is4K = `@media(min-width: ${hd4K}px)`;
export const isDesktop = `@media(min-width: ${desktop}px)`;
export const isTablet = `@media(max-width: ${tabletMax}px)`;
export const isMobile = `@media(max-width: ${tabletMin}px)`;
export const isDefault = `@media(min-width: ${tabletMin}px)`;

export const useIsMobile = () => {
  return useMediaQuery({ maxWidth: mobileMax - 1 });
};

export const useIsDefault = () => {
  return useMediaQuery({ minWidth: tabletMin });
};
