import React, { useState } from "react";
import { Table } from "antd";
import type { RowProps, TableProps } from "antd";
import styled from "styled-components";
import { calcRem } from "styles/theme";

import { COLORS, LIGHT_THEME_COLORS } from "styles/colors";
import * as mediaQuery from "components/MediaQuery";

export const PaymentTable = (props: TableProps<any>): JSX.Element => {
  return (
    <Wrapper>
      <Table
        showSorterTooltip={false}
        pagination={false}
        bordered={false}
        locale={{
          emptyText: "",
        }}
        {...(props as TableProps<any>)}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  .ant-table {
    font-size: ${calcRem(12)};
    line-height: ${calcRem(15)};
    color: ${({ theme }) => theme.COLORS.WHITE};
  }
  .ant-table-thead th {
    font-size: ${calcRem(15)};
    padding: 10px 8px !important;
    color: ${({ theme }) => theme.COLORS.WHITE} !important;
    border: none !important;
  }

  .ant-table-cell {
    padding: 5px 8px !important;
    border: none !important;
  }
  thead {
    background: ${({ theme }) => theme.COLORS.PRIMARY3};
  }
  tbody {
    width: 100%;
    tr {
      :nth-child(odd) {
        background-color: ${({ theme }) =>
          theme.value === "light" ? `#fcfcfc` : `${COLORS.PRIMARY3}50`};
      }
    }
  }
`;
