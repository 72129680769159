import * as s from "../ModalTemplateStyled";
import * as mediaQuery from "components/MediaQuery";

import { Text } from "components/atoms/text/Text";
import { COLORS } from "styles/colors";
import SmallPopupDefaultTemplate from "./SmallPopupDefaultTemplate";
import PrimaryButton from "components/atoms/buttons/PrimaryButton";
import { useAppSelector } from "redux/hooks";
import { forwardRef } from "react";

type Props = {
  title: string;
  titleIcon?: JSX.Element;
  subTitle?: string;
  children: React.ReactNode;
  onModal?: () => void;
  onClick: () => void;
  deactivate?: boolean;
  disableClose?: boolean;
  cancel?: boolean;
  confirmLabel?: string;
  buttons?: React.ReactNode;
};

const SmallPopupTemplate = forwardRef<HTMLDivElement, Props>(
  (
    {
      title,
      titleIcon,
      subTitle,
      children,
      onModal,
      onClick,
      deactivate,
      disableClose,
      cancel,
      confirmLabel,
      buttons,
    }: Props,
    ref
  ): JSX.Element => {
    const theme = useAppSelector((state) => state.theme.theme);
    const buttonsComponent = buttons ? (
      buttons
    ) : deactivate || cancel ? (
      <s.ButtonWrapper>
        <PrimaryButton
          buttonType="cancel"
          label="Cancel"
          onClickBtn={onModal as () => void}
        />
        <PrimaryButton
          label={confirmLabel ? confirmLabel : "OK"}
          onClickBtn={onClick}
        />
      </s.ButtonWrapper>
    ) : (
      <PrimaryButton
        label={confirmLabel ? confirmLabel : "Next"}
        onClickBtn={onClick}
      />
    );
    return (
      <SmallPopupDefaultTemplate
        disableClose={disableClose}
        button={buttonsComponent}
        onModal={onModal}
        ref={ref}
      >
        {deactivate ? (
          <s.SmallDeactivateTitle>
            {titleIcon && titleIcon}
            {title}
            <Text color={COLORS.COMPANY} fontSize={14}>
              {subTitle}
            </Text>
          </s.SmallDeactivateTitle>
        ) : (
          <s.SmallTitleWrapper>
            <Text fontSize={22} bold>
              {titleIcon && titleIcon}
              {title}
            </Text>
            {subTitle && (
              <Text
                color={
                  theme === "light"
                    ? `${COLORS.BLACK}70`
                    : "rgba(255,255,255,0.41)"
                }
                fontSize={14}
              >
                {subTitle}
              </Text>
            )}
          </s.SmallTitleWrapper>
        )}

        <s.SmallContents>{children}</s.SmallContents>
      </SmallPopupDefaultTemplate>
    );
  }
);

export default SmallPopupTemplate;
