import styled from "styled-components";
import * as mediaQuery from "components/MediaQuery";

export const SearchFilterContainer = styled.div`
  padding-top: 24px;
  margin-bottom: 36px;
`;

export const SearchFilterRow = styled.div`
  display: grid;
  grid-template:
    "organization useremail menu start end timezone submit" / 2fr 2fr 2fr minmax(230px, 2fr) minmax(230px, 2fr) 3fr 1fr ;

  margin-bottom: 12px;
  column-gap: 8px;

  .organizationid {
    grid-area: organization;
  }
  .useremail {
    grid-area: useremail;
  }

  .menu {
    grid-area: menu;
  }

  .timezone {
    grid-area: timezone;
  }
  .start {
    grid-area: start;
  }

  .end {
    grid-area: end;
  }

  .submit {
    grid-area: submit;
  }


  @media(max-width: 1380px) {
    grid-template:
      "organization useremail menu timezone ."
      "start start end end submit" / 1fr 1fr 1fr 1fr minmax(100px, 1fr);
    row-gap: 8px;
  }

  ${mediaQuery.isMobile} {
    grid-template:
      "organization useremail"
      "menu timezone"
      "start start"
      "end end"
      "submit submit";
  }
`;


export const Label = styled.p`
  padding: 12px 0 8px;
  font-size: 14px;
  color: ${({ theme }) => theme.COLORS.TEXT};
`;

export const FilterItem = styled.div`

`;

export const FilterItemSearchBtn = styled(FilterItem)`
  display: flex;
  align-items: flex-end;
  padding-bottom: 1px;

  ${mediaQuery.isMobile} {
    padding-top: 20px;
    > button {
      width: 100%; 
      height: 40px;
    }
  }
`;
