import InnerContainer from "components/atoms/inner/InnerContainer";
import styled from "styled-components";
import { COLORS, LEVELS } from "../../../styles/colors";
import { FLEX } from "../../../styles/flex";
import { calcRem } from "styles/theme";
import * as mediaQuery from "components/MediaQuery";

export const HEADER_HEIGHT = 50;
export const HeaderContainer = styled.header<{ level?: string }>`
  z-index: 890;
  width: 100%;
  height: ${HEADER_HEIGHT}px;
  background-color: ${(props) =>
    props.level ? LEVELS[props.level] : props.theme.COLORS.HEADERBG};
  background-color: ${(props) =>
    props.level === "EU" && props.theme.COLORS.HEADERBG};
  position: sticky;
  top: 0;
  position: 900;

  ${(props) =>
    props.theme.value === "light" &&
    `
  border-bottom: 1px solid ${props.theme.COLORS.LIGHTGRAYBORDER};
  
  `}

  ${mediaQuery.isMobile} {
    ${FLEX.FlexCenterCenter};
    flex: 1;
    z-index: 955;
  }

  .sidebar-collapse-button {
    color: ${(props) =>
      props.level === "EU" ? props.theme.COLORS.WHITE : COLORS.WHITE};
    ${FLEX.FlexStartCenter};
  }
`;

export const Inner = styled(InnerContainer)`
  height: 100%;
  ${mediaQuery.isDefault} {
    ${FLEX.FlexBetweenCenter};
    overflow-y: unset;
  }
  ${mediaQuery.isMobile} {
    ${FLEX.FlexStartCenter};
    gap: 14px;
    > button {
      > img {
        height: 15px;
      }
    }
  }
`;

export const LeftWrapper = styled.div`
  height: 100%;
  ${mediaQuery.isDefault} {
    ${FLEX.FlexStartCenter};
    gap: 80px;
  }

  ${mediaQuery.isMobile} {
    width: calc(100% - 44px);
    flex: 1;
    ${FLEX.FlexStartCenter};
    gap: 20px;
    position: relative;

    .left-slide-button {
      left: -5px;
      z-index: 99;
    }
    .right-slide-button {
      right: 0;
    }
  }
`;

export const Title = styled.h2<{ level?: string }>`
  color: ${({ theme }) => COLORS.WHITE};
  color: ${(props) => props.level === "EU" && props.theme.COLORS.WHITE};
  /* color: ${(props) => props.theme.COLORS.WHITE}; */
  font-weight: 500;
  font-size: ${calcRem(24)};
  cursor: pointer;
  white-space: nowrap;
`;

export const MenuContainer = styled.nav`
  gap: 20px;
  height: 100%;
  ${FLEX.FlexStartCenter};

  ${mediaQuery.isMobile} {
    padding-right: 20px;
    overflow-x: auto;
    box-sizing: border-box;
    position: relative;

    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const MenuItem = styled.button<{ isCurrent: boolean; level?: string }>`
  height: 100%;
  font-size: ${calcRem(15)};
  ${(props) =>
    props.isCurrent
      ? `
        border-bottom: 6px solid ${COLORS.WHITE};
        color: ${COLORS.WHITE};
        color: ${props.level === "EU" && props.theme.COLORS.WHITE};
        border-bottom: 6px solid${
          props.level === "EU" && props.theme.COLORS.WHITE
        };;
  `
      : `
        border-bottom: 6px solid transparent;
        color: ${COLORS.WHITE}90;
        color: ${props.level === "EU" && props.theme.COLORS.WHITE}90;
        // color: #4F5972;
      `}
`;

export const MapButton = styled.div`
  ${mediaQuery.isDefault} {
    margin-left: 20px;
  }
  button {
    padding: 10px;
  }
  button > span {
    font-size: ${calcRem(14)};
  }
`;

export const SlideButton = styled.button`
  position: absolute;

  /* cursor: pointer; */
`;

export const RightContainer = styled.div`
  ${FLEX.FlexEndCenter};
  gap: 20px;
`;
