import Input from "components/atoms/input/Input";
import * as s from "./../LoginStyled";
import * as cs from "../../SignCommonStyled";
import MYDW_ICON from "assets/icons/myDW.svg";
import { COLORS } from "styles/colors";
import { UnderlineText, ValidateWord } from "components/atoms/text/Text";
import { LOGIN_STEP, Validate, ValidateMsg } from "../LoginContainer";

interface Props {

  isValidated: Validate;
  validateMsg: ValidateMsg;
  onChangeFreeTrialInfo: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmitFreeTrial: () => void;
  onClickStepChange: (step: LOGIN_STEP) => void;
}

export default function FreeTrial(props: Props) {
  const { isValidated, validateMsg, onChangeFreeTrialInfo, onSubmitFreeTrial, onClickStepChange } = props;
  
  return (
    <>
      <s.InputWrapper>
        <button onClick={() => window.location.reload()}>
          <img
            src={MYDW_ICON}
            width="120"
            alt="MyDW Icon"
            className="mydw-logo"
          />
        </button>
        <p className="description">
          Digital Watchdog will send you an invitation email. Once you receive the email, approve it to join myDW. Then you can use myDW services for up to 90 days.
        </p><br />
        <Input
          type="new-email"
          name="email"
          placeholder="Enter your email"
          onChange={onChangeFreeTrialInfo}
          isValidated={isValidated.freeTrialEmail}
          autoComplete="off"
        />
        <cs.ValidateWrapper>
          <ValidateWord>{validateMsg.freeTrialEmail}</ValidateWord>
        </cs.ValidateWrapper>
        <Input
          type="text"
          name="organizationName"
          placeholder="Enter your desired organization name"
          onChange={onChangeFreeTrialInfo}
          isValidated={isValidated.freeTrialOrganizationName}
          autoComplete="off"
        />
        <cs.ValidateWrapper>
          <ValidateWord>{validateMsg.freeTrialOrganizationName}</ValidateWord>
        </cs.ValidateWrapper>
        <cs.BigButton
          onClick={onSubmitFreeTrial}
        >
          Send
        </cs.BigButton>
        <cs.Bottom>
          <UnderlineText
            fontSize={14}
            color={COLORS.BUTTONS1}
            onClick={() =>
              onClickStepChange(LOGIN_STEP.INPUT_EMAIL)
            }
          >
            Go Back
          </UnderlineText>
        </cs.Bottom>
      </s.InputWrapper>
    </>
  )
}