import { DashboardData } from "api/interfaces/dashboardInterface.interface";
import { FaCircle } from "react-icons/fa";
import { MAP_STATUS_COLORS } from "styles/colors";
import { getTopAlertStatusString } from "../../utils";
import { AssigneeInfo } from "./listInfoItem/AssigneeInfo";

type ChildrenRecorderType = {
  rec: DashboardData;
  onSelectRecorder: (system: DashboardData) => void;
}

export function ChildrenRecorder({ rec, onSelectRecorder }: ChildrenRecorderType): JSX.Element {
  const tmpStatus2 = getTopAlertStatusString(rec);
  return (
    <li
      className="recorder-item"
      onClick={() => onSelectRecorder(rec)}
      key={`recorder-item-${rec.recorderId}`}
    >
      <div className="recorder-item-name">
        <FaCircle size={8} color={MAP_STATUS_COLORS[tmpStatus2]} />
        <span>{rec.name}</span>
      </div>
      <div className="recorder-item-assignee">
        <AssigneeInfo recorder={rec} />
      </div>
    </li>
  );
}