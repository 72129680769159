import { getAlertHistoryList } from "api/alertAPI";
import {
  AlertDetail,
  AlertHistoryList,
} from "api/interfaces/alertInterface.interface";
import { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import {
  BasePagination,
  LEVEL_TYPE,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import { UnderlineText } from "components/atoms/text/Text";
import {
  createSearchParams,
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import TableButton from "components/atoms/buttons/TableButton";
import * as s from "../AlertStyled";
import { COLORS } from "styles/colors";
import TableColumnFilterTitle from "components/blocks/table/TableColumnFilterTitle";
import Type from "components/atoms/text/labels/Type";
import * as mediaQuery from "components/MediaQuery";
import { notify } from "components/atoms/notification/Notification";
import { getRecorderTimeFormat } from "utils/timeUtil";
import { AlertRecorerTypeFilter } from "utils/AlertUtil";
import { convertType } from "utils/functions";
import { useIntl } from "react-intl";
import { isNull } from "lodash";
import { queryNavigate } from "utils/MenuUtil";
import RecorderTitle from "components/blocks/recorder/RecorderTitle";
import { TableColumnsType } from "antd";
import {
  changeSortOrderAntd,
  CommonTable,
  onSortAntd,
} from "components/atoms/table/AntdTable";
import { ColumnType } from "antd/es/table";
import AccountId from "components/atoms/text/labels/AccountId";

const initialQueryRuleInfo = {
  total: 0,
  pageNum: 0,
  pageLimit: 20,
  keyword: "",
  sortType: "mergedSystemName",
  sortDirection: SORT_DIRECTION.ASC,
  type: null,
};

export default function AlertHistoryListComponent(): JSX.Element {
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const { keyword, setAlertDetail, setAlertTotal, setTitleComponent } =
    useOutletContext<any>();

  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const themeMode = useAppSelector((state) => state.theme.theme);

  const [isPending, setIsPending] = useState<boolean>(true);

  useEffect(() => {
    const changeParam = new URLSearchParams(location.search);
    if (changeParam.size > 0) {
      setAlertHistoryRuleQueryInfo((info) => {
        return {
          ...info,
          pageNum: isNull(changeParam.get("pageNum"))
            ? 0
            : Number(changeParam.get("pageNum")),
          pageLimit: isNull(changeParam.get("pageLimit"))
            ? 20
            : Number(changeParam.get("pageLimit")),
          keyword: isNull(changeParam.get("keyword"))
            ? ""
            : (changeParam.get("keyword") as string),
          sortType: isNull(changeParam.get("sortType"))
            ? "mergedSystemName"
            : (changeParam.get("sortType") as string),
          sortDirection: isNull(changeParam.get("sortDirection"))
            ? SORT_DIRECTION.ASC
            : (changeParam.get("sortDirection") as SORT_DIRECTION),
          type: isNull(changeParam.get("type"))
            ? null
            : (changeParam.get("type") as string),
        };
      });
    } else {
      setAlertHistoryRuleQueryInfo((info) => {
        return {
          ...info,
          pageNum: 0,
          pageLimit: 20,
          keyword: "",
          sortType: "mergedSystemName",
          sortDirection: SORT_DIRECTION.ASC,
          type: null,
        };
      });
    }
  }, [location]);

  const [data, setData] = useState<AlertHistoryList[]>([]);

  useEffect(() => {
    setTitleComponent("Alert History");
  }, []);

  const [alertHistoryRuleQueryInfo, setAlertHistoryRuleQueryInfo] =
    useState<BasePagination>({
      ...initialQueryRuleInfo,
    });

  const onClickAlertRecorderType = useCallback(
    (value: string | undefined) => {
      if (value !== undefined) {
        if (value === "all") {
          queryNavigate(
            navigate,
            location.pathname,
            {
              pageLimit: alertHistoryRuleQueryInfo.pageLimit,
              pageNum: 0,
              keyword: alertHistoryRuleQueryInfo.keyword as string,
              sortType: alertHistoryRuleQueryInfo.sortType,
              sortDirection:
                alertHistoryRuleQueryInfo.sortDirection as SORT_DIRECTION,
              type: null,
            },
            selectedAccount
          );
          // setAlertHistoryRuleQueryInfo((info) => {
          //   return { ...info, type: null, pageNum: 0 };
          // });
        } else {
          queryNavigate(
            navigate,
            location.pathname,
            {
              pageLimit: alertHistoryRuleQueryInfo.pageLimit,
              pageNum: 0,
              keyword: alertHistoryRuleQueryInfo.keyword as string,
              sortType: alertHistoryRuleQueryInfo.sortType,
              sortDirection:
                alertHistoryRuleQueryInfo.sortDirection as SORT_DIRECTION,
              type: value,
            },
            selectedAccount
          );
          // setAlertHistoryRuleQueryInfo((info) => {
          //   return { ...info, type: value as string, pageNum: 0 };
          // });
        }
      }
    },
    [
      alertHistoryRuleQueryInfo.keyword,
      alertHistoryRuleQueryInfo.pageLimit,
      alertHistoryRuleQueryInfo.sortDirection,
      alertHistoryRuleQueryInfo.sortType,
      location.pathname,
      navigate,
      selectedAccount,
    ]
  );
  const onHeaderCell = (column: ColumnType<AlertHistoryList>) => {
    return {
      onClick: () => {
        if (column.key) {
          setAlertHistoryRuleQueryInfo((info) => {
            return {
              ...info,
              sortType: column.key as string,
              sortDirection: onSortAntd(info.sortDirection),
            };
          });
        }
      },
    };
  };

  // alert history
  const columns: TableColumnsType<AlertHistoryList> = [
    {
      title: (
        <TableColumnFilterTitle
          title="Type"
          filters={AlertRecorerTypeFilter}
          onClick={onClickAlertRecorderType}
        />
      ),
      render: (value, row) => <Type type={convertType(row)} />,
      width: 110,
    },
    {
      title: "Organization",
      render: (value, row) => (
        <s.AccountBox>
          {row.accountName}
          <AccountId accountId={row.accountNumber} level={LEVEL_TYPE.EU} />
        </s.AccountBox>
      ),
      width: mediaQuery.useIsMobile() ? "30%" : 200,
      hidden: selectedAccount.accountLevel === LEVEL_TYPE.EU,
    },
    {
      key: "mergedSystemName",
      title: "Recorder Title",
      render: (row) => (
        <RecorderTitle
          mergedSystemName={row.name}
          recorderName={row.recorderName}
        />
      ),
      //row.type === "Spectrum" ? row.name : row.recorderName,
      sorter: true,
      sortOrder: changeSortOrderAntd(
        alertHistoryRuleQueryInfo,
        "mergedSystemName"
      ),
      onHeaderCell: onHeaderCell,
      defaultSortOrder: "ascend",
      ellipsis: true,
    },
    {
      title: "",
      render: (row) => (
        <s.Row>
          {row.recorderCount > 1 ? (
            <UnderlineText
              color={themeMode === "light" ? COLORS.BUTTONS2 : COLORS.SELECTED}
              fontSize={14}
              onClick={() => onRowClick(row)}
            >
              Recorders
              <mediaQuery.Default>: {row.recorderCount}</mediaQuery.Default>
            </UnderlineText>
          ) : (
            ""
          )}
        </s.Row>
      ),
      align: "right",
      responsive: ["md"],
      width: 90,
    },
    {
      title: "System",
      render: (row) => row.systemCount || 0,
      align: "center",
      responsive: ["xl"],
      width: 100,
    },
    {
      title: "Disk",
      render: (row) => row.diskCount || 0,
      align: "center",
      responsive: ["xl"],
      width: 100,
    },
    {
      title: "Video",
      render: (row) => row.videoCount || 0,
      align: "center",
      responsive: ["xl"],
      width: 100,
    },
    {
      title: "Last Alert",
      render: (row) =>
        row.date !== undefined
          ? getRecorderTimeFormat(row.date, selectedAccount, row.timezone, true)
          : "",
      responsive: ["md"],
      width: 200,
    },
    // 마지막 버튼
    {
      title: "",
      render: (row) => (
        <span className="row-click-button table-button-wrapper">
          {row.recorderCount === 1 ? (
            <TableButton
              label="View"
              onClick={() => onRowClick(row)}
              // disabled={
              //   row.systemCount + row.diskCount + row.videoCount === 0
              //     ? true
              //     : false
              // }
            />
          ) : null}
        </span>
      ),
      align: "right",
      width: mediaQuery.useIsMobile() ? 30 : 100,
    },
  ];

  const onClickMerged = (row: AlertHistoryList) => {
    navigate({
      pathname: `/alert/history/merged/${row.mergedSystemId}`,
      search: `?${createSearchParams({
        account: selectedAccount.accountId,
      })}`,
    });
  };

  const onRowClick = (row: AlertHistoryList) => {
    if (row.recorderCount !== undefined && row.recorderCount !== 1) {
      onClickMerged(row);
    } else {
      navigate({
        pathname: `/alert/history/detail`,
        search: `?${createSearchParams({
          recorderId: row.recorderId,
          account: selectedAccount.accountId,
        })}`,
      });
    }
  };

  const alertHistoryQuery = useQuery(
    ["alertHistoryList", alertHistoryRuleQueryInfo, selectedAccount.accountId],
    () =>
      getAlertHistoryList({
        accountId: selectedAccount.accountId,
        payload: alertHistoryRuleQueryInfo,
      }),
    {
      retry: 0,
      enabled: selectedAccount.accountId !== "",
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res.error !== 0 && res.result === undefined) {
          setData([]);
          setAlertTotal(0);
          setAlertDetail({
            system: 0,
            disk: 0,
            video: 0,
          });
          setAlertHistoryRuleQueryInfo({
            ...initialQueryRuleInfo,
          });
        } else {
          setAlertTotal(res.result.total);
          setAlertDetail({
            system: res.result.systemCount || 0,
            disk: res.result.diskCount || 0,
            video: res.result.videoCount || 0,
          });
          setData(res.result.alertRule);
          setAlertHistoryRuleQueryInfo((info) => {
            return {
              ...info,
              ...res.page,
            };
          });
        }
      },
      onError: (e: any) => {
        setData([]);
        setAlertTotal(0);
        setAlertDetail({
          system: 0,
          disk: 0,
          video: 0,
        });
        setAlertHistoryRuleQueryInfo({
          ...initialQueryRuleInfo,
        });
        if (e.response?.data !== undefined) {
          if (e.response?.data.error !== 5001) {
            notify(
              "error",
              intl.formatMessage({
                id: "label.alertHistory.notify.search.fail",
                defaultMessage: "Alert history search error.",
              })
            );
          }
        }
      },
      onSettled: (e: any) => {
        setIsPending(false);
      },
    }
  );

  const onChangePage = (page: number, totalRows: number) => {
    if (page !== alertHistoryRuleQueryInfo.pageNum + 1) {
      queryNavigate(
        navigate,
        location.pathname,
        {
          pageLimit: alertHistoryRuleQueryInfo.pageLimit,
          pageNum: page - 1,
          keyword: alertHistoryRuleQueryInfo.keyword as string,
          sortType: alertHistoryRuleQueryInfo.sortType,
          sortDirection:
            alertHistoryRuleQueryInfo.sortDirection as SORT_DIRECTION,
        },
        selectedAccount
      );
      setIsPending(true);
    }
  };

  useEffect(() => {
    setAlertHistoryRuleQueryInfo((query) => {
      return {
        ...query,
        keyword,
      };
    });
  }, [keyword]);

  return (
    <CommonTable
      columns={columns}
      dataSource={data}
      queryInfo={alertHistoryRuleQueryInfo}
      onChangePage={onChangePage}
      onRow={(record, rowIndex) => {
        return {
          onClick: (event) => {
            onRowClick(record);
          },
        };
      }}
      loading={isPending}
    />
  );
}
