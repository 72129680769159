import * as s from "../SignupStyled";
import * as cs from "../../SignCommonStyled";
import { UserInfo, Validate } from "../SignupContainer";
import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import Input from "components/atoms/input/Input";
import { Text, UnderlineText, ValidateWord } from "components/atoms/text/Text";
import PhoneInput from "components/atoms/input/phone/PhoneInput";
import { checkPasswordNumberRegEx, checkPasswordRegEx } from "utils/regEx";
import PasswordBubble from "components/blocks/bubble/PasswordBubble";
import { isEmpty } from "lodash";
import TableButton from "components/atoms/buttons/TableButton";
import ToggleComponent from "components/atoms/toggle/Toggle";
import { UPDATE_TYPE } from "../SignupPresenter";
import { FaInfoCircle } from "react-icons/fa";
import { BsQuestionCircleFill } from "react-icons/bs";
import InfoBubble from "components/blocks/bubble/InfoBubble";
import { BubbleContainer } from "pages/license/LicenseStyled";

interface Props {
  checkedEmail: string;
  onClickSignup: () => void;
  onClickVerify: () => void;
  onClickVerifyCancel: () => void;
  onChangeUserInfo: (type: UPDATE_TYPE, data: string | boolean) => void;
  userInfo: UserInfo;
  verifyCode: string;
  onChangeVerifyCode: (value: string) => void;
  onblurConfirmPassword: () => void;
  isValidConfirm: boolean;
  onOpenEULAModal: (isOpen: boolean) => void;
  isValidated: Validate;
  firstNameRef: any;
  lastNameRef: any;
  passwordRef: any;
  confirmPasswordRef: any;
  agreementRef: any;
  validateMsg: string;
  onClickResend: () => void;
  isValidVerify: boolean;
  isPhoneVerified: boolean;
  onSendVerify: () => void;
  mfaNumber: string;
  validatePhoneMsg: string;
}

export default function InputInfo(props: Props): JSX.Element {
  const {
    checkedEmail,
    onClickSignup,
    onClickVerify,
    onClickVerifyCancel,
    onChangeUserInfo,
    userInfo,
    verifyCode,
    onChangeVerifyCode,
    onblurConfirmPassword,
    isValidConfirm,
    onOpenEULAModal,
    isValidated,
    firstNameRef,
    lastNameRef,
    passwordRef,
    confirmPasswordRef,
    agreementRef,
    validateMsg,
    onClickResend,
    isValidVerify,
    isPhoneVerified,
    onSendVerify,
    mfaNumber,
    validatePhoneMsg,
  } = props;
  const intl = useIntl();
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [isMouseOver, setIsMouseOver] = useState<boolean>(false);

  useEffect(() => {
    if (
      !(userInfo.password.length < 8) &&
      checkPasswordRegEx(userInfo.password) &&
      checkPasswordNumberRegEx(userInfo.password)
    ) {
      let timer = setTimeout(() => {
        setIsFocus(false);
      }, 800);
      return () => {
        clearTimeout(timer);
      };
    } else {
      //setIsFocus(true);
    }
  }, [userInfo.password]);

  return (
    <>
      <s.InputWrapper>
        <Input
          id="dw_signup_input_email"
          type="text"
          className="input-field"
          defaultValue={checkedEmail}
          //value="dwadmin@digital-watchdog.com"
          disabled
        />
        <Input
          id="dw_signup_input_firstName"
          ref={firstNameRef}
          type="text"
          className="input-field"
          placeholder="First name"
          autoComplete="off"
          value={userInfo.firstName}
          onChange={(e) =>
            onChangeUserInfo(UPDATE_TYPE.FIRST_NAME, e.target.value)
          }
          onBlur={(e) => onblurConfirmPassword()}
          isValidated={isValidated.firstName}
        />
        <Input
          id="dw_signup_input_lastName"
          ref={lastNameRef}
          type="text"
          className="input-field"
          placeholder="Last name"
          autoComplete="off"
          value={userInfo.lastName}
          onChange={(e) =>
            onChangeUserInfo(UPDATE_TYPE.LAST_NAME, e.target.value)
          }
          onBlur={(e) => onblurConfirmPassword()}
          isValidated={isValidated.lastName}
          autoFocus={isValidated.lastName}
        />
        <s.PasswordInputInner>
          <div style={{ height: 0 }}>
            <input value={checkedEmail} />
          </div>
          <Input
            id="dw_signup_input_password"
            ref={passwordRef}
            type="password"
            autoComplete="off"
            className="input-field"
            placeholder="Password"
            value={userInfo.password}
            onChange={(e) =>
              onChangeUserInfo(UPDATE_TYPE.PASSWORD, e.target.value)
            }
            isValidated={isValidated.password}
            onFocus={() => setIsFocus(true)}
            onBlur={() => setIsFocus(false)}
          />
          {isFocus && (
            <s.PasswordBubble>
              <PasswordBubble password={userInfo.password} />
            </s.PasswordBubble>
          )}
        </s.PasswordInputInner>

        <Input
          id="dw_signup_input_confirmPassword"
          ref={confirmPasswordRef}
          type="password"
          className="input-field"
          placeholder="Confirm Password"
          autoComplete="off"
          value={userInfo.confirmPassword}
          onChange={(e) =>
            onChangeUserInfo(UPDATE_TYPE.CONFIRM_PASSWORD, e.target.value)
          }
          onBlur={(e) => onblurConfirmPassword()}
          isValidated={isValidConfirm}
          autoFocus={isValidConfirm}
        />
        {isValidConfirm && (
          <cs.Bottom>
            <ValidateWord>Password you entered do not mismatch </ValidateWord>
          </cs.Bottom>
        )}
        <s.PhoneWrapper>
          <div className="phone-input-wrapper">
            <PhoneInput
              login
              // selected={props.isFlagSelected}
              // onSelect={props.onSelectFlag}
              selected={userInfo.phoneNumber}
              placeholder="Phone number (Optional)"
              onChange={(value: string) => {
                onChangeUserInfo(UPDATE_TYPE.PHONE_NUMBER, value);
              }}
              // onEnterKeyPress={() => console.log("on enter")}
              isValidated={isValidated.phoneNnumber}
              disabled={isPhoneVerified}
            />
            <TableButton
              onClick={mfaNumber !== "" ? onClickResend : onSendVerify}
              disabled={isPhoneVerified}
              label={
                mfaNumber !== ""
                  ? intl.formatMessage({
                      id: "resend_code",
                      defaultMessage: "Resend Code",
                    })
                  : "Send Code"
              }
              buttonType="primary"
              icon={
                mfaNumber === "" && (
                  <button
                    className="icon-button"
                    onMouseEnter={() => {
                      setIsMouseOver(true);
                    }}
                    onMouseLeave={() => {
                      setIsMouseOver(false);
                    }}
                  >
                    <BsQuestionCircleFill size={12} />
                    {isMouseOver && (
                      <BubbleContainer>
                        <InfoBubble>Verify it’s you</InfoBubble>
                      </BubbleContainer>
                    )}
                  </button>
                )
              }
            />
          </div>
          {isPhoneVerified && (
            <div style={{ width: "100%" }}>
              <UnderlineText
                id="dw_signup_button_forward_signup"
                onClick={onClickVerifyCancel}
                fontSize={13}
              >
                {intl.formatMessage({
                  id: "reset_phone_number",
                  defaultMessage: "Reset Phone Number",
                })}
              </UnderlineText>
            </div>
          )}
          {mfaNumber !== "" && !isPhoneVerified && (
            <s.ValidWrapper>
              <div className="phone-input-wrapper">
                <Input
                  value={verifyCode}
                  onChange={(e) => onChangeVerifyCode(e.target.value)}
                  // onKeyDown={onClickVerify}
                  maxLength={6}
                  isValidated={isValidVerify}
                  placeholder="Verification Code"
                />
                <TableButton
                  onClick={onClickVerify}
                  label={intl.formatMessage({
                    id: "verify",
                    defaultMessage: "Verify",
                  })}
                  buttonType="primary"
                />
              </div>
            </s.ValidWrapper>
          )}

          {validatePhoneMsg !== "" && (
            <ValidateWord>{validatePhoneMsg}</ValidateWord>
          )}
        </s.PhoneWrapper>

        <s.TFAWrapper>
          <Text fontSize={14}>2FA Code</Text>
          <div className="checkbox-wrapper ">
            <label>
              <input
                type="radio"
                value="email"
                checked={userInfo.use2faCode === "email"}
                onChange={(e) =>
                  onChangeUserInfo(UPDATE_TYPE.TWOFACTOR, e.target.value)
                }
              />
              Email
            </label>
            <label>
              <input
                type="radio"
                value="phone"
                checked={userInfo.use2faCode === "phone"}
                onChange={(e) =>
                  onChangeUserInfo(UPDATE_TYPE.TWOFACTOR, e.target.value)
                }
                disabled={
                  isEmpty(userInfo.phoneNumber) ||
                  (!isEmpty(userInfo.phoneNumber) && !isPhoneVerified)
                }
              />
              Phone
            </label>
          </div>
        </s.TFAWrapper>
        {isPhoneVerified && (
          <s.TFAWrapper>
            <Text fontSize={14}>Receive SMS</Text>
            <div className="checkbox-wrapper ">
              <ToggleComponent
                isToggled={userInfo.notifySms}
                checked={userInfo.notifySms}
                onChange={(checked) => {
                  onChangeUserInfo(UPDATE_TYPE.NOTIFYSMS, checked);
                }}
              />
            </div>
          </s.TFAWrapper>
        )}
        <cs.Bottom>
          {validateMsg !== "" && <ValidateWord>{validateMsg}</ValidateWord>}
        </cs.Bottom>
      </s.InputWrapper>
      <s.AgreementWrapper>
        <input
          id="dw_signup_input_userAgree"
          key="signup_input_userAgree"
          type="checkbox"
          ref={agreementRef}
          checked={userInfo.agreement}
          onBlur={(e) => onblurConfirmPassword()}
          onChange={(e) => {
            onChangeUserInfo(UPDATE_TYPE.AGREEMENT, e.target.checked);
          }}
        />
        I agree to the
        <UnderlineText onClick={() => onOpenEULAModal(true)} fontSize={14}>
          End User License Agreement.
        </UnderlineText>
      </s.AgreementWrapper>
      <s.SubmitWrapper>
        {/* <cs.ValidateWrapper>
                      <ValidateWord>{validateMsg}</ValidateWord>
                    </cs.ValidateWrapper> */}
        <cs.BigButton id="dw_signup_button_signup" onClick={onClickSignup}>
          Sign Up
        </cs.BigButton>
      </s.SubmitWrapper>
    </>
  );
}
