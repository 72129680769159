import React, { useCallback } from "react";
import { useState } from "react";
import * as s from "styles/ModalCommonStyled";
import ModalSubMenu from "components/blocks/modal/menu/ModalSubMenu";
import {
  ModalRecorder,
  SearchRecorderList,
  SelectedRowWithAddress,
} from "./SearchRecorderList";
import { SearchRecorderGroup } from "./SearcRecorderGroup";
import DebounceSearchInput from "components/atoms/input/search/DebounceSearchInput";
import { RecorderGroupSystem } from "api/interfaces/recorderInterface.interface";

export type SearchRecorderProps = {
  accountId: string;
  onSelectedRowsChange?: (selectedRows: string[]) => void;
  onSelectedGroupRowsChange?: (selectedRows: number[]) => void;
  onSelectRowChangeAddress?: (row: SelectedRowWithAddress) => void;
  selectedRow?: string[] | string;
  selectedGroupRow?: number[];
  isTab?: boolean;
  isSingleMode?: boolean;
  isEditable?: boolean;
  userId: string | undefined;
};

export interface DataRow {
  id: string;
  name: string;
  cameras: number;
  location: string;
}

export function SearchRecorder(props: SearchRecorderProps): JSX.Element {
  const [searchText, setSearchText] = useState<string>("");
  const onClickSearch = (keyword: string) => {
    setSearchText(keyword);
  };
  // step 0 - recorders sub menu
  const recorderMenus = [
    {
      label: `Add Recorders (${
        !props.isSingleMode
          ? props.selectedRow !== undefined
            ? props.selectedRow?.length
            : 0
          : 0
      })`,
      value: true,
    },
    {
      label: `Add Recorder Groups (${
        props.selectedGroupRow !== undefined
          ? props.selectedGroupRow?.length
          : 0
      })`,
      value: false,
    },
  ];

  const [currentMenu, setCurrentMenu] = useState(0);
  const onChangeMenu = (index: number) => {
    setCurrentMenu(index);
  };

  const onChangeSearchText = useCallback((value: string) => {
    setSearchText(value);
  }, []);

  return (
    <>
      {props.isTab ? (
        <s.ModalTabSearchWrapper>
          <ModalSubMenu
            menus={recorderMenus}
            currentMenu={currentMenu}
            onChange={onChangeMenu}
          />
          <div className="search-box">
            {/* search input group, individual 공통으로 검색되도록 */}
            <DebounceSearchInput
              placeholder="Search for Recorder"
              onClickSearch={onClickSearch}
              onChange={onChangeSearchText}
            />
          </div>
        </s.ModalTabSearchWrapper>
      ) : (
        <DebounceSearchInput
          placeholder="Search for Recorder"
          onClickSearch={onClickSearch}
          onChange={onChangeSearchText}
        />
      )}

      <s.ModalTableContainer>
        {currentMenu === 0 && (
          <div>
            {!props.isTab && (
              <div className="search-title">
                {props.isSingleMode ? "Select Recorder" : "Select Recorders"}
              </div>
            )}
            <SearchRecorderList
              accountId={props.accountId}
              search={searchText}
              selectedRow={props.selectedRow}
              onSelectedRowsChange={props.onSelectedRowsChange}
              onSelectRowChangeAddress={props.onSelectRowChangeAddress}
              isSingleMode={props.isSingleMode}
              isEditable={props.isEditable}
              userId={props.userId}
            />
          </div>
        )}

        {currentMenu === 1 && (
          <div>
            {!props.isTab && (
              <div className="search-title">Select Recorders</div>
            )}
            <SearchRecorderGroup
              accountId={props.accountId}
              search={searchText}
              selectedRecorderGroupRow={props.selectedGroupRow}
              onSelectedRowsChange={props.onSelectedGroupRowsChange}
              userId={props.userId}
            />
          </div>
        )}
      </s.ModalTableContainer>
    </>
  );
}
