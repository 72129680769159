import { useCallback, useEffect, useState } from "react";
import ReportPresenter from "./ReportPresenter";
import * as s from "./ReportStyled";
import TableButton from "components/atoms/buttons/TableButton";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import {
  CPReport,
  CPReportDataRow,
  CVVReport,
  GetReportListResposne,
  InstallReport,
  ReportDataRow,
  ReportRecorderGroup,
  ReportSystem,
  ThumbnailReport,
} from "api/interfaces/reportInterface.interface";
import {
  changeTypeForReportType,
  changeTypeNumberForReportText,
} from "utils/functions";
import TableColumnFilterTitle from "components/blocks/table/TableColumnFilterTitle";
import {
  BasePagination,
  LEVEL_TYPE,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import { useQuery, useQueryClient } from "react-query";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import {
  getReportInfo,
  getReportInfoCP,
  getReportInfoCVV,
  getReportInfoInstall,
  getReportInfoThumbnail,
  getReportList,
} from "api/reportAPI";
import { useAuth } from "components/Auth";
import { getAccountTimeFormat } from "utils/timeUtil";
import DetailButton from "components/atoms/buttons/detail/DetailButton";
import RBACWrapper from "components/blocks/function/RBACWrapper";
import {
  HealthReport,
  InventoryReport,
} from "api/interfaces/reportInterface.interface";
import { isCheckPermission } from "utils/AccountUtil";
import { useIntl } from "react-intl";
import { notify } from "components/atoms/notification/Notification";
import { isNull } from "lodash";
import {
  menuNavigation,
  queryNavigate,
  returnToStartPage,
} from "utils/MenuUtil";
import AccountId from "components/atoms/text/labels/AccountId";
import { TableColumnsType } from "antd";
import { ColumnType } from "antd/es/table";
import {
  changeSortOrderAntd,
  onSortAntd,
} from "components/atoms/table/AntdTable";
import InfoBubble from "components/blocks/bubble/InfoBubble";
import * as mediaQuery from "components/MediaQuery";
import { FaInfoCircle } from "react-icons/fa";
import { BubbleContainer } from "pages/license/LicenseStyled";
import { AddReportListType } from "./add/AddReport";
import { Row } from "pages/users/UsersStyled";

export interface DataRow {
  type: string;
  title: string;
  recorder: string;
  createdBy: string;
  email: string;
  createdAt: string;
  enable: boolean;
  accountName: string;
  accountNumber: string;
  recorderTotalCount: number;
}

const initialDetailCP = [
  {
    name: "health",
    value: 0,
    label: "Health",
  },
  {
    name: "inventory",
    value: 0,
    label: "Inventory",
  },
  {
    name: "installation",
    value: 0,
    label: "Installation",
  },
];

const initialDetailEU = [
  {
    name: "health",
    value: 0,
    label: "Health",
  },
  {
    name: "cvv",
    value: 0,
    label: "CVV",
  },
  // {
  //   name: "thumbnail",
  //   value: 0,
  //   label: "Thumbnail",
  // },
  {
    name: "inventory",
    value: 0,
    label: "Inventory",
  },
  {
    name: "installation",
    value: 0,
    label: "Installation",
  },
];

const typeFiltersCP = [
  {
    label: "Health",
    value: "health",
  },
  {
    label: "Inventory",
    value: "inventory",
  },
  {
    label: "Installation",
    value: "installation",
  },
];

const typeFiltersEU = [
  {
    label: "Health",
    value: "health",
  },
  {
    label: "CVV",
    value: "cvv",
  },
  // {
  //   label: "Thumbnail",
  //   value: "thumbnail",
  // },
  {
    label: "Inventory",
    value: "inventory",
  },
  {
    label: "Installation",
    value: "installation",
  },
];

const initialReportQueryInfo: BasePagination = {
  total: 0,
  pageNum: 0,
  pageLimit: 20,
  keyword: "",
  sortType: "createdAt",
  sortDirection: SORT_DIRECTION.ASC,
  type: null,
};

export default function ReportContainer(): JSX.Element {
  const intl = useIntl();
  const location = useLocation();
  const auth = useAuth();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const [isMouseOver, setIsMouseOver] = useState<boolean>(false);
  const [mouseIndex, setMouseIndex] = useState<number | null>(null);
  const [pending, setPending] = useState<boolean>(true);

  const [isRowClickEditable, setIsRowClickEditable] = useState<boolean>(
    isCheckPermission("manageReportRules", selectedAccount)
  );
  useEffect(() => {
    if (selectedAccount) {
      setIsRowClickEditable(
        isCheckPermission("manageReportRules", selectedAccount)
      );
    }
  }, [selectedAccount]);

  const [selectReportRow, setSelectReportRow] = useState<
    ReportDataRow | CPReportDataRow
  >();

  const [selectData, setSelectData] = useState<
    | HealthReport
    | InventoryReport
    | InstallReport
    | CVVReport
    | ThumbnailReport
    | CPReport
  >();

  const onRowClick = (row: ReportDataRow | CPReportDataRow) => {
    if (selectedAccount.accountLevel === LEVEL_TYPE.CP) {
      onClickCPEdit(row as CPReportDataRow);
      return;
    }
    onViewDetail(row);
  };

  const onViewDetail = ({ type, id }: { type: string; id: string }) => {
    navigate({
      pathname: `/reports/${changeTypeNumberForReportText(type)}/${id}`,
      search: `?${createSearchParams({
        account: selectedAccount.accountId,
      })}`,
    });
  };

  const [reportList, setReportList] = useState<
    ReportDataRow[] | CPReportDataRow[]
  >([]);
  const [totalCount, setTotalCount] = useState(0);
  const [detailItems, setDetailItems] = useState<
    { name: string; value: number; label: string }[]
  >(
    selectedAccount.accountLevel === LEVEL_TYPE.CP
      ? initialDetailCP
      : initialDetailEU
  );

  const [reportQueryInfo, setReportQueryInfo] = useState<BasePagination>({
    ...initialReportQueryInfo,
  });

  const status = {
    totalTitle: "Total",
    totalValue: totalCount,
    items:
      selectedAccount.accountLevel === LEVEL_TYPE.EU
        ? [
            <DetailButton
              title={"Report Quantities by category"}
              contents={detailItems}
            />,
          ]
        : [],
  };

  useEffect(() => {
    if (!selectedAccount.userPermission.viewReports) {
      returnToStartPage(selectedAccount, navigate, auth.user);
    }
  }, [selectedAccount.userPermission.viewReports]);

  useEffect(() => {
    const changeParam = new URLSearchParams(location.search);
    if (changeParam.size > 0) {
      setReportQueryInfo((info) => {
        return {
          ...info,
          pageNum: isNull(changeParam.get("pageNum"))
            ? 0
            : Number(changeParam.get("pageNum")),
          pageLimit: isNull(changeParam.get("pageLimit"))
            ? 20
            : Number(changeParam.get("pageLimit")),
          keyword: isNull(changeParam.get("keyword"))
            ? ""
            : (changeParam.get("keyword") as string),
          sortType: isNull(changeParam.get("sortType"))
            ? "createdAt"
            : (changeParam.get("sortType") as string),
          sortDirection: isNull(changeParam.get("sortDirection"))
            ? SORT_DIRECTION.ASC
            : (changeParam.get("sortDirection") as SORT_DIRECTION),
          type: isNull(changeParam.get("type"))
            ? null
            : (changeParam.get("type") as string),
        };
      });
    } else {
      setReportQueryInfo({ ...initialReportQueryInfo });
    }
  }, [location, navigate]);

  const onChangeReportType = useCallback(
    (value: string | undefined) => {
      if (value !== undefined) {
        if (value === "all") {
          if (reportQueryInfo.type !== null) {
            queryNavigate(
              navigate,
              location.pathname,
              {
                pageLimit: reportQueryInfo.pageLimit,
                pageNum: 0,
                keyword: reportQueryInfo.keyword as string,
                sortType: reportQueryInfo.sortType,
                sortDirection: reportQueryInfo.sortDirection as SORT_DIRECTION,
                type: null,
              },
              selectedAccount
            );
          } else {
            refetch();
          }
          // setReportQueryInfo((info) => {
          //   return { ...info, type: null, pageNum: 0 };
          // });
        } else {
          if (reportQueryInfo.type !== value) {
            queryNavigate(
              navigate,
              location.pathname,
              {
                pageLimit: reportQueryInfo.pageLimit,
                pageNum: 0,
                keyword: reportQueryInfo.keyword as string,
                sortType: reportQueryInfo.sortType,
                sortDirection: reportQueryInfo.sortDirection as SORT_DIRECTION,
                type: value,
              },
              selectedAccount
            );
          } else {
            refetch();
          }
          // setReportQueryInfo((info) => {
          //   return { ...info, type: value as string, pageNum: 0 };
          // });
        }
        setPending(true);
      }
    },
    [
      location.pathname,
      navigate,
      reportQueryInfo.keyword,
      reportQueryInfo.pageLimit,
      reportQueryInfo.sortDirection,
      reportQueryInfo.sortType,
      reportQueryInfo.type,
      selectedAccount,
    ]
  );
  const onHeaderCell = (
    column: ColumnType<ReportDataRow> | ColumnType<CPReportDataRow>
  ) => {
    return {
      onClick: () => {
        if (column.key) {
          setReportQueryInfo((info) => {
            return {
              ...info,
              sortType: column.key as string,
              sortDirection: onSortAntd(info.sortDirection),
            };
          });
        }
      },
    };
  };
  // table columns
  const columns: TableColumnsType<ReportDataRow> = [
    {
      title: (
        <TableColumnFilterTitle
          title="Type"
          filters={
            selectedAccount.accountLevel === LEVEL_TYPE.CP
              ? typeFiltersCP
              : typeFiltersEU
          }
          onClick={onChangeReportType}
        />
      ),
      render: (value, row) => (
        <s.Row onClick={() => onViewDetail(row)}>
          {changeTypeForReportType(row.type)}
        </s.Row>
      ),
      width: 120,
    },
    {
      title: "Title",
      sorter: true,
      key: "title",
      sortOrder: changeSortOrderAntd(reportQueryInfo, "title"),
      onHeaderCell: onHeaderCell,
      render: (value, row) => row.title,
    },
    {
      title: "Organization",
      // sorter: true,
      key: "accountName",
      // sortOrder: changeSortOrderAntd(reportQueryInfo, "accountName"),
      onHeaderCell: onHeaderCell,
      render: (value, row) => (
        <s.AccountBox>
          {row.accountName}
          <AccountId accountId={row.accountNumber} level={LEVEL_TYPE.EU} />
        </s.AccountBox>
      ),
    },
    {
      title: "Recorder",
      render: (value, row, index) => (
        <s.RecorderRow status={row.recorderTotalCount > 0 ? true : false}>
          {row.recorder}
          {row.type === "INSTALLATION" &&
            row.systems === undefined &&
            row.installationRecorder !== undefined &&
            row.recorderTotalCount === 0 && (
              <mediaQuery.Default>
                <button
                  className="bubble-container"
                  onMouseEnter={() => {
                    setIsMouseOver(true);
                    setMouseIndex(index);
                  }}
                  onMouseLeave={() => {
                    setIsMouseOver(false);
                    setMouseIndex(null);
                  }}
                >
                  <FaInfoCircle />
                  {isMouseOver && mouseIndex !== null && mouseIndex === index && (
                    <BubbleContainer>
                      <InfoBubble>
                        No longer available in the organization.
                        <br /> Re-register the recorder or delete the report
                      </InfoBubble>
                    </BubbleContainer>
                  )}
                </button>
              </mediaQuery.Default>
            )}
        </s.RecorderRow>
      ),
      responsive: ["md"],
    },
    {
      title: "Created By",
      sorter: true,
      sortOrder: changeSortOrderAntd(reportQueryInfo, "createdBy"),
      key: "createdBy",
      onHeaderCell: onHeaderCell,
      render: (value, row) => row.createdBy,
      responsive: ["lg"],
    },
    {
      title: "Date Created",
      sorter: true,
      key: "createdAt",
      defaultSortOrder: "ascend",
      sortOrder: changeSortOrderAntd(reportQueryInfo, "createdAt"),
      onHeaderCell: onHeaderCell,
      render: (value, row) =>
        row.createdAt !== undefined
          ? getAccountTimeFormat(row.createdAt, selectedAccount, true)
          : "",
      width: 200,
      responsive: ["lg"],
    },
    // 마지막 버튼
    {
      title: "",
      render: (value, row) => (
        <s.Row className="table-button-wrapper">
          <RBACWrapper requiredPermissions="manageReportRules">
            {selectedAccount.accountLevel === "EU" ? (
              <TableButton
                label="Edit"
                onClick={(e) => {
                  e.stopPropagation();
                  onClickEdit(row);
                }}
                // disabled={
                //   !isEditAble(
                //     selectedAccount,
                //     auth.user,
                //     row.createdBy,
                //     row.email
                //   )
                // }
              />
            ) : null}
          </RBACWrapper>
          <span className="row-click-button">
            <TableButton label="View" onClick={() => onViewDetail(row)} />
          </span>
        </s.Row>
      ),
      align: "right",
      width: mediaQuery.useIsMobile() ? 50 : 150,
    },
  ];

  const cpColumns: TableColumnsType<CPReportDataRow> = [
    {
      title: "Title",
      sorter: true,
      key: "title",
      sortOrder: changeSortOrderAntd(reportQueryInfo, "title"),
      onHeaderCell: onHeaderCell,
      render: (value, row) => <Row isAdmin={row.isDefault}>{row.title}</Row>,
    },

    {
      title: "Organizations",
      render: (value, row) => row.accountCount,
      align: "center",
      width: 150,
    },
    {
      title: "Recorders",
      render: (value, row) => row.recorderCount,
      align: "center",
      width: 150,
    },
    {
      title: "Cameras",
      render: (value, row) => row.cameraCount,
      align: "center",
      width: 150,
    },
    {
      title: "Created By",
      sorter: true,
      sortOrder: changeSortOrderAntd(reportQueryInfo, "createdBy"),
      key: "createdBy",
      onHeaderCell: onHeaderCell,
      render: (value, row) => row.createdBy,
      responsive: ["xxl"],
    },
    {
      title: "Date Created",
      sorter: true,
      key: "createdAt",
      defaultSortOrder: "ascend",
      sortOrder: changeSortOrderAntd(reportQueryInfo, "createdAt"),
      onHeaderCell: onHeaderCell,
      render: (value, row) =>
        row.createdAt
          ? getAccountTimeFormat(row.createdAt, selectedAccount, true)
          : "",
      responsive: ["xl"],
    },

    {
      title: "",
      render: (value, row) => (
        <s.Row className="table-button-wrapper">
          <TableButton
            label="Inventory"
            onClick={() => onViewDetail({ type: "INVENTORY", id: row.id })}
          />
          <TableButton
            label="Health"
            onClick={() => onViewDetail({ type: "HEALTH", id: row.id })}
          />
          <RBACWrapper requiredPermissions="manageReportRules">
            <span className="row-click-button">
              <TableButton label="Edit" onClick={() => onClickCPEdit(row)} />
            </span>
          </RBACWrapper>
        </s.Row>
      ),
      align: "right",
      width: 220,
    },
  ];

  const onClickEdit = (row: ReportDataRow) => {
    // console.log(row);
    const reportTypeString: AddReportListType = changeTypeNumberForReportText(
      row.type
    );
    setSelectReportRow((report) => {
      return row;
    });

    switch (reportTypeString) {
      case "health":
      case "inventory":
        getReportInfo({
          accountId: selectedAccount.accountId,
          reportId: row.id,
        }).then((res: any) => {
          if (res.result !== undefined && res.error === 0) {
            setSelectData((data) => {
              return res.result as HealthReport;
            });
            onModal("edit");
          }
        });
        break;
      case "cvv":
        getReportInfoCVV({
          accountId: selectedAccount.accountId,
          reportId: row.id,
        }).then((res: any) => {
          if (res.result !== undefined && res.error === 0) {
            setSelectData((data) => {
              return res.result as CVVReport;
            });
            onModal("edit");
          }
        });
        break;
      case "thumbnail":
        getReportInfoThumbnail({
          accountId: selectedAccount.accountId,
          reportId: row.id,
        }).then((res: any) => {
          if (res.result !== undefined && res.error === 0) {
            setSelectData((data) => {
              return res.result as ThumbnailReport;
            });
            onModal("edit");
          }
        });
        break;
      case "installation":
        getReportInfoInstall({
          accountId: selectedAccount.accountId,
          reportId: row.id,
        }).then((res: any) => {
          if (res.result !== undefined && res.error === 0) {
            setSelectData((data) => {
              return res.result as InstallReport;
            });
            onModal("edit");
          }
        });
        break;
      default:
        break;
    }
  };

  const onClickCPEdit = (row: CPReportDataRow) => {
    setSelectReportRow({ ...row });
    getReportInfoCP({
      accountId: selectedAccount.accountId,
      reportId: row.id,
    }).then((res: any) => {
      if (res.result !== undefined && res.error === 0) {
        setSelectData((data) => {
          return res.result as CPReport;
        });
        onModal("edit");
      }
    });
  };

  const onModal = (type?: string) => {
    if (type === "add") {
      if (isAddModal) {
        queryClient.invalidateQueries("reportList");
      }
      setIsAddModal(!isAddModal);
    }
    if (type === "edit") {
      if (isEditModal) {
        queryClient.invalidateQueries("reportList");
      }
      setIsEditModal(!isEditModal);
    }
  };

  const [isAddModal, setIsAddModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);

  const displayRecorder = (
    systems: any[],
    recorderGroup: any[],
    recorderTotalCount: number,
    type: string,
    installationRecorder?: string
  ) => {
    let name = "";
    let count = recorderTotalCount;
    let mergedCount = 0;

    if (systems !== undefined && systems.length > 0) {
      mergedCount += systems.length;
      systems.forEach((merged: any) => {
        if (merged.recorders !== undefined) {
          if (name === "") {
            if (type !== "INSTALLATION") {
              name = merged.mergedSystemName;
            } else {
              if (
                merged.recorders !== undefined &&
                merged.recorders.length > 0
              ) {
                name = merged.recorders[0].name;
              }
            }
          }
        }
      });
    }

    if (recorderGroup !== undefined && recorderGroup.length > 0) {
      recorderGroup.forEach((group: any) => {
        mergedCount += group.recorderCount;
        if (group.systems !== undefined && group.systems.length > 0) {
          group.systems.forEach((merged: any) => {
            if (name === "") {
              name = merged.mergedSystemName;
            }
          });
        }
      });
    }

    // installation deleted
    if (type === "INSTALLATION") {
      if (systems === undefined && installationRecorder !== undefined) {
        count = 1;
        name = `${installationRecorder}`;
      }
    }
    return count === 0
      ? "No Recorder"
      : count === 1 || mergedCount === 1
      ? name
      : name + `+${count - 1}`;
  };

  const { error, refetch } = useQuery(
    ["reportList", reportQueryInfo],
    () =>
      getReportList({
        payload: reportQueryInfo,
        accountId: selectedAccount.accountId,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: GetReportListResposne) => {
        console.log(res);
        if (!res.result || res.page?.total === 0) {
          setTotalCount(0);
          setReportQueryInfo({ ...initialReportQueryInfo });
          setDetailItems(
            selectedAccount.accountLevel === LEVEL_TYPE.CP
              ? initialDetailCP
              : initialDetailEU
          );
          setReportList([]);

          return;
        }
        setTotalCount(res.page.total);
        setReportQueryInfo({ ...reportQueryInfo, ...res.page });

        if (selectedAccount.accountLevel === LEVEL_TYPE.EU) {
          setDetailItems(
            initialDetailEU.map((item) => {
              for (const typeInfo of res.result.typeInfos) {
                if (item.name === typeInfo.type.toLowerCase()) {
                  return { ...item, value: typeInfo.typeCount };
                }
              }
              return {
                ...item,
              };
            })
          );

          setReportList(
            res.result.reports.map((report: any) => {
              // console.log(report);
              return {
                ...report,
                recorder: report.autoApply
                  ? "ALL"
                  : displayRecorder(
                      report.systems,
                      report.recorderGroups,
                      report.recorderTotalCount,
                      report.type,
                      report.installationRecorder
                    ),

                enable: report.isEnable,
              };
            })
          );
          return;
        }

        setReportList(res.result.reports as CPReportDataRow[]);
      },
      onError: (e: any) => {
        setReportQueryInfo({ ...initialReportQueryInfo });
        setDetailItems(
          selectedAccount.accountLevel === LEVEL_TYPE.CP
            ? initialDetailCP
            : initialDetailEU
        );
        setReportList([]);
        notify(
          "error",
          intl.formatMessage({
            id: "label.report.notify.list.search.fail",
            defaultMessage: "Failed to search report list.",
          })
        );
      },
      onSettled: (e: any) => {
        setPending(false);
      },
    }
  );

  const onClickSearch = useCallback(
    (keyword: string) => {
      if (reportQueryInfo.keyword !== keyword) {
        queryNavigate(
          navigate,
          location.pathname,
          {
            pageLimit: reportQueryInfo.pageLimit,
            pageNum: 0,
            keyword: keyword,
            sortType: reportQueryInfo.sortType,
            sortDirection: reportQueryInfo.sortDirection as SORT_DIRECTION,
            type: reportQueryInfo.type,
          },
          selectedAccount
        );
      } else {
        refetch();
      }
      setPending(true);
    },
    [
      location.pathname,
      navigate,
      refetch,
      reportQueryInfo.keyword,
      reportQueryInfo.pageLimit,
      reportQueryInfo.sortDirection,
      reportQueryInfo.sortType,
      reportQueryInfo.type,
      selectedAccount,
    ]
  );

  const onChangePage = (page: number, totalRows: number) => {
    if (page !== reportQueryInfo.pageNum + 1) {
      queryNavigate(
        navigate,
        location.pathname,
        {
          pageLimit: reportQueryInfo.pageLimit,
          pageNum: page - 1,
          keyword: reportQueryInfo.keyword as string,
          sortType: reportQueryInfo.sortType,
          sortDirection: reportQueryInfo.sortDirection as SORT_DIRECTION,
          type: reportQueryInfo.type,
        },
        selectedAccount
      );
      setPending(true);
    }
  };

  return (
    <ReportPresenter
      columns={columns}
      cpColumns={cpColumns}
      data={reportList}
      onModal={onModal}
      isAddModal={isAddModal}
      isEditModal={isEditModal}
      status={status}
      onRowClick={onRowClick}
      selectReportRow={selectReportRow}
      selectData={selectData}
      selectedAccount={selectedAccount}
      paginationInfo={reportQueryInfo}
      onChangePage={onChangePage}
      onClickSearch={onClickSearch}
      isRowClickEditable={isRowClickEditable}
      tablePending={pending}
    />
  );
}
