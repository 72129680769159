import { useCallback, useEffect, useState } from "react";
import GroupPresenter from "./GroupPresenter";
import * as s from "../RecordersStyled";
import TableButton from "components/atoms/buttons/TableButton";
import { Text } from "components/atoms/text/Text";
import { COLORS } from "styles/colors";
import { useLocation, useNavigate } from "react-router-dom";
import {
  RecorderGroup,
  RecorderGroupListResponse,
} from "api/interfaces/recorderInterface.interface";
import * as mediaQuery from "components/MediaQuery";
import { useAppSelector } from "redux/hooks";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import {
  BasePagination,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import { useQuery, useQueryClient } from "react-query";
import { getRecorderGroupList } from "api/recorderAPI";
import { useIntl } from "react-intl";
import { isCheckPermission } from "utils/AccountUtil";
import RBACWrapper from "components/blocks/function/RBACWrapper";
import { isNull } from "lodash";
import { queryNavigate } from "utils/MenuUtil";
import { TableColumnsType } from "antd";
import {
  changeSortOrderAntd,
  onSortAntd,
} from "components/atoms/table/AntdTable";
import { ColumnType } from "antd/es/table";

const initialQueryInfo = {
  total: 0,
  pageNum: 0,
  pageLimit: 20,
  keyword: "",
  sortType: "name",
  sortDirection: SORT_DIRECTION.ASC,
};

// TODO Recorder Group API 구현 후 개발보완 필요.
export function RecordersContainer(): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const intl = useIntl();

  const [isAddModal, setIsAddModal] = useState<boolean>(false);
  const [isEditModal, setIsEditModal] = useState<boolean>(false);

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const [recGroup, setRecGroup] = useState<RecorderGroup[]>([]);
  const [currGroup, setCurrGroup] = useState<RecorderGroup>();

  const [listTotal, setListTotal] = useState<number>(0);

  const [recGroupQueryInfo, setRecGroupQueryInfo] = useState<BasePagination>({
    ...initialQueryInfo,
  });
  const [pending, setPending] = useState<boolean>(true);

  const [isRowClickEditable, setIsRowClickEditable] = useState<boolean>(
    isCheckPermission("manageRecordersAndGroups", selectedAccount)
  );

  useEffect(() => {
    if (selectedAccount) {
      setIsRowClickEditable(
        isCheckPermission("manageRecordersAndGroups", selectedAccount)
      );
    }
  }, [selectedAccount]);

  useEffect(() => {
    const changeParam = new URLSearchParams(location.search);
    if (changeParam.size > 0) {
      setRecGroupQueryInfo((info) => {
        return {
          ...info,
          pageNum: isNull(changeParam.get("pageNum"))
            ? 0
            : Number(changeParam.get("pageNum")),
          pageLimit: isNull(changeParam.get("pageLimit"))
            ? 20
            : Number(changeParam.get("pageLimit")),
          keyword: isNull(changeParam.get("keyword"))
            ? ""
            : (changeParam.get("keyword") as string),
          sortType: isNull(changeParam.get("sortType"))
            ? "name"
            : (changeParam.get("sortType") as string),
          sortDirection: isNull(changeParam.get("sortDirection"))
            ? SORT_DIRECTION.ASC
            : (changeParam.get("sortDirection") as SORT_DIRECTION),
        };
      });
    } else {
      setRecGroupQueryInfo((info) => {
        return {
          ...initialQueryInfo,
        };
      });
    }
  }, [location]);

  const [status, setStatus] = useState({
    totalTitle: "Total Groups",
    totalValue: 0,
  });
  const onHeaderCell = (column: ColumnType<RecorderGroup>) => {
    return {
      onClick: () => {
        if (column.key) {
          setRecGroupQueryInfo((info) => {
            return {
              ...info,
              sortType: column.key as string,
              sortDirection: onSortAntd(info.sortDirection),
            };
          });
        }
      },
    };
  };
  const columns: TableColumnsType<RecorderGroup> = [
    {
      title: "Name",
      sorter: true, // 정렬 활성화,
      sortOrder: changeSortOrderAntd(recGroupQueryInfo, "name"),
      onHeaderCell: onHeaderCell,
      key: "name",
      defaultSortOrder: "ascend",
      render: (row) => row.name,
    },
    {
      title: "Host",
      sorter: true,
      sortOrder: changeSortOrderAntd(recGroupQueryInfo, "createBy"),
      onHeaderCell: onHeaderCell,
      key: "createBy",
      render: (value, row) => (
        <s.Row>
          <Text fontSize={14} isDeleted={!row.hostValidation}>
            {row.username}
          </Text>
          <mediaQuery.Default>
            <Text
              color={COLORS.BUTTONS1}
              fontSize={13}
              isDeleted={!row.hostValidation}
            >
              {row.hostValidation
                ? `(${row.email})`
                : `(${row.email}) - Deactivated User`}
            </Text>
          </mediaQuery.Default>
        </s.Row>
      ),
      ellipsis: true,
    },
    {
      title: "Recorders",
      render: (value, row) => row.recorderCount,
      align: "center",
      width: 100,
    },
    {
      title: "Description",
      sorter: true,
      sortOrder: changeSortOrderAntd(recGroupQueryInfo, "description"),
      onHeaderCell: onHeaderCell,
      key: "description",
      render: (value, row) => row.description,
      responsive: ["xl"],
      width: 250,
      ellipsis: true,
    },
    // edit 버튼
    {
      title: "",
      width: 60,
      render: (value, row) => (
        <RBACWrapper requiredPermissions="manageRecordersAndGroups">
          <s.Row className="table-button-wrapper">
            <span className="row-click-button">
              <TableButton
                label="Edit"
                onClick={() => {
                  setCurrGroup(row);
                  onModal("edit");
                }}
              />
            </span>
          </s.Row>
        </RBACWrapper>
      ),
      align: "right",
    },
  ];

  const onModal = (type: string, row?: RecorderGroup) => {
    if (type === "add") {
      // if (isAddModal) {
      //   queryClient.invalidateQueries("recorderGroup");
      // }
      setIsAddModal(!isAddModal);
    }
    if (type === "edit") {
      // if (isEditModal) {
      //   queryClient.invalidateQueries("recorderGroup");
      // }
      setIsEditModal(!isEditModal);
    }
    //queryClient.invalidateQueries("recorderGroup");
    refetch();
  };

  const onClickEdit = (row: RecorderGroup) => {
    if (isCheckPermission("manageRecordersAndGroups", selectedAccount)) {
      setCurrGroup(row);
      onModal("edit");
    }
  };

  const { error, refetch } = useQuery(
    ["recorderGroup", recGroupQueryInfo],
    () =>
      getRecorderGroupList({
        payload: recGroupQueryInfo,
        accountId: selectedAccount.accountId,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: RecorderGroupListResponse) => {
        if (res.result === undefined) {
          setRecGroup([]);
          setListTotal(0);
          setStatus({
            totalTitle: "Total Groups",
            totalValue: 0,
          });
          setRecGroupQueryInfo((info) => {
            return {
              ...initialQueryInfo,
            };
          });
          return;
        }
        setRecGroup(res.result);
        setListTotal(res.result.length);
        setStatus({
          totalTitle: "Total Groups",
          totalValue: res.page.total,
        });
        setRecGroupQueryInfo((info) => {
          return {
            ...info,
            ...res.page,
          };
        });
      },
      onError: (e: any) => {
        setRecGroup([]);
        setListTotal(0);
        setStatus({
          totalTitle: "Total Groups",
          totalValue: 0,
        });
        setRecGroupQueryInfo((info) => {
          return {
            ...initialQueryInfo,
          };
        });
      },
      onSettled: (e: any) => {
        setPending(false);
      },
    }
  );

  const onChangePage = useCallback(
    (page: number, totalRows: number) => {
      console.log(page, totalRows);
      if (page !== recGroupQueryInfo.pageNum + 1) {
        queryNavigate(navigate, location.pathname, {
          pageLimit: recGroupQueryInfo.pageLimit,
          pageNum: page - 1,
          keyword: recGroupQueryInfo.keyword as string,
          sortType: recGroupQueryInfo.sortType,
          sortDirection: recGroupQueryInfo.sortDirection as SORT_DIRECTION,
        });
        setPending(true);
        // setRecGroupQueryInfo((info) => {
        //   return { ...info, pageNum: page as number };
        // });
      }
    },
    [
      location.pathname,
      navigate,
      recGroupQueryInfo.keyword,
      recGroupQueryInfo.pageLimit,
      recGroupQueryInfo.pageNum,
      recGroupQueryInfo.sortDirection,
      recGroupQueryInfo.sortType,
    ]
  );

  const onClickSearch = useCallback(
    (keyword: string) => {
      if (recGroupQueryInfo.keyword !== keyword) {
        queryNavigate(navigate, location.pathname, {
          pageLimit: recGroupQueryInfo.pageLimit,
          pageNum: 0,
          keyword: keyword,
          sortType: recGroupQueryInfo.sortType,
          sortDirection: recGroupQueryInfo.sortDirection as SORT_DIRECTION,
        });
      } else {
        refetch();
      }
      setPending(true);
      // setRecGroupQueryInfo((query) => {
      //   return {
      //     ...query,
      //     keyword,
      //   };
      // });
      // refetch();
    },
    [
      location.pathname,
      navigate,
      recGroupQueryInfo.keyword,
      recGroupQueryInfo.pageLimit,
      recGroupQueryInfo.sortDirection,
      recGroupQueryInfo.sortType,
      refetch,
    ]
  );

  return (
    <GroupPresenter
      onModal={onModal}
      isAddModal={isAddModal}
      isEditModal={isEditModal}
      columns={columns}
      status={status}
      data={recGroup}
      selectGroup={currGroup}
      onClickEdit={onClickEdit}
      paginationInfo={recGroupQueryInfo}
      totalRows={listTotal}
      onChangePage={onChangePage}
      onClickSearch={onClickSearch}
      isRowClickEditable={isRowClickEditable}
      initialPending={pending}
    />
  );
}
