import { SimpleFileViewerModal } from "components/templates/modal/simpleFileViewer/SimpleFileViewerModal";
import GeneralAlertModal from "components/templates/modal/small/generalAlertModal/GeneralAlertModal";
import { useState } from "react";
import styled from "styled-components";
import { COLORS } from "styles/colors";

export const HELP_PDF_DEREGISTER = 'https://mydw-resource-front.s3.us-west-2.amazonaws.com/help_pdf/Spectrum+Deregister.pdf';

type DeregisterHelpType = {
  onModal: () => void;
}

export const DeregisterHelp = ({ onModal }: DeregisterHelpType): JSX.Element => {
  const [isHelpPdf, setIsHelpPdf] = useState<boolean>(false);
  const messageContent: JSX.Element = (
    <div>Spectrum has been removed from myDW.<br />
      myDW Plugin for the Spectrum system may remain registered.<br />
      It is recommended to click <MessageLink onClick={() => setIsHelpPdf(true)}>Help</MessageLink> to clear the plugin.
    </div>
  );
  return (
    <>
    {!isHelpPdf && <GeneralAlertModal text={messageContent} onModal={onModal} />}
    {isHelpPdf && <SimpleFileViewerModal fileUrl={HELP_PDF_DEREGISTER} onModal={onModal} fileType="pdf" />}
    </>
  );
}

const MessageLink = styled.span`
  text-decoration: underline;
  color: ${COLORS.RED};
  cursor: pointer;
`;