import {
  Location,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { HistoryDetailAlert } from "api/interfaces/alertInterface.interface";
import TableColumnFilterTitle from "components/blocks/table/TableColumnFilterTitle";
import { substractTime } from "utils/functions";
import TableButton from "components/atoms/buttons/TableButton";
import { useCallback, useEffect, useState } from "react";
import { getAlertHistoryListDetail } from "api/alertAPI";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useQuery } from "react-query";
import {
  BasePagination,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import { getRecorderTimeFormat } from "utils/timeUtil";
import { notify } from "components/atoms/notification/Notification";
import moment from "moment";
import {
  AlertCategoryTypeFilter,
  AlertStatusTypeFilter,
  displayAlert,
  navigateAlertDetailPage,
} from "utils/AlertUtil";
import { useIntl } from "react-intl";
import * as mediaQuery from "components/MediaQuery";
import { queryNavigate } from "utils/MenuUtil";
import { StatusTxt } from "pages/alert/AlertStyled";
import { TableColumnsType } from "antd";
import {
  changeSortOrderAntd,
  CommonTable,
  onSortAntd,
} from "components/atoms/table/AntdTable";
import { ColumnType } from "antd/es/table";

type Props = {};

export default function AlertHistoryDetail(props: Props): JSX.Element {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location: Location = useLocation();

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const {
    alertHistoryDetailQueryInfo,
    setAlertHistoryDetailQueryInfo,
    listTotal,
    setListTotal,
    isPending,
    setIsPending,
    recorderId,
  } = useOutletContext<any>();

  const [data, setData] = useState<HistoryDetailAlert[]>([]);

  const alertDetailQuery = useQuery(
    [
      "alertHistoryDetail",
      alertHistoryDetailQueryInfo,
      selectedAccount.accountId,
      recorderId,
    ],
    () =>
      getAlertHistoryListDetail({
        accountId: selectedAccount.accountId,
        recorderId: recorderId as string,
        payload: alertHistoryDetailQueryInfo,
      }),
    {
      retry: 0,
      enabled: recorderId !== undefined && selectedAccount.accountId !== "",
      onSuccess: (res: any) => {
        // console.log(res.result, 5645465);
        if (
          res.error !== undefined &&
          res.error === 0 &&
          res.result !== undefined
        ) {
          setData(res.result.alerts);
          setListTotal(res.page.total);
        } else {
          setData([]);
          setListTotal(0);
        }
      },
      onError: (e: any) => {
        setData([]);
        setListTotal(0);
        notify(
          "error",
          intl.formatMessage({
            id: "label.alertHistory.notify.search.fail",
            defaultMessage: "Alert history search error.",
          })
        );
      },
      onSettled: (e: any) => {
        setIsPending(false);
      },
    }
  );

  const onClickAlertCategoryType = useCallback(
    (value: string | undefined) => {
      if (value !== undefined) {
        if (value === "all") {
          if (alertHistoryDetailQueryInfo.category !== null) {
            queryNavigate(navigate, location.pathname, {
              ...(recorderId !== null && { recorderId }),
              pageLimit: alertHistoryDetailQueryInfo.pageLimit,
              pageNum: 0,
              keyword: alertHistoryDetailQueryInfo.keyword as string,
              sortType: alertHistoryDetailQueryInfo.sortType,
              sortDirection:
                alertHistoryDetailQueryInfo.sortDirection as SORT_DIRECTION,
              category: null,
              status: alertHistoryDetailQueryInfo.status,
            });
          } else {
            alertDetailQuery.refetch();
          }
          setIsPending(true);
          // setAlertHistoryDetailQueryInfo((info) => {
          //   return { ...info, category: null, pageNum: 0 };
          // });
        } else {
          if (alertHistoryDetailQueryInfo.category !== value) {
            queryNavigate(navigate, location.pathname, {
              ...(recorderId !== null && { recorderId }),
              pageLimit: alertHistoryDetailQueryInfo.pageLimit,
              pageNum: 0,
              keyword: alertHistoryDetailQueryInfo.keyword as string,
              sortType: alertHistoryDetailQueryInfo.sortType,
              sortDirection:
                alertHistoryDetailQueryInfo.sortDirection as SORT_DIRECTION,
              category: value,
              status: alertHistoryDetailQueryInfo.status,
            });
          } else {
            alertDetailQuery.refetch();
          }
          setIsPending(true);
          // setAlertHistoryDetailQueryInfo((info) => {
          //   return { ...info, category: value as string, pageNum: 0 };
          // });
        }
      }
    },
    [
      alertDetailQuery,
      alertHistoryDetailQueryInfo.category,
      alertHistoryDetailQueryInfo.keyword,
      alertHistoryDetailQueryInfo.pageLimit,
      alertHistoryDetailQueryInfo.sortDirection,
      alertHistoryDetailQueryInfo.sortType,
      alertHistoryDetailQueryInfo.status,
      location.pathname,
      navigate,
    ]
  );

  const onClickAlertStatusType = useCallback(
    (value: string | undefined) => {
      if (value !== undefined) {
        if (value === "all") {
          if (alertHistoryDetailQueryInfo.status !== null) {
            queryNavigate(navigate, location.pathname, {
              ...(recorderId !== null && { recorderId }),
              pageLimit: alertHistoryDetailQueryInfo.pageLimit,
              pageNum: 0,
              keyword: alertHistoryDetailQueryInfo.keyword as string,
              sortType: alertHistoryDetailQueryInfo.sortType,
              sortDirection:
                alertHistoryDetailQueryInfo.sortDirection as SORT_DIRECTION,
              category: alertHistoryDetailQueryInfo.category,
              status: null,
            });
          } else {
            alertDetailQuery.refetch();
          }
          setIsPending(true);
          // setAlertHistoryDetailQueryInfo((info) => {
          //   return { ...info, status: null, pageNum: 0 };
          // });
        } else {
          if (alertHistoryDetailQueryInfo.status !== value) {
            queryNavigate(navigate, location.pathname, {
              ...(recorderId !== null && { recorderId }),
              pageLimit: alertHistoryDetailQueryInfo.pageLimit,
              pageNum: 0,
              keyword: alertHistoryDetailQueryInfo.keyword as string,
              sortType: alertHistoryDetailQueryInfo.sortType,
              sortDirection:
                alertHistoryDetailQueryInfo.sortDirection as SORT_DIRECTION,
              category: alertHistoryDetailQueryInfo.category,
              status: value,
            });
          } else {
            alertDetailQuery.refetch();
          }
          setIsPending(true);
          // setAlertHistoryDetailQueryInfo((info) => {
          //   return { ...info, status: value as string, pageNum: 0 };
          // });
        }
      }
    },
    [
      alertDetailQuery,
      alertHistoryDetailQueryInfo.category,
      alertHistoryDetailQueryInfo.keyword,
      alertHistoryDetailQueryInfo.pageLimit,
      alertHistoryDetailQueryInfo.sortDirection,
      alertHistoryDetailQueryInfo.sortType,
      alertHistoryDetailQueryInfo.status,
      location.pathname,
      navigate,
    ]
  );
  const onHeaderCell = (column: ColumnType<HistoryDetailAlert>) => {
    return {
      onClick: () => {
        if (column.key) {
          setAlertHistoryDetailQueryInfo((info: BasePagination) => {
            return {
              ...info,
              sortType: column.key as string,
              sortDirection: onSortAntd(info.sortDirection),
            };
          });
        }
      },
    };
  };
  const detailColumns: TableColumnsType<HistoryDetailAlert> = [
    {
      title: (
        <TableColumnFilterTitle
          title="Category"
          filters={AlertCategoryTypeFilter}
          onClick={onClickAlertCategoryType}
        />
      ),
      render: (value, row) => row.category,
      width: 100,
    },
    {
      title: "Rule Name",
      render: (value, row) => row.ruleName,
      responsive: ["md"],
    },
    {
      title: (
        <TableColumnFilterTitle
          title="Status"
          filters={AlertStatusTypeFilter}
          onClick={onClickAlertStatusType}
        />
      ),
      render: (value, row) => (
        <StatusTxt string={row.status.toUpperCase()}>{row.status}</StatusTxt>
      ),
    },
    {
      title: "Alert",
      render: (value, row) => displayAlert(row),
      responsive: ["md"],
    },
    {
      title: "Trigger Time",
      key: "alertTrigger",
      render: (value, row) =>
        row.alertTriggered !== undefined
          ? getRecorderTimeFormat(
              row.alertTriggered,
              selectedAccount,
              row.timezone,
              true
            )
          : "",
      width: 200,
      sortOrder: changeSortOrderAntd(
        alertHistoryDetailQueryInfo,
        "alertTrigger"
      ),
      sorter: true,
      onHeaderCell: onHeaderCell,
      defaultSortOrder: "descend",
    },
    {
      key: "alertClose",
      title: "Closed Time",
      render: (value, row) =>
        row.closedDate !== undefined
          ? getRecorderTimeFormat(
              row.closedDate,
              selectedAccount,
              row.timezone,
              true
            )
          : "",
      responsive: ["lg"],
    },
    {
      title: "Duration",
      render: (value, row) =>
        row.status === "CLOSED" || row.status === "Closed"
          ? substractTime(row.alertTriggered, row.closedDate)
          : substractTime(row.alertTriggered, moment()),
      responsive: ["xxl"],
      width: 100,
    },
    {
      title: "Resolved By",
      render: (value, row) =>
        row.resolvedUsername ? row.resolvedUsername : "",
      responsive: ["xl"],
    },
    // 마지막 버튼
    {
      title: "",
      render: (value, row) => (
        <span className="row-click-button table-button-wrapper">
          <TableButton label="View Alert" onClick={() => onRowClick(row)} />
        </span>
      ),
      align: "right",
      width: 100,
    },
  ];
  const onRowClick = (row: HistoryDetailAlert) => {
    navigateAlertDetailPage({
      location,
      navigate,
      recorderId: recorderId ?? "",
      historyId: row.alertHistoryId,
      alertType: row.alertType,
    });
  };

  const onChangePage = useCallback(
    (page: number, totalRows: number) => {
      if (page !== alertHistoryDetailQueryInfo.pageNum + 1) {
        queryNavigate(navigate, location.pathname, {
          ...(recorderId !== null && { recorderId }),
          pageLimit: alertHistoryDetailQueryInfo.pageLimit,
          pageNum: page - 1,
          keyword: alertHistoryDetailQueryInfo.keyword as string,
          sortType: alertHistoryDetailQueryInfo.sortType,
          sortDirection:
            alertHistoryDetailQueryInfo.sortDirection as SORT_DIRECTION,
          category: alertHistoryDetailQueryInfo.category,
          status: alertHistoryDetailQueryInfo.status,
        });
        setIsPending(true);
        // setAlertHistoryDetailQueryInfo((info) => {
        //   return { ...info, pageNum: (page - 1) as number };
        // });
      }
    },
    [
      alertHistoryDetailQueryInfo.category,
      alertHistoryDetailQueryInfo.keyword,
      alertHistoryDetailQueryInfo.pageLimit,
      alertHistoryDetailQueryInfo.pageNum,
      alertHistoryDetailQueryInfo.sortDirection,
      alertHistoryDetailQueryInfo.sortType,
      alertHistoryDetailQueryInfo.status,
      location.pathname,
      navigate,
    ]
  );

  return (
    <CommonTable
      columns={detailColumns}
      dataSource={data}
      queryInfo={alertHistoryDetailQueryInfo}
      onChangePage={onChangePage}
      onRow={(record, rowIndex) => {
        return {
          onClick: (event) => {
            onRowClick(record);
          },
        };
      }}
      loading={isPending}
    />
  );
}
