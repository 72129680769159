import {
  components,
  OptionProps,
  ValueContainerProps,
  GroupBase,
} from "react-select";
import { Option } from "utils/options";

const CustomOption = (props: OptionProps<any, true, GroupBase<any>>) => {
  return (
    <components.Option {...props}>
      <div style={{ display: "flex", alignItems: "flex-start", width: "100%" }}>
        <input
          type="checkbox"
          checked={props.isSelected}
          readOnly
          style={{ marginRight: 5, flexShrink: 0 }}
        />
        <span
          style={{
            flexGrow: 1,
            whiteSpace: "normal",
            wordWrap: "break-word",
          }}
        >
          {props.label}
        </span>
      </div>
    </components.Option>
  );
};

const CustomValueContainer = (
  props: ValueContainerProps<any, true, GroupBase<any>>
) => {
  const [values, input] = props.children as any;

  let displayValue = values;

  if (Array.isArray(values)) {
    displayValue =
      values.length === 1
        ? `${values[0].props.data.label}`
        : `${values[0].props.data.label} +${values.length - 1}`;
  }

  return (
    <components.ValueContainer {...props}>
      {displayValue}
      {input}
    </components.ValueContainer>
  );
};

export { CustomOption, CustomValueContainer };
