import { Action, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProfileAccountInfoFromServer } from "api/interfaces/accountInterface.interface";
import { UserInfo } from "api/interfaces/userInterface.interface";

interface StompState {
  connected: boolean,
  currentAccount: ProfileAccountInfoFromServer | null,
  recorderListUpdated: Date | null,
  userPermissionUpdated: Date | null,
}

const initialState: StompState = {
  connected: false,
  currentAccount: null,
  recorderListUpdated: null,
  userPermissionUpdated: null,
}

const stompSlice = createSlice({
  name: 'stomp',
  initialState,
  reducers: {
    setConnected: (state) => {
      state.connected = true;
      return state;
    },
    setDisconnected: (state) => {
      state.connected = false;
      return state;
    },
    setCurrentAccount: (state, action) => {
      state.currentAccount = action.payload;
      return state;
    },
    recorderListUpdated: (state, action) => {
      if (!state.currentAccount
        || action.payload.accountNumber !== state.currentAccount.accountNumber) {
        return state;
      }
      state.recorderListUpdated = new Date();
      return state;
    },
    userGroupUpdated: (state, action) => {
      if (!state.currentAccount) {
        state.userPermissionUpdated = new Date();
        return state;
      }
      if (action.payload.accountNumber !== state.currentAccount.accountNumber) {
        return state;
      }
      state.userPermissionUpdated = new Date();
      return state;
    },
    userUpdated: (state, action) => {
      const isCurrentAccount = action.payload.accounts.some((account: { accountId: string, accountName: string, accountNumber: string}) => {
        return account.accountNumber === state.currentAccount?.accountNumber;
      });
      if (!isCurrentAccount) {
        return state;
      }
      state.userPermissionUpdated = new Date();
      return state;
    }
  }
});

export const {
  setConnected,
  setDisconnected,
  setCurrentAccount,
  recorderListUpdated,
  userGroupUpdated,
  userUpdated,
} = stompSlice.actions;
export const stompReducer = stompSlice.reducer;

export const connectStomp = (user: UserInfo): PayloadAction<UserInfo> => ({ type: 'connect', payload: user });
export const disconnectStomp = (): Action => ({ type: 'disconnect' });