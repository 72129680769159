import { DashboardData } from "api/interfaces/dashboardInterface.interface"
import { BiMailSend, BiMessageRoundedDots } from "react-icons/bi"
import styled from "styled-components"
import { ALERT_CHART_COLORS } from "styles/colors"
import { SendEmailModal } from "./SendEmailModal"
import { useState } from "react"
import { SendSMSModal } from "./SendSMSModal"


// TODO: Assignee from 'alertInterface.interface.ts'
export interface Assignee {
  name: string,
  email: string,
  phone?: string
}

const getAssignee = (recorder: DashboardData): Assignee | null => {
  if (recorder.critical
    && recorder.critical.length > 0
    && recorder.critical[0].assigneeName
    && recorder.critical[0].assigneeEmail) {
    return {
      name: recorder.critical[0].assigneeName,
      email: recorder.critical[0].assigneeEmail,
      phone: recorder.critical[0].assigneePhone,
    }
  }

  if (recorder.alerts
    && recorder.alerts.length > 0
    && recorder.alerts[0].assigneeName
    && recorder.alerts[0].assigneeEmail) {
    return {
      name: recorder.alerts[0].assigneeName,
      email: recorder.alerts[0].assigneeEmail,
      phone: recorder.alerts[0].assigneePhone,
    }
  }
  return null;
}


type AssigneeInfoType = {
  recorder: DashboardData;
}

export const AssigneeInfo = ({ recorder }: AssigneeInfoType): JSX.Element => {
  const [isSendEmail, setIsSendEmail] = useState<boolean>(false);
  const [isSendSMS, setIsSendSMS] = useState<boolean>(false);
  const assignee = getAssignee(recorder);

  if (!assignee) {
    return <></>;
  }

  const onClickSendEmail = (e: any) => {
    e.stopPropagation();
    setIsSendEmail(true);
  }

  const onClickSendSMS = (e: any) => {
    e.stopPropagation();
    setIsSendSMS(true);
  }

  return (<>
    <Container>
      <span className="name">{assignee.name}</span>
      {assignee.email && (
        <button onClick={onClickSendEmail} className="email">
          <BiMailSend size={16} />
        </button>
      )}
      {assignee.phone && (
        <button onClick={onClickSendSMS} className="phone">
          <BiMessageRoundedDots size={16} />
        </button>
      )}
    </Container>
    {isSendEmail && assignee.email && (
      <SendEmailModal
        assignee={assignee}
        recorderId={recorder.recorderId}
        onModal={() => setIsSendEmail(false)}
      />
    )}
    {isSendSMS && assignee.phone &&  (
      <SendSMSModal
        assignee={assignee}
        recorderId={recorder.recorderId}
        onModal={() => setIsSendSMS(false)}
      />
    )}
    </>);
}

const Container = styled.span`
  display: flex;
  width: 100%;
  align-items: center;
  color: ${ALERT_CHART_COLORS.ASSIGNED};

  .name {
    margin-right: 6px; 
    font-weight: 400;
  }

  & .email {
    margin-right: 3px;
  }

  & button {
    display: flex;
    align-items: center;
    color: ${ALERT_CHART_COLORS.ASSIGNED};
    cursor: pointer;
  }
`;
