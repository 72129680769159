import styled from "styled-components";
import * as mediaQuery from "components/MediaQuery";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";
import { motion } from "framer-motion";
import { LEVEL_TYPE } from "api/interfaces/commonInterface.interface";

export const Container = styled.div<{ length: number | undefined }>`
  position: relative;
  /* max-width: 170px; */

  ${({ length }) =>
    length === 0 &&
    `
    display: none;
  `}
`;

export const StatusItem = styled.div`
  ${FLEX.FlexCenterCenter};
  gap: 4px;
  border-radius: 16px;
  padding: 6px;
  font-size: ${calcRem(13)};
  color: ${({ theme }) => theme.COLORS.WHITE};
  white-space: nowrap;
  cursor: pointer;
  height: 30px;
  background-color: ${({ theme }) => theme.COLORS.FILTERITEM};
  user-select: none;

  button {
    width: 10px;
    ${FLEX.FlexCenterCenter};
    color: ${({ theme }) => theme.COLORS.WHITE};
  }

  ${mediaQuery.isMobile} {
    border-radius: 6px;
  }
`;

export const MissingIcon = styled.p`
  border-radius: 50%;
  border: 1px solid ${COLORS.PROGRESSBAR};
  background-color: ${COLORS.WHITE};
  ${FLEX.FlexCenterCenter};
  width: 20px;
  height: 20px;
  margin-right: 2px;

  > img {
    width: 18px;
    height: 18px;
  }
`;

/**
 * Missing List
 */

export const MissingWrapper = styled.div`
  position: relative;

  .input_checkbox {
    width: 16px;
    height: 16px;
  }
`;

export const ListContainer = styled(motion.div)`
  /* position: absolute;
  right: 0;
  top: 0; */
  background-color: ${({ theme }) => theme.COLORS.PRIMARY2};
  border-radius: 9px;
  box-shadow: 0px 3px 6px ${COLORS.BLACK}16;
  padding: 10px 16px;
  /* margin-top: 6px; */
  z-index: 899;
  min-width: 300px;
  max-width: 300px;

  ${mediaQuery.isMobile} {
    width: 80dvw;
    max-width: 80dvw;
  }
`;

export const ListTitle = styled.label`
  width: 100%;
  ${FLEX.FlexStartCenter};
  gap: 8px;
  border-bottom: 1px solid #828b9b;
  padding: 10px 0 15px;

  img {
    width: 15px;
  }
`;

export const ListWrapper = styled.ul`
  padding-top: 10px;
  max-height: 120px;
  overflow-y: auto;
  width: 100%;
`;

export const ListItem = styled.li<{ accountLevel: LEVEL_TYPE }>`
  ${FLEX.FlexBetweenCenter};
  width: 100%;
  margin: 8px 0;
  padding-right: 10px;
  cursor: pointer;

  > label {
    width: 100%;
    flex: 1;
    ${FLEX.FlexBetweenStart};
  }

  span {
    ${FLEX.FlexStartCenter};
    gap: 10px;

    > input {
    }
  }
  .recorder-name-wrapper {
    ${FLEX.FlexStartStart};
    /* flex: 1; */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${({ accountLevel }) =>
      accountLevel === LEVEL_TYPE.CP &&
      `
        width:  calc(100% - 110px);
      
      `}

    ${({ accountLevel }) =>
      accountLevel === LEVEL_TYPE.EU &&
      `
        width:  100%;
      
      `}
  }

  .recorder-name {
    width: 100%;

    /* border: 1px solid red; */
    color: ${({ theme }) => theme.COLORS.WHITE};
    ${FLEX.FlexStartStart};
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    > span {
      width: 100%;
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
    }

    .title {
      font-weight: 500;
      line-height: ${calcRem(16)};
    }
    .mergedSystemName {
      font-size: ${calcRem(13)};
    }
  }

  button {
    position: relative;
    flex-shrink: 0;
    max-width: 100px;

    .account-id-label {
      gap: 4px;
      max-width: 100px;
      text-overflow: ellipsis;
      overflow: hidden;

      svg {
        flex-shrink: 0;
      }

      span {
        display: inline-block;
        flex: 1;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
`;

/**
 * Enter Address
 */

export const AddressContainer = styled.div``;

export const AddressWrapper = styled.div`
  padding: 20px 0 10px;
  ${FLEX.FlexStartStart};
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;

export const AddressMapWrapper = styled.div`
  ${FLEX.FlexStartStart};
  flex-direction: column;
  width: 100%;
  height: 150px;
`;

export const ButtonWrapper = styled.div`
  ${FLEX.FlexBetweenCenter};
  width: 100%;
`;
