import styled from "styled-components";
import { FLEX } from "styles/flex";
import * as mediaQuery from "components/MediaQuery";
import { COLORS } from "styles/colors";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  Breadcrumb,
  selectBreadcrumbRoute,
} from "redux/reducers/breadcrumb/breadcrumb";
import { useAuth } from "components/Auth";
import { setAccountSetting } from "redux/reducers/account/accountSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { setMenu } from "redux/reducers/menu/menuSlice";
import { useEffect, useState } from "react";
import { calcRem } from "styles/theme";
import { getAccountInfo } from "utils/AccountUtil";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { menuNavigation } from "utils/MenuUtil";
import { BsChevronRight } from "react-icons/bs";

// 경로 이동 props
export interface RoutesProps {
  name: string;
  routeName?: string;
  accountId?: string;
}
type Props = {
  isSelf?: boolean;
};
function RoutesMark(props: Props): JSX.Element {
  const headerRoutes: RoutesProps[] = useAppSelector(
    (state) => state.breadcrumb.routeItem
  );
  const dispatch = useAppDispatch();
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [breadcrumb, setBreadcrumb] = useState<boolean>(false);

  useEffect(() => {
    if (
      location.pathname.includes("account") ||
      location.pathname.includes("recorder") ||
      location.pathname.includes("billing")
    ) {
      setBreadcrumb(true);
    } else {
      setBreadcrumb(false);
    }
  }, [location]);

  const ChangeAccount = (e: any) => {
    //console.log("ChangeAccount", location);
    const value = e.currentTarget.dataset.id;

    // TODO Breadcrumb선택 시 해당 Account로 이동하는 함수 구현필요.
    getAccountInfo(value).then(function (selectAccount) {
      if (!!selectAccount) {
        dispatch(selectBreadcrumbRoute(value));
        dispatch(setAccountSetting(selectAccount));
        if (selectAccount.accountLevel !== "EU") {
          if (location.pathname.includes("billing")) {
            dispatch(setMenu("Billing"));
            menuNavigation(selectAccount, navigate, "/billing/customeraccount");
            //navigate(`/billing/customeraccount/${selectAccount.accountId}`);
          } else {
            dispatch(setMenu("Organization"));
            // Link 클릭 시 해당 account id를 param으로 전달해야함.
            menuNavigation(selectAccount, navigate, "/account");
            //navigate(`/account/${selectAccount.accountId}`);
          }
        }
      }
    });
  };

  return (
    <RoutesContainer>
      {headerRoutes.length > 0 && breadcrumb && !props.isSelf ? (
        headerRoutes.map((item: RoutesProps, index: number) => (
          <RoutesItem key={index}>
            <button data-id={item.accountId} onClick={ChangeAccount}>
              {item.name}
            </button>
            {index !== headerRoutes.length - 1 && (
              <span className="chevron-right">
                <BsChevronRight size={10} />
              </span>
              // <img src={CHEVRON_RIGHT} alt="chevron right icon" />
            )}
          </RoutesItem>
        ))
      ) : (
        <RoutesItem>
          <button>&nbsp;</button>
        </RoutesItem>
      )}
    </RoutesContainer>
  );
}
const RoutesContainer = styled.ul`
  gap: 7px;
  ${mediaQuery.isDefault} {
    ${FLEX.FlexStartCenter};
  }
  ${mediaQuery.isMobile} {
    ${FLEX.FlexCenterCenter};
    background-color: ${COLORS.PRIMARY3};
    border-bottom: 1px solid ${COLORS.BUTTONS1};
    padding: 5px;
  }
`;

const RoutesItem = styled.li`
  ${FLEX.FlexAroundCenter};
  gap: 4px;
  ${mediaQuery.isDefault} {
    color: ${(props) => props.theme.COLORS.STATUS};

    > button {
      color: ${(props) => props.theme.COLORS.STATUS};
      font-size: ${calcRem(14)};

      &:hover {
        text-decoration: underline;
      }
    }

    * {
      color: ${(props) => props.theme.COLORS.STATUS};
    }
  }

  ${mediaQuery.isMobile} {
    color: ${(props) => props.theme.COLORS.STATUS};
    * {
      color: ${(props) => props.theme.COLORS.STATUS};
    }
  }
`;

export default RoutesMark;
