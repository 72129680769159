import React from "react";
import * as s from "../BillingPaymentStyled";
import { UnderlineText } from "components/atoms/text/Text";
import STARNDARD_ICON from "assets/icons/standard-license.svg";
import PREMIUM_ICON from "assets/icons/premium-license.svg";
import STORAGE_ICON from "assets/icons/storage-license.svg";
import { COLORS } from "styles/colors";
import {
  BillingAccountByLicense,
  BillingLicenses,
} from "api/interfaces/licenseInterface.interface";
import { TableColumnsType } from "antd";
import { PaymentTable } from "components/atoms/table/AntdBillingTable";
import { IoChevronDownOutline, IoChevronUp } from "react-icons/io5";

export interface Props {
  data: BillingLicenses;
  onChangeSort: (type: number) => void;
  onDetail: (accountId: string) => void;
  onCollapsePartItem: () => void;
}

export default function PaymentPartTable(props: Props): JSX.Element {
  const columns = React.useMemo<TableColumnsType<BillingAccountByLicense>>(
    () => [
      {
        dataIndex: "name",
        title: "END USER",
        render: (value) => value,
        width: "40%",
      },
      {
        dataIndex: "qty",
        title: "END USER QTY",
        render: (value) => value,
      },

      {
        dataIndex: "status",
        title: "STATUS",
        render: (value, row) => {
          return (
            <s.StatusCell>
              <li>{row.status}</li>
              {row.accountId !== undefined &&
                row.accountId !== null &&
                row.accountId !== "" && (
                  <li>
                    <UnderlineText
                      fontSize={14}
                      onClick={(e) => {
                        e.stopPropagation();
                        props.onDetail(row.accountId);
                      }}
                    >
                      Details
                    </UnderlineText>
                  </li>
                )}
            </s.StatusCell>
          );
        },
        width: "30%",
      },
      {
        dataIndex: "accountId",
        render: () => "",
      },
    ],
    []
  );

  return (
    <s.TableTitleBox>
      <s.TableTitleItem isCollapsed={props.data.isCollapsed}>
        <s.TableTitle>
          <button
            className="collapse-button"
            onClick={props.onCollapsePartItem}
          >
            {props.data.isCollapsed ? (
              <IoChevronDownOutline />
            ) : (
              <IoChevronUp />
            )}
          </button>
          {props.data.type === "STORAGE" && (
            <img src={STORAGE_ICON} alt={`storage icon`} />
          )}
          {props.data.type === "STANDARD" && (
            <img src={STARNDARD_ICON} alt={`standard icon`} />
          )}
          {props.data.type === "PREMIUM" && (
            <img src={PREMIUM_ICON} alt={`premium icon`} />
          )}
          <span>
            <span className="bold">{props.data.description}</span> (
            {props.data.partNumber})
          </span>
        </s.TableTitle>
        {props.data.isCollapsed && (
          <s.TableTitle>
            <span className="bold">TOTAL QTY:</span>
            <span>{props.data.totalQty}</span>
          </s.TableTitle>
        )}
      </s.TableTitleItem>
      {!props.data.isCollapsed && (
        <s.TableWrapper>
          <PaymentTable columns={columns} dataSource={props.data.accounts} />
        </s.TableWrapper>
      )}
    </s.TableTitleBox>
  );
}
