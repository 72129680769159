import * as mediaQuery from "components/MediaQuery";
import { Modal } from "components/templates/modal/ModalTemplateStyled";
import styled from "styled-components";
import { COLORS, LEVELS } from "styles/colors";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";

export const Row = styled.div`
  ${FLEX.FlexStartCenter};
  gap: 15px;
`;
export const NameLabel = styled(Row)`
  gap: 4px;

  cursor: pointer;

  .org {
    width: 13px;
    height: 13px;

    > svg {
      width: 100%;
      height: 100%;
    }
  }

  > button {
    color: ${({ theme }) => theme.COLORS.WHITE};
    margin-left: 2px;
    ${FLEX.FlexStartCenter}
  }
`;

export const ButtonWrapper = styled(Row)<{ disabled?: boolean }>`
  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.25;
  `}
`;

export const EditCompanyLogo = styled.div`
  width: 100%;

  ${FLEX.FlexCenterCenter};
  background-color: ${({ theme }) => theme.COLORS.SIDEBARBG};
  ${({ theme }) =>
    theme.value === "light" &&
    `
    border: 1px solid #cacaca;
  `}
  position: relative;
  /* margin-top: 10px; */
  img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
  }
  > button {
    position: absolute;
    bottom: 6px;
    right: 6px;
  }
  .company-log-img {
    object-fit: scale-down;
    height: 80%;
  }
  .image-spinner {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 88;
    background-color: rgba(0, 0, 0, 0.5);
    ${FLEX.FlexCenterCenter};

    > div {
      padding-top: 0;
    }
  }
`;

/**
 * SwitchAccount
 */

export const SpinnerContainer = styled.div`
  width: 100%;
  ${FLEX.FlexCenterStart};
  padding: 80px 0;
`;

export const SwitchInner = styled(Modal)`
  .inner {
    width: 98%;
    padding: 20px;
  }
  ${mediaQuery.isDefault} {
    min-width: 800px;
    max-width: 800px;
  }
  ${mediaQuery.isMobile} {
    min-width: 90vw;
    max-width: 90vw;
  }
`;
export const GroupWrapper = styled.div`
  width: 100%;
  padding: 20px 0 40px;
  ${FLEX.FlexStartStart};
  flex-direction: column;
  gap: 28px;
`;

export const AccountGroup = styled.div`
  width: 100%;
  .title {
    color: ${({ theme }) => theme.COLORS.WHITE};
    font-weight: 500;
    margin-bottom: 8px;
  }
  ul {
    ${FLEX.FlexStartCenter};
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
    padding-right: 20px;

    -ms-overflow-style: none;
    ::-webkit-scrollbar {
      display: none;
    }

    ${mediaQuery.isDefault} {
      gap: 10px;
    }
    ${mediaQuery.isMobile} {
      gap: 6px;
    }
  }
  .react-multi-carousel-list {
    position: static;
  }

  .react-multiple-carousel__arrow--left {
    position: fixed;
    left: calc(-6% + 1px);
  }

  .react-multiple-carousel__arrow--right {
    position: fixed;
    right: calc(-6% + 1px);
  }

  .account-group-wrapper {
    position: relative;

    .right_button {
      top: 0px;
      right: -30px;
    }

    .left_button {
      left: -30px;
      top: 0px;
      // background-image: linear-gradient(
      //   -90deg,
      //   transparent,
      //   rgba(0, 0, 0, 0.5)
      // );
    }
  }

  .account-item {
    flex: unset !important;
    width: unset !important;
  }

  .swiper-button-prev {
    background-color: #fff;
    opacity: 0.5;
    padding: 15px 5px;
    border-radius: 20px;
    color: black !important;
  }
  .swiper-button-next {
    background-color: #fff;
    opacity: 0.5;
    padding: 15px 5px;
    border-radius: 20px;
    color: black !important;
  }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-size: 1.1rem !important;
    font-weight: 600 !important;
  }

  .swiper-button-disabled {
    opacity: 0;
  }

  // .swiper-button-prev{
  //   background-color: #fff;
  //   opacity: 0.5;
  //   padding: 15px 5px;
  //   border-radius: 20px;
  //   color: black !important;
  // }
  // .swiper-button-next {
  //   background-color: #fff;
  //   opacity: 0.5;
  //   padding: 15px 5px;
  //   border-radius: 20px;
  //   color: black !important;
  // }

  // .swiper-button-prev:after,
  // .swiper-button-next:after {
  //   font-size: 1.1rem !important;
  //   font-weight: 600 !important;
  // }
`;

export const AccountItem = styled.div<{ level: string }>`
  padding: 12px;
  border-radius: 2px;
  color: ${COLORS.WHITE};
  background-color: ${({ level }) => LEVELS[level]};
  white-space: nowrap;
  cursor: pointer;
`;

export const AccountItemTop = styled.div`
  width: 100%;
  padding-bottom: 16px;

  ${FLEX.FlexBetweenStart};

  .account-info {
    font-size: ${calcRem(15)};
    flex: 1;
    ${FLEX.FlexStartStart};
    flex-direction: column;
    gap: 4px;
    width: 90%;
    max-width: 90%;

    > span {
      width: 100%;
      line-height: ${calcRem(20)};
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .group {
      font-size: ${calcRem(11)};
    }
  }

  .org-name {
    font-weight: 400;
  }
`;

export const AccountNumber = styled(NameLabel)`
  ${FLEX.FlexStartCenter};
  gap: 2px;
  font-size: ${calcRem(12)};
  color: ${COLORS.WHITE};
  padding: 3px;
  background-color: ${COLORS.WHITE}20;

  .org {
    width: 10px;
    height: 10px;
  }

  button {
    ${FLEX.FlexCenterCenter};
    color: ${COLORS.WHITE};
  }
`;

export const AccountItemBottom = styled.div`
  width: 100%;
  ${FLEX.FlexStartCenter};
  gap: 10px;
  padding-top: 10px;
  /* padding-right: 10px; */
  font-size: ${calcRem(10)};
  border-top: 1px solid ${COLORS.WHITE}50;
`;

export const AccountItemScrollButton = styled.button`
  position: absolute;
  width: max-content;
  right: -10px;
  height: 100%;
  //background-image: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.5));
`;
