import * as mediaQuery from "components/MediaQuery";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { CVVPairItem } from "api/interfaces/reportInterface.interface";
import RBACWrapper from "components/blocks/function/RBACWrapper";
import { Text, UnderlineText } from "components/atoms/text/Text";
import { getRecorderTimeFormat, getTimezoneOptionsByOffset, getOffsetInHour, getRecorderTimeFormatWithOffset } from "utils/timeUtil";
import { COLORS } from "styles/colors";
import { useAppSelector } from "redux/hooks";
import styled from "styled-components";
import { FLEX } from "styles/flex";
import { CVVType } from "./interface";
import moment from "moment-timezone";
import Spinner from "components/atoms/loader/Spinner";
import { useState } from "react";

const getIanaTimezone = (timezoneOffset: number): string => {
  const offsetInMin = getOffsetInHour(timezoneOffset);
  const timezoneOptionFound = getTimezoneOptionsByOffset(offsetInMin)[0];
  return timezoneOptionFound ? timezoneOptionFound.value : '';
}


type CvvCameraThumbnailEventInfoProps = {
  pairItem: CVVPairItem,
  onClickCheckNow: () => Promise<void>,
  timezoneOffset: number,
  timezone:string
}

export function CvvCameraThumbnailEventInfo({
  pairItem,
  onClickCheckNow,
  timezoneOffset,
  timezone,
}: CvvCameraThumbnailEventInfoProps) {

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const ianaTimezone = getIanaTimezone(timezoneOffset);

  const thumbnailTimestamp = pairItem ? getRecorderTimeFormat(
    pairItem.eventThumbnail.date,
    selectedAccount,
    timezone,
    true,
    true
  ) : '';

  return (<>
    <mediaQuery.Default>
      <div>
        <ThumbnailTimestamp value={thumbnailTimestamp} />
        <DefaultTitle>
          <CheckNowBtn onClick={onClickCheckNow} />
        </DefaultTitle>
      </div>
    </mediaQuery.Default>

    <mediaQuery.Mobile>
      <MobileTitle>
        <span></span>
        <ThumbnailTimestamp value={thumbnailTimestamp} />
        <CheckNowBtn onClick={onClickCheckNow} />
      </MobileTitle>
    </mediaQuery.Mobile>
  </>)
}



type CvvCameraThumbnailReferenceInfoProps = {
  pairItem: CVVPairItem,
  dayOrNight: CVVType,
  onClickUpdate: () => Promise<void>;
  timezoneOffset: number,
  timezone:string
}

export function CvvCameraThumbnailReferenceInfo({
  pairItem,
  dayOrNight,
  onClickUpdate,
  timezoneOffset,
  timezone
}: CvvCameraThumbnailReferenceInfoProps) {

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  
  // CVV의 시간은 타임존이 적용된 시간이므로 Offset으로 시간이동을 시키면 안됨.
  const thumbnailTimestamp = pairItem ? getRecorderTimeFormat(
    pairItem.referenceThumbnail.date,
    selectedAccount,
    timezone,
    true,
    true
  ) : '';

  return (<>
    <mediaQuery.Default>
      <div>
        <ThumbnailTimestamp value={thumbnailTimestamp} />
        <DefaultTitle>
          <ReferenceNightOrDay dayOrNight={dayOrNight} />
          <UpdateBtn onClickUpdate={onClickUpdate} />
        </DefaultTitle>
      </div>
    </mediaQuery.Default>

    <mediaQuery.Mobile>
      <MobileTitle>
        <ReferenceNightOrDay dayOrNight={dayOrNight} />
        <ThumbnailTimestamp value={thumbnailTimestamp} />
        <UpdateBtn onClickUpdate={onClickUpdate} />
      </MobileTitle>
    </mediaQuery.Mobile>
  </>)
}


type ReferenceNightOrDayProps = {
  dayOrNight: CVVType,
}

const ReferenceNightOrDay = ({ dayOrNight }: ReferenceNightOrDayProps) => {
  if (dayOrNight === 'night') {
    return (
      <Text color="#00A8FF" bold>
        Reference Night
      </Text>
    );
  }
  if (dayOrNight === 'day') {
    return (
      <Text color="#FF9D64" bold>
        Reference Day
      </Text>
    )
  }
  return <></>
}


type ThumbnailTimestampProps = {
  value: string,
}

const ThumbnailTimestamp = ({ value }: ThumbnailTimestampProps) => {
  return (
    <Text className="timestamp">
      {value}
    </Text>
  )
}


type UpdateBtnProps = {
  onClickUpdate: () => Promise<void>;
}

const UpdateBtn = (props: UpdateBtnProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const onClick = async() => {
    setLoading(true);
    props.onClickUpdate()
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    }

  if (loading) {
    return <Spinner size={8} margin={2} inline={true} />;
  }
  return (
    <RBACWrapper requiredPermissions="manageReportRules">
      <UnderlineText
        color={COLORS.BUTTONS1}
        onClick={onClick}
      >
        Update
      </UnderlineText>
    </RBACWrapper>
  )
}

type CheckNowBtnProps = {
  onClick: () => Promise<void>;
}

const CheckNowBtn = (props: CheckNowBtnProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const onClick = async() => {
    setLoading(true);
    props.onClick()
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }
  if (loading) {
    return <Spinner size={8} margin={2} inline={true} />;
  }
  return (
    <RBACWrapper requiredPermissions="manageReportRules">
      <UnderlineText
        color={COLORS.BUTTONS1}
        onClick={onClick}
      >
        Check Now
      </UnderlineText>
    </RBACWrapper>
  )
}


const DefaultTitle = styled.div`
  width: 100%;
  ${FLEX.FlexStartCenter};
  margin-top: 6px;
  gap: 10px;
`;

const MobileTitle = styled.div`
  position: relative;
  width: 100%;
  height: 1.8rem;

  & :nth-child(1) {
    position: absolute;
    left: 0;
    top: 0;
  }

  & :nth-child(2) {
    
    width; 100%;
    text-align: center;
  }

  & : nth-child(3) {
    position: absolute;
    right: 0;
    top: 0;
  }
`;
