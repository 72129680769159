import * as s from "../TableItemStyled";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
  BasePagination,
  LEVEL_TYPE,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import { useIntl } from "react-intl";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import SearchInput from "components/atoms/input/search/SearchInput";
import Type from "components/atoms/text/labels/Type";
import { checkStatus, convertType } from "utils/functions";
import { DashboardChartDetailTable } from "components/atoms/table/AntdTable";
import { TableColumnsType } from "antd";
import {
  postStatInstalledRecorder,
  postWidgetListRecorder,
} from "api/widgetAPI";
import AccountSelects from "../AccountSelects";
import {
  calTickValues,
  changeStatsToBarDatum,
  commonOrgColumn,
  commonPaginationPerPage,
  getAxisBottomValue,
} from "pages/dashboard/dashboard/WidgetContainer";
import {
  GetWidgetCvvStatsResponse,
  WidgetRecorderList,
} from "api/interfaces/widgetInterface.interface";
import DashboardTableTitle, {
  TableTitleProps,
} from "components/blocks/table/DashboardTableTitle";
import { FilterValue, SorterResult } from "antd/es/table/interface";
import { TablePaginationConfig } from "antd/lib";
import { AlertRecorerTypeFilter } from "utils/AlertUtil";
import TypeSelects, { WidgetChart } from "../TypeSelects";
import BarChart, { barKeyName } from "components/atoms/chart/BarChart";
import { BarDatum } from "@nivo/bar";
import { Layout } from "react-grid-layout";
import { TableItemName } from "../TableItemList";
import TableItemHeader from "../TableItemHeader";
import NoDataChart from "components/templates/nopage/NoDataChart";
import { Level } from "components/atoms/level/Level";
import { getLevelFullName } from "api/mappers/accountMapper";

interface Props {
  layout: Layout;
  chartLayout: Layout[];
  onDeleteWidget: (key: TableItemName) => void;
  onLayoutChange: (layout: Layout[]) => void;
}

export default function Recorders(props: Props): JSX.Element {
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const [type, setType] = useState<WidgetChart>("list");

  const [accountIds, setAccountIds] = useState<string[]>([]);
  const [data, setData] = useState<WidgetRecorderList[]>([]);
  const [graphData, setGraphData] = useState<BarDatum[]>([]);
  const initialQueryInfo = {
    total: 0,
    pageNum: 0,
    pageLimit: commonPaginationPerPage,
    keyword: "",
    sortType: "mergedSystemName",
    sortDirection: SORT_DIRECTION.ASC,
    type: null,
  };
  const [recorderQueryInfo, setRecorderQueryInfo] = useState<BasePagination>({
    ...initialQueryInfo,
  });

  const onChangeTable = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter:
      | SorterResult<WidgetRecorderList>
      | SorterResult<WidgetRecorderList>[]
    // extra: TableCurrentDataSource<WidgetRecorderList>
  ) => {
    // console.log(filters, sorter, "onChangeTable");
    if (filters !== null) {
      setRecorderQueryInfo((info) => {
        return {
          ...info,
          type: filters.type && (filters.type[0] as string),
          pageNum: 0,
        };
      });
    }
  };

  const columns: TableColumnsType<WidgetRecorderList> = [
    {
      ...commonOrgColumn(selectedAccount),
      render: (value, row) => (
        <s.ColumnText>
          <Level level={getLevelFullName(LEVEL_TYPE.EU)} />{" "}
          {row.recorders[0].accountName}
        </s.ColumnText>
      ),
    },

    {
      title: "Type",
      dataIndex: "type",
      width: 100,
      render: (value, row) => <Type type={convertType(row)} />,
      filterMultiple: false,
      filters: AlertRecorerTypeFilter.map((fil) => {
        return { text: fil.label, value: fil.value };
      }),
    },
    {
      title: "Title",
      dataIndex: "title",
      render: (value, row) => (
        <DashboardTableTitle {...(row as TableTitleProps)} />
      ),
      // width: `calc(100% - 300px)`,
      ellipsis: true,
    },

    {
      title: "Cameras",
      dataIndex: "cameraCount",
      align: "center",
      width: 90,
    },
  ];

  const getWidgetListRecorder = useQuery(
    ["postWidgetListRecorder", accountIds, recorderQueryInfo],
    () =>
      postWidgetListRecorder({
        accountId: selectedAccount.accountId,
        payload: {
          accountIds: accountIds,
          listDto: recorderQueryInfo,
          type: recorderQueryInfo.type ? recorderQueryInfo.type : "",
        },
      }),
    {
      // enabled: type === "list",
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res.error !== 0 && res.result === undefined) {
          setData([]);
          setRecorderQueryInfo((info) => {
            return { ...info, total: 0 as number, pageNum: 0 };
          });
        } else {
          // console.log(res.result, "postWidgetListRecorder");
          setData(res.result);
          setRecorderQueryInfo((info) => {
            return { ...info, total: res.page.total as number };
          });
        }
      },
      onError: (e: any) => {
        setData([]);
        // setRecorderQueryInfo((info) => {
        //   return { ...info, total: 0 as number, pageNum: 0 };
        // });
      },
    }
  );

  // const getWidgetStatRecorder = useQuery(
  //   ["getWidgetStatRecorder", accountIds],
  //   () =>
  //     postStatInstalledRecorder({
  //       accountId: selectedAccount.accountId,
  //       interval: "daily",
  //       payload: {
  //         accountIds,
  //         keyword: recorderQueryInfo.keyword ? recorderQueryInfo.keyword : "",
  //       },
  //     }),
  //   {
  //     enabled: type === "graph",
  //     retry: 0,
  //     refetchOnWindowFocus: false,
  //     onSuccess: (res: GetWidgetCvvStatsResponse) => {
  //       if (res.error !== 0 && res.result === undefined) {
  //         setGraphData([]);
  //       } else {
  //         // console.log(res.result, "getWidgetStatRecorder");
  //         if (res.result.stats) {
  //           setGraphData(changeStatsToBarDatum(res.result.stats));
  //         } else {
  //           setGraphData([]);
  //         }
  //       }
  //     },
  //     onError: (e: any) => {
  //       setGraphData([]);
  //     },
  //   }
  // );

  const onChangeAccount = (value: string[]) => {
    setRecorderQueryInfo((quer) => {
      return {
        ...quer,
        pageNum: 0,
      };
    });
    setAccountIds(value);
  };
  const onChangeType = (value: WidgetChart) => {
    setType(value);
  };
  const onClickSearch = (keyword: string) => {
    setRecorderQueryInfo((quer) => {
      return {
        ...initialQueryInfo,
        keyword,
      };
    });
  };
  const onChangePage = useCallback(
    (page: number, totalRows: number) => {
      if (page !== recorderQueryInfo.pageNum + 1) {
        setRecorderQueryInfo((info) => {
          return { ...info, pageNum: (page - 1) as number };
        });
      }
    },
    [recorderQueryInfo.pageNum]
  );

  useEffect(() => {
    if (props.layout.h >= 2) {
      setRecorderQueryInfo((info) => {
        return {
          ...info,
          pageLimit: commonPaginationPerPage * props.layout.h,
        };
      });
    } else {
      setRecorderQueryInfo((info) => {
        return {
          ...info,
          pageLimit: commonPaginationPerPage,
        };
      });
    }
  }, [props.layout.h]);

  // const isError =
  //   (type === "list" && getWidgetListRecorder.isError) ||
  //   (type === "graph" && getWidgetStatRecorder.isError);

  return (
    <>
      <TableItemHeader
        layout={props.layout}
        chartLayout={props.chartLayout}
        onDeleteWidget={props.onDeleteWidget}
        onLayoutChange={props.onLayoutChange}
        isError={getWidgetListRecorder.isError}
      />
      <s.Contents className="drag-cancel-content">
        <s.ContentWrapper>
          <s.ContentHeader>
            <div>
              <AccountSelects onChange={onChangeAccount} value={accountIds} />
              {/* <TypeSelects onChange={onChangeType} value={type} /> */}
            </div>
            <div className="dashboard-search-input">
              <SearchInput onClickSearch={onClickSearch} />
            </div>
          </s.ContentHeader>
          <s.ContentInner>
            {/* {type === "list" && ( */}
            <DashboardChartDetailTable
              dataSource={data}
              columns={columns}
              queryInfo={recorderQueryInfo}
              onChangePage={onChangePage}
              onChange={onChangeTable}
            />
            {/* )} */}
            {/* {type === "graph" && (
              <div className="bar-chart-wrapper">
                {graphData.length === 0 ? (
                  <NoDataChart />
                ) : (
                  <BarChart
                    data={graphData}
                    layout="vertical"
                    margin={{ top: 50, right: 20, bottom: 30, left: 50 }}
                    gridYValues={4}
                    enableGridY={
                      graphData.every((d) => d.barChartKey === 0) ? false : true
                    }
                    legendLabel={(value) => `${value} REC(S)`}
                    axisLeft={{
                      format: (value) => `${value} REC(S)`,
                      tickSize: 0,
                      tickValues: calTickValues(graphData),
                    }}
                    axisBottom={{
                      tickValues: getAxisBottomValue(graphData),
                      tickSize: 7, // 눈금의 길이 설정
                      tickPadding: 1, // 눈금과 레이블 간의 간격 설정
                    }}
                    isInteractive={false}
                    layers={[
                      "grid",
                      "axes",
                      "bars",
                      "markers",
                      "legends",
                      "annotations",
                    ]}
                  />
                )}
              </div>
            )} */}
          </s.ContentInner>
        </s.ContentWrapper>
      </s.Contents>
    </>
  );
}
