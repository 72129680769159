import styled from "styled-components";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";
import { noScrollBar } from "styles/commonStyles";
import DefaultThumbnail from "components/atoms/image/DefaultThumbnail";
import * as mediaQuery from "components/MediaQuery";
import { Hashtag } from "pages/videoshare/VideoShareStyled";

const minHeight = `min-height: calc(100vh - 360px)`;

export const Grid = styled.ul`
  width: 100%;
  max-width: 100%;
  min-width: 0;
  ${minHeight};
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 30px;
  margin: 30px 0 50px;

  ${mediaQuery.isTablet} {
    grid-template-columns: repeat(4, 1fr);
  }
  ${mediaQuery.isMobile} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const HeaderContainer = styled.div`
  background-color: ${({ theme }) => theme.COLORS.PRIMARY3};
  border-radius: 3px;
  padding: 10px 14px;
  ${FLEX.FlexBetweenCenter};

  .select-header {
    width: 100%;
    margin-right: 10px;
  }
`;

export const SearchWrapper = styled.div`
  width: 340px;
  max-width: 340px;
  ${FLEX.FlexEndCenter};
  flex: 1;
`;

export const TitleComponent = styled.h1`
  font-weight: 400;
  font-size: ${calcRem(22)};
  ${FLEX.FlexStartCenter};
  gap: 10px;
  color: ${({ theme }) => theme.COLORS.WHITE};

  .icon {
    width: 22px;

    svg {
      width: 100%;
      path {
        fill: ${({ theme }) => theme.COLORS.WHITE};
      }
    }
  }
`;

export const TitleWrapper = styled.div`
  min-width: 28vw;
`;

export const LeftFilter = styled.ul`
  ${FLEX.FlexStartCenter};
  flex: 1;
  gap: 8px;
`;

export const LeftItem = styled.li<{ isFilter: boolean }>`
  border-radius: 20px;
  padding: 5px 8px;
  cursor: pointer;
  background-color: ${COLORS.WHITE};
  color: ${COLORS.BLACK};
  font-size: ${calcRem(14)};

  ${({ isFilter }) =>
    isFilter &&
    `
      background-color: ${COLORS.BUTTONS2};
      color: ${COLORS.WHITE};
      font-weight: 500;
  `};
`;

export const RightFilter = styled.div`
  flex: 3;
  width: 50%;
  max-width: 70%;
  padding-right: 20px;
  position: relative;
  /* border: 1px solid blue; */
`;
export const RightScroll = styled(LeftFilter)`
  flex: 0;
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  ${noScrollBar};
`;

export const RightItem = styled(LeftItem)`
  white-space: nowrap;
  ${({ isFilter }) =>
    isFilter &&
    `
      background-color: ${COLORS.RED};
      color: ${COLORS.WHITE};
    `};
`;

export const RightScrollButton = styled.button`
  position: absolute;
  right: 0;
  z-index: 44;
  height: 100%;
  width: 25px;
  text-align: right;

  img {
    height: 50%;
  }
`;

export const LeftScrollButton = styled(RightScrollButton)`
  left: -25px;
  text-align: left;
`;

export const VideoItem = styled.li`
  /* overflow: hidden; */
  color: ${COLORS.WHITE};
  cursor: pointer;
  width: 100%;
  max-width: 100%;
  min-width: 0;

  .time {
    padding: 8px 0;
  }
`;

export const VideoImage = styled(DefaultThumbnail)``;

export const VideoSection = styled.div`
  ${FLEX.FlexBetweenStart};
  padding-top: 16px;

  img {
    width: 20px;
  }
`;

export const VideoTitle = styled.div`
  /* margin-right: 30px; */
  width: 80%;

  .video-title-inner {
    line-height: ${calcRem(20)};
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;

    display: -webkit-box;
    -webkit-line-clamp: 2; // 원하는 라인수
    -webkit-box-orient: vertical;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const Status = styled.span<{ status?: string }>`
  padding: 1px 8px;
  border-radius: 3px;
  font-size: ${calcRem(12)};
  font-weight: 500;
  margin-right: 3px;
  background-color: ${COLORS.WHITE};
  white-space: nowrap;
  color: ${COLORS.WHITE};

  ${({ status }) =>
    status === "open" &&
    `
    background-color: ${COLORS.BUTTONS2};
  `}
  ${({ status }) =>
    status === "closed" &&
    `
    background-color: ${COLORS.BUTTONS1};
  `}
  ${({ status }) =>
    status === "ongoing" &&
    `
    background-color: #ED9C22;
  `}
`;

export const FilterWrapper = styled(LeftFilter)`
  gap: 2px;
  overflow-x: auto;
  width: 100%;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ImageLabel = styled(Status)<{ time?: boolean }>`
  background-color: ${COLORS.BLACK}90;
  position: absolute;
  z-index: 33;
  padding: 2px 4px;

  ${({ time }) =>
    time
      ? `
        bottom: 6px;
        right: 6px;
      `
      : `
        top: 6px;
        left: 6px;
      `}
`;

/**
 * View Video Share
 */

export const ViewContainer = styled.div`
  /* height: 100%; */
`;

export const ViewInner = styled.div`
  ${mediaQuery.isDefault} {
    display: grid;
    grid-template-columns: 1fr 450px;
    gap: 30px;
    margin-bottom: 30px;
    height: 60vh;
    max-height: 60vh;

    > div {
      height: 60vh;
      max-height: 60vh;
    }
  }

  ${mediaQuery.isMobile} {
    ${FLEX.FlexStartStart};
    width: 100%;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 30px;
  }
`;

export const ViewVideoImage = styled.div`
  height: 100%;

  ${mediaQuery.isMobile} {
    width: 100%;
  }
`;

export const TableWrapper = styled.div`
  height: 30%;
`;

export const RightAside = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  min-height: 0;
  ${FLEX.FlexStartStart};
  flex-direction: column;
`;

export const RightWrapper = styled.div`
  ${FLEX.FlexBetweenCenter};
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: calc(100% - 30px);
  flex: 5;
`;

export const Title = styled.h3`
  color: ${COLORS.WHITE};
  font-weight: 500;
  font-size: ${calcRem(20)};
`;

export const SubTitle = styled.div`
  color: ${COLORS.BUTTONS1};
  font-weight: 500;
  margin-bottom: 6px;
  font-size: ${calcRem(12)};
`;

export const ContentsRow = styled.ul`
  ${FLEX.FlexBetweenCenter};

  > li {
    ${FLEX.FlexStartStart};
    flex: 1;
    flex-direction: column;
  }
`;

export const ContentsItem = styled.div`
  /* padding-bottom: 20px; */
  flex: 1;
  width: 100%;
`;

export const Top = styled(ContentsRow)`
  gap: 20px;
  padding: 10px 0;
  width: 100%;
  span {
    flex: 1;
    ${FLEX.FlexStartCenter};
    gap: 16px;
  }
  ${mediaQuery.isMobile} {
    width: 100%;
  }
`;

export const CommentWrapper = styled.ul`
  margin-top: 16px;
  width: 100%;
  flex: 1;
  /* height: 100%;
  max-height: 100%; */
  height: 100%;
  overflow-y: auto;
`;

export const CommentItem = styled.li<{ isWarning?: boolean }>`
  width: 100%;
  padding: 12px 15px;
  height: 58px;
  ${FLEX.FlexStartStart};
  gap: 8px;

  ${({ isWarning }) =>
    isWarning
      ? `
    border: 1px solid ${COLORS.SELECTED};
  `
      : `
    border-top: 1px solid ${COLORS.BUTTONS1};
  `}

  img {
    height: 100%;
  }

  .contents {
    height: 100%;
    ${FLEX.FlexBetweenStart};
    flex: 1;
    width: 10%;
    flex-direction: column;
  }
  .top-info {
    width: 100%;
    ${FLEX.FlexBetweenCenter};
  }
  .user-info {
    ${FLEX.FlexStartStart};
    gap: 8px;
    flex: 1;
  }
`;

export const InputWrapper = styled.div`
  ${FLEX.FlexCenterStart};
  flex: 1;
  max-width: 100%;
  width: 100%;
  gap: 6px;
  padding-top: 8px;
  flex-direction: column;

  > textarea {
    min-height: 50px;
    resize: none;
  }

  .send-bottom {
    ${FLEX.FlexBetweenCenter};
    width: 100%;
  }

  .send-bottom label {
    color: ${COLORS.WHITE};
  }

  .send {
    border-radius: 4px;
    padding: 6px;
    ${FLEX.FlexCenterCenter};
    gap: 5px;
    font-size: ${calcRem(14)};
    color: ${COLORS.WHITE};
    background-color: ${COLORS.BUTTONS2};

    img {
      height: 10px;
    }
  }
`;

export const ButtonWrapper = styled.div`
  ${FLEX.FlexBetweenCenter};
  max-width: 100%;

  .title {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80%;
  }

  button {
    background: none;
    padding: 0;
    width: 20px;

    img {
      height: 20px;
    }
  }

  input {
    padding: 5px;
    flex: 2;
    margin-right: 10px;
  }
`;

export const SendWrapper = styled.div`
  width: 100%;
  flex: 1;
  ${FLEX.FlexEndCenter};
  padding-top: 10px;
`;

export const StatusWrapper = styled.div`
  max-width: 200px;
`;

export const EmailSelectContainer = styled.div`
  width: 100%;
  max-width: 600px;
`;

export const MessageOptional = styled.div`
  padding: 20px 0;

  > div {
    padding-bottom: 8px;
  }
`;

export const AddCheck = styled.div`
  ${FLEX.FlexBetweenCenter};
  margin-top: 20px;

  .date-select {
    flex: 1;
    width: 60%;
    max-width: 60%;
  }
  .select-wrapper {
    padding: 5px;
  }
`;

export const DeleteTextWrapper = styled.div`
  text-align: center;
  padding: 20px 0;
  line-height: ${calcRem(28)};
`;

export const EditTextWrapper = styled.div`
  ${FLEX.FlexStartCenter};
  gap: 10px;
  padding-bottom: 10px;

  .title {
    flex: 1;
  }
  .content {
    flex: 4;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const DeleteButton = styled.button`
  ${FLEX.FlexStartCenter};
  gap: 6px;

  > img {
    width: 14px;
  }
`;

export const HashtagInputWrapper = styled.div`
  width: 100%;
  ${FLEX.FlexBetweenCenter};
  margin-top: 10px;

  > div {
    flex: 9;
  }

  > button {
    flex: 1;
    text-align: right;
  }
`;

export const TagWrapper = styled.div`
  width: 100%;
  ${FLEX.FlexBetweenCenter};
  gap: 20px;
`;

export const TagInner = styled.div`
  width: 100%;
  overflow-y: auto;
  ${FLEX.FlexStartCenter};
`;

export const ShareWrapper = styled.span`
  position: relative;
`;

export const ShareBubble = styled.p`
  position: absolute;
  background-color: ${COLORS.BLACK};
  border-radius: 9px;
  padding: 15px;
  width: 280px;
  max-width: 280px;
  margin-top: 6px;
  right: 0;
  z-index: 43;
`;

export const ShareTitle = styled.div`
  ${FLEX.FlexBetweenCenter};
`;

export const ShareInner = styled.div`
  margin-top: 20px;
`;

export const SnapshotImage = styled.div`
  height: 350px;
  overflow: hidden;
  position: relative;
  border: 0.5px solid ${COLORS.BLACK};

  .capture-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .snapshot-bar {
    position: absolute;
    bottom: 0;
    background-color: ${COLORS.BLACK}60;
    width: 100%;
    padding: 6px;
    color: ${COLORS.WHITE};
    font-size: ${calcRem(14)};
    ${FLEX.FlexBetweenCenter};
  }
`;

export const SendBox = styled.div`
  ${FLEX.FlexStartStart};
  width: 100%;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`;
