import BigPopupTemplate from "components/templates/modal/big/BigPopupTemplate";
import moment from "moment";
import { useRef, useState } from "react";
import { VideoShareUploadCompleted } from "./ScreenRecorderVideoShareComplete";
import { VideoShareUploading } from "./ScreenRecorderVideoShareUploading";
import { ScreenRecorderHookProps } from "./useScreenRecorder";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import { useAuth } from "components/Auth";
import { uploadRecordedVideoToVideoShare } from "api/screenRecorderAPI";
import { VideoShareException } from "./ScreenRecorderVideoShareException";
import { useNavigate } from "react-router-dom";
import { ActionButtons } from "./ScreenRecorderResultActionButtons";

type ScreenRecorderResultType = {
  hookProps: ScreenRecorderHookProps;
  onFinish: () => void;
}

export function ScreenRecorderResult({ hookProps, onFinish  }: ScreenRecorderResultType) {
  const auth = useAuth();
  const navigate = useNavigate();
  const { resultBlob: blob, resultBlobUrl: blobUrl, durationInMs, startedAt, fileExtension } = hookProps;
  const downloadRef = useRef<HTMLAnchorElement>(null);
  const [isInProgress, setIsInProgress] = useState<boolean>(false);
  const [isVideoShareUploading, setIsVideoShareUploading] = useState<boolean>(false);
  const [isVideoShareUploaded, setIsVideoShareUploaded] = useState<boolean>(false);
  const [videoId, setVideoId] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);
  const account: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  if (!blobUrl || !blob) {
    return (
      <BigPopupTemplate title="Recording Result" onModal={onFinish}>
        <p>Something went wrong - no blob</p>
      </BigPopupTemplate>
    )
  }
  
  const startedAtInFormat = moment(startedAt).format('MM_DD_YYYY-h_mm_ssa').toUpperCase();
  const fileName =`Recorded-${startedAtInFormat}.${fileExtension}`;

  function download() {
    if (!downloadRef.current) {
      return;
    }
    downloadRef.current.click();
  }

  function uploadToVideoShare() {
    if (!blob) {
      return;
    }
    setIsInProgress(true);
    setIsVideoShareUploading(true);

    const payload = {
      accountId: account.accountId,
      userEmail: auth.user.email,
      title: `Recorded Screen ${startedAtInFormat}`,
      fileName: fileName,
      startTimeMs: (startedAt || new Date()).valueOf(),
      durationMs: durationInMs,
      description: "This video was recorded on the user's PC."
    }

    uploadRecordedVideoToVideoShare(blob, payload)
      .then((res) => {
        setIsVideoShareUploading(false);
        setIsVideoShareUploaded(true);
        setVideoId(res.videoId);
      })
      .catch(err => {
        setIsError(true);
    });
  }

  function sendToTechSupport() {
    const searchParams = new URLSearchParams();
    searchParams.append('bloburl', blobUrl);
    searchParams.append('filename', fileName);
    navigate(`/help/techsupport?${searchParams.toString()}`);
    onFinish();
  }

  function returnToResultView() {
    setIsInProgress(false);
    setIsVideoShareUploading(false);
    setIsVideoShareUploaded(false);
    setIsError(false);
  }

  return (
    <BigPopupTemplate title="Recording Result" onModal={onFinish}>
      <video src={blobUrl} controls autoPlay loop width="600" />
      <a ref={downloadRef} href={blobUrl} download={fileName}></a>
      <ActionButtons
        isInProgress={isInProgress}
        accountLevel={account.accountLevel}
        download={download}
        uploadToVideoShare={uploadToVideoShare}
        sendToTechSupport={sendToTechSupport} />
  
      {isVideoShareUploading && <VideoShareUploading  />}
      {isVideoShareUploaded && <VideoShareUploadCompleted videoId={videoId} onClose={returnToResultView} onFinish={onFinish} />}
      {!!isError && <VideoShareException onClose={returnToResultView} />}
    </BigPopupTemplate>
  )
}