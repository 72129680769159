import styled from "styled-components";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import * as mediaQuery from "components/MediaQuery";
import { NOTICE_HEIGHT } from "components/element/NoticeBar";
import {
  collapsedSidebarWidth,
  discollapsedSidebarWidth,
} from "components/blocks/sidenav/SideNavBarStyled";

export const Container = styled.div<{ noticeLength: number }>`
  width: 100%;
  height: max-content;
  background-color: ${({ theme }) =>
    theme.value === "light" ? "transparent" : COLORS.PRIMARY2};

  ${mediaQuery.isDefault} {
    ${FLEX.FlexEndStart};
    min-height: 100vh;
  }
  ${mediaQuery.isMobile} {
    ${FLEX.FlexStartCenter};
    flex-direction: column;
    position: relative;
    height: ${({ noticeLength }) =>
      `
    calc(100dvh - ${NOTICE_HEIGHT * noticeLength}px);
  `};
    min-height: -webkit-fill-available;
  }
`;

export const ContentsContainer = styled.main<{
  isVideoShare: boolean;
  isOpen: boolean;
  isDashboard: boolean;
}>`
  width: 100%;
  height: 100%;
  position: relative;

  ${mediaQuery.isDefault} {
    ${({ isDashboard, theme }) =>
      isDashboard &&
      `
    background-color: ${theme.value === "light" && "#F1F1F1"}
    `}
  }

  ${({ isVideoShare, isOpen }) =>
    isVideoShare &&
    (isOpen
      ? `width: calc(100vw - ${discollapsedSidebarWidth}px);`
      : `width: calc(100vw - ${collapsedSidebarWidth}px);`)}
`;

export const Layout = styled.div<{
  noticeLength: number;
}>`
  display: flex;
  height: ${({ noticeLength }) => `
    calc(100dvh - ${NOTICE_HEIGHT * noticeLength}px);
  `};
  min-height: -webkit-fill-available;
  position: relative;
  /* direction: ltr; */
  /* max-height: ${({ noticeLength }) => `
    calc(100vh - ${NOTICE_HEIGHT * noticeLength}px);
  `}; */

  .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
    transition-property: none !important;
  }
`;
