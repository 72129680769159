/**
 * @author ydoni
 * @description modal 안 테이블 메뉴 선택 버튼
 */

import styled from "styled-components";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";
import * as mediaQuery from "components/MediaQuery";

export interface Menus {
  label: string;
  value?: boolean;
}

type ModalSubMenuProps = {
  menus: Array<Menus>;
  currentMenu: number; //현재 메뉴 index
  onChange: (index: number) => void;
  blue?: boolean;
  subTitle?: boolean;
  isDisabled?: boolean;
};

function ModalSubMenu({
  menus,
  currentMenu,
  onChange,
  blue,
  subTitle,
  isDisabled,
}: ModalSubMenuProps): JSX.Element {
  return (
    <Container subTitle={subTitle} className="modal-submenu">
      {menus.map((menu, index) => (
        <Item
          className="modal-sub-menu-li"
          key={index}
          isSelected={currentMenu === index}
          onClick={() => onChange(index)}
          blue={blue}
          isDisabled={isDisabled}
        >
          {menu.label}
        </Item>
      ))}
    </Container>
  );
}

const Container = styled.ul<{ subTitle?: boolean }>`
  ${FLEX.FlexStartStart};
  gap: 30px;

  ${mediaQuery.isDefault} {
    ${({ subTitle }) =>
      !subTitle &&
      `
        padding-top: 15px;
        padding-bottom: 6px;
      `}
  }
  ${mediaQuery.isMobile} {
    ${({ subTitle }) =>
      !subTitle &&
      `
        padding: 10px 0;
      `}
  }
`;

const Item = styled.li<{
  isSelected: boolean;
  blue?: boolean;
  isDisabled?: boolean;
}>`
  cursor: pointer;
  ${mediaQuery.isDefault} {
    font-size: ${calcRem(16)};
    padding: 10px 0;
  }

  ${mediaQuery.isMobile} {
    font-size: ${calcRem(14)};
    padding: 6px 0;
  }

  ${({ isSelected, theme }) =>
    isSelected
      ? `
      
        border-bottom: 6px solid;
        color: ${theme.COLORS.WHITE};
        font-weight: 500;
        ${mediaQuery.isMobile} {
          border-width: 6px;
        };
        border-color: ${
          theme.value === "light" ? COLORS.COMPANY : COLORS.BUTTONS2
        }
      `
      : `
        color: ${COLORS.BUTTONS1};
    `};

  ${({ isSelected, blue, theme }) =>
    isSelected &&
    blue &&
    `
        color: ${theme.value === "light" ? COLORS.COMPANY : COLORS.BUTTONS2};

      `};

  ${({ isDisabled }) =>
    isDisabled &&
    `
        color: ${COLORS.BUTTONS1};
        border: none;
      `}
`;

export default ModalSubMenu;
