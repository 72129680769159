import { useCallback, useEffect, useState } from "react";
import { RecordersChildrenListPresenter } from "./ChildrenListPresenter";
import * as s from "../RecordersStyled";
import TableButton from "components/atoms/buttons/TableButton";
import Type from "components/atoms/text/labels/Type";
import ONLINE_ICON from "assets/icons/online.svg";
import OFFLINE_ICON from "assets/icons/offline.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Status from "components/atoms/text/labels/Status";
import {
  BasePagination,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import { useAppSelector } from "redux/hooks";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useQuery, useQueryClient } from "react-query";
import { deleteMergedSystem, getRecorderListDetail } from "api/recorderAPI";
import { convertType, findPopup } from "utils/functions";
import { StatusName, StatusValue } from "components/blocks/status/Status";
import RBACWrapper from "components/blocks/function/RBACWrapper";
import {
  LicenseCount,
  MergedRecorder,
  Recorder,
} from "api/interfaces/recorderInterface.interface";
import { LicenseRowType } from "api/interfaces/billing.interface";
import { isCheckPermission } from "utils/AccountUtil";
import { useIntl } from "react-intl";
import { isNull } from "lodash";
import { queryNavigate } from "utils/MenuUtil";
import { useMutation } from "react-query";
import { notify } from "components/atoms/notification/Notification";
import * as mediaQuery from "components/MediaQuery";
import { Text } from "components/atoms/text/Text";
import { COLORS } from "styles/colors";
import { NeedActionCell } from "../list/NeedActionCell";
import { TableColumnsType } from "antd";
import {
  changeSortOrderAntd,
  onSortAntd,
} from "components/atoms/table/AntdTable";
import { ColumnType } from "antd/es/table";

export interface DataRow {
  id: string;
  recordId: string;
  cloudSystemId: string;
  type: string;
  brand?: string;
  title: string;
  status: string;
  host?: string;
  recorderCount: number;
  spectrumRecoders?: any;
  version: string;
  cameras: number;
  onlineStatusCount: number;
  mergedSystemId: string;
  licenses: LicenseRowType;
  createBy: string;
  createByEmail: string;
  enableEdit?: boolean;
  isMerged?: boolean;
  hasAuthError?: boolean;
  isDetached?: boolean;
  hostValidation: boolean;
  row: MergedRecorder | Recorder;
}

function responseToDataRow(
  recorder: any,
  cloudSystemId: string,
  mergedSystemId: string
): DataRow {
  return {
    id: recorder.recorderId,
    recordId: recorder.recorderId,
    cloudSystemId: cloudSystemId,
    mergedSystemId: mergedSystemId,
    type: recorder.type,
    title: recorder.name,
    status: recorder.onlineStatue ? "online" : "offline",
    host: recorder.installer,
    onlineStatusCount: recorder.onlineStatusCount,
    recorderCount: recorder.recorderCount,
    spectrumRecoders: recorder.maxCameras,
    version: recorder.firmwareVersion,
    cameras: recorder.cameraCount,
    createBy: recorder.createBy,
    createByEmail: recorder.createByEmail,
    isMerged: true,
    hasAuthError: recorder.isAuthError,
    isDetached: recorder.isDetached,
    licenses: {
      count: 0,
      status: "PREPARING",
    },
    hostValidation: recorder.hostValidation,
    row: recorder,
  };
}

export interface MergedSystemInfo {
  selectedMergedSystemId: string;
  selectedMergedSystemName: string;
  selectedMergedSystemVersion: string;
  selectedCloudSystemId: string;
}

const statusInitValue = {
  totalTitle: "Total",
  totalValue: 0,
  items: [
    <>
      <s.Label>
        <img src={ONLINE_ICON} alt="online icon" />
        <StatusName>Online</StatusName>
      </s.Label>
      <StatusValue>0</StatusValue>
    </>,
    <>
      <s.Label>
        <img src={OFFLINE_ICON} alt="offline icon" />
        <StatusName>Offline</StatusName>
      </s.Label>
      <StatusValue>0</StatusValue>
    </>,
  ],
};

const inititalRecorderDetailQueryInfo = {
  total: 0,
  pageNum: 0,
  pageLimit: 25,
  keyword: "",
  sortType: "name",
  sortDirection: SORT_DIRECTION.ASC,
};
export function RecordersChildrenListContainer(): JSX.Element {
  const intl = useIntl();
  const location = useLocation();

  const navigate = useNavigate();
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const queryClient = useQueryClient();
  const [recorder, setRecorder] = useState<DataRow[]>([]);

  const params = useParams();
  const mergedSystemId = params.mergedSystemId as string;
  const [mergedSystemInfo, setMergedSystemInfo] = useState<MergedSystemInfo>({
    selectedMergedSystemId: "",
    selectedMergedSystemName: "",
    selectedMergedSystemVersion: "",
    selectedCloudSystemId: "",
  });

  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);

  const [recorderDetailQueryInfo, setRecorderDetailQueryInfo] =
    useState<BasePagination>({
      ...inititalRecorderDetailQueryInfo,
    });

  const [status, setStatus] = useState(statusInitValue);
  const [licenseCount, setLicenseCount] = useState<LicenseCount>({
    totalCameraCount: 0,
    totalLicensedCameraCount: 0,
  });

  const [isAddModal, setIsAddModal] = useState<boolean>(false);
  const [isEditModal, setIsEditModal] = useState<boolean>(false);
  const [isLicenseModal, setIsLicenseModal] = useState<boolean>(false);
  const [isRemoveLicenseModal, setIsRemoveLicenseModal] =
    useState<boolean>(false);
  const [selectRow, setSelectRow] = useState<DataRow>();

  const [isBubble, setIsBubble] = useState<boolean>(false);
  const [isDeregisterHelp, setIsDeregisterHelp] = useState<boolean>(false);

  useEffect(() => {
    const changeParam = new URLSearchParams(location.search);
    if (changeParam.size > 0) {
      setRecorderDetailQueryInfo((info) => {
        return {
          ...info,
          pageNum: isNull(changeParam.get("pageNum"))
            ? 0
            : Number(changeParam.get("pageNum")),
          pageLimit: isNull(changeParam.get("pageLimit"))
            ? 25
            : Number(changeParam.get("pageLimit")),
          keyword: isNull(changeParam.get("keyword"))
            ? ""
            : (changeParam.get("keyword") as string),
          sortType: isNull(changeParam.get("sortType"))
            ? "name"
            : (changeParam.get("sortType") as string),
          sortDirection: isNull(changeParam.get("sortDirection"))
            ? SORT_DIRECTION.ASC
            : (changeParam.get("sortDirection") as SORT_DIRECTION),
        };
      });
    } else {
      setRecorderDetailQueryInfo((info) => {
        return {
          ...inititalRecorderDetailQueryInfo,
        };
      });
    }
  }, [location]);

   useEffect(() => {
    const interval = setInterval(() => {
      if(findPopup("recorder-polling-popup") == null){
          recorderDetailQuery.refetch();
        } 
      }, 10000);
  
      return () => clearInterval(interval);
    }, []);

  const onHeaderCell = (column: ColumnType<DataRow>) => {
    return {
      onClick: () => {
        if (column.key) {
          setRecorderDetailQueryInfo((info) => {
            return {
              ...info,
              sortType: column.key as string,
              sortDirection: onSortAntd(info.sortDirection),
            };
          });
        }
      },
    };
  };
  const columns: TableColumnsType<DataRow> = [
    {
      title: "Type",
      width: 110,
      render: (value, row) => <Type type={convertType(row)} />,
    },
    {
      title: "Title",
      sorter: true,
      key: "name",
      defaultSortOrder: "ascend",
      sortOrder: changeSortOrderAntd(recorderDetailQueryInfo, "name"),
      onHeaderCell: onHeaderCell,
      render: (value, row) => (
        <s.Row>
          <Status status={row.status} />
          {row.title}
        </s.Row>
      ),
    },
    {
      title: "Host",
      sorter: true,
      sortOrder: changeSortOrderAntd(recorderDetailQueryInfo, "createBy"),
      onHeaderCell: onHeaderCell,
      key: "createBy",
      render: (value, row) => (
        <s.Row>
          <Text fontSize={14} isDeleted={!row.hostValidation}>
            {row.createBy}
          </Text>
          <mediaQuery.Default>
            <Text
              color={COLORS.BUTTONS1}
              fontSize={13}
              isDeleted={!row.hostValidation}
            >
              {row.hostValidation
                ? `(${row.createByEmail})`
                : `(${row.createByEmail}) - (Deactivated User)`}
            </Text>
          </mediaQuery.Default>
        </s.Row>
      ),
      responsive: ["lg"],
    },
    {
      title: "Cameras",
      render: (value, row) => row.cameras,
      align: "center",
      responsive: ["xl"],
      width: 100,
    },
    {
      title: "",
      width: 150,
      render: (value, row) => <NeedActionCell recorder={row} />,
      responsive: ["xl"],
    },
    {
      title: "",
      render: (row) => (
        <RBACWrapper requiredPermissions="manageRecordersAndGroups">
          <s.Row className="table-button-wrapper">
            <span className="row-click-button">
              <TableButton label="Edit" onClick={() => onModal("edit", row)} />
            </span>
          </s.Row>
        </RBACWrapper>
      ),
      align: "right",
      width: 80,
    },
  ];

  const onModal = (type: string, row?: DataRow) => {
    if (type === "add") {
      if (isAddModal) {
        queryClient.invalidateQueries("recorderDetail");
      }
      setIsAddModal(!isAddModal);
    }
    if (type === "edit") {
      setSelectRow(row);
      setIsEditModal(!isEditModal);
    }
    if (type === "license") {
      setIsLicenseModal(!isLicenseModal);
    }
    if (type === "remove") {
      setIsRemoveLicenseModal(!isRemoveLicenseModal);
    }
  };

  const onClickEdit = (row: DataRow) => {
    if (isCheckPermission("manageRecordersAndGroups", selectedAccount)) {
      onModal("edit", row);
    }
  };

  const [pending, setPending] = useState<boolean>(true);

  const initialData = () => {
    setMergedSystemInfo({
      selectedMergedSystemId: "",
      selectedMergedSystemName: "",
      selectedMergedSystemVersion: "",
      selectedCloudSystemId: "",
    });
    setRecorder([]);
    setStatus(statusInitValue);
    setRecorderDetailQueryInfo((info) => {
      return {
        ...inititalRecorderDetailQueryInfo,
      };
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const recorderDetailQuery = useQuery(
    ["recorderDetail", recorderDetailQueryInfo, mergedSystemId],
    () =>
      getRecorderListDetail({
        payload: recorderDetailQueryInfo,
        accountId: selectedAccount.accountId,
        mergedSystemId: mergedSystemId,
      }),
    {
      enabled: true,
      //refetchInterval: 10000,
      refetchOnWindowFocus: false,
      retry: 0,
      onSuccess: (res: any) => {
        if (res.result === undefined || res.error !== 0) {
          initialData();
          return;
        }
        setMergedSystemInfo({
          selectedMergedSystemId: res.result.mergedSystemId,
          selectedMergedSystemName: res.result.mergedSystemName,
          selectedMergedSystemVersion: res.result.firmwareVersion,
          selectedCloudSystemId: res.result.cloudSystemId,
        });

        setRecorder(
          res.result.recorders.map((recorder: any): DataRow => {
            return responseToDataRow(
              recorder,
              res.result.cloudSystemId,
              res.result.mergedSystemId
            );
          })
        );

        setRecorderDetailQueryInfo((info) => {
          return {
            ...info,
            ...res.page,
          };
        });
        setStatus({
          totalTitle: "Total",
          totalValue: res.page.total,
          items: [
            <>
              <s.Label>
                <img src={ONLINE_ICON} alt="online icon" width={16} />
                <StatusName>Online</StatusName>
              </s.Label>
              <StatusValue>{res.result.onlineStatusCount}</StatusValue>
            </>,
            <>
              <s.Label>
                <img src={OFFLINE_ICON} alt="offline icon" width={16} />
                <StatusName>Offline</StatusName>
              </s.Label>
              <StatusValue>
                {res.page.total - res.result.onlineStatusCount}
              </StatusValue>
            </>,
          ],
        });
      },
      onError: (e: any) => {
        initialData();
      },
      onSettled: (e: any) => {
        setPending(false);
      },
    }
  );

  const onChangePage = (page: number, totalRows: number) => {
    if (page !== recorderDetailQueryInfo.pageNum + 1) {
      queryNavigate(
        navigate,
        location.pathname,
        {
          pageLimit: recorderDetailQueryInfo.pageLimit,
          pageNum: page - 1,
          keyword: recorderDetailQueryInfo.keyword as string,
          sortType: recorderDetailQueryInfo.sortType,
          sortDirection:
            recorderDetailQueryInfo.sortDirection as SORT_DIRECTION,
        },
        selectedAccount
      );
      setPending(true);
    }
  };

  const onClickSearch = useCallback(
    (keyword: string) => {
      if (recorderDetailQueryInfo.keyword !== keyword) {
        queryNavigate(
          navigate,
          location.pathname,
          {
            pageLimit: recorderDetailQueryInfo.pageLimit,
            pageNum: 0,
            keyword: keyword,
            sortType: recorderDetailQueryInfo.sortType,
            sortDirection:
              recorderDetailQueryInfo.sortDirection as SORT_DIRECTION,
          },
          selectedAccount
        );
      } else {
        recorderDetailQuery.refetch();
      }
      setPending(true);
    },
    [
      true,
      location.pathname,
      navigate,
      recorderDetailQuery,
      recorderDetailQueryInfo.keyword,
      recorderDetailQueryInfo.pageLimit,
      recorderDetailQueryInfo.sortDirection,
      recorderDetailQueryInfo.sortType,
      selectedAccount,
    ]
  );

  const mutationDeleteSystem = useMutation(deleteMergedSystem, {
    onSuccess: () => {
      notify(
        "success",
        intl.formatMessage({
          id: "label.recorder.notify.deRegistration.success",
          defaultMessage: "System deleted successfully.",
        })
      );
      queryClient.invalidateQueries("recorderDetail");
      if (selectRow && selectRow.type.toUpperCase() === "SPECTRUM") {
        setIsDeregisterHelp(true);
      }
    },
    onError: () => {
      notify(
        "error",
        intl.formatMessage({
          id: "label.recorder.notify.deRegistration.fail",
          defaultMessage: "Failed to delete system.",
        })
      );
    },
    onSettled: () => {
      setIsDeleteConfirm(false);
    },
  });
  const onClickDelete = useCallback(
    (result: boolean) => {
      setIsDeleteConfirm(false);
      if (result) {
        console.log(selectRow?.mergedSystemId);
        mutationDeleteSystem.mutate({
          accountId: selectedAccount.accountId,
          mergedSystemId: selectRow?.mergedSystemId,
        });
      }
    },
    [mutationDeleteSystem, selectedAccount.accountId, selectRow?.mergedSystemId]
  );

  return (
    <RecordersChildrenListPresenter
      onModal={onModal}
      selectRow={selectRow as DataRow}
      data={recorder}
      isAddModal={isAddModal}
      isEditModal={isEditModal}
      isBubble={isBubble}
      licenseCount={licenseCount}
      setIsBubble={setIsBubble}
      columns={columns}
      status={status}
      paginationInfo={recorderDetailQueryInfo}
      onChangePage={onChangePage}
      onClickSearch={onClickSearch}
      onClickEdit={onClickEdit}
      selectedMergedSystemId={mergedSystemInfo.selectedMergedSystemId}
      selectedMergedSystemName={mergedSystemInfo.selectedMergedSystemName}
      selectedMergedSystemVersion={mergedSystemInfo.selectedMergedSystemVersion}
      selectedCloudSystemId={mergedSystemInfo.selectedCloudSystemId}
      initialPending={pending}
      selectedAccount={selectedAccount}
      isDeleteConfirm={isDeleteConfirm}
      onClickDelete={onClickDelete}
      isDeregisterHelp={isDeregisterHelp}
      closeDeregisterHelp={() => setIsDeregisterHelp(false)}
    />
  );
}
