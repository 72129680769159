import ModalHeader from "components/blocks/modalheader/ModalHeader";
import ModalTemplate from "../ModalTemplate";
import * as s from "../ModalTemplateStyled";
import styled from "styled-components";
import { RiAlertFill } from "react-icons/ri";
import { COLORS } from "styles/colors";
import { FileType, SimpleFileViewerModalType } from "./interface";
import ModalHeaderNoTitle from "components/blocks/modalheader/ModalHeaderNoTitle";


const getContent = (fileType: FileType, fileUrl: string) => {
  if (fileType === 'video') {
    return (
      <Video muted playsInline loop controls>
        <source src={fileUrl} />
        Your browser does not support the video tag.
      </Video>
    );
  }
  if (fileType === 'image') {
    return (
      <Image src={fileUrl} />
    );
  }
  if (fileType === 'pdf') {
    return (
      <Pdf>
        <embed
          src={fileUrl}
          type="application/pdf"
        />
      </Pdf>
    )
  }
  return <Image src={fileUrl} />
}

export const SimpleFileViewerModal = ({ title, fileUrl, fileType, fileName, warn, onModal }: SimpleFileViewerModalType) => {
  title = title || fileName || "";
  let className = '';
  if (fileType === 'pdf') {
    className = 'wide';
  }

  return (
    <ModalTemplate>
      <ViewerModal data-key="recorder-polling-popup" id="simple_viewer_popup" className={className}>
        {title && (
          <>
            {warn && <ModalHeader title={title} onModal={onModal} titleIcon={<RiAlertFill color={COLORS.WARNING} size={22} />} />}
            {!warn && <ModalHeader title={title} onModal={onModal} />}
          </>
        )}
        {!title && <ModalHeaderNoTitle onModal={onModal} />}
        <Contents className={className}>
          {getContent(fileType, fileUrl)}
        </Contents>
      </ViewerModal>

    </ModalTemplate>
  );
}

const modalWidePadding = 15;
const modalWideMaxWidth = 1024;

const ViewerModal = styled(s.Modal)`
  max-width: 80vw;
  padding: 30px;

  &.wide {
    max-width: ${modalWideMaxWidth}px;
    padding: ${modalWidePadding}px;
  }
`;

const Contents = styled.div`
  width: 100%;
  margin-top: 24px;

  &.wide {
    margin-top: 0;
  }
`;

const Video = styled.video`
  width: 100%;
`;

const Image = styled.img`
  width: 100%;
`;

const Pdf = styled.div`
  width: 100%;
  height: 100%;
  & embed {
    width: 90vw;
    max-width: ${modalWideMaxWidth - modalWidePadding * 2}px;
    height: 90vh;
  }
`;