import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { LEVEL_TYPE } from "api/interfaces/commonInterface.interface";
import {
  Group,
  UserGroupRecorderTypePermission,
  UserGroupUserPermission,
} from "api/interfaces/userInterface.interface";

export const invertPermissionBooleans = (
  permission: UserGroupUserPermission
): UserGroupUserPermission => {
  return Object.fromEntries(
    Object.entries(permission).map(([key, value]) => [
      key,
      typeof value === "boolean" ? !value : value,
    ])
  ) as UserGroupUserPermission;
};

export const checkAutoApply = ({
  selectedAccount,
  enableAllPermissions,
}: {
  selectedAccount: ProfileAccountInfo;
  enableAllPermissions: boolean;
}): {
  autoApplyAccount: boolean;
  autoApplyRecorder: boolean;
} => {
  if (selectedAccount.accountLevel === LEVEL_TYPE.EU) {
    return {
      autoApplyAccount: false,
      autoApplyRecorder: true,
    };
  }
  return {
    autoApplyAccount: true,
    autoApplyRecorder: false,
  };
};

// admin group 에 속한 user 는 user management 를 제어할 수 있음.
export const checkUserPermission = (isAdminGroup: boolean) => {
  if (isAdminGroup) {
    return {
      checked: {
        enableAllPermissions: true,
        manageUsersAndGroups: true,
        viewUsersAndGroups: true,
      },
      disable: {
        enableAllPermissions: false,
        manageUsersAndGroups: false,
        viewUsersAndGroups: false,
      },
    };
  }

  return {
    checked: {
      enableAllPermissions: false,
      manageUsersAndGroups: false,
      viewUsersAndGroups: false,
    },
    disable: {
      enableAllPermissions: true,
      manageUsersAndGroups: true,
      viewUsersAndGroups: true,
    },
  };
};

export const defaultGroupName = ["administrator", "manager", "viewer"];
export const allDisabledUserPermission: UserGroupUserPermission = {
  enableAllPermissions: true,
  manageAlertRules: true,
  viewAlerts: true,
  manageUsersAndGroups: true,
  viewUsersAndGroups: true,
  manageReportRules: true,
  viewReports: true,
  manageRecordersAndGroups: true,
  viewRecordersAndGroups: true,
  manageAccount: true,
  viewAccount: true,
  view: true,
  manageBillingAndLicenses: true,
  enableDelete: true,
  managePlan: true,
  viewBilling: true,
  suspend: true,
  myOrganization: true,
  videoGuideSetting: true,
  techSupport: true,
  siteNotice: true,
  emails: true,
};

export const defaultDisabledUserPermission = (
  selectedAccount: ProfileAccountInfo
): UserGroupUserPermission => {
  const initialDisable = {
    manageAlertRules: false,
    viewAlerts: false,
    manageReportRules: false,
    viewReports: false,
    manageRecordersAndGroups: false,
    viewRecordersAndGroups: false,
    manageAccount: false,
    viewAccount: false,
    view: false,
    manageBillingAndLicenses: false,
    groupName: "",
    enableDelete: false,
    managePlan: false,
    viewBilling: false,
    suspend: false,
    myOrganization: false,
    videoGuideSetting: false,
    techSupport: false,
    siteNotice: false,
    emails: false,
  };
  // admin group 은 권한 모두 가능
  if (selectedAccount.isAdminGroup) {
    return {
      ...initialDisable,
      ...checkUserPermission(selectedAccount.isAdminGroup).disable,
    };
  }
  // admin group 아닌 수정 가능한 사람은 내가 갖고 있는 권한만 가능하도록
  return {
    ...invertPermissionBooleans(selectedAccount.userPermission),
    ...checkUserPermission(selectedAccount.isAdminGroup).disable,
  };
};

export const defaultCheckedUserPermission = (
  isAdminGroup: boolean,
  checkedUserAdmin: boolean,
  isEdit?: boolean
): Record<LEVEL_TYPE, UserGroupUserPermission> => {
  return {
    [LEVEL_TYPE.DW]: {
      manageAlertRules: false,
      viewAlerts: false,
      manageReportRules: false,
      viewReports: false,
      manageRecordersAndGroups: false,
      viewRecordersAndGroups: false,
      manageAccount: true,
      viewAccount: true,
      view: false,
      manageBillingAndLicenses: false,
      groupName: "",
      enableDelete: true,
      managePlan: true,
      viewBilling: true,
      suspend: true,
      myOrganization: true,
      videoGuideSetting: true,
      techSupport: true,
      siteNotice: true,
      emails: true,
      ...checkUserPermission(isAdminGroup).checked,
    },
    [LEVEL_TYPE.CP]: {
      manageAlertRules: true,
      viewAlerts: true,
      manageReportRules: true,
      viewReports: true,
      manageRecordersAndGroups: false,
      viewRecordersAndGroups: false,
      manageAccount: true,
      viewAccount: true,
      view: false,
      manageBillingAndLicenses: true,
      enableDelete: true,
      managePlan: false,
      viewBilling: false,
      suspend: false,
      myOrganization: false,
      videoGuideSetting: false,
      techSupport: false,
      siteNotice: false,
      emails: false,
      groupName: "",
      ...checkUserPermission(isAdminGroup).checked,
    },
    [LEVEL_TYPE.EU]: {
      manageAlertRules: true,
      viewAlerts: true,
      manageReportRules: true,
      viewReports: true,
      manageRecordersAndGroups: true,
      viewRecordersAndGroups: true,
      manageAccount: false,
      viewAccount: false,
      view: true,
      manageBillingAndLicenses: false,
      enableDelete: true,
      managePlan: false,
      viewBilling: false,
      suspend: false,
      myOrganization: false,
      videoGuideSetting: false,
      techSupport: false,
      siteNotice: false,
      emails: false,
      groupName: "",
      ...checkUserPermission(isAdminGroup).checked,
    },
    [LEVEL_TYPE.INTL]: {
      manageAlertRules: true,
      viewAlerts: true,
      manageReportRules: true,
      viewReports: true,
      manageRecordersAndGroups: true,
      viewRecordersAndGroups: true,
      manageAccount: true,
      viewAccount: true,
      view: true,
      manageBillingAndLicenses: true,
      enableDelete: true,
      managePlan: true,
      viewBilling: true,
      suspend: true,
      myOrganization: true,
      videoGuideSetting: true,
      techSupport: true,
      siteNotice: true,
      emails: true,
      groupName: "",
      ...checkUserPermission(isAdminGroup).checked,
    },
  };
};

export const defaultRoleCheckList: {
  [key: string]: {
    check: UserGroupRecorderTypePermission;
    disable: UserGroupRecorderTypePermission;
  };
} = {
  administrator: {
    check: {
      spectrum: {
        cameraSetting: true,
        viewArchive: true,
        exportArchive: true,
        viewBookmark: true,
        modifyBookmark: true,
        viewEventLog: true,
        cameraUserInput: true,
      },
      vmax: {
        system: true,
        alarm: true,
        shutdown: true,
        playback: true,
        search: true,
        device: true,
        export: true,
        ptz: true,
        backup: true,
        network: true,
        record: true,
      },
      cova: {
        localCamera: true,
        remoteCamera: true,
        disk: true,
        aiEvent: true,
        schedule: true,
        localSystem: true,
        remoteSystem: true,
        accountAuthority: true,
        remoteLogin: true,
        network: true,
        record: true,
      },
    },
    disable: {
      spectrum: {
        cameraSetting: true,
        viewArchive: true,
        exportArchive: true,
        viewBookmark: true,
        modifyBookmark: true,
        viewEventLog: true,
        cameraUserInput: true,
      },
      vmax: {
        system: true,
        alarm: true,
        shutdown: true,
        playback: true,
        search: true,
        device: true,
        export: true,
        ptz: true,
        backup: true,
        network: true,
        record: true,
      },
      cova: {
        localCamera: true,
        remoteCamera: true,
        disk: true,
        aiEvent: true,
        schedule: true,
        localSystem: true,
        remoteSystem: true,
        accountAuthority: true,
        remoteLogin: true,
        network: true,
        record: true,
      },
    },
  },
  viewer: {
    check: {
      spectrum: {
        cameraSetting: false,
        viewArchive: true,
        exportArchive: true,
        viewBookmark: true,
        modifyBookmark: false,
        viewEventLog: false,
        cameraUserInput: false,
      },
      vmax: {
        system: false,
        alarm: false,
        shutdown: false,
        playback: true,
        search: true,
        device: false,
        export: true,
        ptz: false,
        backup: true,
        network: true,
        record: false,
      },
      cova: {
        localCamera: true,
        remoteCamera: true,
        disk: true,
        aiEvent: true,
        schedule: true,
        localSystem: true,
        remoteSystem: true,
        accountAuthority: true,
        remoteLogin: true,
        network: true,
        record: true,
      },
    },
    disable: {
      spectrum: {
        cameraSetting: true,
        viewArchive: true,
        exportArchive: true,
        viewBookmark: true,
        modifyBookmark: true,
        viewEventLog: true,
        cameraUserInput: true,
      },
      vmax: {
        system: true,
        alarm: true,
        shutdown: true,
        playback: true,
        search: true,
        device: true,
        export: true,
        ptz: true,
        backup: true,
        network: true,
        record: true,
      },
      cova: {
        localCamera: true,
        remoteCamera: true,
        disk: true,
        aiEvent: true,
        schedule: true,
        localSystem: true,
        remoteSystem: true,
        accountAuthority: true,
        remoteLogin: true,
        network: true,
        record: true,
      },
    },
  },
  custom: {
    check: {
      spectrum: {
        cameraSetting: false,
        viewArchive: true,
        exportArchive: false,
        viewBookmark: false,
        modifyBookmark: false,
        viewEventLog: false,
        cameraUserInput: false,
      },
      vmax: {
        system: false,
        alarm: false,
        shutdown: false,
        playback: true,
        search: false,
        device: false,
        export: false,
        ptz: false,
        backup: false,
        network: true,
        record: false,
      },
      cova: {
        localCamera: true,
        remoteCamera: true,
        disk: true,
        aiEvent: true,
        schedule: true,
        localSystem: true,
        remoteSystem: true,
        accountAuthority: true,
        remoteLogin: true,
        network: true,
        record: true,
      },
    },
    disable: {
      spectrum: {
        cameraSetting: false,
        viewArchive: false,
        exportArchive: false,
        viewBookmark: false,
        modifyBookmark: true,
        viewEventLog: false,
        cameraUserInput: false,
      },
      vmax: {
        system: false,
        alarm: false,
        shutdown: false,
        playback: false,
        search: false,
        device: false,
        export: false,
        ptz: false,
        backup: false,
        network: true,
        record: false,
      },
      cova: {
        localCamera: true,
        remoteCamera: true,
        disk: true,
        aiEvent: true,
        schedule: true,
        localSystem: true,
        remoteSystem: true,
        accountAuthority: true,
        remoteLogin: true,
        network: true,
        record: true,
      },
    },
  },
};

// 기존 check list - cova 는 우선 임시로 admin 으로 해야하기때문에 나중에 변경해야함
export const defaultRoleCheckListLater: {
  [key: string]: {
    check: UserGroupRecorderTypePermission;
    disable: UserGroupRecorderTypePermission;
  };
} = {
  administrator: {
    check: {
      spectrum: {
        cameraSetting: true,
        viewArchive: true,
        exportArchive: true,
        viewBookmark: true,
        modifyBookmark: true,
        viewEventLog: true,
        cameraUserInput: true,
      },
      vmax: {
        system: true,
        alarm: true,
        shutdown: true,
        playback: true,
        search: true,
        device: true,
        export: true,
        ptz: true,
        backup: true,
        network: true,
        record: true,
      },
      cova: {
        localCamera: true,
        remoteCamera: true,
        disk: true,
        aiEvent: true,
        schedule: true,
        localSystem: true,
        remoteSystem: true,
        accountAuthority: true,
        remoteLogin: true,
        network: true,
        record: true,
      },
    },
    disable: {
      spectrum: {
        cameraSetting: true,
        viewArchive: true,
        exportArchive: true,
        viewBookmark: true,
        modifyBookmark: true,
        viewEventLog: true,
        cameraUserInput: true,
      },
      vmax: {
        system: true,
        alarm: true,
        shutdown: true,
        playback: true,
        search: true,
        device: true,
        export: true,
        ptz: true,
        backup: true,
        network: true,
        record: true,
      },
      cova: {
        localCamera: true,
        remoteCamera: true,
        disk: true,
        aiEvent: true,
        schedule: true,
        localSystem: true,
        remoteSystem: true,
        accountAuthority: true,
        remoteLogin: true,
        network: true,
        record: true,
      },
    },
  },
  viewer: {
    check: {
      spectrum: {
        cameraSetting: false,
        viewArchive: true,
        exportArchive: true,
        viewBookmark: true,
        modifyBookmark: false,
        viewEventLog: false,
        cameraUserInput: false,
      },
      vmax: {
        system: false,
        alarm: false,
        shutdown: false,
        playback: true,
        search: true,
        device: false,
        export: true,
        ptz: false,
        backup: true,
        network: true,
        record: false,
      },
      cova: {
        localCamera: false,
        remoteCamera: false,
        disk: false,
        aiEvent: false,
        schedule: false,
        localSystem: true,
        remoteSystem: true,
        accountAuthority: false,
        remoteLogin: true,
        network: false,
        record: true,
      },
    },
    disable: {
      spectrum: {
        cameraSetting: true,
        viewArchive: true,
        exportArchive: true,
        viewBookmark: true,
        modifyBookmark: true,
        viewEventLog: true,
        cameraUserInput: true,
      },
      vmax: {
        system: true,
        alarm: true,
        shutdown: true,
        playback: true,
        search: true,
        device: true,
        export: true,
        ptz: true,
        backup: true,
        network: true,
        record: true,
      },
      cova: {
        localCamera: true,
        remoteCamera: true,
        disk: true,
        aiEvent: true,
        schedule: true,
        localSystem: true,
        remoteSystem: true,
        accountAuthority: true,
        remoteLogin: true,
        network: true,
        record: true,
      },
    },
  },
  custom: {
    check: {
      spectrum: {
        cameraSetting: false,
        viewArchive: true,
        exportArchive: false,
        viewBookmark: false,
        modifyBookmark: false,
        viewEventLog: false,
        cameraUserInput: false,
      },
      vmax: {
        system: false,
        alarm: false,
        shutdown: false,
        playback: true,
        search: false,
        device: false,
        export: false,
        ptz: false,
        backup: false,
        network: true,
        record: false,
      },
      cova: {
        localCamera: false,
        remoteCamera: false,
        disk: false,
        aiEvent: false,
        schedule: false,
        localSystem: false,
        remoteSystem: false,
        accountAuthority: false,
        remoteLogin: true,
        network: false,
        record: false,
      },
    },
    disable: {
      spectrum: {
        cameraSetting: false,
        viewArchive: false,
        exportArchive: false,
        viewBookmark: false,
        modifyBookmark: false,
        viewEventLog: false,
        cameraUserInput: false,
      },
      vmax: {
        system: false,
        alarm: false,
        shutdown: false,
        playback: false,
        search: false,
        device: false,
        export: false,
        ptz: false,
        backup: false,
        network: true,
        record: false,
      },
      cova: {
        localCamera: false,
        remoteCamera: false,
        disk: true,
        aiEvent: false,
        schedule: false,
        localSystem: false,
        remoteSystem: false,
        accountAuthority: true,
        remoteLogin: false,
        network: false,
        record: false,
      },
    },
  },
};
