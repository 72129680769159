import { Table } from "antd";
import type { TableProps } from "antd";
import styled from "styled-components";
import { calcRem } from "styles/theme";
import { COLORS, LIGHT_THEME_COLORS } from "styles/colors";
import * as mediaQuery from "components/MediaQuery";
import NoData from "components/templates/nopage/NoData";

export const VirtualTable = (props: TableProps<any>): JSX.Element => {
  return (
    <VirtualTableWrapper>
      <div className="table-wrapper">
        <Table
          showSorterTooltip={false}
          pagination={false}
          virtual
          scroll={{ x: "100%", y: 200 }}
          tableLayout={mediaQuery.isMobile ? "auto" : "fixed"}
          {...(props as TableProps<any>)}
          locale={{
            emptyText: props.loading ? "" : <NoData />,
          }}
        />
      </div>
    </VirtualTableWrapper>
  );
};

export const VirtualExpandTable = (props: TableProps<any>): JSX.Element => {
  return (
    <div className="virtual-expand-table">
      <Table
        showSorterTooltip={false}
        pagination={false}
        tableLayout={mediaQuery.isMobile ? "auto" : "fixed"}
        {...(props as TableProps<any>)}
        locale={{
          emptyText: props.loading ? "" : <NoData />,
        }}
      />
    </div>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  .ant-table-wrapper {
    width: 100%;
    height: 100%;
  }
  .ant-table {
    font-size: ${calcRem(13)};
    color: ${({ theme }) => theme.COLORS.WHITE};
  }
  .ant-empty-normal {
    margin-block: 0;
  }

  .ant-table-filter-dropdown {
    .ant-dropdown-menu {
      padding: 0;
    }
    .ant-dropdown-menu-item {
      padding: 4px;
    }
  }

  .ant-dropdown-menu-title-content {
    color: ${COLORS.BLACK} !important;
  }

  .ant-table-filter-trigger,
  .ant-table-column-sorter {
    color: unset !important;
  }

  .ant-table-filter-trigger.active {
    color: ${({ theme }) => theme.COLORS.SELECTED} !important;
  }
  .ant-table-tbody-virtual-scrollbar-thumb {
    background-color: ${({ theme }) =>
      theme.value === "light" ? `${COLORS.LIGHTGRAY}` : "#545E77"} !important;
  }

  .ant-checkbox-disabled {
    background-color: ${COLORS.BUTTONS1};
  }
  .ant-checkbox-disabled .ant-checkbox-inner:after {
    border-color: ${COLORS.WHITE};
  }

  .ant-radio-disabled.ant-radio-checked .ant-radio-inner {
    background-color: ${COLORS.BUTTONS1} !important;
  }
`;

const VirtualTableWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  .table-wrapper {
    flex: 1 1 auto;
  }

  .ant-table-thead {
    /* padding: 14px 6px; */
    background-color: ${({ theme }) =>
      theme.value === "light" ? LIGHT_THEME_COLORS.LIGHTGRAY : COLORS.PRIMARY3};
  }
  .ant-table-thead th {
    padding: 6px 4px !important;
    color: ${({ theme }) => theme.COLORS.WHITE} !important;
    padding-right: 10px !important;

    .ant-table-column-title {
      font-size: ${calcRem(13)} !important;
      font-weight: 500 !important;
    }
  }
  .ant-table-cell {
    padding: 4px !important;
    padding-right: 6px !important;
  }

  tbody {
    tr {
      &:last-child {
        > td {
          border-bottom: none;
        }
      }
    }
  }

  .virtual-expand-table {
    width: 100%;
  }

  .ant-table-expanded-row {
    .ant-table-row {
      &:last-child {
        .ant-table-cell {
          border-bottom: none !important;
        }
      }
    }
    .ant-table-cell {
      padding-top: 3px !important;
      padding-bottom: 3px !important;
    }
  }
`;
