import {
  deleteAlertRuleList,
  postAlertRuleList,
  putAlertRuleList,
} from "api/alertAPI";
import {
  LEVEL_TYPE,
  ProfileAccountInfo,
} from "api/interfaces/accountInterface.interface";
import {
  Alert,
  AlertRuleGroupRecipients,
  AlertRuleListResponse,
  AlertRuleRecipients,
  PostAlertRequest,
} from "api/interfaces/alertInterface.interface";
import {
  ALERT_DETECT_TIME,
  CustomError,
  Validate,
  ValidateMsg,
} from "api/interfaces/commonInterface.interface";
import { notify } from "components/atoms/notification/Notification";
import { useAuth } from "components/Auth";
import { useCallback, useEffect, useRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useAppSelector } from "redux/hooks";
import { AddRulePresenter } from "./AddRulePresenter";
import { useIntl } from "react-intl";
import { isEmpty } from "lodash";
import useApiError from "hook/useApiError";

interface Props {
  onModal: () => void;
  isEdit?: boolean;
  isView?: boolean;
  editData?: PostAlertRequest;
  alertRule?: AlertRuleListResponse;
}

export enum UPDATE_TYPE {
  AUTO_APPLY_RECORDER,
  AUTO_APPLY_ACCOUNT,
  ACCOUNT,
  RECORDER,
  RECORDER_GROUP,
  USER,
  USER_GROUP,
  SYSTEM_ENABLE,
  SYSTEM_HARDWAREFAILURE,
  SYSTEM_NOTRESPONSE,
  SYSTEM_NOTRESPONSE_DETECTTIME,
  SYSTEM_ABNORMALSTART,
  SYSTEM_OVERHEATED,
  DISK_ENABLE,
  DISK_OVERHEATED,
  DISK_BADSECTOR,
  DISK_STORAGE_STATUS,
  DISK_REINDEXING,
  DISK_STORAGE_SPACE_FULL,
  DISK_RESERVE_SPACE_CLEAR,
  VIDEO_ENABLE,
  VIDEO_LOST_CAMERA,
  VIDEO_LOST_CAMERA_DETECTTIME,
  VIDEO_RECORDING,
  RULE_NAME,
  HOST,
  DESC,
  EMAIL,
  ENABLE,
}

const minMinuteSystemNotResponse = 15;

function AddRuleContainer(props: Props): JSX.Element {
  const auth = useAuth();
  const intl = useIntl();
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const queryClient = useQueryClient();
  const { handleError } = useApiError();

  const initialData: PostAlertRequest = {
    isEnable: true,
    level: selectedAccount.accountLevel,
    accountRule: {
      autoApplyAccount: false,
      accounts: {
        cp: [],
        eu: [],
      },
    },
    recorderRule: {
      autoApplyRecorder: false,
      systems: [],
      recorderGroups: [],
    },
    recipientsRule: {
      recipients: [],
      recipientUserGroups: [],
    },
    preferenceRule: {
      ruleName: "",
      host: auth.user?.name,
      email: auth.user?.email,
      description: "",
    },
    systemAlert: {
      isEnable: true,
      isCriticalNotResponse: true,
      notResponse: 30,
      notResponseType: ALERT_DETECT_TIME.MIN,
      isCriticalAbnormalStart: false,
      abnormalStart: 3,
      abnormalStartType: ALERT_DETECT_TIME.DAY,
      isCriticalOverheated: false,
      overHeated: 1,
      overHeatedType: ALERT_DETECT_TIME.DAY,
    },
    diskAlert: {
      isEnable: true,
      isCriticalHardwareFailure: true,
      isCriticalOverheated: false,
      overheated: 1,
      overheatedType: ALERT_DETECT_TIME.DAY,
      isCriticalBadSectorOver: false,
      badSectorOver: 3,
      isCriticalStorageStatus: false,
      storageStatus: 1,
      storageStatusType: ALERT_DETECT_TIME.HOUR,
      isCriticalReindexing: false,
      reindexing: 24,
      reindexingType: ALERT_DETECT_TIME.HOUR,
      isCriticalStorageSpaceNearFull: false,
      storageSpaceNearFull: 1,
      storageSpaceNearFullType: ALERT_DETECT_TIME.HOUR,
      isCriticalReservedSpaceNotClearing: false,
      reservedSpaceNotClearing: 1,
      reservedSpaceNotClearingType: ALERT_DETECT_TIME.HOUR,
    },
    videoAlert: {
      isEnable: true,
      isCriticalVideoRecording: true,
      isCriticalLostConCamera: false,
      lostConCamera: 12,
      lostConCameraType: ALERT_DETECT_TIME.HOUR,
    },
  };

  // 모달 안 몇번째 스텝
  const [page, setPage] = useState<number>(0);
  const [checkedAccount, setCheckedAccount] = useState<string[]>([]);
  const [originalCheckedAccount, setOriginalCheckedAccount] = useState<
    string[]
  >([]);
  const [alertInfo, setAlertInfo] = useState<PostAlertRequest>(
    props.isEdit && props.editData
      ? props.editData
      : {
          ...initialData,
        }
  );

  useEffect(() => {
    if (props.editData !== undefined) {
      setAlertInfo({
        isEnable: props.editData.isEnable || true,
        level: selectedAccount.accountLevel,
        accountRule: {
          autoApplyAccount:
            props.editData.accountRule?.autoApplyAccount || false,
          accounts: {
            cp: props.editData.accountRule?.accounts?.cp || [],
            eu: props.editData.accountRule?.accounts?.eu || [],
          },
        },
        recorderRule: {
          autoApplyRecorder:
            props.editData.recorderRule?.autoApplyRecorder || false,
          systems: props.editData.recorderRule?.systems || [],
          recorderGroups: props.editData.recorderRule?.recorderGroups || [],
        },
        recipientsRule: {
          recipients: props.editData.recipientsRule.recipients || [],
          recipientUserGroups:
            props.editData.recipientsRule.recipientUserGroups || [],
        },
        preferenceRule: {
          ruleName: props.editData.preferenceRule.ruleName || "",
          host: props.editData.preferenceRule.host || auth.user?.name,
          email: props.editData.preferenceRule.email || auth.user?.email,
          description: props.editData.preferenceRule.description || "",
        },
        systemAlert: {
          isEnable: props.editData.systemAlert.isEnable || true,
          isCriticalNotResponse:
            props.editData.systemAlert.isCriticalNotResponse || true,
          notResponse: props.editData.systemAlert.notResponse || 30,
          notResponseType:
            props.editData.systemAlert.notResponseType || ALERT_DETECT_TIME.MIN,
          isCriticalAbnormalStart:
            props.editData.systemAlert.isCriticalAbnormalStart || false,
          abnormalStart: props.editData.systemAlert.abnormalStart || 3,
          abnormalStartType:
            props.editData.systemAlert.abnormalStartType ||
            ALERT_DETECT_TIME.DAY,
          isCriticalOverheated:
            props.editData.systemAlert.isCriticalOverheated || false,
          overHeated: props.editData.systemAlert.overHeated || 1,
          overHeatedType:
            props.editData.systemAlert.overHeatedType || ALERT_DETECT_TIME.DAY,
        },
        diskAlert: {
          isEnable: props.editData.diskAlert.isEnable || true,
          isCriticalHardwareFailure:
            props.editData.diskAlert.isCriticalHardwareFailure || true,
          isCriticalOverheated:
            props.editData.diskAlert.isCriticalOverheated || false,
          overheated: props.editData.diskAlert.overheated || 1,
          overheatedType:
            props.editData.diskAlert.overheatedType || ALERT_DETECT_TIME.DAY,
          isCriticalBadSectorOver:
            props.editData.diskAlert.isCriticalBadSectorOver || false,
          badSectorOver: props.editData.diskAlert.badSectorOver || 3,
          isCriticalStorageStatus:
            props.editData.diskAlert.isCriticalStorageStatus || false,
          storageStatus: props.editData.diskAlert.storageStatus || 1,
          storageStatusType:
            props.editData.diskAlert.storageStatusType ||
            ALERT_DETECT_TIME.HOUR,
          isCriticalReindexing:
            props.editData.diskAlert.isCriticalReindexing || false,
          reindexing: props.editData.diskAlert.reindexing || 24,
          reindexingType:
            props.editData.diskAlert.reindexingType || ALERT_DETECT_TIME.HOUR,
          isCriticalStorageSpaceNearFull:
            props.editData.diskAlert.isCriticalStorageSpaceNearFull || false,
          storageSpaceNearFull:
            props.editData.diskAlert.storageSpaceNearFull || 1,
          storageSpaceNearFullType:
            props.editData.diskAlert.storageSpaceNearFullType ||
            ALERT_DETECT_TIME.HOUR,
          isCriticalReservedSpaceNotClearing:
            props.editData.diskAlert.isCriticalReservedSpaceNotClearing ||
            false,
          reservedSpaceNotClearing:
            props.editData.diskAlert.reservedSpaceNotClearing || 1,
          reservedSpaceNotClearingType:
            props.editData.diskAlert.reservedSpaceNotClearingType ||
            ALERT_DETECT_TIME.HOUR,
        },
        videoAlert: {
          isEnable: props.editData.videoAlert.isEnable || true,
          isCriticalVideoRecording:
            props.editData.videoAlert.isCriticalVideoRecording || true,
          isCriticalLostConCamera:
            props.editData.videoAlert.isCriticalLostConCamera || false,
          lostConCamera: props.editData.videoAlert.lostConCamera || 12,
          lostConCameraType:
            props.editData.videoAlert.lostConCameraType ||
            ALERT_DETECT_TIME.HOUR,
        },
      });

      const originalChecked = mergedCheckAccount(props.editData);
      setOriginalCheckedAccount(originalChecked);
      setCheckedAccount(originalChecked);
    }
  }, [props.editData]);

  const mergedCheckAccount = (alert: Alert) => {
    let mergedAccount: string[] = [];
    if (alert.accountRule?.accounts?.cp !== undefined) {
      const tmpCp = alert.accountRule.accounts?.cp.map((value) =>
        value.concat("_", "CP")
      );
      mergedAccount.push(...tmpCp);
    }

    if (alert.accountRule?.accounts?.eu !== undefined) {
      const tmpEu = alert.accountRule?.accounts?.eu.map((value) =>
        value.concat("_", "EU")
      );
      mergedAccount.push(...tmpEu);
    }
    return mergedAccount;
  };

  const steps = [
    {
      label:
        selectedAccount.accountLevel !== LEVEL_TYPE.EU
          ? "Organizations"
          : "Recorders",
    },
    {
      label: "Rule",
    },
    {
      label: "Recipients",
    },
    {
      label: "Preferences",
    },
  ];

  const [alertRuleValidate, setAlertRuleValidate] = useState<Validate>({
    isRecorder: false,
    isAccount: false,
    isRuleName: false,
  });
  const [alertRuleValidateMsg, setAlertRuleValidateMsg] = useState<ValidateMsg>(
    {
      isRecorder: "",
      isAccount: "",
      isRuleName: "",
    }
  );

  const initAlertRuleValidate = useCallback((field: string) => {
    setAlertRuleValidate((info) => {
      return { ...info, [field as keyof typeof alertRuleValidate]: false };
    });
    setAlertRuleValidateMsg((info) => {
      return { ...info, [field as keyof typeof alertRuleValidateMsg]: "" };
    });
  }, []);

  const onChangePage = (name: string, index?: number) => {
    if (name === "next") {
      setPage(page + 1);
    }
    if (name === "previous") {
      setPage(page - 1);
    }
    if (name === "save") {
      props.onModal();
    }
    if (name === "step" && index !== undefined) {
      setPage(index);
    }
  };

  const mutationCreateAlert = useMutation(postAlertRuleList, {
    onSuccess: () => {
      notify(
        "success",
        intl.formatMessage({
          id: "label.alertRule.notify.add.success",
          defaultMessage: "Alert rule added successfully.",
        })
      );
      props.onModal();
      // queryClient.invalidateQueries("alertList");
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.alertRule.notify.add.fail",
          defaultMessage: "Alert rule failed to be added.",
        })
      );
    },
  });

  const mutationSaveAlert = useMutation(putAlertRuleList, {
    onSuccess: () => {
      notify(
        "success",
        intl.formatMessage({
          id: "label.alertRule.notify.update.success",
          defaultMessage: "Alert rule updated successfully.",
        })
      );
      props.onModal();
      // queryClient.invalidateQueries("alertList");
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.alertRule.notify.update.fail",
          defaultMessage: "Alert rule update failed.",
        })
      );
    },
  });

  const onClickCreate = useCallback(() => {
    //setIsAddModal(true);
    initAlertRuleValidate("isRecorder");
    initAlertRuleValidate("isAccount");
    initAlertRuleValidate("isRuleName");

    if (
      selectedAccount.accountLevel === LEVEL_TYPE.EU &&
      !alertInfo.recorderRule.autoApplyRecorder &&
      alertInfo.recorderRule.systems.length === 0 &&
      alertInfo.recorderRule.recorderGroups.length === 0
    ) {
      setAlertRuleValidate((info) => {
        return { ...info, isRecorder: true as boolean };
      });

      setAlertRuleValidateMsg((info) => {
        return {
          ...info,
          isRecorder: intl.formatMessage({
            id: "validateMsg.alert.rule.emptyRecorder",
            defaultMessage: "Select one or more recorders.",
          }) as string,
        };
      });
      setPage(0);
      return;
    }

    if (selectedAccount.accountLevel === LEVEL_TYPE.CP) {
      if (
        !alertInfo.accountRule.autoApplyAccount &&
        checkedAccount.length === 0
      ) {
        setAlertRuleValidate((info) => {
          return { ...info, isAccount: true as boolean };
        });

        setAlertRuleValidateMsg((info) => {
          return {
            ...info,
            isRecorder: intl.formatMessage({
              id: "validateMsg.alert.rule.emptyAccount",
              defaultMessage: "Select one or more accounts",
            }) as string,
          };
        });
        setPage(0);
        return;
      } else {
        let cpList: string[] = [];
        let euList: string[] = [];
        checkedAccount.forEach((value) => {
          if (value.endsWith(LEVEL_TYPE.CP)) {
            cpList.push(value.replace(`_CP`, ``));
          } else if (value.endsWith(LEVEL_TYPE.EU)) {
            euList.push(value.replace(`_EU`, ``));
          }
        });
        setAlertInfo((info) => {
          info.accountRule.accounts = {
            cp: cpList,
            eu: euList,
          };
          return info;
        });
      }
    }

    if (isEmpty(alertInfo.preferenceRule.ruleName)) {
      setAlertRuleValidate((info) => {
        return { ...info, isRuleName: true as boolean };
      });

      setAlertRuleValidateMsg((info) => {
        return {
          ...info,
          isRuleName: intl.formatMessage({
            id: "validateMsg.alert.rule.emptyRuleName",
            defaultMessage: "Please enter alert rule name.",
          }) as string,
        };
      });
      setPage(3);
      return;
    }

    createAndUpdateRule();
  }, [alertInfo, checkedAccount]);

  const createAndUpdateRule = useCallback(() => {
    if (props.isEdit) {
      mutationSaveAlert.mutate({
        accountId: selectedAccount.accountId,
        alertRuleId: props.alertRule?.alertRuleId as string,
        payload: alertInfo,
      });
    } else {
      mutationCreateAlert.mutate({
        payload: alertInfo,
        accountId: selectedAccount.accountId,
      });
    }
  }, [
    alertInfo,
    mutationCreateAlert,
    mutationSaveAlert,
    props.alertRule,
    props.isEdit,
    selectedAccount.accountId,
  ]);

  const mutationDeleteAlert = useMutation(deleteAlertRuleList, {
    onSuccess: () => {
      notify(
        "success",
        intl.formatMessage({
          id: "label.alertRule.notify.delete.success",
          defaultMessage: "Alert rule deleted successfully.",
        })
      );
      props.onModal();
      // queryClient.invalidateQueries("alertList");
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.alertRule.notify.delete.fail",
          defaultMessage: "Alert rule deletion failed",
        })
      );
    },
  });

  const onClickDelete = useCallback(() => {
    mutationDeleteAlert.mutate({
      accountId: selectedAccount.accountId,
      alertRuleId: props.alertRule?.alertRuleId as string,
    });
  }, [mutationDeleteAlert, props.alertRule, selectedAccount.accountId]);

  const onChangeAlertRuleInfo = useCallback(
    (
      type: UPDATE_TYPE,
      value: ALERT_DETECT_TIME | boolean | string | number | string[] | number[]
    ) => {
      if (type === UPDATE_TYPE.AUTO_APPLY_RECORDER) {
        setAlertInfo((info) => {
          return {
            ...info,
            recorderRule: {
              ...info.recorderRule,
              autoApplyRecorder: value as boolean,
            },
          };
        });
      } else if (type === UPDATE_TYPE.AUTO_APPLY_ACCOUNT) {
        setAlertInfo((info) => {
          return {
            ...info,
            accountRule: {
              ...info.accountRule,
              autoApplyAccount: value as boolean,
            },
          };
        });
      } else if (type === UPDATE_TYPE.ENABLE) {
        setAlertInfo((info) => {
          return { ...info, isEnable: value as boolean };
        });
      } else if (type === UPDATE_TYPE.ACCOUNT) {
        setCheckedAccount(value as string[]);
        // setAlertInfo((info) => {
        //   return {
        //     ...info,
        //     accountRule: {
        //       ...info.accountRule,
        //       accounts: value as string[],
        //     },
        //   };
        // });
      } else if (type === UPDATE_TYPE.RECORDER) {
        setAlertInfo((info) => {
          return {
            ...info,
            recorderRule: {
              ...info.recorderRule,
              systems: value as string[],
            },
          };
        });
      } else if (type === UPDATE_TYPE.RECORDER_GROUP) {
        setAlertInfo((info) => {
          return {
            ...info,
            recorderRule: {
              ...info.recorderRule,
              recorderGroups: value as number[],
            },
          };
        });
      } else if (type === UPDATE_TYPE.SYSTEM_ENABLE) {
        setAlertInfo((info) => {
          return {
            ...info,
            systemAlert: { ...info.systemAlert, isEnable: value as boolean },
          };
        });
      } else if (type === UPDATE_TYPE.SYSTEM_NOTRESPONSE) {
        if (
          alertInfo.systemAlert.notResponseType === ALERT_DETECT_TIME.MIN &&
          (value as number) < minMinuteSystemNotResponse
        ) {
          value = minMinuteSystemNotResponse;
        }
        setAlertInfo((info) => {
          return {
            ...info,
            systemAlert: { ...info.systemAlert, notResponse: value as number },
          };
        });
      } else if (type === UPDATE_TYPE.SYSTEM_NOTRESPONSE_DETECTTIME) {
        if (
          (value as ALERT_DETECT_TIME) === ALERT_DETECT_TIME.MIN &&
          (alertInfo.systemAlert.notResponse as number) <
            minMinuteSystemNotResponse
        ) {
          setAlertInfo((info) => {
            return {
              ...info,
              systemAlert: {
                ...info.systemAlert,
                notResponse: minMinuteSystemNotResponse as number,
              },
            };
          });
        }

        setAlertInfo((info) => {
          return {
            ...info,
            systemAlert: {
              ...info.systemAlert,
              notResponseType: value as ALERT_DETECT_TIME,
            },
          };
        });
      } else if (type === UPDATE_TYPE.SYSTEM_OVERHEATED) {
        setAlertInfo((info) => {
          return {
            ...info,
            systemAlert: { ...info.systemAlert, overHeated: value as number },
          };
        });
      } else if (type === UPDATE_TYPE.SYSTEM_ABNORMALSTART) {
        setAlertInfo((info) => {
          return {
            ...info,
            systemAlert: {
              ...info.systemAlert,
              abnormalStart: value as number,
            },
          };
        });
      } else if (type === UPDATE_TYPE.DISK_ENABLE) {
        setAlertInfo((info) => {
          return {
            ...info,
            diskAlert: { ...info.diskAlert, isEnable: value as boolean },
          };
        });
      } else if (type === UPDATE_TYPE.DISK_OVERHEATED) {
        setAlertInfo((info) => {
          return {
            ...info,
            diskAlert: { ...info.diskAlert, overheated: value as number },
          };
        });
      } else if (type === UPDATE_TYPE.DISK_BADSECTOR) {
        setAlertInfo((info) => {
          return {
            ...info,
            diskAlert: { ...info.diskAlert, badSectorOver: value as number },
          };
        });
      } else if (type === UPDATE_TYPE.DISK_STORAGE_STATUS) {
        setAlertInfo((info) => {
          return {
            ...info,
            diskAlert: { ...info.diskAlert, storageStatus: value as number },
          };
        });
      } else if (type === UPDATE_TYPE.DISK_REINDEXING) {
        setAlertInfo((info) => {
          return {
            ...info,
            diskAlert: { ...info.diskAlert, reindexing: value as number },
          };
        });
      } else if (type === UPDATE_TYPE.DISK_STORAGE_SPACE_FULL) {
        setAlertInfo((info) => {
          return {
            ...info,
            diskAlert: {
              ...info.diskAlert,
              storageSpaceNearFull: value as number,
            },
          };
        });
      } else if (type === UPDATE_TYPE.DISK_RESERVE_SPACE_CLEAR) {
        setAlertInfo((info) => {
          return {
            ...info,
            diskAlert: {
              ...info.diskAlert,
              reservedSpaceNotClearing: value as number,
            },
          };
        });
      } else if (type === UPDATE_TYPE.VIDEO_ENABLE) {
        setAlertInfo((info) => {
          return {
            ...info,
            videoAlert: { ...info.videoAlert, isEnable: value as boolean },
          };
        });
      } else if (type === UPDATE_TYPE.VIDEO_LOST_CAMERA) {
        setAlertInfo((info) => {
          return {
            ...info,
            videoAlert: { ...info.videoAlert, lostConCamera: value as number },
          };
        });
      } else if (type === UPDATE_TYPE.VIDEO_LOST_CAMERA_DETECTTIME) {
        setAlertInfo((info) => {
          return {
            ...info,
            videoAlert: {
              ...info.videoAlert,
              lostConCameraType: value as ALERT_DETECT_TIME,
            },
          };
        });
      } else if (type === UPDATE_TYPE.USER) {
        setAlertInfo((info) => {
          return {
            ...info,
            recipientsRule: {
              ...info.recipientsRule,
              userId: value as string[],
            },
          };
        });
      } else if (type === UPDATE_TYPE.RULE_NAME) {
        setAlertInfo((info) => {
          return {
            ...info,
            preferenceRule: {
              ...info.preferenceRule,
              ruleName: value as string,
            },
          };
        });
      } else if (type === UPDATE_TYPE.DESC) {
        setAlertInfo((info) => {
          return {
            ...info,
            preferenceRule: {
              ...info.preferenceRule,
              description: value as string,
            },
          };
        });
      }
    },
    [alertInfo, checkedAccount]
  );

  const onChangeRecipients = (
    value: AlertRuleRecipients[] | AlertRuleGroupRecipients[],
    isGroup?: boolean
  ) => {
    if (isGroup) {
      setAlertInfo((info) => {
        return {
          ...info,
          recipientsRule: {
            ...info.recipientsRule,
            recipientUserGroups: value as AlertRuleGroupRecipients[],
          },
        };
      });
      return;
    }
    setAlertInfo((info) => {
      return {
        ...info,
        recipientsRule: {
          ...info.recipientsRule,
          recipients: value as AlertRuleRecipients[],
        },
      };
    });
  };

  const onChangeAlertRuleCheckInfo = useCallback(
    (type: UPDATE_TYPE, value: boolean) => {
      if (type === UPDATE_TYPE.SYSTEM_NOTRESPONSE) {
        setAlertInfo((info) => {
          return {
            ...info,
            systemAlert: {
              ...info.systemAlert,
              isCriticalNotResponse: value as boolean,
            },
          };
        });
      } else if (type === UPDATE_TYPE.SYSTEM_HARDWAREFAILURE) {
        setAlertInfo((info) => {
          return {
            ...info,
            systemAlert: {
              ...info.systemAlert,
              isCriticalHardwareFailure: value as boolean,
            },
          };
        });
      } else if (type === UPDATE_TYPE.SYSTEM_OVERHEATED) {
        setAlertInfo((info) => {
          return {
            ...info,
            systemAlert: {
              ...info.systemAlert,
              isCriticalOverheated: value as boolean,
            },
          };
        });
      } else if (type === UPDATE_TYPE.SYSTEM_ABNORMALSTART) {
        setAlertInfo((info) => {
          return {
            ...info,
            systemAlert: {
              ...info.systemAlert,
              isCriticalAbnormalStart: value as boolean,
            },
          };
        });
      } else if (type === UPDATE_TYPE.DISK_OVERHEATED) {
        setAlertInfo((info) => {
          return {
            ...info,
            diskAlert: {
              ...info.diskAlert,
              isCriticalOverheated: value as boolean,
            },
          };
        });
      } else if (type === UPDATE_TYPE.DISK_BADSECTOR) {
        setAlertInfo((info) => {
          return {
            ...info,
            diskAlert: {
              ...info.diskAlert,
              isCriticalBadSectorOver: value as boolean,
            },
          };
        });
      } else if (type === UPDATE_TYPE.DISK_STORAGE_STATUS) {
        setAlertInfo((info) => {
          return {
            ...info,
            diskAlert: {
              ...info.diskAlert,
              isCriticalStorageStatus: value as boolean,
            },
          };
        });
      } else if (type === UPDATE_TYPE.DISK_REINDEXING) {
        setAlertInfo((info) => {
          return {
            ...info,
            diskAlert: {
              ...info.diskAlert,
              isCriticalReindexing: value as boolean,
            },
          };
        });
      } else if (type === UPDATE_TYPE.DISK_STORAGE_SPACE_FULL) {
        setAlertInfo((info) => {
          return {
            ...info,
            diskAlert: {
              ...info.diskAlert,
              isCriticalStorageSpaceNearFull: value as boolean,
            },
          };
        });
      } else if (type === UPDATE_TYPE.DISK_RESERVE_SPACE_CLEAR) {
        setAlertInfo((info) => {
          return {
            ...info,
            diskAlert: {
              ...info.diskAlert,
              isCriticalReservedSpaceNotClearing: value as boolean,
            },
          };
        });
      } else if (type === UPDATE_TYPE.VIDEO_LOST_CAMERA) {
        setAlertInfo((info) => {
          return {
            ...info,
            videoAlert: {
              ...info.videoAlert,
              isCriticalLostConCamera: value as boolean,
            },
          };
        });
      } else if (type === UPDATE_TYPE.VIDEO_RECORDING) {
        setAlertInfo((info) => {
          return {
            ...info,
            videoAlert: {
              ...info.videoAlert,
              isCriticalVideoRecording: value as boolean,
            },
          };
        });
      }
    },
    []
  );

  return (
    <AddRulePresenter
      accountId={selectedAccount.accountId}
      onModal={props.onModal}
      page={page}
      onChangePage={onChangePage}
      isEdit={props.isEdit}
      isView={props.isView}
      steps={steps}
      data={alertInfo}
      selectedRecorder={alertInfo.recorderRule?.systems}
      selectedAccountList={originalCheckedAccount}
      selectedRecorderGroup={alertInfo.recorderRule?.recorderGroups}
      selectedUser={alertInfo.recipientsRule.recipients}
      selectedGroup={alertInfo.recipientsRule.recipientUserGroups}
      hostName={auth.user?.name + "(" + auth.user?.email + ")"}
      onChangeAlertRuleInfo={onChangeAlertRuleInfo}
      onClickCreate={onClickCreate}
      onClickSave={onClickCreate}
      onClickDelete={onClickDelete}
      onChangeAlertRuleCheckInfo={onChangeAlertRuleCheckInfo}
      onChangeRecipients={onChangeRecipients}
      selectedAccount={selectedAccount}
      alertRuleValidate={alertRuleValidate}
      alertRuleValidateMsg={alertRuleValidateMsg}
      userId={props.isEdit ? props.alertRule?.userId : auth.user.userId}
    />
  );
}

export default AddRuleContainer;
