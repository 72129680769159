import { getVideoTokenInfo } from "api/videoshareAPI";
import VideoPlayer from "components/blocks/video/VideoPlayer";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import * as cs from "pages/auth/SignCommonStyled";
import { useIntl } from "react-intl";
import { Text, ValidateWord } from "components/atoms/text/Text";
import {
  GetVideoStringResponse,
  GuestVideoInfo,
  GuestVideoResponse,
} from "api/interfaces/videoshareInterface.interface";
import { CustomError } from "api/interfaces/commonInterface.interface";
import LinkExpired from "assets/images/video/expired.png";
import { VERIFY_TYPE } from "pages/auth/verify2fa/resendCode/resendCode";
import VerifyTitle from "components/blocks/verify/VerifyTitle";

const videoJsOptions = {
  autoplay: false,
  controls: true,
  responsive: true,
  fluid: true,
  muted: true,
  controlBar: {
    pictureInPictureToggle: false,
  },
};

export default function GuestShare({}): JSX.Element {
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();

  const playerReference = React.useRef(null);
  const state = location.state;

  const [tokenInfo, setTokenInfo] = useState<GuestVideoResponse | null>(null);
  const [userId, setUserId] = useState<string>("");
  const [shareEnd, setShareEnd] = useState<boolean>(false);
  const [searchParams] = useSearchParams();

  const playerReady = (player: any) => {
    playerReference.current = player;

    // handling video player
    // player.on("waiting", () => {
    //   console.log("Video Player is waiting");
    // });
    // player.on("dispose", () => {
    //   console.log("Video player will dispose");
    // });
  };

  // console.log(tokenInfo);

  const navigateToVerify = () => {
    if (tokenInfo) {
      navigate(`/verify/2fa`, {
        state: {
          isAuth: false,
          videoInfo: { ...tokenInfo, userId },
          verifyType: VERIFY_TYPE.EMAIL,
          loginInfo: {
            email: tokenInfo.sharedEmail,
          },
        },
      });
    }
  };

  const getToken = async (payload: GuestVideoInfo) => {
    return getVideoTokenInfo(payload)
      .then((res: GetVideoStringResponse) => {
        if (res.error !== 0 && !res.result) {
          setShareEnd(true);
          return;
        }
        setTokenInfo({
          ...res.result,
          canDownload: res.result.canDownload ?? false,
        });
      })
      .catch((error: CustomError) => {
        if (error.response?.data !== undefined) {
          if (error.response?.data.error === 11003) {
            setShareEnd(true);
          }
        }
      });
  };

  useEffect(() => {
    const videoId = searchParams.get("videoId") as string;
    const userId = searchParams.get("userId") as string;

    const isNotVerified = !(state && state.isVerified);

    if (isNotVerified) {
      if (videoId && userId) {
        setUserId(userId);
        const payload: GuestVideoInfo = {
          videoId: videoId,
          userId: userId,
        };
        getToken(payload);
      }
    }
  }, []);

  return (
    <cs.BG noticeLength={0}>
      {state?.isVerified && state?.tokenInfo ? (
        <VideoPlayer
          videoUrl={state.tokenInfo.url}
          options={videoJsOptions}
          onReady={playerReady}
          isAuth={false}
          timezone={state.tokenInfo.timeZone}
          title="Guest"
          guestStartMs={state.tokenInfo.startTimeMs}
          {...state.tokenInfo}
        />
      ) : (
        <cs.Container>
          <cs.LoginBox>
            <cs.CenterBox>
              {shareEnd ? (
                <>
                  <img src={LinkExpired} width="100" alt="expired_link" />
                  <ValidateWord>
                    {intl.formatMessage({
                      id: "validateMsg.videoShare.err.expiredLink",
                      defaultMessage: "The link you followed has expired.",
                    })}
                  </ValidateWord>
                </>
              ) : (
                <>
                  <VerifyTitle
                    title={intl.formatMessage({
                      id: "shared_email_authentication",
                      defaultMessage: "Video Share Authentication",
                    })}
                    desc={""}
                  />
                  <p className="description">
                    {intl.formatMessage({
                      id: "label.videoshare.guest.verification.sendCode",
                      defaultMessage:
                        "For Security reasons, myDW required an email verification to access shared videos. Click the button below to send a verification code to",
                    })}
                  </p>
                  {tokenInfo?.sharedEmail && (
                    <Text fontSize={14} className="user-name">
                      {tokenInfo.sharedEmail}
                    </Text>
                  )}
                  <br />
                  <br />
                  <cs.Bottom>
                    <cs.BigButton
                      onClick={navigateToVerify}
                      disabled={shareEnd}
                      cancel={shareEnd}
                    >
                      Send Code
                    </cs.BigButton>
                  </cs.Bottom>
                </>
              )}
            </cs.CenterBox>
          </cs.LoginBox>
        </cs.Container>
      )}
    </cs.BG>
  );
}
