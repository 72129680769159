import { AxiosResponse } from "axios";
import qs from "qs";
import { AxiosInstance } from "./axiosInstance";
import {
  AlertAssignRequest,
  AlertAssignResponse,
  AlertCommentRequest,
  AlertCommentResponse,
  AlertHistoryTraceResponse,
  AlertRuleAccountListResponse,
  AlertRuleDetailInfoResponse,
  AlertStatusRequest,
  AlertStatusResponse,
  AlertViewListResponse,
  DeleteAlertHistoryResponse,
  DeleteAlertResponse,
  GetAlertActiveListResponse,
  GetAlertHistoryListDetailResponse,
  GetAlertHistoryListResponse,
  GetAlertHistorySummaryResponse,
  GetAlertListResponse,
  GetAlertRuleDetailResponse,
  GetAlertRuleDetailViewResponse,
  PostAlertRequest,
  PostAlertResponse,
  PutAlertResponse,
} from "./interfaces/alertInterface.interface";
import {
  BasePagination,
  CommonResponse,
  CustomError,
} from "./interfaces/commonInterface.interface";
import { GetUserAllList } from "./interfaces/userInterface.interface";

const API_PATH = "/alert";

export const deleteAlertRule = async ({
  accountId,
  alertRuleId,
}: {
  accountId: string;
  alertRuleId: string;
}): Promise<DeleteAlertResponse> => {
  try {
    const { data }: AxiosResponse<DeleteAlertResponse> =
      await AxiosInstance.delete(
        `${API_PATH}/rule/delete/${accountId}/${alertRuleId}`
      );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getAlertRuleDetailInfo = async ({
  accountId,
  alertRuleId,
}: {
  accountId: string;
  alertRuleId: string;
}): Promise<AlertRuleDetailInfoResponse> => {
  try {
    const { data }: AxiosResponse<AlertRuleDetailInfoResponse> =
      await AxiosInstance.get(
        `${API_PATH}/rule/detail/${accountId}/${alertRuleId}`
      );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

// Get detail alert rule
export const getAlertRuleDetail = async ({
  accountId,
  alertRuleId,
  payload,
}: {
  accountId: string;
  alertRuleId: string;
  payload: BasePagination;
}): Promise<GetAlertRuleDetailResponse> => {
  try {
    const { data }: AxiosResponse<GetAlertRuleDetailResponse> =
      await AxiosInstance.get(
        `${API_PATH}/rule/view/${accountId}/${alertRuleId}?${qs.stringify(
          payload,
          { skipNulls: true }
        )}`
      );
    return data;
  } catch (e) {
    console.error(e);
    const customErr = e as CustomError;
    throw customErr;
  }
};

export const getAlertRuleDetailMerged = async ({
  accountId,
  alertRuleId,
  systemId,
  payload,
}: {
  accountId: string;
  alertRuleId: string;
  systemId: string;
  payload: BasePagination;
}): Promise<GetAlertRuleDetailResponse> => {
  try {
    const { data }: AxiosResponse<GetAlertRuleDetailResponse> =
      await AxiosInstance.get(
        `${API_PATH}/rule/view/${accountId}/${alertRuleId}/${systemId}?${qs.stringify(
          payload
        )}`
      );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getAlertActiveList = async ({
  accountId,
  payload,
}: {
  accountId: string;
  payload: BasePagination;
}): Promise<GetAlertActiveListResponse> => {
  try {
    const { data }: AxiosResponse<GetAlertActiveListResponse> =
      await AxiosInstance.get(
        `${API_PATH}/history/list/active/${accountId}?${qs.stringify(payload, {
          skipNulls: true,
        })}`
      );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

// Search Alert Rule by accountId
export const getAlertRuleList = async ({
  accountId,
  payload,
}: {
  accountId: string;
  payload: BasePagination;
}): Promise<GetAlertListResponse> => {
  try {
    const { data }: AxiosResponse<GetAlertListResponse> =
      await AxiosInstance.get(
        `${API_PATH}/rule/list/${accountId}?${qs.stringify(payload)}`
      );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

// Get detail alert rule view

export const getAlertRuleDetailView = async ({
  accountId,
  alertRuleId,
  recorderId,
}: {
  accountId: string;
  alertRuleId: string;
  recorderId: string;
}): Promise<GetAlertRuleDetailViewResponse> => {
  try {
    const { data }: AxiosResponse<GetAlertRuleDetailViewResponse> =
      await AxiosInstance.get(
        `${API_PATH}/rule/summary/${accountId}/${alertRuleId}/${recorderId}`
      );
    // console.log(data);
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getAlertViewList = async ({
  accountId,
  recorderId,
  historyId,
}: {
  accountId: string;
  recorderId: string;
  historyId?: string;
}): Promise<AlertViewListResponse> => {
  try {
    const { data }: AxiosResponse<AlertViewListResponse> =
      await AxiosInstance.get(
        `${API_PATH}/view/list/${accountId}/${recorderId}?${qs.stringify({
          historyId: historyId,
        })}`
      );
    // console.log(data);
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const postAlertRuleList = async ({
  accountId,
  payload,
}: {
  accountId: string;
  payload: PostAlertRequest;
}): Promise<PostAlertResponse> => {
  try {
    const { data }: AxiosResponse<PostAlertResponse> = await AxiosInstance.post(
      `${API_PATH}/rule/create/${accountId}`,
      payload
    );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const putAlertRuleList = async ({
  accountId,
  alertRuleId,
  payload,
}: {
  accountId: string;
  alertRuleId: string;
  payload: PostAlertRequest;
}): Promise<PutAlertResponse> => {
  try {
    const { data }: AxiosResponse<PutAlertResponse> = await AxiosInstance.put(
      `${API_PATH}/rule/update/${accountId}/${alertRuleId}`,
      payload
    );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const deleteAlertRuleList = async ({
  accountId,
  alertRuleId,
}: {
  accountId: string;
  alertRuleId: string;
}): Promise<PutAlertResponse> => {
  try {
    const { data }: AxiosResponse<PutAlertResponse> =
      await AxiosInstance.delete(
        `${API_PATH}/rule/delete/${accountId}/${alertRuleId}`
      );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const putAlertAssign = async ({
  accountId,
  alertHistoryId,
  payload,
}: {
  accountId: string;
  alertHistoryId: string;
  payload: AlertAssignRequest;
}): Promise<AlertAssignResponse> => {
  try {
    const { data }: AxiosResponse<AlertAssignResponse> =
      await AxiosInstance.put(
        `${API_PATH}/history/update/assign/${accountId}/${alertHistoryId}`,
        payload
      );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const postAlertAssign = async ({
  accountId,
  recorderId,
  payload,
}: {
  accountId: string;
  recorderId: string;
  payload: AlertAssignRequest;
}): Promise<AlertAssignResponse> => {
  try {
    const { data }: AxiosResponse<AlertAssignResponse> =
      await AxiosInstance.post(
        `${API_PATH}/view/create/assign/${accountId}/${recorderId}`,
        payload
      );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const postAlertComment = async ({
  accountId,
  recorderId,
  payload,
}: {
  accountId: string;
  recorderId: string;
  payload: AlertCommentRequest;
}): Promise<AlertCommentResponse> => {
  try {
    const { data }: AxiosResponse<AlertCommentResponse> =
      await AxiosInstance.post(
        `${API_PATH}/view/create/comment/${accountId}/${recorderId}`,
        payload
      );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const changeAlertStatus = async ({
  accountId,
  alertHistoryId,
  payload,
}: {
  accountId: string;
  alertHistoryId: string;
  payload: AlertStatusRequest;
}): Promise<AlertStatusResponse> => {
  try {
    const { data }: AxiosResponse<AlertStatusResponse> =
      await AxiosInstance.put(
        `${API_PATH}/history/update/status/${accountId}/${alertHistoryId}`,
        payload
      );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const changeAlertViewStatus = async ({
  accountId,
  recorderId,
  historyId,
  payload,
}: {
  accountId: string;
  recorderId: string;
  historyId: string;
  payload: AlertStatusRequest;
}): Promise<AlertStatusResponse> => {
  try {
    const { data }: AxiosResponse<AlertStatusResponse> =
      await AxiosInstance.put(
        `${API_PATH}/view/update/status/close/${accountId}/${recorderId}/${historyId}`,
        payload
      );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getAlertHistoryList = async ({
  accountId,
  payload,
}: {
  accountId: string;
  payload: BasePagination;
}): Promise<GetAlertHistoryListResponse> => {
  try {
    const { data }: AxiosResponse<GetAlertHistoryListResponse> =
      await AxiosInstance.get(
        `${API_PATH}/history/list/${accountId}?${qs.stringify(payload, {
          skipNulls: true,
        })}`
      );
    return data;
  } catch (e) {
    console.error(e);
    const customErr = e as CustomError;
    throw customErr;
  }
};

export const getAlertHistoryTraceList = async ({
  accountId,
  historyId,
}: {
  accountId: string;
  historyId: string;
}): Promise<AlertHistoryTraceResponse> => {
  try {
    const { data }: AxiosResponse<AlertHistoryTraceResponse> =
      await AxiosInstance.get(
        `${API_PATH}/history/list/trace/${accountId}/${historyId}`
      );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getAlertHistoryMergedList = async ({
  accountId,
  systemId,
  payload,
}: {
  accountId: string;
  systemId: string;
  payload: BasePagination;
}): Promise<GetAlertHistoryListResponse> => {
  try {
    const { data }: AxiosResponse<GetAlertHistoryListResponse> =
      await AxiosInstance.get(
        `${API_PATH}/history/list/${accountId}/${systemId}?${qs.stringify(
          payload
        )}`
      );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getAlertHistoryListDetail = async ({
  accountId,
  recorderId,
  payload,
}: {
  accountId: string;
  recorderId: string;
  payload: BasePagination;
}): Promise<GetAlertHistoryListDetailResponse> => {
  try {
    const { data }: AxiosResponse<GetAlertHistoryListDetailResponse> =
      await AxiosInstance.get(
        `${API_PATH}/history/detail/${accountId}/${recorderId}?${qs.stringify(
          payload,
          { skipNulls: true }
        )}`
      );

    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const deleteAlertHistory = async ({
  accountId,
  alertRuleId,
}: {
  accountId: string;
  alertRuleId: string;
}): Promise<DeleteAlertHistoryResponse> => {
  try {
    const { data }: AxiosResponse<DeleteAlertHistoryResponse> =
      await AxiosInstance.delete(
        `${API_PATH}/rule/update/${accountId}/${alertRuleId}`
      );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const putAlertHistory = async ({
  accountId,
  alertRuleId,
}: {
  accountId: string;
  alertRuleId: string;
}): Promise<DeleteAlertHistoryResponse> => {
  try {
    const { data }: AxiosResponse<DeleteAlertHistoryResponse> =
      await AxiosInstance.put(
        `${API_PATH}/history/update/${accountId}/${alertRuleId}`
      );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getAlertHistoryDetailView = async ({
  accountId,
  historyId,
}: {
  accountId: string;
  historyId: string;
}): Promise<GetAlertHistorySummaryResponse> => {
  try {
    const { data }: AxiosResponse<GetAlertHistorySummaryResponse> =
      await AxiosInstance.get(
        `${API_PATH}/history/summary/${accountId}/${historyId}`
      );
    // console.log(data);
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const postAlertSendEmail = async ({
  accountId,
  recorderId,
  payload,
}: {
  accountId: string;
  recorderId: string;
  payload: {
    email: string;
    message: string;
  };
}): Promise<CommonResponse> => {
  try {
    const { data }: AxiosResponse<CommonResponse> = await AxiosInstance.post(
      `${API_PATH}/view/assign/email/${accountId}/${recorderId}`,
      payload
    );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const postAlertSendMessage = async ({
  accountId,
  recorderId,
  payload,
}: {
  accountId: string;
  recorderId: string;
  payload: {
    phoneNumber: string;
    message: string;
  };
}): Promise<CommonResponse> => {
  try {
    const { data }: AxiosResponse<CommonResponse> = await AxiosInstance.post(
      `${API_PATH}/view/assign/message/${accountId}/${recorderId}`,
      payload
    );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getAlertRuleAccountList = async ({
  accountId,
}: {
  accountId: string;
}): Promise<AlertRuleAccountListResponse> => {
  try {
    const { data }: AxiosResponse<AlertRuleAccountListResponse> =
      await AxiosInstance.get(`${API_PATH}/rule/accountList/${accountId}`);
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getAlertTraceList = async ({
  accountId,
  recorderId,
  historyId,
}: {
  accountId: string;
  recorderId: string;
  historyId?: string;
}): Promise<AlertHistoryTraceResponse> => {
  try {
    const { data }: AxiosResponse<AlertHistoryTraceResponse> =
      await AxiosInstance.get(
        `${API_PATH}/view/trace/list/${accountId}/${recorderId}?${qs.stringify({
          historyId: historyId,
        })}`
      );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getAlertViewUserList = async ({
  accountId,
  recorderId,
}: {
  accountId: string;
  recorderId: string;
}): Promise<GetUserAllList> => {
  try {
    const { data }: AxiosResponse<GetUserAllList> = await AxiosInstance.get(
      `${API_PATH}/view/user/list/${accountId}/${recorderId}`
    );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const putAlertStatusClose = async ({
  accountId,
  recorderId,
  historyId,
}: {
  accountId: string;
  recorderId: string;
  historyId: string;
}): Promise<AlertStatusResponse> => {
  try {
    const { data }: AxiosResponse<AlertStatusResponse> =
      await AxiosInstance.put(
        `${API_PATH}/view/update/status/close/${accountId}/${recorderId}/${historyId}`
      );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const putAlertStatusAllClose = async ({
  accountId,
  recorderId,
}: {
  accountId: string;
  recorderId: string;
}): Promise<AlertHistoryTraceResponse> => {
  try {
    const { data }: AxiosResponse<AlertHistoryTraceResponse> =
      await AxiosInstance.put(
        `${API_PATH}/view/update/status/allClose/${accountId}/${recorderId}`
      );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};
