import {
  getLogin,
  postCheckEmail,
  postForgotEmail,
  postForgotPassword,
  postLogin,
  submitFreeTrial,
} from "api/authAPI";
import { useAuth } from "components/Auth";
import { getItemWithExpireTime, useLocalStorage } from "hook/useLocalStorage";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { LoginPresenter } from "./LoginPresenter";
import { isEmpty } from "lodash";
import { notify } from "components/atoms/notification/Notification";
import { getClientDevice, getStartPageUrl } from "utils/functions";
import { checkEmailRegEx } from "utils/regEx";
import qs from "qs";
import { ACCESS_TOKEN, AxiosInstance, REFRESH_TOKEN } from "api/axiosInstance";
import { useAppDispatch } from "redux/hooks";
import { setMenu } from "redux/reducers/menu/menuSlice";
import { UserInfo } from "api/interfaces/userInterface.interface";
import { checkGroup, checkTrialShowMenu } from "utils/AccountUtil";
import { getCookieExpireTime } from "utils/timeUtil";
import { setCookie } from "cookies/cookie";
import { useIntl } from "react-intl";
import { LEVEL_TYPE } from "api/interfaces/accountInterface.interface";
import { CustomError } from "api/interfaces/commonInterface.interface";
import { menuNavigation } from "utils/MenuUtil";
import { datadogRum } from "@datadog/browser-rum";
import {
  GetLoginResponse,
  LoginResponse,
  PostLoginResponse,
} from "api/interfaces/authInterface.interface";
import { onSuccessLogin, setUserInfo } from "utils/AuthUtil";
import GeneralAlertModal from "components/templates/modal/small/generalAlertModal/GeneralAlertModal";
import axios, { AxiosError } from "axios";

export enum LOGIN_STEP {
  INPUT_EMAIL = "INPUT_EMAIL",
  INPUT_PASSWORD = "INPUT_PASSWORD",
  FORGOT_EMAIL = "FORGOT_EMAIL",
  FORGOT_EMAIL_SENT = "FORGOT_EMAIL_SENT",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  FREE_TRIAL = "FREE_TRIAL",
}

export interface LoginInfo {
  email: string;
  password: string;
  tel?: string;
}

export interface FreeTrialInfo {
  email: string;
  organizationName: string;
}

export interface Validate {
  [key: string]: boolean;
}
export interface ValidateMsg {
  [key: string]: string;
}

type Props = {
  code?: string;
};
export function LoginPage({ code }: Props): JSX.Element {
  const auth = useAuth();
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [step, setSteps] = useState<LOGIN_STEP>(LOGIN_STEP.INPUT_EMAIL);
  const [loginInfo, setLoginInfo] = useState<LoginInfo>({
    email: "",
    password: "",
    tel: "",
  });

  const [isValidated, setIsValidated] = useState<Validate>({
    email: false,
    password: false,
    tel: false,
  });

  const [validateMsg, setValidateMsg] = useState<ValidateMsg>({
    email: "",
    password: "",
    tel: "",
  });
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [freeTrialInfo, setFreeTrialInfo] = useState<FreeTrialInfo>({
    email: "",
    organizationName: "",
  });

  const [modal, setModal] = useState<JSX.Element | null>(null);

  //로컬스토리지 저장.
  const [emailLocal, setEmailLocal] = useLocalStorage("email", "");
  const [rememberLocal, setRememberLocal] = useLocalStorage("remember", false);

  const [cloudLoading, setCloudLoading] = useState<boolean>(false);

  const [rememberInfo, setRememberInfo] = useState<{
    email: string;
    isRemember: boolean;
  }>({
    email: "",
    isRemember: false,
  });

  const qsSearchParam = qs.parse(location.state?.search, {
    ignoreQueryPrefix: true,
    decoder: (s) => decodeURIComponent(s),
  });
  const email =
    qsSearchParam.email !== null ? (qsSearchParam.email as string) : "";
  const toSearchParam = new URLSearchParams(location.state?.search);
  //const email = toSearchParam.get("email") !== null ? toSearchParam.get("email") as string : "";
  const mobilePlusCode = qsSearchParam.mobilePlusCode;

  const [isSpectrumMouseOver, setIsSpectrumMouseOver] =
    useState<boolean>(false);

  const onMouseOverSpectrumLogin = () => {
    setIsSpectrumMouseOver(!isSpectrumMouseOver);
  };

  useEffect(() => {
    (async () => {
      const device = getClientDevice();
      const hasCode = code !== undefined && code !== "";
      if (hasCode) {
        window.opener.postMessage({ code }, window.location.origin);
        return;
      }
      const hasEmail = !!email;
      if (hasEmail) {
        setLoginInfo((info) => ({
          ...info,
          email: email as string,
        }));
      }

      const hasLoginInfoFromMobilePlus = !!mobilePlusCode && !!email;
      if (hasLoginInfoFromMobilePlus) {
        mutationLoginFromCode.mutate({
          code: mobilePlusCode,
        });
      }
    })();
  }, [location]);

  const setStep = (string: LOGIN_STEP) => {
    setIsValidated({
      email: false,
      password: false,
      tel: false,
    });
    setValidateMsg({
      email: "",
      password: "",
      tel: "",
    });
    setSteps(string);
  };

  useLayoutEffect(() => {
    if (rememberLocal) {
      setLoginInfo((info) => {
        return {
          ...info,
          email: emailLocal as string,
        };
      });
    } else {
      setLoginInfo((info) => {
        return {
          ...info,
          email: "",
        };
      });
    }
  }, []);

  const onChangePhoneNumber = useCallback((number: string) => {
    setLoginInfo((info) => ({
      ...info,
      tel: number,
    }));
    setPhoneNumber(number);
  }, []);

  const onChangeLoginInfo = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.name === "email") {
        setLoginInfo((info) => ({
          ...info,
          email:
            e.target.value !== undefined && e.target.value !== ""
              ? e.target.value.trim()
              : "",
        }));
      }

      if (e.target.name === "password") {
        setLoginInfo((info) => ({
          ...info,
          password: e.target.value,
        }));
      }
    },
    []
  );

  const onChangeFreeTrialInfo = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFreeTrialInfo((info) => {
        return {
          ...info,
          [e.target.name]: (e.target.value || "").trim(),
        };
      });
    },
    []
  );

  const onErrorPassword = (msg?: string) => {
    setIsValidated({ ...isValidated, password: true });
    setLoginInfo({
      ...loginInfo,
      password: "",
    });
    setValidateMsg({
      ...validateMsg,
      password: msg
        ? msg
        : intl.formatMessage({
            id: "validateMsg.signup.loginIncorrect",
            defaultMessage: "Incorrect Login Credentials",
          }),
    });
  };

  const onSuccessLogin2 = (
    loginData: LoginResponse,
    toSearchParam?: URLSearchParams
  ) => {
    datadogRum.setUser({
      email: loginInfo.email,
    });

    // 2fa 필요 없을 때 로그인
    setCookie({
      name: ACCESS_TOKEN,
      value: loginData.accessToken,
      options: {
        path: "/",
        expires: getCookieExpireTime(6),
      },
    });
    setCookie({
      name: REFRESH_TOKEN,
      value: loginData.refreshToken,
      options: {
        path: "/",
        expires: getCookieExpireTime(72),
      },
    });
    AxiosInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${loginData.accessToken}`;

    setUserInfo(auth, loginData.accountId, toSearchParam)
      .then(function (resolvedData) {
        const accountData = resolvedData as UserInfo;
        //BUG [RND-330] email link
        if (accountData !== undefined && accountData.isAccount !== 0) {
          if (
            toSearchParam &&
            toSearchParam.size > 0 &&
            toSearchParam.get("url") !== null
          ) {
            auth.processLinkNavigate(
              toSearchParam.get("url") as string,
              toSearchParam,
              accountData
            );
          } else {
            auth.loginNavigator("/", accountData);
          }
        } else {
          console.error("Account information bot found");
        }
      })
      .catch(function (err) {
        setIsValidated({ ...isValidated, password: true });
        setValidateMsg({
          ...validateMsg,
          password: intl.formatMessage({
            id: "validateMsg.signup.loginIncorrect",
            defaultMessage: "Incorrect Login Credentials.",
          }),
        });
      });
  };

  const onLoginSuccess = (data: GetLoginResponse | PostLoginResponse) => {
    const result: LoginResponse = data.result;

      const storedTrustDevice = getItemWithExpireTime(
        "trust_" + loginInfo.email + result.accountId
      );

      if (result.phoneNumber) {
        setLoginInfo((info) => ({
          ...info,
          tel: result.phoneNumber,
        }));
      }

      // 2fa 인증필요 && 30일 미인증 -> 2fa 페이지 이동
      if (result && result.requiredTwoFactor && !storedTrustDevice) {
        navigate(`/verify/2fa`, {
          state: {
            isAuth: true,
            verifyType: result.use2faCode,
            loginInfo: {
              email: loginInfo.email,
              password: loginInfo.password,
              phone: result.phoneNumber,
            },
            isLogin: result,
            accountId: result.accountId,
            accessToken: result.accessToken,
          },
        });
        return;
      }

      const onError = () => {
        setIsValidated({ ...isValidated, password: true });
        setValidateMsg({
          ...validateMsg,
          password: intl.formatMessage({
            id: "validateMsg.signup.loginIncorrect",
            defaultMessage: "Incorrect Login Credentials.",
          }),
        });
      };

      onSuccessLogin(auth, loginInfo.email, result, onError, toSearchParam);
  }

  const mutationLoginFromCode = useMutation(getLogin, {
    onSuccess: (data: GetLoginResponse) => {
      onLoginSuccess(data);
    },
    onError: (e: { error: number, errorString: string }) => {
      if (!!e.errorString) {
        notify(
          "error",
          e.errorString,
        );
      }
    },
  });

  const mutationLogin = useMutation(postLogin, {
    onSuccess: (data: PostLoginResponse) => {
      onLoginSuccess(data);
    },
    onError: () => {
      onErrorPassword();
    },
  });

  const onClickLogin = useCallback(() => {
    if (rememberInfo.isRemember) {
      setEmailLocal(loginInfo.email);
      setRememberLocal(true);
    } else {
      setEmailLocal("");
      setRememberLocal(false);
    }
    mutationLogin.mutate({
      email: loginInfo.email,
      password: loginInfo.password,
      device: getClientDevice(),
    });
  }, [loginInfo, mutationLogin, setEmailLocal]);

  const mutationCheckEmail = useMutation(postCheckEmail, {
    onSuccess: (res: any) => {
      if (
        res.result.provider !== undefined &&
        res.result.provider !== "AUTH0"
      ) {
        setIsValidated({ ...isValidated, email: true });
        setValidateMsg({
          ...validateMsg,
          email: intl.formatMessage({
            id: "validateMsg.signup.checkEmail.cloudUser",
            defaultMessage:
              "Please try logging in with your DW Spectrum Cloud account.",
          }),
        });
        return;
      }

      if (res.result.isExist) {
        //Check Registered Email
        if (res.result.isActivate) {
          //Check SingUp User
          if (res.result?.hasAccount === undefined || !res.result?.hasAccount) {
            setIsValidated({ ...isValidated, email: true });
            setValidateMsg({
              ...validateMsg,
              email: intl.formatMessage({
                id: "validateMsg.signup.accountNotFound",
                defaultMessage: "Organization does not exist.",
              }),
            });
          } else {
            setStep(LOGIN_STEP.INPUT_PASSWORD);
          }
        } else {
          setIsValidated({ ...isValidated, email: true });
          setValidateMsg({
            ...validateMsg,
            email: intl.formatMessage({
              id: "validateMsg.signup.disActivateUser",
              defaultMessage:
                "Please accept the signup invite sent to this address",
            }),
          });
        }
      } else {
        setIsValidated({ ...isValidated, email: true });
        setValidateMsg({
          ...validateMsg,
          email: intl.formatMessage({
            id: "validateMsg.login.checkEmail.noExist",
            defaultMessage: "The email address entered is not registered",
          }),
        });
      }
    },
    onError: () => {
      setIsValidated({ ...isValidated, email: true });
      setValidateMsg({
        ...validateMsg,
        email: intl.formatMessage({
          id: "validateMsg.signup.accountNotFound",
          defaultMessage: "Couldn't find your myDW organization",
        }),
      });
    },
  });

  const onClickNext = useCallback(() => {
    if (isEmpty(loginInfo.email)) {
      setIsValidated({ ...isValidated, email: true });
      setValidateMsg({
        ...validateMsg,
        email: intl.formatMessage({
          id: "validateMsg.signup.invalidEmail",
          defaultMessage: "Invalid email address",
        }),
      });
      return;
    }

    if (!checkEmailRegEx(loginInfo.email)) {
      setIsValidated({ ...isValidated, email: true });
      setValidateMsg({
        ...validateMsg,
        email: intl.formatMessage({
          id: "validateMsg.signup.invalidEmail",
          defaultMessage: "Invalid email address",
        }),
      });
      return;
    }
    if (rememberInfo.isRemember) {
      setEmailLocal(loginInfo.email.replace(/^\s+|\s+$/gm, ""));
      setRememberLocal(true);
    } else {
      setEmailLocal("");
      setRememberLocal(false);
    }
    setLoginInfo((info) => ({
      ...info,
      password: "",
    }));
    setIsValidated({ ...isValidated, password: false });
    setValidateMsg({
      ...validateMsg,
      password: "",
    });
    setEmailLocal(loginInfo.email);

    try {
      delete AxiosInstance.defaults.headers.common["Authorization"];
    } catch {
      console.error("cant not delete Authorization");
    }
    mutationCheckEmail.mutate({
      email: loginInfo.email.replace(/^\s+|\s+$/gm, ""),
    });
  }, [
    intl,
    isValidated,
    loginInfo,
    mutationCheckEmail,
    rememberInfo.isRemember,
    setEmailLocal,
    setRememberLocal,
    validateMsg,
  ]);

  const onChangeRemember = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setRememberInfo((rememberInfo) => {
        return {
          ...rememberInfo,
          isRemember: e.target.checked,
        };
      });
      setRememberLocal(e.target.checked);
    },
    []
  );

  const mutationForgotEmail = useMutation(postForgotEmail, {
    onSuccess: () => {
      if (step === LOGIN_STEP.FORGOT_EMAIL_SENT) {
        notify(
          "info",
          intl.formatMessage({
            id: "label.login.forgotEmail.resend.sms",
            defaultMessage: "Email reminder was sent successfully.",
          }),
          "top"
        );
      } else {
        setStep(LOGIN_STEP.FORGOT_EMAIL_SENT);
      }
    },
    onError: () => {
      setIsValidated({ ...isValidated, tel: true });
      setValidateMsg({
        ...validateMsg,
        tel: intl.formatMessage({
          id: "validateMsg.signup.noExistPhone",
          defaultMessage: "Phone number entered not found in the system.",
        }),
      });
    },
  });

  const onClickForgotEmail = useCallback(() => {
    mutationForgotEmail.mutate({
      payload: {
        phoneNumber: phoneNumber,
      },
    });
    //setStep(LOGIN_STEP.FORGOT_EMAIL_SENT);
  }, [phoneNumber]);

  const onClickStepChange = useCallback((step: LOGIN_STEP) => {
    setStep(step);
  }, []);

  const onClickForgotPassword = useCallback(() => {
    mutationForgotPassword.mutate({
      payload: {
        email: loginInfo.email,
      },
    });
  }, [loginInfo]);

  const mutationForgotPassword = useMutation(postForgotPassword, {
    onSuccess: () => {
      setStep(LOGIN_STEP.FORGOT_PASSWORD);
    },
    onError: (err: CustomError) => {
      notify(
        "error",
        intl.formatMessage({
          id: "validateMsg.signup.noExistEmail",
          defaultMessage: "Email does not exist",
        })
      );
    },
  });

  const onClickCloudLogin = () => {
    const OATUH_HOST = "https://dwspectrum.digital-watchdog.com/authorize";
    const client_id = "mydw-api";
    const redirect_uri = process.env.REACT_APP_OAUTH_CALLBACK_URL;
    //const redirect_uri = "http://localhost:3000";
    const response_type = "code";

    const AUTHORIZE_URI = `${OATUH_HOST}?${qs.stringify({
      client_id,
      redirect_uri,
      response_type,
    })}`;

    window.location.href = AUTHORIZE_URI;
  };

  const mutationSubmitFreeTrial = useMutation(submitFreeTrial, {
    onSuccess: () => {
      const message = intl.formatMessage({
        id: "label.freeTrial.success",
        defaultMessage:
          "EYour application has been received and we will send yu an invitation email.",
      });
      const onModal = () => {
        setStep(LOGIN_STEP.INPUT_EMAIL);
        setModal(null);
      };
      setModal(<GeneralAlertModal text={message} onModal={onModal} />);
    },
    onError: (axiosError: AxiosError) => {
      if (
        !axiosError.response ||
        !axiosError.response.data ||
        !(axiosError.response.data as any).error
      ) {
        setValidateMsg({
          ...validateMsg,
          freeTrialEmail: "",
          freeTrialOrganizationName: "Request failed",
        });
        return;
      }
      const errorCode = (axiosError.response.data as any).error;
      const markAsError = (field: "email" | "organizationName") => {
        setIsValidated({
          ...isValidated,
          freeTrialOrganizationName: field === "organizationName",
          freeTrialEmail: field === "email",
        });
      };

      if (errorCode === 1000) {
        markAsError("email");
        setValidateMsg({
          ...validateMsg,
          freeTrialEmail: intl.formatMessage({
            id: "validateMsg.freeTrial.alreadyRegistredEmail",
            defaultMessage: "This email is already registered with myDW.",
          }),
          freeTrialOrganizationName: "",
        });
        return;
      }
      if (errorCode === 1024) {
        markAsError("email");
        setValidateMsg({
          ...validateMsg,
          freeTrialEmail: intl.formatMessage({
            id: "validateMsg.freeTrial.alreadyInvitedEmail",
            defaultMessage:
              "Please check your mailbox again or contact your organization's administrator if you would like to receive the invitation email again.",
          }),
          freeTrialOrganizationName: "",
        });
        return;
      }
      if (errorCode === 3008) {
        markAsError("organizationName");
        setValidateMsg({
          ...validateMsg,
          freeTrialEmail: "",
          freeTrialOrganizationName: intl.formatMessage({
            id: "validateMsg.freeTrial.existingOrganizationName",
            defaultMessage:
              "An organization with the same name already exists. Please enter a different name.",
          }),
        });
        return;
      }
    },
  });

  const onSubmitFreeTrial = () => {
    if (isEmpty(freeTrialInfo.email) || !checkEmailRegEx(freeTrialInfo.email)) {
      setIsValidated({ ...isValidated, freeTrialEmail: true });
      setValidateMsg({
        ...validateMsg,
        freeTrialEmail: intl.formatMessage({
          id: "validateMsg.signup.invalidEmail",
          defaultMessage: "Invalid email address",
        }),
      });
      return;
    }
    setIsValidated({
      ...isValidated,
      freeTrialEmail: false,
      freeTrialOrganizationName: false,
    });
    setValidateMsg({
      ...validateMsg,
      freeTrialEmail: "",
      freeTrialOrganizationName: "",
    });

    mutationSubmitFreeTrial.mutate({
      payload: {
        email: freeTrialInfo.email,
        orgName: freeTrialInfo.organizationName,
      },
    });
  };

  const onCode = async (code: string) => {
    if (code !== undefined && code !== "") {
      try {
        const device = getClientDevice();
        setCloudLoading(true);
        // code를 이용해서 token들을 받아옴
        sessionStorage.setItem("code", code);
        const resp = await AxiosInstance.post("/auth/token", {
          code,
          device,
        });

        if (resp.status === 404) {
          setIsValidated({ ...isValidated, email: true });
          setValidateMsg({
            ...validateMsg,
            email: intl.formatMessage({
              id: "validateMsg.signup.accountNotFound",
              defaultMessage: "Couldn't find your myDW organization",
            }),
          });
        } else if (200 > resp.status || 300 < resp.status) {
          setIsValidated({ ...isValidated, email: true });
          setValidateMsg({
            ...validateMsg,
            email: intl.formatMessage({
              id: "validateMsg.signup.loginIncorrect",
              defaultMessage: "Incorrect Login Credentials.",
            }),
          });
        } else {
          AxiosInstance.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${resp.data.result.accessToken}`;
          setCookie({
            name: ACCESS_TOKEN,
            value: resp.data.result.accessToken,
            options: {
              path: "/",
              expires: getCookieExpireTime(6),
            },
          });
          setCookie({
            name: REFRESH_TOKEN,
            value: resp.data.result.refreshToken,
            options: {
              path: "/",
              expires: getCookieExpireTime(72),
              //secure : true,
              //httpOnly : true
            },
          });
          //sessionStorage.setItem("token", resp.data.token);
          setUserInfo(auth)
            .then(function (resolvedData) {
              const accountData = resolvedData as UserInfo;
              //BUG [RND-330] email link
              if (accountData !== undefined && accountData.isAccount !== 0) {
                if (
                  toSearchParam.size > 0 &&
                  toSearchParam.get("url") !== null
                ) {
                  auth.processLinkNavigate(
                    toSearchParam.get("url") as string,
                    toSearchParam,
                    accountData
                  );
                } else {
                  auth.loginNavigator("/", accountData);
                }
              } else {
                console.error("Account information bot found");
              }
            })
            .catch(function (err) {
              setIsValidated({ ...isValidated, email: true });
              setValidateMsg({
                ...validateMsg,
                email: intl.formatMessage({
                  id: "validateMsg.signup.loginIncorrect",
                  defaultMessage: "Incorrect Login Credentials.",
                }),
              });
            });
        }
      } catch (err) {
        setIsValidated({ ...isValidated, email: true });
        setValidateMsg({
          ...validateMsg,
          email: intl.formatMessage({
            id: "validateMsg.signup.loginIncorrect",
            defaultMessage: "Incorrect Login Credentials.",
          }),
        });
      } finally {
        setCloudLoading(false);
      }
    }
  };

  return (
    <LoginPresenter
      step={step}
      loginInfo={loginInfo}
      checkedRemember={rememberInfo.isRemember}
      isValidated={isValidated}
      validateMsg={validateMsg}
      onClickNext={onClickNext}
      onClickLogin={onClickLogin}
      onChangeLoginInfo={onChangeLoginInfo}
      onChangePhoneNumber={onChangePhoneNumber}
      onChangeRemember={onChangeRemember}
      onChangeFreeTrialInfo={onChangeFreeTrialInfo}
      onClickForgotEmail={onClickForgotEmail}
      onClickStepChange={onClickStepChange}
      onClickForgotPassword={onClickForgotPassword}
      onSubmitFreeTrial={onSubmitFreeTrial}
      onClickCloudLogin={onClickCloudLogin}
      cloudLoading={cloudLoading}
      onCode={onCode}
      isSpectrumMouseOver={isSpectrumMouseOver}
      onMouseOverSpectrumLogin={onMouseOverSpectrumLogin}
      modal={modal}
    />
  );
}
