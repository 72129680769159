import Select, {
  ActionMeta,
  components,
  GroupBase,
  MultiValue,
  OnChangeValue,
  OptionProps,
  Props,
  SelectComponentsConfig,
  StylesConfig,
  ValueContainerProps,
} from "react-select";
import { COLORS, LIGHT_THEME_COLORS } from "styles/colors";
import { calcRem } from "styles/theme";
import { useAppSelector } from "redux/hooks";
import { Option } from "utils/options";
import { CustomOption, CustomValueContainer } from "./CustomSelectComponents";

export interface SelectComboProps extends Props {
  onChangeSelectCombo?: (value: MultiValue<Option>) => void;
}

export const customComboStyles: StylesConfig<any, true, GroupBase<any>> = {
  control: (styles, state) => {
    let isLightTheme =
      state.selectProps.className?.includes("select-light-mode");
    const backgroundColor = state.isDisabled
      ? isLightTheme
        ? LIGHT_THEME_COLORS.LIGHTGRAY
        : COLORS.BUTTONS1
      : COLORS.WHITE;
    const borderColor =
      state.isDisabled && isLightTheme ? LIGHT_THEME_COLORS.BORDER : "#cacaca";
    const maxHeight = "33px";
    const minHeight = "33px";

    return { ...styles, backgroundColor, borderColor, maxHeight, minHeight };
  },
  container: (styles, state) => {
    let isLightTheme =
      state.selectProps.className?.includes("select-light-mode");

    return {
      ...styles,
      width: "100%",
      height: "100%",
      color: state.isDisabled
        ? isLightTheme
          ? `${COLORS.BLACK}60`
          : COLORS.WHITE
        : COLORS.BLACK,
      fontSize: calcRem(14),
      fontWeight: "normal",
    };
  },
  valueContainer: (styles, state) => {
    let isLightTheme =
      state.selectProps.className?.includes("select-light-mode");

    return {
      ...styles,
      color: state.isDisabled
        ? isLightTheme
          ? `${COLORS.BLACK}60`
          : `${COLORS.WHITE}60`
        : COLORS.BLACK,
      fontSize: calcRem(14),
      fontWeight: "normal",
    };
  },
  menu: (provided) => ({ ...provided, zIndex: 9999 }),
  placeholder: (styles, state) => {
    return {
      ...styles,
      color: "#abb5c5",
      fontWeight: "normal",
    };
  },
  indicatorSeparator: () => ({}),
  indicatorsContainer: (styles) => ({ ...styles, padding: 0, height: "33px" }),
  dropdownIndicator: (styles) => ({
    ...styles,
    padding: 0,
    color: COLORS.BUTTONS1,
  }),
};

const SelectsCombo = (props: SelectComboProps) => {
  const theme = useAppSelector((state) => state.theme.theme);
  const { onChangeSelectCombo, components, ...rest } = props;

  const onChange = (
    newValue: OnChangeValue<Option, true>,
    actionMeta: ActionMeta<Option>
  ) => {
    onChangeSelectCombo && onChangeSelectCombo(newValue as MultiValue<Option>);
  };

  return (
    <Select<any, true, GroupBase<any>>
      {...rest}
      isMulti
      styles={customComboStyles}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      isClearable={false}
      backspaceRemovesValue={false}
      placeholder={props.placeholder || "Select"}
      onChange={onChange}
      className={`select-container select-${theme}-mode`}
      components={{
        Option: CustomOption,
        ValueContainer: CustomValueContainer,
        ...(components as SelectComponentsConfig<any, true, GroupBase<any>>),
      }}
    />
  );
};
export default SelectsCombo;
