import React, { useState } from "react";
import * as s from "../BillingPaymentStyled";
import { BillingCustomers } from "api/interfaces/licenseInterface.interface";
import { PaymentTable } from "components/atoms/table/AntdBillingTable";
import { TableColumnsType } from "antd";

export type Props = {
  data: BillingCustomers[];
};

export default function PaymentCustomerTable(props: Props): JSX.Element {
  const columns = React.useMemo<TableColumnsType<BillingCustomers>>(
    () => [
      {
        dataIndex: "po",
        title: "CUSTOMER P.O.",
        render: (value) => value,
        width: "50%",
      },
      {
        dataIndex: "payment",
        title: "PAYMENT DETAILS",
        render: (value) => value,
        width: "50%",
      },
    ],
    []
  );

  return (
    <s.TableWrapper>
      <PaymentTable columns={columns} dataSource={props.data} />
    </s.TableWrapper>
  );
}
