import { DashboardData } from "api/interfaces/dashboardInterface.interface";
import MARKWE_ALERT from "assets/icons/map/marker_alert.svg";
import MARKWE_ASSIGN from "assets/icons/map/marker_assign.svg";
import MARKWE_HEALTHY from "assets/icons/map/marker_healthy.svg";
import MARKWE_WARNING from "assets/icons/map/marker_warning.svg";
import MARKWE_CRITICAL from "assets/icons/map/marker_critical.svg";
import styled from "styled-components";
import { calcRem } from "styles/theme";
import { FLEX } from "styles/flex";
import { determineLableColor } from "../../GoogleMapContainer";

export interface Props {
  data: {
    warningCount: number;
    alertCount: number;
    criticalCount: number;
    assignCount: number;
    systems: DashboardData[] | undefined;
    name: string;
  };
}

const determineMarker = (
  // status: number,
  warningCount: number,
  assignCount: number,
  alertCount: number,
  criticalCount: number
) => {
  let markerIcon = MARKWE_HEALTHY;

  if (warningCount > 0) {
    markerIcon = MARKWE_WARNING;
  }

  if (assignCount > 0) {
    markerIcon = MARKWE_ASSIGN;
  }

  if (alertCount > 0) {
    markerIcon = MARKWE_ALERT;
  }
  if (criticalCount > 0) {
    markerIcon = MARKWE_CRITICAL;
  }
  return markerIcon;
};

export default function SingleMarker(props: Props): JSX.Element {
  return (
    <Wrapper
      status={{
        alertCount: props.data.alertCount,
        criticalCount: props.data.criticalCount,
        warningCount: props.data.warningCount,
        assignCount: props.data.assignCount,
      }}
    >
      <span className="google-map-marker-label">
        {props.data.systems !== undefined && props.data.systems.length > 1
          ? `${props.data.systems.length} SYS`
          : props.data.name}
      </span>
      <img
        src={determineMarker(
          props.data.warningCount,
          props.data.assignCount,
          props.data.alertCount,
          props.data.criticalCount
        )}
      />

      {/* <div className="circle-animation"></div> */}
    </Wrapper>
  );
}

const Wrapper = styled.div<{
  status: { [key: string]: number };
}>`
  ${FLEX.FlexCenterCenter};
  flex-direction: column;
  gap: 4px;
  width: 18px;
  position: relative;

  .google-map-marker-label {
    white-space: nowrap;
    font-size: ${calcRem(15)};
    font-weight: 400;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 2px 4px;
    border-radius: 2px;
    color: ${(props) =>
      determineLableColor(
        props.status.warningCount,
        props.status.assignCount,
        props.status.alertCount,
        props.status.criticalCount
      )};
  }

  img {
    width: 100%;
  }

  /* .circle-animation {
    width: 40px;
    height: 13px;
    background-color: ${(props) =>
    determineLableColor(
      props.status.warningCount,
      props.status.assignCount,
      props.status.alertCount,
      props.status.criticalCount
    )};
    position: absolute;
    z-index: -1;
    bottom: -5px;
    border-radius: 50%;
    opacity: 0.5;
    animation: pulse 1.5s infinite;
  }
  @keyframes pulse {
    0%,
    100% {
      transform: scale(1);
      opacity: 0.5;
    }
    50% {
      transform: scale(1.4);
      opacity: 0.6;
    }
  } */
`;
