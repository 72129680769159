import { OperationPresenter } from "./OperationPresenter";
import { useCallback, useState } from "react";
import { useQuery } from "react-query";
import { getOperationLogs } from "api/operationlogAPI";
import { GetOperationLogRequest, GetOperationLogResponse, GetOperationLogResponseResult, OperationType, Page } from "api/interfaces/operationlogInterface.interface";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import { BasePagination, SORT_DIRECTION } from "api/interfaces/commonInterface.interface";
import moment from "moment";
import spacetime from "spacetime";
import { getAccountTimeFormatDate, getAccountTimeFormatDateReplace, getTimezoneDisplay } from "utils/timeUtil";

function getFormattedDateTime(time: string, accountInfo: ProfileAccountInfo, selectedTimezone: string): string {
  // TODO: timeUtil.getAccountTimeFormat 동작 안함: https://dw-rnd.atlassian.net/browse/FW-24
  if (!time) {
    return '-';
  }
  const dateFormat = accountInfo.dateFormat.replace('dd', 'DD');
  const format = `${dateFormat} ${accountInfo.timeFormat}`;
  const momentTime = moment(time).tz(selectedTimezone);
  return `${momentTime.format(format)} ${getTimezoneDisplay(spacetime(time), selectedTimezone)}`;
}

type OperationLog = GetOperationLogResponseResult;

const PAGE_LIMIT = 20;

export interface SearchParams {
  username: string,
  accountNumber: string,
  page: Page | null,
  operationType: OperationType | null,
  timezone: string,
  startTime: Date,
  endTime: Date,
};

const initialSearchParams = (timezone: string): SearchParams => {
  const now = new Date();
  const oneHourBefore = new Date();
  oneHourBefore.setHours(now.getHours() - 2);
  return {
    username: '',
    accountNumber: '',
    page: null,
    operationType: null,
    timezone: "America/Los_Angeles",
    startTime: oneHourBefore,
    endTime: now,
  }
};

const initialPagination: BasePagination = {
  total: 0,
  pageNum: 0,
  pageLimit: PAGE_LIMIT,
  sortType: "createdAt",
  sortDirection: SORT_DIRECTION.DESC,
}

const stateToRequestParams = (state: SearchParams & BasePagination): GetOperationLogRequest => {

  const startTimeNoTimezone = (moment(state.startTime).format('YYYY-MM-DDTHH:mm:ss'));
  const endTimeNoTimezone = (moment(state.endTime).format('YYYY-MM-DDTHH:mm:ss'));

  const requestParams: GetOperationLogRequest = {
    username: state.username,
    accountNumber: state.accountNumber,
    timezone: state.timezone,
    startTime: startTimeNoTimezone,
    endTime: endTimeNoTimezone,
    total: 0,
    pageNum: state.pageNum,
    pageLimit: PAGE_LIMIT,
    sortType: state.sortType,
    sortDirection: state.sortDirection,
  }
  if (state.page) {
    requestParams.page = state.page;
  }
  if (state.operationType) {
    requestParams.operationType = state.operationType;
  }
  return requestParams;
}


export default function OperationContainer(): JSX.Element {
  const [operationLogList, setOperationLogList] = useState<OperationLog[]>([]);
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const [searchParams, setSearchParams] = useState<SearchParams>(initialSearchParams(selectedAccount.timezone));
  const [paginationInfo, setPaginationInfo] = useState<BasePagination>(initialPagination);

  const { error, refetch } = useQuery(
    ["operationLog", paginationInfo],
    () =>
      getOperationLogs(stateToRequestParams({ ...searchParams, ...paginationInfo }), selectedAccount.accountId),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: GetOperationLogResponse) => {
        console.log(res);
        if (res.error || res.errorString) {
          console.log(res.error, res.errorString);
          setPaginationInfo(initialPagination);
          setOperationLogList([]);
          return;
        }
        setPaginationInfo({ ...res.page });
        const logs = res.result.map((log) => {
          return {
            ...log,
            time: getAccountTimeFormatDateReplace(log.time, selectedAccount, searchParams.timezone, true)
          }
        })
        setOperationLogList(logs);
      },
      onError: (err: any) => {
        console.log(err);
      },
    }
  );

  const onClickSearch = () => {
    setPaginationInfo((paginationInfo) => ({
      ...paginationInfo,
      pageNum: 0,
    }));
    refetch();
  };


  const onChangePage = (page: number) => {
    setPaginationInfo((paginationInfo) => ({
      ...paginationInfo,
      pageNum: page - 1,
    }));
  };

  const onSearchParamChange = useCallback((name: string, value: any) => {
    setSearchParams(searchParams => {
      return {
        ...searchParams,
        [name]: value
      }
    });
  }, []);

  return (
    <OperationPresenter
      data={operationLogList}
      accountInfo={selectedAccount}
      onSearchParamChange={onSearchParamChange}
      onClickSearch={onClickSearch}
      searchParams={searchParams}
      paginationInfo={paginationInfo}
      setPaginationInfo={setPaginationInfo}
      onChangePage={onChangePage}
    />
  );
}
