import React, { useEffect } from "react";
import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { getRecorderTotalList } from "api/recorderAPI";
import {
  Recorder,
  RecorderTotalList,
  RecorderTotalListResposne,
} from "api/interfaces/recorderInterface.interface";
import {
  VirtualExpandTable,
  VirtualTable,
} from "components/atoms/table/AntdVirtualTable";
import { TableColumnsType } from "antd";
import { TableRowSelection } from "antd/es/table/interface";
import { TableProps } from "antd/lib";
import { matchStringSearchKeyword } from "utils/functions";

export type SelectedRowWithAddress = {
  id: string;
  address: string;
};

export type SearchRecorderProps = {
  accountId: string;
  isSingleMode?: boolean;
  onSelectedRowsChange?: (selectedRows: string[]) => void;
  onSelectRowChangeAddress?: (row: SelectedRowWithAddress) => void;
  selectedRow?: string[] | string;
  search?: string;
  isEditable?: boolean;
  userId?: string;
};

export interface DataRow {
  key: string;
  name: string;
  cameras: number;
  location: string;
}

export interface ModalRecorder {
  key: string;
  recorderName: string;
  cameraCount: number;
  location: string;
  brand: string;
  subRows?: ModalRecorder[];
}

export function SearchRecorderList(props: SearchRecorderProps): JSX.Element {
  const [recorder, setRecorder] = useState<ModalRecorder[]>([]);
  const [pending, setPending] = useState<boolean>(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const columns: TableColumnsType<ModalRecorder> = useMemo(
    () => [
      {
        key: "recorderName",
        dataIndex: "recorderName",
        title: "Name",
        render: (value, row) => value,
        ellipsis: true,
        width: 180,
      },
      {
        key: "cameraCount",
        dataIndex: "cameraCount",
        title: "Cameras",
        render: (value, row) => value,
        align: "center",
        width: 100,
      },
      {
        key: "location",
        dataIndex: "location",
        title: "Locations",
        render: (value, row) => value,
        width: 150,
        ellipsis: true,
      },
    ],
    [recorder]
  );

  const expandColumns: TableColumnsType<ModalRecorder> = useMemo(
    () => [...columns],
    [recorder]
  );

  const { isLoading, isSuccess, error, refetch } = useQuery(
    ["recorder", props.selectedRow],
    () =>
      getRecorderTotalList({
        accountId: props.accountId,
        userId: props.userId,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: RecorderTotalListResposne) => {
        if (!res.result) {
          return;
        }

        let totalList: ModalRecorder[] = [];
        res.result.forEach((data: RecorderTotalList, index: number) => {
          const isSingleRecorder = data.recorders.length === 1;
          const model: ModalRecorder = {
            key:
              props.isSingleMode && isSingleRecorder
                ? data.recorders[0].recorderId
                : data.mergedSystemId,
            recorderName: data.mergedSystemName,
            cameraCount: data.cameraTotalCount,
            brand: data.brand,
            location: data.location || "",
            subRows: !isSingleRecorder
              ? data.recorders.map((item: Recorder) => ({
                  key: item.recorderId,
                  recorderName: item.name,
                  cameraCount: item.cameraCount,
                  brand: item.type,
                  location: item.location?.location || "",
                }))
              : [],
          };
          totalList.push(model);
        });

        setRecorder(totalList);
      },
      onError: (e: any) => {},
      onSettled: () => {
        setPending(false);
      },
    }
  );

  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    selectedRows: ModalRecorder[]
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);

    if (props.isSingleMode) {
      if (selectedRows.length !== 0 && props.onSelectRowChangeAddress) {
        props.onSelectRowChangeAddress({
          id: selectedRows[0].key,
          address: selectedRows[0].location,
        });
      }
    } else {
      if (props.onSelectedRowsChange) {
        props.onSelectedRowsChange(newSelectedRowKeys as string[]);
      }
    }
  };

  const rowSelection: TableRowSelection<ModalRecorder> = {
    columnWidth: 40,
    selectedRowKeys,
    onChange: onSelectChange,
    type: props.isSingleMode ? "radio" : "checkbox",
    getCheckboxProps: (record: ModalRecorder) => ({
      style: {
        visibility:
          props.isSingleMode &&
          record.subRows !== undefined &&
          record.subRows.length !== 0
            ? "hidden"
            : "visible",
      },
      disabled: props.isEditable,
    }),
  };

  const expandedRowRender = useMemo(
    () => (data: ModalRecorder[]) => {
      const rowExpandedSelection: TableRowSelection<ModalRecorder> = {
        columnWidth: 40,
        selectedRowKeys,
        onChange: onSelectChange,
        type: "radio",
        getCheckboxProps: (record: ModalRecorder) => ({
          disabled: props.isEditable,
          checked: selectedRowKeys.includes(record.key),
        }),
      };
      return (
        <VirtualExpandTable
          columns={expandColumns}
          dataSource={data}
          showHeader={false}
          rowSelection={props.isSingleMode ? rowExpandedSelection : undefined}
        />
      );
    },
    [selectedRowKeys]
  );

  const expandableProps = useMemo<
    TableProps<ModalRecorder>["expandable"]
  >(() => {
    return {
      columnWidth: 40,
      expandedRowRender: (record) =>
        record.subRows ? expandedRowRender(record.subRows) : undefined,
      rowExpandable: (record) =>
        record.subRows ? record.subRows.length > 1 : false,
    };
  }, [recorder, selectedRowKeys]);

  useEffect(() => {
    if (props.selectedRow !== undefined) {
      if (props.isSingleMode && typeof props.selectedRow === "string") {
        setSelectedRowKeys([props.selectedRow]);
      } else {
        setSelectedRowKeys(props.selectedRow as React.Key[]);
      }
    }
  }, [props.selectedRow]);

  const data: ModalRecorder[] = useMemo(() => {
    return recorder.filter((rec) => {
      if (props.search) {
        // 하위 recorder 있을때도 keyword 확인
        if (rec.subRows) {
          const tempSubRowsKeyword = rec.subRows.filter((sr) => {
            if (props.search)
              return (
                matchStringSearchKeyword(sr.recorderName, props.search) ||
                matchStringSearchKeyword(sr.location, props.search)
              );
          });
          return tempSubRowsKeyword.length > 1;
        }

        return (
          matchStringSearchKeyword(rec.recorderName, props.search) ||
          matchStringSearchKeyword(rec.location, props.search)
        );
      } else return true;
    });
  }, [props.search, recorder]);

  return (
    <>
      <VirtualTable
        columns={columns}
        dataSource={data}
        rowSelection={rowSelection}
        expandable={expandableProps}
        loading={pending}
      />
    </>
  );
}
