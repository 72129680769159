import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  DashboardAlertData,
  DashboardData,
  DashboardReduxData,
  DashboardReduxDataPerType,
} from "api/interfaces/dashboardInterface.interface";

const getDashboardReduxDataOfRecorder = (
  recorder: DashboardData
): DashboardReduxDataPerType => {
  return {
    recorderCount: 1,
    systemAlert: recorder.systemAlerts || 0,
    diskAlert: recorder.storageAlerts || 0,
    videoAlert: recorder.videoAlerts || 0,
    alertList: [...recorder.alerts, ...recorder.assign, ...recorder.critical],
  };
};

const merge = (
  a: DashboardReduxDataPerType,
  b: DashboardReduxDataPerType
): DashboardReduxDataPerType => {
  return {
    recorderCount: a.recorderCount + b.recorderCount,
    systemAlert: a.systemAlert + b.systemAlert,
    diskAlert: a.diskAlert + b.diskAlert,
    videoAlert: a.videoAlert + b.videoAlert,
    alertList: [...a.alertList, ...b.alertList],
  };
};

const initialState: DashboardReduxData = {
  spectrum: {
    systemAlert: 0,
    diskAlert: 0,
    videoAlert: 0,
    alertList: [],
    recorderCount: 0,
  },
  nvr: {
    systemAlert: 0,
    diskAlert: 0,
    videoAlert: 0,
    alertList: [],
    recorderCount: 0,
  },
  dvr: {
    systemAlert: 0,
    diskAlert: 0,
    videoAlert: 0,
    alertList: [],
    recorderCount: 0,
  },
  cova: {
    systemAlert: 0,
    diskAlert: 0,
    videoAlert: 0,
    alertList: [],
    recorderCount: 0,
  },
  recorderList: [],
};

// 사용자 로그인 후 Account 정보와 선택정보를 전역으로 관리.

export const dashboardDataSlice = createSlice({
  name: "dashboardData",
  initialState,
  reducers: {
    setDashboardAlertData(
      state,
      action: PayloadAction<{
        spectrum: DashboardAlertData[];
        nvr: DashboardAlertData[];
        dvr: DashboardAlertData[];
        cova: DashboardAlertData[];
      }>
    ) {},
    setDashboardData(state, action: PayloadAction<DashboardData[]>) {
      if (action.payload === undefined || action.payload === null) {
        return initialState;
      }
      let newState: DashboardReduxData = { ...initialState };
      action.payload.forEach((recorder: DashboardData, index) => {
        if (!recorder.type) {
          return;
        }
        newState.recorderList = [...newState.recorderList, recorder];
        const recorderType = recorder.type.toUpperCase();

        switch (recorderType) {
          case "SPECTRUM":
            newState.spectrum = merge(
              newState.spectrum,
              getDashboardReduxDataOfRecorder(recorder)
            );
            break;
          case "NVR":
            newState.nvr = merge(
              newState.nvr,
              getDashboardReduxDataOfRecorder(recorder)
            );
            break;
          case "DVR":
            newState.dvr = merge(
              newState.dvr,
              getDashboardReduxDataOfRecorder(recorder)
            );
            break;
          case "COVA":
            newState.cova = merge(
              newState.cova,
              getDashboardReduxDataOfRecorder(recorder)
            );
            break;
          default:
            break;
        }
      });
      return newState;
    },
    setDashboardDataInit(state) {
      return initialState;
    },
  },
});

export const { setDashboardAlertData, setDashboardData, setDashboardDataInit } =
  dashboardDataSlice.actions;

export default dashboardDataSlice;
