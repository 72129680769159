import styled from "styled-components";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";
import { COLORS } from "../../../styles/colors";
import { useState } from "react";
import INFO_ICON from "assets/icons/info.svg";
import AdditionalAccountBubble from "components/blocks/bubble/AdditionalAccountBubble";
import * as mediaQuery from "components/MediaQuery";

interface Props {
  color?: string;
  fontSize?: number;
  bold?: boolean;
  border?: number;
  center?: boolean;
  isDeleted?: boolean;
  isRowClickEditable?: boolean;
}

interface InputLabelProps {
  label: string;
  essential?: boolean;
  noBold?: boolean;
  isBubble?: boolean;
  bubbleContents?: any;
}

export const Text = styled.div<Props>`
  color: ${(props) =>
    props.isDeleted
      ? props.theme.COLORS.BUTTONS1
      : props.color
      ? props.color
      : props.theme.COLORS.WHITE};
  font-size: ${(props) =>
    props.fontSize ? calcRem(props.fontSize) : calcRem(16)};
  line-height: ${(props) =>
    props.fontSize ? calcRem(props.fontSize + 2) : calcRem(18)};
  white-space: nowrap;
  ${(props) =>
    props.bold &&
    `
      font-weight: 500;
    `};
`;

export const UnderlineText = styled(Text)<Props & { disabled?: boolean }>`
  text-decoration: underline;
  ${FLEX.FlexStartCenter}
  gap: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: ${({ color, theme }) =>
    color ? color : theme.value === "light" ? COLORS.COMPANY : COLORS.SELECTED};

  ${({ disabled, theme }) =>
    disabled
      ? `
    cursor: default;
    color: ${COLORS.LIGHTGRAY};
  `
      : `
    cursor: pointer;

  `}
`;

export const CenterlineText = styled(Text)<Props>`
  width: 100%;
  display: flex;
  flex-basis: 100%;
  align-items: center;

  :after {
    content: "";
    flex-grow: 1;
    margin: 0 10px;
    background: ${(props) =>
      props.color ? `${props.color}50` : props.theme.COLORS.WHITE};
    height: ${({ border }) => (border ? `${border}px` : "1px")};
    font-size: 0px;
    line-height: 0px;
  }

  ${(props) =>
    props.center &&
    `
      :before {
      content: "";
      flex-grow: 1;
      margin: 0 10px;
      background: ${
        props.color ? `${props.color}50` : props.theme.COLORS.WHITE
      };
      height: ${props.border ? `${props.border}px` : "1px"};
      font-size: 0px;
      line-height: 0px;
    }
    `}
`;

const InputLabelBox = styled.label`
  ${FLEX.FlexStartCenter};
`;

const EssentialPoint = styled.span`
  color: ${COLORS.RED};
`;

export function InputLabel({
  label,
  essential,
  noBold,
  isBubble,
  bubbleContents,
}: InputLabelProps): JSX.Element {
  const [isHover, setIsHover] = useState<boolean>(false);
  return (
    <InputLabelBox>
      <Text bold={!noBold} className="modal-input-label-title">
        {label}
      </Text>
      {isBubble && bubbleContents !== undefined && (
        <InfoButton
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          <img src={INFO_ICON} alt="info icon" />
          {isHover && (
            <BubbleContainer>
              <AdditionalAccountBubble />
            </BubbleContainer>
          )}
        </InfoButton>
      )}
      {essential && <EssentialPoint>*</EssentialPoint>}
    </InputLabelBox>
  );
}
const InfoButton = styled.button`
  ${FLEX.FlexCenterCenter};
  position: relative;
  width: 15px;
  height: 15px;

  ${mediaQuery.isMobile} {
    width: 12px;
    height: 12px;
  }

  img {
    width: 100%;
    height: 100%;
  }
`;
const BubbleContainer = styled.div`
  position: absolute;
  z-index: 299;
  left: 50%;
  transform: translate(-50%, 0);
  top: 30px;
  min-width: max-content;
`;
const ValidateWordWrapper = styled.span`
  ${FLEX.FlexStartCenter};
  color: ${COLORS.VALIDATERED};
  padding-top: 10px;
`;

export function ValidateWord({ children }: any): JSX.Element {
  return (
    <ValidateWordWrapper className="validate-word">
      {children}
    </ValidateWordWrapper>
  );
}
