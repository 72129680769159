import { DownloadButton } from "components/atoms/buttons/DownloadButton";
import Input from "components/atoms/input/Input";
import styled from "styled-components";

const InputWrapper = styled.div`
  position: relative;
  width: inherit; 
  height: inherit;
`;

const DownloadButtonWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 40px;
  height: 33px;
`;

type RecordedVideoFileInput = {
  fileUrl: string;
  uploadFile: File | undefined;
}

export const RecordeVideoFileInput = ({ fileUrl, uploadFile }: RecordedVideoFileInput) => {
  if (!uploadFile) {
    return <></>;
  }

  return (
    <InputWrapper>
      <Input
        disabled
        placeholder={"Recorded video file"}
        value={uploadFile?.name}
      />
      <DownloadButtonWrapper>
        <DownloadButton fileUrl={fileUrl} fileName={uploadFile.name} />
      </DownloadButtonWrapper>
    </InputWrapper>
  )
}