import * as mediaQuery from "components/MediaQuery";
import { useEffect, useState } from "react";
import * as s from "../FilterStyled";
import styled from "styled-components";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  MapFilter,
  mapFilterInitialState,
  setTypes,
} from "redux/reducers/map/mapFilter";
import {
  DashboardFilter,
  DashboardFilterType,
} from "api/interfaces/dashboardInterface.interface";
import { Text, UnderlineText } from "components/atoms/text/Text";
import TinyButton from "components/atoms/buttons/TinyButton";
import { COLORS } from "styles/colors";

type Props = {
  onApply: () => void;
  onChangeFiltering: (name: string, value: boolean) => void;
};

type List = {
  label: string;
  value: "spectrum" | "nvr" | "dvr" | "cova";
};

export const typeList: List[] = [
  {
    label: "SPECTRUM",
    value: "spectrum",
  },
  {
    label: "VMAX IP",
    value: "nvr",
  },
  {
    label: "VMAX A1",
    value: "dvr",
  },
  {
    label: "COVA",
    value: "cova",
  },
];

const TypeFilter = (props: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const mapFilter: DashboardFilter = useAppSelector(
    (state) => state.mapFilter.filters
  );

  const [type, setType] = useState<DashboardFilterType>(mapFilter.types);

  const onChange = (e: any) => {
    let {
      target: { checked, name },
    } = e;

    setType({
      ...type,
      [name]: checked,
    });
  };

  useEffect(() => {
    if (type) {
      dispatch(setTypes(type));
    }
  }, [type]);

  useEffect(() => {
    if (mapFilter.types) {
      setType(mapFilter.types);
      if (Object.values(mapFilter.types).every((type) => !type)) {
        props.onChangeFiltering("types", false);
      } else {
        props.onChangeFiltering("types", true);
      }
    }
  }, [mapFilter]);

  const onResetFilter = () => {
    dispatch(setTypes(mapFilterInitialState.filters.types));
    setType(mapFilterInitialState.filters.types);
  };

  return (
    <s.Container>
      <mediaQuery.Mobile>
        <s.Title>
          <Text fontSize={14} bold>
            Type
          </Text>
        </s.Title>
      </mediaQuery.Mobile>
      <Wrapper>
        {typeList.map((stat: List) => {
          return (
            <Item key={stat.label}>
              <label>
                <input
                  type="checkbox"
                  checked={type[stat.value]}
                  name={stat.value}
                  onChange={onChange}
                />

                {stat.label}
              </label>
            </Item>
          );
        })}
      </Wrapper>
      <s.Reset>
        <UnderlineText
          color={COLORS.LIGHTGRAY}
          fontSize={12}
          onClick={onResetFilter}
        >
          Clear
        </UnderlineText>
        <TinyButton label="Done" onClickBtn={props.onApply} />
      </s.Reset>
    </s.Container>
  );
};

export default TypeFilter;

const Wrapper = styled.ul`
  position: relative;
  ${FLEX.FlexStartStart};
  flex-direction: column;
  gap: 8px;

  ${mediaQuery.isMobile} {
    margin-top: 10px;
  }
`;

const Item = styled.li`
  color: ${({ theme }) => theme.COLORS.WHITE};
  ${FLEX.FlexStartCenter};
  /* gap: 6px; */
  font-size: ${calcRem(13)};

  img {
    height: 20px;
  }
`;
