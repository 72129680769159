import * as s from "./ButtonStyled";
import { COLORS } from "styles/colors";
import * as mediaQuery from "components/MediaQuery";
import { FaEdit } from "react-icons/fa";
import { BiArrowToRight, BiSearch } from "react-icons/bi";
import { ImEnter } from "react-icons/im";

type TableButtonProps = {
  label: string;
  buttonType?: string;
  icon?: any;
};

function TableButton({
  label,
  buttonType,
  icon,
  ...divProps
}: TableButtonProps &
  React.ButtonHTMLAttributes<HTMLButtonElement>): JSX.Element {
  return (
    <>
      <mediaQuery.Default>
        <s.Container
          buttonType={buttonType ? buttonType : "cancel"}
          className="table-button"
          {...divProps}
        >
          <s.ButtonLabel table>
            {label}
            {icon && icon}
          </s.ButtonLabel>
        </s.Container>
      </mediaQuery.Default>
      <mediaQuery.Mobile>
        {!(label === "Edit" || label === "Enter" || label.includes("View")) ? (
          <s.Container
            buttonType={buttonType ? buttonType : "cancel"}
            className="table-button"
            {...divProps}
          >
            <s.ButtonLabel table>
              {icon && icon}
              {label}
            </s.ButtonLabel>
          </s.Container>
        ) : (
          <s.MobileTableButtonIconWrapper {...divProps}>
            {label === "Edit" && <FaEdit size={16} />}
            {label === "Enter" && <ImEnter size={16} />}
            {label.includes("View") && (
              <BiSearch
                size={20}
                color={
                  label.includes("Alerts") ? COLORS.PROGRESSBAR : COLORS.WHITE
                }
              />
            )}
          </s.MobileTableButtonIconWrapper>
        )}
      </mediaQuery.Mobile>
    </>
  );
}

export default TableButton;
